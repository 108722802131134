import React from "react";
// PrimeReact components
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
// Custom components
import {
  AddressInputForm,
  CustomerInputForm,
  SimpleAddressInputForm,
} from "./CustomerComponents";
// Localization
import { injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
  VALIDATION_RULES,
} from "assets/staticData/enums";
// Helper functions
import { initLogger, sendQuery } from "common/Helpers";

const logger = initLogger("appointment_customer_view");
class AppointmentCustomerView extends React.Component {
  state = {
    customerUpdatePending: false,
    validMatriculation: null,
    appointmentDeletePending: false,
  };

  mapCustomerToDTO = () => {
    const { customer } = this.props;

    return {
      addressList: [],
      customer: {
        ...customer,

        sylvain: false,

        dateOfDeath: null,
        isDead: false,
      },
      invoiceList: [],
    };
  };

  handleDeleteClick = () => {
    const { intl } = this.props;
    const {
      DIALOG_WARNING_TITLE,
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
      WARNING_CONFIRM_REMOVE,
    } = MESSAGE_KEYS;
    confirmDialog({
      message: (
        <div className="mt-4 flex align-items-center">
          <i
            className="pi pi-exclamation-triangle mr-2"
            style={{ fontSize: "2em" }}
          />{" "}
          {intl.formatMessage({ id: WARNING_CONFIRM_REMOVE }, { value: "" })}
        </div>
      ),
      header: intl.formatMessage({ id: DIALOG_WARNING_TITLE }),
      accept: this.handleAppointmentDelete,
      reject: null,
      acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
      rejectLabel: intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL }),
    });
  };

  handleAppointmentDelete = () => {
    const { driveId, intl, callWebsocket, handleDialogClose } = this.props;
    const { APPOINTMENTS_DELETE_SUCCESS, APPOINTMENTS_DELETE_FAIL } =
      MESSAGE_KEYS;
    sendQuery(`${QUERIES.DELETE_APPOINTMENT}${driveId}`, "DELETE").then(
      () => {
        this.toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({ id: APPOINTMENTS_DELETE_SUCCESS }),
        });
        this.setState({
          appointmentDeletePending: false,
        });
        callWebsocket(driveId, true);
        handleDialogClose();
      },
      (error) => {
        logger.log(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: APPOINTMENTS_DELETE_FAIL }),
        });
        this.setState({
          appointmentDeletePending: false,
        });
      }
    );
  };

  /**
   * Sends the modified data to the server.
   * @param {CustomerData} data
   * @returns {Promise<Object|String>}
   */
  saveCustomer = () => {
    return new Promise((resolve, reject) => {
      try {
        let data = this.mapCustomerToDTO();

        sendQuery(QUERIES.EDIT_CUSTOMER, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  validateNewCustomer = () => {
    return new Promise((resolve, reject) => {
      let errors = [];
      const {
        CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER,
        CUSTOMERS_ERROR_MATRICULATION_LENGTH,
        CUSTOMERS_ERROR_MATRICULATION_REQUIRED,
        ERROR_DATA_SAVE,
      } = MESSAGE_KEYS;
      const {
        MATRICULATION_LUCKY_NUMBER_MIN_LENGTH,
        MATRICULATION_MIN_LENGTH,
      } = VALIDATION_RULES;
      try {
        const {
          customer: { healthInsuranceNumber },
        } = this.props;
        if (!healthInsuranceNumber) {
          errors.push(CUSTOMERS_ERROR_MATRICULATION_REQUIRED);
        } else {
          if (healthInsuranceNumber.length !== MATRICULATION_MIN_LENGTH) {
            errors.push(CUSTOMERS_ERROR_MATRICULATION_LENGTH);
          }
          let luckyNumber = healthInsuranceNumber.split("-");
          if (
            luckyNumber.length !== 2 ||
            luckyNumber[1] === undefined ||
            luckyNumber[1].length !== MATRICULATION_LUCKY_NUMBER_MIN_LENGTH
          ) {
            errors.push(CUSTOMERS_VALIDATION_ERROR_INSURANCE_NUMBER);
          }
        }
        if (errors.length === 0) {
          resolve();
        } else {
          reject(errors);
        }
      } catch (validationException) {
        logger.error(validationException);
        reject([ERROR_DATA_SAVE]);
      }
    });
  };

  handleCustomerSaveClick = () => {
    this.validateNewCustomer().then(
      () => {
        this.setState({
          validMatriculation: true,
          customerUpdatePending: true,
        });
        this.saveCustomer()
          .then(
            (response) => {
              if (response && response.customer && response.customer.personId) {
                this.toast.show({
                  severity: MESSAGE_SEVERITY.SUCCESS,
                  summary: this.props.intl.formatMessage({
                    id: MESSAGE_KEYS.CUSTOMERS_SAVE_SUCCESS_MESSAGE,
                  }),
                });
                this.setState(
                  {
                    customerUpdatePending: false,
                  },
                  () => {
                    this.props.setBaseValue(
                      "appointmentCustomer",
                      response.customer
                    );
                  }
                );
              }
            },
            (error) => {
              logger.error(error);
              this.setState({
                validMatriculation: null,
                customerUpdatePending: false,
              });
              if (this.toast) {
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: this.props.intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                  }),
                });
              }
            }
          )
          .then(() => {
            this.props.handleCustomerSave();
          });
      },
      (errors) => {
        const { intl } = this.props;
        const { ERROR } = MESSAGE_KEYS;
        this.setState({
          validMatriculation: false,
        });
        logger.warn(errors);
        errors.forEach((message) => {
          if (this.toast) {
            this.toast.show({
              severity: MESSAGE_SEVERITY.WARNING,
              summary: intl.formatMessage({
                id: ERROR,
              }),
              detail: intl.formatMessage({ id: message }),
            });
          }
        });
      }
    );
  };

  renderButtonRow = () => {
    const { intl, handleParentUpdate, driveId, handleCustomerSave } =
      this.props;
    const { appointmentDeletePending } = this.state;
    const {
      DIALOG_CONTINUE_BUTTON_LABEL,
      APPOINTMENTS_DELETE_BUTTON_LABEL,
      APPOINTMENTS_SAVE_BUTTON_LABEL,
      APPOINTMENTS_CREATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;

    let saveButtonLabel =
      driveId !== null
        ? APPOINTMENTS_SAVE_BUTTON_LABEL
        : APPOINTMENTS_CREATE_BUTTON_LABEL;
    return (
      <div className={"flex col-12 justify-content-between"}>
        {driveId ? (
          <Button
            label={intl.formatMessage({
              id: APPOINTMENTS_DELETE_BUTTON_LABEL,
            })}
            onClick={this.handleDeleteClick}
            visible={driveId}
            disabled={appointmentDeletePending}
          />
        ) : (
          <></>
        )}

        <Button
          label={intl.formatMessage({ id: saveButtonLabel })}
          onClick={handleCustomerSave}
        />

        <Button
          label={intl.formatMessage({ id: DIALOG_CONTINUE_BUTTON_LABEL })}
          onClick={handleParentUpdate}
        />
      </div>
    );
  };

  render = () => {
    const { intl, customer, driveId } = this.props;
    const { validMatriculation, customerUpdatePending } = this.state;
    const {
      APPOINTMENTS_CUSTOMER_HEADER,
      APPOINTMENTS_DESTINATION_HEADER,
      APPOINTMENTS_DEPARTURE_HEADER,
      CUSTOMERS_CREATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;

    return (
      <div className="grid">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="col-6">
          <Fieldset
            legend={intl.formatMessage({ id: APPOINTMENTS_CUSTOMER_HEADER })}
            style={{ height: "100%" }}
          >
            <CustomerInputForm validMatriculation={validMatriculation} />

            <Divider />
            <AddressInputForm className="mt-2" />
            {driveId ? (
              <div className="flex align-items-center mt-1">
                <Button
                  label={intl.formatMessage({
                    id: CUSTOMERS_CREATE_BUTTON_LABEL,
                  })}
                  disabled={
                    (customer && customer.personId) || customerUpdatePending
                  }
                  onClick={this.handleCustomerSaveClick}
                />
              </div>
            ) : (
              <></>
            )}
          </Fieldset>
        </div>
        <div className="col-6 flex flex-column justify-content-between">
          <Fieldset
            legend={intl.formatMessage({ id: APPOINTMENTS_DEPARTURE_HEADER })}
            className="mb-1"
          >
            <SimpleAddressInputForm isDeparture />
          </Fieldset>
          <Fieldset
            legend={intl.formatMessage({ id: APPOINTMENTS_DESTINATION_HEADER })}
          >
            <SimpleAddressInputForm />
          </Fieldset>
        </div>
        {this.renderButtonRow()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      appWizard: {
        appointmentCustomer,
        appointmentCustomerAddress,
        appointmentDrive,
      },
    } = state;
    return {
      customer: appointmentCustomer,
      customerAddress: appointmentCustomerAddress,
      driveId: appointmentDrive.appointmentId,
    };
  } catch (mapException) {
    logger.error(mapException);
    return {
      customer: null,
      customerAddress: null,
      driveId: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(AppointmentCustomerView)
);
