import React, { useState } from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// Custom components
import { FloatingPassword } from "components/common";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const ChangePasswordDialog = (props) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const intl = useIntl();
  const {
    ACCOUNT_PASSWORD_LABEL,
    ACCOUNT_PASSWORD_REPEAT_LABEL,
    ACCOUNT_SAVE_PASSWORD_LABEL,
    ACCOUNT_FIRST_LOGIN_WARNING,
    ACCOUNT_FIRST_LOGIN_HEADER,
  } = MESSAGE_KEYS;

  const handleSaveClick = () => {
    console.log('Was clicked.');
  };

  const saveButton = (
    <div>
      <Button
        label={intl.formatMessage({ id: ACCOUNT_SAVE_PASSWORD_LABEL })}
        onClick={handleSaveClick}
      />
    </div>
  );

  return (
    <Dialog
      icon="pi pi-exclamation-triangle"
      visible={props.visible}
      closeOnEscape={false}
      closable={false}
      onHide={() => {}}
      header={intl.formatMessage({ id: ACCOUNT_FIRST_LOGIN_HEADER })}
      footer={saveButton}
    >
      <div className="mt-4">
        {intl.formatMessage({ id: ACCOUNT_FIRST_LOGIN_WARNING })}
        <FloatingPassword
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          label={intl.formatMessage({ id: ACCOUNT_PASSWORD_LABEL })}
        />
        <FloatingPassword
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
          label={intl.formatMessage({ id: ACCOUNT_PASSWORD_REPEAT_LABEL })}
        />
      </div>
    </Dialog>
  );
};

export default ChangePasswordDialog;
