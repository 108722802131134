// Redux Tool Kit
import { configureStore } from "@reduxjs/toolkit";
// Redux
import reduxThunk from "redux-thunk";
import reducers from "reducers";
// React offline
import { createOffline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";

const { middleware, enhanceReducer, enhanceStore } =
  createOffline(offlineConfig);
const enhancedReducers = enhanceReducer(reducers);

const storeTK = configureStore({
  reducer: enhancedReducers,
  middleware: [middleware, reduxThunk],
  enhancers: [enhanceStore],
});

export default storeTK;
