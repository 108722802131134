import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending } from "actions/sessionActions";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import {
  initLogger,
  equalObjects,
  hasValueChanged,
  sendQuery,
} from "common/Helpers";
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
  LOCALES,
} from "assets/staticData/enums";
// Logging
const logger = initLogger("payments_edit_view");

const EMPTY_STATE = {
  inputNameDe: "",
  inputNameFr: "",
  inputPrice: 0,
  inputQuantity: 0,
  inputActive: true,

  validName: null,

  updatePending: false,
};

class ProductEditView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {    
    const { value } = this.props;    
    if (!equalObjects(prevProps.value, value, "productId")) {
      logger.info("UPDATE", value);
      this.initInputs(value);
    }
  };

  initInputs = (value) => {
    if (value) {
      const {
        productNameDE,
        productName,
        price,
        active,
        quantity,
      } = this.props.value;
      this.setState({
        inputNameFr: productName ? productName : "",
        inputNameDe: productNameDE ? productNameDE : "",
        inputPrice: price ? price : 0,
        inputActive: active ? true : false,
        inputQuantity: quantity ? quantity : 0,
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  mapInputsToDTO = () => {
    let dto;
    try {
      const {
        inputNameFr,
        inputPrice,
        inputNameDe,
        inputActive,
        inputQuantity,
      } = this.state;
      const {
        value: { productId = null },
      } = this.props;
      dto = {
        quantity: inputQuantity,
        productName: inputNameFr,
        productNameDE: inputNameDe,
        price: inputPrice,
        active: inputActive,
        productId: productId && productId > 0 ? productId : null,
      };
    } catch (mapException) {
      logger.warn(mapException);
      return null;
    } finally {
      return dto;
    }
  };

  /**
   * Checks if input values are valid.
   * Resolves if true, rejects & returns errors else.
   *
   * @returns {Promise}
   */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        const { inputNameFr } = this.state;
        let validName = inputNameFr && inputNameFr !== "" ? true : false;
        if (validName) {
          resolve();
        } else {
          reject([
            this.props.intl.formatMessage({
              id: MESSAGE_KEYS.PRODUCTS_VALIDATION_NAME_REQUIRED,
            }),
          ]);
        }
      } catch (validationException) {
        logger.warn(validationException);
        reject([validationException.message]);
      }
    });
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    try {
      this.validateInputs().then(
        () => {
          let data = this.mapInputsToDTO();
          if (data) {
            sendQuery(QUERIES.EDIT_PAYMENT_PRESET, "post", data).then(
              (response) => {
                if (response) {
                  this.props.handleParentUpdate(response.productId);
                } else {
                  logger.warn("Server response is empty", response);
                  this.toast.show({
                    severity: MESSAGE_SEVERITY.ERROR,
                    summary: intl.formatMessage({
                      id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                    }),
                  });
                }
              },
              (error) => {
                logger.warn(error);
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                  }),
                });
              }
            );
          } else {
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({
                id: MESSAGE_KEYS.ERROR,
              }),
            });
          }
        },
        (errors) => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.WARNING,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.WARNING_VALIDATION_FAILED,
            }),
            detail: errors,
          });
        }
      );
    } catch (saveException) {}
  };

  checkChangePending = (keyValue) => {
    let isPending;
    try {
      const {
        productName,
        productNameDE,
        price,
        active,
        quantity,
      } = this.props.value;
      const {
        inputActive,
        inputNameDe,
        inputNameFr,
        inputPrice,
        inputQuantity,
      } = this.state;

      isPending =
        hasValueChanged(productName, inputNameFr) ||
        hasValueChanged(productNameDE, inputNameDe) ||
        hasValueChanged(price, inputPrice) ||
        hasValueChanged(active, inputActive) ||
        hasValueChanged(quantity, inputQuantity);
    } catch (checkException) {
      logger.warn(checkException, keyValue);
      isPending = true;
    } finally {
      this.props.setChangePending(isPending);
    }
  };

  /**
   * Renders the bottom row of the form.
   * Returns a loading animation if a data transfer is pending, returns a row containing the save- & reset-button else.
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      PRODUCTS_SAVE_BUTTON_LABEL,
      PRODUCTS_CREATE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { intl, value, fetchPending } = this.props;
    const { updatePending, inputActive } = this.state;

    let saveButtonLabel = "";
    if (value && value.productId < 0) {
      saveButtonLabel = intl.formatMessage({
        id: PRODUCTS_CREATE_BUTTON_LABEL,
      });
    } else {
      saveButtonLabel = intl.formatMessage({
        id: PRODUCTS_SAVE_BUTTON_LABEL,
      });
    }
    return (
      <div className="account_button_row">
        <Button
          onClick={() => this.handleSaveClick()}
          label={saveButtonLabel}
          disabled={fetchPending || updatePending}
          icon={updatePending || updatePending ? "pi pi-spin pi-spinner" : ""}
        />

        <ToggleButton
          checked={inputActive ? true : false}
          offIcon="pi pi-times"
          offLabel={isDesktop ?  intl.formatMessage({ id: INACTIVE }) : ""}
          onIcon={"pi pi-check"}
          onLabel={isDesktop ? intl.formatMessage({ id: ACTIVE }) : ""}
          onChange={(e) =>
            this.setState({ inputActive: e.value }, () => {
              this.checkChangePending({ inputActive: e.value });
            })
          }
          disabled={fetchPending || updatePending}
          className={isDesktop ? "" : "ml-1 mr-1"}
        />

        <Button
          onClick={() => this.initInputs()}
          label={intl.formatMessage({
            id: RESET_VALUES,
          })}
          className="p-button-warning"
          disabled={fetchPending || updatePending}
        />
      </div>
    );
  };

  renderInputs = () => {
    const {
      PRODUCTS_QUANTITY_LABEL,
      PRODUCTS_NAME_LABEL,
      PRODUCTS_PRICE_LABEL,
    } = MESSAGE_KEYS;
    const { GERMAN, FRENCH } = LOCALES;
    const { intl, fetchPending } = this.props;
    const {
      inputNameDe,
      inputNameFr,
      inputPrice,
      inputQuantity,
      validName,
      updatePending,
    } = this.state;
    let content;
    try {
      content = (
        <div>
          <div className={`flex   mb-4 ${isDesktop ? "flex-row mt-4 justify-content-between align-items-end" : "flex-column"}`}>
            <FloatingTextInput
              value={inputNameFr}
              onChange={(e) => {
                this.setState(
                  {
                    inputNameFr: e.target.value,
                  },
                  () => {
                    this.checkChangePending({ inputNameFr: e.target.value });
                  }
                );
              }}
              label={`${intl.formatMessage({
                id: PRODUCTS_NAME_LABEL,
              })} (${intl.formatMessage({ id: FRENCH.key })})`}
              valid={validName}
              disabled={fetchPending || updatePending}
              className="mr-2"
            />
            <FloatingTextInput
              value={inputNameDe}
              onChange={(e) => {
                this.setState(
                  {
                    inputNameDe: e.target.value,
                  },
                  () => {
                    this.checkChangePending({ inputNameDe: e.target.value });
                  }
                );
              }}
              label={`${intl.formatMessage({
                id: PRODUCTS_NAME_LABEL,
              })} (${intl.formatMessage({ id: GERMAN.key })})`}
              className="mr-2"
              disabled={fetchPending || updatePending}
            />
          </div>
          <div className={`flex   mb-4 ${isDesktop ? "flex-row mt-4 justify-content-between align-items-end" : "flex-column"}`}>
            <span className="p-float-label">
              <InputNumber
                id={PRODUCTS_QUANTITY_LABEL}
                value={inputQuantity}
                onValueChange={(event) =>
                  this.setState({ inputQuantity: event.target.value }, () => {
                    this.checkChangePending({
                      inputQuantity: event.target.value,
                    });
                  })
                }
                onFocus={(e) => e.target.select()}
                useGrouping={false}
                disabled={fetchPending || updatePending}
              />
              <label htmlFor={PRODUCTS_QUANTITY_LABEL}>
                {intl.formatMessage({ id: PRODUCTS_QUANTITY_LABEL })}
              </label>
            </span>
            <span className={`p-float-label ${isDesktop ? "" : "mt-4"}`}>
              <InputNumber
                id={PRODUCTS_PRICE_LABEL}
                value={inputPrice}
                onValueChange={(event) =>
                  this.setState({ inputPrice: event.target.value }, () => {
                    this.checkChangePending({ inputPrice: event.target.value });
                  })
                }
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="€"
                onFocus={(e) => e.target.select()}
                useGrouping={false}
                disabled={fetchPending || updatePending}
                locale="en-US"
              />
              <label htmlFor={PRODUCTS_PRICE_LABEL}>
                {intl.formatMessage({ id: PRODUCTS_PRICE_LABEL })}
              </label>
            </span>
          </div>
          {this.renderButtonRow()}
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      content = (
        <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>
      );
    } finally {
      return content;
    }
  };

  renderContent = () => {
    const { intl } = this.props;
    const { updatePending } = this.state;
    let content;
    try {
      if (updatePending) {
        content = <ProgressSpinner />;
      } else {
        content = this.renderInputs();
      }
    } catch (renderException) {
      logger.warn(renderException);
      content = (
        <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>
      );
    } finally {
      return content;
    }
  };

  render = () => {
    const { value, intl } = this.props;
    const {
      PRODUCTS_NEW_ENTRY_TITLE,
      PRODUCTS_EDIT_ENTRY_TITLE,
      PRODUCTS_NO_SELECTION_TITLE,
    } = MESSAGE_KEYS;
    if (value !== null) {
      let panelHeader =
        value.productId >= 0
          ? intl.formatMessage(
              { id: PRODUCTS_EDIT_ENTRY_TITLE },
              { name: value.productName }
            )
          : intl.formatMessage({ id: PRODUCTS_NEW_ENTRY_TITLE });
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <Panel header={panelHeader}>{this.renderContent()}</Panel>
        </div>
      );
    } else {
      return (
        <Card>{intl.formatMessage({ id: PRODUCTS_NO_SELECTION_TITLE })}</Card>
      );
    }
  };
}

export default connect(null, { setChangePending })(injectIntl(ProductEditView));
