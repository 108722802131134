import { MOBILE_SET_DAY, MOBILE_SET_USER, MOBILE_SET_ALL_DAYS } from "./types";

export const updateDay = (key, value) => {
  return {
    type: MOBILE_SET_DAY,
    payload: { key, value },
  };
};

export const updateUserId = (userId) => {
  return {
    type: MOBILE_SET_USER,
    payload: userId,
  };
};

export const updateAllDays = (allDays) => {
  return {
    type: MOBILE_SET_ALL_DAYS,
    payload: allDays,
  };
};
