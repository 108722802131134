import { MESSAGE_KEYS } from "assets/staticData/enums";
import { dateToString, initLogger, numberToPriceString } from "common/Helpers";
import React from "react";
import { injectIntl } from "react-intl";

// Logging
const logger = initLogger("bills_view");

const BillFilterToolTip = (props) => {
  const { intl, rowData } = props;
  const {
    BILLS_DEPARTURE_LABEL,
    BILLS_DESTINATION_LABEL,
    BILLS_POSITIONS_AMOUNT_LABEL,
    BILLS_COMMENT_INTERNAL_LABEL,
    BILLS_COMMENT_EXTERNAL_LABEL,

    BILLS_TRANSPORT_DATE_LABEL,

    BILLS_VALIDATION_WARNING_NO_FILE,

    BILLS_REMINDER_UPDATE_LABEL,

    CREATED_BY,
    CREATED_ON,
    EDITED_BY,
    EDITED_ON,
  } = MESSAGE_KEYS;
  const {
    transportDate,
    fromAddress,
    toAddress,
    totalPrice,
    info,
    remark,
    lastmodifyUserName,
    lastModifyDate,
    createDate,
    createUserName,
    hasFilearchive,
    reminderLastUpdate,
  } = rowData;
  try {
    return (
      <div>
        <div className="flex justify-content-space">
          {`${intl.formatMessage({ id: BILLS_POSITIONS_AMOUNT_LABEL })}: `}
          <strong>{`${
            totalPrice ? numberToPriceString(totalPrice) : "-"
          }`}</strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: BILLS_TRANSPORT_DATE_LABEL })}: `}
          <strong>
            {lastModifyDate ? dateToString(new Date(transportDate), "/") : "-"}
          </strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: BILLS_DEPARTURE_LABEL })}: `}
          <strong>{`${fromAddress ? fromAddress : "-"}`}</strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: BILLS_DESTINATION_LABEL })}: `}
          <strong>{`${toAddress ? toAddress : "-"}`}</strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}: `}
          <strong>{`${remark ? remark.replace("€", "\u20ac") : "-"}`}</strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: BILLS_COMMENT_EXTERNAL_LABEL })}: `}
          <strong>{`${info ? info.replace("€", "\u20ac") : "-"}`}</strong>
        </div>
        <div>
          {intl.formatMessage({ id: EDITED_BY })}
          <strong>{lastmodifyUserName ? lastmodifyUserName : "-"}</strong>
        </div>
        <div>
          {intl.formatMessage({ id: EDITED_ON })}
          <strong>
            {lastModifyDate ? dateToString(new Date(lastModifyDate), "/") : "-"}
          </strong>
        </div>
        <div className="flex align-items-center">
          {intl.formatMessage({ id: CREATED_BY })}
          <strong>{createUserName ? createUserName : ""}</strong>
        </div>
        <div>
          {`${intl.formatMessage({ id: CREATED_ON })} `}
          <strong>
            {createDate ? dateToString(new Date(createDate), "/") : "-"}
          </strong>
        </div>
        {!hasFilearchive && (
          <div>
            <strong>
              {intl.formatMessage({ id: BILLS_VALIDATION_WARNING_NO_FILE })}
            </strong>
          </div>
        )}
        {reminderLastUpdate && (
          <div>
            {`${intl.formatMessage({ id: BILLS_REMINDER_UPDATE_LABEL })} `}{" "}
            <strong>
              {createDate
                ? dateToString(new Date(reminderLastUpdate), "/")
                : "-"}
            </strong>
          </div>
        )}
      </div>
    );
  } catch (renderException) {
    logger.warn(
      "Exception on generateTooltipContent",
      renderException,
      rowData
    );
    return <div>-</div>;
  }
};

export default injectIntl(BillFilterToolTip);
