import React, { useEffect } from "react";
// React redux
import { useDispatch } from "react-redux";
// PrimeReact imports
import RegularsTable from "./components/RegularsTable";
// Static values
import { setTopbarTitle } from "actions/index";
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { useIntl } from "react-intl";


const RegularsView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {    
    dispatch(setTopbarTitle(intl.formatMessage({id:MESSAGE_KEYS.MENU_REGULARS})));
  }, [dispatch, intl]);

  return (
    <div>
      <RegularsTable />
    </div>
  );
};

export default RegularsView;
