import React from "react";
// React redux
import { connect } from "react-redux";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { ToggleButton } from "primereact/togglebutton";
import { Divider } from "primereact/divider";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
// Custom components
import { SplitDateTimeInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, LOCALES } from "assets/staticData/enums";
// Helper functions
import { initLogger, getCurrentUserLocale, valiDate } from "common/Helpers";

import "./Style.scss";
import { FloatingTextInput } from "components/common/index";

const logger = initLogger("oncall_event_popup");
const EMPTY_STATE = {
  inputSelection: [],
  inputSource: [],
  inputLimit: 0,
  inputAddMe: false,

  inputType: null,
  inputDate: new Date(),

  title: "",

  isSpecial: false,

  inputSpecialTitle: "",
  validType: true,
};
const SPECIAL_TYPE_ID = 10;

class OnCallEventPopup extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  initInputs = () => {
    const { value, currentUser, onCallTypes } = this.props;

    if (value) {
      try {
        const {
          _def: {
            title,
            extendedProps: { members, limit, appointmentTypeId, description },
          },
        } = value;

        let inputAddMe = false;
        if (members) {
          inputAddMe = members.some(
            (user) => user.personId === currentUser.personId
          );
        }

        let titleAppendix = "";
        if (
          appointmentTypeId === SPECIAL_TYPE_ID &&
          description &&
          description !== "Standby"
        ) {
          titleAppendix = ` - ${description}`;
        }

        this.setState({
          title: `${title}${titleAppendix}`,
          inputSelection: members,
          inputLimit: limit,
          inputAddMe,
          inputSource: this.normalizeSource(members),
          inputType: onCallTypes.find(
            (callType) => appointmentTypeId === callType.appointmentTypeId
          ),

          isSpecial: appointmentTypeId === SPECIAL_TYPE_ID,
          inputSpecialTitle: description,
        });
      } catch (initException) {
        logger.warn(initException);
        this.setState({
          ...EMPTY_STATE,
        });
      }
    } else {
      this.setState({
        ...EMPTY_STATE,
        inputSource: this.normalizeSource([]),
      });
    }
  };

  normalizeSource = (members) => {
    let inputSource;
    try {
      const { drivers } = this.props;
      let selectionIds = [];
      members.forEach((member) => {
        selectionIds.push(member.personId);
      });
      inputSource = [...members];
      drivers.forEach((driver) => {
        if (!selectionIds.includes(driver.personId)) {
          inputSource.push({
            personId: driver.personId,
            shortname: driver.alias,
          });
        }
      });
      return inputSource;
    } catch (normalException) {
      return [...members];
    }
  };

  handleMemberToggle = (isRegistred) => {
    const {
      currentUser: { personId, alias },
    } = this.props;
    let inputSelection = [...this.state.inputSelection];
    if (isRegistred) {
      inputSelection.push({ personId, alias });
    } else {
      let searchIndex = inputSelection.findIndex((member) => {
        return member.personId === personId;
      });
      if (searchIndex >= 0) {
        inputSelection.splice(searchIndex, 1);
      }
    }
    this.setState({
      inputAddMe: isRegistred,
      inputSelection,
    });
  };

  validateInputs = () => {
    const { inputType } = this.state;
    let validType = inputType !== null && inputType !== undefined;
    this.setState({ validType });
    return validType;
  };

  renderAdminInput = () => {
    const { onCallTypes, intl } = this.props;
    const {
      PAYMENTS_DATE_LABEL,
      ON_CALLS_NUMBER_SLOTS_LABEL,
      SHIFTS_MEMBERS_PLACEHOLDER,
      MENU_ON_CALL,
    } = MESSAGE_KEYS;
    const {
      inputLimit,
      inputSelection,
      inputSource,
      inputType,
      inputDate,
      validType,
    } = this.state;
    return (
      <div className="flex flex-column">
        {!isDesktop ? (
          <SplitDateTimeInput
            value={inputDate}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputDate: newDate });
            }}
            label={intl.formatMessage({
              id: PAYMENTS_DATE_LABEL,
            })}
            className="mb-4"
          />
        ) : (
          <></>
        )}
        <div
          className={
            isDesktop ? "" : "flex p-flex-row align-items-end mb-4 justify-content-between"
          }
          style={{ maxWidth: "100%" }}
        >
          <div className={isDesktop ? "mr-2 mb-4" : ""}>
            <Dropdown
              value={inputType}
              options={onCallTypes}
              optionLabel={
                getCurrentUserLocale() === LOCALES.GERMAN.key
                  ? "name"
                  : "nameFr"
              }
              onChange={(e) => {
                this.setState({ inputType: e.value });
              }}
              style={{ width: "100%" }}
              className={validType ? "" : "p-invalid"}
              placeholder={intl.formatMessage({ id: MENU_ON_CALL })}
            />
          </div>
          <span className={`p-float-label ${isDesktop ? "mb-4" : ""}`}>
            <InputNumber
              id="inp_limit"
              value={inputLimit}
              onChange={(e) => {
                this.setState({
                  inputLimit: e.value,
                });
              }}
              min={0}
              style={{ width: "100%" }}
            />
            <label htmlFor="inp_limit">
              {intl.formatMessage({ id: ON_CALLS_NUMBER_SLOTS_LABEL })}
            </label>
          </span>
        </div>
        <MultiSelect
          optionLabel="shortname"
          value={inputSelection}
          options={inputSource}
          onChange={(e) => this.setState({ inputSelection: e.value })}
          filter
          filterBy={"shortname"}
          placeholder={intl.formatMessage({ id: SHIFTS_MEMBERS_PLACEHOLDER })}
        />
        {this.renderOverbookingWarning()}
      </div>
    );
  };

  renderOverbookingWarning = () => {
    const { intl } = this.props;
    const { ON_CALLS_LOW_SLOTS_WARNING, ERROR_RENDER } = MESSAGE_KEYS;
    const { inputLimit, inputSelection } = this.state;

    try {
      if (inputLimit < inputSelection.length) {
        return (
          <div className="mt-2 flex flex-row align-items-center">
            <i className="pi pi-exclamation-triangle mr-2" />{" "}
            <div>{intl.formatMessage({ id: ON_CALLS_LOW_SLOTS_WARNING })}</div>
          </div>
        );
      } else {
        return <></>;
      }
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderUserInput = () => {
    const { intl } = this.props;
    const {
      ON_CALLS_REGISTER_BUTTON_LABEL,
      ON_CALLS_UNREGISTER_BUTTON_LABEL,
      ON_CALLS_SHIFT_FULL_LABEL,
    } = MESSAGE_KEYS;
    const { inputAddMe, inputLimit, inputSelection } = this.state;
    let offLabel = intl.formatMessage({
      id:
        inputLimit === inputSelection.length
          ? ON_CALLS_SHIFT_FULL_LABEL
          : ON_CALLS_UNREGISTER_BUTTON_LABEL,
    });
    if (!inputAddMe && inputLimit === inputSelection.length) {
      return <strong>{offLabel}</strong>;
    } else {
      return (
        <div>
          <ToggleButton
            checked={inputAddMe ? true : false}
            offLabel={offLabel}
            onLabel={intl.formatMessage({
              id: ON_CALLS_REGISTER_BUTTON_LABEL,
            })}
            onChange={(e) => {
              this.handleMemberToggle(e.value);
            }}
            offIcon="pi pi-times"
            onIcon="pi pi-check"
          />
        </div>
      );
    }
  };

  renderTitle = () => {
    const { intl, canEdit } = this.props;
    const { title, isSpecial, inputSpecialTitle } = this.state;
    if (canEdit && isSpecial) {
      return (
        <div className="mb-4">
          <div className="font-bold mb-4">{title}</div>
          <FloatingTextInput
            value={inputSpecialTitle}
            onChange={(e) =>
              this.setState({ inputSpecialTitle: e.target.value })
            }
            label={intl.formatMessage({
              id: MESSAGE_KEYS.CUSTOMERS_TITLE_LABEL,
            })}
          />
        </div>
      );
    } else {
      return <div className="mb-4 font-bold">{title}</div>;
    }
  };

  renderSelection = () => {
    const { intl, canEdit } = this.props;
    const { ERROR_RENDER, ON_CALLS_SHIFT_EMPTY_LABEL, ON_CALLS_DRIVERS_TITLE } =
      MESSAGE_KEYS;
    let content;
    try {
      const { inputSelection } = this.state;
      if (inputSelection && inputSelection.length > 0) {
        content = [
          <h5 key="memberlist_title">
            {intl.formatMessage({ id: ON_CALLS_DRIVERS_TITLE })}
          </h5>,
        ];
        inputSelection.forEach((member) => {
          const { alias, shortname, confirmed, personId } = member;
          let memberName = "";
          if (alias) {
            memberName = alias;
          } else if (shortname) {
            memberName = shortname;
          }          
          content.push(
            <div
              className="mb-1 flex p-flex-row justify-content-between align-items-center"
              key={member.personId}
            >
              <div>
                <strong>{memberName}</strong>
                {confirmed && (
                  <div className="font-light" style={{ fontSize: "small" }}>
                    {intl.formatMessage({
                      id: MESSAGE_KEYS.ON_CALLS_CONFIRMED,
                    })}
                  </div>
                )}
              </div>
              {canEdit && (
                <div className="flex">
                  <ToggleButton
                    className="p-button-rounded p-button-sm m-1"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    onLabel=""
                    offLabel=""
                    checked={confirmed}
                    onChange={(e) => {
                      let newSelection = [...inputSelection];
                      const searchIndex = newSelection.findIndex(
                        (member) => member.personId === personId
                      );
                      if (searchIndex >= 0) {
                        newSelection[searchIndex].confirmed = e.value;
                      }
                      this.setState({
                        inputSelection: newSelection,
                      });
                    }}
                  />

                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-sm m-1"
                    onClick={() => {
                      let newSelection = inputSelection.filter(
                        (member) => member.personId !== personId
                      );
                      this.setState({
                        inputSelection: newSelection,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          );
        });
        return content;
      } else {
        return (
          <div>{intl.formatMessage({ id: ON_CALLS_SHIFT_EMPTY_LABEL })}</div>
        );
      }
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderContent = () => {
    const { intl, handleSaveClick, handleCancel, canEdit } = this.props;
    const {
      ERROR_RENDER,
      ON_CALLS_SLOTS_OCCUPIED_LABEL,
      ON_CALLS_EDIT_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    try {
      const {
        inputSelection,
        inputLimit,
        inputType,
        inputDate,
        inputSpecialTitle,
      } = this.state;
      const totalConfirmed = inputSelection.filter(
        (member) => member.confirmed === true
      ).length;
      return (
        <div style={{ minWidth: "400px" }}>
          {this.renderTitle()}
          <div className={isDesktop ? "p-grid" : "mb-2"}>
            <div className={isDesktop ? "p-col-5" : ""}>
              {canEdit ? this.renderAdminInput() : this.renderUserInput()}
            </div>
            <div className={isDesktop ? "p-col-7" : ""}>
              <div>
                {intl.formatMessage(
                  { id: ON_CALLS_SLOTS_OCCUPIED_LABEL },
                  {
                    members: totalConfirmed,
                    limit: inputLimit ? inputLimit : 0,
                  }
                )}
              </div>
              <Divider />
              {this.renderSelection()}
            </div>
          </div>
          <div className="flex p-flex-row justify-content-between">
            <Button
              onClick={() => {
                if (this.validateInputs()) {
                  handleSaveClick({
                    state: inputType,
                    start: inputDate,
                    members: inputSelection,
                    limit: inputLimit ? inputLimit : 0,
                    description: inputSpecialTitle,
                    placesUsed: totalConfirmed,
                  });
                }
              }}
              label={intl.formatMessage({ id: ON_CALLS_EDIT_BUTTON_LABEL })}
            />
            <Button
              onClick={handleCancel}
              label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
            />
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    return this.renderContent();
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      persist: { onCallTypes },
    } = state;

    return {
      currentUser,
      onCallTypes,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      currentUser: null,
      onCallTypes: [],
    };
  }
};

export default connect(mapStateToProps, {})(injectIntl(OnCallEventPopup));
