import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// Helper functions
import {
  changePendingConfirm,
  safeRender,
  sendQuery,
  initLogger,
  dateToBillNumber,
} from "common/Helpers";
// Generate download dialog
import download from "downloadjs";
// Localization
import { injectIntl } from "react-intl";
// JSDoc && autocomplete
import PropTypes from "prop-types";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
const logger = initLogger("bill_file_editor");

const EMPTY_STATE = {
  pdfFetchPending: false,
  warningFetchPending: false,
};

const MAX_FILE_SIZE = 10000000;

/**
 * This class renders the file archive management component for bill or customer uploads.
 *
 * @version 1.0
 * @author [Ian Husting]
 *
 * @augments {Component<Props, State>}
 */
class FilearchiveEditor extends React.Component {
  state = { ...EMPTY_STATE };
  componentDidMount = () => {
    this.uploadRef = React.createRef();
  };
  /**
   * If available, will send a BE call that downloads and merges all additional file archives of the selected bill into a single PDF file.
   */
  handleMergedDownloadButtonClick = () => {
    const { value, idLabel } = this.props;
    try {
      sendQuery(
        `${QUERIES.GET_MERGED_FILES}${idLabel}=${value[idLabel]}`,
        "get",
        null,
        "blob"
      ).then(
        (response) => {
          if (response) {
            let fileName;
            try {
              const { invoiceNumber = 1, orderDate = null } = value;
              fileName = `${invoiceNumber}-${new Date(
                orderDate
              ).getFullYear()}-annex.pdf`;
            } catch (dumException) {
              fileName = "bills_merged.pdf";
              logger.warn(
                "Exception on name generation",
                dumException,
                this.state
              );
            }
            download(response, fileName, "application/pdf");
          } else {
            logger.warn("No merged document found for ", value[idLabel]);
          }
        },
        (error) => {
          logger.warn("Could not download merged file", value[idLabel], error);
          try {
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_FETCH,
              }),
            });
          } catch (toastException) {
            logger.warn(toastException);
          }
        }
      );
    } catch (downloadException) {
      logger.warn(
        "Exception on handle merged download button click",
        downloadException
      );
      try {
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage({
            id: MESSAGE_KEYS.ERROR_DATA_FETCH,
          }),
        });
      } catch (toastException) {
        logger.warn(toastException);
      }
    }
  };
  /**
   * Prompts the user to confirm file archive deletion.
   * If the user confirms, the BE query to delete the file archive will be called.
   *
   * @param {File} file
   */
  handleFileDeleteButtonClick = (file) => {
    if (file && file.filearchiveId) {
      changePendingConfirm(
        file,
        this.callDeleteFile,
        this.props.intl,
        file.name
      );
    }
  };
  /**
   * Sends a request to the BE to delete the selected file archive.
   * Updates parent component on success, displays error message else.
   *
   * @param {File} file
   */
  callDeleteFile = (file) => {
    const { SUCCESS, ERROR } = MESSAGE_SEVERITY;
    const { intl, updateParent } = this.props;
    const { BILLS_FILES_REMOVE_SUCCESS } = MESSAGE_KEYS;
    try {
      const { filearchiveId } = file;
      sendQuery(`${QUERIES.REMOVE_BILL_FILE}${filearchiveId}`, "get").then(
        (response) => {
          const { value } = this.props;
          let newFileList = [...value.filearchive];
          let deleteIndex = newFileList.findIndex((delFile) => {
            return delFile.filearchiveId === file.filearchiveId;
          });
          if (deleteIndex >= 0) {
            newFileList.splice(deleteIndex, 1);
          }
          updateParent([...newFileList]);
          try {
            this.toast.show({
              severity: SUCCESS,
              summary: intl.formatMessage({
                id: BILLS_FILES_REMOVE_SUCCESS,
              }),
            });
          } catch (toastException) {
            logger.warn(toastException);
          }
        },
        (error) => {
          logger.warn("Could not download file", file, error);
          try {
            this.toast.show({
              severity: ERROR,
              summary: intl.formatMessage({
                id: MESSAGE_KEYS.ERROR,
              }),
            });
          } catch (toastException) {
            logger.warn(toastException);
          }
        }
      );
    } catch (downloadException) {
      logger.warn(
        "Exception on handle download button click",
        downloadException
      );
      try {
        this.toast.show({
          severity: ERROR,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.ERROR,
          }),
        });
      } catch (toastException) {
        logger.warn(toastException);
      }
    }
  };
  /**
   * Calls the BE to download the selected additional file archive.
   *
   * @param {File} file
   */
  handleFileDownloadButtonClick = (file) => {
    if (file) {
      const { relativePath = null, name = null } = file;
      if (relativePath && name) {
        try {
          sendQuery(
            `${QUERIES.GET_FILE}filename=${name}&folder=${relativePath}`,
            "get",
            null,
            "blob"
          ).then(
            (response) => {
              if (response) {
                download(response, file.name, "application/pdf");
              } else {
                logger.warn("No document found for ", file);
              }
            },
            (error) => {
              logger.warn("Could not download file", file, error);
            }
          );
        } catch (downloadException) {
          logger.warn(
            "Exception on handle download button click",
            downloadException
          );
        }
      }
    }
  };
  /**
   * Unfinished function call to generate a report and send it to the stored email address.
   */
  handleReportButtonSendClick = () => {
    const { value, idLabel } = this.props;
    try {
      let data = {
        to: ["dev@eye-t.lu"],
        cc: ["dev@eye-t.lu"],
        subject: "subject",
        body: "body",
        ids: [value[idLabel]],
      };

      this.setState(
        {
          pdfFetchPending: true,
        },
        () =>
          sendQuery(QUERIES.SEND_BILL_REPORT, "POST", data).then(
            (response) => {
              try {
                if (response) {
                  logger.info("email send:", value[idLabel]);
                }
                this.setState({
                  pdfFetchPending: false,
                });
              } catch (generatorException) {
                logger.warn("Exception on send Email", generatorException);
                this.setState({
                  pdfFetchPending: false,
                });
              }
            },
            (error) => {
              logger.warn(error);
              this.setState({
                pdfFetchPending: false,
              });
            }
          )
      );
    } catch (reportException) {
      logger.warn("Exception on handleBillButtonClick", reportException);
      this.setState({
        pdfFetchPending: false,
      });
    }
  };

  /**
   * Sends a BE request to download the bill or warning pdf, depending on the flag.
   *
   * @param {Boolean} billDownload True to receive the bill pdf, false to receive the warning pdf.
   */
  handlePDFButtonClick = (billDownload) => {
    const { idLabel, value, intl } = this.props;
    const { orderDate, invoiceNumber } = value;
    const { ERROR_NO_DATA } = MESSAGE_KEYS;
    let propertyKey = billDownload ? "pdfFetchPending" : "warningFetchPending";
    try {
      this.setState(
        {
          [propertyKey]: true,
        },
        () =>
          sendQuery(
            `${QUERIES.GET_WARNING_REPORT}${value[idLabel]}&state=${
              billDownload ? "1" : "4"
            }`,
            "GET",
            null,
            "blob"
          ).then(
            (response) => {
              try {
                if (response) {
                  download(
                    response,
                    `${
                      billDownload ? "facture" : "avertissement"
                    }-${dateToBillNumber(
                      orderDate ? new Date(orderDate) : new Date()
                    )}-${invoiceNumber}.pdf`,
                    "application/pdf"
                  );
                } else {
                  logger.warn("No document found for ", value[idLabel]);
                }
                this.setState({
                  [propertyKey]: false,
                });
              } catch (generatorException) {
                logger.warn(
                  "Exception on handleBillButtonClick at document generation",
                  generatorException
                );
                this.setState({
                  [propertyKey]: false,
                });
                try {
                  this.toast.show({
                    severity: MESSAGE_SEVERITY.ERROR,
                    summary: intl.formatMessage({
                      id: ERROR_NO_DATA,
                    }),
                  });
                } catch (toastException) {
                  logger.warn(toastException);
                }
              }
            },
            (error) => {
              logger.warn(error);
              this.setState({
                [propertyKey]: false,
              });
              try {
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: intl.formatMessage({
                    id: ERROR_NO_DATA,
                  }),
                });
              } catch (toastException) {
                logger.warn(toastException);
              }
            }
          )
      );
    } catch (reportException) {
      logger.warn("Exception on handlePDFButtonClick", reportException);
      this.setState({
        [propertyKey]: false,
      });
    }
  };

  /**
   * Clears all special characters from the file name to prevent error down the line.
   * Generates a random file name for files consisting of special characters only.
   *
   * @param {String} fileName
   * @returns {String} File name without special characters
   */
  sanitizeFileName = (fileName) => {
    let purged = "";
    try {
      purged = fileName.replace(/[^a-zA-Z0-9\-_.]/g, "");
      // Check if the purged name is not the extension only.
      if (purged === ".pdf") {
        throw new Error(`Invalid filename ${fileName}.`);
      } else {
        return purged;
      }
    } catch (sanitizeException) {
      // Could not sanitize or no name remained, generate random name instead.
      logger.info(sanitizeException);
      return `la_${new Date().getTime()}.pdf`;
    }
  };

  /**
   * Handles the upload of the selected files.
   *
   * @param {Event} uploadEvent
   */
  handleFileUpload = (uploadEvent) => {
    const { intl, value, updateParent, idLabel, isBill } = this.props;
    const { BILLS_FILES_ERROR_UPLOAD } = MESSAGE_KEYS;
    const { UPLOAD_BILL_FILE, UPLOAD_CUSTOMER_FILE } = QUERIES;
    const { ERROR } = MESSAGE_SEVERITY;
    try {
      const today = new Date();
      // Upload-deviation.
      const pathPrefix = `${today.getFullYear()}/${today.getMonth()}/`;
      let data = new FormData();
      uploadEvent.files.forEach((f) => {
        data.append("files", f, this.sanitizeFileName(f.name));
      });
      let queryURL = `${
        isBill ? UPLOAD_BILL_FILE : UPLOAD_CUSTOMER_FILE
      }${pathPrefix}${value[idLabel]}&${idLabel}=${value[idLabel]}`;
      this.setState({
        fileUploadPending: true,
      });
      sendQuery(queryURL, "post", data).then(
        (response) => {
          if (this.uploadRef && this.uploadRef.current) {
            this.uploadRef.current.clear();
          }
          this.setState({
            fileUploadPending: false,
          });
          updateParent(
            response && response.fileArchiveList
              ? [...response.fileArchiveList]
              : []
          );
        },
        (error) => {
          logger.warn("UPLOAD FAILED", error);
          try {
            this.toast.current.show({
              severity: ERROR,
              summary: intl.formatMessage({
                id: BILLS_FILES_ERROR_UPLOAD,
              }),
            });
            this.setState({
              fileUploadPending: false,
            });
          } catch (toastException) {
            logger.warn(toastException);
          }
        }
      );
    } catch (uploadException) {
      logger.warn("Exception on uploadhandler", uploadException);
    }
  };
  /**
   * Renders the email button. Clicking the button will send the email request and disables the button for the duration of the request.
   * As this function is not yet implemented on the BE, the button is currently completly disabled.
   *
   * @returns {JSX.Element}
   */
  renderEmailButton = () => {
    const { intl } = this.props;
    //const { value } = this.state;
    const { BILLS_FILES_SEND_LABEL } = MESSAGE_KEYS;
    return (
      <Button
        icon="pi pi-envelope"
        onClick={this.handleReportButtonSendClick}
        className="mb-1"
        label={intl.formatMessage({ id: BILLS_FILES_SEND_LABEL })}
        disabled={
          /*!(
                value &&
                value.customer &&
                value.customer.email &&
                value.customer.email !== ""
              ) || (!value || value < 0)*/ true // TODO Undo this when email server is available.
        }
      />
    );
  };
  /**
   * This function renders the bill/warning download button.
   * This button sends a BE request to download the respective PDF file. It will be disabled for the duration of the request.
   *
   * @param {Boolean} billDownload Set to true to render the bill button, false to render the warning button.
   * @returns {JSX.Element}
   */
  renderDownloadButton = (billDownload = true) => {
    const { intl, value } = this.props;
    const { BILLS_FILES_REPORT_LABEL, BILLS_FILES_WARNING_LABEL } =
      MESSAGE_KEYS;
    const { pdfFetchPending, warningFetchPending } = this.state;

    let fetchPending = billDownload ? pdfFetchPending : warningFetchPending;
    let label = billDownload
      ? BILLS_FILES_REPORT_LABEL
      : BILLS_FILES_WARNING_LABEL;

    return (
      <Button
        icon={`pi ${fetchPending ? "pi-spin pi-spinner" : "pi-download"}`}
        label={intl.formatMessage({ id: label })}
        disabled={fetchPending || !value || value < 0}
        onClick={() => this.handlePDFButtonClick(billDownload)}
        className="mb-1"
      />
    );
  };
  /**
   * Renders the list of additional file archives.
   * Each row contains the file name as well as a delete- and download-button.
   * Returns an empty element if no additional file archive exists.
   *
   * @returns {Array<JSX.Element>|JSX.Element} List of file archives if available, an empty JSX element else.
   */
  renderFilesList = () => {
    let fileList;
    const { intl, value } = this.props;
    if (value) {
      try {
        const {
          value: { filearchive = null },
        } = this.props;
        if (filearchive && filearchive.length > 0) {
          if (filearchive.length > 1) {
            fileList = [
              <Button
                key="btn_files_merge"
                className="mt-2"
                icon="pi pi-angle-double-down"
                label={intl.formatMessage({
                  id: MESSAGE_KEYS.BILLS_FILES_MERGE_LABEL,
                })}
                onClick={this.handleMergedDownloadButtonClick}
              />,
            ];
          } else {
            fileList = [];
          }
          filearchive.forEach((file) => {
            fileList.push(
              <div
                className="flex align-items-center justify-content-between mt-2 p-2"
                key={`add_file_${file.filearchiveId}`}
              >
                <div className="flex align-items-center">
                  <Button
                    className="p-button-rounded mr-1"
                    icon="pi pi-download"
                    onClick={() => {
                      this.handleFileDownloadButtonClick(file);
                    }}
                  />
                  <span className="font-light">{file.name}</span>
                </div>

                <Button
                  className="p-button-rounded p-button-danger mr-1"
                  icon="pi pi-trash"
                  onClick={() => {
                    this.handleFileDeleteButtonClick(file);
                  }}
                />
              </div>
            );
          });
        }
      } catch (renderException) {
        logger.warn("Exception on render files list", renderException);
        fileList = <></>;
      } finally {
        return fileList;
      }
    } else {
      return <></>;
    }
  };
  /**
   * Renders the file upload component and calls the functions to render the buttons and file list.
   *
   * @returns {JSX.Element}
   */
  renderContent = () => {
    const { intl, isLandscape, isBill } = this.props;
    const isFullHD = window.innerWidth >= 1920;
    const {
      BILLS_FILES_CHOOSE_LABEL,
      BILLS_FILES_UPLOAD_LABEL,
      DIALOG_CANCEL_SHORT_BUTTON_LABEL,
      BILLS_FILES_ERROR_SIZE_DETAIL,
      BILLS_FILES_BILL_PDF_TITLE,
      BILLS_FILES_ADDITIONAL_FILES_TITLE,
    } = MESSAGE_KEYS;
    const { fileUploadPending } = this.state;
    let isResponsive = !isDesktop && !isLandscape;
    return (
      <div>
        {isBill ? (
          <div>
            <div className="mb-2 font-bold">
              {intl.formatMessage({ id: BILLS_FILES_BILL_PDF_TITLE })}
            </div>
            <div
              className={`flex justify-content-between mb-2 ${
                isFullHD ? "flex-row" : "flex-column"
              }`}
            >
              {this.renderDownloadButton()}
              {this.renderDownloadButton(false)}
              {this.renderEmailButton()}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="mb-2 font-bold">
          {intl.formatMessage({ id: BILLS_FILES_ADDITIONAL_FILES_TITLE })}
        </div>
        <FileUpload
          ref={this.uploadRef}
          className={isResponsive ? "resp_upload" : ""}
          name="bill_file_upload"
          customUpload
          multiple
          disabled={fileUploadPending}
          accept="application/pdf"
          maxFileSize={MAX_FILE_SIZE}
          uploadHandler={this.handleFileUpload}
          chooseLabel={intl.formatMessage({ id: BILLS_FILES_CHOOSE_LABEL })}
          uploadLabel={
            isResponsive
              ? ""
              : intl.formatMessage({ id: BILLS_FILES_UPLOAD_LABEL })
          }
          cancelLabel={intl.formatMessage({
            id: DIALOG_CANCEL_SHORT_BUTTON_LABEL,
          })}
          invalidFileSizeMessageSummary=" "
          invalidFileSizeMessageDetail={intl.formatMessage(
            { id: BILLS_FILES_ERROR_SIZE_DETAIL },
            { fileSize: "10MB" }
          )}
          emptyTemplate={
            <div>
              {" "}
              <i className="pi pi-upload" />{" "}
              {this.props.intl.formatMessage({
                id: MESSAGE_KEYS.BILLS_FILES_EMPTY_LABEL,
              })}
            </div>
          }
        />
        <div>{this.renderFilesList()}</div>
      </div>
    );
  };
  /**
   * Main render function.
   *
   * @returns {JSX.Element}
   */
  render = () => {
    const { intl } = this.props;
    const { ERROR_RENDER } = MESSAGE_KEYS;

    let errorLabel = intl.formatMessage({ id: ERROR_RENDER });
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        {safeRender(this.renderContent, errorLabel)}
      </div>
    );
  };
}

FilearchiveEditor.propTypes = {
  /** The parent object containing the filearchive array. */
  value: PropTypes.object,
  /** The parameter name of the parent object ID field. */
  idLabel: PropTypes.string,
  /** True if the component should handle bill uploads, false for customer uploads. */
  isBill: PropTypes.bool,
  /** Function to update the parent component's state. */
  updateParent: PropTypes.func,
};

export default injectIntl(withOrientationChange(FilearchiveEditor));
