import React from "react";
// Styling
import "./Style.scss";

// PrimeReact components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const ShoutboxHeader = ({
  showDialog,
  filterSearch,
  setFilterSearch,
  sortBy,
  setSortBy,
  isAdmin,
  disabled,
}) => {
  const intl = useIntl();
  const {
    SHOUTBOX_SORT_SEVERITY_LABEL,
    SHOUTBOX_SORT_TIME_LABEL,
    SHOUTBOX_SEARCH_PLACEHOLDER,
    DRIVER_STATUS_SORT_LABEL,
  } = MESSAGE_KEYS;
  const availableOptions = [
    {
      name: intl.formatMessage({ id: SHOUTBOX_SORT_SEVERITY_LABEL }),
      value: "state",
    },
    {
      name: intl.formatMessage({ id: SHOUTBOX_SORT_TIME_LABEL }),
      value: "releaseDate",
    },
  ];

  return (
    <div className="shoutbox-toolbar">
      <div className="filter-container">
        <Dropdown
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.value);
          }}
          options={availableOptions}
          optionLabel="name"
          placeholder={intl.formatMessage({
            id: DRIVER_STATUS_SORT_LABEL,
          })}
          dropdownIcon={"pi pi-sort-alt"}
          disabled={disabled}
          style={{ width: "150px" }}
        />
      </div>
      <div className="input-search">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={filterSearch}
            disabled={disabled}
            placeholder={intl.formatMessage({
              id: SHOUTBOX_SEARCH_PLACEHOLDER,
            })}
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
          />
        </span>
      </div>
      <div className="actions-container">
        {isAdmin && (
          <Button
            icon="pi pi-plus"
            onClick={() => {
              showDialog(true, "add");
            }}
            disabled={!isAdmin}
          />
        )}
      </div>
    </div>
  );
};

export default ShoutboxHeader;
