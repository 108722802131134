import React, { useState, useEffect, memo, useRef } from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
// Custom components
import { CustomerSelector } from "components/common";
// Localization
import { useIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Help functions
import {
  dateToQueryString,
  dateToString,
  sendQuery,
  initLogger,
  isCurrentUserAdmin,
} from "common/Helpers";

const logger = initLogger("RegularsTable");

const RegularsTable = () => {
  const intl = useIntl();
  const {
    APPOINTMENTS_VEHICLE_TYPE_LABEL,
    CUSTOMERS_COMMENT_LABEL,
    CUSTOMERS_FILTER_NAME,
    CUSTOMERS_FILTER_ADDRESS,
    CUSTOMERS_FILTER_INSURANCE_NUMBER,
    CUSTOMERS_FILTER_PHONE,
    REGULARS_DATES,
    REGULARS_END,
    REGULARS_START,
    REGULARS_ID,
    CUSTOMERS_SAVE_SUCCESS_MESSAGE,
    ERROR_DATA_SAVE,
  } = MESSAGE_KEYS;
  const [regulars, setRegulars] = useState([]);
  const [pending, setPending] = useState(false);
  const [editingRows, setEditingRows] = useState({});
  const [user, setUser] = useState(null);
  const toast = useRef(null);
  logger.log("Loading regulars table");
  let isAdmin = isCurrentUserAdmin();
  const customerEditor = (options) => {
    const { rowData } = options;
    if (rowData.regularCustomerId < 1) {
      return (
        <div>
          <div className="mb-2">{textEditor(options)}</div>
          <CustomerSelector
            value={user}
            onChange={(selection) => {
              setUser(selection);
              if (selection) {
                const {
                  lastname,
                  firstname,
                  girlName,
                  address,
                  countryProvince,
                  zipcode,
                  titleTxt,
                  healthInsuranceNumber,
                  phoneHome,
                  phoneWork,
                  gsm,
                } = selection;
                options.rowData["customerName"] = `${textCombiner(
                  [lastname, girlName],
                  "-"
                )} ${firstname ? firstname : ""}`;
                options.rowData["address"] = textCombiner([
                  address,
                  zipcode,
                  countryProvince,
                ]);
                options.rowData["title"] = titleTxt ? titleTxt : "";
                options.rowData["healthInsuranceNumber"] =
                  healthInsuranceNumber;
                options.rowData["phone"] = textCombiner(
                  [phoneHome, phoneWork, gsm],
                  ", "
                );
              }
            }}
          />
        </div>
      );
    } else {
      return textEditor(options);
    }
  };

  const textCombiner = (texts, separator = " ") => {
    let comboText = "";
    texts.forEach((block) => {
      if (block) {
        comboText += `${comboText ? separator : ""}${block}`;
      }
    });
    return comboText;
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      />
    );
  };
  const dateEditor = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        selectOtherMonths={true}
      />
    );
  };

  const deleteButton = ({ regularCustomerId }) => {
    return (
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger custom_delete_button"
        onClick={() => handleDeleteClick(regularCustomerId)}
      />
    );
  };

  const TABLE_STRUCTURE = [
    {
      field: "transportType",
      header: APPOINTMENTS_VEHICLE_TYPE_LABEL,
      editor: textEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
    {
      field: "startDate",
      header: REGULARS_START,
      editor: dateEditor,
      body: ({ startDate }) => dateToString(startDate, "/"),
      style: { width: "180px", maxWidth: "180px" },
    },
    {
      field: "remark",
      header: CUSTOMERS_COMMENT_LABEL,
      editor: textEditor,
      body: null,
    },
    {
      field: "title",
      editor: textEditor,
      body: null,
      style: { width: "80px", maxWidth: "80px" },
      noFilter: true,
    },
    {
      field: "customerName",
      header: CUSTOMERS_FILTER_NAME,
      editor: customerEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
    {
      field: "address",
      header: CUSTOMERS_FILTER_ADDRESS,
      editor: textEditor,
      body: null,
      style: { width: "350px", maxWidth: "350px" },
    },
    {
      field: "healthInsuranceNumber",
      header: CUSTOMERS_FILTER_INSURANCE_NUMBER,
      editor: textEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
    {
      field: "phone",
      header: CUSTOMERS_FILTER_PHONE,
      editor: textEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
    {
      field: "destination",
      header: REGULARS_END,
      editor: textEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
    {
      field: "dates",
      header: REGULARS_DATES,
      editor: textEditor,
      body: null,
      style: { width: "175px", maxWidth: "175px" },
    },
  ];

  useEffect(() => {
    setPending(true);
    sendQuery(QUERIES.GET_REGULARS, "get").then(
      (response) => {
        response.forEach((entry) => normalizeResponse(entry));
        setRegulars(response);
        setPending(false);
      },
      (error) => {
        logger.warn(error);
        setPending(false);
      }
    );
  }, [setRegulars]);

  const onRowEditComplete = ({ newData, index }) => {
    const _regulars = [...regulars];
    setPending(true);
    if (newData.regularCustomerId === 0) {
      newData.regularCustomerId = null;
    }
    if (newData.startDate) {
      newData.startDate = dateToQueryString(newData.startDate);
      logger.warn(dateToQueryString(newData.startDate), newData);
    }
    sendQuery(QUERIES.EDIT_REGULARS, "POST", {
      regularCustomer: newData,
    }).then(
      (response) => {
        normalizeResponse(response);
        const mapIndex = _regulars.findIndex(
          (updatedCustomer) =>
            updatedCustomer.regularCustomerId === response.regularCustomerId
        );
        // Index might have changed due to sorting, get updated index.
        _regulars[mapIndex] = response;
        setRegulars(_regulars);
        setUser(null);
        setPending(false);
        toast.current.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({ id: CUSTOMERS_SAVE_SUCCESS_MESSAGE }),
        });
      },
      (error) => {
        logger.warn(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: ERROR_DATA_SAVE }),
        });

        setPending(false);
      }
    );
  };

  const onRowEditCancel = ({ data }) => {
    if (data.regularCustomerId < 1) {
      let _regulars = [...regulars];
      _regulars.shift();
      setRegulars(_regulars);
      let _editingRows = { ...editingRows };
      delete _editingRows[`${0}`];
      setEditingRows(_editingRows);
      setUser(null);
    }
  };

  const deleteRegular = (id) => {
    const { REGULARS_DELETE_FAIL, REGULARS_DELETE_SUCCESS } = MESSAGE_KEYS;
    sendQuery(`${QUERIES.DELETE_REGULARS}${id}`, "DELETE").then(
      (response) => {
        logger.log(response);
        let _regulars = [...regulars].filter(
          (entry) => entry.regularCustomerId !== id
        );
        setRegulars(_regulars);
        toast.current.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({ id: REGULARS_DELETE_SUCCESS }),
        });
      },
      (error) => {
        logger.warn(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: REGULARS_DELETE_FAIL }),
        });
      }
    );
  };

  const handleDeleteClick = (id) => {
    const {
      REGULARS_DELETE_CONFIRM,
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
      DIALOG_WARNING_TITLE,
    } = MESSAGE_KEYS;
    confirmDialog({
      message: (
        <div className="mt-4 flex align-items-center">
          <i
            className="pi pi-exclamation-triangle mr-2"
            style={{ fontSize: "2em" }}
          />{" "}
          {intl.formatMessage(
            { id: REGULARS_DELETE_CONFIRM },
            { regularId: id }
          )}
        </div>
      ),
      header: intl.formatMessage({ id: DIALOG_WARNING_TITLE }),
      accept: () => deleteRegular(id),
      reject: null,
      acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
      rejectLabel: intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL }),
    });
  };

  const handleNewClick = () => {
    let newRegular = {
      regularCustomerId: 0,
      transportType: "",
      startDate: "",
      remark: "",
      customerName: "",
      address: "",
      healthInsuranceNumber: "",
      phone: "",
      destination: "",
      dates: "",
      title: "",
    };
    setRegulars([newRegular, ...regulars]);
    setTimeout(() => {
      setActive(newRegular.regularCustomerId);
    }, 100);

    setUser(null);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const setActive = (id) => {
    let _editingRows = {
      ...editingRows,
      ...{ [`${id}`]: true },
    };
    setEditingRows(_editingRows);
  };

  const renderNewButton = () => {
    let button = (
      <Button
        icon="pi pi-plus"
        className="p-button-rounded"
        onClick={handleNewClick}
      />
    );
    if (regulars[0] && regulars[0].regularCustomerId < 1) {
      button = <></>;
    }
    return <div className="flex">{button}</div>;
  };

  const renderColumns = () => {
    const columns = [];
    TABLE_STRUCTURE.forEach((col) => {
      columns.push(
        <Column
          header={col.header ? intl.formatMessage({ id: col.header }) : null}
          field={col.field}
          editor={col.editor}
          body={col.body}
          filter={col.noFilter ? false : true}
          sortable={col.noFilter ? false : true}
          showFilterMenu={false}
          filterMatchMode="contains"
          key={`regulars_${col.field}`}
          style={col.style}
        />
      );
    });
    return columns;
  };

  const normalizeResponse = (regular) => {
    Object.keys(regular).forEach(
      (k) => (regular[k] = regular[k] === null ? "" : regular[k])
    );
    if (regular.startDate) {
      regular.startDate = new Date(regular.startDate);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <DataTable
        loading={pending}
        value={regulars}
        editMode="row"
        dataKey="regularCustomerId"
        onRowEditComplete={onRowEditComplete}
        onRowEditChange={onRowEditChange}
        onRowEditCancel={onRowEditCancel}
        editingRows={editingRows}
        responsiveLayout="scroll"
        className="inline_edit_table"
        filterDisplay="row"
        paginator
        rows={25}
        rowsPerPageOptions={[10, 25, 50]}
        sortField={"regularCustomerId"}
        sortOrder={1}
      >
        {isAdmin && (
          <Column
            field="regularCustomerId"
            header={intl.formatMessage({ id: REGULARS_ID })}
            sortable
          />
        )}
        {renderColumns()}
        {isAdmin && (
          <Column
            rowEditor
            header={renderNewButton}
            headerStyle={{
              display: "flex",
              justifyContent: "center",
              minWidth: "8rem",
            }}
            bodyStyle={{ textAlign: "center" }}
            key={`editor_${new Date().getTime}`}
            id={`editor_${new Date().getTime()}`}
            style={{ width: "80px", maxWidth: "80px" }}
          />
        )}
        {isAdmin && (
          <Column
            style={{ width: "80px", maxWidth: "80px" }}
            body={deleteButton}
          />
        )}
      </DataTable>
    </div>
  );
};
//RegularsTable.whyDidYouRender = true;

export default memo(RegularsTable);
