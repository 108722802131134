import React, { useEffect, useRef, useState, memo } from "react";
// React redux
import { useDispatch } from "react-redux";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
// Static values
import { setTopbarTitle } from "actions/index";
import {
  DATE_FORMAT,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Localization
import { useIntl } from "react-intl";
// Helper functions
import {
  dateToQueryString,
  dateToString,
  sendQuery,
  initLogger,
} from "common/Helpers";

const logger = initLogger("holidaytable");

const HolidayTable = ({ year }) => {
  const intl = useIntl();
  const {
    CUSTOMERS_TITLE_LABEL,
    VACATIONS_HOLIDAY_LABEL,
    BILLS_POSITIONS_DESCRIPTION_LABEL,
    VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE,
    VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE,
  } = MESSAGE_KEYS;
  const [holidays, setHolidays] = useState([]);
  const [pending, setPending] = useState(false);
  const [editingRows, setEditingRows] = useState({});
  const toast = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTopbarTitle(intl.formatMessage({ id: MESSAGE_KEYS.MENU_HOLIDAYS }))
    );
  }, [dispatch, intl]);

  useEffect(() => {
    let startDate = new Date();
    startDate.setFullYear(year);
    startDate.setMonth(0);
    startDate.setDate(1);
    let endDate = new Date();
    endDate.setFullYear(year);
    endDate.setMonth(11);
    endDate.setDate(31);

    sendQuery(
      `${QUERIES.GET_HOLIDAYS_BY_DATE}?fromDate=${dateToQueryString(
        startDate
      )}&toDate=${dateToQueryString(endDate)}`,
      "GET"
    ).then(
      (response) => {
        response.forEach((entry) => normalizeResponse(entry));
        setHolidays(response);
        setPending(false);
      },
      (error) => {
        logger.error(error);
        setPending(false);
      }
    );
  }, [setHolidays, year]);

  const setActive = (id) => {
    let _editingRows = {
      ...editingRows,
      ...{ [`${id}`]: true },
    };
    setEditingRows(_editingRows);
  };

  const handleNewClick = () => {
    let newHoliday = {
      appointmentId: 0,
      starttime: new Date(),
      typeOfAppointment: 13,
      bankHolidayName: "",
      description: "",
    };
    setHolidays([newHoliday, ...holidays]);
    setTimeout(() => {
      setActive(newHoliday.appointmentId);
    }, 200);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const onRowEditComplete = ({ newData, index }) => {
    const { appointmentId, starttime, bankHolidayName, description } = newData;
    const _holidays = [...holidays];
    setPending(true);
    const newId = appointmentId === 0 ? null : appointmentId;

    let newStartTime = starttime;
    if (starttime) {
      newStartTime = dateToQueryString(starttime);
    }
    sendQuery(QUERIES.EDIT_HOLIDAY, "POST", {
      bankHoliday: {
        appointmentId: newId,
        starttime: newStartTime,
        type: 5,
        typeOfAppointment: 13,
        bankHolidayName,
        description: description,
      },
    }).then(
      (response) => {
        normalizeResponse(response);
        _holidays[index] = response;
        setHolidays(_holidays);
        setPending(false);
        toast.current.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({
            id: VACATIONS_HOLIDAY_UPDATE_SUCCESS_MESSAGE,
          }),
        });
      },
      (error) => {
        logger.warn(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: VACATIONS_HOLIDAY_UPDATE_FAILURE_MESSAGE,
          }),
        });

        setPending(false);
      }
    );
  };

  const onRowEditCancel = ({ data }) => {
    if (data.appointmentId < 1) {
      let _holidays = [...holidays];
      _holidays.shift();
      setHolidays(_holidays);
      let _editingRows = { ...editingRows };
      delete _editingRows[`${0}`];
      setEditingRows(_editingRows);
    }
  };

  const handleDeleteClick = (id, title) => {
    const {
      VACATIONS_HOLIDAY_DELETE_WARNING,
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
      DIALOG_WARNING_TITLE,
    } = MESSAGE_KEYS;
    confirmDialog({
      message: (
        <div className="mt-4 flex align-items-center">
          <i
            className="pi pi-exclamation-triangle mr-2"
            style={{ fontSize: "2em" }}
          />{" "}
          {intl.formatMessage(
            { id: VACATIONS_HOLIDAY_DELETE_WARNING },
            { holiday: title ? title : id }
          )}
        </div>
      ),
      header: intl.formatMessage({ id: DIALOG_WARNING_TITLE }),
      accept: () => deleteHoliday(id),
      reject: null,
      acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
      rejectLabel: intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL }),
    });
  };

  const deleteHoliday = (id) => {
    const {
      VACATIONS_HOLIDAY_DELETE_SUCCESS_MESSAGE,
      VACATIONS_HOLIDAY_DELETE_FAILURE_MESSAGE,
    } = MESSAGE_KEYS;
    sendQuery(`${QUERIES.DELETE_HOLIDAY}${id}`, "DELETE").then(
      (response) => {
        logger.log(response);
        let _holidays = [...holidays].filter(
          (entry) => entry.appointmentId !== id
        );
        setHolidays(_holidays);
        toast.current.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({
            id: VACATIONS_HOLIDAY_DELETE_SUCCESS_MESSAGE,
          }),
        });
      },
      (error) => {
        logger.warn(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: VACATIONS_HOLIDAY_DELETE_FAILURE_MESSAGE,
          }),
        });
      }
    );
  };

  const normalizeResponse = (regular) => {
    Object.keys(regular).forEach(
      (k) => (regular[k] = regular[k] === null ? "" : regular[k])
    );
    if (regular.starttime) {
      regular.starttime = new Date(regular.starttime);
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  const dateEditor = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        dateFormat={DATE_FORMAT}
        selectOtherMonths={true}
      />
    );
  };

  const renderNewButton = () => {
    let button = (
      <Button
        icon="pi pi-plus"
        className="p-button-rounded"
        onClick={handleNewClick}
      />
    );
    if (holidays[0] && holidays[0].appointmentId < 1) {
      button = <></>;
    }
    return <div className="flex">{button}</div>;
  };
  const deleteButton = ({ appointmentId, bankHolidayName }) => {
    return (
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger custom_delete_button"
        onClick={() => handleDeleteClick(appointmentId, bankHolidayName)}
      />
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <DataTable
        loading={pending}
        value={holidays}
        editMode="row"
        dataKey="appointmentId"
        responsiveLayout="scroll"
        className="inline_edit_table"
        filterDisplay="row"
        paginator
        rows={25}
        rowsPerPageOptions={[10, 25, 50]}
        onRowEditComplete={onRowEditComplete}
        onRowEditChange={onRowEditChange}
        onRowEditCancel={onRowEditCancel}
        editingRows={editingRows}
      >
        <Column
          field="bankHolidayName"
          header={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
          editor={textEditor}
          filter
          sortable
          showFilterMenu={false}
          filterMatchMode="contains"
        />
        <Column
          field="starttime"
          header={intl.formatMessage({ id: VACATIONS_HOLIDAY_LABEL })}
          body={({ starttime }) => dateToString(starttime, "/")}
          editor={dateEditor}
          filter
          sortable
          showFilterMenu={false}
          filterMatchMode="contains"
        />
        <Column
          field="description"
          header={intl.formatMessage({
            id: BILLS_POSITIONS_DESCRIPTION_LABEL,
          })}
          editor={textEditor}
          filter
          sortable
          showFilterMenu={false}
          filterMatchMode="contains"
        />

        <Column
          rowEditor
          header={renderNewButton}
          headerStyle={{
            display: "flex",
            justifyContent: "center",
            minWidth: "8rem",
          }}
          bodyStyle={{ textAlign: "center" }}
          key={`editor_${new Date().getTime}`}
          id={`editor_${new Date().getTime()}`}
          style={{ width: "80px", maxWidth: "80px" }}
        />

        <Column
          style={{ width: "80px", maxWidth: "80px" }}
          body={deleteButton}
        />
      </DataTable>
    </div>
  );
};
export default memo(HolidayTable);
