import React, { useState, useEffect } from "react";
// primeng
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { setBillSession } from "actions/sessionActions";
// localization
import { injectIntl } from "react-intl";
// helpers
import {
  sendInvoiceToPeppol,
  dateToQueryString,
  logPeppolMessage,
  utcToDate,
  updatePeppolStatus,
} from "common/Helpers";

import {
  MESSAGE_SEVERITY,
  PEPPOL_MESSAGES,
  PEPPOL_STATES,
  MESSAGE_KEYS,
} from "assets/staticData/enums";
import { connect } from "react-redux";

const DELAY_HIDE_LOADING_PEPPOL = 2000;

const PeppolView = (props) => {
  const { bill, toast } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [peppolState, setPeppolState] = useState(null);

  // transactionPeppolLogs changes when an invoice is being send.. so we need useEffect to capture this change :)
  useEffect(() => {
    if (bill?.transactionPeppolLogs?.length > 0) {
      bill.transactionPeppolLogs.sort((a, b) => {
        return new Date(b.lastModifyDate) - new Date(a.lastModifyDate);
      });

      // take the latest state of the logs
      setPeppolState(bill.transactionPeppolLogs[0]?.peppolState);
    }
  }, [bill]);

  const peppolId = bill?.invoiceAddress?.peppolParticipantId;

  const handleSetResendPeppol = async () => {
    if (peppolId && bill) {
      if (bill?.transactionId) {
        const { intl, parentUpdate } = props;
        const { PEPPOL_SET_RESEND_SUCCESS } = MESSAGE_KEYS;

        // update peppol state
        const reqSuccessfully = await updatePeppolStatus(
          bill.transactionId,
          false
        );

        if (reqSuccessfully) {
          // create a warning log that the user wants to resend the invoice
          logPeppolMessage(
            bill.transactionId,
            PEPPOL_STATES.WARNING,
            PEPPOL_MESSAGES.RESEND_BILL
          );

          parentUpdate(bill?.transactionId, bill?.invoiceNumber);
          toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage({ id: PEPPOL_SET_RESEND_SUCCESS }),
          });
        }
      }
    }
  };

  const handleSendInvoiceToPeppol = async () => {
    const { intl, parentUpdate } = props;
    const { PEPPOL_SEND_BILL_FAILURE, PEPPOL_SEND_BILL_SUCCESS } = MESSAGE_KEYS;

    let toastSeverity = null;
    let toastSummary = null;

    if (peppolId && bill) {
      setIsFetching(true);
      try {
        await sendInvoiceToPeppol(bill);

        toastSeverity = MESSAGE_SEVERITY.SUCCESS;
        toastSummary = intl.formatMessage({ id: PEPPOL_SEND_BILL_SUCCESS });
      } catch (error) {
        toastSeverity = MESSAGE_SEVERITY.ERROR;
        toastSummary =
          intl.formatMessage({
            id: PEPPOL_SEND_BILL_FAILURE,
          }) +
            ": " +
            error?.message || "Unknown error occurred";
      } finally {
        parentUpdate(bill?.transactionId, bill?.invoiceNumber);

        toast.show({
          severity: toastSeverity,
          summary: toastSummary,
        });

        setTimeout(() => {
          setIsFetching(false);
        }, DELAY_HIDE_LOADING_PEPPOL);
      }
    } else {
      if (toast) {
        toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: "PeppolId or bill is null.",
        });
      }
    }
  };

  const generateHistoryLog = (logs) => {
    if (!logs || !logs.length) {
      return "";
    }

    const latestLogs = logs.length > 15 ? logs.slice(0, 15) : logs;

    return latestLogs
      .map(
        (log) =>
          `${dateToQueryString(utcToDate(log?.lastModifyDate), true)}: ${
            log?.peppolState
          } ${
            log?.peppolRequestMessage ? " - " + log?.peppolRequestMessage : ""
          }`
      )
      .join("\n");
  };

  const PEPPOL_ICONS = {
    success: "pi pi-check",
    warning: "pi pi-exclamation-triangle",
    failed: "pi pi-times",
  };

  const getIconBasedOnPeppolState = (state) =>
    PEPPOL_ICONS[state] || PEPPOL_ICONS.warning;

  return (
    <>
      {peppolId &&
        bill?.transactionId &&
        bill?.transactionDetails?.length > 0 && (
          <div className="font-light peppol-status flex align-items-center justify-content-center gap-2">
            <Tooltip target=".peppol-badge" />
            <div className="peppol-status-img">
              <img
                src="assets/layout/images/peppol-icon-alone.png"
                alt="peppol icon"
                className="peppol-icon"
                title="peppol"
              />
              <div
                data-pr-tooltip={generateHistoryLog(
                  bill?.transactionPeppolLogs
                )}
                data-pr-position="bottom"
                className="peppol-badge"
                style={{ height: "24px" }}
              >
                <Badge value={bill?.transactionPeppolLogs?.length}></Badge>
              </div>
            </div>
            {isFetching && (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px", width: "50px", top: "0px" }}
              ></ProgressBar>
            )}
            {!isFetching && (
              <>
                <div className="peppol-status-text">
                  <i className={getIconBasedOnPeppolState(peppolState)}></i>

                  {/* STATE WARNING OR FAILED */}
                  {peppolState !== PEPPOL_STATES.SUCCESS && (
                    <Button
                      icon="pi pi-file-export"
                      aria-label="Replay"
                      size="small"
                      onClick={handleSendInvoiceToPeppol}
                    />
                  )}

                  {/* STATE SUCCESS */}
                  {peppolState === PEPPOL_STATES.SUCCESS && (
                    <Button
                      icon="pi pi-replay"
                      aria-label="Replay"
                      size="small"
                      onClick={handleSetResendPeppol}
                      tooltip="Rechnung zur erneuten Sendung freigeben"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  try {
    const {
      session: { billSession = null },
    } = state;
    return {
      billSession,
    };
  } catch (mapException) {
    return {
      billSession: null,
    };
  }
};

export default connect(mapStateToProps, {
  setBillSession,
})(injectIntl(PeppolView));
