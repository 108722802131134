//react
import React from "react";

// Style
import "./Style.scss";

// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";

// Localization
import { injectIntl } from "react-intl";

//custom components
import {
  ShoutboxDialog,
  ShoutboxContainer,
  ShoutboxHeader,
  ShoutboxWebsocket,
} from "./components/index";

//primeng
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
//helpers
import { isCurrentUserAdmin, sendQuery, initLogger } from "common/Helpers";
// Static data
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import { AUTHOR_ROLE } from "assets/staticData/combodata";

const { MENU_SHOUTBOX } = MESSAGE_KEYS;
const logger = initLogger("shoutbox");

const WS_UPDATE_TYPES = {
  SHOUTBOX: "shoutbox",
  REACTION: "reaction",
};

class ShoutboxView extends React.Component {
  componentDidMount = () => {
    const { setTopbarTitle, intl } = this.props;

    const canEdit =
      isCurrentUserAdmin() ||
      this.props.currentUser.userRoles.findIndex((role) => {
        return role.role_id === AUTHOR_ROLE.role_id;
      }) >= 0;
    this.setState({
      isAdmin: canEdit,
    });

    this.getLatestMessages(canEdit);
    setTopbarTitle(intl.formatMessage({ id: MENU_SHOUTBOX }));
    this.fetchUsers();
    this.fetchGroups();

    this.websocket = React.createRef();
  };

  componentWillUnmount = () => {};

  state = {
    filterSearch: "",
    sortBy: "state",
    displayMessages: [],
    messages: [],
    visibleDialog: false,
    dialogHeader: "",
    dialogType: "",
    selectedMessage: {},
    users: [],
    groups: [],
    isAdmin: false,
    isLoading: true,
  };

  handleWSUpdate = (object, type) => {
    const { SHOUTBOX, REACTION } = WS_UPDATE_TYPES;
    const updatedMessages = [...this.state.messages];
    // Find the index of the shoutbox with the given ID in the messages array
    const shoutIndex = this.state.messages.findIndex(
      (message) => message.shoutboxId === object.shoutboxId
    );
    switch (type) {
      case SHOUTBOX:
        if (shoutIndex !== -1) {
          updatedMessages[shoutIndex] = object;
        } else {
          updatedMessages.push(object);
        }

        break;
      case REACTION:
        if (shoutIndex >= 0) {
          const shout = updatedMessages[shoutIndex];
          const reactionIndex = shout.reactions.findIndex(
            (reaction) => reaction.reactionId === object.reactionId
          );
          if (reactionIndex >= 0) {
            shout.reactions[reactionIndex] = object;
          } else {
            shout.reactions.push(object);
          }
        }
        break;
      default:
        logger.warn(`Unknown WS update type ${type} encountered`);
    }
    this.setState(
      {
        messages: updatedMessages,
        displayMessages: updatedMessages,
      },
      () => {
        this.setSortBy(this.state.sortBy);
      }
    );
  };

  handleWSRemove = (id, type, secondId = null) => {
    const { messages } = this.state;
    const { SHOUTBOX, REACTION } = WS_UPDATE_TYPES;
    switch (type) {
      case SHOUTBOX:
        const updatedMessages = messages.filter(
          (message) => message.shoutboxId !== id
        );
        // Update the state with the new array
        this.setState({
          messages: updatedMessages,
          displayMessages: updatedMessages,
        });
        break;
      case REACTION:
        if (secondId) {
          const updatedMessages = [...messages];
          const shoutIndex = updatedMessages.findIndex(
            (message) => message.shoutboxId === secondId
          );
          if (shoutIndex >= 0) {
            const updatedShout = { ...updatedMessages[shoutIndex] };
            const _reactions = [
              ...updatedShout.reactions.filter(
                (reaction) => reaction.reactionId !== id
              ),
            ];
            updatedShout.reactions = _reactions;
            updatedMessages[shoutIndex] = updatedShout;
          }
          this.setState({
            messages: updatedMessages,
            displayMessages: updatedMessages,
          });
        }
        break;
      default:
        logger.warn(`Unknown WS update type ${type} encountered`);
    }
  };

  handleWSCall = (msg) => {
    try {
      let changes = JSON.parse(msg.body);
      if (changes.type === undefined) {
        this.handleWSUpdate(changes, this.getWSTypeById(changes));
      } else {
        this.handleWSRemove(
          changes.id,
          this.getWSTypeById(changes),
          changes.shoutboxId
        );
      }

      logger.info("Greetings", changes);
    } catch (wsException) {
      logger.warn(wsException);
    }
  };

  getWSTypeById = (changes) => {
    const { SHOUTBOX, REACTION } = WS_UPDATE_TYPES;
    if (typeof changes.reactionId === "number") {
      return REACTION;
    } else {
      return SHOUTBOX;
    }
  };

  fetchUsers = () => {
    sendQuery(
      `${QUERIES.GET_USERS_PAGES}?size=3000&sort=personId,asc`,
      "get"
    ).then(
      (response) => {
        if (response?.content) {
          const onlyActive = response.content.filter(
            (user) => user.active === true
          );
          this.setState({
            users: [...onlyActive],
          });
        }
      },
      (error) => {
        logger.warn(error);
      }
    );
  };

  fetchGroups = () => {
    sendQuery(`api/base/person-groups/all`, "get").then(
      (response) => {
        if (response) {
          this.setState({
            groups: [...response],
          });
        }
      },
      (error) => {
        logger.warn(error);
      }
    );
  };

  getLatestMessages = (canEdit) => {
    this.setState({ isLoading: true });
    let url = "api/base/shoutbox/all";
    if (!canEdit) {
      url += `?userId=${this.props.currentUser.personId}`;
    }
    sendQuery(url, "GET", null).then(
      (response) => {
        this.setState(
          {
            messages: response,
            displayMessages: response,
            isLoading: false,
          },
          () => {
            this.setSortBy(this.state.sortBy);
          }
        );
      },
      (error) => {
        logger.warn(error);
        this.setState({ messages: [], displayMessages: [], isLoading: false });
      }
    );
  };

  handleDialog = (shouldShow, type, message = {}) => {
    const { intl } = this.props;
    const {
      SHOUTBOX_DIALOG_TITLE_ADD,
      SHOUTBOX_DIALOG_TITLE_EDIT,
      SHOUTBOX_DIALOG_TITLE_DELETE,
      SHOUTBOX_DIALOG_TITLE_CHAT,
    } = MESSAGE_KEYS;
    const { title } = message;

    const headers = {
      add: intl.formatMessage({ id: SHOUTBOX_DIALOG_TITLE_ADD }),
      edit: intl.formatMessage({ id: SHOUTBOX_DIALOG_TITLE_EDIT }, { title }),
      delete: intl.formatMessage(
        { id: SHOUTBOX_DIALOG_TITLE_DELETE },
        { title }
      ),
      chat: intl.formatMessage({ id: SHOUTBOX_DIALOG_TITLE_CHAT }, { title }),
    };
    this.setState({
      selectedMessage: message,
      dialogType: type,
      dialogHeader: headers[type] ? headers[type] : "",
      visibleDialog: shouldShow,
    });
  };

  actionDialog = (type, inputData = null) => {
    switch (type) {
      case "add":
        this.editMessage(inputData, type);
        break;
      case "edit":
        this.editMessage(inputData, type);
        break;
      case "delete":
        this.deleteMessage(inputData);
        break;
      default:
        break;
    }
    this.handleDialog(false);
  };

  editMessage = (inputData, type) => {
    let handler = {
      method: type === "edit" ? "PUT" : "POST",
      action: type === "edit" ? "edit" : "create",
    };

    const payload = {
      ...inputData,
      assignedGroup: inputData?.assignedGroup?.groupId
        ? { groupId: inputData.assignedGroup.groupId }
        : null,
      assignedPerson: inputData?.assignedPerson?.personId
        ? { personId: inputData.assignedPerson.personId }
        : null,
      targetLanguageId: inputData.languageId,
    };

    if (type === "edit") {
      delete payload.mediaList;
    } else {
      delete payload.shoutboxId;
      if (payload.mediaList.length === 0) {
        payload.mediaList = null;
      }
    }

    delete payload.releaseDate;

    sendQuery(
      `api/base/shoutbox/${handler.action}`,
      handler.method,
      payload
    ).then(
      (response) => {
        if (response) {
          this.websocket.current.callWebsocket(
            response.shoutboxId,
            WS_UPDATE_TYPES.SHOUTBOX,
            false
          );
        }
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage(
            {
              id: MESSAGE_KEYS.SHOUTBOX_EDIT_FAIL_MESSAGE,
            },
            { type: WS_UPDATE_TYPES.SHOUTBOX }
          ),
        });
      }
    );
  };

  deleteMessage = (id) => {
    sendQuery(`api/base/shoutbox/${id}`, "delete").then(
      () => {
        this.websocket.current.callWebsocket(
          id,
          WS_UPDATE_TYPES.SHOUTBOX,
          true
        );
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage(
            {
              id: MESSAGE_KEYS.SHOUTBOX_DELETE_FAIL_MESSAGE,
            },
            { type: WS_UPDATE_TYPES.SHOUTBOX }
          ),
        });
      }
    );
  };

  deleteReaction = (reactionId, shoutboxId) => {
    sendQuery(`api/base/shoutbox/deleteReaction/${reactionId}`, "delete").then(
      () => {
        this.websocket.current.callWebsocketWithPayload(
          reactionId,
          WS_UPDATE_TYPES.REACTION,
          shoutboxId,
          true
        );
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage(
            {
              id: MESSAGE_KEYS.SHOUTBOX_DELETE_FAIL_MESSAGE,
            },
            { type: WS_UPDATE_TYPES.REACTION }
          ),
        });
      }
    );
  };

  setFilterSearch = (value) => {
    if (this.state.messages) {
      const filteredArray = Array.from(this.state.messages).filter((message) =>
        message?.title?.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ displayMessages: [...filteredArray] });
    }

    this.setState({ filterSearch: value });
  };

  sortByStateAndDate = (firstMessage, secondMessage) => {
    // First, compare by state
    if (firstMessage.state < secondMessage.state) return -1;
    if (firstMessage.state > secondMessage.state) return 1;

    // If states are equal, compare by releaseDate
    if (firstMessage.releaseDate < secondMessage.releaseDate) return 1;
    if (firstMessage.releaseDate > secondMessage.releaseDate) return -1;

    return 0;
  };

  setSortBy = (value) => {
    if (this.state.messages) {
      let filtered = [];
      switch (value) {
        case "state":
          filtered = this.state.messages.sort(this.sortByStateAndDate);
          break;
        case "releaseDate":
          filtered = this.state.messages.sort((a, b) =>
            a[value].toLowerCase() > b[value].toLowerCase() && a[value] !== null
              ? -1
              : 1
          );
          break;
        default:
          break;
      }
      logger.info(filtered);
      this.setState({
        displayMessages: [...filtered],
      });
      if (this.state.filterSearch !== null || this.state.filterSearch !== "") {
        this.setFilterSearch(this.state.filterSearch);
      }
    }
    this.setState({ sortBy: value });
  };

  addReaction = (emoji, index, id) => {
    let payload = {
      personId: this.props.currentUser.personId,
      icon: emoji,
    };

    sendQuery(`api/base/shoutbox/${id}/add-reaction`, "post", payload).then(
      (response) => {
        const reactionId = response.reactionId;

        const updatedMessages = [...this.state.messages];
        const messageToUpdate = updatedMessages[index];

        const existingReactionIndex = messageToUpdate.reactions.findIndex(
          (reaction) => reaction.reactionId === reactionId
        );

        if (existingReactionIndex !== -1) {
          messageToUpdate.reactions[existingReactionIndex].icon = payload.icon;
        } else {
          const newReaction = {
            reactionId: reactionId,
            personId: payload.personId,
            icon: payload.icon,
            alias: payload.alias,
          };
          messageToUpdate.reactions.push(newReaction);
        }

        this.setState(
          {
            messages: updatedMessages,
            displayMessages: updatedMessages,
          },
          () => {
            this.setSortBy(this.state.sortBy);
          }
        );
        this.websocket.current.callWebsocket(reactionId, "reaction", false);
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage(
            {
              id: MESSAGE_KEYS.SHOUTBOX_EDIT_FAIL_MESSAGE,
            },
            { type: WS_UPDATE_TYPES.REACTION }
          ),
        });
      }
    );
  };

  render = () => {
    if (this.state.isLoading) {
      return (
        <div className="shoutbox">
          <div className="flex align-items-center header_container">
            <ShoutboxHeader isAdmin={this.state.isAdmin} disabled={true} />
          </div>

          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        </div>
      );
    } else {
      return (
        <div className="shoutbox">
          <ShoutboxWebsocket
            callback={this.handleWSCall}
            ref={this.websocket}
          />
          <Toast ref={(el) => (this.toast = el)} />
          <ShoutboxDialog
            header={this.state.dialogHeader}
            type={this.state.dialogType}
            targetMessage={this.state.selectedMessage}
            visible={this.state.visibleDialog}
            hideDialog={this.handleDialog}
            actionDialog={this.actionDialog}
            users={this.state.users}
            groups={this.state.groups}
            refetchGroups={this.fetchGroups}
            user={this.props.currentUser}
          />
          <div className="flex align-items-center header_container">
            <ShoutboxHeader
              showDialog={this.handleDialog}
              sortBy={this.state.sortBy}
              filterSearch={this.state.filterSearch}
              setFilterSearch={this.setFilterSearch}
              setSortBy={this.setSortBy}
              isAdmin={this.state.isAdmin}
              disabled={this.state.messages.length === 0}
            />
          </div>
          <div className="shoutbox-container">
            <ShoutboxContainer
              messages={this.state.displayMessages}
              handleDialog={this.handleDialog}
              isAdmin={this.state.isAdmin}
              handleReactionAdd={this.addReaction}
              handleReactionDelete={this.deleteReaction}
              userId={this.props.currentUser.personId}
            />
          </div>
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
    ...state.application,
  };
};

export default connect(mapStateToProps, { setTopbarTitle })(
  injectIntl(ShoutboxView)
);
