import React, { Component } from "react";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions/index";
// Custom components
import { CalendarToolbar } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static data
import { QUERIES } from "assets/staticData/enums";
// Helper function
import { sendQuery, dateToQueryString } from "common/Helpers";
import MonthReportTable from "./components/MonthReportTable";

class MonthReportView extends Component {
  state = {
    displayedDate: new Date(),
    eventFetchPending: true,
    reportData: [],
    reportColumns: [],
  };

  componentDidMount = () => {
    this.fetchReport(this.state.displayedDate);
  };

  generateColumns = (lenght) => {
    const _reportColumns = [];
    for (let c = 1; c < lenght + 1; c++) {
      _reportColumns.push({
        field: c,
        header: c,
      });
    }
    return _reportColumns;
  };

  fetchReport = (date) => {
    this.setState(
      {
        eventFetchPending: true,
      },
      () => {
        sendQuery(
          `${QUERIES.GET_MONTH_REPORT}${dateToQueryString(date)}`,
          "GET"
        )
          .then(
            (response) => {
              const _reportData = this.mapData(response);
              let _columns = [];
              if (response[0]?.dayDifferences) {
                _columns = this.generateColumns(
                  Object.keys(response[0].dayDifferences).length
                );
              }
              this.setState({
                reportData: _reportData,
                reportColumns: _columns,
              });
            },
            (error) => {
              console.error(error);
            }
          )
          .finally(() => {
            this.setState({
              eventFetchPending: false,
            });
          });
      }
    );
  };

  handleDateChange = (e) => {
    this.setState(
      {
        displayedDate: e.value,
      },
      () => this.fetchReport(e.value)
    );
  };

  mapData = (data) => {
    const value = [];
    data.forEach((entry) => {
      const { user, dayDifferences, totalDifference } = entry;
      value.push({
        userId: user.personId,
        name: `${user.lastname} ${user.firstname}`,
        totalDifference,
        ...dayDifferences,
      });
    });
    return value;
  };

  render() {
    const { displayedDate, eventFetchPending, reportColumns, reportData } =
      this.state;
    return (
      <div>
        <CalendarToolbar
          className="month_report_calendar"
          displayedDate={displayedDate}
          handleDateChange={this.handleDateChange}
          monthOnly
          disabled={eventFetchPending}
          selectOtherMonths={true}
          loading={eventFetchPending}
        />
        <div>
          <MonthReportTable
            value={reportData}
            columns={reportColumns}
            displayedDate={displayedDate}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, { setTopbarTitle })(MonthReportView));
