import React from "react";
// PrimeReact components
import { Toast } from "primereact/toast";
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
// Localization
import { injectIntl } from "react-intl";
// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, fas } from "@fortawesome/free-solid-svg-icons";
// Custom components
import { CustomerFilterLayout } from "components/CustomersView/components";
// Static values
import {
  QUERIES,
  MESSAGE_SEVERITY,
  MESSAGE_KEYS,
} from "assets/staticData/enums";
// Helper classes
import { sendQuery, generateQueryParameters, initLogger } from "common/Helpers";
// Logging
const logger = initLogger("customer_selector");

class CustomerSelector extends React.Component {
  state = {
    displayedCustomers: [],
    fetchPending: false,
  };

  componentDidMount = () => {
    this.focusRef = React.createRef();
    this.buttonRef = React.createRef();
  };

  filterCustomers = (filter) => {
    try {
      let page = 0; // Always display the first page of results.
      const rows = 30; // Limit results to 30.
      let queryString = generateQueryParameters(filter, "personId");
      this.setState({ fetchPending: true, displayedCustomers: [] }, () => {
        sendQuery(
          `${QUERIES.GET_CUSTOMER_FILTERED}page=${page}&size=${rows}${queryString}`,
          "get"
        ).then(
          (response) => {
            let displayedCustomers = response?.content
              ? [...response.content]
              : [];
            this.setState({
              displayedCustomers,
              fetchPending: false,
            });
          },
          (error) => {
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : this.props.intl.formatMessage({
                        id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                      }),
              })
            );
          }
        );
      });
    } catch (filterException) {
      logger.error(filterException);
    }
  };

  renderList = () => {
    try {
      const { selectedCustomer, displayedCustomers } = this.state;
      if (displayedCustomers && displayedCustomers.length > 0) {
        return (
          <ListBox
            value={selectedCustomer}
            options={displayedCustomers}
            itemTemplate={this.renderListItem}
            onChange={(e) => this.handleCustomerSelection(e.value)}
            optionLabel={"lastname"}
            listStyle={{ maxHeight: "242px" }}
            className="ml-2"
          />
        );
      } else {
        return <></>;
      }
    } catch (listRenderException) {
      logger.warn("Exception in renderList", listRenderException);
      return <></>;
    }
  };

  renderListItem = (item) => {
    try {
      library.add(fas, faCross);
      const { firstname, girlName, lastname, healthInsuranceNumber, isDead } =
        item;
      let fullLastName = `${lastname ? lastname : ""}${
        girlName && lastname ? "-" : ""
      }${girlName ? girlName : ""}`;
      return (
        <div>
          {isDead ? (
            <FontAwesomeIcon icon={["fas", "cross"]} className="mr-2" />
          ) : (
            <></>
          )}
          {`${fullLastName ? fullLastName : ""} ${firstname ? firstname : ""} ${
            healthInsuranceNumber ? healthInsuranceNumber : ""
          }`}
        </div>
      );
    } catch (renderException) {
      logger.warn("Could not render list item.", renderException, item);
      return <div>Error</div>;
    }
  };

  handleCustomerSelection = (selectedCustomer) => {
    logger.info("HAVE SELECTED", selectedCustomer);
    this.props.onChange(selectedCustomer);
    this.customerOp.hide();
  };

  resetCustomerFilter = () => {
    this.props.onChange(null);
  };

  renderCustomerOverlayButton = () => {
    return (
      <div>
        <Button
          icon="pi pi-search"
          onClick={(e) => {
            this.customerOp.toggle(e);
            if (this.focusRef && this.focusRef.current) {
              this.focusRef.current.focus();
            }
          }}
        />
        <OverlayPanel
          ref={(el) => (this.customerOp = el)}
          showCloseIcon
          dismissable
        >
          {this.renderOverlayContent()}
        </OverlayPanel>
      </div>
    );
  };

  renderOverlayContent = () => {
    return (
      <div className="bill_selector_base">
        <Toast ref={(el) => (this.toast = el)} />
        <CustomerFilterLayout
          isPending={this.state.fetchPending}
          handleSearch={this.filterCustomers}
          focusRef={this.focusRef}
          initValue={this.props.value ? "" : this.props.tempCustomer}
        />
        {this.renderList()}
      </div>
    );
  };

  renderInput = () => {
    let clearButton;
    let displayText;
    if (this.props.value) {
      const {
        value: { firstname, lastname, healthInsuranceNumber },
      } = this.props;
      clearButton = (
        <Button
          className="p-button-warning"
          icon="pi pi-times"
          onClick={this.resetCustomerFilter}
        />
      );
      displayText = `${lastname ? lastname : ""} ${
        firstname ? firstname : ""
      } ${healthInsuranceNumber ? healthInsuranceNumber : ""}`;
    } else {
      const { placeholder, intl } = this.props;
      if (placeholder) {
        displayText = placeholder;
      } else {
        displayText = intl.formatMessage({
          id: MESSAGE_KEYS.BILLS_FILTER_CUSTOMER_LABEL,
        });
      }
      clearButton = <></>;
    }
    const { valid, className } = this.props;
    return (
      <div className="p-inputgroup">
        {this.renderCustomerOverlayButton()}
        <InputText
          value={displayText}
          disabled
          className={`${className} ${
            valid === true || valid === null || valid === undefined
              ? ""
              : "p-invalid"
          }`}
        />
        {clearButton}
      </div>
    );
  };

  render = () => {
    try {
      return this.renderInput();
    } catch (renderException) {
      logger.warn("Exception on renderCustomerSelection", renderException);
      return <div></div>;
    }
  };
}

export default injectIntl(CustomerSelector);
