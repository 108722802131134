import React, { memo } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// Styling
import "./Style.scss";
import { ShoutboxElement } from "./index";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const ShoutboxContainer = ({
  messages,
  handleDialog,
  isAdmin,
  handleReactionAdd,
  handleReactionDelete,
  userId,
}) => {
  const intl = useIntl();
  const renderMessagesElements = () => {
    if (messages.length === 0) {
      return (
        <p>{intl.formatMessage({ id: MESSAGE_KEYS.SHOUTBOX_NO_MESSAGE })}</p>
      );
    } else {
      const messagesElement = [];

      messages.forEach((message, index) => {
        messagesElement.push(
          <ShoutboxElement
            key={message?.shoutboxId}
            message={message}
            handleDialog={handleDialog}
            isPreview={false}
            isAdmin={isAdmin}
            handleReactionAdd={handleReactionAdd}
            handleReactionDelete={handleReactionDelete}
            index={index}
            user={{ personId: userId }}
          />
        );
      });
      return messagesElement;
    }
  };

  return (
    <div className="shoutbox_container_body">
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          350: 1,
          730: 2,
          1280: 3,
          1600: 4,
          1920: 5,
          2280: 6,
        }}
      >
        <Masonry columnsCount={4}>{renderMessagesElements()}</Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default memo(ShoutboxContainer);
