/**
 * Renders the data table displaying the current page of addresses.
 * If no address data is available, it will display a single row with a "No data" message.
 * Will render an error message if something went wrong during rendering.
 *
 * @returns {JSX.Element}
 */
import React from "react";
import { isDesktop, withOrientationChange } from "react-device-detect";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS, PAGE_LINK_SIZES } from "assets/staticData/enums";

const AddressResultTable = (props) => {
  const {
    intl,
    isLandscape,
    params: {
      displayedAddresses,
      first,
      fetchPending,
      totalAddresses,
      rows,
      rowsPerPage,
      selectedRow,
    },
    handlePageChange,
    handleSelectionChange,
  } = props;

  const responsiveAddressCellTemplate = (rowData) => {
    const { line1, line2 } = rowData;
    return (
      <div>
        <div>{line1}</div>
        <div>{line2}</div>
      </div>
    );
  };

  const generateCustomerFieldBody = (rowData) => {
    return (
      <div className="column-customer-name">
        {rowData?.peppolParticipantExists && (
          <img
            src="assets/layout/images/peppol-icon-alone.png"
            height={"40px"}
            alt="peppol icon"
            title="Peppol"
          ></img>
        )}
        <span>{rowData?.customerName}</span>
      </div>
    );
  };

  const {
    CUSTOMERS_FILTER_ADDRESS,
    CUSTOMERS_ADDRESS_LINE_1_LABEL,
    CUSTOMERS_ADDRESS_LINE_2_LABEL,
    CUSTOMERS_ADDRESS_CITY_LABEL,
    CUSTOMERS_ADDRESS_ZIP_LABEL,
    CUSTOMERS_FILTER_NAME,
    ERROR_NO_DATA,
    ERROR_RENDER,
  } = MESSAGE_KEYS;
  let columns = [];
  if (isDesktop || isLandscape) {
    columns = [
      <Column
        key={`col_adr_name`}
        field="customerName"
        header={intl.formatMessage({
          id: CUSTOMERS_FILTER_NAME,
        })}
        body={generateCustomerFieldBody}
      />,
      <Column
        key={`col_adr_line1`}
        field="line1"
        header={intl.formatMessage({
          id: CUSTOMERS_ADDRESS_LINE_1_LABEL,
        })}
      />,
      <Column
        key={`col_adr_line2`}
        field="line2"
        header={intl.formatMessage({
          id: CUSTOMERS_ADDRESS_LINE_2_LABEL,
        })}
      />,
    ];
  } else {
    columns = [
      <Column
        key={`col_adr_address`}
        header={intl.formatMessage({
          id: CUSTOMERS_FILTER_ADDRESS,
        })}
        body={responsiveAddressCellTemplate}
      />,
    ];
  }
  columns.push(
    <Column
      key={`col_adr_city`}
      field="countryProvince"
      header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_CITY_LABEL })}
    />,
    <Column
      key={`col_adr_city`}
      field="zipCode"
      header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_ZIP_LABEL })}
    />
  );

  try {
    return (
      <div>
        <DataTable
          value={displayedAddresses}
          selectionMode="single"
          dataKey="addressId"
          selection={selectedRow}
          onSelectionChange={(event) => {
            handleSelectionChange(event.value || selectedRow);
          }}
          emptyMessage={intl.formatMessage({
            id: ERROR_NO_DATA,
          })}
          loading={fetchPending}
          className="p-datatable-sm"
        >
          {columns}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalAddresses}
          rowsPerPageOptions={rowsPerPage}
          onPageChange={(e) => handlePageChange(e)}
          pageLinkSize={
            isDesktop || isLandscape
              ? PAGE_LINK_SIZES.DESKTOP
              : PAGE_LINK_SIZES.MOBILE
          }
        />
      </div>
    );
  } catch (renderException) {
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default withOrientationChange(injectIntl(AddressResultTable));
