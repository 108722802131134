import React from "react";
// PrimeReact components
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
// Custom components
import {
  CustomerSelector,
  FloatingTextInput,
  TranslatedCB,
  CheckboxText,
} from "components/common";
import { PaymentStatusIcon } from "./";
// Localization
import { injectIntl } from "react-intl";
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Helper functions
import {
  initLogger,
  hasValueChanged,
  equalObjects,
  sendQuery,
} from "common/Helpers";
// Redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// Static data
import { GENDERS, TITLES } from "assets/staticData/combodata";

const logger = initLogger("Customer_input_form");

const EMPTY_STATE = {
  inputCustomer: null,

  inputFirstName: "",
  inputLastName: "",
  inputGirlName: "",
  inputMatriculation: "",
  inputPhone: "",
  inputAdditionalPhone: "",
  inputSex: null,
  inputTitle: null,
  inputEmail: "",

  inputIsOverweight: false,
  inputWeight: null,
  inputHasStairs: false,
  inputNumberStairs: null,
  inputOwnWheelchair: false,
  inputElevator: false,
  inputVaccinated: false,

  inputCreateCustomer: false,

  validFirstName: null,
  validLastName: null,

  addressId: null,

  notPaid: 0,
  lastInvoiceNumber: null,
  lastInvoiceDate: null,

  paymentFetchPending: false,

  validInsurance: null,
  customerUpdatePending: false,
};

class CustomerInputForm extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.valueModified(prevState)) {
      this.props.setBaseValue("appointmentCustomer", this.mapInputsToDTO());
    }
    if (!equalObjects(prevProps.value, this.props.value, "personId")) {
      this.initInputs();
    }
  };

  /**
   *
   * @returns {Boolean} True if a state value was changed, false else.
   */
  valueModified = (prevState) => {
    const {
      inputFirstName,
      inputLastName,
      inputGirlName,
      inputElevator,
      inputHasStairs,
      inputIsOverweight,
      inputMatriculation,
      inputNumberStairs,
      inputOwnWheelchair,
      inputVaccinated,
      inputPhone,
      inputAdditionalPhone,
      inputSex,
      inputTitle,
      inputWeight,
      inputCustomer,
      inputCreateCustomer,
      inputEmail,
    } = this.state;
    let isModified = false;
    try {
      isModified =
        hasValueChanged(inputFirstName, prevState.inputFirstName) ||
        hasValueChanged(inputLastName, prevState.inputLastName) ||
        hasValueChanged(inputGirlName, prevState.inputGirlName) ||
        hasValueChanged(inputElevator, prevState.inputElevator) ||
        hasValueChanged(inputHasStairs, prevState.inputHasStairs) ||
        hasValueChanged(inputIsOverweight, prevState.inputIsOverweight) ||
        hasValueChanged(inputMatriculation, prevState.inputMatriculation) ||
        hasValueChanged(inputNumberStairs, prevState.inputNumberStairs) ||
        hasValueChanged(inputOwnWheelchair, prevState.inputOwnWheelchair) ||
        hasValueChanged(inputVaccinated, prevState.inputVaccinated) ||
        hasValueChanged(inputPhone, prevState.inputPhone) ||
        hasValueChanged(inputAdditionalPhone, prevState.inputAdditionalPhone) ||
        hasValueChanged(inputSex, prevState.inputSex) ||
        hasValueChanged(inputTitle, prevState.inputTitle) ||
        hasValueChanged(inputWeight, prevState.inputWeight) ||
        hasValueChanged(inputCreateCustomer, prevState.inputCreateCustomer) ||
        hasValueChanged(inputEmail, prevState.inputEmail) ||
        !equalObjects(inputCustomer, prevState.inputCustomer, "personId");
      return isModified;
    } catch (checkException) {
      logger.warn("Exception on value modified", this.state);
      return false;
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value) {
      this.setState({
        ...this.mapDTOToInputs(value),
      });
      if (value.personId) this.fetchCustomerPaymentInfo(value.personId);
    } else {
      this.setState({ ...EMPTY_STATE });
    }
  };

  fetchCustomerPaymentInfo = (personId) => {
    this.setState({ paymentFetchPending: true });
    sendQuery(`${QUERIES.GET_CUSTOMER_PAYMENT_INFO}${personId}`, "get").then(
      (response) => {
        const { notPaid, lastInvoiceNumber, lastInvoiceDate } = response;
        this.setState({
          notPaid,
          lastInvoiceNumber,
          lastInvoiceDate,
          paymentFetchPending: false,
        });
      },
      (error) => {
        logger.warn(error);
        this.setState({
          lastInvoiceNumber: "-",
          lastInvoiceDate: "-",
          notPaid: "-",
          paymentFetchPending: false,
        });
      }
    );
  };

  handleCustomerSelection = (customer) => {
    const { setBaseValue, resetValue } = this.props;
    let newState;
    if (customer) {
      newState = { ...this.mapDTOToInputs(customer) };
      this.fetchCustomerPaymentInfo(customer.personId);
    } else {
      newState = resetValue
        ? { ...this.mapDTOToInputs(resetValue) }
        : { ...EMPTY_STATE };
    }
    this.setState({ ...newState });
    this.fetchCustomerAddress(customer);
    setBaseValue("appointmentCustomer", customer);
  };

  fetchCustomerAddress = (newCustomer) => {
    const { setBaseValue } = this.props;
    logger.info("CUSTOMER HAS ADDRESS?", newCustomer);
    try {
      if (newCustomer && newCustomer.addressId) {
        const { addressId } = newCustomer;
        sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${addressId}`, "GET").then(
          (response) => {
            if (response && response.address) {
              const { address } = response;
              const { firstname, lastname } = newCustomer;
              setBaseValue("appointmentCustomerAddress", address);

              let customerName;
              if (firstname || lastname) {
                customerName = `${firstname ? firstname : ""} ${
                  lastname ? lastname : ""
                }`;
              } else {
                customerName = address.countryProvince
                  ? address.countryProvince
                  : "";
              }

              let departureAddress = {
                keyword: address.searchkey
                  ? address.searchkey
                  : address.countryProvince,
                room: "",
                department: "",
                address: {
                  ...address,
                  name: address.countryProvince,
                  customerName,
                },
                text: "",
                departureAddress1: address.line1,
                departureAddress2: address.line2,
                departureZipcode: address.zipCode,
              };

              setBaseValue("appointmentDeparture", departureAddress);
            } else {
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary: this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_NO_DATA,
                }),
              });
            }
          },
          (error) => {
            logger.warn("Error on update customer address", error, addressId);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_FETCH,
              }),
            });
          }
        );
      }
    } catch (updateException) {
      logger.warn(
        "Exception on update customer address",
        updateException,
        newCustomer
      );
    }
  };

  mapDTOToInputs = (value) => {
    let mappedValue;
    try {
      const {
        firstname,
        lastname,
        girlName,
        sexId,
        healthInsuranceNumber,
        phoneHome,
        phoneWork,
        gsm,
        titleId,
        active,

        ownWheelchair,
        elevator,
        stairsCount,
        stairs,
        weight,
        overweight,
        addressId,
        addressIdInvoice,

        email,

        vaccinated,
        patientVaccinated,

        createCustomer,

        title,        
      } = value;

      let newAddressId;
      if (addressIdInvoice) {
        newAddressId = addressIdInvoice;
      } else {
        newAddressId = addressId ? addressId : null;
      }

      let theSex = typeof sexId === "number" ? sexId : parseInt(sexId);
      let inputNumberStairs = 0;
      if (stairsCount) {
        inputNumberStairs =
          typeof stairsCount === "number" ? stairsCount : parseInt(stairsCount);
      }
      let inputWeight = 0.0;
      if (weight) {
        inputWeight = typeof weight === "number" ? weight : parseFloat(weight);
      }

      let phone2 = "";
      if (gsm || phoneWork) {
        phone2 = gsm ? gsm : phoneWork;
      }

      let inputTitle = TITLES.find(
        (titleEntry) => titleEntry.titleId === (title ? title : titleId) // Inconsistent prop naming by BE
      );
      if (!inputTitle) {
        inputTitle = TITLES[0];
      }

      mappedValue = {
        inputCustomer: value && value.personId ? value : null,
        inputFirstName: firstname ? firstname : "",
        inputLastName: lastname ? lastname : "",
        inputGirlName: girlName ? girlName : "",
        inputMatriculation: healthInsuranceNumber ? healthInsuranceNumber : "",
        inputPhone: phoneHome ? phoneHome : "",
        inputAdditionalPhone: phone2,
        inputSex: GENDERS.find((sex) => {
          return sex.sexId === theSex;
        }),
        inputTitle,
        inputActive: active,        

        inputEmail: email ? email : "",

        inputIsOverweight: overweight ? overweight : false,
        inputWeight,
        inputHasStairs: stairs ? stairs : false,
        inputNumberStairs,
        inputOwnWheelchair: ownWheelchair ? ownWheelchair : false,
        inputVaccinated: vaccinated || patientVaccinated,
        inputElevator: elevator ? elevator : false,
        inputCreateCustomer: createCustomer ? createCustomer : false,

        addressId: newAddressId,
      };
      return mappedValue;
    } catch (mapException) {
      logger.warn("Exception on map DTO to inputs", mapException, value);
      return { ...EMPTY_STATE };
    }
  };

  mapInputsToDTO = () => {
    const {
      inputFirstName,
      inputLastName,
      inputGirlName,
      inputElevator,
      inputHasStairs,
      inputIsOverweight,
      inputMatriculation,
      inputNumberStairs,
      inputOwnWheelchair,
      inputVaccinated,
      inputPhone,
      inputAdditionalPhone,
      inputSex,
      inputTitle,
      inputWeight,


      inputEmail,

      inputCustomer,

      inputCreateCustomer,
      addressId,
    } = this.state;

    let customerSelected =
      inputCustomer && inputCustomer.personId ? true : false;
    try {
      return {
        personId: customerSelected ? inputCustomer.personId : null,
        firstname: inputFirstName,
        lastname: inputLastName,
        girlName: inputGirlName,
        healthInsuranceNumber: inputMatriculation,
        phoneHome: inputPhone,
        gsm: inputAdditionalPhone,
        titleId: inputTitle ? inputTitle.titleId : 0,
        sexId: inputSex ? inputSex.sexId : 2,
        active: customerSelected ? inputCustomer.active : true,
        dateOfBirth: this.props.value.dateOfBirth,

        email: inputEmail,

        ownWheelchair: inputOwnWheelchair,
        elevator: inputElevator,
        stairsCount: inputNumberStairs,
        stairs: inputHasStairs,
        weight: inputWeight,
        overweight: inputIsOverweight,

        addressId: addressId ? addressId : null,
        createCustomer: customerSelected ? false : inputCreateCustomer,
        vaccinated: inputVaccinated,
        patientVaccinated: inputVaccinated,
      };
    } catch (mapException) {
      logger.warn("Exception on map inputs to DTO", mapException, this.state);
      return null;
    }
  };

  generateCustomerFilter = () => {
    const {
      inputFirstName,
      inputLastName,
      inputGirlName,
      inputMatriculation,
      inputPhone,
      inputAdditionalPhone,
      inputEmail,
    } = this.state;
    const { city, address } = this.props;
    let searchParams = "";
    [
      inputFirstName,
      inputLastName,
      inputGirlName,
      inputMatriculation,
      inputPhone,
      inputAdditionalPhone,
      inputEmail,
      city,
      address,
    ]
      .filter((input) => input)
      .forEach((validInput) => {
        searchParams += ` ${validInput}`;
      });
    return searchParams;
  };

  render = () => {
    const { intl, validMatriculation } = this.props;
    const {
      ERROR_RENDER,
      CUSTOMERS_FIRST_NAME_LABEL,
      CUSTOMERS_LAST_NAME_LABEL,
      CUSTOMERS_PHONE_LABEL,
      APPOINTMENTS_PHONE_LABEL,
      CUSTOMERS_MATRICULATION_LABEL,
      CUSTOMERS_GENDER_LABEL,
      CUSTOMERS_MIDDLE_NAME_LABEL,
      CUSTOMERS_TITLE_LABEL,
      BILLS_IS_OVERWEIGHT_LABEL,
      BILLS_WEIGHT_LABEL,
      BILLS_HAS_STAIRS_LABEL,
      BILLS_NUMBER_STAIRS_LABEL,
      BILLS_WHEELCHAIR_LABEL,
      BILLS_ELEVATOR_LABEL,
      APPOINTMENTS_CREATE_CUSTOMER_LABEL,
      CUSTOMERS_EMAIL_LABEL,
      APPOINTMENTS_VACCINATED_LABEL,
    } = MESSAGE_KEYS;
    try {
      const {
        inputCustomer,

        inputFirstName,
        inputLastName,
        inputGirlName,
        inputMatriculation,
        inputPhone,
        inputAdditionalPhone,
        inputSex,
        inputTitle,

        inputEmail,

        inputIsOverweight,
        inputWeight,
        inputHasStairs,
        inputNumberStairs,
        inputOwnWheelchair,
        inputElevator,

        inputCreateCustomer,

        validFirstName,
        validLastName,

        lastInvoiceNumber,
        lastInvoiceDate,
        notPaid,
        paymentFetchPending,

        inputVaccinated,
      } = this.state;

      const tempCustomer = this.generateCustomerFilter();

      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <div className="flex align-items-center justify-content-between">
            <CustomerSelector
              value={inputCustomer}
              onChange={(selection) => this.handleCustomerSelection(selection)}
              tempCustomer={tempCustomer}
            />
            {paymentFetchPending ? (
              <i
                className="pi pi-spin pi-spinner mx-1"
                style={{ fontSize: "1.4em" }}
              ></i>
            ) : (
              <PaymentStatusIcon
                value={{
                  notPaid,
                  lastInvoiceNumber,
                  lastInvoiceDate,
                  personId: inputCustomer?.personId
                    ? inputCustomer.personId
                    : null,
                  comment: inputCustomer?.comment,
                  sylvain: inputCustomer?.sylvain,
                }}
              />
            )}

            <div className="flex align-items-center">
              <Checkbox
                inputId="cb_create_customer"
                className="mr-2"
                checked={inputCreateCustomer}
                onChange={(e) => {
                  this.setState({ inputCreateCustomer: e.checked });
                }}
                disabled={inputCustomer && inputCustomer.personId}
              />
              <span className="text-nowrap">
                {intl.formatMessage({ id: APPOINTMENTS_CREATE_CUSTOMER_LABEL })}
              </span>
            </div>
          </div>
          <div className="flex align-items-end justify-content-between mt-1">
            <TranslatedCB
              value={inputTitle}
              options={TITLES}
              onChange={(selection) => {
                this.setState({ inputTitle: selection });
              }}
              placeholder={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
              className="mr-1"
            />

            <FloatingTextInput
              value={inputFirstName}
              label={intl.formatMessage({
                id: CUSTOMERS_FIRST_NAME_LABEL,
              })}
              onChange={(e) => {
                this.setState({ inputFirstName: e.target.value });
              }}
              valid={validFirstName}
              className="mr-1"
              boldInput={true}
            />

            <FloatingTextInput
              value={inputLastName}
              label={intl.formatMessage({
                id: CUSTOMERS_LAST_NAME_LABEL,
              })}
              onChange={(e) => {
                this.setState({ inputLastName: e.target.value });
              }}
              valid={validLastName}
              boldInput={true}
            />
          </div>

          <div className="flex align-items-end justify-content-between">
            <TranslatedCB
              value={inputSex}
              options={GENDERS}
              onChange={(selection) => {
                this.setState({ inputSex: selection });
              }}
              placeholder={intl.formatMessage({ id: CUSTOMERS_GENDER_LABEL })}
              className="mr-1"
            />

            <FloatingTextInput
              value={inputMatriculation}
              label={intl.formatMessage({
                id: CUSTOMERS_MATRICULATION_LABEL,
              })}
              onChange={(e) => {
                this.setState({ inputMatriculation: e.target.value });
              }}
              valid={validMatriculation}
              className="mr-1"
            />

            <FloatingTextInput
              value={inputGirlName}
              label={intl.formatMessage({
                id: CUSTOMERS_MIDDLE_NAME_LABEL,
              })}
              onChange={(e) => {
                this.setState({ inputGirlName: e.target.value });
              }}
              boldInput={true}
            />
          </div>

          <div className="flex align-items-end justify-content-between">
            <FloatingTextInput
              value={inputPhone}
              label={intl.formatMessage({
                id: CUSTOMERS_PHONE_LABEL,
              })}
              onChange={(e) => {
                this.setState({ inputPhone: e.target.value });
              }}
              className="mr-1"
            />
            <FloatingTextInput
              value={inputAdditionalPhone}
              onChange={(e) =>
                this.setState({
                  inputAdditionalPhone: e.target.value,
                })
              }
              label={intl.formatMessage({ id: APPOINTMENTS_PHONE_LABEL })}
              className="mr-1"
            />
            <FloatingTextInput
              value={inputEmail}
              label={intl.formatMessage({
                id: CUSTOMERS_EMAIL_LABEL,
              })}
              onChange={(e) =>
                this.setState({
                  inputEmail: e.target.value,
                })
              }
            />
          </div>

          <div className="flex align-items-end justify-content-between">
            <div className="mr-1">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="cb_vaccinated"
                  className="mr-2"
                  checked={inputVaccinated}
                  onChange={(e) => {
                    this.setState({ inputVaccinated: e.checked });
                  }}
                />
                <span className="text-nowrap">
                  {intl.formatMessage({ id: APPOINTMENTS_VACCINATED_LABEL })}
                </span>
              </div>

              <div className="flex align-items-center">
                <Checkbox
                  inputId="cb_wheelchair"
                  className="mr-2"
                  checked={inputOwnWheelchair}
                  onChange={(e) => {
                    this.setState({ inputOwnWheelchair: e.checked });
                  }}
                />
                <span className="text-nowrap">
                  {intl.formatMessage({ id: BILLS_WHEELCHAIR_LABEL })}
                </span>
              </div>

              <div className="flex align-items-center">
                <Checkbox
                  inputId="cb_elevator"
                  className="mr-2"
                  checked={inputElevator}
                  onChange={(e) => {
                    this.setState({ inputElevator: e.checked });
                  }}
                />
                <span>{intl.formatMessage({ id: BILLS_ELEVATOR_LABEL })}</span>
              </div>
            </div>

            <CheckboxText
              inputId="cbt_overweight"
              value={inputWeight}
              checked={inputIsOverweight}
              onChange={(value) => {
                this.setState({ inputWeight: value });
              }}
              onToggle={(toggle) => {
                this.setState({ inputIsOverweight: toggle });
              }}
              label={intl.formatMessage({ id: BILLS_IS_OVERWEIGHT_LABEL })}
              placeholder={intl.formatMessage({ id: BILLS_WEIGHT_LABEL })}
              suffix="kg"
              isFloat
              className="mr-1"
            />

            <CheckboxText
              id="cbt_stairs"
              value={inputNumberStairs}
              checked={inputHasStairs}
              onChange={(value) => {
                this.setState({ inputNumberStairs: value });
              }}
              onToggle={(toggle) => {
                this.setState({ inputHasStairs: toggle });
              }}
              label={intl.formatMessage({ id: BILLS_HAS_STAIRS_LABEL })}
              placeholder={intl.formatMessage({
                id: BILLS_NUMBER_STAIRS_LABEL,
              })}
            />
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on render new customer inputs", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      appWizard: {
        appointmentCustomer,
        resetCustomer,
        appointmentCustomerAddress,
      },
    } = state;

    return {
      value: appointmentCustomer,
      resetValue: resetCustomer,
      address: appointmentCustomerAddress.line1,
      city: appointmentCustomerAddress.countryProvince,
    };
  } catch (exception) {
    logger.error(exception);
    return {
      value: null,
      resetValue: null,
      address: "",
      city: "",
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(CustomerInputForm)
);
