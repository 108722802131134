import React from "react";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Helper functions
import {
  priceCellTemplate,
  responsiveTextCellTemplate,
  numberToPriceString,
  changePendingConfirm,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";

const BillPositionEditLayout = (props) => {
  const {
    BILLS_ADD_POSITION_BUTTON_LABEL,
    BILLS_POSITIONS_AMOUNT_LABEL,
    BILLS_POSITIONS_TOTAL_AMOUNT_LABEL,
    BILLS_POSITIONS_DESCRIPTION_LABEL,
    BILLS_POSITIONS_QUANTITY_LABEL,
    ERROR_NO_DATA,
  } = MESSAGE_KEYS;
  const {
    intl,
    value,
    handleDelete,
    handleAdd,
    handleEdit,
    handleReorder,
    isLandscape,
    totalAmount,
  } = props;

  const buttonCellTemplate = (rowData, index) => {
    const { productName, active } = rowData;
    let delButton, editButton;
    if (active === true) {
      let value = productName ? productName : "";
      delButton = (
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger mr-1"
          onClick={() =>
            changePendingConfirm(index.rowIndex, handleDelete, intl, value)
          }
        />
      );
      editButton = (
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={() => {
            handleEdit(index.rowIndex);
          }}
        />
      );
    } else {
      delButton = <i className="pi pi-times" style={{ color: "red" }} />;
      editButton = <></>;
    }

    return (
      <div className="flex flex-row justify-content-between">
        {delButton}
        {editButton}
      </div>
    );
  };

  const addButtonTemplate = (
    <div className="table-header">
      <Button
        icon="pi pi-plus-circle"
        style={{ float: "right" }}
        label={intl.formatMessage({
          id: BILLS_ADD_POSITION_BUTTON_LABEL,
        })}
        onClick={handleAdd}
      />
    </div>
  );

  const priceFooterTemplate = () => {
    return <div>{numberToPriceString(totalAmount ? totalAmount : 0.0)}</div>;
  };

  let columns;
  if (isDesktop || isLandscape) {
    columns = [
      <Column
        key="bill_position_description_column"
        field="productName"
        header={intl.formatMessage({
          id: BILLS_POSITIONS_DESCRIPTION_LABEL,
        })}
        style={{ textAlign: "left" }}
      />,
      <Column
        key="bill_position_quantity_column"
        field="amount"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_QUANTITY_LABEL })}
      />,
      <Column
        key="bill_position_amount_column"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_AMOUNT_LABEL })}
        body={(rowData) => {
          return priceCellTemplate(rowData.price);
        }}
      />,
      <Column
        key="bill_position_total_column"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_TOTAL_AMOUNT_LABEL })}
        body={(rowData) => {
          return priceCellTemplate(
            (rowData.price ? rowData.price : 0) *
              (rowData.amount ? rowData.amount : 0)
          );
        }}
        footer={priceFooterTemplate}
      />,
      <Column
        key="bill_position_edit_column"
        body={buttonCellTemplate}
        style={{ textAlign: "center" }}
      />,
    ];
  } else {
    columns = [
      <Column
        key="bill_position_description_column"
        style={{ textAlign: "center" }}
        body={(rowData) => {
          return responsiveTextCellTemplate(rowData.productName);
        }}
      />,
      <Column
        key="bill_position_quantity_column"
        field="amount"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_QUANTITY_LABEL })}
      />,
      <Column
        key="bill_position_amount_column"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_AMOUNT_LABEL })}
        body={(rowData) => {
          return priceCellTemplate(rowData.price);
        }}
      />,
      <Column
        key="bill_position_total_column"
        style={{ textAlign: "right" }}
        header={intl.formatMessage({ id: BILLS_POSITIONS_TOTAL_AMOUNT_LABEL })}
        body={(rowData) => {
          return priceCellTemplate(
            (rowData.price ? rowData.price : 0) *
              (rowData.amount ? rowData.amount : 0)
          );
        }}
        footer={priceFooterTemplate}
      />,
      <Column
        key="bill_position_edit_column"
        body={buttonCellTemplate}
        style={{ textAlign: "center" }}
      />,
    ];
  }

  return (
    <div>
      <DataTable
        value={value}
        emptyMessage={intl.formatMessage({ id: ERROR_NO_DATA })}
        header={addButtonTemplate}
        className="p-datatable-sm"
        onRowReorder={(e) => handleReorder(e.value)}
        reorderableRows
      >
        <Column rowReorder style={{ width: "3em" }} />
        {columns}
      </DataTable>
    </div>
  );
};

export default injectIntl(withOrientationChange(BillPositionEditLayout));
