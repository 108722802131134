/**
 * This component renders the account view, which allows the current user to update his or her account information.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact Components
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
// Custom Components
import { UserEditLayout } from "components/common";
// Helper functions
import { initLogger, sendQuery } from "common/Helpers";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Style
import "./Style.scss";
// Logging
const logger = initLogger("account_view");

class AccountView extends React.Component {
  state = {
    userData: null,
    fetchPending: false,
  };

  /**
   * Update top bar title on component mount.
   */
  componentDidMount = () => {
    const { currentUser, setTopbarTitle } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_ACCOUNT })
    );
    if (currentUser?.personId) {
      sendQuery(
        `${QUERIES.GET_USER_BY_ID}${currentUser.personId}`,
        "GET",
        null
      ).then(
        (response) => {
          if (response?.user) {
            this.setState({ userData: response.user, fetchPending: false });
          }
        },
        (error) => {
          logger.error(error);
          this.setState({ userData: null, fetchPending: false });
        }
      );
    }
  };

  /**
   * Renders the main content of this view.
   * If data loading is pending, it will render a loading animation, the UserEditLayout component is loaded else.
   * If an error occurred during loading, an error message will be rendered instead.
   *
   * @returns {JSX.Element}
   */
  renderContent = () => {
    const { intl } = this.props;
    const { fetchPending, userData } = this.state;
    if (fetchPending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else if (userData) {
      return (
        <div>
          <UserEditLayout selectedUser={userData} />
        </div>
      );
    } else {
      return (
        <div>
          {intl.formatMessage({
            id: MESSAGE_KEYS.ACCOUNT_ERROR_COULD_NOT_LOAD,
          })}
        </div>
      );
    }
  };

  render = () => {
    const { intl } = this.props;
    return (
      <div>
        <Panel
          header={intl.formatMessage({ id: MESSAGE_KEYS.ACCOUNT_TITLE_LABEL })}
        >
          {this.renderContent()}
        </Panel>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
    ...state.application,
  };
};

export default connect(mapStateToProps, { setTopbarTitle })(
  injectIntl(AccountView)
);
