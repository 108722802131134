import React from "react";
// React redux
import { connect } from "react-redux";
import { setTopbarTitle, setShiftTypes } from "actions";
// Responsive
import { isMobile } from "react-device-detect";
// Fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import frLocale from "@fullcalendar/core/locales/fr";
import listPlugin from "@fullcalendar/list";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
// Custom components
import { ShiftEventLayout, ShiftEventPopup } from "./components";
import { CalendarToolbar } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import {
  initLogger,
  sendQuery,
  getCurrentUserLocale,
  getRandomColor,
  dateToQueryString,
  dateToISOString,
  fetchHolidays,
  setShiftOrder,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  LOCALES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";

const logger = initLogger("on calls view");
class ShiftsView extends React.Component {
  calendarRef = React.createRef();

  state = {
    currentEvents: [],
    selectedAppointment: null,
    displayedDate: new Date(),
    drivers: [],
    currentLocale: getCurrentUserLocale(),

    eventFetchPending: false,

    shifts: [],
    editDialogVisible: false,
  };

  componentDidMount = () => {
    const { shiftTypes } = this.props;
    this.fetchDrivers();
    // Check redux for data, fetch from BE if not available.
    this.fetchShiftTypes().then(
      () => {
        this.initDraggables();
        this.fetchAppointments();
      },
      (error) => {
        logger.warn(error);
        if (shiftTypes && shiftTypes.length > 0) {
          this.setState({ shifts: setShiftOrder(shiftTypes) }, () => {
            this.initDraggables();
          });
          this.fetchAppointments();
        }
      }
    );

    this.props.setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_SHIFTS })
    );
  };

  /**
   * Adds drag&drop functionality to all draggable elements. (identified by their class name fc-event)
   */
  initDraggables = () => {
    try {
      // Fetch all drag objects and add draggable functionality.
      let draggables = [...document.getElementsByClassName("fc-event")];
      if (draggables && draggables.length > 0) {
        draggables.forEach((element) => {
          let eventType = this.state.shifts.find((shift) => {
            return shift.appointmentTypeId === parseInt(element.id);
          });
          if (eventType) {
            let eventBackgroundColor;
            if (eventType) {
              eventBackgroundColor = eventType.color;
            } else {
              eventBackgroundColor = null;
            }

            new Draggable(element, {
              eventData: {
                title: element.innerHTML,
                allDay: true,
                backgroundColor: eventBackgroundColor,
                borderColor: eventBackgroundColor,
                extendedProps: {
                  members: [],
                  limit: 0,
                  appointmentTypeId: eventType.appointmentTypeId,
                },
              },
            });
          }
        });
      }
    } catch (mountException) {
      logger.error(mountException);
    }
  };

  fetchAppointments = () => {
    try {
      const { displayedDate } = this.state;
      let startDate = new Date(displayedDate.getTime());
      startDate.setDate(1);
      startDate.setDate(startDate.getDate() - 8);

      let endDate = new Date(
        displayedDate.getFullYear(),
        displayedDate.getMonth() + 1,
        0
      );
      endDate.setDate(endDate.getDate() + 8);
      this.setState({
        eventFetchPending: true,
      });

      let eventSources = this.calendarRef.current.getApi().getEvents();
      // Clear calendar events to prevent duplicates after adding new event.
      let len = eventSources.length;
      for (let i = 0; i < len; i++) {
        eventSources[i].remove();
      }

      sendQuery(
        `${QUERIES.GET_SHIFTS_BY_DATE}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}`,
        "get"
      ).then(
        (response) => {
          this.mapResponseToEvents(response, startDate, endDate).then(
            (currentEvents) => {
              fetchHolidays(startDate, endDate).then(
                (holidays) => {
                  this.setState({
                    currentEvents: [...currentEvents, ...holidays],
                    eventFetchPending: false,
                  });
                },
                () => {
                  this.setState({ currentEvents, eventFetchPending: false });
                }
              );
            },
            (error) => {
              logger.warn(error);
              this.setState({
                currentEvents: [],
                eventFetchPending: false,
              });
            }
          );
        },
        (error) => {
          logger.warn(error);
          this.setState({
            eventFetchPending: false,
          });
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({
        eventFetchPending: false,
      });
    }
  };

  /**
   * Fetch & store appointment types for draggable initialization.
   * @returns {Promise}
   */
  fetchShiftTypes = () => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.GET_SHIFT_TYPES, "get").then(
          (response) => {
            if (response) {
              try {
                const shifts = setShiftOrder(response);
                this.setState({ shifts });
                this.props.setShiftTypes(shifts);
                resolve();
              } catch (e) {
                logger.warn(e);
                reject(e);
              }
            } else {
              reject("No types found.");
            }
          },
          (error) => {
            reject(error);
          }
        );
      } catch (fetchException) {
        logger.warn(fetchException);
        reject(fetchException);
      }
    });
  };

  fetchDrivers = () => {
    sendQuery(QUERIES.GET_DRIVERS, "get").then(
      (response) => {
        if (response) {
          this.setState({ drivers: [...response] });
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
  };

  /**
   * Gets called when the date in the calendar component is changed. It will update the state and call the Fullcalendar function to display the selected date.
   *
   * @param {Object} e - The onChange event object of the calendar component.
   * @param {Date} e.value - The selected date.
   */
  handleDateChange = (e) => {
    try {
      this.setState({ displayedDate: e.value }, () => {
        /** @type {Date} */
        let date = e.value;
        if (this.calendarRef.current && date) {
          this.calendarRef.current
            .getApi()
            .gotoDate(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            );
          this.fetchAppointments();
        }
      });
    } catch (changeException) {
      logger.error(changeException);
    }
  };

  handleEventClick = (clickInfo) => {
    this.setState({
      selectedAppointment: clickInfo.event,
      selection: [...clickInfo.event._def.extendedProps.members],
    });
    if (isMobile) {
      this.openEditDialog(null);
    }
  };

  handleEventSave = ({ event }) => {
    try {
      let data = this.mapEventToDTO(event);
      logger.info("SAVING", data);
      if (data) {
        sendQuery(QUERIES.EDIT_SHIFT, "post", data).then(
          (response) => {
            if (response && response.appointmentId) {
              logger.info("SAVED", event, response);
              event.setExtendedProp("appointmentId", response.appointmentId);
            }
          },
          (error) => {
            logger.warn(error);
          }
        );
      }
    } catch (dropException) {
      logger.warn(dropException);
    }
  };

  handleEventDelete = () => {
    const { intl } = this.props;
    const { SHIFTS_UPDATE_FAIL, SHIFTS_DELETE_SUCCESS } = MESSAGE_KEYS;
    const { selectedAppointment } = this.state;
    try {
      if (selectedAppointment?._def.extendedProps.appointmentId) {
        sendQuery(
          `${QUERIES.DELETE_SHIFT}${selectedAppointment._def.extendedProps.appointmentId}`,
          "DELETE"
        ).then(
          () => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: intl.formatMessage({
                id: SHIFTS_DELETE_SUCCESS,
              }),
            });
            this.closeEditDialog();
            selectedAppointment.remove();
          },
          (error) => {
            logger.log(error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({
                id: SHIFTS_UPDATE_FAIL,
              }),
            });
          }
        );
      }
    } catch (deleteException) {
      logger.log(deleteException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: SHIFTS_UPDATE_FAIL }),
      });
    }
  };

  /**
   *
   * @param {Date} day
   * @param {Number} eventType
   * @param {Array<Object>} events
   * @returns {Boolean}
   */
  checkDayEvent = (day, eventType, events) => {
    try {
      day.setHours(0, 0, 0, 0);
      let searchResult = events.findIndex((searchEvent) => {
        const {
          starttime,

          typeOfAppointment,
        } = searchEvent;
        const normalStart = new Date(starttime);
        normalStart.setHours(0, 0, 0, 0);

        return (
          typeOfAppointment === eventType &&
          normalStart.getTime() === day.getTime()
        );
      });
      return searchResult >= 0;
    } catch (searchExeption) {
      logger.error(searchExeption);
      return false;
    }
  };

  /**
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {Array<Object>}
   */
  generateEmptyEvents = (startDate, endDate, actualEvents) => {
    const { shifts } = this.state;
    let events = [];
    for (
      let dayCounter = new Date(startDate);
      dayCounter <= endDate;
      dayCounter.setDate(dayCounter.getDate() + 1)
    ) {
      shifts.forEach((shift) => {
        if (
          !this.checkDayEvent(dayCounter, shift.appointmentTypeId, actualEvents)
        ) {
          let eventData = this.getTypeValues(shift.appointmentTypeId);
          events.push({
            id: null,
            title: eventData.title,
            start: new Date(dayCounter),
            end: null,
            allDay: true,
            extendedProps: {
              limit: 0,
              members: [],
              appointmentTypeId: shift.appointmentTypeId,
              appointmentId: null,
              onCallClinic: "",
            },
            backgroundColor: eventData.eventColor,
            borderColor: eventData.eventColor,
            textColor: "white",
          });
        }
      });
    }
    return events;
  };

  /**@typedef {Object} shiftData
   * @property {string} title
   * @property {string} eventColor
   */
  /**
   *
   * @param {Number} typeId
   * @returns {shiftData}
   */
  getTypeValues = (typeId) => {
    const { shifts, currentLocale } = this.state;
    let eventData = {
      title: "",
      eventColor: "",
    };
    if (shifts) {
      let searchShift = shifts.find((entry) => {
        return entry.appointmentTypeId === typeId;
      });
      if (searchShift) {
        eventData.title =
          currentLocale === LOCALES.GERMAN.key
            ? searchShift.name
            : searchShift.nameFr;
        eventData.eventColor = searchShift.color
          ? searchShift.color
          : getRandomColor();
      }
    } else {
      eventData.title = this.props.intl.formatMessage({
        id: MESSAGE_KEYS.SHIFTS_ERROR_NO_SHIFT,
      });
    }
    return eventData;
  };

  /**
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {Array<Object>} response
   */
  mapResponseToEvents = (response, startDate, endDate) => {
    return new Promise((resolve, reject) => {
      try {
        logger.info("MAPPING RESPONSE", response);
        let events = this.generateEmptyEvents(startDate, endDate, response);
        if (response && response.length > 0) {
          response.forEach((entry) => {
            const {
              starttime,
              endtime,
              placesTotal,
              layerEmployees,
              appointmentId,
              typeOfAppointment,
              onCallClinic,
            } = entry;

            let eventData = this.getTypeValues(typeOfAppointment);

            events.push({
              id: appointmentId,
              title: eventData.title,
              start: new Date(starttime),
              end: new Date(endtime),
              allDay: true,
              extendedProps: {
                limit: placesTotal,
                members: [...layerEmployees],
                appointmentTypeId: typeOfAppointment,
                appointmentId,
                onCallClinic: onCallClinic ? onCallClinic : "",
              },
              backgroundColor: eventData.eventColor,
              borderColor: eventData.eventColor,
              textColor: "white",
            });
          });
        }
        resolve(events);
      } catch (mapException) {
        logger.warn(mapException);
        reject([]);
      }
    });
  };

  mapEventToDTO = (event) => {
    logger.info("MAPPING", event);
    try {
      const {
        _def: {
          extendedProps: {
            appointmentTypeId,
            members = [],
            appointmentId = null,
            onCallClinic,
          },
        },
        _instance: {
          range: { start },
        },
      } = event;
      return {
        appointmentId,
        starttime: dateToISOString(start),
        type: 4,
        typeOfAppointment: appointmentTypeId,
        active: true,
        layerEmployees: [...members],
        onCallClinic,
      };
    } catch (mappingException) {
      logger.warn(mappingException);
      return null;
    }
  };

  handleViewChange = (e) => {
    try {
      if (e?.value) {
        this.calendarRef.current.getApi().changeView(e.value, e.start);
      }
    } catch (exception) {
      logger.error(exception);
    }
  };

  handleShiftSave = (members, onCallClinic = "") => {
    const { selectedAppointment } = this.state;
    selectedAppointment.setExtendedProp("members", members);
    selectedAppointment.setExtendedProp("onCallClinic", onCallClinic);
    this.handleEventSave({ event: selectedAppointment });
    this.closeEditDialog();
  };

  handleEventChange = (event) => {
    try {
      const {
        event: {
          _instance: {
            range: { start, end },
          },
        },
      } = event;
      logger.info("CHANGED RANGE", start, end);
      let difference = Math.ceil(Math.abs(start - end) / (24 * 60 * 60 * 1000));
      let startDate = start <= end ? start : end;
      logger.info(
        `DAY DIFFERENCE ${difference} STARTING FROM ${startDate.toDateString()}`
      );
      if (difference > 1) {
        let calendarApi = this.calendarRef.current.getApi();
        // Was stretched over multiple days, generate events for individual dates.
        let keepId = true;
        for (let c = 0; c < difference; c++) {
          let newDate = new Date(startDate.getTime());
          newDate.setDate(newDate.getDate() + c);
          // Keep ID of original event, set id to null for follow ups to create new events.
          let newId = keepId
            ? event.event._def.extendedProps.appointmentId
            : null;
          // Update member appointment id.
          let members = [...event.event._def.extendedProps.members];
          if (!keepId && members.length > 0) {
            members.forEach((member) => {
              member.appointmentId = null;
              member.layerEmployeeId = null;
            });
          }
          calendarApi.addEvent({
            id: newId,
            title: event.event._def.title,
            start: newDate,
            end: newDate,
            allDay: event.event._def.allDay,
            extendedProps: {
              ...event.event._def.extendedProps,
              members,
              appointmentId: newId,
            },
            color: event.event._def.ui.backgroundColor,
          });
          // Unset flag to clear ids of follow up events.
          if (keepId) {
            keepId = false;
          }
        }
        event.event.remove();
      }
    } catch (splitException) {
      logger.warn(splitException);
    }
  };

  openEditDialog = (event) => {
    if (isMobile || event === null) {
      this.setState({ editDialogVisible: true });
    } else {
      this.op.toggle(event);
    }
  };

  closeEditDialog = () => {
    if (isMobile) {
      this.setState({ editDialogVisible: false });
    } else {
      this.op.hide();
    }
  };

  renderDraggables = () => {
    const { shifts, currentLocale } = this.state;
    const { intl } = this.props;
    let draggables = [];
    shifts.forEach((shift) => {
      let label =
        currentLocale === LOCALES.GERMAN.key ? shift.name : shift.nameFr;
      let dragger = (
        <div
          id={shift.appointmentTypeId}
          key={`dragg_${label}`}
          className="fc-event px-4 py-1 mr-1"
          style={{
            backgroundColor: shift.color ? shift.color : getRandomColor(),
            borderRadius: "5px",
            color: "white",
            cursor: "pointer",
          }}
        >
          {label}
        </div>
      );
      draggables.push(dragger);
    });

    return (
      <div className="mb-2">
        <h4>
          {intl.formatMessage({ id: MESSAGE_KEYS.SHIFTS_CREATE_BUTTON_LABEL })}
        </h4>
        <div id="external-events" className="flex">
          {draggables}
        </div>
      </div>
    );
  };

  renderEventContent = (e) => {
    return <ShiftEventLayout value={e} onClick={this.openEditDialog} />;
  };

  renderEditDialog = () => {
    const { selectedAppointment, drivers, editDialogVisible } = this.state;
    const content = (
      <ShiftEventPopup
        value={selectedAppointment}
        drivers={drivers}
        handleSaveClick={this.handleShiftSave}
        showCloseIcon
        dismissable
        onDelete={this.handleEventDelete}
      />
    );
    if (isMobile) {
      return (
        <Dialog visible={editDialogVisible} onHide={this.closeEditDialog}>
          {content}
        </Dialog>
      );
    } else {
      return (
        <OverlayPanel ref={(el) => (this.op = el)}>{content}</OverlayPanel>
      );
    }
  };

  render() {
    const { eventFetchPending } = this.state;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        {this.renderDraggables()}
        {this.renderEditDialog()}
        <CalendarToolbar
          displayedDate={this.state.displayedDate}
          handleDateChange={this.handleDateChange}
          monthOnly
          disabled={eventFetchPending}
          handleViewChange={this.handleViewChange}
          viewOptions={[
            { icon: "pi pi-calendar", value: "dayGridMonth" },
            { icon: "pi pi-list", value: "listWeek" },
          ]}
        />
        <FullCalendar
          ref={this.calendarRef}
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={false}
          headerToolbar={false}
          droppable
          weekends={this.state.weekendsVisible}
          initialEvents={[]}
          eventReceive={this.handleEventSave}
          events={this.state.currentEvents}
          eventContent={this.renderEventContent} // custom render function
          eventClick={this.handleEventClick}
          eventAdd={this.handleEventSave}
          eventChange={this.handleEventChange}
          eventRemove={(event) => {}}
          locales={[deLocale, frLocale]}
          locale={this.state.currentLocale}
          firstDay={1}
          contentHeight={window.innerHeight - 300}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  try {
    const {
      persist: { shiftTypes },
    } = state;

    return { shiftTypes };
  } catch (mapException) {
    return { shiftTypes: null };
  }
};

export default connect(mapStateToProps, { setTopbarTitle, setShiftTypes })(
  injectIntl(ShiftsView)
);
