import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Tippy.js tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// PrimeReact components
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
// Helper functions
import {
  isCurrentUserAdmin,
  dateToString,
  safeRender,
  initLogger,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
import { LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
// Logging
const logger = initLogger("vacation_event_layout");

const ShiftEventLayout = (props) => {
  const {
    value,
    onClick,
    intl,
    vacationStates,
    currentLocale,
    handleAccept,
    handleReject,
  } = props;
  const isFullHD = window.innerWidth >= 1920;

  const {
    ERROR_RENDER,
    VACATIONS_COMMENT_ADMIN_LABEL,
    VACATIONS_COMMENT_USER_LABEL,
    VACATIONS_STATUS_LABEL,
    CREATED_ON,
    //CREATED_BY,
    EDITED_ON,
    //EDITED_BY,
    VACATIONS_START_LABEL,
    VACATIONS_END_LABEL,
  } = MESSAGE_KEYS;

  const APPROVED_LABEL = "approved";
  const DECLINED_LABEL = "declined";
  const TAKEN_LABEL = "taken";

  let renderError = intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER });

  let content;
  try {
    const {
      event: {
        _def: {
          title,
          allDay,
          extendedProps: {
            remark,
            remark2,
            state,
            createDate,
            halfday,
            description,
            /*appointmentId,
            createUser,
            lastModifyUser,*/
            lastModifyDate,
          },
          ui: { display },
        },
        _instance: {
          range: { start, end },
        },
      },
    } = value;
    const isHoliday = display === "background";

    let diffDays = 1;
    try {
      diffDays = Math.ceil(Math.abs(start - end) / (1000 * 60 * 60 * 24));
    } catch (diffException) {
      logger.error(diffException);
    }

    let showDeclineButton,
      showApproveButton = false;
    let stateLabel = intl.formatMessage({
      id: MESSAGE_KEYS.ERROR_MISSING_VALUES,
    });
    const SICK_ID = 4;
    const SPECIAL_ID = 6;
    if (vacationStates) {
      let stateObject = vacationStates.find((searchState) => {
        return searchState.appointmentStateId === state;
      });
      if (stateObject && isDesktop) {
        stateLabel =
          currentLocale && currentLocale === LOCALES.FRENCH.key
            ? stateObject.nameFr
            : stateObject.nameDe;
        if (isCurrentUserAdmin() && !isHoliday) {
          switch (stateObject.name) {
            case APPROVED_LABEL:
              showDeclineButton = true;
              break;
            case DECLINED_LABEL:
              showApproveButton = true;
              showDeclineButton = false;
              break;
            case TAKEN_LABEL:
              showDeclineButton = false;
              break;
            default:
              let showButtons = ![SICK_ID, SPECIAL_ID].includes(state);
              showApproveButton = showButtons;
              showDeclineButton = showButtons;
          }
        }
      }
    }

    const renderComment = (comment, label) => {
      try {
        if (comment) {
          return (
            <div>
              <strong>{`${intl.formatMessage({ id: label })}:`}</strong>
              <div>{comment}</div>
            </div>
          );
        } else {
          return <></>;
        }
      } catch (renderException) {
        return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
      }
    };

    const renderToolTipContent = () => {
      if (isHoliday) {
        return <div>{description}</div>;
      } else {
        let dumEndDate = "-";
        if (end) {
          if (allDay) {
            const tmpDate = new Date(end);
            tmpDate.setDate(tmpDate.getDate() - 1);
            dumEndDate = dateToString(tmpDate);
          } else {
            dumEndDate = dateToString(end);
          }
        }
        return (
          <div>
            {renderComment(remark, VACATIONS_COMMENT_USER_LABEL)}
            {renderComment(remark2, VACATIONS_COMMENT_ADMIN_LABEL)}

            <div>
              <strong className="mr-1">
                {`${intl.formatMessage({ id: VACATIONS_STATUS_LABEL })}:`}
              </strong>
              <span>{stateLabel}</span>
            </div>

            <div>
              <strong className="mr-1">
                {`${intl.formatMessage({ id: VACATIONS_START_LABEL })}:`}
              </strong>
              <span>{start ? dateToString(start) : "-"}</span>
            </div>

            <div>
              <strong className="mr-1">
                {`${intl.formatMessage({ id: VACATIONS_END_LABEL })}:`}
              </strong>
              <span>{dumEndDate}</span>
            </div>

            {halfday && (
              <div>
                {intl.formatMessage({
                  id: MESSAGE_KEYS.VACATIONS_HALF_DAY_LABEL,
                })}
              </div>
            )}

            <Divider />
            <div>
              <div className="flex align-items-center">
                <span className="mr-1">
                  {intl.formatMessage({ id: EDITED_ON })}
                </span>
                <strong>
                  {lastModifyDate
                    ? dateToString(new Date(lastModifyDate), "/")
                    : ""}
                </strong>
              </div>

              <div className="flex align-items-center">
                <span className="mr-1">
                  {intl.formatMessage({ id: CREATED_ON })}
                </span>
                <strong>
                  {createDate ? dateToString(new Date(createDate), "/") : ""}
                </strong>
              </div>
            </div>
          </div>
        );
      }
    };

    function shortDate(date) {
      let newFormattedString = "";
      newFormattedString = date.split(".")[0] + "." + date.split(".")[1];
      return newFormattedString;
    }

    const renderEventContent = () => {
      if (isHoliday) {
        return <strong>{title}</strong>;
      } else if (!isDesktop) {
        return (
          <div className="vacation_name">
            <strong>{title}</strong>
            {halfday && (
              <div>
                {intl.formatMessage({
                  id: MESSAGE_KEYS.VACATIONS_HALF_DAY_LABEL,
                })}
              </div>
            )}
          </div>
        );
      } else {
        const allDayVal = allDay ? "" : " - " + shortDate(dateToString(end));
        const endDate = end ? allDayVal : " - -";
        return (
          <div>
            <div>
              <span className="font-bold">{title}</span> {stateLabel}
            </div>

            <div>
              {start ? shortDate(dateToString(start)) : "-"}
              {endDate}
            </div>
            {halfday && (
              <div>
                {intl.formatMessage({
                  id: MESSAGE_KEYS.VACATIONS_HALF_DAY_LABEL,
                })}
              </div>
            )}
          </div>
        );
      }
    };

    const cssClass = isHoliday ? "align-items-top mt-1" : "align-items-center";

    content = (
      <div style={{ width: "100%" }}>
        <Tippy content={safeRender(renderToolTipContent, renderError)}>
          <div
            onClick={onClick}
            className={`flex ${
              !isFullHD && diffDays < 2 ? "flex-column" : "flex-row"
            } justify-content-between ${cssClass} pl-2`}
            style={{ minHeight: "45px" }}
          >
            {safeRender(renderEventContent, renderError)}
            <div className="flex flex-row justify-content-between">
              {showApproveButton ? (
                <Button
                  icon="pi pi-check"
                  className="p-button-rounded p-button-secondary m-1 p-button-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    handleAccept();
                  }}
                />
              ) : (
                <></>
              )}
              {showDeclineButton ? (
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-secondary m-1 p-button-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleReject();
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Tippy>
      </div>
    );
  } catch (renderException) {
    content = <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  } finally {
    return content;
  }
};

export default injectIntl(ShiftEventLayout);
