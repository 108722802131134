import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
// Custom components
import { AddPaymentDialog, PaymentsTable } from "./";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";

class PaymentEditorLayout extends React.Component {
  state = {
    showDialog: false,
    selectedIndex: null,
  };

  render = () => {
    const {
      intl,
      updatePayment,
      deletePayment,
      selectedCustomer,
      value,
      totalAmount,
      selectedBill,
      fetchPending,
    } = this.props;
    const { CUSTOMERS_ADD_PAYMENT_TITLE_LABEL } = MESSAGE_KEYS;
    const { showDialog, selectedIndex } = this.state;
    let totalPaid = 0;
    if (value && value.length) {
      value.forEach((entry) => {
        totalPaid += entry.amount;
      });
    }
    let selectedValue = value[selectedIndex];

    return (
      <div>
        <AddPaymentDialog
          handleAdd={(newValue) => {
            updatePayment(newValue, selectedIndex);
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          visible={showDialog}
          onHide={() => {
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          remainingAmount={
            (totalAmount ? totalAmount : 0) - (totalPaid ? totalPaid : 0)
          }
          transactionId={selectedBill}
          customerId={selectedCustomer}
          value={selectedValue}
        />
        <Button
          icon={`pi ${fetchPending ? "pi-spin pi-spinner" : "pi-plus"}`}
          label={intl.formatMessage({
            id: CUSTOMERS_ADD_PAYMENT_TITLE_LABEL,
          })}
          onClick={() => {
            this.setState({ showDialog: true, selectedIndex: null });
          }}
          disabled={fetchPending}
        />
        <PaymentsTable
          value={value}
          handlePaymentDelete={deletePayment}
          handleEditClick={(index) => {
            this.setState({
              selectedIndex: index,
              showDialog: true,
            });
          }}
        />
      </div>
    );
  };
}

export default injectIntl(PaymentEditorLayout);
