import React from "react";
// React redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// PrimeReact components
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";

import { ScrollPanel } from "primereact/scrollpanel";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
// Helper function
import { initLogger, addLeadingZero, dateToQueryString } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Custom components
import { ReturnLine } from "./ReturnComponents";
// Styling
import "../../Style.scss";
// Logging
const logger = initLogger("appointment_customer_view");

const EMPTY_STATE = {
  inputRepeat: [],
  inputNumberRepeats: 1,

  inputStartDate: [],
  inputAppointmentTime: "",
};

class AppointmentReturnView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  initInputs = () => {
    // TODO
    if (this.props && this.props.value) {
      this.setState({
        ...EMPTY_STATE,
        inputRepeat: this.mapDTOtoInputs(this.props.value),
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  handleAddRow = () => {
    const { inputAppointmentTime, inputStartDate } = this.state;
    let inputRepeat = [...this.state.inputRepeat];
    try {
      inputStartDate.forEach((startDate) => {
        if (
          inputRepeat.findIndex(
            (searchDate) => searchDate.startDate === startDate
          ) === -1
        ) {
          const dumDate = new Date(startDate);
          dumDate.setHours(3);
          inputRepeat.push({
            startDate: dumDate,
            appointmentTime: inputAppointmentTime,
          });
        }
      });

      this.setState({
        inputRepeat,
        inputStartDate: [],
        inputAppointmentTime: "",
      });
    } catch (rowAddException) {
      logger.warn(
        "Exception on handle row add",
        rowAddException,
        inputAppointmentTime,
        inputStartDate
      );
    }
  };

  handleUpdateRow = (newValue, index) => {
    const inputRepeat = [...this.state.inputRepeat];
    inputRepeat[index] = { ...newValue };
    this.setState({ ...this.state, inputRepeat });
  };

  handleDeleteRow = (index) => {
    let inputRepeat = [...this.state.inputRepeat];
    inputRepeat.splice(index, 1);
    this.setState({
      inputRepeat,
    });
  };

  renderCreateLine = () => {
    return (
      <div className="flex">
        <div className="mt-3 mr-3">
          <span className="p-float-label">
            <Calendar
              value={this.state.inputStartDate}
              dateFormat="dd/mm/yy"
              onChange={(e) =>
                this.setState({ ...this.state, inputStartDate: e.value })
              }
              selectionMode={"multiple"}
              id="multi_select_calendar"
              numberOfMonths={3}
              selectOtherMonths={true}
            />
            <label htmlFor="multi_select_calendar">
              {this.props.intl.formatMessage({
                id: MESSAGE_KEYS.PAYMENTS_DATE_LABEL,
              })}
            </label>
          </span>
        </div>
        <div className="mt-3 mr-3">
          <span className="p-float-label">
            <InputMask
              id="imk_appointmentTime"
              mask="99:99"
              value={this.state.inputAppointmentTime}
              onChange={(e) => this.setState({ inputAppointmentTime: e.value })}
              style={{ width: "100%" }}
            />
            <label htmlFor="imk_appointmentTime">
              {this.props.intl.formatMessage({
                id: MESSAGE_KEYS.APPOINTMENTS_START_DATE_LABEL,
              })}
            </label>
          </span>
        </div>
        <div className="flex align-items-start justify-content-start mt-3">
          <Button
            icon="pi pi-plus"
            className="p-button-rounded"
            onClick={this.handleAddRow}
          />
        </div>
      </div>
    );
  };

  renderRepeatInputs = () => {
    const { intl } = this.props;
    const { ERROR_NO_DATA, ERROR_RENDER } = MESSAGE_KEYS;
    try {
      const { inputRepeat } = this.state;
      if (inputRepeat && inputRepeat.length > 0) {
        const lines = [];
        for (let c = 0; c < inputRepeat.length; c++) {
          lines.push(
            <ReturnLine
              key={`return_line_${c}`}
              value={inputRepeat[c]}
              handleUpdate={this.handleUpdateRow}
              handleDelete={this.handleDeleteRow}
              index={c}
            />
          );
        }
        return lines;
      } else {
        return <div>{intl.formatMessage({ id: ERROR_NO_DATA })}</div>;
      }
    } catch (renderException) {
      logger.warn("EXCEPTION ON REPEAT INPUTS", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  mapDTOtoInputs = (dto) => {
    const mappedVal = [];
    dto.forEach((val) => {
      let startDate = new Date(val.starttime);
      let appointmentTime = `${addLeadingZero(
        startDate.getHours()
      )}:${addLeadingZero(startDate.getMinutes())}`;
      mappedVal.push({
        startDate,
        appointmentTime,
      });
    });
    return mappedVal;
  };

  /**
   *  "starttime": "2021-01-11 14:15",
   *  "appointementTime": "2021-01-11 14:45"
   */
  mapInputsToDTO = () => {
    const { inputRepeat } = this.state;
    let multiAppointmentList = [];
    inputRepeat.forEach((entry) => {
      const { startDate, appointmentTime } = entry;
      multiAppointmentList.push({
        starttime: `${dateToQueryString(startDate, false)} ${appointmentTime}`,
        appointmentTime: `${dateToQueryString(
          startDate,
          false
        )} ${appointmentTime}`,
      });
    });
    return multiAppointmentList;
  };

  handleSelection = () => {
    const { handleParentUpdate, setBaseValue } = this.props;
    setBaseValue("appointmentRepeating", this.mapInputsToDTO()).then(() => {
      handleParentUpdate();
    });
  };

  handleBackClick = () => {
    const { setBaseValue } = this.props;
    setBaseValue("appointmentRepeating", this.mapInputsToDTO()).then(() => {
      this.props.handleBackClick();
    });
  };

  renderButtonRow = () => {
    const {
      intl,
      value: { appointmentId = null },
    } = this.props;
    const {
      ERROR_RENDER,
      DIALOG_BACK_BUTTON_LABEL,
      APPOINTMENTS_SAVE_BUTTON_LABEL,
      APPOINTMENTS_CREATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    try {
      return (
        <div className="flex justify-content-between mt-3">
          <Button
            label={intl.formatMessage({ id: DIALOG_BACK_BUTTON_LABEL })}
            onClick={this.handleBackClick}
          />
          <Button
            label={intl.formatMessage({
              id:
                appointmentId !== null
                  ? APPOINTMENTS_SAVE_BUTTON_LABEL
                  : APPOINTMENTS_CREATE_BUTTON_LABEL,
            })}
            onClick={this.handleSelection}
          />
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on map button row", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    return (
      <div className="grid">
        <div className="col-12">
          <Fieldset
            legend={this.props.intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENTS_MULTI_STEP_LABEL,
            })}
          >
            {this.renderCreateLine()}

            <ScrollPanel style={{ width: "100%", height: "600px" }}>
              <div className="grid  mt-3 p-3">{this.renderRepeatInputs()}</div>
            </ScrollPanel>
          </Fieldset>
          {this.renderButtonRow()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      appWizard: { appointmentRepeating },
      appointmentDrive,
    } = state;
    return {
      value: appointmentRepeating,
      ogStartTime:
        appointmentDrive && appointmentDrive.starttime
          ? appointmentDrive.starttime
          : null,
    };
  } catch (mapException) {
    return {
      value: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(AppointmentReturnView)
);
