import React, { useEffect } from "react";
// React redux
import { useDispatch } from "react-redux";
// PrimeReact imports
import RegularListTable from "./components/RegularListTable"
// Static values
import { setTopbarTitle } from "actions/index";
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { useIntl } from "react-intl";


const RegularListView = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {    
    dispatch(setTopbarTitle(intl.formatMessage({id:MESSAGE_KEYS.MENU_REGULARS})));
  }, [dispatch, intl]);

  return (
    <div>
      <RegularListTable />
    </div>
  );
};

export default RegularListView;
