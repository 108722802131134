import React, { useState, useRef, useEffect } from "react";

//PrimeReact components
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";

// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

// Helper functions
import { connect } from "react-redux";

// Localization
import { useIntl } from "react-intl";
import {
  sendQuery,
  getStatusColor,
  getLocalTimeDate,
  initLogger,
} from "common/Helpers";

const logger = initLogger("driver_status");

const DriverStatus = ({
  cars,
  userId,
  driverStatus,
  driverStatusCar,
  updateStatus,
  handleStateWSUpdate,
}) => {
  const [car, setCar] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setCar(driverStatusCar); // Update car state when driverStatusCar prop changes
  }, [driverStatusCar]);

  const toast = useRef(null);
  const intl = useIntl();

  const {
    DRIVER_STATUS_SAVE_FAILED,
    DRIVER_STATUS_SAVE_SUCCESS,
    APPOINTMENTS_VEHICLE_LABEL,
    APPOINTMENTS_DRIVER_REPORT_SAVE,
    DRIVER_STATUS_AVAILABLE_LABEL,
    DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL,
    DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL,
    DRIVER_STATUS_WITH_PATIENT_LABEL,
    DRIVER_STATUS_CLOSING_TIME_LABEL,
    DRIVER_STATUS_UNLOADING_LABEL,
    DRIVER_STATUS_BREAK_LABEL,
    DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL,
  } = MESSAGE_KEYS;

  const saveStatus = () => {
    if (!driverStatus || car?.vehicleId == null || userId == null) {
      // x == null also checks if value is undefined
      logger.warn(
        `Could not save status using: status: ${driverStatus} - car: ${car?.vehicleId} - userId: ${userId}`
      );
      toast.current.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: DRIVER_STATUS_SAVE_FAILED }),
      });
      return;
    }
    setPending(true);

    const mappedData = {
      driverState: driverStatus,
      vehicleId: car.vehicleId,
      personId: userId,
      dateState: getLocalTimeDate(),
    };

    sendQuery(QUERIES.SET_DAILY_DRIVER_STATUS, "post", mappedData).then(
      (data) => {
        handleStateWSUpdate(data?.id);
        toast.current.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({ id: DRIVER_STATUS_SAVE_SUCCESS }),
        });
        setPending(false);
      },
      (error) => {
        logger.error(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: DRIVER_STATUS_SAVE_FAILED }),
        });
        setPending(false);
      }
    );
  };

  const radiobuttons = () => {
    const fontColor = getStatusColor(driverStatus).fColor;
    return (
      <>
        <Tooltip target=".label-tooltip" />
        <RadioButton
          value="S1"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S1"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_AVAILABLE_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S1
        </label>
        <RadioButton
          value="S2"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S2"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S2
        </label>
        <RadioButton
          value="S3"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S3"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S3
        </label>
        <RadioButton
          value="S4"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S4"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_WITH_PATIENT_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S4
        </label>
        <RadioButton
          value="S5"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S5"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_BREAK_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S5
        </label>
        <RadioButton
          value="S6"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S6"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_CLOSING_TIME_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S6
        </label>
        <RadioButton
          value="S7"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S7"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S7
        </label>
        <RadioButton
          value="S8"
          onChange={(e) => updateStatus(e.value)}
          checked={driverStatus === "S8"}
          disabled={!car}
        />
        <label
          className="label-tooltip"
          data-pr-tooltip={intl.formatMessage({
            id: DRIVER_STATUS_UNLOADING_LABEL,
          })}
          data-pr-position="top"
          style={{ color: fontColor }}
        >
          S8
        </label>
      </>
    );
  };

  return (
    <div
      className="grid driver_header_base mb-4 p-2 drive_header_driver_status"
      style={{
        backgroundColor: getStatusColor(driverStatus).bgColor,
      }}
    >
      <Toast ref={toast} />
      <Dropdown
        value={car}
        options={cars}
        onChange={(e) => {
          setCar(e.target.value);
        }}
        optionLabel="license_plate"
        filterBy="license_plate"
        filter
        placeholder={intl.formatMessage({ id: APPOINTMENTS_VEHICLE_LABEL })}
        className="dropdown-cars"
        filterInputAutoFocus={true}
      />
      <div id="header-status-radiobutton">{radiobuttons()}</div>

      <Button
        className="p-button-success"
        loading={pending}
        onClick={saveStatus}
        icon="pi pi-save"
        label={intl.formatMessage({ id: APPOINTMENTS_DRIVER_REPORT_SAVE })}
        disabled={!car || !driverStatus}
      />
    </div>
  );
};

const mapStatesToProps = (state) => {
  try {
    return { userId: state.mobile.userId };
  } catch (mapException) {
    logger.error(mapException);
    return { userId: null };
  }
};

export default connect(mapStatesToProps, {})(DriverStatus);
