import React from "react";
import { Route } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { component, path, disabled = false } = props;

  try {
    return !disabled ? (
      <Route path={path} exact component={component} />
    ) : (
      <></>
    );
  } catch (routeException) {
    return <></>;
  }
};

export default ProtectedRoute;
