import React from "react";
// Redux
import { connect } from "react-redux";
// PrimeReact components
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
// Helper functions
import { addLeadingZero, valiDate } from "common/Helpers";
// Static values
import { LOCALES } from "assets/staticData/enums";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarCheck, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DEFAULT_TIME = "02:00";
/**
 *
 * @param {{value: Date, onChange: Function, label: String, showTime: Boolean, valid: Boolean, className: String, disableDrag: boolean}} props
 */
class SplitDateTimeInput extends React.Component {
  state = {
    inputDate: "",
    inputTime: DEFAULT_TIME,
    inputFullDate: null,

    currentLocale: LOCALES.GERMAN.key,
  };

  componentDidMount = () => {
    this.op = React.createRef();
    this.initInputs();
    this.initLocale();
    if (this.props.disableDrag) {
      document.ondragstart = (e) => e.preventDefault();
    }
  };

  initLocale = () => {
    let currentLocale;
    const { GERMAN } = LOCALES;
    const { currentUser } = this.props;
    if (currentUser && currentUser.languageId) {
      currentLocale = Object.keys(LOCALES).find((localeKey) => {
        return LOCALES[localeKey].languageId === currentUser.languageId;
      });
    }
    if (!currentLocale) {
      currentLocale = GERMAN.key;
    } else {
      currentLocale = LOCALES[currentLocale].key;
    }
    this.setState({
      currentLocale,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { value, showTime } = this.props;
    let inputDate, inputTime;
    if (value === null || value === undefined) {
      //hopefully this won't break anything :D
      // It did. :(
      //state is getting overwritten if null is the new value, so resetting would be impossible (does not count for init)
      const reset = value === undefined;
      inputDate = this.state.inputDate && !reset ? this.state.inputDate : "";
      inputTime = this.state.inputTime && !reset ? this.state.inputTime : "";
    } else {
      if (!isNaN(Date.parse(value))) {
        inputDate = `${addLeadingZero(value.getDate())}/${addLeadingZero(
          value.getMonth() + 1
        )}/${value.getFullYear()}`;
        inputTime = `${addLeadingZero(value.getHours())}:${addLeadingZero(
          value.getMinutes()
        )}`;
      } else {
        inputDate = !this.state.inputDate ? "" : this.state.inputDate;
        if (!this.state.inputTime) {
          if (!inputDate) {
            // Leave empty if no value is set.
            inputTime = "";
          } else {
            // Default time to 3 a.m. if time is not displayed to prevent timezone offset messing with the date.
            let offset = new Date().getTimezoneOffset() / 60;
            let newHour = offset >= 0 ? offset : "3";
            inputTime = showTime ? "" : `0${newHour}:00`;
          }
        } else {
          inputTime = !inputDate ? "" : this.state.inputTime;
        }
      }
    }
    this.setState({
      inputDate,
      inputTime,
      inputFullDate: value ? value : null,
    });
  };

  handleChange = (selection) => {
    // Date assumes absolutely barbaric default JS format MM/DD/YYYY, shuffle input around to fit.
    let dumValues = selection.split("/");
    let dumValue = `${dumValues[1]}/${dumValues[0]}/${dumValues[2]}`;
    let newVal;
    if (
      valiDate(dumValue) &&
      (!this.props.showTime || this.state.inputTime !== "")
    ) {
      // Check time
      const dumTime = dumValue.split(" ");
      const newTimeVal = `${dumTime[0]} ${this.checkTimeInput(dumTime[1])}`;
      newVal = new Date(newTimeVal);
      const hours = dumTime[1].substring(0, 2);
      if (hours === "00" || hours === "24") {
        newVal.setDate(newVal.getDate() - 1);
      }
      /*if (newVal.getHours() === 0 && this.props.showTime) {
        const dumTime = this.state.inputTime.split(":");
        let dumHour = parseInt(dumTime[0]);
        let dumMin = 0;
        if (dumTime.length === 2 && !isNaN(parseInt(dumTime[1]))) {
          dumMin = parseInt(dumTime[1]);
        }
        newVal.setHours(parseInt(dumHour), parseInt(dumMin));
      }*/
    } else {
      newVal = null;
    }
    this.props.onChange(newVal);
  };

  checkTimeInput = (value) => {
    if (value.length === 5) {
      const hours = parseInt(value.substring(0, value.indexOf(":")));
      const minutes = parseInt(value.substring(value.indexOf(":") + 1));
      let returnTime;
      if (hours >= 0 && hours <= 24) {
        returnTime = `${hours === 0 ? 24 : addLeadingZero(hours.toString())}:`;
      } else {
        returnTime = "23:";
      }

      return `${returnTime}${minutes > 59 ? "00" : addLeadingZero(minutes)}`;
    } else {
      return value;
    }
  };

  render = () => {
    library.add(fas, faCalendarCheck);

    const {
      label,
      showTime,
      className = "",
      valid = true,
      disabled = false,
      appendTo,
      iconRight,
    } = this.props;
    const { inputDate, inputFullDate, inputTime, currentLocale } = this.state;

    try {
      let id = `splt_date_${Math.floor(Math.random() * 1000)}`;

      return (
        <div id={id} className={className} draggable="false">
          <OverlayPanel
            ref={this.op}
            appendTo={appendTo ? document.getElementById(appendTo) : null}
          >
            <Calendar
              inline
              value={inputFullDate}
              onChange={(e) => {
                let stringVal = `${addLeadingZero(
                  e.value.getDate()
                )}/${addLeadingZero(
                  e.value.getMonth() + 1
                )}/${e.value.getFullYear()}`;
                this.setState({ inputFullDate: e.value, inputDate: stringVal });
                this.op.current.hide();
                this.handleChange(
                  `${stringVal} ${showTime ? inputTime : DEFAULT_TIME}`
                );
              }}
              locale={currentLocale}
              selectOtherMonths={true}
            />
          </OverlayPanel>
          <label className="font-light split_label">{label ? label : ""}</label>
          <div className="flex flex-row align-items-end">
            <div
              className="p-inputgroup"
              style={{ width: showTime ? "66%" : "100%" }}
            >
              {!iconRight ? (
                <Button
                  icon={
                    inputFullDate ? (
                      <FontAwesomeIcon icon={faCalendarCheck} />
                    ) : (
                      "pi pi-calendar"
                    )
                  }
                  iconPos="right"
                  onClick={(e) => this.op.current.toggle(e)}
                  disabled={disabled}
                />
              ) : (
                <></>
              )}
              <InputMask
                id={id}
                mask="99/99/9999"
                slotChar="tt/mm/yyyy"
                value={inputDate}
                onChange={(e) => {
                  this.setState({ inputDate: e.value });
                  this.handleChange(
                    `${e.value} ${showTime ? inputTime : DEFAULT_TIME}`
                  );
                }}
                className={valid === true || valid === null ? "" : "p-invalid"}
                disabled={disabled}
                onDragStart={(e) => e.stopPropagation()}
              />
              {!disabled && iconRight ? (
                <Button
                  icon={
                    inputFullDate
                      ? "fa-solid fa-calendar-check"
                      : "pi pi-calendar"
                  }
                  iconPos="right"
                  onClick={(e) => this.op.current.toggle(e)}
                />
              ) : (
                <></>
              )}
            </div>
            {showTime === true ? (
              <InputMask
                mask="99:99"
                slotChar="ss:mm"
                value={inputTime}
                onChange={(e) => {
                  this.setState({ inputTime: e.value });
                  this.handleChange(`${inputDate} ${e.value}`);
                }}
                style={{ width: "33%" }}
                disabled={disabled}
                onDragStart={() => false}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } catch (renderException) {
      return <div>-</div>;
    }
  };
}

const mapStateToProps = (state) => {
  try {
    return {
      currentUser: state.authentication.currentUser,
    };
  } catch (mapException) {
    return {
      currentUser: { currentLocale: LOCALES.GERMAN },
    };
  }
};

export default connect(mapStateToProps, {})(SplitDateTimeInput);
