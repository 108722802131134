/**
 * This component renders a text input component.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
import { InputText } from "primereact/inputtext";
import "./Common.scss";
import "primeflex/primeflex.css";

/**
 * Renders a text input field with the floating label effect.
 *
 * @param {Object} props - Component props
 * @param {String} props.id - The unique HTML ID.
 * @param {String} props.value - Contains value of the input field.
 * @param {Function} props.onChange - The onChange behaviour of the field.
 * @param {String} props.label - The label attached to the input.
 * @param {Boolean} props.valid - Set to false to mark the input as invalid.
 * @param {Function} props.onKeyDown - The onKeyDown behaviour of the field.
 * @param {Boolean} props.fullWidth - Set true to set the input's width to the parent's width. Defaults to true.
 *
 * @returns {JSX.Element} The text input with a floating label.
 */
const FloatingTextInput = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    valid = true,
    onKeyDown,
    fullWidth = true,
    className = "",
    disabled = false,
    autoFocus = false,
    boldInput = false,
  } = props;
  return (
    <span className={`p-float-label input_wrapper ${className}`}>
      <InputText
        className={`${valid === true || valid === null ? "" : "p-invalid"} ${
          boldInput ? "font-bold" : ""
        }`}
        style={fullWidth ? { width: "100%" } : {}}
        id={id}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete="false"
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
};

export default FloatingTextInput;
