import React, { useState, useEffect } from "react";
// PrimeReact components
import { Dropdown } from "primereact/dropdown";
// Custom components
import HolidayTable from "./components/HolidayTable";

const HolidayView = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearSelection, setYearSelection] = useState([]);

  useEffect(() => {
    let midYear = new Date().getFullYear();
    const prevYears = [];
    const nextYears = [];
    const MAX_STEPS = 5;
    for (let c = MAX_STEPS; c > 0; c--) {
      prevYears.push(midYear - c);
      nextYears.unshift(midYear + c);
    }
    setYearSelection([...prevYears, midYear, ...nextYears]);
  }, [setYearSelection]);

  return (
    <div>
      <Dropdown
        value={year}
        options={yearSelection}
        onChange={(e) => setYear(e.value)}
        placeholder="Select a year"
      />
      <HolidayTable year={year} />
    </div>
  );
};

export default HolidayView;
