import React, { Component, Suspense } from "react";
// PrimeReact Components
import { locale, addLocale } from "primereact/api";
import { ProgressBar } from "primereact/progressbar";
import { Messages } from "primereact/messages";

import { Notifications } from 'react-push-notification';

// Views
import LoginView from "./components/LoginView";

// Styles
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/layout.scss";
import "./App.scss";
// Redux
import { connect } from "react-redux";
import { fetchOfflineData, setTopbarTitle, logout } from "actions";
// Localization
import { IntlProvider } from "react-intl";
import messages from "./data/messages";
// Helper function
import { generateCalendarLocale, initLogger } from "common/Helpers";
// Static data
import { LOCALES, MESSAGE_SEVERITY } from "./assets/staticData/enums";
import MainView from "components/MainView/index";
// Logging
const logger = initLogger('app.js')
class App extends Component {
  state = {
    layoutMode: "static",
    layoutColorMode: "dark",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
    getOfflineData: true,

    isAdmin: false,
    isBilling: false,
    isPlanning: false,

    menuModel: [],
    isProd: ["start-prod", "prod"].includes(process.env.REACT_APP_SETTING),
    passwordDialogVisible: false,
  };

  componentDidMount = () => {
    addLocale(LOCALES.GERMAN.key, generateCalendarLocale(LOCALES.GERMAN.key));
    addLocale(LOCALES.FRENCH.key, generateCalendarLocale(LOCALES.FRENCH.key));
  };

  componentDidCatch = (error, info) => {
    if (this.messages) {
      this.messages.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: info,
        detail: error,
      });
    } else {
      logger.error(error, info);
    }
  };

  render() {
    let currentLocale = LOCALES.GERMAN.key;
    let renderFunction = <LoginView />;
    if (this.props.currentUser) {
      const { currentUser } = this.props;
      if (!isNaN(currentUser.languageId)) {
        // Find & set language locale of logged in user.
        let userLocale = Object.keys(LOCALES).find((key) => {
          return LOCALES[key].languageId === currentUser.languageId;
        });
        if (userLocale) {
          currentLocale = LOCALES[userLocale].key;
        } else {
          currentLocale = LOCALES.GERMAN.key;
        }
      }
      locale(currentLocale);
      renderFunction = <MainView />;
    }
    return (
      <IntlProvider
        key={currentLocale}
        locale={currentLocale}
        messages={messages[currentLocale]}
      >        
      <Notifications />
        <Suspense fallback={<ProgressBar mode="indeterminate" />}>
          <Messages ref={(el) => (this.messages = el)}></Messages>
          {renderFunction}
        </Suspense>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authentication: { currentUser, sessionExpired },
    application: {
      backendAvailable,
      offlineData: { hasOfflineData },
    },
    session: { changePending },
  } = state;
  return {
    currentUser,
    backendAvailable,
    hasOfflineData,
    changePending,
    sessionExpired,
  };
};

export default connect(mapStateToProps, {
  fetchOfflineData,
  setTopbarTitle,
  logout,
})(App);
