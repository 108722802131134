import React from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
// Custom components
import { UserBudgetDialog } from "./";
// Helper functions
import { safeRender, sendQuery, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

const logger = initLogger("UserBudgetLayout");

class UserBudgetLayout extends React.Component {
  state = {
    showDialog: false,
    selectedIndex: null,
    deletePending: false,
  };

  /** Custom table cell renderer that returns an edit-button. */
  editButtonTemplate = (rowData, index) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        onClick={() => {
          this.setState({
            selectedIndex: index.rowIndex,
            showDialog: true,
          });
        }}
      />
    );
  };

  /** Custom table cell renderer that returns an edit-button. */
  deleteButtonTemplate = (rowData, index) => {
    const { intl } = this.props;
    const {
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_DENY_BUTTON_LABEL,
      USERS_BUDGET_DELETE_MESSAGE,
      USERS_BUDGET_DELETE_TITLE,
    } = MESSAGE_KEYS;
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={() => {
          confirmDialog({
            message: intl.formatMessage(
              { id: USERS_BUDGET_DELETE_MESSAGE },
              { year: rowData.year }
            ),
            header: intl.formatMessage({ id: USERS_BUDGET_DELETE_TITLE }),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: intl.formatMessage({
              id: DIALOG_CONFIRM_BUTTON_LABEL,
            }),
            rejectLabel: intl.formatMessage({ id: DIALOG_DENY_BUTTON_LABEL }),
            accept: () =>
              this.handleDelete(rowData.vacationBudgetId, index.rowIndex),
          });
        }}
        severity="danger"
        rounded
      />
    );
  };

  renderTable = () => {
    const { value, intl } = this.props;
    const {
      USERS_VACATION_BUDGET_YEAR_LABEL,
      USERS_VACATION_BUDGET_LABEL,
      USERS_VACATION_BUDGET_COMMENT_LABEL,
    } = MESSAGE_KEYS;

    return (
      <DataTable value={value} loading={this.state.deletePending}>
        <Column
          field="year"
          header={intl.formatMessage({ id: USERS_VACATION_BUDGET_YEAR_LABEL })}
        />
        <Column
          field="budget"
          header={intl.formatMessage({ id: USERS_VACATION_BUDGET_LABEL })}
        />
        <Column
          field="remark"
          header={intl.formatMessage({
            id: USERS_VACATION_BUDGET_COMMENT_LABEL,
          })}
        />
        <Column body={this.editButtonTemplate} />
        <Column body={this.deleteButtonTemplate} />
      </DataTable>
    );
  };

  handleDelete = (vacationBudgetId, index) => {
    const { intl, value, handleBudgetUpdate } = this.props;
    const { USERS_BUDGET_DELETE_SUCCESS, USERS_BUDGET_DELETE_FAIL } =
      MESSAGE_KEYS;
    const { ERROR, SUCCESS } = MESSAGE_SEVERITY;
    this.setState({ deletePending: true });
    sendQuery(`${QUERIES.DELETE_VACATION_BUDGET}${vacationBudgetId}`, "DELETE")
      .then(
        () => {
          const _value = [...value];
          _value.splice(index, 1);
          handleBudgetUpdate(_value);
          this.toast.show({
            severity: SUCCESS,
            summary: intl.formatMessage({
              id: USERS_BUDGET_DELETE_SUCCESS,
            }),
          });
        },
        (error) => {
          logger.error(error);
          this.toast.show({
            severity: ERROR,
            summary: intl.formatMessage({
              id: USERS_BUDGET_DELETE_FAIL,
            }),
          });
        }
      )
      .finally(() => {
        this.setState({ deletePending: false });
      });
  };

  render = () => {
    const { intl, addRow, userId, value } = this.props;
    const { ERROR_RENDER, USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL } =
      MESSAGE_KEYS;
    const { showDialog, selectedIndex } = this.state;

    let val = selectedIndex !== null ? value[selectedIndex] : null;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <UserBudgetDialog
          visible={showDialog}
          onHide={() => {
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          handleSave={(newValue) => {
            addRow(newValue, selectedIndex);
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          userId={userId}
          value={val}
        />
        <Button
          icon="pi pi-plus"
          label={intl.formatMessage({
            id: USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,
          })}
          onClick={() => {
            this.setState({ showDialog: true, selectedIndex: null });
          }}
          className="mb-2"
        />
        {safeRender(this.renderTable, intl.formatMessage({ id: ERROR_RENDER }))}
      </div>
    );
  };
}

export default injectIntl(UserBudgetLayout);
