/**
 * Renders the toolbar & its content.
 *
 * @version 1.0
 * @author [Ian Husting]
 */

import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReacht components
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// Custom components
import { SplitDateTimeInput } from "components/common";
// Helper functions
import { valiDate } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { dateToString } from "common/Helpers";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";

const MONTHS = [
  MESSAGE_KEYS.JANUARY_LONG,
  MESSAGE_KEYS.FEBRUARY_LONG,
  MESSAGE_KEYS.MARCH_LONG,
  MESSAGE_KEYS.APRIL_LONG,
  MESSAGE_KEYS.MAY_LONG,
  MESSAGE_KEYS.JUNE_LONG,
  MESSAGE_KEYS.JULY_LONG,
  MESSAGE_KEYS.AUGUST_LONG,
  MESSAGE_KEYS.SEPTEMBER_LONG,
  MESSAGE_KEYS.OCTOBER_LONG,
  MESSAGE_KEYS.NOVEMBER_LONG,
  MESSAGE_KEYS.DECEMBER_LONG,
];

/**
 *
 * @param {Object} props
 * @param {Date} displayedDate - The date currently displayed on the scheduler view
 * @param {Function} handleDateChange - The function that handles date changing on the scheduler view.
 * @returns {JSX.Element}
 */
class CalendarToolbar extends React.Component {
  state = {
    inputDate: null,
    inputView: "resourceTimeGridDay",
  };

  componentDidMount = () => {
    const { displayedDate } = this.props;
    if (displayedDate) {
      this.setState({
        inputDate: displayedDate,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.displayedDate !== this.props.displayedDate) {
      this.setState({ inputDate: this.props.displayedDate });
    }
  };

  handleDateSelection = (newDate) => {
    this.setState({
      inputDate: newDate,
    });
    if (newDate) {
      this.props.handleDateChange({ value: newDate });
    }
  };

  selectTemplate = (option) => {
    return <i className={option.icon} />;
  };

  gotoDate = (monthOnly, increment) => {
    let prevDate;
    this.setState(
      (prevState) => {
        prevDate = prevState.inputDate ?? new Date();
        if (monthOnly) {
          prevDate.setDate(1);
          prevDate.setMonth(prevDate.getMonth() + increment);
        } else {
          prevDate.setDate(prevDate.getDate() + increment);
        }
        return {
          inputDate: prevDate,
        };
      },
      () => {
        this.props.handleDateChange({ value: new Date(prevDate.getTime()) });
      }
    );
  };

  render = () => {
    const {
      handleDateChange,
      intl,
      monthOnly = false,
      pending = false,
    } = this.props;
    const { inputDate } = this.state;

    let label = "";
    if (inputDate && isDesktop) {
      if (monthOnly) {
        label = `${intl.formatMessage({
          id: MONTHS[inputDate.getMonth()],
        })} ${inputDate.getFullYear()}`;
      } else {
        label = dateToString(inputDate, "/");
      }
    }

    const rightInputs = (
      <div className="flex align-items-center justify-content-between">
        <div className="mr-4 font-bold">{label}</div>
        <Button
          label={intl.formatMessage({ id: MESSAGE_KEYS.TODAY })}
          onClick={() => {
            handleDateChange({ value: new Date() });
          }}
          disabled={pending}
        />
      </div>
    );
    const leftInputs = (
      <div className="flex align-items-center">
        <Button
          icon="pi pi-caret-left"
          onClick={() => {
            this.gotoDate(monthOnly, -1);
          }}
          className="mr-1"
          disabled={pending}
        />
        <Button
          icon="pi pi-caret-right"
          onClick={() => {
            this.gotoDate(monthOnly, +1);
          }}
          className="mr-4"
          disabled={pending}
        />
        {monthOnly ? (
          <Calendar
            value={inputDate}
            view="month"
            dateFormat="mm/yy"
            yearNavigator
            onChange={(e) => this.handleDateSelection(e.value)}
            yearRange="2010:3000"
            disabled={pending}
            selectOtherMonths={true}
          />
        ) : (
          <SplitDateTimeInput
            value={inputDate}
            onChange={(e) => {
              this.handleDateSelection(valiDate(e) ? new Date(e) : null);
            }}
            label=""
            disabled={pending}
          />
        )}
        {/*  {handleViewChange ? (
          <SelectButton
            className="ml-4"
            value={inputView}
            options={this.props.viewOptions}
            onChange={(e) =>
              this.setState({ inputView: e.value }, () => handleViewChange(e))
            }
            itemTemplate={this.selectTemplate}
            unselectable={true}
          />
        ) : null} */}
      </div>
    );

    return <Toolbar left={leftInputs} right={rightInputs} className="mb-2" />;
  };
}

export default injectIntl(CalendarToolbar);
