import {
  MOBILE_SET_DAY,
  MOBILE_SET_USER,
  MOBILE_SET_ALL_DAYS,
} from "actions/types";

const INITIAL_STATE = {
  userId: -1,
  days: {},
};

const mobileReducer = (state = { ...INITIAL_STATE }, action) => {
  const { payload, type } = action;
  switch (type) {
    case MOBILE_SET_DAY:      
      return { ...state, days: { ...state.days, [payload.key]: payload.value } };
    case MOBILE_SET_USER:
      return { ...state, userId: payload };
    case MOBILE_SET_ALL_DAYS:
      return { ...state, days: payload.value };
    default:
      return state;
  }
};

export default mobileReducer;
