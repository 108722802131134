import React from "react";
// PrimeReact Components
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
// Custom components
import { UserEditLayout, ErrorBoundary } from "components/common";
import { UserBudgetLayout } from "./";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";


const EMPTY_STATE = {
  selectedUser: null,
  fetchPending: true,
  fetchError: null,
  userFetchPending: false,
  userFetchError: null,

  inputBudgets: [],  
};

class UserEditView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedUser } = this.props;
    if (prevProps.selectedUser !== selectedUser) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { selectedUser } = this.props;
    if (selectedUser) {
      this.setState({
        selectedUser: selectedUser,
        inputBudgets: selectedUser.vacationBudget,        
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  handleBudgetUpdate = (newBudgets) => {
    this.setState({ inputBudgets: newBudgets });
  };
  

  addRow = (newData, index) => {
    let newBudget = [...this.state.inputBudgets];
    if (index !== null) {
      newBudget[index] = newData;
    } else {
      newBudget.push(newData);
    }
    this.setState({
      inputBudgets: [...newBudget],
    });
  };

  renderContent = () => {
    const { intl, updateParent } = this.props;
    const {
      ERROR,
      ERROR_DATA_FETCH,
      USERS_TITLE_LABEL,
      USERS_SELECT_USER_LABEL,
    } = MESSAGE_KEYS;
    const {
      selectedUser,
      userFetchPending,
      userFetchError,
      inputBudgets,      
    } = this.state;
    if (userFetchPending) {
      return (
        <Card>
          <ProgressSpinner />
        </Card>
      );
    } else {
      if (userFetchError) {
        return (
          <Card
            title={intl.formatMessage({
              id: ERROR,
            })}
          >
            {intl.formatMessage({ id: ERROR_DATA_FETCH })}
          </Card>
        );
      } else {
        if (selectedUser) {
          let name = "";
          if (selectedUser.alias) {
            name = selectedUser.alias;
          } else if (selectedUser.login) {
            name = selectedUser.login;
          }
          return (
            <div>
              <Panel
                header={intl.formatMessage(
                  { id: USERS_TITLE_LABEL },
                  {
                    name,
                  }
                )}
              >
                <ErrorBoundary>
                  <UserEditLayout
                    selectedUser={selectedUser}
                    updateParent={updateParent}
                  />
                </ErrorBoundary>
              </Panel>
              <div className="p-3">
                <UserBudgetLayout
                  value={inputBudgets}
                  handleBudgetUpdate={this.handleBudgetUpdate}
                  addRow={this.addRow}
                  userId={selectedUser.personId ? selectedUser.personId : null}
                />
              </div>
            </div>
          );
        } else {
          return (
            <Card
              title={intl.formatMessage({
                id: USERS_SELECT_USER_LABEL,
              })}
            />
          );
        }
      }
    }
  };

  render = () => {
    return <div>{this.renderContent()}</div>;
  };
}

export default injectIntl(UserEditView);
