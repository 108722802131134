import React from "react";
// React redux
import { connect } from "react-redux";
// Localization
import { injectIntl } from "react-intl";
import { Calendar } from "primereact/calendar";
import { locale, addLocale } from "primereact/api";
// Helper functions
import { generateCalendarLocale, initLogger } from "common/Helpers";
// Static values
import { DATE_FORMAT, LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
// Logging
const logger = initLogger("floating_date_input");

const FloatingDateInput = (props) => {
  const {
    value,
    onChange,
    valid = true,
    label,
    intl,
    appendTo = null,
    showTime = false,
    disabled,
    className = "",
    currentUser,
  } = props;
  try {
    let displayLabel = "";
    let id = "flt_cal_def";
    let currentLocale;
    const { GERMAN } = LOCALES;
    if (currentUser?.languageId) {
      currentLocale = Object.keys(LOCALES).find((localeKey) => {
        return LOCALES[localeKey].languageId === currentUser.languageId;
      });
    }
    if (!currentLocale) {
      currentLocale = GERMAN.key;
    } else {
      currentLocale = LOCALES[currentLocale].key;
    }

    addLocale(currentLocale, generateCalendarLocale(currentLocale));
    locale(currentLocale);

    if (label) {
      id = label;
      displayLabel = intl.formatMessage({ id: label });
    }

    return (
      <span
        className={`p-float-label ${className}`}
        key={`fdi_${Math.floor(Math.random() * 1000)}`}
      >
        <Calendar
          id={id}
          key={`cal_${Math.floor(Math.random() * 1000)}`}
          value={value}
          className={`${valid === true || valid === null ? "" : "p-invalid"}`}
          onChange={(e) =>
            onChange
              ? onChange(e.value)
              : logger.warn(`No onchange on ${label}`)
          }
          showIcon
          showTime={showTime}
          dateFormat={DATE_FORMAT}
          monthNavigator
          yearNavigator
          yearRange="1900:3000"
          locale={currentLocale}
          appendTo={appendTo}
          disabled={disabled}
          selectOtherMonths={true}
        />
        <label htmlFor={id} key={`fdi_lbl_${Math.floor(Math.random() * 1000)}`}>
          {displayLabel}
        </label>
      </span>
    );
  } catch (renderException) {
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
  }
};

const mapStateToProps = (state) => {
  try {
    return {
      currentUser: state.authentication.currentUser,
    };
  } catch (mapException) {
    return {
      currentUser: { currentLocale: LOCALES.GERMAN },
    };
  }
};

export default connect(mapStateToProps, {})(injectIntl(FloatingDateInput));
