import React from "react";
// PrimeReact components
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
// Localization
import { useIntl } from "react-intl";
import { LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { addLeadingZero, dateToString } from "common/Helpers";
import { GENDERS, TITLES } from "assets/staticData/combodata";
import { TranslatedCB } from "components/common/index";

const TicketDetails = ({
  customerName,
  startTime,
  meetingTime,
  inputData,
  updateParent,
  disabled,
  driveType,
  departure,
  arrival,
  appointmentTypes,
  currentUser,
  isNew,
  status,
  isMobile,
  stations,
}) => {
  const {
    REGULARS_DATES,
    APPOINTMENTS_COMMENT_LABEL,
    APPOINTMENTS_START_DATE_LABEL,
    APPOINTMENTS_APPOINTMENT_LABEL,
    DRIVES_DETAILS_TITLE,
    APPOINTMENTS_CLIENT_LABEL,
    APPOINTMENTS_STATUS_LABEL,
    APPOINTMENTS_DEPARTURE_HEADER,
    APPOINTMENTS_START_ADDRESS_LABEL,
    APPOINTMENTS_DESTINATION_HEADER,
    CUSTOMERS_FILTER_INSURANCE_NUMBER,
    APPOINTMENTS_END_ADDRESS_LABEL,
    CUSTOMERS_FILTER_PHONE,
    APPOINTMENTS_PHONE_LABEL,
    CUSTOMERS_GENDER_LABEL,
    CUSTOMERS_TITLE_LABEL,
    CUSTOMERS_FIRST_NAME_LABEL,
    CUSTOMERS_LAST_NAME_LABEL,
    CUSTOMERS_MIDDLE_NAME_LABEL,
  } = MESSAGE_KEYS;

  const intl = useIntl();

  const { remark, healthInsuranceNumber, phoneHome, phoneWork, customer } =
    inputData;

  const startDate = new Date(startTime ? startTime : new Date());

  const startHour = `${addLeadingZero(
    startDate.getUTCHours()
  )}:${addLeadingZero(startDate.getMinutes())}`;

  let isGerman = true;
  if (currentUser && currentUser.currentLocale) {
    isGerman =
      currentUser.currentLocale.languageId === LOCALES.GERMAN.languageId;
  }

  let meetingTimeText = "-";
  if (meetingTime && Date.parse(meetingTime)) {
    const meetDate = new Date(meetingTime);
    meetingTimeText = `${addLeadingZero(
      meetDate.getUTCHours()
    )}:${addLeadingZero(meetDate.getMinutes())}`;
  }

  const appointmentType = appointmentTypes?.find((type) => {
    return driveType === type.appointmentStateId;
  });
  let typeText = "-";
  if (appointmentType) {
    typeText =
      currentUser?.currentLocale.languageId === LOCALES.GERMAN.languageId
        ? appointmentType.nameDe
        : appointmentType.nameFr;
  }

  const getStatus = (status) => {
    return status
      .filter((aType) => aType.visible === true)
      .sort((a, b) => a.sort - b.sort);
  };
  const renderAddress = ({ line1, line2, city, zip, keyword }, title) => {
    return (
      <div className="col-5">
        <strong>{intl.formatMessage({ id: title })}</strong>
        <div>
          {(line1 || keyword) && (
            <div>
              {line1 ? line1 : ""}
              {keyword ? ` ${keyword}` : ""}
            </div>
          )}
          {line2 && <div>{line2}</div>}
          {(city || zip) && (
            <div>
              {city ? city : ""}
              {zip ? ` ${zip}` : ""}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="ticket_column_title">
        {intl.formatMessage({ id: DRIVES_DETAILS_TITLE })}
      </div>
      <Divider />
      <div className="grid">
        <div className={isMobile ? "col-2" : "col-1"}>
          <strong>{intl.formatMessage({ id: REGULARS_DATES })}</strong>
          <div>{dateToString(startDate)}</div>
        </div>
        <div className={isMobile ? "col-2 " : "col-3"}>
          <strong>
            {intl.formatMessage({ id: APPOINTMENTS_START_DATE_LABEL })}
          </strong>
          {!isNew && <div>{startHour}</div>}
          {isNew && (
            <div>
              {/*      <InputMask
                mask="99:99"
                value={inputData?.startHour}
                onChange={(event) => {
                  updateParent({
                    ...inputData,
                    startHour: event.value,
                  });
                }}
                style={{ border: !inputData.startHour ? "3px solid red" : "" }}
                className="responsive-input-drives"
              /> */}
              <Calendar
                id="calendar-timeonly"
                value={inputData?.startHour}
                onChange={(event) => {
                  updateParent({
                    ...inputData,
                    startHour: event.value,
                  });
                }}
                stepMinute={15}
                timeOnly
              />
            </div>
          )}
        </div>
        {!isNew && (
          <div className="col-2">
            <strong>
              {intl.formatMessage({ id: APPOINTMENTS_CLIENT_LABEL })}
            </strong>
            <div>{customerName}</div>
          </div>
        )}
        {isNew && (
          <>
            <div className={isMobile ? "col-2" : "col-1"}>
              <strong>
                {" "}
                {intl.formatMessage({
                  id: CUSTOMERS_TITLE_LABEL,
                })}
              </strong>
              <TranslatedCB
                value={inputData.titleId}
                options={TITLES}
                onChange={(selection) => {
                  updateParent({
                    ...inputData,
                    titleId: selection,
                  });
                }}
                placeholder={intl.formatMessage({
                  id: CUSTOMERS_TITLE_LABEL,
                })}
              />
            </div>
            <div className={isMobile ? "col-2" : "col-1"}>
              <strong>
                {intl.formatMessage({
                  id: CUSTOMERS_GENDER_LABEL,
                })}
              </strong>
              <TranslatedCB
                value={inputData.sexId}
                options={GENDERS}
                onChange={(selection) => {
                  updateParent({
                    ...inputData,
                    sexId: selection,
                  });
                }}
                placeholder={intl.formatMessage({
                  id: CUSTOMERS_GENDER_LABEL,
                })}
              />
            </div>
          </>
        )}

        <div className="col-4">
          <strong className="highlighted-label">
            {intl.formatMessage({ id: CUSTOMERS_FILTER_INSURANCE_NUMBER })}
          </strong>
          <InputMask
            mask="99999999-99999"
            pattern="[0-9]*"
            inputMode="numeric"
            type="tel"
            id={CUSTOMERS_FILTER_INSURANCE_NUMBER}
            value={
              customer?.healthInsuranceNumber
                ? customer.healthInsuranceNumber
                : healthInsuranceNumber
            }
            onChange={(event) => {
              if (!customer?.healthInsuranceNumber) {
                updateParent({
                  ...inputData,
                  healthInsuranceNumber: event.value,
                });
              }
            }}
            onFocus={(e) => e.target.select()}
            style={{ width: "100%" }}
            disabled={
              disabled || customer?.healthInsuranceNumber ? true : false
            }
            mode="decimal"            
          />
        </div>
      </div>
      <div>
        <div className="grid">
          <div className={isMobile ? "col-2 m-0 p-0" : "col-1 m-0 p-0"}>
            <strong>
              {intl.formatMessage({ id: APPOINTMENTS_STATUS_LABEL })}
            </strong>
            {!isNew && <div>{typeText}</div>}
            {isNew && (
              <div>
                <Dropdown
                  value={inputData.appointmentStatus}
                  options={getStatus(status)}
                  onChange={(event) =>
                    updateParent({
                      ...inputData,
                      appointmentStatus: event.value,
                    })
                  }
                  placeholder={intl.formatMessage({
                    id: APPOINTMENTS_STATUS_LABEL,
                  })}
                  style={{
                    width: "100%",
                    border: !inputData.appointmentStatus ? "3px solid red" : "",
                  }}
                  optionLabel={isGerman ? "nameDe" : "nameFr"}
                  optionDisabled="inactive"
                />
              </div>
            )}
          </div>
          <div className={isMobile ? "col-4" : "col-3"}>
            {!isNew && (
              <div>
                {renderAddress(departure, APPOINTMENTS_DEPARTURE_HEADER)}
              </div>
            )}
            {isNew && (
              <div>
                <strong>
                  {intl.formatMessage({ id: APPOINTMENTS_START_ADDRESS_LABEL })}
                </strong>
                <div>
                  <InputText
                    value={inputData?.fromAddressKeyword}
                    onChange={(event) => {
                      updateParent({
                        ...inputData,
                        fromAddressKeyword: event.target.value,
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}
          </div>
          {isNew && (
            <>
              <div className={isMobile ? "col-2" : "col-2"}>
                <strong>
                  {" "}
                  {intl.formatMessage({ id: CUSTOMERS_FIRST_NAME_LABEL })}
                </strong>
                <InputText
                  type="text"
                  value={inputData?.firstname}
                  onChange={(event) => {
                    updateParent({
                      ...inputData,
                      firstname: event.target.value,
                    });
                  }}
                  onFocus={(e) => e.target.select()}
                  style={{ width: "100%" }}
                />
              </div>
              <div className={isMobile ? "col-2" : "col-2"}>
                <strong>
                  {" "}
                  {intl.formatMessage({ id: CUSTOMERS_LAST_NAME_LABEL })}
                </strong>
                <InputText
                  type="text"
                  value={inputData?.lastname}
                  onChange={(event) => {
                    updateParent({
                      ...inputData,
                      lastname: event.target.value,
                    });
                  }}
                  onFocus={(e) => e.target.select()}
                  style={{ width: "100%" }}
                />
              </div>
              <div className={isMobile ? "col-2" : "col-2"}>
                <strong>
                  {intl.formatMessage({ id: CUSTOMERS_MIDDLE_NAME_LABEL })}
                </strong>
                <InputText
                  type="text"
                  value={inputData?.girlName}
                  onChange={(event) => {
                    updateParent({
                      ...inputData,
                      girlName: event.target.value,
                    });
                  }}
                  onFocus={(e) => e.target.select()}
                  style={{ width: "100%" }}
                />
              </div>
            </>
          )}
        </div>
        <div className="grid">
          {!isNew && (
            <div className={isMobile ? "col-2" : "col-1"}>
              <strong>
                {intl.formatMessage({ id: APPOINTMENTS_APPOINTMENT_LABEL })}
              </strong>
              <div>{meetingTimeText}</div>
            </div>
          )}
          {isNew && (
            <div className={isMobile ? "col-2" : "col-1"}>
              <strong>
                {intl.formatMessage({ id: APPOINTMENTS_APPOINTMENT_LABEL })}
              </strong>
              <Dropdown
                value={inputData.chosenStation}
                options={stations}
                onChange={(event) =>
                  updateParent({
                    ...inputData,
                    chosenStation: event.value,
                  })
                }
                style={{
                  width: "100%",
                  border: !inputData.chosenStation ? "3px solid red" : "",
                }}
                optionLabel={"stationName"}
              />
            </div>
          )}

          <div className={isMobile && isNew ? "col-4" : "col-3"}>
            {!isNew && (
              <div>
                {renderAddress(arrival, APPOINTMENTS_DESTINATION_HEADER)}
              </div>
            )}
            {isNew && (
              <div>
                <strong>
                  {intl.formatMessage({ id: APPOINTMENTS_END_ADDRESS_LABEL })}
                </strong>
                <div>
                  <InputText
                    value={inputData?.toAddressKeyword}
                    onChange={(event) => {
                      updateParent({
                        ...inputData,
                        toAddressKeyword: event.target.value,
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-3">
            <strong className="highlighted-label">
              {intl.formatMessage({ id: CUSTOMERS_FILTER_PHONE })}
            </strong>
            <InputText
              inputMode="numeric"
              type="tel"
              value={customer?.phoneHome ? customer.phoneHome : phoneHome}
              onChange={(event) => {
                if (customer?.phoneHome || customer?.phoneHome === "") {
                  updateParent({
                    ...inputData,
                    customer: { ...customer, phoneHome: event.target.value },
                  });
                } else {
                  updateParent({ ...inputData, phoneHome: event.target.value });
                }
              }}
              onFocus={(e) => e.target.select()}
              style={{ width: "100%" }}
              disabled={disabled}
            />
          </div>
          <div className="col-3">
            <strong className="highlighted-label">
              {intl.formatMessage({ id: APPOINTMENTS_PHONE_LABEL })}
            </strong>
            <InputText
              inputMode="numeric"
              type="tel"
              value={customer?.gsm ? customer.gsm : phoneWork}
              onChange={(event) => {
                if (customer?.gsm || customer?.phoneWork === "") {
                  updateParent({
                    ...inputData,
                    customer: { ...customer, gsm: event.target.value },
                  });
                } else {
                  updateParent({ ...inputData, phoneWork: event.target.value });
                }
              }}
              onFocus={(e) => e.target.select()}
              style={{ width: "100%" }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <strong>
              {intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
            </strong>
            <span className="p-float-label">
              <InputTextarea
                id={APPOINTMENTS_COMMENT_LABEL}
                value={remark}
                onChange={(event) =>
                  updateParent({ ...inputData, remark: event.target.value })
                }
                onFocus={(e) => e.target.select()}
                style={{ width: "100%" }}
                disabled={disabled}
                autoResize={true}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
