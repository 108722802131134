import React from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Helper functions
import { addLeadingZero, utcToDate } from "common/Helpers";
// Localization
import { useIntl } from "react-intl";

import "./Style.scss";
import { MESSAGE_KEYS, RESPONSIVE_BREAKPOINT } from "assets/staticData/enums";

const DriveListTable = ({
  appointments,
  pending,
  handleSelection,
  handleReupload,
  selDate,
}) => {
  const intl = useIntl();
  const {
    DRIVES_TABLE_MEETING_TIME,
    DRIVES_TABLE_PATIENT,
    APPOINTMENTS_START_ADDRESS_LABEL,
    APPOINTMENTS_END_ADDRESS_LABEL,
    DRIVES_HOURS_DEPARTURE,
    DRIVES_TABLE_VEHICLE,
    DRIVES_TABLE_BUTTON_START,

    DRIVES_TIMES_RESPONSIVE,
    DRIVES_ADDRESS_RESPONSIVE,
    DRIVES_TIME_START_RESPONSIVE,
    DRIVES_TIME_MEETING_RESPONSIVE,
    DRIVES_DEPARTURE_RESPONSIVE,
    DRIVES_ARRIVAL_RESPONSIVE,
    APPOINTMENTS_RETURN_LABEL,
    APPOINTMENTS_RETURN_HEADER,
    DRIVES_NO_APPOINTMENT,

    DRIVES_REUPLOAD,
  } = MESSAGE_KEYS;

  const isMobile = window.innerWidth <= RESPONSIVE_BREAKPOINT;
  const heightStyle = {
    height: `calc(100vh - ${!isMobile ? "360" : "330"}px)`,
  };

  const renderTime = (rowData, column) => {
    if (rowData[column.field] && Date.parse(rowData[column.field])) {
      const dateValue = utcToDate(rowData[column.field]);
      return (
        <div>{`${addLeadingZero(dateValue.getHours())}:${addLeadingZero(
          dateValue.getMinutes()
        )}`}</div>
      );
    } else {
      return <div>-</div>;
    }
  };

  const renderTimeResponsive = (rowData, column) => {
    return (
      <div>
        <strong>
          {intl.formatMessage({ id: DRIVES_TIME_START_RESPONSIVE })}
        </strong>
        <div>{renderTime(rowData, { field: "starttime" })}</div>
        <strong>
          {intl.formatMessage({ id: DRIVES_TIME_MEETING_RESPONSIVE })}
        </strong>
        <div>{renderTime(rowData, { field: "meetingTime" })}</div>
      </div>
    );
  };

  const renderStartbutton = (rowData) => {
    if (rowData.appointment.driverUpload === -1) {
      return (
        <Button
          label={intl.formatMessage({ id: DRIVES_REUPLOAD })}
          onClick={() => {
            handleReupload(rowData.appointment.appointmentId);
          }}
          className="p-button-secondary"
        />
      );
    } else {
      return (
        <Button
          label={intl.formatMessage({ id: DRIVES_TABLE_BUTTON_START })}
          onClick={() => {
            handleSelection({
              ...rowData.appointment,
              fullName: rowData.customerfullname,
              rueckfahrt: rowData.rueckfahrt,
            });
          }}
        />
      );
    }
  };

  const renderAddress = (rowData, column) => {
    if (rowData[column.field]) {
      return rowData[column.field].replaceAll("#", " ");
    } else {
      return;
    }
  };

  const renderReturn = (rowData, column) => {
    if (rowData[column.field]) {
      return (
        <strong style={{ color: "red", textTransform: "uppercase" }}>
          {intl.formatMessage({ id: APPOINTMENTS_RETURN_LABEL })}
        </strong>
      );
    } else {
      return <></>;
    }
  };

  const renderAddressResponsive = (rowData, column) => {
    return (
      <div>
        <strong>
          {intl.formatMessage({ id: DRIVES_DEPARTURE_RESPONSIVE })}
        </strong>
        <div>{renderAddress(rowData, { field: "fullFromAddress" })}</div>
        <strong>{intl.formatMessage({ id: DRIVES_ARRIVAL_RESPONSIVE })}</strong>
        <div>{renderAddress(rowData, { field: "fullToAddress" })}</div>
      </div>
    );
  };

  const renderUploadStatus = (rowData) => {
    if (rowData.appointment.driverUpload > 0) {
      return <i className="pi pi-check-circle" style={{ color: "green" }} />;
    } else if (rowData.appointment.driverUpload === -1) {
      return <i className="pi pi-times-circle" style={{ color: "red" }} />;
    } else {
      return <></>;
    }
  };

  const rowClass = (rowData) => {
    return { drive_row_return: rowData.rueckfahrt === true };
  };

  const onCreateClick = () => {
    handleSelection({
      isNew: true,
      starttime: selDate,
    });
  };

  return (
    <div style={heightStyle}>
      <DataTable
        value={appointments}
        scrollable
        scrollHeight="flex"
        loading={pending}
        className="drives_table"
        rowClassName={rowClass}
        emptyMessage={intl.formatMessage({ id: DRIVES_NO_APPOINTMENT })}
      >
        <Column
          field="rueckfahrt"
          header={intl.formatMessage({ id: APPOINTMENTS_RETURN_HEADER })}
          body={renderReturn}
          style={{ maxWidth: "130px" }}
        />
        {!isMobile && (
          <Column
            field="starttime"
            header={intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}
            body={renderTime}
            style={{ maxWidth: "70px" }}
          />
        )}
        {isMobile && (
          <Column
            field="starttime"
            header={intl.formatMessage({ id: DRIVES_TIMES_RESPONSIVE })}
            body={renderTimeResponsive}
          />
        )}
        {!isMobile && (
          <Column
            field="meetingTime"
            header={intl.formatMessage({ id: DRIVES_TABLE_MEETING_TIME })}
            body={renderTime}
            style={{ maxWidth: "70px" }}
          />
        )}
        <Column
          field="customerfullname"
          header={intl.formatMessage({ id: DRIVES_TABLE_PATIENT })}
        />
        {isMobile && (
          <Column
            header={intl.formatMessage({ id: DRIVES_ADDRESS_RESPONSIVE })}
            field="fullFromAddress"
            body={renderAddressResponsive}
          />
        )}
        {!isMobile && (
          <Column
            field="fullFromAddress"
            header={intl.formatMessage({
              id: APPOINTMENTS_START_ADDRESS_LABEL,
            })}
            body={renderAddress}
          />
        )}
        {!isMobile && (
          <Column
            field="fullToAddress"
            header={intl.formatMessage({ id: APPOINTMENTS_END_ADDRESS_LABEL })}
            body={renderAddress}
          />
        )}
        {false && (
          <Column
            field="firstCarLicencePlate"
            header={intl.formatMessage({ id: DRIVES_TABLE_VEHICLE })}
          />
        )}
        <Column
          body={renderUploadStatus}
          headerClassName="drive_no_header"
          className="drive_icon"
          style={{ maxWidth: "50px" }}
        />
        <Column
          body={renderStartbutton}
          headerClassName="drive_no_header"
          style={{ maxWidth: "140px" }}
          header={
            <Button
              icon="pi pi-plus"
              className="p-button mr-2"
              onClick={() => onCreateClick("new")}
            />
          }
        />
      </DataTable>
    </div>
  );
};

export default DriveListTable;
