import {
  SESSION_SET_CHANGE_PENDING,
  SESSION_SET_ADDRESS_SESSION,
  SESSION_SET_PAYMENT_SESSION,
  SESSION_SET_CUSTOMER_SESSION,
  SESSION_SET_BILL_SESSION,
  SESSION_SET_APPOINTMENT_SESSION,
  SESSION_CLEAR_SESSION_DATA,
  SESSION_SET_DRIVES_SESSION,
} from "./types";

/* SESSION ACTIONS */
/**
 * This action set the change pending value, indication whether or not the user has entered changes on the current DTO.
 *
 * @param {Boolean} changePending True if an input value was changed, false else.
 */
export const setChangePending = (changePending) => {
  return {
    type: SESSION_SET_CHANGE_PENDING,
    payload: changePending,
  };
};
/**
 * This action stores the latest content of the address view for faster loading.
 *
 * @param {Object} payload
 * @param {Number} payload.first
 * @param {Number} payload.rows
 * @param {Array} payload.displayedAddresses
 * @param {Number} payload.totalAddresses
 * @param {Number} payload.currentPage
 * @param {Object} payload.selectedAddress
 * @param {String} payload.queryString
 */
export const setAddressSession = (payload) => {
  return {
    type: SESSION_SET_ADDRESS_SESSION,
    payload,
  };
};

/**
 * This action stores the latest content of the payment view for faster loading.
 *
 * @param {Object} payload
 * @param {Number} payload.first
 * @param {Number} payload.rows
 * @param {Array} payload.displayedPayments
 * @param {Number} payload.totalPayments
 * @param {Number} payload.currentPage
 * @param {Object} payload.selectedPayment
 * @param {String} payload.queryString
 */
export const setPaymentSession = (payload) => {
  return {
    type: SESSION_SET_PAYMENT_SESSION,
    payload,
  };
};

/**
 * This action stores the latest content of the customer view for faster loading.
 *
 * @param {Object} payload
 * @param {Number} payload.first
 * @param {Number} payload.rows
 * @param {Array} payload.displayedCustomers
 * @param {Number} payload.totalCustomers
 * @param {Number} payload.currentPage
 * @param {Object} payload.selectedSessionCustomer
 * @param {Object} payload.selectedSessionBill
 * @param {Array<Object>} payload.selectedSessionPayments
 * @param {String} payload.queryString
 */
export const setCustomerSession = (payload) => {
  return {
    type: SESSION_SET_CUSTOMER_SESSION,
    payload,
  };
};

/**
 * This action stores the latest content of the bills view for faster loading.
 *
 * @param {Object} payload
 * @param {Number} payload.first
 * @param {Number} payload.rows
 * @param {Array} payload.displayedBills
 * @param {Number} payload.totalBills
 * @param {Number} payload.currentPage
 * @param {Object} payload.selectedSessionBill
 * @param {String} payload.queryString
 * @param {Number} totalAmount
 * @param {Number} totalPaid
 */
export const setBillSession = (payload) => {
  return {
    type: SESSION_SET_BILL_SESSION,
    payload,
  };
};

/**
 * This action stores the content of the displayed scheduler page for offline availability.
 *
 * @param {Array<Object>} payload
 */
export const setAppointmentSession = (payload) => {
  return {
    type: SESSION_SET_APPOINTMENT_SESSION,
    payload,
  };
};
/**
 * This action stores the selected date and driver of the drives view.
 *
 * @param {Object} payload
 * @param {Object} payload.user
 * @param {Date} payload.date
 * @param {number} payload.index
 */
export const setDriveSession = (payload) => {
  return {
    type: SESSION_SET_DRIVES_SESSION,
    payload,
  };
};

/**
 * Clears all session data stored for faster loading.
 * Appointment data is not affected since it's required for offline availability.
 */
export const clearSessionData = () => {
  return {
    type: SESSION_CLEAR_SESSION_DATA,
  };
};
