import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// React redux
import { connect } from "react-redux";
import { setTopbarTitle, setVacationStates } from "actions";
// Localization
import { injectIntl } from "react-intl";
// Fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import frLocale from "@fullcalendar/core/locales/fr";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
// Custom components
import {
  VacationEventLayout,
  VacationEventPopup,
  MyVacationsLayout,
  VacationBudget,
} from "./components";
import { CalendarToolbar } from "components/common";
// Helper functions
import {
  initLogger,
  sendQuery,
  getCurrentUserLocale,
  getRandomColor,
  dateToQueryString,
  dateToISOString,
  isCurrentUserAdmin,
  purgeObjectArray,
  sortObjectArray,
  fetchHolidays,
  getLocalTimeDate,
} from "common/Helpers";
// Styling
import "./Style.scss";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
import { HolidayLayout, VacationDraggables } from "./components/index";
import messages from "data/messages";
import DriverStatusWebsocket from "components/DrivesView/components/DriverStatusWebsocket";

const logger = initLogger("on calls view");
const sickYellow = "#f3f77e";
const HOLIDAY_ID = 13;
const SHOW_ALL_USER = {
  personId: -1,
  alias:
    getCurrentUserLocale() === "DE"
      ? messages["DE"][MESSAGE_KEYS.VACATIONS_SHOW_ALL_LABEL]
      : messages["FR"][MESSAGE_KEYS.VACATIONS_SHOW_ALL_LABEL],
};
class VacationsView extends React.Component {
  calendarRef = React.createRef();

  initFilterUser = () => {
    if (isCurrentUserAdmin()) {
      return SHOW_ALL_USER;
    } else {
      return this.props.currentUser;
    }
  };

  state = {
    currentEvents: [],
    selectedAppointment: null,
    displayedDate: new Date(),
    drivers: [],
    currentLocale: getCurrentUserLocale(),
    myVacations: [],
    allVacations: [],
    vacationBudgetData: null,

    vacationstates: [],

    editDialogVisible: false,
    createDialogVisible: false,
    holidayDialogVisible: false,

    appointmentFetchPending: false,
    filterUser: this.initFilterUser(),
    availableDays: 0,
    budgetDays: 0,
  };

  componentDidMount = () => {
    const { setTopbarTitle, vacationStates, intl, currentUser } = this.props;
    this.fetchVacationStates().then(
      () => {
        this.fetchAppointments();
        this.fetchMyVacations(currentUser.personId);
        this.fetchVacationBudget(currentUser.personId);
      },
      (error) => {
        logger.warn(error);
        this.setState({
          vacationstates: vacationStates,
        });
        this.fetchAppointments();
        this.fetchMyVacations(currentUser.personId);
        this.fetchVacationBudget(currentUser.personId);
      }
    );
    this.fetchDrivers();
    setTopbarTitle(intl.formatMessage({ id: MESSAGE_KEYS.MENU_VACATION }));
    this.websocketState = React.createRef();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { displayedDate, filterUser } = this.state;
    if (
      displayedDate &&
      displayedDate.getFullYear() !== prevState.displayedDate.getFullYear()
    ) {
      this.fetchMyVacations(filterUser.personId);
      this.fetchVacationBudget(filterUser.personId);
    }
  };

  fetchVacationBudget = (userId) => {
    try {
      const { displayedDate } = this.state;

      let startDate = new Date(displayedDate.getTime());
      startDate.setMonth(0, 1);
      let endDate = new Date(displayedDate.getTime());
      endDate.setMonth(11, 31);

      sendQuery(
        `${QUERIES.GET_VACATION_REPORT_BY_DATE_AND_USER}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}&userId=${userId}`,
        "get"
      ).then(
        (response) => {
          if (response && response.length > 0) {
            this.setState({
              vacationBudgetData:
                response && response.length > 0 ? response[0] : null,
              availableDays: response[0].available ?? 0,
              budgetDays: response[0].budget ?? 0,
            });
          }
        },
        (error) => {
          logger.warn(error);
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
    }
  };

  fetchDrivers = () => {
    sendQuery(
      `${QUERIES.GET_USERS_PAGES}?size=3000&sort=personId,asc`,
      "get"
    ).then(
      ({ content }) => {
        if (content) {
          const activeDrivers = content.filter((c) => c.active === true);
          const drivers = this.sortDrivers([...activeDrivers]);
          this.setState({
            drivers: [SHOW_ALL_USER, ...drivers],
          });
        } else {
          this.setState({ drivers: [] });
        }
      },
      (error) => {
        logger.warn("Error on users fetch", error);
      }
    );
  };
  /**
   * Returns a promise that fetches the different vacation states from the backend.
   * Resolves on success, rejects with the error code else.
   *
   * @returns {Promise}
   */
  fetchVacationStates = () => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.GET_VACATION_TYPES, "get").then(
          (response) => {
            this.props.setVacationStates([...response]);
            this.setState({
              vacationstates: [...response],
            });
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      } catch (fetchException) {
        reject(fetchException);
      }
    });
  };
  /**
   * Fetches all vacation requests for the selected year & current user and writes them into the state.
   */
  fetchMyVacations = (id) => {
    try {
      const { displayedDate } = this.state;
      let startDate = new Date(displayedDate.getTime());
      startDate.setMonth(0, 1);
      let endDate = new Date(displayedDate.getTime());
      endDate.setMonth(11, 31);

      sendQuery(
        `${QUERIES.GET_VACATIONS_BY_DATE_AND_USER}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}&userId=${id}`,
        "get"
      ).then(
        (response) => {
          this.setState({ myVacations: [...response] });
        },
        (error) => {
          logger.warn(error);
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
    }
  };

  /**
   * Fetches all vacation requests for the current month.
   * Writes them into the state on success, clears the state else.
   */
  fetchAppointments = () => {
    try {
      const { displayedDate } = this.state;
      let startDate = new Date(displayedDate.getTime());
      startDate.setDate(1);
      startDate.setDate(startDate.getDate() - 8);

      let endDate = new Date(
        displayedDate.getFullYear(),
        displayedDate.getMonth() + 1,
        0
      );
      endDate.setDate(endDate.getDate() + 8);
      this.setState({
        appointmentFetchPending: true,
      });
      sendQuery(
        `${QUERIES.GET_VACATIONS_BY_DATE}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}`,
        "get"
      ).then(
        (response) => {
          this.mapResponseToEvents(response).then(
            (allEvents) => {
              const currentEvents = this.filterVacations(allEvents);
              if (this.calendarRef?.current?.getApi) {
                let eventSources = this.calendarRef.current
                  .getApi()
                  .getEvents();
                logger.info("MAPPED RESPONSE", currentEvents);
                // Clear calendar events to prevent duplicates after adding new event.
                const len = eventSources.length;
                for (let i = 0; i < len; i++) {
                  eventSources[i].remove();
                }
              }
              this.setHolidays(currentEvents, allEvents);
            },
            (error) => {
              logger.warn(error);
              this.setState({
                currentEvents: [],
                appointmentFetchPending: false,
              });
            }
          );
          logger.info("RESPONSE", response);
        },
        (error) => {
          logger.warn(error);
          this.setState({ appointmentFetchPending: false });
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({ appointmentFetchPending: false });
    }
  };

  filterVacations = (allVacations) => {
    const { filterUser } = this.state;
    if (
      isCurrentUserAdmin() &&
      filterUser.personId === SHOW_ALL_USER.personId
    ) {
      return allVacations;
    } else {
      return allVacations.filter(
        (vacay) => vacay.extendedProps.user === filterUser.personId
      );
    }
  };

  changeUser = (filterUser) => {
    const { allVacations } = this.state;

    let personId = filterUser.personId;
    let currentEvents = allVacations;

    if (personId !== SHOW_ALL_USER.personId) {
      currentEvents = allVacations.filter(
        (vacay) =>
          vacay.extendedProps.user === personId ||
          vacay.extendedProps.appointmentTypeId === HOLIDAY_ID
      );
    } else {
      personId = this.props.currentUser.personId;
    }

    this.fetchMyVacations(personId);
    this.fetchVacationBudget(personId);
    let eventSources = this.calendarRef.current.getApi().getEvents();
    // Clear calendar events to prevent duplicates after adding new event.
    const len = eventSources.length;
    for (let i = 0; i < len; i++) {
      eventSources[i].remove();
    }

    this.setHolidays(currentEvents, eventSources);
    this.setState({ currentEvents, filterUser });
  };

  /**
   * Sorts a list of drivers by their alias.
   *
   * @param {Array<Object>} drivers An unsorted list of drivers
   * @returns {Array<Object>}
   */
  sortDrivers = (drivers) => {
    sortObjectArray(drivers, "alias");
    return purgeObjectArray(drivers, "personId");
  };

  setHolidays = (currentEvents, eventSources) => {
    const { displayedDate } = this.state;
    let startDate = new Date(displayedDate.getTime());
    startDate.setDate(1);
    startDate.setDate(startDate.getDate() - 8);

    let endDate = new Date(
      displayedDate.getFullYear(),
      displayedDate.getMonth() + 1,
      0
    );

    endDate.setDate(endDate.getDate() + 8);
    fetchHolidays(startDate, endDate).then(
      (holidays) => {
        this.setState({
          currentEvents: [...currentEvents, ...holidays],
          allVacations: eventSources,
          eventFetchPending: false,
        });
      },
      () => {
        this.setState({
          allVacations: eventSources,
          currentEvents,
          eventFetchPending: false,
        });
      }
    );
  };

  getEventColor = (stateId) => {
    let eventColor;
    const { vacationstates } = this.state;
    if (vacationstates) {
      let searchVacation = vacationstates.find((entry) => {
        return entry.appointmentStateId === stateId;
      });
      if (searchVacation) {
        eventColor = searchVacation.color
          ? searchVacation.color
          : getRandomColor();
      }
    } else {
      eventColor = getRandomColor();
    }

    return eventColor;
  };

  /**
   * Gets called when the date in the calendar component is changed. It will update the state and call the Fullcalendar function to display the selected date.
   *
   * @param {Object} e - The onChange event object of the calendar component.
   * @param {Date} e.value - The selected date.
   */
  handleDateChange = (e) => {
    try {
      this.setState({ displayedDate: e.value }, () => {
        /** @type {Date} */
        let date = e.value;
        if (this.calendarRef.current && date) {
          this.fetchAppointments();
          this.forceBudgetUpdate();
          if (this.state.filterUser?.personId !== -1) {
            this.fetchMyVacations(this.state.filterUser.personId);
          } else {
            this.fetchMyVacations(this.props.currentUser.personId);
          }
          this.calendarRef.current
            .getApi()
            .gotoDate(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            );
        }
      });
    } catch (changeException) {
      logger.error(changeException);
    }
  };
  /**
   * Is called when clicking an event in the calendar.
   * Updates state based on the clicked event.
   *
   * @param {Object} clickInfo
   */
  handleEventClick = (clickInfo) => {
    const {
      title,
      allDay,
      extendedProps: {
        state,
        remark,
        remark2,
        countedFullDays,
        halfday,
        user,
        appointmentId,
      },
    } = clickInfo.event._def;

    const { start, end } = clickInfo.event._instance.range;
    this.setState({
      selectedAppointment: clickInfo.event,
      selection: {
        appointmentId,
        startDate: start,
        endDate: end,
        state,
        remark,
        remark2,
        countedFullDays,
        halfday,
        user,
        title,
        allDay,
      },
    });
    this.toggleEventOverlay(null, clickInfo);
  };
  /**
   * Handles the save click on the create event dialog.
   * Closes the dialog and adds the created event to the calendar view.
   *
   * @param {Object} eventData Values entered in the create dialog.
   */
  handleEventCreate = (eventData) => {
    const {
      startDate,
      endDate,
      state,
      remark,
      remark2,
      countedFullDays,
      halfday,
      user,
    } = eventData;

    const isSingle = halfday || startDate.getTime() === endDate.getTime();
    let calendarApi = this.calendarRef.current.getApi();
    // clear date selection
    calendarApi.unselect();
    const eventColor = this.getEventColor(state);

    calendarApi.addEvent({
      title: user.alias,
      start: startDate,
      end: endDate,
      allDay: isSingle,
      extendedProps: {
        appointmentTypeId: 6,
        appointmentId: null,
        state,
        remark,
        remark2,
        countedFullDays,
        halfday,
        user: user.personId,
        createDate: new Date(),
        startDate,
        endDate,
        lastModifyDate: new Date(),
      },
      color: eventColor,
      backgroundColor: eventColor,
      borderColor: eventColor,
      textColor: eventColor === sickYellow ? "black" : "white",
    });
    this.setState({
      createDialogVisible: false,
    });

    this.forceBudgetUpdate();
  };

  handleHolidayCreate = (startDate, title, description) => {
    const { intl } = this.props;
    const { VACATION_UPDATE_FAILURE_MESSAGE, VACATION_UPDATE_SUCCESS_MESSAGE } =
      MESSAGE_KEYS;
    sendQuery(QUERIES.EDIT_HOLIDAY, "POST", {
      bankHoliday: {
        appointmentId: null,
        starttime: `${dateToQueryString(startDate)}`,
        type: 5,
        typeOfAppointment: HOLIDAY_ID,
        bankHolidayName: title,
        description,
      },
    }).then(
      (response) => {
        const _currentEvents = [...this.state.currentEvents];

        let holidayEvent = {
          id: response.appointmentId,
          title,
          start: startDate,
          end: startDate,
          allDay: true,
          display: "background",
          backgroundColor: "#faffd8",
          borderColor: "#faffd8",
          textColor: "black",
          extendedProps: { description },
        };
        _currentEvents.push(holidayEvent);
        this.setState({
          currentEvents: _currentEvents,
          holidayDialogVisible: false,
        });
        this.toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: intl.formatMessage({
            id: VACATION_UPDATE_SUCCESS_MESSAGE,
          }),
        });
        if (response?.state) {
          this.callWSState(response);
        }
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: VACATION_UPDATE_FAILURE_MESSAGE,
          }),
        });
      }
    );
  };

  /**
   * Called when clicking on an empty date in the calendar.
   * Initializes and opens the create dialog allowing the user to input vacation values.
   *
   * @param {Object} selectInfo An object containing data of the clicked day.
   */
  handleDateSelect = (selectInfo, isVacation = true) => {
    const { vacationstates } = this.state;
    let selection;
    if (selectInfo) {
      selection = {
        startDate: selectInfo.start,
        endDate: selectInfo.end,
        appointmentId: null,
        state: vacationstates ? vacationstates[1] : { appointmentStateId: 1 },
        remark: "",
        remark2: "",
        countedFullDays: 1,
        halfday: false,
        user: this.props.currentUser.personId,
      };
    } else {
      let dummyDate = new Date();
      selection = {
        startDate: dummyDate,
        endDate: dummyDate,
        appointmentId: null,
        state: vacationstates ? vacationstates[1] : { appointmentStateId: 1 },
        remark: "",
        remark2: "",
        countedFullDays: 1,
        halfday: false,
        user: this.props.currentUser.personId,
      };
    }
    if (isVacation) {
      this.setState((prevState) => {
        return {
          createDialogVisible: true,
          selection,
          availableDays: prevState?.vacationBudgetData?.available ?? 0,
          budgetDays: prevState?.vacationBudgetData?.budget ?? 0,
        };
      });
      this.setState({ createDialogVisible: true, selection });
    } else {
      this.setState({ holidayDialogVisible: true, selection });
    }
  };
  /**
   * Sends a save request to the BE.
   *
   * @param {Object} event The event that will be saved.
   * @param {Boolean} convertDate Flag telling the mapper whether or not the end date should be converted from UTC. Defaults to false.
   */
  handleEventSave = (event) => {
    const { intl } = this.props;
    const { VACATION_UPDATE_FAILURE_MESSAGE, VACATION_UPDATE_SUCCESS_MESSAGE } =
      MESSAGE_KEYS;
    try {
      const {
        _def: { allDay },
      } = event;
      let data = this.mapEventToDTO(event, allDay);
      logger.info("SAVING", data, event);
      if (data) {
        sendQuery(QUERIES.EDIT_VACATION, "post", data).then(
          (response) => {
            if (response?.appointmentId) {
              logger.info("SAVED", event, response);
              event.setExtendedProp("appointmentId", response.appointmentId);
              const eventColor = this.getEventColor(response.state);
              event.setProp("allDay", response.starttime === response.endtime);
              event.setProp("color", eventColor);
              event.setProp(
                "textColor",
                eventColor === sickYellow ? "black" : "white"
              );
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: intl.formatMessage({
                  id: VACATION_UPDATE_SUCCESS_MESSAGE,
                }),
              });
              this.setState({ editDialogVisible: false });
              this.forceBudgetUpdate();
              if (response?.state) {
                this.callWSState(response);
              }
            }
          },
          (error) => {
            logger.warn(error);
          }
        );
      }
    } catch (dropException) {
      logger.warn(dropException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: VACATION_UPDATE_FAILURE_MESSAGE }),
      });
    }
  };

  callWSState = (payload) => {
    let websocketPayload = {
      alias: payload?.alias,
      type: payload.state === 5 ? "MALA" : "VAC",
      date: getLocalTimeDate(payload?.starttime),
      endDate: getLocalTimeDate(payload?.endtime),
    };
    this.websocketState.current.callWebsocket(-1, websocketPayload);
  };
  handleEventDelete = () => {
    const { intl } = this.props;
    const { VACATION_UPDATE_FAILURE_MESSAGE, VACATION_DELETE_SUCCESS_MESSAGE } =
      MESSAGE_KEYS;
    const { selectedAppointment } = this.state;
    try {
      if (selectedAppointment?._def.extendedProps.appointmentId) {
        sendQuery(
          `${QUERIES.DELETE_VACATION}${selectedAppointment._def.extendedProps.appointmentId}`,
          "DELETE"
        ).then(
          () => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: intl.formatMessage({
                id: VACATION_DELETE_SUCCESS_MESSAGE,
              }),
            });
            this.setState({ editDialogVisible: false });
            selectedAppointment.remove();
            this.forceBudgetUpdate();
          },
          (error) => {
            logger.log(error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({
                id: VACATION_UPDATE_FAILURE_MESSAGE,
              }),
            });
          }
        );
      }
    } catch (deleteException) {
      logger.log(deleteException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: VACATION_UPDATE_FAILURE_MESSAGE }),
      });
    }
  };

  /**
   * Maps BE vacation data to objects readable for FullCalendar.
   *
   * @param {Array<Object>} response
   */
  mapResponseToEvents = (response) => {
    return new Promise((resolve, reject) => {
      try {
        logger.info("MAPPING RESPONSE", response);
        let events = [];
        if (response && response.length > 0) {
          response.forEach((entry) => {
            const {
              starttime,
              endtime,
              appointmentId,
              typeOfAppointment,
              remark,
              remark2,
              state,
              countedFullDays,
              halfday,
              user,
              alias,

              createDate,
              createUser,
              lastModifyUser,
              lastModifyDate,
            } = entry;
            let eventColor = this.getEventColor(state);
            let title = alias ? alias : "";
            events.push({
              id: appointmentId,
              title,
              start: new Date(starttime),
              end: new Date(endtime),
              allDay: halfday || starttime === endtime,
              nextDayThreshold: "23:00:00",
              extendedProps: {
                appointmentTypeId: typeOfAppointment,
                appointmentId,
                state: state,
                remark,
                remark2,
                countedFullDays,
                halfday,
                user,

                createDate,
                createUser,
                lastModifyUser,
                lastModifyDate,
                startDate: new Date(starttime),
                endDate: new Date(endtime),
              },
              color: eventColor,
              backgroundColor: eventColor,
              borderColor: eventColor,
              textColor: eventColor === sickYellow ? "black" : "white",
            });
          });
        }
        resolve(events);
      } catch (mapException) {
        logger.warn(mapException);
        reject([]);
      }
    });
  };

  forceBudgetUpdate = () => {
    if (this.state.filterUser?.personId === null) {
      return;
    }
    this.state.filterUser?.personId !== -1
      ? this.fetchVacationBudget(this.state.filterUser.personId)
      : this.fetchVacationBudget(this.props.currentUser.personId);
  };
  /**
   * Extracts DTO data from the supplied FullCalendar event.
   *
   * @param {Object} event The FullCalendar event that will be converted.
   * @param {Boolean} convertDate Flag telling the mapper whether or not the end date should be converted from UTC. Defaults to false.
   * @returns
   */
  mapEventToDTO = (event, convertDate = false) => {
    logger.info("MAPPING", event);
    try {
      const {
        _def: {
          extendedProps: {
            appointmentId,
            state,
            remark,
            remark2,
            countedFullDays,
            halfday,
            user,
            startDate,
            endDate,
          },
        },
      } = event;
      const PAID_VACATION_TYPE_ID = 1;
      return {
        appointmentId,
        typeOfAppointment: PAID_VACATION_TYPE_ID,
        starttime: dateToISOString(startDate),
        endtime: dateToISOString(endDate),
        active: true,
        state,
        remark,
        remark2,
        countedFullDays,
        halfday,
        user,
      };
    } catch (mappingException) {
      logger.warn(mappingException);
      return null;
    }
  };

  /**
   * Toggles event edit overlay.
   * The overlay is only toggled if the current user is either an admin or creator of the current event.
   *
   * @param {Object} event The received DOM event
   * @param {Object} selectedEvent The fullcalendar event.
   */
  toggleEventOverlay = (event, selectedEvent) => {
    const { currentUser } = this.props;
    const {
      event: {
        _def: {
          extendedProps: { user },
        },
      },
    } = selectedEvent;
    if (isCurrentUserAdmin() || user === currentUser.personId) {
      const { displayedDate } = this.state;
      let startDate = new Date(displayedDate.getTime());
      startDate.setMonth(0, 1);
      let endDate = new Date(displayedDate.getTime());
      endDate.setMonth(11, 31);

      sendQuery(
        `${QUERIES.GET_VACATION_REPORT_BY_DATE_AND_USER}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}&userId=${user}`,
        "get"
      )
        .then((response) => {
          this.setState({
            availableDays: response[0]?.available ?? 0,
            budgetDays: response[0]?.budget ?? 0,
            editDialogVisible: true,
          });
        })
        .catch(() => {
          this.setState({
            availableDays: 0,
            budgetDays: 0,
            editDialogVisible: true,
          });
        });
    }
  };

  /**
   * Renders the lefthand list of vacations of the current user.
   *
   * @returns {JSX.Element}
   */
  renderMyVacations = () => {
    const { vacationstates, currentLocale, displayedDate, myVacations } =
      this.state;
    try {
      return (
        <MyVacationsLayout
          value={{ vacationstates, currentLocale, displayedDate, myVacations }}
        />
      );
    } catch (renderException) {
      logger.warn(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  renderBugdets = () => {
    const { vacationBudgetData, displayedDate, vacationstates } = this.state;
    try {
      if (vacationBudgetData) {
        return (
          <VacationBudget
            value={{ vacationBudgetData, displayedDate, vacationstates }}
          />
        );
      } else {
        return <></>;
      }
    } catch (renderException) {
      logger.warn(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  renderEvent = (e) => {
    const { vacationstates, currentLocale } = this.state;

    return (
      <VacationEventLayout
        value={e}
        onClick={(event) => this.toggleEventOverlay(event, e)}
        vacationStates={vacationstates}
        currentLocale={currentLocale}
        handleAccept={() => {
          e.event.setExtendedProp(
            "state",
            vacationstates[2].appointmentStateId
          );
          this.handleEventSave(e.event);
        }}
        handleReject={() => {
          e.event.setExtendedProp(
            "state",
            vacationstates[3].appointmentStateId
          );
          this.handleEventSave(e.event);
        }}
      />
    );
  };

  /**
   * Renders the FullCalendar component.
   *
   * @returns {JSX.Element}
   */
  renderCalendar = () => {
    const {
      appointmentFetchPending,
      weekendsVisible,
      currentEvents,
      displayedDate,
    } = this.state;

    try {
      return (
        <div>
          {this.renderBugdets()}
          <CalendarToolbar
            displayedDate={displayedDate}
            handleDateChange={this.handleDateChange}
            monthOnly
            disabled={appointmentFetchPending}
          />
          <FullCalendar
            id="vacation_calendar_base"
            ref={this.calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
            initialView={"dayGridMonth"}
            editable={true}
            selectable={true}
            selectMirror={true}
            headerToolbar={false}
            droppable
            weekends={weekendsVisible}
            initialEvents={[]}
            select={this.handleDateSelect}
            events={currentEvents}
            eventContent={this.renderEvent} // custom render function
            eventClick={this.handleEventClick}
            eventRemove={(event) => {}}
            locales={[deLocale, frLocale]}
            locale={this.state.currentLocale}
            firstDay={1}
            dayMaxEvents={false}
            eventStartEditable={isCurrentUserAdmin()}
            eventAdd={(event) => {
              this.handleEventSave(event.event);
            }}
          />
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  render = () => {
    const { intl } = this.props;
    const {
      VACATIONS_CREATE_DIALOG_TITLE,
      VACATIONS_EDIT_DIALOG_TITLE,
      VACATIONS_HOLIDAY_TITLE_LABEL,
    } = MESSAGE_KEYS;
    const {
      selectedAppointment,
      createDialogVisible,
      selection,
      vacationstates,
      currentLocale,
      drivers,
      holidayDialogVisible,
      currentEvents,
      editDialogVisible,
      displayedDate,
      filterUser,
      availableDays,
      budgetDays,
    } = this.state;

    let dialogId = `crt_vac_dlg_${Math.floor(Math.random() * 1000)}`;

    return (
      <div>
        <DriverStatusWebsocket ref={this.websocketState} />
        <Toast ref={(el) => (this.toast = el)} />
        <Dialog
          header={intl.formatMessage({ id: VACATIONS_HOLIDAY_TITLE_LABEL })}
          visible={holidayDialogVisible}
          onHide={() => {
            this.setState({ holidayDialogVisible: false });
          }}
        >
          <HolidayLayout
            handleSave={this.handleHolidayCreate}
            holidays={currentEvents.filter((e) => e.display === "background")}
            onHide={() => {
              this.setState({ holidayDialogVisible: false });
            }}
          />
        </Dialog>
        <Dialog
          id={dialogId}
          header={intl.formatMessage({ id: VACATIONS_CREATE_DIALOG_TITLE })}
          visible={createDialogVisible}
          onHide={() => {
            this.setState({ createDialogVisible: false });
          }}
        >
          <VacationEventPopup
            filterUser={filterUser}
            drivers={drivers}
            value={selection}
            onHide={() => {
              this.setState({ createDialogVisible: false });
            }}
            onDelete={this.handleEventDelete}
            onConfirm={this.handleEventCreate}
            vacationStates={vacationstates}
            currentLocale={currentLocale}
            baseId={dialogId}
            isNew={true}
            available={availableDays}
            budget={budgetDays}
          />
        </Dialog>
        <VacationDraggables
          isDesktop={isDesktop}
          sickYellow={sickYellow}
          vacationstates={vacationstates}
          currentLocale={currentLocale}
          displayedDate={displayedDate}
          select={this.handleDateSelect}
          updateParent={this.handleDateChange}
          filterUser={filterUser}
          drivers={drivers}
          changeUser={this.changeUser}
        />

        <Dialog
          header={intl.formatMessage({ id: VACATIONS_EDIT_DIALOG_TITLE })}
          visible={editDialogVisible}
          onHide={() => {
            this.setState({ editDialogVisible: false });
          }}
        >
          <VacationEventPopup
            filterUser={filterUser}
            drivers={drivers}
            value={selection}
            vacationStates={vacationstates}
            available={availableDays}
            budget={budgetDays}
            onDelete={this.handleEventDelete}
            onConfirm={({
              startDate,
              endDate,
              remark,
              remark2,
              state,
              halfday,
              countedFullDays,
            }) => {
              const isSingle =
                halfday || startDate.getTime() === endDate.getTime();
              /**
               * This is required if a when an event changes from single to multiple days and vice versa.
               * Apparently Fullcalendar minds the order. I don't even.
               */
              if (!isSingle) {
                selectedAppointment.setAllDay(isSingle);
                selectedAppointment.setDates(startDate, endDate);
              } else {
                selectedAppointment.setDates(startDate, endDate);
                selectedAppointment.setAllDay(isSingle);
              }

              selectedAppointment.setExtendedProp("remark", remark);
              selectedAppointment.setExtendedProp("remark2", remark2);
              selectedAppointment.setExtendedProp("startDate", startDate);
              selectedAppointment.setExtendedProp("endDate", endDate);
              selectedAppointment.setExtendedProp("halfday", halfday);
              selectedAppointment.setExtendedProp(
                "countedFullDays",
                countedFullDays
              );
              selectedAppointment.setExtendedProp("state", state);

              this.handleEventSave(selectedAppointment);
            }}
            onHide={() => {
              this.setState({ editDialogVisible: false });
            }}
            currentLocale={currentLocale}
            isNew={false}
          />
        </Dialog>
        <div className="grid">
          {isDesktop ? (
            <div className="col-2">{this.renderMyVacations()}</div>
          ) : (
            <></>
          )}
          <div className={`col-${isDesktop ? "10" : "12"}`}>
            {this.renderCalendar()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      persist: { vacationStates },
      authentication: { currentUser },
    } = state;

    return { currentUser, vacationStates };
  } catch (mapException) {
    return { currentUser: null, vacationStates: null };
  }
};

export default connect(mapStateToProps, { setTopbarTitle, setVacationStates })(
  injectIntl(VacationsView)
);
