import React from "react";
// PrimeReact Imports
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const ReturnLine = (props) => {
  const { value, handleUpdate, handleDelete, index, intl } = props;
  const { startDate, appointmentTime } = value;

  return (
    <div className="col-4 lg:col-4 md:col-6 p-sm-12 flex mt-3">
      <div className="mr-3">
        <span className="p-float-label">
          <Calendar
            value={startDate}
            dateFormat="dd/mm/yy"
            onChange={(e) =>
              handleUpdate({ startDate: e.value, appointmentTime }, index)
            }
            id={`imk_startDate_${index}`}
            selectOtherMonths={true}
          />
          <label htmlFor={`imk_startDate_${index}`}>
            {intl.formatMessage({
              id: MESSAGE_KEYS.PAYMENTS_DATE_LABEL,
            })}
          </label>
        </span>
      </div>
      <div className="mr-3">
        <span className="p-float-label">
          <InputMask
            mask="99:99"
            value={appointmentTime}
            onChange={(e) =>
              handleUpdate({ startDate, appointmentTime: e.value }, index)
            }
            style={{ width: "100%" }}
            id={`imk_appointmentTime_${index}`}
          />
          <label htmlFor={`imk_appointmentTime_${index}`}>
            {intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENTS_START_DATE_LABEL,
            })}
          </label>
        </span>
      </div>
      <div className="mr-3">
        <Button
          type="button"
          icon="pi pi-minus-circle"
          className="p-button-danger"
          onClick={() => handleDelete(index)}
        />
      </div>
    </div>
  );
};

export default injectIntl(ReturnLine);
