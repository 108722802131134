import React, { useState, useRef } from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// Helper functions
import { sendQuery, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Generate download dialog
import download from "downloadjs";
// Static values
import {
  QUERIES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,  
} from "assets/staticData/enums";
// Logging
const logger = initLogger("print_customer_button");

const PrintCustomerButton = (props) => {
  const { customerId, intl, className = "" } = props;
  const { BILLS_GENERATING_MESSAGE, ERROR_DATA_FETCH, CUSTOMERS_PRINT_BUTTON_LABEL } = MESSAGE_KEYS;
  const { INFO, ERROR } = MESSAGE_SEVERITY;
  let [pending, setPending] = useState(false);
  const toast = useRef(null);

  const handleFileDownload = () => {
    setPending(true);
    toast.current.show({
      severity: INFO,
      summary: intl.formatMessage({
        id: BILLS_GENERATING_MESSAGE,
      }),
    });
    try {
      sendQuery(
        `${QUERIES.GET_BILL_CUSTOMER_REPORT}${customerId}`,
        "GET",
        null,
        "blob"
      ).then(
        (response) => {
          if (response) {
            download(
              response,
              `${new Date().getTime()}.pdf`,
              "application/pdf"
            );
          }
          setPending(false);
        },
        (error) => {
          setPending(false);
          logger.warn(error);
          if (toast && toast.current) {
            toast.current.show({
              severity: ERROR,
              summary: intl.formatMessage({
                id: ERROR_DATA_FETCH,
              }),
            });
          }
        }
      );
    } catch (sendException) {
      setPending(false);
      logger.error(sendException);
      toast.current.show({
        severity: ERROR,
        summary: intl.formatMessage({
          id: ERROR_DATA_FETCH,
        }),
      });
    }
  };

  return (
    <div className={className}>
      <Toast ref={toast} />
      <Button
        icon={`pi ${pending ? "pi-spin pi-spinner" : "pi-file-pdf"}`}
        onClick={handleFileDownload}
        disabled={pending || !customerId}
        label={intl.formatMessage({id: CUSTOMERS_PRINT_BUTTON_LABEL})}
      />
    </div>
  );
};

export default injectIntl(PrintCustomerButton);
