/**
 * This component generates an input group consisting of buttons, a text input and an overlay.
 * It allows the user to look up and select customers using the filter component, or clear the current selection.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Toast } from "primereact/toast";
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
// Localization
import { injectIntl } from "react-intl";
// Custom components
import { AddressFilterLayout } from "components/AddressView/components";
// Static values
import {
  QUERIES,
  MESSAGE_SEVERITY,
  MESSAGE_KEYS,
} from "assets/staticData/enums";
// Helper classes
import { sendQuery, initLogger, generateQueryParameters } from "common/Helpers";
// Logging
const logger = initLogger("AddressSelector");

class AddressSelector extends React.Component {
  state = {
    displayedAddresses: [],
    fetchPending: false,
  };

  /**
   * Fetches customer data from the backend that corresponds to the filter input values.
   * The result count is limited to 30.
   * Stores result in state on success, stores error else.
   *
   * @param {Object} filter
   * @param {String} filter.inputAddress Not sure about this one actually. Legacy?
   * @param {String} filter.inputCity Filter by city
   * @param {String} filter.inputZip Filter by ZIP code
   * @param {String} filter.inputFirstName Filter by customer first name.
   * @param {String} filter.inputLastName Filter by customer last name.
   * @param {Boolean} filter.inputFuzzyAddress True => Look for results exactly like address input, look for similar addresses else.
   */
  filterAddresses = (filter) => {
    try {
      let page = 0; // Always display the first page of results.
      const rows = 30; // Limit results to 30.      
      if (filter) {
        let queryString = generateQueryParameters(filter, "personId");
        this.setState({ fetchPending: true }, () => {
          sendQuery(
            `${QUERIES.GET_ADDRESSES_PAGES}page=${page}&size=${rows}${
              queryString ? queryString : this.state.queryString
            }`,
            "get",
            null
          ).then(
            (response) => {
              this.setState({
                displayedAddresses: response.content,
                fetchPending: false,
              });
            },
            (error) => {
              this.setState(
                {
                  fetchPending: false,
                  fetchError: error,
                },
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary:
                    typeof error === "string"
                      ? error
                      : this.props.intl.formatMessage({
                          id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                        }),
                })
              );
            }
          );
        });
      }
    } catch (filterException) {
      logger.error(filterException);
    }
  };
  /**
   * Renders the filter results.
   * @returns {JSX.Element} A listbox component if results are available, an empty component else
   */
  renderList = () => {
    try {
      const { selectedAddress, displayedAddresses } = this.state;
      if (displayedAddresses && displayedAddresses.length > 0) {
        return (
          <ListBox
            value={selectedAddress}
            options={displayedAddresses}
            itemTemplate={this.renderListItem}
            onChange={(e) => this.handleAddressSelection(e.value)}
            optionLabel="line1"            
            listStyle={{maxHeight: '202px'}}
            tabIndex={4}
            className="ml-2"
          />
        );
      } else {
        return <></>;
      }
    } catch (listRenderException) {
      logger.warn("Exception in renderList", listRenderException);
      return <></>;
    }
  };
  /**
   * Custom renderer for result listbox rows.
   * Displays the result's health insurance number, first- & line1 (if available).
   *
   * @param {String} item A div element with the rows available data on success, an error div else.
   */
  renderListItem = (item) => {
    try {
      const { zipCode, line1, countryProvince, customerName, phone1, phone2, remark } = item;
      let phoneLine;
      if(phone1 || phone2 || remark) {
        phoneLine = <div>
          {`${phone1 ? phone1 : ""} ${phone2 ? phone2 : ""} ${remark ? remark : ""}`}
        </div>
      } else {
        phoneLine = <></>;
      }
      return (        
        <div className="flex flex-column">
          {customerName ? <div>{customerName}</div> : <></>}
          <div>{`${line1 ? line1 : ""} ${zipCode ? zipCode : ""} ${countryProvince ? countryProvince : ""}`}</div>    
          {phoneLine}      
        </div> 
      );
    } catch (renderException) {
      logger.warn("Could not render list item.", renderException, item);
      return "Error";
    }
  };
  /**
   * This function is called when an item in the listbox is clicked.
   * Calls the onChange function from the props and closes the filter overlay.
   *
   * @param {Object} selectedAddress The object attached to the respective row.
   */
  handleAddressSelection = (selectedAddress) => {
    this.props.onChange(selectedAddress);
    this.addressOp.hide();
  };
  /**
   * This function is called when the clear button was clicked.
   * Calls the props' onChange function with null in order to clear the value attached to this component.
   */
  clearAddressSelector = () => {
    this.props.onChange(null); 
  };
  /**
   * This function renders the button used to toggle the filter overlay visibility.
   * @returns {JSX.Element} A div component containing an icon button with the corresponding overlay panel.
   */
  renderAddressOverlayButton = () => {
    return (
      <div>
        <Button icon="pi pi-search" onClick={(e) => this.addressOp.toggle(e)} />
        <OverlayPanel
          ref={(el) => (this.addressOp = el)}
          showCloseIcon
          dismissable
        >
          {this.renderOverlayContent()}
        </OverlayPanel>
      </div>
    );
  };
  /**
   * This function renders the filter overlay content.
   * @returns {JSX.Element} A div containing a Toast- & AddressFilterLayout-component.
   */
  renderOverlayContent = () => {
    return (
      <div className="flex justify-content-between">
        <Toast ref={(el) => (this.toast = el)} />
        <AddressFilterLayout
          isPending={this.state.fetchPending}
          handleSearch={this.filterAddresses}
          isOverlay          
        />
        {this.renderList()}
      </div>
    );
  };
  /**
   * Renders the component base.
   * Returns an input group; the layout of the input group varies based on the component's value.
   * - If value is not set, the input group contains a disabled text input with placeholder text and the overlay button.
   * - If value is set, the input group contains a clear button, a disabled text input displaying the value content and the overlay button.
   * @returns {JSX.Element} The respective input group based on the component's value.
   */
  renderInput = () => {
    const { value, placeholder, intl, valid } = this.props;

    let clearButton;
    let displayText;
    if (value) {
      // Value is set, display clear button & selection value.
      const {
        value: { line1, line2, countryProvince, zipCode },
      } = this.props;
      clearButton = (
        <Button
          className="p-button-warning"
          icon="pi pi-times"
          onClick={this.clearAddressSelector}
        />
      );
      displayText = `${line1 ? line1 : ""} ${line2 ? line2 : ""} ${
        zipCode ? zipCode : ""
      } ${countryProvince ? countryProvince : ""}`;
    } else {
      // No value is set, hide clear button & display placeholder if set, standard text else.
      if (placeholder) {
        displayText = placeholder;
      } else {
        displayText = intl.formatMessage({
          id: MESSAGE_KEYS.BILLS_FILTER_ADDRESS_LABEL,
        });
      }
      clearButton = <></>;
    }
    return (
      <div className="p-inputgroup">
        {this.renderAddressOverlayButton()}
        <InputText
          value={displayText}
          disabled
          className={`${
            valid === true || valid === null || valid === undefined
              ? ""
              : "p-invalid"
          }`}
        />
        {clearButton}
      </div>
    );
  };
  /**
   * Main render function.
   * Calls the renderInput function to render the base component, returns an empty element if an error occurs.
   *
   * @returns {JSX.Element} The AddressSelectorComponent on success, an empty element else.
   */
  render = () => {
    try {
      return this.renderInput();
    } catch (renderException) {
      logger.warn("Exception on renderAddressSelection", renderException);
      return <div></div>;
    }
  };
}

export default injectIntl(AddressSelector);
