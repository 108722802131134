import React from "react";
// PrimeReact components
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
// Responsive
import { isMobile } from "react-device-detect";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { dateToString, initLogger } from "common/Helpers";
// Styling
import "./Style.scss";
import FloatingTextInput from "components/common/FloatingTextInput";

const logger = initLogger("shift_event_popup");
const EMPTY_STATE = {
  inputSelection: [],
  inputSource: [],
  inputOnDuty: "",
  title: "",
};

class ShiftEventPopup extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  initInputs = () => {
    const { value } = this.props;
    try {
      const {
        _def: {
          title,
          extendedProps: { members, onCallClinic },
        },
        _instance: {
          range: { start },
        },
      } = value;
      this.setState({
        title: ` ${dateToString(start)} - ${title}`,
        inputSelection: members,
        inputSource: this.normalizeSource(members),
        inputOnDuty: onCallClinic,
      });
    } catch (initException) {
      logger.warn(initException);
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  normalizeSource = (members) => {
    let inputSource;
    try {
      const { drivers } = this.props;
      let selectionIds = [];
      members.forEach((member) => {
        selectionIds.push(member.personId);
      });
      inputSource = [...members];
      drivers.forEach((driver) => {
        if (!selectionIds.includes(driver.personId)) {
          inputSource.push({
            personId: driver.personId,
            shortname: driver.alias,
          });
        }
      });
      return inputSource;
    } catch (normalException) {
      return [...members];
    }
  };

  handlePersonDelete = (personId) => {
    const { inputSelection } = this.state;
    let newSelection = [...inputSelection];
    let delIndex = newSelection.findIndex(
      (searchElement) => personId === searchElement.personId
    );
    if (delIndex >= 0) {
      newSelection.splice(delIndex, 1);
    }
    this.setState({
      inputSelection: newSelection,
    });
  };

  handlePersonSelection = ({ value }) => {
    this.setState({ inputSelection: [...value] });
  };

  handleDutyInput = (event) => {
    this.setState({ inputOnDuty: event.target.value });
  };

  callSaveClick = () => {
    const { inputSelection, inputOnDuty } = this.state;
    this.props.handleSaveClick(inputSelection, inputOnDuty);
  };

  renderSelection = () => {
    const { intl } = this.props;
    const { SHIFTS_NO_MEMBERS_LABEL, ERROR_RENDER } = MESSAGE_KEYS;
    let content;
    try {
      const { inputSelection } = this.state;
      if (inputSelection && inputSelection.length > 0) {
        content = [];
        inputSelection.forEach((member) => {
          content.push(
            <div
              key={`${member.personId}${new Date().getTime()}`}
              className="mb-1 flex flex-row justify-content-between align-items-center"
            >
              {member.shortname}
              <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger"
                onClick={() => this.handlePersonDelete(member.personId)}
              />
            </div>
          );
        });
        return content;
      } else {
        return <div>{intl.formatMessage({ id: SHIFTS_NO_MEMBERS_LABEL })}</div>;
      }
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderContent = () => {
    const { intl, simpleView } = this.props;
    const {
      ERROR_RENDER,
      SHIFTS_EDIT_BUTTON_LABEL,
      SHIFTS_DUTY_LABEL,
      SHIFTS_DELETE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    try {
      const { inputSelection, inputSource, inputOnDuty, title } = this.state;
      return (
        <div style={{ width: isMobile ? "100%" : "500px" }}>
          <div className="mb-4 font-bold">{title}</div>
          <div className="grid">
            <div className="col-6 mr-2 shift_user_select">
              <MultiSelect
                optionLabel="shortname"
                value={inputSelection}
                options={inputSource}
                onChange={this.handlePersonSelection}
                filter
                filterBy={"shortname"}
              />
              {!simpleView && (
                <FloatingTextInput
                  label={intl.formatMessage({ id: SHIFTS_DUTY_LABEL })}
                  value={inputOnDuty}
                  onChange={this.handleDutyInput}
                />
              )}
            </div>
            <div className="flex flex-column col-5">
              {this.renderSelection()}
            </div>
          </div>
          <div className="flex justify-content-between">
            <Button
              onClick={() => this.callSaveClick()}
              label={intl.formatMessage({ id: SHIFTS_EDIT_BUTTON_LABEL })}
            />
            {!simpleView && (
              <Button
                onClick={this.props.onDelete}
                label={intl.formatMessage({ id: SHIFTS_DELETE_BUTTON_LABEL })}
              />
            )}
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    return this.renderContent();
  };
}

export default injectIntl(ShiftEventPopup);
