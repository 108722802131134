import React, { Component } from "react";
import classNames from "classnames";
import { AppTopbar, AppMenu, AppProfile } from "components/Layout";
import { Prompt } from "react-router-dom";
// PrimeReact Components
import { locale, addLocale } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
// Views
import ChangePasswordDialog from "components/ChangePasswordDialog";
// Custom components
import { AppRoutes, GlobalShoutboxWebsocket } from "./components";
// Styles
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "layout/layout.scss";

// Redux
import { connect } from "react-redux";
import {
  fetchOfflineData,
  setTopbarTitle,
  logout,
  setMenuActive,
} from "actions";
// Localization
import { injectIntl } from "react-intl";
// Helper function
import {
  generateCalendarLocale,
  isCurrentUserAdmin,
  equalObjects,
  initLogger,
} from "common/Helpers";
// Static data
import {
  ADMIN_MENU_PROD,
  ADMIN_MENU,
  USER_MENU,
  BILLS_MENU,
  APPOINTMENTS_MENU,
  OFFLINE_MENU,
  FIDUS_MENU,
  AUTHOR_MENU,
} from "assets/staticData/menustructure";
import {
  MESSAGE_KEYS,
  LOCALES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
import {
  AUTHOR_ROLE,
  DRIVER_ROLE,
  FIDUS_ROLE,
  INVOICE_ROLE,
  PLANNING_ROLE,
  USER_ROLE,
} from "assets/staticData/combodata";

const logger = initLogger("main_view");

class App extends Component {
  state = {
    layoutMode: "static",
    layoutColorMode: "dark",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
    getOfflineData: true,

    isAdmin: false,
    isBilling: false,
    isPlanning: false,
    isFidus: false,

    menuModel: [],
    isProd: ["start-prod", "prod"].includes(process.env.REACT_APP_SETTING),
    passwordDialogVisible: false,
    pushRegistration: null,
  };

  componentDidMount = () => {
    addLocale(LOCALES.GERMAN.key, generateCalendarLocale(LOCALES.GERMAN.key));
    addLocale(LOCALES.FRENCH, generateCalendarLocale(LOCALES.FRENCH.key));
    this.createMenu();
    this.websocket = React.createRef();
  };

  componentDidUpdate = (prevProps) => {
    const { currentUser, fetchOfflineData, backendAvailable } = this.props;
    if (this.state.getOfflineData && currentUser) {
      this.setState(
        {
          getOfflineData: false,
        },
        () => {
          fetchOfflineData(currentUser.token);
        }
      );
    }
    // Build menu if not yet done or if the logged in user was changed.
    if (
      !this.menu ||
      !equalObjects(prevProps.currentUser, currentUser, "personId") ||
      prevProps.backendAvailable !== backendAvailable
    ) {
      this.createMenu();
    }
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  };

  componentDidCatch = (error, info) => {
    this.messages.show({
      severity: MESSAGE_SEVERITY.ERROR,
      summary: info,
      detail: error,
    });
  };

  onWrapperClick = () => {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  };

  generateUpdateText = (message) => {
    const {
      SHOUTBOX_NOTIFICATION_EDIT_BODY,
      SHOUTBOX_NOTIFICATION_EDIT_TITLE,
      SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE,
      SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY,
      SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY,
    } = MESSAGE_KEYS;
    if (message.hasOwnProperty("reactionId")) {
      return {
        messageId: SHOUTBOX_NOTIFICATION_EDIT_REACTION_BODY,
        titleId: SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE,
        sender: { personId: message.personId, alias: message.alias },
        title: "",
      };
    } else if (message.hasOwnProperty("shoutboxId")) {
      return {
        messageId: SHOUTBOX_NOTIFICATION_EDIT_BODY,
        titleId: SHOUTBOX_NOTIFICATION_EDIT_TITLE,
        sender: message.sender,
        title: message.title,
      };
    } else {
      return {
        messageId: SHOUTBOX_NOTIFICATION_EDIT_DEFAULT_BODY,
        titleId: SHOUTBOX_NOTIFICATION_EDIT_REACTION_TITLE,
        sender: { personId: "", alias: "" },
        title: "",
      };
    }
  };

  handleWSCall = (msg) => {
    try {
      let changes = JSON.parse(msg.body);
      const { intl, currentUser } = this.props;
      if (changes.type === undefined) {
        const updateText = this.generateUpdateText(changes);
        if (updateText.sender.personId !== currentUser.personId) {
          this.triggerNotification(
            intl.formatMessage({ id: updateText.titleId }),
            intl.formatMessage(
              { id: updateText.messageId },
              { sender: updateText.sender.alias, title: updateText.title }
            )
          );
        }
      }
    } catch (wsException) {
      logger.error(wsException);
    }
  };

  triggerNotification(title, message) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(title, {
          body: message,
          icon: "/assets/layout/images/logo_luxabl_small.png",
        });
      });
    }
  }

  onToggleMenu = (event) => {
    const { setMenuActive } = this.props;
    this.menuClick = true;
    let menuActive;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: this.state.overlayMenuActive,
        });
        menuActive = !this.state.overlayMenuActive;
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
        menuActive = this.state.staticMenuInactive;
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
      menuActive = mobileMenuActive;
    }

    event.preventDefault();
    setMenuActive(menuActive);
  };

  onSidebarClick = (event) => {
    this.menuClick = true;
  };

  onMenuItemClick = (event) => {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  };

  onToggleViewMode = (value) => {
    this.setState({ layoutColorMode: value });
  };

  /**
   * Menu template structure used to generate the role restricted menus.
   * @typedef {Object} MenuTemplate
   * @property {String} template.url The URL for the respective page. Is null for submenus.
   * @property {String} template.icon The classname of the icon displayed next to the menu item.
   * @property {String} template.label The message key for the translated menu item text.
   * @property {Array<MenuTemplate>} template.items The submenu items
   */
  /**
   * Generates the menu structure based on the supplied menu template.
   * Is called recursivly for nested menus.
   *
   * @param {Array<MenuTemplate>} template
   * @returns {Array<Objects>} An array containing the menu items for PrimeReact.
   */
  generateMenuFromTemplate = (template) => {
    const { intl } = this.props;
    let menu = [];
    template.forEach((entry) => {
      const { url, icon, label } = entry;
      let menuItem = {
        label: intl.formatMessage({ id: label ? label : MESSAGE_KEYS.ERROR }),
        icon: icon,
      };
      if (entry.items && entry.items.length > 0) {
        menuItem.items = [...this.generateMenuFromTemplate(entry.items)];
      } else {
        menuItem.to = url;
      }
      menu.push(menuItem);
    });

    const uniqueArray = menu.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        menu.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    return uniqueArray;
  };

  createMenu() {
    const { currentUser, backendAvailable } = this.props;
    let menuType;
    let isBilling, isAdmin, isPlanning, isFidus;
    isBilling = isAdmin = isPlanning = isFidus = false;
    if (backendAvailable) {
      if (isCurrentUserAdmin()) {
        isAdmin = true;
        if (this.state.isProd) {
          menuType = ADMIN_MENU_PROD;
        } else {
          menuType = ADMIN_MENU;
        }
      } else {
        menuType = [];
        if (currentUser?.userRoles) {
          if (
            currentUser.userRoles.findIndex((role) => {
              return role.role_id === INVOICE_ROLE.role_id;
            }) >= 0
          ) {
            isBilling = true;
            menuType.push(...BILLS_MENU);
          }
          if (
            currentUser.userRoles.findIndex((role) => {
              return role.role_id === PLANNING_ROLE.role_id;
            }) >= 0
          ) {
            isPlanning = true;
            menuType.push(...APPOINTMENTS_MENU);
          }
          if (
            currentUser.userRoles.findIndex((role) => {
              return role.role_id === FIDUS_ROLE.role_id;
            }) >= 0
          ) {
            menuType.push(...FIDUS_MENU);
            isFidus = true;
          }
          if (
            currentUser.userRoles.findIndex((role) => {
              return role.role_id === USER_ROLE.role_id;
            }) >= 0
          ) {
            menuType.push(...USER_MENU);
          }
          if (
            currentUser.userRoles.findIndex((role) =>
              [DRIVER_ROLE.role_id, USER_ROLE.role_id].includes(role.role_id)
            ) >= 0
          ) {
            menuType.push(...USER_MENU);
          }
          if (
            currentUser.userRoles.findIndex((role) => {
              return role.role_id === AUTHOR_ROLE.role_id;
            }) >= 0
          ) {
            menuType.push(...AUTHOR_MENU);
          }
        }
        if (menuType.length < 1) {
          menuType = USER_MENU;
        }
      }
    } else {
      menuType = OFFLINE_MENU;
    }
    let menuModel = [...this.generateMenuFromTemplate(menuType)];
    this.setState({
      menuModel,
      isAdmin,
      isBilling,
      isPlanning,
      isFidus,
    });
    this.menu = [...menuModel];
    logger.info(menuModel);
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  renderApplication = () => {
    const logo = "assets/layout/images/logo_luxabl_small.png";
    const {
      passwordDialogVisible,
      layoutMode,
      staticMenuInactive,
      overlayMenuActive,
      mobileMenuActive,
      layoutColorMode,
    } = this.state;
    const logoFontClass =
      this.state.layoutColorMode === "dark" ? "dark_logo" : "light_logo";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": layoutMode === "overlay",
      "layout-static": layoutMode === "static",
      "layout-static-sidebar-inactive":
        staticMenuInactive && layoutMode === "static",
      "layout-overlay-sidebar-active":
        overlayMenuActive && layoutMode === "overlay",
      "layout-mobile-sidebar-active": mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": layoutColorMode === "dark",
      "layout-sidebar-light": layoutColorMode === "light",
    });

    const { intl, sessionExpired, logout, backendAvailable } = this.props;
    const { isAdmin, isBilling, isPlanning, isFidus } = this.state;

    const logoutButton = (
      <div>
        <Button
          label={intl.formatMessage({ id: MESSAGE_KEYS.MENU_LOGOUT })}
          onClick={() => logout()}
        />
      </div>
    );

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <ChangePasswordDialog visible={passwordDialogVisible} />
        <Dialog
          icon="pi pi-exclamation-triangle"
          visible={sessionExpired}
          closeOnEscape={false}
          closable={false}
          onHide={() => {}}
          header={intl.formatMessage({ id: MESSAGE_KEYS.ERROR })}
          footer={logoutButton}
        >
          {intl.formatMessage({ id: MESSAGE_KEYS.ERROR_TOKEN_EXPIRED })}
        </Dialog>
        <GlobalShoutboxWebsocket
          callback={this.handleWSCall}
          ref={this.websocket}
        />
        <AppTopbar
          onToggleMenu={this.onToggleMenu}
          onToggleViewMode={this.onToggleViewMode}
          layoutColorMode={this.state.layoutColorMode}
        />
        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <div className="layout-logo">
            <img alt="Logo" src={logo} style={{ maxHeight: 60 }} />
            <div className={logoFontClass}>
              <strong>Luxambulance S.à.r.l</strong>
              <div
                className="font-light align-self-end"
                style={{ fontSize: "x-small" }}
              >{`v${process.env.REACT_APP_VERSION_NUMBER}-${process.env.REACT_APP_SETTING}`}</div>
            </div>
          </div>
          <AppProfile />
          <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
        </div>
        <AppRoutes
          isAdmin={isAdmin}
          isBilling={isBilling}
          isPlanning={isPlanning}
          isFidus={isFidus}
          backendAvailable={backendAvailable}
        />
        <div className="layout-mask"></div>
      </div>
    );
  };

  render() {
    let currentLocale = LOCALES.GERMAN.key;

    if (this.props.currentUser) {
      const { currentUser } = this.props;
      if (!isNaN(currentUser.languageId)) {
        // Find & set language locale of logged in user.
        let userLocale = Object.keys(LOCALES).find((key) => {
          return LOCALES[key].languageId === currentUser.languageId;
        });
        if (userLocale) {
          currentLocale = LOCALES[userLocale].key;
        } else {
          currentLocale = LOCALES.GERMAN.key;
        }
      }
      locale(currentLocale);
    }
    return (
      <div>
        <ConfirmDialog />
        <Prompt
          message={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.WARNING_UNSAVED_CHANGES,
          })}
          when={this.props.changePending}
        />
        {this.renderApplication()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authentication: { currentUser, sessionExpired },
    application: {
      backendAvailable,
      offlineData: { hasOfflineData },
    },
    session: { changePending },
  } = state;
  return {
    currentUser,
    backendAvailable,
    hasOfflineData,
    changePending,
    sessionExpired,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchOfflineData,
    setTopbarTitle,
    logout,
    setMenuActive,
  })(App)
);
