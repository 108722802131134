/**
 * This component renders the users view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { FilterMatchMode } from "primereact/api";
// Custom components
import { UserEditView } from "components/UsersView/components";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS, QUERIES, ROWS_PER_PAGE } from "assets/staticData/enums";
// Helper classes
import { sendQuery, initLogger } from "common/Helpers";
// Styling
import "./Style.scss";
import { ActiveCellTemplate } from "components/common/index";
// Logging
const logger = initLogger("users_view");

class UsersView extends React.Component {
  state = {
    first: 0,
    rows: ROWS_PER_PAGE[isDesktop ? 2 : 0],
    rowsPerPage: ROWS_PER_PAGE,
    displayedUsers: [],
    totalUsers: 0,
    currentPage: 0,
    selectedUser: null,
    fetchPending: true,
    fetchError: null,
    userFetchPending: false,
    userFetchError: null,

    inputBudgets: [],

    filters: {
      alias: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      gsm: { value: null, matchMode: FilterMatchMode.CONTAINS },
      userAddress: { value: null, matchMode: FilterMatchMode.CONTAINS },
      hireDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      active: { value: true, matchMode: FilterMatchMode.EQUALS },
    },
  };

  componentDidMount = () => {
    this.fetchUsers();
    this.props.setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_USERS })
    );
  };

  /**
   * Gets called on paginator actions. (Page or # rows changed).
   * Updates paginator values in the state, then fetches users based on page & rows value.
   *
   * @param {Object} event The page change event of the paginator.
   * @param {Number} event.first Index of the first object in the page. Is usually 1.
   * @param {Number} event.rows The maximum number of rows displayed in the table.
   * @param {Number} event.page The number of the currently display page.
   */
  handlePageChange = (event) => {
    const { first, rows, page } = event;
    this.setState({
      first,
      rows,
      currentPage: page,
    });
  };
  /**
   * Gets called when a row in the table is selected.
   * Will attempt to fetch detailed user data for the selected row.
   * Fetched user data will be stored in the state on success, store clear user selection data else.
   *
   * @param {Number} user.personId The ID of the selected user.
   */
  handleSelectionChange = (userId) => {
    if (userId > 0) {
      this.fetchUser(userId);
    } else {
      this.setState({ selectedUser: { selectedUser: { personId: userId } } });
    }
  };

  fetchUser = (userId) => {
    this.setState({
      userFetchError: null,
      userFetchPending: true,
    });
    sendQuery(`${QUERIES.GET_USER_BY_ID}${userId}`, "GET", null).then(
      (response) => {
        if (response && response.user) {
          const { user } = response;
          this.setState({
            selectedUser: user,
            userFetchPending: false,
            userFetchError: null,

            inputBudgets: user.vacationBudget ? [...user.vacationBudget] : [],
          });
        }
      },
      (error) => {
        logger.warn(error);
        this.setState({
          selectedUser: null,
          userFetchPending: false,
          userFetchError: error,

          inputBudgets: [],
        });
      }
    );
  };
  /**
   * Attemps to fetch the requested page of users from the backend.
   * Will update stored users with backend response in state on success, clears stored users else.
   *
   */
  fetchUsers = () => {
    try {
      sendQuery(
        `${QUERIES.GET_USERS_PAGES}?size=3000&sort=personId,asc`,
        "get"
      ).then(
        (response) => {
          if (response && response.content) {
            this.setState({
              displayedUsers: [...response.content],
              totalUsers: response.length,
              fetchPending: false,
            });
          }
        },
        (error) => {
          this.setState({
            fetchPending: false,
            fetchError: error,
          });
        }
      );
    } catch (fetchException) {
      logger.error(fetchException);
      this.setState({
        fetchPending: false,
        fetchError: fetchException.message,
        displayedUsers: [],
        totalUsers: 0,
      });
    }
  };
  /**
   * Renders the toolbar above the table containing the add user button.
   * The add user button will be disabled during fetch actions or on fetch errors.
   *
   * @returns {JSX.Element} The user action toolbar.
   */
  renderToolbar = () => {
    const { fetchError, fetchPending } = this.state;

    const leftContent = (
      <Button
        icon="pi pi-user-plus"
        onClick={() => this.handleSelectionChange(-1)}
        disabled={fetchError || fetchPending}
      />
    );

    return <Toolbar left={leftContent} />;
  };

  renderDateCell = ({ hireDate }) => {
    if (hireDate) {
      const dateParts = hireDate.split("-");
      if (dateParts.length === 3) {
        return <div>{`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`}</div>;
      } else {
        return <div>{hireDate}</div>;
      }
    } else {
      return <></>;
    }
  };

  /**
   * Renders a table containing the current page of users and its corresponding paginator
   * Will return a data table component if user fetch action was successful, will return a card component with an error message else.
   *
   * @returns {JSX.Element} Datatable & paginator on user fetch success, card with error message else.
   */
  renderTable = () => {
    const { intl } = this.props;
    const {
      ERROR_NO_DATA,
      USERS_NICK_NAME_LABEL,
      ACCOUNT_EMAIL_LABEL,

      USERS_MOBILE_PHONE_LABEL,
      USERS_HIRE_DATE_LABEL,

      CUSTOMERS_FILTER_ADDRESS,
      ACTIVE,
    } = MESSAGE_KEYS;
    const {
      displayedUsers,
      first,
      fetchError,
      fetchPending,
      totalUsers,
      rows,
      rowsPerPage,
      selectedUser,
    } = this.state;

    try {
      return (
        <div>
          <DataTable
            value={displayedUsers}
            selectionMode="single"
            selection={selectedUser}
            onSelectionChange={(event) => {
              this.handleSelectionChange(event.value?.personId);
            }}
            emptyMessage={intl.formatMessage({
              id: ERROR_NO_DATA,
            })}
            loading={fetchPending}
            className="p-datatable-sm"
            paginator
            rows={rows}
            first={first}
            rowsPerPageOptions={rowsPerPage}
            totalRecords={totalUsers}
            filterDisplay="row"
            filters={this.state.filters}
          >
            <Column
              field="alias"
              header={intl.formatMessage({ id: USERS_NICK_NAME_LABEL })}
              filter
              showFilterMenu={false}
              sortable
            />
            <Column
              field="email"
              header={intl.formatMessage({ id: ACCOUNT_EMAIL_LABEL })}
              filter
              showFilterMenu={false}
              sortable
            />
            <Column
              field="gsm"
              header={intl.formatMessage({ id: USERS_MOBILE_PHONE_LABEL })}
              filter
              showFilterMenu={false}
            />
            <Column
              field="userAddress"
              header={intl.formatMessage({ id: CUSTOMERS_FILTER_ADDRESS })}
              filter
              showFilterMenu={false}
            />
            <Column
              field="hireDate"
              header={intl.formatMessage({ id: USERS_HIRE_DATE_LABEL })}
              filter
              showFilterMenu={false}
              body={this.renderDateCell}
            />
            <Column
              field="active"
              header={intl.formatMessage({ id: ACTIVE })}
              body={ActiveCellTemplate}
              filter
              dataType="boolean"
              filterElement={(filter) => {
                return (
                  <TriStateCheckbox
                    value={filter.value}
                    onChange={(e) => {
                      filter.filterApplyCallback(e.value);
                    }}
                  />
                );
              }}
              showFilterMenu={false}
            />
          </DataTable>
        </div>
      );
    } catch (renderError) {
      logger.error(renderError);
      return <Card>{fetchError.message}</Card>;
    }
  };

  render = () => {
    const { selectedUser } = this.state;
    if (isDesktop) {
      return (
        <div className="grid ">
          <div className="col-7">
            {this.renderToolbar()}
            {this.renderTable()}
          </div>
          <div className="col-5">
            {
              <UserEditView
                selectedUser={selectedUser}
                updateParent={(newUser) => {
                  this.fetchUsers();
                  this.setState({ selectedUser: newUser });
                }}
              />
            }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.renderToolbar()}
          {this.renderTable()}
          <UserEditView selectedUser={selectedUser} />
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authentication.currentUser,
  };
};

export default connect(mapStateToProps, { setTopbarTitle })(
  injectIntl(UsersView)
);
