/**
 * This component renders the user CRUD view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { updateAccount } from "actions";
// PrimeReact Components
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
// Custom Components
import {
  FloatingTextInput,
  FloatingPassword,
  UserEmergencyPersonLayout,
} from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Encryption
import { genSaltSync, hashSync } from "bcryptjs";
// Static data
import {
  LOCALES,
  VALIDATION_RULES,
  MESSAGE_SEVERITY,
  MESSAGE_KEYS,
  QUERIES,
} from "assets/staticData/enums";
import { ROLES } from "assets/staticData/combodata";
// Helper functions
import {
  sendQuery,
  initLogger,
  equalObjects,
  isCurrentUserAdmin,
} from "common/Helpers";
// import css
// Styling
import "./Common.scss";
import { LanguageSelector } from "./index";
import download from "downloadjs";
// Logging
const logger = initLogger("user_edit_layout");

const EMPTY_STATE = {
  isNewUser: true,
  inputUsername: "",
  inputEmail: "",
  inputLocale: LOCALES.FRENCH.languageId,
  inputPassword: "",
  inputPasswordRepeat: "",
  inputRoles: [],

  inputFirstName: "",
  inputLastName: "",
  inputNickName: "",
  inputPhoneWork: "",
  inputPhoneHome: "",
  inputPhoneMobile: "",
  inputAllergies: "",
  inputDiseases: "",
  inputBloodGroup: "",
  inputComment: "",
  inputEmergencyPersons: [],

  inputAddress: "",

  inputHireDate: "",
  inputBirthDate: "",

  inputTimeRecording: false,
  inputWeekHours: 0,

  inputActive: true,

  validUsername: false,
  validEmail: false,
  validPassword: false,

  updatePending: false,
};

class UserEditLayout extends React.Component {
  state = { ...EMPTY_STATE };

  /**
   * Init input values when component is mounted.
   */
  componentDidMount = () => {
    this.initInputs();
  };

  /**
   * If the component was updated, the follwing things are checked:
   * - Component was in a pending state but isn't anymore => display failure toast if state error is set, success toast else.
   * - Another user was selected => fetch new user data.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { pending, error, selectedUser, intl } = this.props;
    // Display update results when action is no longer pending.
    if (prevProps.pending !== pending) {
      if (pending === false) {
        if (error) {
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary:
              typeof error === "string"
                ? error
                : this.props.intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                  }),
          });
        } else {
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.ACCOUNT_SAVE_SUCCESS_MESSAGE,
            }),
          });
        }
      }
    }
    // Fetch new user data if another user was selected.
    if (selectedUser.personId !== prevProps.selectedUser.personId) {
      this.initInputs();
    }
  };

  /**
   * Initialize input field values based on selected user.
   * Setup empty input fields when no user is selected.
   */
  initInputs = () => {
    const { selectedUser } = this.props;
    let isNewUser = !selectedUser.hasOwnProperty("personId");
    let inputRoles = {};
    if (isNewUser !== true) {
      const {
        selectedUser: {
          login,
          email,
          roles = [],
          languageId,
          firstname,
          lastname,
          alias,
          phoneHome,
          phoneWork,
          gsm,
          active,
          allergies,
          diseases,
          bloodGroup,
          comment,

          userAddress,

          dateOfBirth,
          hireDate,
          emergencyPersons,

          timerecording,
          hoursPerWeek,
        },
      } = this.props;
      let compareArray = roles.map((role) => role.role_id);

      ROLES.forEach((role) => {
        inputRoles[role.messageKey] = {
          id: role.role_id,
          selected: compareArray.includes(role.role_id),
        };
      });      

      this.setState({
        isNewUser,
        inputUsername: login ?? "",
        inputEmail: email,
        inputLocale: languageId,
        inputPassword: "",
        inputPasswordRepeat: "",
        validUsername: true,
        validEmail: true,
        validPassword: true,
        inputRoles,

        inputFirstName: firstname ?? "",
        inputLastName: lastname ?? "",
        inputNickName: alias ?? "",
        inputPhoneWork: phoneWork ?? "",
        inputPhoneHome: phoneHome ?? "",
        inputPhoneMobile: gsm ?? "",
        inputAllergies: allergies ?? "",
        inputDiseases: diseases ?? "",
        inputBloodGroup: bloodGroup ?? "",
        inputComment: comment ?? "",
        inputEmergencyPersons: emergencyPersons ?? [],

        inputAddress: userAddress ?? "",

        inputBirthDate: dateOfBirth ?? "",
        inputHireDate: hireDate ?? "",

        inputActive: active,

        inputWeekHours: hoursPerWeek ?? 0,
        inputTimeRecording: timerecording,
      });
    } else {
      ROLES.forEach((role) => {
        inputRoles[role.messageKey] = {
          id: role.role_id,
          selected: false,
        };
      });
      this.setState({
        ...EMPTY_STATE,
        inputRoles,
      });
    }
  };

  /**
   * Returns a promise that validates the input fields.
   * On resolve, it returns a mapped user object that's ready to be sent to the backend.
   * On reject, it returns an array of errors.
   *
   * @returns {Promise<Object|Array<String>>}
   */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      const { intl } = this.props;
      const { inputUsername, inputEmail, inputPassword, inputPasswordRepeat } =
        this.state;
      const { USERNAME_MIN_LENGTH, PASSWORD_MIN_LENGTH } = VALIDATION_RULES;
      const {
        ACCOUNT_ERROR_EMAIL_INVALID,
        ACCOUNT_ERROR_EMAIL_REQUIRED,
        ACCOUNT_ERROR_NAME_LENGTH,
        ACCOUNT_ERROR_NAME_REQUIRED,
        ACCOUNT_ERROR_PASSWORD_LENGTH,
        ACCOUNT_ERROR_PASSWORD_MATCH,
      } = MESSAGE_KEYS;
      const { ERROR } = MESSAGE_SEVERITY;
      const errors = [];
      // Check username length
      let validUsername = inputUsername.length >= USERNAME_MIN_LENGTH;
      if (!validUsername) {
        if (!inputUsername) {
          errors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: ACCOUNT_ERROR_NAME_REQUIRED }),
          });
        } else {
          errors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: ACCOUNT_ERROR_NAME_LENGTH }),
          });
        }
      }
      // Check email input (has Email input & is valid email)
      let validEmail = inputEmail && inputEmail.match(/\S+@\S+\.\S+/) !== null;
      if (!validEmail) {
        if (!inputEmail) {
          errors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: ACCOUNT_ERROR_EMAIL_REQUIRED }),
          });
        } else {
          errors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: ACCOUNT_ERROR_EMAIL_INVALID }),
          });
        }
      }
      // Check password input (only if current user is admin and any password entered, check length & matches password repeat)
      let validPassword = true;
      if (
        isCurrentUserAdmin() &&
        (inputPassword.length > 0 || inputPasswordRepeat.length > 0)
      ) {
        validPassword =
          inputPassword === inputPasswordRepeat &&
          inputPassword.length >= PASSWORD_MIN_LENGTH;
        if (!validPassword) {
          if (inputPassword !== inputPasswordRepeat) {
            errors.push({
              severity: ERROR,
              summary: intl.formatMessage({ id: ACCOUNT_ERROR_PASSWORD_MATCH }),
            });
          }
          if (inputPassword.length < PASSWORD_MIN_LENGTH) {
            errors.push({
              severity: ERROR,
              summary: intl.formatMessage({
                id: ACCOUNT_ERROR_PASSWORD_LENGTH,
              }),
            });
          }
        }
      }
      if (errors.length > 0) {
        logger.warn(errors);
        this.setState({ validUsername, validEmail, validPassword });
        reject(errors);
      } else {
        this.setState({ validUsername, validEmail, validPassword });
        resolve();
      }
    });
  };

  mapInputsToDTO = () => {
    return new Promise((resolve, reject) => {
      let errors = [];
      try {
        const { selectedUser } = this.props;
        const {
          inputUsername,
          inputEmail,
          inputPassword,
          inputLocale,
          inputRoles,

          inputFirstName,
          inputLastName,
          inputNickName,
          inputPhoneWork,
          inputPhoneHome,
          inputPhoneMobile,

          inputAllergies,
          inputBloodGroup,
          inputDiseases,
          inputComment,

          inputAddress,

          inputActive,

          inputHireDate,
          inputBirthDate,

          inputEmergencyPersons,

          inputTimeRecording,
          inputWeekHours,
        } = this.state;
        const { PASSWORD_MIN_LENGTH } = VALIDATION_RULES;

        let roles = [];
        Object.keys(inputRoles).forEach((inputRole) => {
          const { id, selected } = inputRoles[inputRole];
          if (selected === true) {
            roles.push({
              role_id: id,
              name: inputRole,
            });
          }
        });
        let newAccountData = {
          user: {
            personId: selectedUser.personId ? selectedUser.personId : null,

            login: inputUsername,
            email: inputEmail,
            languageId: inputLocale,
            roles,

            firstname: inputFirstName,
            lastname: inputLastName,
            alias: inputNickName,
            phoneHome: inputPhoneHome,
            phoneWork: inputPhoneWork,
            gsm: inputPhoneMobile,
            active: inputActive,
            allergies: inputAllergies,
            diseases: inputDiseases,
            bloodGroup: inputBloodGroup,
            comment: inputComment,
            emergencyPersons: inputEmergencyPersons,

            userAddress: inputAddress,

            dateOfBirth: inputBirthDate,
            hireDate: inputHireDate,
            timerecording: inputTimeRecording,
            hoursPerWeek: inputWeekHours,
          },
          addressList: [],
        };
        if (inputPassword && inputPassword.length >= PASSWORD_MIN_LENGTH) {
          newAccountData.user.password = hashSync(
            inputPassword,
            genSaltSync(12)
          );
        }
        resolve(newAccountData);
      } catch (mapException) {
        logger.warn("Exception on map inputs to DTO", mapException, this.state);
        errors.push({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: mapException.message,
        });
        reject(errors);
      }
    });
  };

  /**
   * Sends the modified data to the server.
   * @param {*} data
   */
  sendData = (data) => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.EDIT_USER, "post", data).then(
          (response) => {
            logger.info(response);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
        // TODO Handle offline action
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  handleReportClick = () => {
    const { selectedUser, intl } = this.props;
    this.setState({ updatePending: true });
    sendQuery(
      `${QUERIES.GET_USER_REPORT}${selectedUser.personId}`,
      "GET",
      null,
      "blob"
    )
      .then(
        (response) => {
          this.setState({ updatePending: false });
          if (response) {
            download(response);
          }
        },
        (error) => {
          this.setState({ updatePending: false });
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary:
              typeof error === "string"
                ? error
                : intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                  }),
          });
        }
      )
      .catch((reportException) => {
        this.setState({ updatePending: false });
        logger.error(reportException);
      });
  };

  /**
   * Get called when the save button is clicked.
   * Validates inputs and send them to the server if they check out. Will update parent table data on success.
   * Will display an error message if the validation data transfer fails.
   */
  handleSaveClick = () => {
    const { updateParent, currentUser, updateAccount } = this.props;
    this.setState({ updatePending: true });
    this.validateInputs().then(
      () => {
        this.mapInputsToDTO().then(
          (newAccountData) => {
            try {
              this.sendData(newAccountData).then(
                (response) => {
                  const { user } = newAccountData;
                  if (equalObjects(currentUser, user, "personId")) {
                    // When current user data was edited, map new data to redux data, then update redux state.
                    let reduxData = { ...user, userRoles: [...user.roles] };
                    if (reduxData.hasOwnProperty("roles")) {
                      delete reduxData.roles;
                    }
                    updateAccount(reduxData);
                  }
                  if (updateParent) {
                    updateParent(response.user);
                  }
                  this.setState({ updatePending: false });
                  this.toast.show({
                    severity: MESSAGE_SEVERITY.SUCCESS,
                    summary: this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.ACCOUNT_SAVE_SUCCESS_MESSAGE,
                    }),
                  });
                },
                (error) => {
                  logger.error(error);
                  this.toast.show({
                    severity: MESSAGE_SEVERITY.ERROR,
                    summary:
                      typeof error === "string"
                        ? error
                        : this.props.intl.formatMessage({
                            id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                          }),
                  });
                  this.setState({ updatePending: false });
                }
              );
            } catch (uploadException) {
              logger.error(uploadException);
              this.setState({ updatePending: false });
            }
          },
          (errors) => {
            this.toast.show(errors);
            this.setState({ updatePending: false });
          }
        );
      },
      (errors) => {
        this.toast.show(errors);
        this.setState({ updatePending: false });
      }
    );
  };

  addEmergencyPersonRow = (newData, index) => {
    let newPersons = [...this.state.inputEmergencyPersons];
    if (index !== null) {
      newPersons[index] = newData;
    } else {
      newPersons.push(newData);
    }
    this.setState({
      inputEmergencyPersons: [...newPersons],
    });
  };

  /**
   * Renders input checkboxes for each available user role.
   * The available user roles are dependend on the currently logged in user's role.
   * @returns {Array<JSX.Element>|JSX.Element} - An array of role checkboxes on success, an error message else.
   */
  renderRoleCheckboxes = () => {
    try {
      const { intl } = this.props;
      const { inputRoles } = this.state;
      if (inputRoles) {
        let result = [];
        let roles = Object.keys(inputRoles);
        roles.forEach((role) => {
          const { selected } = inputRoles[role];
          result.push(
            <div className="col-12" key={`input_${role}`}>
              <Checkbox
                className="mr-2"
                inputId={`cb_${role}`}
                onChange={(event) =>
                  this.setState({
                    inputRoles: {
                      ...inputRoles,
                      [role]: { ...inputRoles[role], selected: event.checked },
                    },
                  })
                }
                checked={selected}
              ></Checkbox>
              <label htmlFor={`cb_${role}`} className="p-checkbox-label">
                {intl.formatMessage({ id: role })}
              </label>
            </div>
          );
        });
        return result;
      }
    } catch (renderException) {
      logger.error(renderException);
      return <></>;
    }
  };

  /**
   * Renders a dropdown element containing the available locales.
   *
   * @returns {JSX.Element}
   */
  renderLocaleSelect = () => {
    return (
      <LanguageSelector
        value={this.state.inputLocale}
        setValue={(newValue) => {
          this.setState({ inputLocale: newValue });
        }}
      />
    );
  };

  /**
   * Renders the Save/Reset-buttons at the bottom of the element.
   * If a data transfer is pending, this function returns a loading animation, else it will return a <div> element containing the save and reset buttons.
   * The label of the save button changes whether the selected user is an existing or a new one.
   *
   * @returns {JSX.Element}
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      ACCOUNT_CREATE_BUTTON_LABEL,
      ACCOUNT_SAVE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { pending, intl } = this.props;
    const { updatePending, inputActive, isNewUser } = this.state;
    if (pending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else {
      let saveButtonLabel = "";
      if (!isNewUser) {
        saveButtonLabel = intl.formatMessage({ id: ACCOUNT_SAVE_BUTTON_LABEL });
      } else {
        saveButtonLabel = intl.formatMessage({
          id: ACCOUNT_CREATE_BUTTON_LABEL,
        });
      }
      return (
        <div className="account_button_row mb-2">
          <Button
            onClick={() => this.handleSaveClick()}
            label={saveButtonLabel}
            disabled={updatePending}
          />
          {isCurrentUserAdmin() && (
            <ToggleButton
              checked={inputActive ? true : false}
              offIcon="pi pi-times"
              offLabel={intl.formatMessage({ id: INACTIVE })}
              onIcon={"pi pi-check"}
              onLabel={intl.formatMessage({ id: ACTIVE })}
              onChange={(e) => this.setState({ inputActive: e.value })}
              disabled={updatePending}
            />
          )}
          <Button
            onClick={() => this.initInputs()}
            label={intl.formatMessage({
              id: RESET_VALUES,
            })}
            className="p-button-warning"
            disabled={updatePending}
          />
        </div>
      );
    }
  };

  /**
   * Renders a value as simple text if a value is supplied.
   *
   * @param {string} label The message key of the label above the value.
   * @param {string} value The displayed value
   * @param {number} width The gridwidth, from 1-12.
   * @returns A JSX-element displaying the value with a label if a value is set, an empty element else.
   */
  renderInputDisplay = (label, value, width) => {
    if (value) {
      return (
        <div className={`col-${width}`}>
          <div className="font-light mb-2" style={{ fontSize: "12px" }}>
            {this.props.intl.formatMessage({ id: label })}
          </div>
          <div>{value}</div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  renderUserInputs = () => {
    const { intl, updateParent, selectedUser } = this.props;
    const {
      ERROR_RENDER,
      USERS_FIRST_NAME_LABEL,
      USERS_HOME_PHONE_LABEL,
      USERS_LAST_NAME_LABEL,
      USERS_MOBILE_PHONE_LABEL,
      USERS_NICK_NAME_LABEL,
      USERS_WORK_PHONE_LABEL,
      CUSTOMERS_FILTER_ADDRESS,
      USERS_HIRE_DATE_LABEL,
      CUSTOMERS_BIRTH_DATE_LABEL,
      USERS_ALLERGIES_LABEL,
      USERS_DISEASES_LABEL,
      USERS_BLOODTYPE_LABEL,
      CUSTOMERS_COMMENT_LABEL,
      USERS_INFO_REPORT_BUTTON_LABEL,
      USERS_TIMERECORDING_LABEL,
      USERS_WEEKHOURS_LABEL,
    } = MESSAGE_KEYS;
    try {
      const {
        inputFirstName,
        inputLastName,
        inputNickName,
        inputPhoneHome,
        inputPhoneMobile,
        inputPhoneWork,
        inputAllergies,
        inputDiseases,
        inputBloodGroup,
        inputComment,
        inputAddress,
        inputHireDate,
        inputBirthDate,
        isNewUser,
        inputEmergencyPersons,
        inputWeekHours,
        inputTimeRecording,
        updatePending,
      } = this.state;

      const isAdmin = isCurrentUserAdmin();
      return (
        <div className="grid">
          <FloatingTextInput
            id={USERS_FIRST_NAME_LABEL}
            value={inputFirstName}
            label={intl.formatMessage({
              id: USERS_FIRST_NAME_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputFirstName: event.target.value });
            }}
            className={`col-${isNewUser ? "6" : "5"}`}
          />
          <FloatingTextInput
            id={USERS_LAST_NAME_LABEL}
            value={inputLastName}
            label={intl.formatMessage({
              id: USERS_LAST_NAME_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputLastName: event.target.value });
            }}
            className={`col-${isNewUser ? "6" : "5"}`}
          />
          {!isNewUser && (
            <div className="col-2 flex align-items-end">
              <Button
                onClick={() => this.handleReportClick()}
                label={intl.formatMessage({
                  id: USERS_INFO_REPORT_BUTTON_LABEL,
                })}
                className="p-button"
                disabled={isNewUser || updatePending}
                icon="pi pi-file-pdf"
              />
            </div>
          )}
          <FloatingTextInput
            id={USERS_ALLERGIES_LABEL}
            value={inputAllergies}
            label={intl.formatMessage({
              id: USERS_ALLERGIES_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputAllergies: event.target.value });
            }}
            className="col-5"
          />
          <FloatingTextInput
            id={USERS_DISEASES_LABEL}
            value={inputDiseases}
            label={intl.formatMessage({
              id: USERS_DISEASES_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputDiseases: event.target.value });
            }}
            className="col-5"
          />
          <FloatingTextInput
            id={USERS_BLOODTYPE_LABEL}
            value={inputBloodGroup}
            label={intl.formatMessage({
              id: USERS_BLOODTYPE_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputBloodGroup: event.target.value });
            }}
            className="col-2"
          />

          {isAdmin && updateParent ? (
            <FloatingTextInput
              id={USERS_NICK_NAME_LABEL}
              value={inputNickName}
              label={intl.formatMessage({
                id: USERS_NICK_NAME_LABEL,
              })}
              onChange={(event) => {
                this.setState({ inputNickName: event.target.value });
              }}
              className="col-6"
            />
          ) : (
            this.renderInputDisplay(USERS_NICK_NAME_LABEL, inputNickName, 6)
          )}

          <FloatingTextInput
            id={USERS_HOME_PHONE_LABEL}
            value={inputPhoneHome}
            label={intl.formatMessage({
              id: USERS_HOME_PHONE_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPhoneHome: event.target.value });
            }}
            className="col-6"
          />
          <FloatingTextInput
            id={USERS_WORK_PHONE_LABEL}
            value={inputPhoneWork}
            label={intl.formatMessage({
              id: USERS_WORK_PHONE_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPhoneWork: event.target.value });
            }}
            className="col-6"
          />
          <FloatingTextInput
            id={USERS_MOBILE_PHONE_LABEL}
            value={inputPhoneMobile}
            label={intl.formatMessage({
              id: USERS_MOBILE_PHONE_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPhoneMobile: event.target.value });
            }}
            className="col-6"
          />

          <FloatingTextInput
            id={CUSTOMERS_FILTER_ADDRESS}
            value={inputAddress}
            label={intl.formatMessage({
              id: CUSTOMERS_FILTER_ADDRESS,
            })}
            onChange={(event) => {
              this.setState({ inputAddress: event.target.value });
            }}
            className="col-12"
          />

          <div className="flex flex-column col-6">
            <label className="font-light" style={{ fontSize: "12px" }}>
              {intl.formatMessage({
                id: CUSTOMERS_BIRTH_DATE_LABEL,
              })}
            </label>
            <InputMask
              mask="9999-99-99"
              value={inputBirthDate}
              onChange={(e) => this.setState({ inputBirthDate: e.value })}
            />
          </div>
          {isAdmin ? (
            <div className="flex flex-column col-6">
              <label className="font-light" style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: USERS_HIRE_DATE_LABEL,
                })}
              </label>
              <InputMask
                mask="9999-99-99"
                value={inputHireDate}
                onChange={(e) => this.setState({ inputHireDate: e.value })}
              />
            </div>
          ) : (
            this.renderInputDisplay(USERS_HIRE_DATE_LABEL, inputHireDate, 6)
          )}
          <div className="flex flex-column col-12">
            <label
              htmlFor={CUSTOMERS_COMMENT_LABEL}
              className="font-light"
              style={{ fontSize: "12px" }}
            >
              {intl.formatMessage({ id: CUSTOMERS_COMMENT_LABEL })}
            </label>
            <InputTextarea
              id={CUSTOMERS_COMMENT_LABEL}
              value={inputComment}
              onChange={(e) => this.setState({ inputComment: e.target.value })}
              rows={3}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
            />
          </div>
          {isAdmin && updateParent && (
            <div className="flex flex-row col-12 grid mt-3">
              <div className="col-6">
                <Checkbox
                  className="mr-2"
                  inputId={USERS_TIMERECORDING_LABEL}
                  onChange={(event) =>
                    this.setState({
                      inputTimeRecording: event.checked,
                    })
                  }
                  checked={inputTimeRecording}
                ></Checkbox>
                <label
                  htmlFor={USERS_TIMERECORDING_LABEL}
                  className="p-checkbox-label"
                >
                  {intl.formatMessage({ id: USERS_TIMERECORDING_LABEL })}
                </label>
              </div>
              <span className="p-float-label">
                <InputNumber
                  id={USERS_WEEKHOURS_LABEL}
                  value={inputWeekHours}
                  onChange={(event) => {
                    this.setState({ inputWeekHours: event.value });
                  }}
                  min={0}
                  className="col-6"
                />
                <label htmlFor={USERS_WEEKHOURS_LABEL}>
                  {intl.formatMessage({ id: USERS_WEEKHOURS_LABEL })}
                </label>
              </span>
            </div>
          )}
          <div className="col-12">
            <UserEmergencyPersonLayout
              value={inputEmergencyPersons}
              handleUpdate={(value) =>
                this.setState({ inputEmergencyPersons: value })
              }
              addRow={this.addEmergencyPersonRow}
              userId={selectedUser.personId ? selectedUser.personId : null}
            />
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on render user inputs", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderAccountInputs = () => {
    const { intl, updateParent } = this.props;
    const {
      ACCOUNT_NAME_LABEL,
      ACCOUNT_EMAIL_LABEL,
      ACCOUNT_PASSWORD_LABEL,
      ACCOUNT_PASSWORD_REPEAT_LABEL,
      ERROR_RENDER,
    } = MESSAGE_KEYS;
    try {
      const {
        inputUsername,
        inputEmail,
        inputPassword,
        inputPasswordRepeat,
        validPassword,
        validEmail,
        validUsername,
      } = this.state;

      const isAdmin = isCurrentUserAdmin();

      return (
        <div className="grid">
          <FloatingTextInput
            id={ACCOUNT_NAME_LABEL}
            value={inputUsername}
            label={intl.formatMessage({
              id: ACCOUNT_NAME_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputUsername: event.target.value });
            }}
            valid={validUsername}
            className="col-12"
          />
          <FloatingTextInput
            id={ACCOUNT_EMAIL_LABEL}
            value={inputEmail}
            label={intl.formatMessage({
              id: ACCOUNT_EMAIL_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputEmail: event.target.value });
            }}
            valid={validEmail}
            className="col-12"
          />
          <FloatingPassword
            id={ACCOUNT_PASSWORD_LABEL}
            value={inputPassword}
            label={intl.formatMessage({
              id: ACCOUNT_PASSWORD_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPassword: event.target.value });
            }}
            feedback={true}
            valid={validPassword}
            className="col-12"
          />

          <FloatingPassword
            id={ACCOUNT_PASSWORD_REPEAT_LABEL}
            value={inputPasswordRepeat}
            label={intl.formatMessage({
              id: ACCOUNT_PASSWORD_REPEAT_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPasswordRepeat: event.target.value });
            }}
            feedback={false}
            valid={validPassword}
            className="col-12"
          />

          {isAdmin && updateParent && this.renderLocaleSelect()}
        </div>
      );
    } catch (renderException) {
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const renderCheckboxes =
      isCurrentUserAdmin() &&
      this.props.updateParent !== undefined &&
      this.props.updateParent !== null;

    try {
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <div>{this.renderUserInputs()}</div>
          <div className={`${isDesktop ? "grid" : ""}`}>
            <div className={`${isDesktop ? "col-8" : ""}`}>
              {this.renderAccountInputs()}
            </div>
            <div className={`${isDesktop ? "col-4" : ""}`}>
              {renderCheckboxes && this.renderRoleCheckboxes()}
            </div>
          </div>
          {this.renderButtonRow()}
        </div>
      );
    } catch (renderException) {
      logger.error(renderException.message);
    }
  };
}

const mapStateToProps = (state) => {
  const {
    authentication: { currentUser },
  } = state;
  return { currentUser };
};

export default connect(mapStateToProps, { updateAccount })(
  injectIntl(UserEditLayout)
);
