import React from "react";

// Primereact components
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

import { MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { useIntl } from "react-intl";

const AdditionalTimeRow = ({
  garageStart,
  garageEnd,
  pause,
  showButton,
  changeValue,
  index,
  addRow,
  deleteRow,
  blocked,
}) => {
  const {
    DRIVES_HEADER_GARAGE_START,
    DRIVES_HEADER_GARAGE_END,
    DRIVES_HEADER_PAUSE,
  } = MESSAGE_KEYS;
  const intl = useIntl();  
  return (
    <div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_START,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageStart}
            onChange={(event) => {
              changeValue(index, "starttime", event.target.value);
            }}
            style={{
              outline: garageStart ? "none" : "3px solid red",
            }}
            className="time_input"
            disabled={blocked}
          />
        </div>
      </div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_END,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageEnd}
            onChange={(event) => {
              changeValue(index, "endtime", event.target.value);
            }}
            style={{
              outline: garageEnd ? "none" : "3px solid red",
            }}
            className="time_input"
            disabled={blocked}
          />
        </div>
      </div>
      <div>
        <div
          style={{ display: "contents" }}
          className="flex gap-3 align-items-end"
        >
          <div>
            <div className="font-bold">
              {intl.formatMessage({
                id: DRIVES_HEADER_PAUSE,
              })}
              :
            </div>
            <InputNumber
              value={pause}
              onChange={(event) => {
                changeValue(index, "pauseMin", event.value);
              }}
              mode="decimal"
              inputStyle={{
                outline: pause !== null ? "none" : "3px solid red",
              }}
              className="time_input"
              disabled={blocked}
            />
          </div>
          {!blocked && (
            <div className="flex gap-1">
              <Button
                icon="pi pi-minus"
                aria-label="remove"
                onClick={() => deleteRow(index)}
                className="p-button-rounded p-button-danger"
              />
              <Button
                icon="pi pi-plus"
                aria-label="add"
                style={{
                  visibility: showButton ? "visible" : "hidden",
                }}
                onClick={addRow}
                className="p-button-rounded p-button-primary"
                disabled={blocked}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalTimeRow;
