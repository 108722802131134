import React from "react";
// Helper functions
import { initLogger } from "common/Helpers";
// Websocket connections
import { Client } from "@stomp/stompjs";
// Live update
let websocket = null;
const logger = initLogger("appointmentwebsocket");

class DriverStatusWebsocket extends React.Component {
  componentDidMount = () => {
    this.connectWebsocket();
  };

  componentWillUnmount = () => {
    this.disconnectWebsocket();
  };

  connectWebsocket = () => {
    const { callback } = this.props;
    let brokerURL;

    switch (process.env.REACT_APP_SETTING) {
      case "prod":
      case "start":
      case "start-prod":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_PROD;
        break;
      case "demo":
      case "start-demo":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEMO;
        break;
      case "kai":
      case "start-local-kai":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_KAI;
        break;
      default:
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEV;
    }

    try {
      websocket = new Client();
      websocket.configure({
        brokerURL,
        onConnect: () => {
          logger.warn("Connected to", brokerURL);
          websocket.subscribe(
            process.env.REACT_APP_WEBSOCKET_DRIVER_STATE_SUBSCRIBE,
            callback
          );
        },
      });

      websocket.activate();
    } catch (connectException) {      
      logger.warn(connectException, brokerURL);
    }
  };

  callWebsocket = (id, vacObj = null) => {
    try {
      const destination =
        vacObj === null
          ? process.env.REACT_APP_WEBSOCKET_DRIVER_STATE_PUBLISH //driver state
          : process.env.REACT_APP_WEBSOCKET_DRIVER_STATE_HOLIDAY_PUBLISH; //holiday
      logger.info("SENDING STATE MESSAGE", destination);
      websocket.publish({
        destination,
        body: vacObj === null ? id : JSON.stringify(vacObj),
      });
    } catch (sendException) {
      logger.warn(sendException);
    }
  };

  callWebsocketGarage = (id, starttime) => {
    try {
      const destination =
        process.env.REACT_APP_WEBSOCKET_DRIVER_STATE_GARAGE_START_PUBLISH;

      const payload = {
        driverStateId: id,
        starttime: starttime,
      };
      logger.info("SENDING STATE GARAGE MESSAGE", destination);
      websocket.publish({
        destination,
        body: JSON.stringify(payload),
      });
    } catch (sendException) {
      logger.warn(sendException);
    }
  };

  disconnectWebsocket = () => {
    try {
      if (websocket) {
        websocket.deactivate();
      }
    } catch (discoException) {
      logger.warn(discoException);
    }
  };

  render = () => {
    return false;
  };
}

export default DriverStatusWebsocket;
