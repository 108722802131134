/**
 * This component renders the base layout of the right hand side of the payments view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending, setPaymentSession } from "actions/sessionActions";
// PrimeReact Components
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
// Custom Components
import { PaymentEditLayout } from "./index";
// Static values
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, sendQuery } from "common/Helpers";
import { isDesktop } from "react-device-detect";
// Logging
const logger = initLogger("payment_edit_view");

class PaymentEditView extends React.Component {
  state = {
    paymentFetchPending: false,
    paymentError: null,
    paymentFetchError: null,
    displayedPayment: null,
  };

  componentDidMount = () => {
    // Create ref to edit view for scroll to functionality.
    this.editViewRef = React.createRef();
    const { paymentSession } = this.props;
    if (paymentSession && paymentSession.selectedPayment) {
      // Display session data.
      this.setState({
        displayedPayment: paymentSession.selectedPayment,
      });
    }
  };

  /**
   * Checks if the new payment button was clicked or a payment was selected in the payment view table.
   * If a different payment was selected, this function will fetch the selected payment's data from the backend and store if in the state's displayedPayment object.
   * If the new payment button was clicked, the state's displayedPayment object will be filled with empty values.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { selectedPayment } = this.props;
    if (selectedPayment !== prevProps.selectedPayment) {
      if (selectedPayment > 0) {
        this.setState({ paymentFetchPending: true, paymentError: null }, () =>
          this.fetchPayment(selectedPayment)
        );
      } else {
        this.setState(
          {
            displayedPayment: { paymentId: selectedPayment },
            paymentFetchPending: false,
          },
          () => this.handleScrollTo()
        );
      }
    }
  };

  handleSessionUpdate = (newPayment = null) => {
    const { paymentSession, setPaymentSession } = this.props;
    setPaymentSession({
      ...paymentSession,
      selectedPayment: { ...newPayment },
    });
  };

  handleScrollTo = () => {
    if (this.editViewRef && !isDesktop) {
      this.editViewRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  handleParentUpdate = (newId) => {
    const { setChangePending, handleParentUpdate } = this.props;
    this.setState({ selectedPayment: newId }, () => {
      setChangePending(false);
      handleParentUpdate(newId);
      this.fetchPayment();
    });
  };

  /**
   * Attempts to fetch payment data from the backend.
   *
   * @returns {Promise<PaymentData|String>} - The payment data on success, an error code else.
   */
  fetchPayment = () => {
    if (this.props.selectedPayment) {
      try {
        const { selectedPayment } = this.props;
        return sendQuery(
          `${QUERIES.GET_PAYMENT_BY_ID}${selectedPayment}`,
          "GET",
          null
        ).then(
          (response) => {
            let sessionData = { ...response };
            if (sessionData && sessionData.transactionId) {
              return sendQuery(
                `${QUERIES.GET_BILL_BY_ID}${sessionData.transactionId}`,
                "get"
              )
                .then(
                  (billResponse) => {
                    sessionData.bill = {
                      ...billResponse,
                    };
                  },
                  (error) => {
                    logger.error(
                      `Could not fetch bill data for ${sessionData.transactionId}`,
                      error
                    );
                  }
                )
                .finally(() => {
                  this.setState(
                    {
                      displayedPayment: sessionData,
                      paymentFetchPending: false,
                    },
                    () => {
                      this.handleSessionUpdate(sessionData);
                      this.handleScrollTo();
                    }
                  );
                });
            }
          },
          (error) => {
            this.setState(
              {
                displayedPayment: null,
                paymentFetchPending: false,
                paymentError: error,
              },
              () => this.handleSessionUpdate()
            );
          }
        );
      } catch (fetchException) {
        this.setState(
          {
            displayedPayment: null,
            paymentFetchPending: false,
            paymentError: fetchException.message,
          },
          () => this.handleParentUpdate(null)
        );
      }
    }
  };

  /**
   * Returns the payment edit layout component if the payment fetch request is done.
   * Returns an error message if an error occurred during the data transfer.
   * Will return a loading animation while the request is pending.
   *
   * @returns {JSX.Element}
   */
  renderPaymentInputs = () => {
    const { paymentFetchPending, paymentError, displayedPayment } = this.state;

    if (paymentFetchPending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else {
      if (paymentError) {
        return (
          <div>
            {typeof paymentError === "string"
              ? paymentError
              : paymentError.message}
          </div>
        );
      } else {
        return (
          <PaymentEditLayout
            selectedPayment={displayedPayment}
            currentUser={this.props.currentUser}
            handleParentUpdate={this.handleParentUpdate}
          />
        );
      }
    }
  };

  /**
   * Renders a basic 3 column layout for the payment data-, payments- & payments-components when a payment is selected.
   * Will return a simple card component with a "Select payment" message else.
   *
   * @returns {JSX.Element}
   */
  renderContent = () => {
    const { selectedPayment, intl, paymentSession } = this.props;
    const { PAYMENTS_SELECT_PAYMENT_LABEL } = MESSAGE_KEYS;
    let content;

    if (selectedPayment || (paymentSession?.selectedPayment)) {
      content = (
        <div key="payment_row" className="col-12">
          {this.renderPaymentInputs()}
        </div>
      );
    } else {
      content = (
        <div className="col-12">
          <Card>
            <strong>
              {intl.formatMessage({
                id: PAYMENTS_SELECT_PAYMENT_LABEL,
              })}
            </strong>
          </Card>
        </div>
      );
    }
    return content;
  };

  render = () => {
    return (
      <div className="grid" ref={this.editViewRef}>
        {this.renderContent()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      session: { paymentSession = null },
    } = state;
    return {
      paymentSession,
    };
  } catch (mapException) {
    logger.error(mapException);
    return { paymentSession: null };
  }
};

export default connect(mapStateToProps, {
  setChangePending,
  setPaymentSession,
})(injectIntl(PaymentEditView));
