import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const FuzzyInput = (props) => {
  const { FUZZY_OFF, FUZZY_ON } = MESSAGE_KEYS;
  const {
    label,
    handleInput,
    handleToggle,
    inputValue,
    toggleValue,
    intl,
    onKeyDown,
    focusRef,
    autoFocus=false
  } = props;

  const toggleButton = () => {
    if (isDesktop) {
      return (
        <ToggleButton
          className={"fuzzy_toggle-button"}
          onLabel={intl.formatMessage({ id: FUZZY_ON })}
          offLabel={intl.formatMessage({ id: FUZZY_OFF })}
          checked={toggleValue}
          onChange={(e) => handleToggle(e.value)}
        />
      );
    } else {
      return (
        <ToggleButton
          className={"fuzzy_toggle-button_responsive"}
          onIcon={"pi pi-circle-on"}
          offIcon={"pi pi-circle-off"}
          onLabel=""
          offLabel=""
          checked={toggleValue}
          onChange={(e) => handleToggle(e.value)}
        />
      );
    }
  };

  return (
    <div className="p-inputgroup">
      <span className="p-float-label">
        <InputText
          id={label}
          value={inputValue}
          onChange={(e) => handleInput(e.target.value)}
          onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }          
        }}
        ref={focusRef}
        autoFocus={autoFocus}
        />
        <label htmlFor={label}>{intl.formatMessage({ id: label })}</label>
      </span>
      {toggleButton()}
    </div>
  );
};

export default injectIntl(FuzzyInput);
