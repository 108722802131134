import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { setTransferParams } from "actions";
// PrimeReact components
import { Button } from "primereact/button";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { URLS, MESSAGE_KEYS } from "assets/staticData/enums";

const NewBillButton = (props) => {
    const { value, intl, setTransferParams } = props;

    const history = useHistory();
    return (
      <Button
        type="button"
        onClick={() => {
          setTransferParams({
            transactionId: new Date().getTime() * -1,
            customer: value,
          });
          history.push(URLS.BILLS);
        }}
        label={intl.formatMessage({
          id: MESSAGE_KEYS.BILLS_CREATE_BUTTON_LABEL,
        })}
      />
    );
  };

  export default connect(null, {setTransferParams})(injectIntl(NewBillButton));