//react
import React, { memo, useState, useRef } from "react";

//custom components
import { FloatingTextInput } from "components/common/index";

//primeng
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// Styling
import "./Style.scss";
import { MESSAGE_SEVERITY } from "assets/staticData/enums";
import { sendQuery } from "common/Helpers";

const CreateGroupContainer = ({ users, hideOverlay }) => {
  const [inputData, setInputData] = useState({
    groupName: "",
    users: [],
  });
  const toast = useRef(null);

  const validateInput = () => {
    const { groupName, users } = inputData;
    return groupName && users.length > 0;
  };

  const saveGroup = () => {
    const payload = {
      groupName: inputData.groupName,
      userIdList: getUserIdList(inputData.users),
    };

    sendQuery(`api/base/person-groups/create`, "post", payload).then(
      (response) => {
        if (response) {
          toast.current.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: "Created Group",
          });
          setTimeout(() => {
            hideOverlay();
          }, 1000);
        }
      },
      (error) => {
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: "Create not create Group " + error,
        });
      }
    );
  };

  const getUserIdList = (users) => {
    let usersIdArray = [];
    users.forEach((user) => {
      usersIdArray.push(user?.personId);
    });
    return usersIdArray;
  };

  return (
    <div className="create-group-container">
      <h3> Create a group </h3>
      <Toast ref={toast} />
      <FloatingTextInput
        id={1}
        value={inputData.groupName}
        onChange={(event) => {
          setInputData({ ...inputData, groupName: event.target.value });
        }}
        label={"Groupname"}
        className={
          inputData.groupName || inputData.groupName !== ""
            ? " "
            : "p-invalid-custom"
        }
      />
      <ListBox
        multiple
        value={inputData.users}
        onChange={(e) => setInputData({ ...inputData, users: e.value })}
        options={users}
        optionLabel="alias"
        listStyle={{ maxHeight: "250px" }}
        filter
        dataKey="personId"
        style={{ outline: inputData.users.length === 0 ? "1px solid red" : "" }}
      />
      <Button
        label="Save"
        className="p-button-success"
        onClick={() => {
          if (validateInput()) {
            saveGroup();
          } else {
            toast.current.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: "Invalid inputs",
            });
          }
        }}
      />
    </div>
  );
};

export default memo(CreateGroupContainer);
