import React, { forwardRef, useImperativeHandle, useState } from "react";

// Fullcalendar components
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// Custom components
import { EventLayout } from "./index";
// Localization
import { LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
import { useIntl } from "react-intl";
// Helper functions
import { initLogger } from "common/Helpers";
import { memo } from "react";

const logger = initLogger("AppointmentScheduler");

const AppointmentScheduler = forwardRef(
  (
    {
      currentUser,
      resources,
      slotMaxTime,
      slotMinTime,
      currentEvents,
      handleDateSelect,
      handleEventClick,
      handleEventChange,
      updateEvents,
      pendingEvents,
      handleShowHistoricalModal,
    },
    ref
  ) => {
    const intl = useIntl();
    const calendarRef = React.createRef();
    const [disableTooltip, setDisableTooltip] = useState(false);

    useImperativeHandle(ref, () => ({
      setDate(date) {
        calendarRef.current
          .getApi()
          .gotoDate(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
          );
      },
      setView(view, start) {
        calendarRef.current.getApi().changeView(view, start);
      },
    }));

    if (resources) {
      try {
        const lang = currentUser?.currentLocale
          ? currentUser.currentLocale.key
          : LOCALES.FRENCH.key;
        return (
          <FullCalendar
            locale={lang}
            height="auto"
            timeZone="UTC"
            allDaySlot={false}
            ref={calendarRef}
            plugins={[resourceTimeGridPlugin, interactionPlugin, listPlugin]}
            initialView="resourceTimeGridDay"
            headerToolbar={false}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              hour12: false,
            }}
            eventDragMinDistance={46}
            editable={pendingEvents.length === 0}
            eventResourceEditable={pendingEvents.length === 0}
            selectable
            droppable={pendingEvents.length === 0}
            selectMirror={false}
            rerenderDelay={10}
            eventStartEditable={pendingEvents.length === 0}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            resources={resources}
            slotDuration={{ minutes: 15 }}
            slotLabelInterval={{ minutes: 15 }}
            events={currentEvents}
            select={handleDateSelect}
            slotEventOverlap
            eventContent={(event) => {
              return (
                <EventLayout
                  eventData={event}
                  handleEventClick={handleEventClick}
                  disableTooltip={disableTooltip}
                  className={"event-layout-element"}
                  handleShowHistoricalModal={handleShowHistoricalModal}
                />
              );
            }}
            eventAdd={(event) => {
              logger.info("ADDED", event);
              handleEventChange(event);
            }}
            eventChange={handleEventChange}
            eventRemove={(event) => {
              logger.info("REMOVED", event);
            }}
            datesSet={(event) => {
              updateEvents(event.start);
            }}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            eventDragStart={() => {
              setDisableTooltip(true);
            }}
            eventDragStop={(event) => {
              setDisableTooltip(false);
              if (event.event.extendedProps.updatePending && event.remove) {
                event.remove();
              }
            }}
          />
        );
      } catch (renderException) {
        logger.error(renderException);
        return (
          <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>
        );
      }
    } else {
      return <></>;
    }
  }
);

export default memo(AppointmentScheduler);
