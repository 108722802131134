import React, { memo, useEffect, useRef, useState } from "react";

// Styling
import "./Style.scss";

//PrimeNG
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FloatingTextInput, LanguageSelector } from "components/common/index";
import {
  ChatBlock,
  CreateGroupContainer,
  ShoutboxEditorHeader,
  ShoutboxElement,
} from "./index";
import { RadioButton } from "primereact/radiobutton";
import { Editor } from "primereact/editor";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import Tippy from "@tippyjs/react";
// Localization
import { useIntl } from "react-intl";
// Responsive
import { isMobileOnly } from "react-device-detect";
// Helpers
import {
  dateToString,
  fetchTexts,
  getColorByMessageType,
  initLogger,
  sendQuery,
} from "common/Helpers";
import {
  LOCALES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";

const logger = initLogger("shoutbox_dialog");

const ShoutboxDialog = ({
  visible,
  hideDialog,
  header,
  type,
  targetMessage,
  actionDialog,
  user,
  users,
  groups,
  refetchGroups,
}) => {
  const op = useRef(null);
  const toast = useRef(null);
  const [inputData, setInputData] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    const languageId = targetMessage?.languageId ?? LOCALES.GERMAN.languageId;
    const texts = fetchTexts(languageId, targetMessage);

    setInputData({
      shoutboxId: targetMessage?.shoutboxId || null,
      state: targetMessage?.state || 1,
      title: texts.title || "",
      description: texts.description || "",
      sender: user || null,
      releaseDate: targetMessage?.releaseDate
        ? dateToString(targetMessage.releaseDate)
        : dateToString(new Date()),
      mediaList: targetMessage?.mediaList || [],
      assignedPerson: targetMessage?.assignedPerson || null,
      assignedGroup: targetMessage?.assignedGroup || null,
      languageId,
      translations: targetMessage?.translations || [],
    });
  }, [targetMessage, user]);

  const {
    SHOUTBOX_DELETE_CONFIRM,
    DIALOG_SAVE_BUTTON_LABEL,
    DIALOG_CANCEL_BUTTON_LABEL,
    APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL,
    CUSTOMERS_TITLE_LABEL,
    SHOUTBOX_NO_CONTENT,
    DIALOG_CONFIRM_BUTTON_LABEL,
    DIALOG_DENY_BUTTON_LABEL,
    BILLS_FILES_ERROR_UPLOAD,
    SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL,
  } = MESSAGE_KEYS;

  const generateDialogContent = () => {
    switch (type) {
      case "edit":
        return generatePreviewContent(type);
      case "add":
        return generatePreviewContent(type);
      case "delete":
        return generateDeleteContent();
      case "chat":
        return generateChatContent();
      default:
        return <p>{intl.formatMessage({ id: SHOUTBOX_NO_CONTENT })}</p>;
    }
  };

  const generateChatContent = () => {
    let chatElements = [];
    targetMessage.chatMessages.forEach((message, i) => {
      chatElements.push(
        <ChatBlock
          key={i}
          user={message?.alias}
          message={message?.messageText}
          date={message?.sendDate}
          reactions={message?.reactions}
        />
      );
    });

    return (
      <>
        <div className="chat-container">{chatElements}</div>
        <div className="send-container grid">
          <InputText className="col-11" />
          <Button className="col-1 p-button-rounded" icon="pi pi-send" />
        </div>
      </>
    );
  };

  const generateDeleteContent = () => {
    return (
      <div className="delete-content">
        <span>{intl.formatMessage({ id: SHOUTBOX_DELETE_CONFIRM })}</span>
        <div className="actions">
          <Button
            label={intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL })}
            className="p-button-danger"
            onClick={() => {
              actionDialog("delete", targetMessage.shoutboxId);
            }}
          />
          <Button
            label={intl.formatMessage({ id: DIALOG_DENY_BUTTON_LABEL })}
            onClick={() => {
              hideDialog();
            }}
          />
        </div>
      </div>
    );
  };

  const optimizeImage = (event) => {
    const MAX_HEIGHT = 1920;
    const MAX_WIDTH = 1080;
    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      logger.error(new Error("Selected file is not an image."));
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      let maxWidth, maxHeight;

      if (width > height) {
        // Landscape image
        maxWidth = MAX_HEIGHT;
        maxHeight = MAX_WIDTH;
      } else {
        // Portrait image
        maxWidth = MAX_WIDTH;
        maxHeight = MAX_HEIGHT;
      }

      // Calculate new dimensions while preserving aspect ratio
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > maxWidth) {
          width = maxWidth;
          height = width / aspectRatio;
        }

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      // Create a canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Set canvas dimensions to the new size
      canvas.width = width;
      canvas.height = height;

      // Draw the image with the new size
      context.drawImage(image, 0, 0, width, height);

      // Convert the canvas content to a Blob
      canvas.toBlob(
        (blob) => {
          handleImageUpload(blob);
        },
        file.type, // Use the original file type
        0.85 // Adjust quality as needed (0.7 is 70% quality)
      );
    };

    image.onerror = () => {
      logger.error(new Error("Failed to load the image."));
    };
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result;
      const mediaType = file.type;

      if (targetMessage?.shoutboxId) {
        uploadImage(targetMessage.shoutboxId, base64String, mediaType);
      } else {
        setInputData({
          ...inputData,
          mediaList: [
            ...inputData.mediaList,
            {
              mediaData: base64String.split(",")[1],
              mediaType: mediaType,
            },
          ],
        });
      }
    };
    reader.readAsDataURL(file);
  };

  const uploadImage = (id, base64String, mediaType) => {
    const payload = {
      mediaType: mediaType,
      mediaData: base64String.split(",")[1],
    };
    sendQuery(`api/base/shoutbox/${id}/media/add-media`, "POST", payload).then(
      () => {
        setInputData({
          ...inputData,
          mediaList: [...inputData.mediaList, payload],
        });
      },
      (error) => {
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: `${intl.formatMessage({
            id: BILLS_FILES_ERROR_UPLOAD,
          })} <br> ${error}`,
        });
      }
    );
  };

  const removeImage = (index, id) => {
    if (id) {
      sendQuery(
        `api/base/shoutbox/${targetMessage.shoutboxId}/media/${id}`,
        "DELETE"
      ).then(
        () => {},
        (error) => {
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: "Could not delete image " + error,
          });
        }
      );
    }

    const updatedMediaList = [...inputData.mediaList];
    updatedMediaList.splice(index, 1); // Remove 1 element at the specified index
    setInputData({ ...inputData, mediaList: updatedMediaList });
  };

  const validateInput = () => {
    const { state, title, description, assignedGroup, assignedPerson } =
      inputData;
    return state && title && description && (assignedGroup || assignedPerson);
  };

  const generateTooltipMembers = (members) => {
    if (members.length > 0) {
      let membersElement = [];
      members.forEach((member, i) =>
        membersElement.push(<div key={i}>{member?.user?.alias}</div>)
      );
      return <div>{membersElement}</div>;
    }
    return <p>-</p>;
  };

  const getTranslationIndexByLanguage = (languageId) => {
    return inputData.translations.findIndex(
      (transl) => transl.languageId === inputData.languageId
    );
  };

  const handleEditorChange = (event, isTitle) => {
    // This codes checks if an image was added to the text.
    /*const insertValue = delta?.ops[1]?.insert;
    if (insertValue) {
      if (insertValue.hasOwnProperty("image")) {
        let _htmlValue = htmlValue.replaceAll("<img src", "<Image preview src");
        _htmlValue = _htmlValue.replaceAll('alt="">', " />");        
        return _htmlValue;
      } else {
        return htmlValue;
      }
    } else {
      return htmlValue;
    }*/
    const field = isTitle ? "title" : "description";
    const val = isTitle ? event.target.value : event.htmlValue;
    if (type === "edit") {
      const translationIndex = getTranslationIndexByLanguage(
        inputData.languageId
      );
      if (translationIndex >= 0) {
        const _translations = [...inputData.translations];
        _translations[translationIndex] = {
          ..._translations[translationIndex],
          [field]: val,
        };
        setInputData({
          ...inputData,
          translations: _translations,
          [field]: val,
        });
      }
    } else {
      setInputData({
        ...inputData,
        [field]: val,
      });
    }
  };

  const handleLanguageSwitch = (newLanguageId) => {
    if (type === "edit") {
      const texts = fetchTexts(newLanguageId, inputData);
      if (texts) {
        setInputData({ ...inputData, languageId: newLanguageId, ...texts });
      }
    } else {
      setInputData({ ...inputData, languageId: newLanguageId });
    }
  };

  const handleGroup = (id, isActive) => {
    const handler = {
      method: isActive ? "DELETE" : "PUT",
      action: isActive ? "deactivate" : "activate",
    };
    sendQuery(
      `api/base/person-groups/${id}/${handler.action}`,
      handler.method
    ).then(
      () => {
        refetchGroups();
      },
      (error) => {
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: "Could not handle group " + error,
        });
      }
    );
  };

  const groupOptionTemplate = (option) => {
    return (
      <div className={`dropbox-group-option `}>
        <div>
          {option.groupName} {option.active ? "" : "(inactive)"}
        </div>
        <div className="actions-buttons">
          <Tippy
            content={
              option?.groupMembers
                ? generateTooltipMembers(option.groupMembers)
                : "-"
            }
          >
            <i className="pi pi-info-circle"></i>
          </Tippy>
          <Button
            className={`p-button-text p-button-rounded ${
              option?.active ? "p-button-danger" : "p-button-success"
            }`}
            icon={!option?.active ? "pi pi-check" : "pi pi-times"}
            onClick={(e) => {
              handleGroup(option.groupId, option?.active);
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    );
  };
  const generatePreviewContent = (type) => {
    return (
      <div className="add-content grid">
        <div className="col-12 severity-container">
          {/* STATE*/}
          <div className="label-radiobutton">
            <i
              className="pi pi-exclamation-triangle "
              style={{ color: getColorByMessageType(1).bgColor }}
            ></i>
            <RadioButton
              value={1}
              onChange={(e) => setInputData({ ...inputData, state: e.value })}
              checked={inputData.state === 1}
            />
          </div>
          <div className="label-radiobutton">
            <i
              className="pi pi-exclamation-circle"
              style={{ color: "orange" }}
            ></i>
            <RadioButton
              value={2}
              onChange={(e) => setInputData({ ...inputData, state: e.value })}
              checked={inputData.state === 2}
            />
          </div>
          <div className="label-radiobutton">
            <i
              className="pi pi-info-circle"
              style={{ color: getColorByMessageType(3).bgColor }}
            ></i>
            <RadioButton
              value={3}
              onChange={(e) => setInputData({ ...inputData, state: e.value })}
              checked={inputData.state === 3}
            />
          </div>
        </div>
        <div
          className={`col-12 assign-container ${
            isMobileOnly ? "flex flex-column" : ""
          }`}
        >
          {/* user - user assignment */}
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </span>
            <Dropdown
              value={inputData.assignedPerson}
              options={users}
              optionLabel="alias"
              onChange={(e) =>
                setInputData({ ...inputData, assignedPerson: e.value })
              }
              showClear={true}
              dataKey="personId"
              filter
              className={
                inputData.assignedPerson || inputData.assignedGroup
                  ? " "
                  : "p-invalid"
              }
              filterInputAutoFocus={true}
            />
          </div>
          {/* group assignment */}
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-users"></i>
            </span>
            <Dropdown
              value={inputData.assignedGroup}
              className={
                inputData.assignedPerson || inputData.assignedGroup
                  ? " "
                  : "p-invalid"
              }
              options={groups}
              optionLabel="groupName"
              filter
              onChange={(e) =>
                setInputData({ ...inputData, assignedGroup: e.value })
              }
              dataKey="groupId"
              showClear={true}
              itemTemplate={groupOptionTemplate}
              filterInputAutoFocus={true}
            />
            <Button
              icon="pi pi-plus"
              onClick={(e) => op.current.toggle(e)}
              disabled={inputData.assignedPerson}
            />
            <OverlayPanel ref={op}>
              <CreateGroupContainer
                users={users}
                hideOverlay={(e) => {
                  op.current.hide(e);
                  refetchGroups();
                }}
              />
            </OverlayPanel>
          </div>
        </div>
        {/* TITLE & LANGUAGE SELECTION */}
        <div className={`col-${isMobileOnly ? "7" : "8"} flex align-items-end`}>
          <FloatingTextInput
            id={1}
            value={inputData.title}
            onChange={(event) => {
              handleEditorChange(event, true);
            }}
            label={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
            className={
              inputData.title || inputData.title !== ""
                ? " "
                : "p-invalid-custom"
            }
          />
        </div>
        <div
          className={`col-${isMobileOnly ? "5" : "4"} flex justify-content-end`}
        >
          <LanguageSelector
            value={inputData.languageId}
            setValue={(newValue) => {
              handleLanguageSwitch(newValue);
            }}
          />
        </div>
        {/* DESCRIPTION */}
        <div className="col-12">
          <Editor
            value={inputData.description}
            onTextChange={(e) => {
              handleEditorChange(e, false);
            }}
            style={{
              height: "100px",
              outline:
                inputData.description || inputData.description !== ""
                  ? ""
                  : "1px solid red",
            }}
            headerTemplate={<ShoutboxEditorHeader />}
          />
        </div>
        {/* IMAGE UPLOAD */}
        <div className="col-12 image-upload-container">
          <label className="file-input">
            <input type="file" accept="image/*" onChange={optimizeImage} />
            <span>
              {intl.formatMessage({ id: SHOUTBOX_IMAGE_SELECT_BUTTON_LABEL })}
            </span>
          </label>
        </div>
        {/* PREVIEW */}
        <div className="col-12 preview-container">
          <h3>Preview</h3>
          <ShoutboxElement
            id={-1}
            message={inputData}
            handleDialog={null}
            isPreview={true}
            handleRemoveImage={removeImage}
            user={user}
          />
        </div>
        {/* BUTTONS */}
        <div className="col-12 actions">
          <Button
            label={intl.formatMessage({ id: DIALOG_SAVE_BUTTON_LABEL })}
            className="p-button-success"
            onClick={() => {
              if (validateInput()) {
                actionDialog(type, inputData);
              } else {
                toast.current.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: intl.formatMessage({
                    id: APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL,
                  }),
                });
              }
            }}
          />
          <Button
            label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
            className="p-button-danger"
            onClick={() => {
              hideDialog();
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <Dialog
      header={header}
      visible={visible}
      className={"shoutbox-dialog"}
      onHide={() => {
        hideDialog(false);
      }}
    >
      <Toast ref={toast} />
      <div className="content">{generateDialogContent()}</div>
    </Dialog>
  );
};

export default memo(ShoutboxDialog);
