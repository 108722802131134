import React from "react";
// Custom components
import {
  DriveList,
  TransportTicket,
  AppointmentWebsocket,
  DriverStatusWebsocket,
} from "./components";
// PrimeReact components
import { Toast } from "primereact/toast";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle, setAppointmentTypes, setCars } from "actions/index";
import { updateDay, updateAllDays } from "actions/mobileActions";
// Localization
import { injectIntl } from "react-intl";
// Static data
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Helper functions
import {
  sendQuery,
  initLogger,
  sortObjectArray,
  dateToParameter,
  equalDates,
  dateToTime,
  addressToString,
  isCurrentUserAdmin,
  generateName,
  getLocalTimeDate,
  getStatusNameById,
  dateToSessionIdentifier,
  isWithinRange,
  fetchSurrounding,
} from "common/Helpers";

const TOAST_STATE_IDS = [1, 2, 3];

const logger = initLogger("drivesview");

class DrivesView extends React.Component {
  state = {
    pending: false,
    appointments: [],
    selectedAppointment: null,
    drivers: [],
    cars: [],
    appointmentTypes: [],
    currentDate: new Date(),
    failedUploads: [],
    isOnline: window.navigator.onLine,
    isAdmin: isCurrentUserAdmin(),
    wsId: null,
    status: [],
    checkboxesList: [],
    driverStatus: null,
    driverStateId: null,
    driverStatusCar: null,
  };

  componentDidMount = () => {
    const { days, updateAllDays, setTopbarTitle, intl } = this.props;
    setTopbarTitle(intl.formatMessage({ id: MESSAGE_KEYS.MENU_DRIVES }));
    this.loadFailedUploads().then((fails) => {
      this.setState({ failedUploads: fails }, () => {
        const { sessionUser, sessionDate } = this.props;
        this.fetchAppointments(
          sessionUser?.personId,
          sessionDate ? new Date(sessionDate) : null
        );
      });
    });
    this.fetchComboData();

    window.addEventListener("offline", this.handleOnlineToggle);

    window.addEventListener("online", this.handleOnlineToggle);

    this.websocket = React.createRef();
    this.websocketState = React.createRef();
    if (days) {
      try {
        // Redux data cleanup (today & 3 days around)
        let reduxRange = fetchSurrounding(
          dateToSessionIdentifier(new Date(), false)
        );
        const _reduxData = Object.keys(days)
          .filter((key) => reduxRange.includes(key))
          .reduce((obj, key) => {
            obj[key] = days[key];
            return obj;
          }, {});

        updateAllDays(_reduxData);
      } catch (e) {
        logger.warn("Failed to clean up redux store");
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { isOnline } = this.state;
    if (!prevState.isOnline && isOnline) {
      const { sessionUser, sessionDate } = this.props;
      this.fetchAppointments(
        sessionUser?.personId,
        sessionDate ? new Date(sessionDate) : null
      );
    }
  };

  /**
   * Clear scroll locks.
   */
  componentWillUnmount = () => {
    window.removeEventListener("offline", this.handleOnlineToggle);
    window.removeEventListener("online", this.handleOnlineToggle);
  };

  handleOnlineToggle = () => {
    this.setState((prevState) => {
      return {
        isOnline: !prevState.isOnline,
      };
    });
  };

  displayUpdate = (appointment) => {
    const { currentDate } = this.state;
    let cmpDate = new Date(currentDate.getTime());
    cmpDate.setHours(0, 0, 0, 0);
    // Check if received appointment is on displayed date.
    let cmpStartTime = new Date(appointment.starttime);
    cmpStartTime.setHours(0, 0, 0, 0);
    if (equalDates(cmpStartTime, cmpDate)) {
      // Received event is on displayed date, check user.
      const { currentUser, userId } = this.props;
      if (userId !== -1 && !currentUser?.personId) {
        // No user, return false.
        return false;
      } else {
        try {
          let checkId = userId !== -1 ? userId : currentUser.personId;
          // Check if the current user is first/second driver or if the appointment is already in the list (removed as driver)
          const {
            firstDriverId,
            secondDriverId,
            firstDriverReturnId,
            secondDriverReturnId,
            firstDriver,
            secondDriver,
            firstDriverReturn,
            secondDriverReturn,
          } = appointment;
          return [
            firstDriverId,
            secondDriverId,
            firstDriverReturnId,
            secondDriverReturnId,
            firstDriver?.personId,
            secondDriver?.personId,
            firstDriverReturn?.personId,
            secondDriverReturn?.personId,
          ].includes(checkId) /*||
            appointments.find(
              (app) => app.appointmentId === appointment.appointmentId
            ) !== undefined*/;
        } catch (checkException) {
          logger.warn(checkException);
          return false;
        }
      }
    } else {
      return false;
    }
  };

  handleWSCall = (msg) => {
    try {
      let newAppointment = JSON.parse(msg.body);
      if (typeof newAppointment === "number") {
        this.handleWSRemove(newAppointment);
      } else {
        // Received event is on displayed date, add new data to event array.
        this.handleWSUpdate(newAppointment);
      }
      logger.info("Greetings", JSON.parse(msg.body));
    } catch (wsException) {
      logger.warn(wsException);
    }
  };

  concernsDriver = (
    {
      firstDriverId,
      firstDriverReturnId,
      secondDriverId,
      secondDriverReturnId,
      appointmentstate,
    },
    checkIncludeOnly = true
  ) => {
    const DRIVE_IDS = [null, 2, 3];
    const CANCELLED_ID = 6;
    const RETURN_IDS = [1, 11];
    const checkIds = [];
    if (
      [...DRIVE_IDS, CANCELLED_ID, ...RETURN_IDS].includes(appointmentstate)
    ) {
      if (checkIncludeOnly) {
        checkIds.push(
          secondDriverReturnId,
          firstDriverReturnId,
          firstDriverId,
          secondDriverId
        );
      } else if (RETURN_IDS.includes(appointmentstate)) {
        checkIds.push(secondDriverReturnId, firstDriverReturnId);
      } else {
        checkIds.push(firstDriverId, secondDriverId);
      }

      const { userId, currentUser } = this.props;
      let checkId = userId !== -1 ? userId : currentUser.personId;
      const test =
        appointmentstate !== CANCELLED_ID && checkIds.includes(checkId);
      return test;
    } else {
      return false;
    }
  };

  /**
   * Is called after updating/creating an event.
   * Sends the id of the saved event to the websocket to inform other connected clients and start the live update.
   *
   * @param {Number} id The ID of the saved event received by the backend.
   */
  /*allWebsocket = (id, callDelete = false) => {
    if (id) {
      try {
        const destination = callDelete
          ? process.env.REACT_APP_WEBSOCKET_UNPUBLISH
          : process.env.REACT_APP_WEBSOCKET_PUBLISH;
        logger.info("SENDING MESSAGE", destination);
        this.websocket.publish({
          destination,
          body: id,
        });
      } catch (sendException) {
        logger.warn(sendException);
      }
    }
  }; */

  handleWSUpdate = (appointment) => {
    if (appointment?.isNew) {
      this.websocket.current.callWebsocket(appointment.appointmentId);
      return;
    }
    const showToast =
      appointment.driverHasRead === 0 &&
      TOAST_STATE_IDS.includes(appointment.appointmentstate);
    if (this.concernsDriver(appointment, true)) {
      sendQuery(
        `${QUERIES.GET_APPOINTMENT_BY_ID}${appointment.appointmentid}`,
        "get",
        null
      ).then((response) => {
        // Check if changed appointment is on displayed date.
        if (this.displayUpdate(response)) {
          const mappedData = this.mapToListData([response])[0];
          const appointments = [...this.state.appointments];
          const searchIndex = appointments.findIndex(
            (app) => app.appointment.appointmentId === appointment.appointmentid
          );
          if (searchIndex >= 0) {
            // Handle update
            appointments[searchIndex] = mappedData;
          } else {
            // Handle add
            appointments.push(mappedData);
          }
          this.sortAppointments(appointments);
          this.setState(
            { appointments, wsId: appointment.appointmentid },
            () => {
              this.updateRedux(appointments, this.state.failedUploads);

              if (showToast) {
                this.toastWS.show({
                  detail: this.generateWSMessage(appointment),
                  severity: MESSAGE_SEVERITY.INFO,
                  summary: this.props.intl.formatMessage({
                    id: MESSAGE_KEYS.DRIVES_WS_TITLE,
                  }),
                  sticky: true,
                  id: appointment.appointmentid,
                  key: appointment.appointmentId,
                });
              }
            }
          );
        }
      });
    } else if (this.displayUpdate(appointment)) {
      this.handleWSRemove(appointment.appointmentid, showToast);
    }
  };

  handleWSRemove = (appointmentId, showToast = true) => {
    const appointments = this.state.appointments.filter(
      (app) => app.appointment.appointmentId !== appointmentId
    );
    this.setState(
      {
        appointments,
        wsId: appointmentId,
      },
      () => {
        this.updateRedux(appointments, this.state.failedUploads);
        if (!showToast) {
          this.toast.show({
            detail: this.generateWSMessage(null),
            severity: MESSAGE_SEVERITY.INFO,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.DRIVES_WS_TITLE,
            }),
            sticky: true,
          });
        }
      }
    );
  };

  setHasReadFlag = (value, id) => {
    if (id) {
      sendQuery(
        `${QUERIES.SET_APPOINTMENT_READ}${value}&appointmentId=${id}`,
        "POST"
      ).then(
        (response) => {
          const { wsId } = this.state;
          logger.info(response);
          this.websocket.current.callWebsocket(id);
          if (id === wsId) {
            this.setState({ wsId: null });
          }
        },
        (error) => {
          logger.warn(error);
        }
      );
    }
  };

  generateWSMessage = (appointment) => {
    const { intl } = this.props;
    const {
      DRIVES_WS_DELETE,
      DRIVES_WS_UPDATE,
      APPOINTMENTS_CLIENT_LABEL,
      DRIVES_TIME_START_RESPONSIVE,
      DRIVES_TIME_MEETING_RESPONSIVE,
    } = MESSAGE_KEYS;
    let fullName = "";
    if (appointment) {
      if (!appointment.customerfullname) {
        const {
          firstname,
          lastname,
          girlName,
          titleId,
          healthInsuranceNumber,
        } = appointment.customer.personId ? appointment.customer : appointment;
        fullName = generateName(
          `${firstname} ${lastname}${girlName ? `-${girlName}` : ""}${
            healthInsuranceNumber ? ` - ${healthInsuranceNumber}` : ""
          }`,
          titleId,
          this.props.intl
        );
      } else {
        fullName = `${appointment.customerfullname} `;
      }
    }
    return (
      <div>
        <div>
          {intl.formatMessage({
            id: appointment ? DRIVES_WS_UPDATE : DRIVES_WS_DELETE,
          })}
        </div>
        {appointment && (
          <ul>
            <li>
              <strong>
                {intl.formatMessage({ id: APPOINTMENTS_CLIENT_LABEL })}:{" "}
              </strong>
              {fullName}
            </li>
            <li>
              <strong>
                {intl.formatMessage({ id: DRIVES_TIME_START_RESPONSIVE })}:
              </strong>{" "}
              {dateToTime(new Date(appointment.starttime), true)}
            </li>
            {appointment.meetingTime && (
              <li>
                <strong>
                  {intl.formatMessage({ id: DRIVES_TIME_MEETING_RESPONSIVE })}:
                </strong>{" "}
                {dateToTime(new Date(appointment.meetingTime), true)}
              </li>
            )}
          </ul>
        )}
      </div>
    );
  };

  loadFailedUploads = () => {
    return new Promise((resolve) => {
      const fails =
        this.props.days?.[dateToSessionIdentifier(this.state.currentDate)]
          ?.failedUploads;
      if (fails && fails.length > 0) {
        this.setState({ failedUploads: fails });
        resolve(fails);
      } else {
        resolve([]);
      }
    });
  };

  storeFailedUpload = (data) => {
    const dateLabel = dateToSessionIdentifier(this.state.currentDate);
    const newDays = { ...this.props.days };
    let failedUploads = [];
    if (newDays[dateLabel]) {
      failedUploads = [...newDays[dateLabel].failedUploads, data];
    } else {
      failedUploads.push(data);
    }

    this.updateRedux(this.state.appointments, failedUploads);
    this.setState({ failedUploads });
  };

  fetchComboData = () => {
    const { cars } = this.props;
    sendQuery(QUERIES.GET_DRIVERS, "get").then(
      (response) => {
        if (response && typeof (response[Symbol.iterator] === "function")) {
          let drivers = [...response];
          sortObjectArray(drivers, "alias");
          this.setState({ drivers });
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
    sendQuery(QUERIES.GET_CARS, "get").then(
      (response) => {
        if (response?.content) {
          let cars = response.content.filter((car) => car.active);
          this.setState({ cars });
          this.props.setCars(cars);
        }
      },
      (error) => {
        logger.warn("Error on cars fetch", error);
        if (cars) {
          this.setState({ cars });
        }
      }
    );
    sendQuery(QUERIES.GET_APPOINTMENT_STATUS, "get").then(
      (response) => {
        if (response) {
          this.setState({
            appointmentTypes: [...response],
          });
          this.props.setAppointmentTypes(response);
        } else {
          this.setState({ appointmentTypes: this.props.appointmentTypes });
        }
      },
      (error) => {
        logger.warn("Error on status fetch", error);
        this.setState({ appointmentTypes: this.props.appointmentTypes });
      }
    );
    sendQuery(`${QUERIES.GET_CHECKLIST_BY_ID}1`, "get").then(
      (response) => {
        if (response) {
          const { checklistItems } = response;
          this.setState({ checkboxesList: checklistItems });
        }
      },
      (error) => {
        logger.warn("APPOINTMENT ERROR", error);
      }
    );
  };

  updateRedux = (appointments, failedUploads = []) => {
    const { updateDay } = this.props;
    const { currentDate } = this.state;
    // Update redux state.
    if (isWithinRange(new Date(), currentDate)) {
      updateDay(dateToSessionIdentifier(this.state.currentDate), {
        appointments,
        failedUploads,
      });
    }
  };

  updateList = (data) => {
    this.setState((prevState) => {
      let newAppointments = [...prevState.appointments];
      const updateAppointment = newAppointments.find(
        (app) => app.appointment.appointmentId === data.appointmentId
      );
      if (updateAppointment) {
        updateAppointment.appointment = { ...data };
      }
      this.updateRedux(prevState.appointments);
      return {
        appointments: newAppointments,
      };
    });
  };

  setDateParameter = (date) => {
    if (date) {
      return dateToParameter(date);
    } else {
      return dateToParameter(this.state.currentDate);
    }
  };

  fetchStatus = () => {
    const { userId } = this.props;
    sendQuery(
      `${
        QUERIES.GET_DAILY_DRIVER_STATUS
      }${getLocalTimeDate()}&personId=${userId}`,
      "get"
    ).then(
      (data) => {
        if (data[0]?.driverState) {
          if (getStatusNameById(data[0].driverState, this.props.intl) !== "") {
            this.setState({
              driverStatus: data[0].driverState,
              driverStateId: data[0].driverStateId,
              driverStatusCar: this.getCarById(data[0].vehicleId),
            });
          } else {
            logger.warn(data[0].driverState + " cannot be used as a state");
            this.setState({
              driverStatus: null,
              driverStateId: null,
              driverStatusCar: null,
            });
          }
        } else {
          logger.warn("driver has no status");
          this.setState({
            driverStatus: null,
            driverStateId: null,
            driverStatusCar: null,
          });
        }
      },
      (error) => {
        logger.error(error);
        this.setState({
          driverStatus: null,
          driverStateId: null,
          driverStatusCar: null,
        });
      }
    );
  };

  getCarById = (id) => {
    const car = this.state.cars.find((car) => car.vehicleId === id);
    return car || null;
  };

  fetchAppointments = (userId = null, date = null) => {
    this.setState({ pending: true });
    const params = this.setDateParameter(date);
    sendQuery(
      `${QUERIES.GET_DRIVE_APPOINTMENTS_UNFILTERED}${
        userId ?? this.props.currentUser.personId
      }&fromDate=${params[0]}`,
      "get",
      {}
    )
      .then(
        (response) => {
          const appointments = this.mapToListData(response);
          appointments.forEach((app) => {
            switch (app.appointment.driverHasRead) {
              case 0:
                this.setHasReadFlag(1, app.appointment.appointmentId);
              /* falls through */
              case 1:
                this.setState({ wsId: app.appointment.appointmentId });
                /* Ignore annoying toast when manually set entry */
                if (
                  app.appointment.driverEntry === null &&
                  TOAST_STATE_IDS.includes(app?.appointment?.state)
                ) {
                  this.toastWS.show({
                    detail: this.generateWSMessage(app.appointment),
                    severity: MESSAGE_SEVERITY.INFO,
                    summary: this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.DRIVES_WS_TITLE,
                    }),
                    sticky: true,
                    id: app.appointment.appointmentId,
                  });
                }
                break;
              default:
            }
          });
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                appointments,
                currentDate: date ?? prevState.currentDate,
              };
            },
            () => this.updateRedux(appointments, this.state.failedUploads)
          );
          this.fetchStatus();
        },
        (error) => {
          logger.error(error);

          let sessionIdentifier = dateToSessionIdentifier(
            this.state.currentDate
          );

          if (this.props?.days) {
            if (this.props.days.hasOwnProperty(sessionIdentifier)) {
              this.setState((prevState) => {
                const reduxDay =
                  this.props.days[
                    dateToSessionIdentifier(prevState.currentDate)
                  ];
                if (reduxDay) {
                  return {
                    appointments: reduxDay.appointments,
                    failedUploads: reduxDay.failedUploads,
                    currentDate: date ?? prevState.currentDate,
                  };
                } else {
                  return prevState;
                }
              });
            }
          }
        }
      )
      .finally(() => {
        this.setState({
          pending: false,
        });
      });
  };

  mapToListData = (appointments) => {
    let appointmentList = [];
    try {
      appointments.forEach((appointment) => {
        let customerfullname = "";
        let title = null;
        if (appointment.customer?.personId) {
          const {
            firstname,
            lastname,
            girlName,
            healthInsuranceNumber,
            titleId,
          } = appointment.customer;
          title = titleId;
          customerfullname = `${firstname} ${lastname}${
            girlName ? `-${girlName}` : ""
          }${healthInsuranceNumber ? ` - ${healthInsuranceNumber}` : ""}`;
        } else {
          const {
            firstname,
            lastname,
            girlName,
            healthInsuranceNumber,
            titleId,
          } = appointment;
          title = titleId;
          customerfullname = `${lastname}${
            girlName ? `-${girlName}` : ""
          } ${firstname} ${
            healthInsuranceNumber ? ` - ${healthInsuranceNumber}` : ""
          }`;
        }
        customerfullname = `${generateName(
          customerfullname,
          title,
          this.props.intl
        )}`;
        const rueckfahrt =
          appointment?.firstDriverReturn?.personId ||
          appointment?.secondDriverReturn?.personId
            ? true
            : false;
        const fromAddress = addressToString({
          zipCode: appointment.departureZipcode,
          countryProvince: appointment.departureCity,
          line1: appointment.departureAddress1,
          line2: appointment.departureAddress2,
          keyword: appointment.fromAddressKeyword,
        });
        const toAddress = addressToString({
          zipCode: appointment.arrivalZipcode,
          countryProvince: appointment.arrivalCity,
          line1: appointment.arrivalAddress1,
          line2: appointment.arrivalAddress2,
          keyword: appointment.toAddressKeyword,
        });
        const mappedAppointment = {
          rueckfahrt,
          starttime: appointment.starttime,
          customerfullname,
          fullFromAddress: fromAddress,
          fullToAddress: toAddress,
          meetingTime: appointment.meetingTime,
          appointment,
        };
        appointmentList.push(mappedAppointment);
      });
      this.sortAppointments(appointmentList);
      return appointmentList;
    } catch (mapException) {
      logger.error(mapException);
    }
  };

  sortAppointments = (appointmentList) => {
    appointmentList.sort((a, b) => {
      try {
        return new Date(a.starttime) - new Date(b.starttime);
      } catch (sortException) {
        return 0;
      }
    });
  };

  handleReupload = (appointmentId) => {
    const data = this.state.failedUploads.find(
      (fail) => fail.appointmentId === appointmentId
    );
    if (data) {
      this.setState({ pending: true });
      sendQuery(QUERIES.EDIT_APPOINTMENT, "POST", data).then(
        (response) => {
          logger.info("Appointment was saved", response, data);
          this.handleAppointmentFinish(data, true);
          this.setState({ pending: false });
        },
        (error) => {
          logger.warn("Could not save data", error, data);
          this.handleAppointmentFinish(data, false);
          this.setState({ pending: false });
        }
      );
    } else {
      logger.error(`Could not find failed upload with id ${appointmentId}`);
    }
  };

  handleAppointmentFinish = (uploadData, success, response = []) => {
    if (response?.appointmentId) {
      this.websocket.current.callWebsocket(response.appointmentId);
    }
    const { intl } = this.props;
    if (!success) {
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: MESSAGE_KEYS.ERROR_DATA_SAVE }),
      });
    } else {
      this.toast.show({
        severity: MESSAGE_SEVERITY.SUCCESS,
        summary: intl.formatMessage({
          id: MESSAGE_KEYS.APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
        }),
      });
    }
    this.updateList(uploadData);
    this.setState({ selectedAppointment: null });
  };

  filterStatusForNewAppointement(status) {
    return status.filter(
      (status) =>
        status.name === "outward journey" || status.name === "executed"
    );
  }

  renderContent = () => {
    const {
      pending,
      appointments,
      selectedAppointment,
      cars,
      appointmentTypes,
      failedUploads,
      drivers,
      isAdmin,
      checkboxesList,
      driverStatus,
      driverStatusCar,
    } = this.state;
    const { currentUser } = this.props;
    if (selectedAppointment) {
      return (
        <TransportTicket
          appointment={selectedAppointment}
          cars={cars}
          appointmentTypes={appointmentTypes}
          isDrive
          handleCancel={() => this.setState({ selectedAppointment: null })}
          handleAppointmentFinish={this.handleAppointmentFinish}
          storeFailedUpload={this.storeFailedUpload}
          currentUser={currentUser}
          status={this.filterStatusForNewAppointement(appointmentTypes)}
          drivers={drivers}
          handleRefresh={this.fetchAppointments}
          checkboxesList={checkboxesList}
        />
      );
    } else {
      return (
        <DriveList
          pending={pending}
          cars={cars}
          appointments={appointments}
          handleSelection={(selectedAppointment) => {
            if (selectedAppointment?.driverHasRead !== 2) {
              this.setHasReadFlag(2, selectedAppointment.appointmentId);
            }
            this.setState({ selectedAppointment });
          }}
          currentUser={currentUser}
          failedUploads={failedUploads}
          handleRefresh={this.fetchAppointments}
          drivers={drivers}
          handleReupload={this.handleReupload}
          isAdmin={isAdmin}
          appointmentFilter={this.concernsDriver}
          driverStatus={driverStatus}
          driverStatusCar={driverStatusCar}
          updateStatus={(status) => {
            this.setState({ driverStatus: status });
          }}
          handleStateWSUpdate={(statusId) => {
            this.websocketState.current.callWebsocket(statusId);
            this.setState({ driverStateId: statusId });
          }}
          handleGarageWSUpdate={(starttime) => {
            if (this.state.driverStateId) {
              this.websocketState.current.callWebsocketGarage(
                this.state.driverStateId,
                starttime
              );
            }
          }}
        />
      );
    }
  };

  render = () => {
    return (
      <div>
        <AppointmentWebsocket
          callback={this.handleWSCall}
          ref={this.websocket}
        />
        <DriverStatusWebsocket ref={this.websocketState} />
        <Toast ref={(el) => (this.toast = el)} />
        <Toast
          ref={(el) => (this.toastWS = el)}
          position="top-center"
          onShow={() => [this.setHasReadFlag(1, this.state.wsId)]}
          onRemove={(closedToast) => {
            return [this.setHasReadFlag(2, closedToast.id)];
          }}
        />
        {this.renderContent()}
      </div>
    );
  };
}
const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser = null },
      mobile: { userId = -1, days = null },
      persist: { appointmentTypes = [], cars = [] },
      session: { driveSession },
    } = state;
    return {
      currentUser,
      userId,
      days,
      appointmentTypes,
      cars,
      sessionUser: driveSession?.user ? driveSession.user : null,
      sessionDate: driveSession?.date ? driveSession.date : null,
    };
  } catch (mapException) {
    logger.error(mapException);
    return {
      currentUser: null,
      userId: -1,
      days: null,
      appointmentTypes: [],
      cars: [],
      sessionUser: null,
      sessionDate: null,
    };
  }
};

export default connect(mapStateToProps, {
  setTopbarTitle,
  updateDay,
  updateAllDays,
  setAppointmentTypes,
  setCars,
})(injectIntl(DrivesView));
