import React from "react";
// Tippy.js tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { initLogger } from "common/Helpers";
// Logging
const logger = initLogger('shift_event_layout');

const ShiftEventLayout = (props) => {
  const { value, onClick, intl } = props;
  const {
    SHIFTS_NO_MEMBERS_LABEL,
    SHIFTS_EVENT_SUMMARY_LABEL,
    SHIFTS_DUTY_LABEL,
  } = MESSAGE_KEYS;
  try {
    const {
      event: {
        _def: {
          title,
          extendedProps: { members, onCallClinic, description },
          ui: { display },
        },
      },
    } = value;

    const isHoliday = display === "background";

    const renderMembers = () => {
      const memberDisplay = [];
      if (members && members.length > 0) {
        members.forEach((member) => {
          memberDisplay.push(
            <div key={`${member.personId}_${new Date().getTime()}`}>
              {member.shortname}
            </div>
          );
        });
      } else {
        memberDisplay.push(
          <div key={"NO_MEMBER"}>
            {intl.formatMessage({ id: SHIFTS_NO_MEMBERS_LABEL })}
          </div>
        );
      }
      if (onCallClinic) {
        memberDisplay.push(
          <div key={"ON_CALL"}>
            {`${intl.formatMessage({ id: SHIFTS_DUTY_LABEL })}: ${
              onCallClinic ? onCallClinic : "-"
            }`}
          </div>
        );
      }
      return memberDisplay;
    };

    return isHoliday ? (
      <Tippy content={description}>
        <strong>{title}</strong>
      </Tippy>
    ) : (
      <div onClick={onClick}>
        <Tippy content={renderMembers()}>
          <div>
            <strong>{title}</strong>
            {!isHoliday && (
              <div>
                {intl.formatMessage(
                  { id: SHIFTS_EVENT_SUMMARY_LABEL },
                  { members: members ? members.length : 0 }
                )}
              </div>
            )}
          </div>
        </Tippy>
      </div>
    );
  } catch (renderException) {    
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
  }
};

export default injectIntl(ShiftEventLayout);
