import React from "react";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// PrimeReact components
import { Badge } from "primereact/badge";
// Helper functions
import { initLogger, dateToString } from "common/Helpers";

const logger = initLogger("payment.status.icon");

const PaymentStatusIcon = (props) => {
  const {
    value: {
      notPaid,
      lastInvoiceNumber,
      lastInvoiceDate,
      personId,
      comment,
      sylvain,
    },
    intl,
  } = props;
  const {
    BILLS_AMOUNT_REMAINING_LABEL,
    BILLS_VALIDATION_ERROR_CUSTOMER,
    ERROR_RENDER,
    APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
    APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
    BILLS_DAS_LABEL,
    CUSTOMERS_COMMENT_LABEL,
  } = MESSAGE_KEYS;

  let color = "black";
  let tooltipContent;
  let icon;  
  try {
    if (personId) {
      tooltipContent = (
        <div>
          <div className="flex flex-row justify-content-between">
            <span className="mr-2">
              {intl.formatMessage({ id: APPOINTMENTS_LAST_BILL_NUMBER_LABEL })}
            </span>
            <span>{lastInvoiceNumber ? lastInvoiceNumber : "-"}</span>
          </div>
          <div className="flex flex-row justify-content-between">
            <span className="mr-2">
              {intl.formatMessage({ id: APPOINTMENTS_LAST_PAYMENT_DATE_LABEL })}
            </span>
            <span>{lastInvoiceDate ? dateToString(lastInvoiceDate) : "-"}</span>
          </div>
          <div className="flex flex-row justify-content-between">
            <span className="mr-2">
              {intl.formatMessage({ id: BILLS_AMOUNT_REMAINING_LABEL })}
            </span>
            <span>{notPaid ? `${notPaid} €` : "-"}</span>
          </div>
          <div>
            {sylvain ? (
              <div>{intl.formatMessage({ id: BILLS_DAS_LABEL })}</div>
            ) : null}
          </div>
          <div>
            {comment ? (
              <div>{`${intl.formatMessage({
                id: CUSTOMERS_COMMENT_LABEL,
              })}: ${comment}`}</div>
            ) : null}
          </div>
        </div>
      );
      if (notPaid === 0) {
        icon = "pi-check-circle";
      } else {
        icon = "pi-times-circle";
        color = "red";
      }
    } else {
      icon = "pi-question-circle";
      tooltipContent = (
        <div>{intl.formatMessage({ id: BILLS_VALIDATION_ERROR_CUSTOMER })}</div>
      );
    }
    return (
      <div className="m-1">
        <Tippy content={tooltipContent}>
          <i
            className={`pi ${icon}  p-overlay-badge`}
            style={{ fontSize: "1.5em", color }}
          >
            {(sylvain === true || comment) && <Badge severity="danger" />}
          </i>
        </Tippy>
      </div>
    );
  } catch (renderException) {
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default injectIntl(PaymentStatusIcon);
