import React, { useState } from "react";
// PrimeReact components
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { useIntl } from "react-intl";
import { DATE_FORMAT, MESSAGE_KEYS } from "assets/staticData/enums";
import { dateToString } from "common/Helpers";

const HolidayLayout = ({ handleSave, holidays, onHide }) => {
  const intl = useIntl();
  const {
    CUSTOMERS_TITLE_LABEL,
    VACATIONS_HOLIDAY_LABEL,
    VACATION_SAVE_HOLIDAY_LABEL,
    DIALOG_CANCEL_BUTTON_LABEL,
    BILLS_POSITIONS_DESCRIPTION_LABEL,
  } = MESSAGE_KEYS;

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [description, setDescription] = useState("");

  return (
    <div className="mt-4">
      <div className="flex">
        <div>
          <FloatingTextInput
            id={CUSTOMERS_TITLE_LABEL}
            label={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <span className="p-float-label mt-4">
            <Calendar
              id="holiday_cal"
              value={startDate}
              onChange={({ value }) => setStartDate(value)}
              dateFormat={DATE_FORMAT}
              selectOtherMonths={true}
            />
            <label htmlFor="holiday_cal">
              {intl.formatMessage({
                id: VACATIONS_HOLIDAY_LABEL,
              })}
            </label>
          </span>
          <span className="p-float-label mt-2" style={{ flexGrow: 1 }}>
            <InputTextarea
              id="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={2}
              style={{ width: "100%" }}
            />
            <label htmlFor="textarea">
              {intl.formatMessage({ id: BILLS_POSITIONS_DESCRIPTION_LABEL })}
            </label>
          </span>
        </div>
        <Divider layout="vertical" />
        <div>
          <DataTable value={holidays} filterDisplay="row" paginator rows={5}>
            <Column
              field="title"
              header={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
            />
            <Column
              field="start"
              header={intl.formatMessage({ id: VACATIONS_HOLIDAY_LABEL })}
              body={({ start }) => dateToString(start, "/")}
            />
            <Column
              field="extendedProps"
              header={intl.formatMessage({
                id: BILLS_POSITIONS_DESCRIPTION_LABEL,
              })}
              body={({ extendedProps }) => extendedProps.description}
            />
          </DataTable>
        </div>
      </div>
      <div className="flex justify-content-between mt-4">
        <Button
          className="mr-2"
          label={intl.formatMessage({ id: VACATION_SAVE_HOLIDAY_LABEL })}
          onClick={() => handleSave(startDate, title, description)}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
        />
      </div>
    </div>
  );
};

export default HolidayLayout;
