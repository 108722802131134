import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { unsetTransferParams, setTopbarTitle } from "actions";
import { setChangePending, setPaymentSession } from "actions/sessionActions";
// Custom components
import { PaymentFilterLayout, PaymentEditView } from "./components";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// Helper functions
import {
  priceCellTemplate,
  initLogger,
  sendQuery,
  generateQueryParameters,
  dateCellTemplate,
  changePendingConfirm,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  PAGE_LINK_SIZES,
  QUERIES,
  MESSAGE_SEVERITY,
  ROWS_PER_PAGE,
} from "assets/staticData/enums";
// Styling
import "./Style.scss";
// Logging
const logger = initLogger("payments_view");

const COLUMN_WIDTHS = {
  DATE: 135,
  BILL_NUMBER: 130,
  PRICE: 95,
};

const EMPTY_FILTER = {
  inputFreeAddress: "",
  inputFreePayment: "",
  inputFreeCustomer: "",
  inputFreeBill: "",
  inputPaymentFromDate: null,
  inputPaymentToDate: null,

  inputBillFromDate: null,
  inputBillToDate: null,
  inputMinAmount: null,
  inputMaxAmount: null,
  inputCustomer: null,
  inputBill: null,
};

class PaymentsView extends React.Component {
  state = {
    first: 0,
    rows: isDesktop ? 10 : 5,
    rowsPerPage: ROWS_PER_PAGE,
    displayedPayments: [],
    totalPayments: 0,
    currentPage: 0,
    selectedPayment: null,
    fetchPending: false,
    fetchError: null,
    paymentFetchPending: false,
    paymentFetchError: null,
    selectedRow: null,

    queryString: "",
  };

  /**
   * Fetches customer data on component mount.
   * If the transfer id is set, load the payment corresponding to the transfer id and clear the ID from the redux store.
   */
  componentDidMount = () => {
    const {
      transferId,
      unsetTransferParams,
      setTopbarTitle,
      setChangePending,
      paymentSession,
    } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_PAYMENTS })
    );
    setChangePending(false);
    if (transferId) {
      this.handleSelectionChange({ paymentId: transferId });
      unsetTransferParams();
    }
    if (paymentSession) {
      this.setState({
        ...paymentSession,
        selectedPayment: null,
      });
    } else {
      this.handleSearch(EMPTY_FILTER);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      rows,
      displayedPayments,
      currentPage,
      selectedPayment,
      queryString,
    } = this.state;

    if (
      prevState.currentPage !== currentPage ||
      prevState.rows !== rows ||
      prevState.queryString !== queryString ||
      prevState.selectedPayment !== selectedPayment
    ) {
      logger.info("UPDATING PAYMENT SESSION", selectedPayment);
      this.handleSessionUpdate(displayedPayments);
    }
  };

  handleSessionUpdate = (displayedPayments = []) => {
    const { setPaymentSession, paymentSession } = this.props;

    const {
      first,
      rows,
      totalPayments,
      currentPage,
      queryString,
      selectedRow,
    } = this.state;
    setPaymentSession({
      ...paymentSession,
      first,
      rows,
      displayedPayments: [...displayedPayments],
      totalPayments,
      currentPage,
      queryString,
      selectedRow,
    });
  };

  handleSearch = (filter) => {
    const { rows } = this.state;
    let queryString = generateQueryParameters(filter);
    this.setState({
      queryString,
    });
    this.fetchPayments(0, rows, queryString);
  };

  fetchPayments = (page, rows, queryString) => {
    const { currentUser, intl } = this.props;
    this.setState({
      currentPage: 0,
      fetchPending: true,
      fetchError: null,
    });

    try {
      if (currentUser) {
        sendQuery(
          `${QUERIES.GET_PAYMENTS_PAGES}page=${page}&size=${rows}${
            queryString ? queryString : this.state.queryString
          }`,
          "get",
          null
        ).then(
          (response) => {
            this.setState(
              {
                displayedPayments: [...response.content],
                totalPayments: response.totalElements,
                fetchPending: false,
              },
              () => this.handleSessionUpdate([...response.content])
            );
          },
          (error) => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_FETCH,
              }),
            });
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              () => this.handleSessionUpdate()
            );
          }
        );
      }
    } catch (fetchException) {
      logger.error(fetchException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({ id: MESSAGE_KEYS.ERROR_DATA_FETCH }),
      });
      this.setState(
        {
          fetchPending: false,
          fetchError: fetchException.message,
        },
        () => this.handleSessionUpdate()
      );
    }
  };

  /**
   * Gets called when the user changes the table page or number of rows displayed.
   * This function updates the table control values of the state and calls the fetchCustomers function to update the displayed rows.
   *
   * @param {Object} event
   */
  handlePageChange = (event) => {
    const { first, rows, page } = event;
    this.setState(
      {
        first,
        rows,
        currentPage: page,
      },
      this.fetchPayments(page, rows)
    );
  };

  /**
   * Gets called when a row in the table is selected.
   * If the changePending value within the state is true, the user will be prompted to confirm the selection.
   * On confirmation, the state is updated and the data for the new selection will be fetched and displayed. No change will happen else.
   *
   * @param {Object} payment - The new selection.
   */
  handleSelectionChange = (payment) => {
    const { changePending, intl } = this.props;
    if (!changePending) {
      this.changeSelection(payment);
    } else {
      changePendingConfirm(payment, this.changeSelection, intl);
    }
  };

  changeSelection = (selection) => {
    this.setState(
      {
        selectedPayment: null,
      },
      () => {
        this.setState({
          selectedPayment: selection.paymentId,
          selectedRow: selection,
        });
        this.props.setChangePending(false);
      }
    );
  };

  renderNewPaymentButton = () => {
    const { fetchError, fetchPending } = this.props;
    return (
      <Button
        icon="pi pi-plus"
        onClick={() => {
          this.handleSelectionChange({ paymentId: new Date().getTime() * -1 });
        }}
        disabled={fetchError || fetchPending}
      />
    );
  };

  /**
   * Renders the filter component.
   *
   * @returns {JSX.Element} - The rendered filter.
   */
  renderFilter = () => {
    const { intl } = this.props;
    const { ERROR_RENDER, PAYMENTS_FILTER_TITLE_LABEL } = MESSAGE_KEYS;
    const { fetchPending } = this.state;
    try {
      return (
        <Panel
          header={intl.formatMessage({ id: PAYMENTS_FILTER_TITLE_LABEL })}
          toggleable
          collapsed={true}
        >
          <PaymentFilterLayout
            isPending={fetchPending}
            handleSearch={this.handleSearch}
          />
        </Panel>
      );
    } catch (renderException) {
      logger.error(renderException);
      return <div>{this.props.intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  generateColumns = () => {
    const { intl } = this.props;
    const { BILL_NUMBER, DATE, PRICE } = COLUMN_WIDTHS;
    const {
      PAYMENTS_AMOUNT_LABEL,
      PAYMENTS_BILL_NUMBER_LABEL,
      PAYMENTS_CUSTOMER_LABEL,
      PAYMENTS_DATE_LABEL,
    } = MESSAGE_KEYS;
    let columns = [
      <Column
        key="pmt_col_date"
        field="paymentDate"
        body={(rowData) => dateCellTemplate(rowData.paymentDate)}
        header={intl.formatMessage({
          id: PAYMENTS_DATE_LABEL,
        })}
        style={{ width: DATE }}
      />,
    ];
    if (isDesktop) {
      columns.push(
        <Column
          key="pmt_col_name"
          field="customer"
          header={intl.formatMessage({
            id: PAYMENTS_CUSTOMER_LABEL,
          })}
        />
      );
    }
    return [
      ...columns,
      <Column
        key="pmt_col_number"
        field="invoiceNumber"
        header={intl.formatMessage({
          id: PAYMENTS_BILL_NUMBER_LABEL,
        })}
        style={{ width: BILL_NUMBER, textAlign: "right" }}
      />,
      <Column
        key="pmt_col_amount"
        style={{ width: PRICE, textAlign: "right" }}
        header={intl.formatMessage({
          id: PAYMENTS_AMOUNT_LABEL,
        })}
        body={(rowData) => {
          return priceCellTemplate(rowData.amount);
        }}
      />,
    ];
  };

  /**
   * Renders the payments table & paginator with the displayed payments.
   * If no payments are displayed, the table will contain a single row with the message that no data is available.
   * If an error occurred, the error message is displayed instead.
   */
  renderTable = () => {
    const { intl } = this.props;
    try {
      const { ERROR_NO_DATA } = MESSAGE_KEYS;
      const {
        displayedPayments,
        first,
        fetchPending,
        totalPayments,
        rows,
        rowsPerPage,
        selectedRow,
      } = this.state;
      return (
        <div>
          <DataTable
            value={displayedPayments}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => this.handleSelectionChange(e.value || selectedRow)}
            emptyMessage={this.props.intl.formatMessage({
              id: ERROR_NO_DATA,
            })}
            loading={fetchPending}
            className="p-datatable-sm"
          >
            {this.generateColumns()}
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalPayments}
            rowsPerPageOptions={rowsPerPage}
            onPageChange={(e) => {
              this.handlePageChange(e);
            }}
            pageLinkSize={
              isDesktop ? PAGE_LINK_SIZES.DESKTOP : PAGE_LINK_SIZES.MOBILE
            }
          />
        </div>
      );
    } catch (renderException) {
      logger.error(renderException);
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    if (isDesktop) {
      return (
        <div className="grid ">
          <Toast ref={(el) => (this.toast = el)} />
          <div className="col-4">
            <div className="flex tool_row">
              {this.renderNewPaymentButton()}
              {this.renderFilter()}
            </div>
            {this.renderTable()}
          </div>
          <div className="col-8">
            <PaymentEditView
              selectedPayment={this.state.selectedPayment}
              currentUser={this.props.currentUser}
              handleParentUpdate={(newId) => {
                this.setState({ selectedPayment: newId }, () => {
                  this.handleSelectionChange({ paymentId: newId });
                });
                setChangePending(false);
                this.fetchPayments();
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <div className="flex tool_row">
            {this.renderNewPaymentButton()}
            {this.renderFilter()}
          </div>
          {this.renderTable()}
          <PaymentEditView
            selectedPayment={this.state.selectedPayment}
            currentUser={this.props.currentUser}
            handleParentUpdate={() => this.fetchPayments()}
          />
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      application: { routerParams = null },
      authentication: { currentUser = null },
      session: { changePending = false, paymentSession = null },
    } = state;
    return {
      transferId: routerParams,
      currentUser,
      changePending,
      paymentSession,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      transferId: null,
      currentUser: null,
      changePending: false,
      paymentSession: null,
    };
  }
};

export default connect(mapStateToProps, {
  unsetTransferParams,
  setTopbarTitle,
  setChangePending,
  setPaymentSession,
})(injectIntl(PaymentsView));
