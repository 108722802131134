import React from "react";
// Localization
import { useIntl } from "react-intl";
import { LOCALES, MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { getRandomColor } from "common/Helpers";
import { VacationActions, VacationUserSelector } from "./index";

const VacationDraggables = ({
  isDesktop,
  sickYellow,
  vacationstates,
  currentLocale,
  displayedDate,
  select,
  updateParent,
  filterUser,
  drivers,
  changeUser,
}) => {
  const intl = useIntl();
  const { VACATIONS_STATUS_LABEL } = MESSAGE_KEYS;
  const isFullHD = window.innerWidth >= 1920;

  let draggables = [];
  if (isDesktop) {
    vacationstates.forEach((vacation) => {
      const { appointmentStateId, nameDe, nameFr, color } = vacation;
      let label = currentLocale === LOCALES.GERMAN.key ? nameDe : nameFr;
      let dragger = (
        <div
          id={appointmentStateId}
          key={`dragg_${label}`}
          className={`fc-event px-4 py-1 mr-1 ${
            isDesktop ? "" : "flex-grow mb-1"
          }`}
          style={{
            backgroundColor: color ? color : getRandomColor(),
            borderRadius: "5px",
            color:
              !appointmentStateId || color === sickYellow ? "black" : "white",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          {label}
        </div>
      );
      draggables.push(dragger);
    });
  }

  return (
    <div className="mb-2">
      {isFullHD ? (
        <h4>{intl.formatMessage({ id: VACATIONS_STATUS_LABEL })}</h4>
      ) : (
        <></>
      )}
      <div
        className={`flex  justify-content-between align-items-center ${
          isFullHD ? "flex-row" : "flex-column"
        }`}
      >
        <div
          id="external-events"
          className={`flex ${isFullHD ? "align-items-center" : "flex-wrap mb-2"}`}
        >
          {draggables}
        </div>
        <VacationUserSelector
          filterUser={filterUser}
          drivers={drivers}
          changeUser={changeUser}
        />
        <VacationActions
          isDesktop={isDesktop}
          displayedDate={displayedDate}
          select={select}
          updateParent={updateParent}
        />
      </div>
    </div>
  );
};

export default VacationDraggables;
