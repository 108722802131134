/**
 * This component displays the customer input form and handles customer data updates.
 *
 * @version 1.0
 * @author [Ian Husting]
 */

/**
 * @typedef CustomerData
 * @property {Array<Any>} addressList
 * @property {String} bloodGroup
 * @property {String} comment
 * @property {number} count
 * @property {String} createDate
 * @property {Number} createuserName
 * @property {String} dateOfBirth
 * @property {String} email
 * @property {String} fax
 * @property {String} firstname
 * @property {String} girlName
 * @property {String} gsm
 * @property {String} healthInsuranceNumber
 * @property {Boolean} isDead
 * @property {Boolean} isLogin
 * @property {String} lastModifyDate
 * @property {Number} lastModifyUserName
 * @property {String} lastname
 * @property {String} passportNumber
 * @property {Number} personId
 * @property {String} phoneHome
 * @property {String} phoneWork
 * @property {String} postingDate
 * @property {Number} sexId
 * @property {Number} titleId
 * @property {Number} type
 * @property {Boolean} active
 * @property {Boolean} sylvain
 * @property {Boolean} ownWheelchair
 * @property {Boolean} elevator
 * @property {Number} stairsCount
 * @property {Boolean} stairs
 * @property {Number} weight
 * @property {Boolean} overweight
 * @property {Boolean} vaccinated
 */
import React from "react";
// Redux
import { connect } from "react-redux";
import { setTransferParams } from "actions";
import { setChangePending, setCustomerSession } from "actions/sessionActions";
// PrimeReact Components
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { ToggleButton } from "primereact/togglebutton";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
// Custom Components
import {
  AddressEditLayout,
  AddAddressDialog,
  BillsTable,
  NewBillButton,
  PrintCustomerButton,
} from "./";
import {
  FloatingTextInput,
  TranslatedCB,
  PaymentEditor,
  CheckboxText,
  SplitDateTimeInput,
  FilearchiveEditor,
} from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper classes
import {
  sendQuery,
  generateValidationError,
  initLogger,
  dateToBillNumber,
  dateToISOString,
  hasValueChanged,
  equalDates,
  valiDate,
  equalArrays,
  equalObjects,
} from "common/Helpers";
// Styling
import "./Style.scss";
// Static values
import {
  MESSAGE_KEYS,
  VALIDATION_RULES,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import {
  TITLES,
  GENDERS,
  ADDRESS_TYPE_IDS,
  ADDRESS_TYPES,
} from "assets/staticData/combodata";
import { isDesktop } from "react-device-detect";
// Logging
const logger = initLogger("customer_edit_layout");

// Static values
const VALIDATORS = {
  validFirstName: null,
  validLastName: null,
  validMatriculation: null,
};
const EMPTY_STATE = {
  isNewCustomer: true,
  inputFirstName: "",
  inputGirlName: "",
  inputLastName: "",
  inputEmail: "",
  inputMatriculation: "",
  inputComment: "",
  inputPhone: "",
  inputMobile: "",
  inputFax: "",
  inputSex: 3,
  inputTitle: 0,
  inputBirthDate: new Date(),
  inputAddresses: [],
  inputBills: [],
  filearchive: [],
  inputActive: true,

  inputDAS: false,
  inputIsOverweight: false,
  inputWeight: 0.0,
  inputHasStairs: false,
  inputNumberStairs: 0,
  inputOwnWheelchair: false,
  inputElevator: false,

  inputIsDead: false,
  inputDeathDate: null,

  inputVaccinated: false,

  selectedBill: null,
  paymentDialogVisible: false,
  paymentsFetchPending: false,

  updatePending: false,

  validators: { ...VALIDATORS },

  positionIndex: null,
  displayedPayments: [],
};

class CustomerEditLayout extends React.Component {
  state = {
    addressDialogVisible: false,
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    const { customerSession } = this.props;
    let loadSession =
      customerSession?.selectedSessionCustomer?.selectedSessionBill;
    if (loadSession) {
      const {
        selectedSessionCustomer: {
          selectedSessionBill,
          selectedSessionPayments = [],
          filearchive = [],
        },
      } = customerSession;
      this.setState({
        selectedBill: selectedSessionBill,
        displayedPayments: selectedSessionPayments,
        filearchive,
      });
    }
    this.initInputs(!loadSession);
  };
  /**
   * If the component was updated, check if the selected user was changed. If so, initialize inputs with the new user data.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { selectedCustomer, addressList, billsList } = this.props;
    if (
      (selectedCustomer &&
        !equalObjects(
          prevProps.selectedCustomer,
          selectedCustomer,
          "personId"
        )) ||
      !equalArrays(prevProps.addressList, addressList, "addressId") ||
      !equalArrays(prevProps.billsList, billsList, "transactionId")
    ) {
      this.initInputs();
    }
  };

  /**
   * Initialize the input field values based on the selected customer. If a new customer is created, initializes the input fields with the EMPTY_STATE const.
   * @param {Boolean} selectBill Set to true to fetch payments of the first bill if available, false else. Defaults to true.
   */
  initInputs = (selectBill = true) => {
    const { selectedCustomer, addressList, billsList, filearchive } =
      this.props;
    if (selectedCustomer && selectedCustomer.personId > 0) {
      const {
        firstname,
        girlName,
        lastname,
        email,
        sexId,
        healthInsuranceNumber,
        phoneHome,
        gsm,
        fax,
        comment,
        titleId,
        dateOfBirth,
        active,

        sylvain,
        ownWheelchair,
        elevator,
        stairsCount,
        stairs,
        weight,
        overweight,

        isDead,
        dateOfDeath,

        vaccinated,
      } = selectedCustomer;
      // Format date
      let inputBirthDate = null;
      if (dateOfBirth) {
        try {
          inputBirthDate = new Date(dateOfBirth);
        } catch (datemapException) {
          inputBirthDate = new Date();
          logger.error(datemapException);
        }
      } else {
        inputBirthDate = new Date();
      }
      let selectedBill = null;

      let sortedBillsList = [];
      if (billsList && billsList.length > 0) {
        selectedBill = billsList[0];
        sortedBillsList = billsList
          .filter((val) => {
            return val.active === true;
          })
          .sort((firstValue, secondValue) => {
            // Sort by state first, and if states are equal sort by date.
            if (firstValue.state === secondValue.state) {
              let firstDate = new Date(firstValue.orderDate).getTime();
              let secondDate = new Date(secondValue.orderDate).getTime();
              if (firstDate === secondDate) {
                let firstBillNumber =
                  typeof firstValue.invoiceNumber === "number"
                    ? firstValue.invoiceNumber
                    : parseInt(firstValue.invoiceNumber);
                let secondBillNumber =
                  typeof secondValue.invoiceNumber === "number"
                    ? secondValue.invoiceNumber
                    : parseInt(secondValue.invoiceNumber);
                if (firstBillNumber === secondBillNumber) {
                  return 0;
                }
                if (firstBillNumber < secondBillNumber) {
                  return 1;
                } else {
                  return -1;
                }
              }
              if (firstDate < secondDate) {
                return 1;
              } else {
                return -1;
              }
            }
            if (firstValue.state < secondValue.state) {
              return -1;
            } else {
              return 1;
            }
          });
      }

      let inputTitle = TITLES.find((title) => {
        return title.titleId === titleId;
      });
      if (!inputTitle) {
        inputTitle = TITLES[0];
      }

      this.setState(
        {
          isNewCustomer: false,
          inputFirstName: firstname ? firstname : "",
          inputGirlName: girlName ? girlName : "",
          inputLastName: lastname ? lastname : "",
          inputEmail: email ? email : "",
          inputMatriculation: healthInsuranceNumber
            ? healthInsuranceNumber
            : "",
          inputPhone: phoneHome ? phoneHome : "",
          inputMobile: gsm ? gsm : "",
          inputFax: fax ? fax : "",
          inputComment: comment ? comment : "",
          inputSex: GENDERS.find((sex) => {
            return sex.sexId === sexId;
          }),
          inputTitle: TITLES.find((title) => {
            return title.titleId === titleId;
          }),
          inputBirthDate,
          inputAddresses: [...addressList],
          inputBills: [...sortedBillsList],
          inputActive: active,
          selectedBill,

          inputDAS: sylvain,
          inputIsOverweight: overweight ? overweight : false,
          inputWeight: weight ? weight : 0.0,
          inputHasStairs: stairs ? stairs : false,
          inputNumberStairs: stairsCount ? stairsCount : 0,
          inputOwnWheelchair: ownWheelchair ? ownWheelchair : false,
          inputElevator: elevator ? elevator : false,

          inputDeathDate: dateOfDeath ? new Date(dateOfDeath) : null,
          inputIsDead: isDead === true,

          inputVaccinated: vaccinated,

          displayedPayments: [],

          filearchive,
        },
        () => {
          if (selectedBill) {
            this.handleBillSelection(selectedBill);
          }
        }
      );
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  handleSessionUpdate = (
    selectedBill = null,
    displayedPayments = null,
    filearchive = null
  ) => {
    const { customerSession, setCustomerSession } = this.props;
    let selectedSessionCustomer = {
      ...customerSession.selectedSessionCustomer,
    };
    if (selectedBill) {
      selectedSessionCustomer.selectedSessionBill = { ...selectedBill };
    }
    if (displayedPayments) {
      selectedSessionCustomer.selectedSessionPayments = [...displayedPayments];
    }
    if (filearchive) {
      selectedSessionCustomer.filearchive = [...filearchive];
    }
    setCustomerSession({
      ...customerSession,
      selectedSessionCustomer,
    });
  };

  /**
   * Gets called when the user clicks on the delete button of the address table. Will attempt to remove the respective address from the state.
   *
   * @param {Number} id
   */
  handleAddressRemove = (id) => {
    let inputAddresses = [...this.state.inputAddresses];
    let deleteIndex = inputAddresses.findIndex((address) => {
      return address.addressId === id;
    });
    if (deleteIndex >= 0) {
      inputAddresses.splice(deleteIndex, 1);
      this.setState({ inputAddresses }, () =>
        this.props.setChangePending(true)
      );
    }
  };

  clearRetrospec = (rows) => {
    return rows.map((row) => {
      delete row.synchAddress;
      return row;
    });
  };

  /**
   * Adds the address created in the add address dialog to the table.
   * A temporary random negative id will be generated for new address entries; the correct ID will be generated when sent to the backend.
   *
   * @param {Object} newRow
   */
  handleAddressAdd = (newRow) => {
    const { positionIndex, inputAddresses } = this.state;
    const {
      inputLine1,
      inputLine2,
      inputZip,
      inputCity,
      inputAddressType: { addressTypeId, name },
      inputName,
      inputRetrospec,
      preset,

      duplicateBill,
      duplicateBase,
    } = newRow;

    let addressId = preset ? preset : Date.now() * -1;

    let newAddresses = inputRetrospec
      ? this.clearRetrospec(inputAddresses)
      : [...inputAddresses];
    if (positionIndex !== null) {
      // Edited entry
      let newAddress = {
        ...inputAddresses[positionIndex],
        line1: inputLine1,
        line2: inputLine2,
        zipCode: inputZip,
        countryProvince: inputCity,
        addressTypeId,
        addressType: name,
        name: inputName,
      };
      if (inputRetrospec) {
        newAddress.synchAddress = true;
      }
      newAddresses[positionIndex] = newAddress;
    } else {
      // Generate new random ID for new entry.
      let mappedRow = {
        addressId,
        line1: inputLine1,
        line2: inputLine2,
        zipCode: inputZip,
        countryProvince: inputCity,
        addressTypeId,
        addressType: name,
        name: inputName,
      };
      if (inputRetrospec) {
        mappedRow.synchAddress = true;
      }
      newAddresses = [...this.state.inputAddresses, mappedRow];
    }
    // Replace type of base/invoice address to additional if flagged as duplicate.
    if (duplicateBill || duplicateBase) {
      const { BASE, INVOICE, ADDITIONAL } = ADDRESS_TYPE_IDS;
      let replaceType = duplicateBill ? INVOICE : BASE;
      let replaceIndex = newAddresses.findIndex((adr) => {
        return adr.addressTypeId === replaceType && adr.addressId !== addressId;
      });
      if (replaceIndex >= 0) {
        newAddresses[replaceIndex].addressTypeId = ADDITIONAL;
        newAddresses[replaceIndex].addressType = ADDRESS_TYPES[ADDITIONAL].name;
      }
    }
    this.setState(
      {
        inputAddresses: newAddresses,
        addressDialogVisible: false,
        positionIndex: null,
      },
      () => this.props.setChangePending(true)
    );
  };

  handleArchiveUpdate = (archiveList) => {
    this.setState({
      filearchive: [...archiveList],
    });
    this.handleSessionUpdate(null, null, archiveList);
  };

  handleBillSelection = (selectedBill) => {
    this.setState({
      paymentsFetchPending: true,
    });
    try {
      if (selectedBill?.transactionId) {
        sendQuery(
          `${QUERIES.GET_PAYMENTS_BY_BILL}${selectedBill.transactionId}`,
          "get"
        )
          .then(
            (response) => {
              this.setState(
                {
                  selectedBill,
                  displayedPayments: response,
                },
                () => {
                  this.handleSessionUpdate(selectedBill, response);
                }
              );
            },
            (error) => {
              logger.warn("Could not fetch bill payments", error);
              if (this.toast) {
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary:
                    typeof error === "string"
                      ? error
                      : this.props.intl.formatMessage({
                          id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                        }),
                });
                this.handleSessionUpdate(selectedBill);
              }
            }
          )
          .finally(() => {
            this.setState({
              paymentsFetchPending: false,
            });
          });
      }
    } catch (billException) {
      logger.warn("Exception on bill selection", billException);
    }
  };

  handlePaymentUpdate = (newPayments) => {
    this.setState({
      displayedPayments: [...newPayments],
    });
    this.handleSessionUpdate(this.state.selectedBill, [...newPayments]);
    this.toast.show({
      severity: MESSAGE_SEVERITY.SUCCESS,
      summary: this.props.intl.formatMessage({
        id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
      }),
    });
  };

  /**
   * Gets called when the user clicks on the delete button of the address table. Will attempt to remove the payment address from the state.
   *
   * @param {Number} id
   */
  handlePaymentRemove = (id) => {
    const { displayedPayments, selectedBill } = this.state;
    let paymentsList = [...displayedPayments];
    let deleteIndex = paymentsList.findIndex((payment) => {
      return payment.paymentId === id;
    });
    if (deleteIndex >= 0) {
      paymentsList.splice(deleteIndex, 1);

      this.setState({
        displayedPayments: [...paymentsList],
      });
      this.handleSessionUpdate(selectedBill, [...paymentsList]);
      this.toast.show({
        severity: MESSAGE_SEVERITY.SUCCESS,
        summary: this.props.intl.formatMessage({
          id: MESSAGE_KEYS.PAYMENT_DELETE_SUCCESS_MESSAGE,
        }),
      });
    }
  };

  /**
   * Will validate currently entrered values. If the validation succeeds, the data will be sent to the server.
   * If the transfer succeeds, parent component values will be updated and input fields will be re-initialized to apply IDs for new users & addresses.
   */
  handleSaveClick = () => {
    const { intl, handleParentUpdate } = this.props;
    try {
      this.setState({ updatePending: true });
      this.validateInput().then(
        (newCustomerData) => {
          this.sendData(newCustomerData).then(
            (response) => {
              if (response && response.customer && response.customer.personId) {
                this.toast.show({
                  severity: MESSAGE_SEVERITY.SUCCESS,
                  summary: intl.formatMessage({
                    id: MESSAGE_KEYS.CUSTOMERS_SAVE_SUCCESS_MESSAGE,
                  }),
                });
                this.setState({
                  isNewCustomer: false,
                  updatePending: false,
                });
                logger.info("CUSTOMER RESPONSE", response);
                handleParentUpdate(response.customer.personId);
              } else {
                logger.error("Server response empty.", response);
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: this.props.intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                  }),
                });
                this.setState({ updatePending: false });
              }
            },
            (error) => {
              logger.error(error);
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : this.props.intl.formatMessage({
                        id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                      }),
              });
              this.setState({ updatePending: false });
            }
          );
        },
        (errors) => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.WARNING,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.WARNING_VALIDATION_FAILED,
            }),
            detail: errors,
          });
          this.setState({ updatePending: false });
        }
      );
    } catch (saveException) {
      logger.warn(saveException);
      this.setState({ updatePending: false });
    }
  };

  /**
   * Checks if the entered input values are valid.
   * Generates & returns a CustomerData object with the new values if validation succeeds, an array with the failed validation messages else.
   *
   * @returns {Promise<CustomerData|Array<String>>}
   */
  validateInput = () => {
    return new Promise((resolve, reject) => {
      const { intl } = this.props;
      let errors = [];
      const {
        MATRICULATION_MIN_LENGTH,
        MATRICULATION_LUCKY_NUMBER_MIN_LENGTH,
      } = VALIDATION_RULES;
      const {
        CUSTOMERS_ERROR_MATRICULATION_LENGTH,
        CUSTOMERS_ERROR_MATRICULATION_REQUIRED,
      } = MESSAGE_KEYS;
      const { inputMatriculation } = this.state;

      try {
        /*let validEmail = inputEmail.match(/\S+@\S+\.\S+/) !== null;
        if (!validEmail) {
          errors.push(
            generateValidationError(ACCOUNT_ERROR_EMAIL_INVALID, intl)
          );
        }*/
        let luckyNumber = inputMatriculation.split("-");
        let validMatriculation =
          !inputMatriculation ||
          inputMatriculation.length !== MATRICULATION_MIN_LENGTH ||
          (luckyNumber.length !== 2 &&
            luckyNumber[1].length !== MATRICULATION_LUCKY_NUMBER_MIN_LENGTH);
        if (!inputMatriculation) {
          errors.push(
            generateValidationError(
              CUSTOMERS_ERROR_MATRICULATION_REQUIRED,
              intl
            )
          );
        } else {
          if (inputMatriculation.length !== MATRICULATION_MIN_LENGTH) {
            errors.push(
              generateValidationError(
                CUSTOMERS_ERROR_MATRICULATION_LENGTH,
                intl
              )
            );
          }
          if (
            luckyNumber.length !== 2 ||
            luckyNumber[1].length !== MATRICULATION_LUCKY_NUMBER_MIN_LENGTH
          ) {
          }
        }
        if (errors.length === 0) {
          resolve(this.mapInputToDTO());
        } else {
          this.setState({ validators: { validMatriculation } }, reject(errors));
        }
      } catch (validationException) {
        logger.warn(validationException);
        errors.push(<span>{validationException.message}</span>);
        reject(errors);
      }
    });
  };

  mapInputToDTO = () => {
    const {
      inputEmail,
      inputMatriculation,
      inputGirlName,
      inputComment,
      inputFirstName,
      inputLastName,
      inputFax,
      inputMobile,
      inputPhone,
      inputTitle,
      inputSex,
      inputBirthDate,
      inputAddresses,
      inputBills,
      inputActive,

      inputDAS,
      inputElevator,
      inputHasStairs,
      inputIsOverweight,
      inputNumberStairs,
      inputOwnWheelchair,
      inputWeight,

      inputDeathDate,
      inputIsDead,
      inputVaccinated,
    } = this.state;
    // Remove temporary frontend IDs from newly generated addresses, add name if missing.
    let addressList = inputAddresses.map((address) => {
      if (address.addressId < 0) {
        address.addressId = null;
      }
      if (!address.name) {
        address.name = `${inputFirstName ? `${inputFirstName} ` : ""} ${
          inputLastName ? inputLastName : ""
        }`;
      }
      return address;
    });
    // Remove temporary frontend IDs from newly generated bills.
    let invoiceList = inputBills.map((bill) => {
      if (bill.transactionId < 0) {
        bill.transactionId = null;
      }
      return bill;
    });
    const { selectedCustomer } = this.props;
    /** @type {CustomerData} */
    let customer = {
      ...selectedCustomer,
      firstname: inputFirstName,
      girlName: inputGirlName,
      lastname: inputLastName,
      email: inputEmail,
      healthInsuranceNumber: inputMatriculation,
      phoneHome: inputPhone,
      gsm: inputMobile,
      fax: inputFax,
      comment: inputComment,
      titleId: inputTitle ? inputTitle.titleId : 0,
      sexId: inputSex ? inputSex.sexId : 2,
      dateOfBirth: inputBirthDate ? dateToISOString(inputBirthDate) : null,
      personId:
        selectedCustomer.personId && selectedCustomer.personId > 0
          ? selectedCustomer.personId
          : null,
      active: inputActive,

      sylvain: inputDAS,
      ownWheelchair: inputOwnWheelchair,
      elevator: inputElevator,
      stairsCount: inputNumberStairs,
      stairs: inputHasStairs,
      weight: inputWeight,
      overweight: inputIsOverweight,

      dateOfDeath: inputDeathDate ? dateToISOString(inputDeathDate) : null,
      isDead: inputIsDead === true,

      vaccinated: inputVaccinated,
    };
    return { customer, addressList, invoiceList };
  };

  /**
   * Sends the modified data to the server.
   * @param {CustomerData} data
   * @returns {Promise<Object|String>}
   */
  sendData = (data) => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.EDIT_CUSTOMER, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
        // TODO Handle offline action
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  checkChangePending = (newKeyVal) => {
    let pending = false;
    const {
      selectedCustomer: {
        firstname,
        girlName,
        lastname,
        email,
        sexId,
        healthInsuranceNumber,
        phoneHome,
        gsm,
        fax,
        comment,
        titleId,
        active,

        sylvain,
        ownWheelchair,
        elevator,
        stairsCount,
        stairs,
        weight,
        overweight,

        dateOfDeath,
        isDead,
      },
      setChangePending,
    } = this.props;
    try {
      let customer = { ...this.state, ...newKeyVal };
      const {
        inputEmail,
        inputMatriculation,
        inputComment,
        inputFirstName,
        inputGirlName,
        inputLastName,
        inputFax,
        inputMobile,
        inputPhone,
        inputTitle,
        inputSex,
        inputActive,

        inputDAS,
        inputElevator,
        inputHasStairs,
        inputIsOverweight,
        inputNumberStairs,
        inputOwnWheelchair,
        inputWeight,

        inputIsDead,
        inputDeathDate,
      } = customer;

      pending =
        hasValueChanged(titleId, inputTitle ? inputTitle.titleId : 0) ||
        hasValueChanged(firstname, inputFirstName) ||
        hasValueChanged(girlName, inputGirlName) ||
        hasValueChanged(lastname, inputLastName) ||
        hasValueChanged(sexId, inputSex ? inputSex.sexId : null) ||
        hasValueChanged(healthInsuranceNumber, inputMatriculation) ||
        hasValueChanged(email, inputEmail) ||
        hasValueChanged(phoneHome, inputPhone) ||
        hasValueChanged(gsm, inputMobile) ||
        hasValueChanged(fax, inputFax) ||
        hasValueChanged(comment, inputComment) ||
        hasValueChanged(active, inputActive) ||
        hasValueChanged(sylvain, inputDAS) ||
        hasValueChanged(elevator, inputElevator) ||
        hasValueChanged(stairs, inputHasStairs) ||
        hasValueChanged(overweight, inputIsOverweight) ||
        hasValueChanged(stairsCount, inputNumberStairs) ||
        hasValueChanged(weight, inputWeight) ||
        hasValueChanged(ownWheelchair, inputOwnWheelchair) ||
        hasValueChanged(isDead, inputIsDead) ||
        !equalDates(inputDeathDate, dateOfDeath);
    } catch (checkException) {
      logger.warn("Exception on check change pending");
      pending = true;
    } finally {
      setChangePending(pending);
    }
  };

  /**
   * Renders the bottom row of the form.
   * Returns a loading animation if a data transfer is pending, returns a row containing the save- & reset-button else.
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      CUSTOMERS_SAVE_BUTTON_LABEL,
      CUSTOMERS_CREATE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { pending, intl } = this.props;
    const { updatePending } = this.state;

    let saveButtonLabel = "";
    if (this.state.isNewCustomer) {
      saveButtonLabel = intl.formatMessage({
        id: CUSTOMERS_CREATE_BUTTON_LABEL,
      });
    } else {
      saveButtonLabel = intl.formatMessage({
        id: CUSTOMERS_SAVE_BUTTON_LABEL,
      });
    }
    return (
      <div className="account_button_row">
        <Button
          onClick={() => this.handleSaveClick()}
          label={saveButtonLabel}
          icon={pending || updatePending ? "pi pi-spin pi-spinner" : ""}
          disabled={pending || updatePending}
        />
        <ToggleButton
          checked={this.state.inputActive}
          offIcon="pi pi-times"
          offLabel={intl.formatMessage({ id: INACTIVE })}
          onIcon={"pi pi-check"}
          onLabel={intl.formatMessage({ id: ACTIVE })}
          onChange={(e) =>
            this.setState({ inputActive: e.value }, () =>
              this.checkChangePending({ inputActive: e.value })
            )
          }
          disabled={pending || updatePending}
        />
        <Button
          onClick={() => this.initInputs(false)}
          label={intl.formatMessage({
            id: RESET_VALUES,
          })}
          className="p-button-warning"
          disabled={pending || updatePending}
        />
      </div>
    );
  };

  /**
   * Renders the customer form.
   * Will return a loading animation if the customer fetch data transfer is pending, will generate the form inputs else.
   * This function calls the renderInputs- & renderbuttonRow-functions.
   */
  renderCustomerForm = () => {
    const { pending, error, intl } = this.props;
    const { inputAddresses } = this.state;

    if (pending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else {
      if (error) {
        return <div>{error}</div>;
      } else {
        return (
          <div>
            <div className="p-fluid formgrid grid">
              {this.renderEditInputs()}
              <div className={"p-field col-12"}>
                <Panel
                  header={intl.formatMessage({
                    id: MESSAGE_KEYS.CUSTOMERS_ADDRESS_TITLE_LABEL,
                  })}
                  className="col-12"
                >
                  <AddressEditLayout
                    addresses={inputAddresses}
                    handleDelete={this.handleAddressRemove}
                    handleAdd={() => {
                      this.setState({
                        addressDialogVisible: true,
                        positionIndex: null,
                      });
                    }}
                    handleEdit={(positionIndex) => {
                      this.setState({
                        addressDialogVisible: true,
                        positionIndex,
                      });
                    }}
                  />
                </Panel>
              </div>
            </div>
            {this.renderButtonRow()}
          </div>
        );
      }
    }
  };

  /**
   * Renders two panels containing the bills- & respectivly payments-tables.
   *
   * @returns {JSX.Element}
   */
  renderBillingTables = () => {
    const { intl, selectedCustomer } = this.props;
    const { displayedPayments, filearchive } = this.state;
    const {
      CUSTOMER_PAYMENT_TITLE_LABEL,
      CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL,
      CUSTOMER_BILL_TITLE_LABEL,
      BILLS_FILES_TITLE,
    } = MESSAGE_KEYS;
    const {
      selectedBill,
      inputBills,
      customerFetchPending,
      paymentsFetchPending,
    } = this.state;

    let paymentsTitle;
    if (selectedBill) {
      const { invoiceNumber, orderDate } = selectedBill;
      paymentsTitle = intl.formatMessage(
        { id: CUSTOMER_PAYMENT_TITLE_SELECTION_LABEL },
        {
          billNumber: `${invoiceNumber ? invoiceNumber : ""}/${
            orderDate ? dateToBillNumber(orderDate) : "-"
          }`,
        }
      );
    } else {
      paymentsTitle = intl.formatMessage({ id: CUSTOMER_PAYMENT_TITLE_LABEL });
    }

    let totalBillAmount =
      selectedBill && selectedBill.totalPrice ? selectedBill.totalPrice : 0;

    return (
      <div>
        <Panel header={intl.formatMessage({ id: CUSTOMER_BILL_TITLE_LABEL })}>
          <NewBillButton value={selectedCustomer} />
          <BillsTable
            value={inputBills}
            fetchPending={customerFetchPending}
            handleSelectionChange={(selection) => {
              this.handleBillSelection(selection);
            }}
            selection={selectedBill}
            totalAmount={totalBillAmount}
          />
        </Panel>

        <Panel header={paymentsTitle}>
          <PaymentEditor
            invoiceList={displayedPayments}
            fetchPending={paymentsFetchPending}
            handleParentDelete={this.handlePaymentRemove}
            handleParentUpdate={this.handlePaymentUpdate}
            disabled={
              !selectedBill ||
              selectedBill < 0 ||
              !selectedCustomer ||
              !selectedCustomer.personId
            }
            selectedBill={selectedBill ? selectedBill.transactionId : null}
            selectedCustomer={
              selectedCustomer ? selectedCustomer.personId : null
            }
            totalAmount={totalBillAmount}
          />
        </Panel>
        <Panel header={intl.formatMessage({ id: BILLS_FILES_TITLE })}>
          <FilearchiveEditor
            value={{ ...selectedCustomer, filearchive }}
            idLabel="personId"
            updateParent={this.handleArchiveUpdate}
          />
        </Panel>
      </div>
    );
  };

  renderEditInputs = () => {
    try {
      const { intl, selectedCustomer } = this.props;
      const {
        CUSTOMERS_FIRST_NAME_LABEL,
        CUSTOMERS_MIDDLE_NAME_LABEL,
        CUSTOMERS_LAST_NAME_LABEL,
        CUSTOMERS_EMAIL_LABEL,
        CUSTOMERS_COMMENT_LABEL,
        CUSTOMERS_PHONE_LABEL,
        CUSTOMERS_MOBILE_LABEL,
        CUSTOMERS_FAX_LABEL,
        CUSTOMERS_MATRICULATION_LABEL,
        CUSTOMERS_GENDER_LABEL,
        CUSTOMERS_TITLE_LABEL,
        CUSTOMERS_DEAD_LABEL,
        CUSTOMERS_BIRTH_DATE_LABEL,

        BILLS_DAS_LABEL,
        BILLS_IS_OVERWEIGHT_LABEL,
        BILLS_WEIGHT_LABEL,
        BILLS_HAS_STAIRS_LABEL,
        BILLS_NUMBER_STAIRS_LABEL,
        BILLS_WHEELCHAIR_LABEL,
        BILLS_ELEVATOR_LABEL,

        APPOINTMENTS_VACCINATED_LABEL,
      } = MESSAGE_KEYS;
      const {
        inputFirstName,
        inputGirlName,
        inputLastName,
        inputEmail,
        inputMatriculation,
        inputComment,
        inputPhone,
        inputMobile,
        inputFax,
        inputSex,
        inputTitle,
        inputBirthDate,

        inputDAS,
        inputIsOverweight,
        inputWeight,
        inputHasStairs,
        inputNumberStairs,
        inputOwnWheelchair,
        inputElevator,

        inputDeathDate,
        inputIsDead,

        inputVaccinated,

        validators: { validFirstName, validLastName, validMatriculation },
      } = this.state;

      return [
        <div
          className={`combobox-wrapper p-field col-${isDesktop ? "3" : "6"}`}
          key={CUSTOMERS_TITLE_LABEL}
        >
          <TranslatedCB
            value={inputTitle}
            options={TITLES}
            onChange={(selection) => {
              this.setState({ inputTitle: selection }, () =>
                this.checkChangePending({ inputTitle: selection })
              );
            }}
            placeholder={intl.formatMessage({ id: CUSTOMERS_TITLE_LABEL })}
          />
        </div>,

        <div
          className={`p-field col-${isDesktop ? "3" : "6"}`}
          key={CUSTOMERS_FIRST_NAME_LABEL}
        >
          <FloatingTextInput
            value={inputFirstName}
            label={intl.formatMessage({
              id: CUSTOMERS_FIRST_NAME_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputFirstName: e.target.value }, () =>
                this.checkChangePending({ inputFirstName: e.target.value })
              );
            }}
            valid={validFirstName}
          />
        </div>,

        <div
          className={`p-field col-${isDesktop ? "3" : "6"}`}
          key={CUSTOMERS_MIDDLE_NAME_LABEL}
        >
          <FloatingTextInput
            value={inputGirlName}
            label={intl.formatMessage({
              id: CUSTOMERS_MIDDLE_NAME_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputGirlName: e.target.value }, () =>
                this.checkChangePending({ inputGirlName: e.target.value })
              );
            }}
          />
        </div>,

        <div
          className={`p-field col-${isDesktop ? "3" : "6"}`}
          key={CUSTOMERS_LAST_NAME_LABEL}
        >
          <FloatingTextInput
            value={inputLastName}
            label={intl.formatMessage({
              id: CUSTOMERS_LAST_NAME_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputLastName: e.target.value }, () =>
                this.checkChangePending({ inputLastName: e.target.value })
              );
            }}
            valid={validLastName}
          />
        </div>,

        <div
          className={"p-field combobox-wrapper col-4"}
          key={CUSTOMERS_GENDER_LABEL}
        >
          <TranslatedCB
            value={inputSex}
            options={GENDERS}
            onChange={(selection) => {
              this.setState({ inputSex: selection }, () =>
                this.checkChangePending({ inputSex: selection })
              );
            }}
            placeholder={intl.formatMessage({ id: CUSTOMERS_GENDER_LABEL })}
          />
        </div>,

        <div
          className="p-field col-4 flex align-items-end"
          key={CUSTOMERS_MATRICULATION_LABEL}
        >
          <span className="p-float-label">
            <InputMask
              value={inputMatriculation}
              mask="99999999-*****"
              onChange={(e) => {
                this.setState({ inputMatriculation: e.target.value }, () =>
                  this.checkChangePending({
                    inputMatriculation: e.target.value,
                  })
                );
              }}
              className={
                validMatriculation || validMatriculation === null
                  ? ""
                  : "p-invalid"
              }
              id={CUSTOMERS_MATRICULATION_LABEL}
              autoClear={false}
            />
            <label htmlFor={CUSTOMERS_MATRICULATION_LABEL}>
              {intl.formatMessage({ id: CUSTOMERS_MATRICULATION_LABEL })}
            </label>
          </span>
        </div>,

        <div
          className="p-field col-4 flex align-items-end"
          key={CUSTOMERS_BIRTH_DATE_LABEL}
        >
          <SplitDateTimeInput
            value={inputBirthDate}
            onChange={(e) => {
              this.setState(
                {
                  inputBirthDate: valiDate(e) ? new Date(e) : null,
                },
                this.checkChangePending({ inputBirthDate: e })
              );
            }}
            label={intl.formatMessage({ id: CUSTOMERS_BIRTH_DATE_LABEL })}
          />
        </div>,

        <div
          className={"p-field col-4 flex flex-column"}
          key={CUSTOMERS_DEAD_LABEL}
        >
          <div>{intl.formatMessage({ id: CUSTOMERS_DEAD_LABEL })}</div>
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <Checkbox
                checked={inputIsDead}
                onChange={(e) =>
                  this.setState({
                    inputIsDead: e.checked,
                  })
                }
              />
            </span>
            <SplitDateTimeInput
              value={inputDeathDate}
              onChange={(e) => {
                this.setState(
                  {
                    inputDeathDate: valiDate(e) ? new Date(e) : null,
                    inputIsDead: e ? true : inputIsDead,
                  },
                  this.checkChangePending({ inputDeathDate: e })
                );
              }}
              iconRight={true}
              showTime={false}
              label={""}
            />
          </div>
        </div>,

        <div
          className={"p-field col-5 flex align-items-end"}
          key={CUSTOMERS_EMAIL_LABEL}
        >
          <FloatingTextInput
            value={inputEmail}
            label={intl.formatMessage({
              id: CUSTOMERS_EMAIL_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputEmail: e.target.value }, () =>
                this.checkChangePending({ inputEmail: e.target.value })
              );
            }}
          />
        </div>,

        <div className={"p-field col-3 flex align-items-end"} key="btn_prt_cst">
          <PrintCustomerButton
            customerId={
              selectedCustomer && selectedCustomer.personId
                ? selectedCustomer.personId
                : null
            }
            className="mr-1"
          />
        </div>,

        <div
          className={`flex align-items-end p-field col-${
            isDesktop ? "4" : "6"
          }`}
          key={CUSTOMERS_PHONE_LABEL}
        >
          <FloatingTextInput
            value={inputPhone}
            label={intl.formatMessage({
              id: CUSTOMERS_PHONE_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputPhone: e.target.value }, () =>
                this.checkChangePending({ inputPhone: e.target.value })
              );
            }}
          />
        </div>,

        <div
          className={`flex align-items-end p-field col-${
            isDesktop ? "4" : "6"
          }`}
          key={CUSTOMERS_FAX_LABEL}
        >
          <FloatingTextInput
            value={inputFax}
            label={intl.formatMessage({
              id: CUSTOMERS_FAX_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputFax: e.target.value }, () =>
                this.checkChangePending({ inputFax: e.target.value })
              );
            }}
          />
        </div>,

        <div
          className={`flex align-items-end p-field col-${
            isDesktop ? "4" : "12"
          }`}
          key={CUSTOMERS_MOBILE_LABEL}
        >
          <FloatingTextInput
            value={inputMobile}
            label={intl.formatMessage({
              id: CUSTOMERS_MOBILE_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputMobile: e.target.value }, () =>
                this.checkChangePending({ inputMobile: e.target.value })
              );
            }}
          />
        </div>,

        <div className={"p-field col-12 mt-3"} key={CUSTOMERS_COMMENT_LABEL}>
          <span className="p-float-label">
            <InputTextarea
              id={CUSTOMERS_COMMENT_LABEL}
              value={inputComment}
              onChange={(e) =>
                this.setState({ inputComment: e.target.value }, () =>
                  this.checkChangePending({ inputComment: e.target.value })
                )
              }
              rows={5}
              cols={30}
              className="p-inputtext p-inputtextarea p-component"
            />
            <label htmlFor={CUSTOMERS_COMMENT_LABEL}>
              {intl.formatMessage({ id: CUSTOMERS_COMMENT_LABEL })}
            </label>
          </span>
        </div>,

        <div
          className={`p-field col-${isDesktop ? "4" : "6"}`}
          key={BILLS_IS_OVERWEIGHT_LABEL}
        >
          <CheckboxText
            id="cbt_overweight"
            value={inputWeight}
            checked={inputIsOverweight}
            onChange={(value) => {
              this.setState({ inputWeight: value }, () =>
                this.checkChangePending("inputWeight", value)
              );
            }}
            onToggle={(toggle) => {
              this.setState({ inputIsOverweight: toggle }, () =>
                this.checkChangePending("inputIsOverweight", toggle)
              );
            }}
            label={intl.formatMessage({ id: BILLS_IS_OVERWEIGHT_LABEL })}
            placeholder={intl.formatMessage({ id: BILLS_WEIGHT_LABEL })}
            suffix="kg"
            isFloat
          />
        </div>,

        <div
          className={`p-field col-${isDesktop ? "4" : "6"}`}
          key={BILLS_HAS_STAIRS_LABEL}
        >
          <CheckboxText
            id="cbt_stairs"
            value={inputNumberStairs}
            checked={inputHasStairs}
            onChange={(value) => {
              this.setState({ inputNumberStairs: value }, () =>
                this.checkChangePending("inputNumberStairs", value)
              );
            }}
            onToggle={(toggle) => {
              this.setState({ inputHasStairs: toggle }, () =>
                this.checkChangePending("inputHasStairs", toggle)
              );
            }}
            label={intl.formatMessage({ id: BILLS_HAS_STAIRS_LABEL })}
            placeholder={intl.formatMessage({
              id: BILLS_NUMBER_STAIRS_LABEL,
            })}
          />
        </div>,
        <div
          className={`p-field col-${
            isDesktop ? "4" : "6"
          } flex flex-column justify-content-end`}
          key={BILLS_WHEELCHAIR_LABEL}
        >
          <div className="flex align-items-center">
            <Checkbox
              id="cb_wheelchair"
              className="mr-2"
              checked={inputOwnWheelchair}
              onChange={(e) => {
                this.setState({ inputOwnWheelchair: e.checked }, () => {
                  this.checkChangePending("inputOwnWheelchair", e.checked);
                });
              }}
            />
            <span>{intl.formatMessage({ id: BILLS_WHEELCHAIR_LABEL })}</span>
          </div>

          <div className="flex align-items-center">
            <Checkbox
              id="cb_elevator"
              className="mr-2"
              checked={inputElevator}
              onChange={(e) => {
                this.setState({ inputElevator: e.checked }, () => {
                  this.checkChangePending("inputElevator", e.checked);
                });
              }}
            />
            <span>{intl.formatMessage({ id: BILLS_ELEVATOR_LABEL })}</span>
          </div>
        </div>,
        <div
          className="p-field col-12 flex flex-column justify-content-end  my-2"
          key={BILLS_DAS_LABEL}
        >
          <div className="flex align-items-center">
            <div className="flex align-items-center mr-2">
              <Checkbox
                id="cb_das"
                className="mr-2"
                checked={inputDAS}
                onChange={(e) => {
                  this.setState({ inputDAS: e.checked }, () => {
                    this.checkChangePending("inputDAS", e.checked);
                  });
                }}
              />
              <span>{intl.formatMessage({ id: BILLS_DAS_LABEL })}</span>
            </div>
            <div className="flex align-items-center">
              <Checkbox
                inputId="cb_vaccinated"
                className="mr-2"
                checked={inputVaccinated}
                onChange={(e) => {
                  this.setState({ inputVaccinated: e.checked });
                }}
              />
              <span className="text-nowrap">
                {intl.formatMessage({ id: APPOINTMENTS_VACCINATED_LABEL })}
              </span>
            </div>
          </div>
        </div>,
      ];
    } catch (renderException) {
      logger.warn("Exception on render edit inputs", renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  renderContent = () => {
    if (!isDesktop) {
      return (
        <div>
          {this.renderCustomerForm()}
          {this.renderBillingTables()}
        </div>
      );
    } else {
      return (
        <div className="grid">
          <div className="col-7">{this.renderCustomerForm()}</div>
          <div className="col-5">{this.renderBillingTables()}</div>
        </div>
      );
    }
  };

  render = () => {
    const {
      addressDialogVisible,
      inputFirstName,
      inputLastName,
      positionIndex,
      inputAddresses,
    } = this.state;

    let basicSet = inputAddresses.findIndex((adr) => {
      return adr.addressTypeId === ADDRESS_TYPE_IDS.BASE;
    });
    let billSet = inputAddresses.findIndex((adr) => {
      return adr.addressTypeId === ADDRESS_TYPE_IDS.INVOICE;
    });

    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <AddAddressDialog
          basicSet={basicSet >= 0}
          billSet={billSet >= 0}
          handleAdd={this.handleAddressAdd}
          onHide={() => this.setState({ addressDialogVisible: false })}
          visible={addressDialogVisible}
          defaultName={
            inputFirstName || inputLastName
              ? `${inputFirstName} ${inputLastName}`
              : null
          }
          value={
            positionIndex !== null &&
            positionIndex !== undefined &&
            positionIndex >= 0
              ? inputAddresses[positionIndex]
              : { addressId: null }
          }
        />
        {this.renderContent()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      session: { customerSession = null },
    } = state;
    return {
      customerSession,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      customerSession: null,
    };
  }
};

export default connect(mapStateToProps, {
  setChangePending,
  setCustomerSession,
  setTransferParams,
})(injectIntl(CustomerEditLayout));
