import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
// Custom components
import { FloatingTextInput } from "components/common";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// Helper functions
import { sendQuery, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Styling
import "./Style.scss";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";

const logger = initLogger("settings_view");

const EMPTY_STATE = {
  updatePending: false,
  account: {
    inputAccountNumber: "",
    configId: null,
    valid: null,
    targetPayment: "",
  },
};

class SettingsView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    const { setTopbarTitle } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_SETTINGS })
    );

    sendQuery(QUERIES.GET_SETTINGS, "get").then(
      (response) => {
        logger.info(response);
        this.initInputs(response);
      },
      (error) => {
        logger.warn(error);
        this.initInputs(null);
      }
    );
  };

  initInputs = (configData) => {
    if (configData) {
      this.setState({
        account: {
          inputAccountNumber: configData.accountNumber,
          configId: configData.configId,
          valid: null,
          targetPayment: configData.targetPayment,
        },
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  validateInputs = () => {
    return new Promise((resolve, reject) => {
      const { intl } = this.props;
      const { SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED, ERROR } =
        MESSAGE_KEYS;
      let errors = [];
      try {
        const {
          account: { inputAccountNumber, configId },
        } = this.state;
        let accountValid =
          configId && inputAccountNumber && inputAccountNumber.length > 0;
        this.setState({
          account: { ...this.state.account, valid: accountValid },
        });
        if (!accountValid) {
          errors.push({
            severity: MESSAGE_SEVERITY.WARNING,
            summary: intl.formatMessage({
              id: SETTINGS_VALIDATION_ACCOUNT_NUMBER_REQUIRED,
            }),
          });
        }

        if (errors.length === 0) {
          resolve();
        } else {
          reject(errors);
        }
      } catch (validateException) {
        logger.warn(validateException);
        reject([
          {
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({ id: ERROR }),
          },
        ]);
      }
    });
  };

  mapInputsToDTO = () => {
    try {
      const { account } = this.state;
      return {
        accountNumber: account.inputAccountNumber,
        targetPayment: account.targetPayment,
        configId: account.configId,
      };
    } catch (mapException) {
      logger.warn(mapException);
      return null;
    }
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    this.validateInputs().then(
      () => {
        this.setState({ updatePending: true });
        let data = this.mapInputsToDTO();
        if (data) {
          sendQuery(QUERIES.EDIT_SETTINGS, "post", data).then(
            (response) => {
              logger.info("SETTINGS SAVED", response);
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: intl.formatMessage({
                  id: MESSAGE_KEYS.SETTINGS_UPDATE_SUCCESS_MESSAGE,
                }),
              });
              this.setState({ updatePending: false }); //Banque : BCEE LU90 0019 1955 7338 5000 BIC:BCEELULL/CCRA LU89 0099 7802 0023 9085
            },
            (error) => {
              logger.warn(error);
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary: intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                }),
              });
              this.setState({ updatePending: false });
            }
          );
        }
      },
      (errors) => {
        this.toast.show(errors);
        this.setState({ updatePending: false });
      }
    );
  };

  render = () => {
    const { intl } = this.props;
    const {
      SETTINGS_ACCOUNT_NUMBER_LABEL,
      SETTINGS_SAVE_BUTTON_LABEL,
      SETTINGS_ACCOUNT_BILL_TEXT_LABEL,
    } = MESSAGE_KEYS;
    const { account, updatePending } = this.state;
    return (
      <div className="grid">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="col-6">
          <div>
            <FloatingTextInput
              value={account.inputAccountNumber}
              onChange={(e) => {
                let newAccountData = {
                  ...account,
                  inputAccountNumber: e.target.value,
                };
                this.setState({ account: { ...newAccountData } });
              }}
              label={intl.formatMessage({ id: SETTINGS_ACCOUNT_NUMBER_LABEL })}
              disabled={updatePending}
              valid={account.valid}
            />
          </div>
          <div className="mt-4">
            <span
              className="p-float-label comment_text_area"
              style={{ width: "100%" }}
            >
              <InputTextarea
                id={SETTINGS_ACCOUNT_BILL_TEXT_LABEL}                
                value={account.targetPayment}
                onChange={(e) => {
                  let newAccountData = {
                    ...account,
                    targetPayment: e.target.value,
                  };
                  this.setState({ account: { ...newAccountData } });
                }}
                rows={5}
                className="p-inputtext p-inputtextarea p-component fullwidth"
              />
              <label htmlFor={SETTINGS_ACCOUNT_BILL_TEXT_LABEL}>
                {intl.formatMessage({ id: SETTINGS_ACCOUNT_BILL_TEXT_LABEL })}
              </label>
            </span>
          </div>
          <div className="mt-4">
            <Button
              label={intl.formatMessage({ id: SETTINGS_SAVE_BUTTON_LABEL })}
              onClick={this.handleSaveClick}
              disabled={updatePending}
              icon={updatePending ? "pi pi-spin pi-spinner" : ""}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default connect(null, { setTopbarTitle })(injectIntl(SettingsView));
