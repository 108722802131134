import React from "react";
import { Badge } from "primereact/badge";
import { dateToQueryString, getStatusColor } from "common/Helpers";

// Localization
import { useIntl } from "react-intl";
import { getStatusNameById } from "common/Helpers";

// Styling
import "./Style.scss";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const DriverStatusTable = ({ driversStatus }) => {
  const intl = useIntl();

  const {
    DRIVER_STATUS_SORT_NO_RESULT,
    DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES,
  } = MESSAGE_KEYS;
  const getHoursFromDate = (date) => {
    if (date) {
      return date.split("T")[1].split(".")[0].slice(0, 5);
    } else {
      return "-";
    }
  };

  const generateElementTooltip = (histlist) => {
    let outputList = [];
    let totalShow = histlist.length < 20 ? histlist.length - 1 : 19; //show maximum 20 elements per tooltip
    for (let i = 0; i <= totalShow; i++) {
      outputList.push(
        <div className="tooltip-line" key={i}>
          <i
            className="pi pi-info-circle"
            style={{ color: getStatusColor(histlist[i].driverState).bgColor }}
          />
          {`${getHoursFromDate(histlist[i]?.lastModifyDate)}: ${
            histlist[i]?.driverState
          } (${getStatusNameById(histlist[i]?.driverState, intl)}) - ${
            histlist[i]?.licencePlate ? histlist[i].licencePlate : "?"
          }`}
        </div>
      );
    }
    return <div>{outputList}</div>;
  };

  const reverseDateString = (dateString) => {
    let parts = dateString.split(" ");

    if (parts.length === 2) {
      let datePart = parts[0].split(".");
      if (datePart.length === 3) {
        // Swap day and year
        let temp = datePart[0];
        datePart[0] = datePart[2];
        datePart[2] = temp;

        return datePart.join(".") + " " + parts[1];
      } else {
        console.error("Invalid date string format");
        return dateString; // Return original string in case of an error
      }
    } else {
      console.error("Invalid date string format");
      return dateString; // Return original string in case of an error
    }
  };

  const generateStatusElement = (status, index, colors) => {
    return (
      <div
        className="driver_status_element"
        key={index}
        id={`driver_status_element-${index}`}
        style={{ backgroundColor: colors.bgColor }}
      >
        <Tippy
          content={
            status?.histList?.length > 0
              ? generateElementTooltip(status.histList)
              : intl.formatMessage({
                  id: DRIVER_STATUS_SORT_NO_HISTORICAL_VALUES,
                })
          }
        >
          <div className="element-body">
            <div className="left_right_flex">
              <p style={{ color: colors.fColor }} className="driver_name">
                {status?.driverName}{" "}
                {status?.winfleetState ? `(${status.winfleetState})` : ""}
              </p>
              <Badge
                value={`${status?.driverState}`}
                className="histcount status"
              ></Badge>
            </div>
            {/* LICENSE PLATE  */}
            <p style={{ color: colors.fColor }} className="licence_plate">
              <i
                className="pi pi-car"
                style={{ fontSize: "9pt", marginRight: "5px" }}
              ></i>
              {status?.licencePlate ? status.licencePlate : "-"}
            </p>
            {/* WINFLEET DATE & POSITION*/}
            <div className="winfleet">
              <p
                style={{
                  color: colors.fColor,
                  fontSize: "9pt",
                }}
              >
                <i
                  className="pi pi-calendar"
                  style={{ fontSize: "9pt", marginRight: "5px" }}
                ></i>
                {status?.winfleetDate
                  ? reverseDateString(
                      dateToQueryString(
                        new Date(status.winfleetDate),
                        true,
                        "."
                      )
                    )
                  : "-"}
              </p>
              <p style={{ color: colors.fColor, fontSize: "9pt" }}>
                <i
                  className="pi pi-map-marker"
                  style={{ fontSize: "9pt", marginRight: "5px" }}
                ></i>
                {status?.winfleetPosition ? status.winfleetPosition : "-"}
              </p>
            </div>
            {/* Uhrzeit Garage START END  */}
            <div className="left_right_flex bold-text">
              <p style={{ color: colors.fColor }}>
                <i
                  className="pi pi-clock"
                  style={{
                    fontSize: "12px",
                    marginRight: "5px",
                  }}
                ></i>
                {getHoursFromDate(status?.lastModifyDate)}
                <i
                  className="pi pi-home"
                  style={{
                    fontSize: "12px",
                    marginRight: "5px",
                    marginLeft: "10px",
                  }}
                ></i>
                {status?.starttime ? status.starttime : "-"}
              </p>
              {status.histList?.length ? (
                <Badge
                  value={`+${status.histList.length}`}
                  className="histcount"
                ></Badge>
              ) : (
                ""
              )}
            </div>
          </div>
        </Tippy>
      </div>
    );
  };

  return (
    <>
      {driversStatus && driversStatus.length > 0 ? (
        driversStatus.map((status, index) =>
          generateStatusElement(
            status,
            index,
            getStatusColor(status?.driverState)
          )
        )
      ) : (
        <div>
          {intl.formatMessage({
            id: DRIVER_STATUS_SORT_NO_RESULT,
          })}
        </div>
      )}
    </>
  );
};

export default DriverStatusTable;
