import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// Signature component
import SignatureCanvas from "react-signature-canvas";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Responsive
import { isMobileOnly } from "react-device-detect";

const TicketSignature = ({
  isVisible,
  handleConfirm,
  handleClose,
  pending,
}) => {
  let sigPad = {};

  const intl = useIntl();

  const {
    DRIVES_SIGNATURE_HEADER,
    DIALOG_CANCEL_BUTTON_LABEL,
    DRIVES_SIGNATURE_CLEAR,
    DRIVES_SIGNATURE_SAVE,
  } = MESSAGE_KEYS;

  const footer = (
    <div className="flex justify-content-between">
      <Button
        label={
          isMobileOnly
            ? null
            : intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })
        }
        onClick={handleClose}
        disabled={pending}
        icon="pi pi-times"
        className="p-button-danger"
        style={{ width: isMobileOnly ? "50px" : "initial" }}
      />

      <Button
        label={
          isMobileOnly
            ? null
            : intl.formatMessage({ id: DRIVES_SIGNATURE_CLEAR })
        }
        onClick={() => {
          if (sigPad) {
            sigPad.clear();
          }
        }}
        disabled={pending}
        icon="pi pi-undo"
        style={{ width: isMobileOnly ? "50px" : "initial" }}
      />
      <Button
        label={
          isMobileOnly
            ? null
            : intl.formatMessage({ id: DRIVES_SIGNATURE_SAVE })
        }
        onClick={() => {
          if (sigPad) {
            handleConfirm(sigPad.getTrimmedCanvas().toDataURL("image/png"));
          }
        }}
        icon={`pi ${pending ? "pi-spin pi-spinner" : "pi-check"}`}
        disabled={pending}
        style={{ width: isMobileOnly ? "50px" : "initial" }}
      />
    </div>
  );

  return (
    <Dialog
      header={intl.formatMessage({ id: DRIVES_SIGNATURE_HEADER })}
      visible={isVisible}
      onHide={handleClose}
      footer={footer}
    >
      <div
        style={{ height: "100%" }}
        className="flex align-items-center justify-content-center p-3"
      >
        <SignatureCanvas
          canvasProps={{ className: "canvas_box ticket_signature" }}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
      </div>
    </Dialog>
  );
};

export default TicketSignature;
