import React from "react";
// PrimeReact components
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";

const CheckboxText = (props) => {
  const {
    id,
    value,
    checked,
    onChange,
    onToggle,
    label,
    placeholder,
    suffix,
    isFloat,
    className = "",
    disabled = false,
  } = props;
  return (
    <div className={`mt-2 ${className}`}>
      <div className="font-light mb-1">{label}</div>
      <div id={id} className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <Checkbox
            checked={checked}
            onChange={(toggleEvent) => {
              onToggle(toggleEvent.checked);
            }}
            disabled={disabled}
          />
        </span>
        <InputNumber
          placeholder={placeholder}
          onChange={(inputEvent) => {
            inputEvent?.value ? onChange(inputEvent.value) : onChange(0);
          }}
          suffix={suffix ?? ""}
          minFractionDigits={isFloat ? 2 : 0}
          maxFractionDigits={isFloat ? 2 : 0}
          value={value}
          disabled={disabled}
          onFocus={(e) => e.target.select()}
          locale="en-US"
        />
      </div>
    </div>
  );
};

export default CheckboxText;
