import React, { useState } from "react";
// Styling
import "./Style.scss";
import { EventLayout } from "components/SchedulerView/components/index";
const AppointmentElement = ({
  editSelection,
  handleShowHistoricalModal,
  handleEventClick,
  preparedEvent,
  index,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleEditSelection = (id, index) => {
    setIsSelected(!isSelected);
    editSelection(id, index, !isSelected);
  };
  return (
    <div
      key={index}
      className={`appointment-element ${
        preparedEvent.backgroundColor === "#B11919" ? "bright_manually" : ""
      }`}
      style={{
        backgroundColor: preparedEvent.backgroundColor,
        color: preparedEvent.textColor,
        outline: isSelected ? "3px solid #0069B5" : "",
      }}
    >
      <EventLayout
        eventData={preparedEvent}
        handleEventClick={handleEventClick}
        disableTooltip={false}
        showAdditionalAttr={true}
        handleShowHistoricalModal={handleShowHistoricalModal}
        addSelection={handleEditSelection}
      />
    </div>
  );
};

export default AppointmentElement;
