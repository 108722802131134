import {
  SESSION_SET_CHANGE_PENDING,
  SESSION_SET_ADDRESS_SESSION,
  SESSION_SET_PAYMENT_SESSION,
  SESSION_SET_BILL_SESSION,
  SESSION_SET_CUSTOMER_SESSION,
  SESSION_SET_APPOINTMENT_SESSION,
  SESSION_SET_DRIVES_SESSION,
  SESSION_CLEAR_SESSION_DATA,
} from "actions/types";

const INITIAL_STATE = {
  changePending: false,
  addressSession: null,
  paymentSession: null,
  customerSession: null,
  billSession: null,
  driveSession: null,
  appointmentSession: {},
};

const sessionReducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case SESSION_SET_CHANGE_PENDING:
      return { ...state, changePending: payload };
    case SESSION_SET_ADDRESS_SESSION:
      return { ...state, addressSession: { ...payload } };
    case SESSION_SET_PAYMENT_SESSION:
      return { ...state, paymentSession: { ...payload } };
    case SESSION_SET_CUSTOMER_SESSION:
      return { ...state, customerSession: { ...payload } };
    case SESSION_SET_BILL_SESSION:
      return { ...state, billSession: { ...payload } };
    case SESSION_SET_APPOINTMENT_SESSION:
      return { ...state, appointmentSession: { ...payload } };
    case SESSION_CLEAR_SESSION_DATA:
      return { ...INITIAL_STATE, appointmentSession: state.appointmentSession };
    case SESSION_SET_DRIVES_SESSION:
      return { ...state, driveSession: { ...payload } };
    default:
      return state;
  }
};

export default sessionReducer;
