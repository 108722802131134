import React, { useState } from "react";
// React redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// PrimeReact components
import { Divider } from "primereact/divider";
// Custom components
import {
  TicketCheckboxes,
  TicketDetails,
  TicketTimes,
  TicketTransport,
} from "components/DrivesView/components/TicketViews/index";
// Static data
import { MESSAGE_KEYS, STATION_NAMES } from "assets/staticData/enums";
// Localization
import { useIntl } from "react-intl";

const INIT_STATE = {
  inputStatus: null,
  inputVehicleType: null,
  inputVehicle: null,
  inputStartDate: null,
  inputFirstDriver: null,
  inputSecondDriver: null,
  inputRendezVous: "",
  inputReturn: "",
  inputRegion: STATION_NAMES.LUX,
  inputComment: "",
  inputShowComment: false,
  inputLine: 0,
  inputDateMask: "",

  inputNextAppointment: null,
  inputHasMulti: false,
  inputChecklist: [],

  inputKilometer: 0,

  validStartDate: null,
  validStatus: null,
  validVehicleType: null,

  transportTypesMapped: [],
};

const AppointmentDriveRedux = ({
  appointment,
  appointmentTypes,
  value,
  drivers,
  status,
}) => {
  const intl = useIntl();
  const {
    APPOINTMENT_STATUS_OUTWARD,
    APPOINTMENT_STATUS_RETURN,
    DRIVES_INFO_TITLE,
  } = MESSAGE_KEYS;
  

  const [inputData, setInputData] = useState({ ...INIT_STATE });

  return (
    <div>
      <TicketDetails
        driveType={appointment?.state}
        car={appointment?.car}
        firstDriver={appointment?.firstDriver}
        secondDriver={appointment?.secondDriver}
        appointmentTypes={appointmentTypes}
      />
      <div className="grid">
        <div className="col-2 ticket_vertical">
          <TicketTransport inputData={inputData} updateParent={setInputData} />
        </div>
        <div className="col-3 ticket_vertical">
          <TicketTimes
            title={intl.formatMessage({ id: APPOINTMENT_STATUS_OUTWARD })}
            inputData={inputData}
            isDeparture={true}
            updateParent={setInputData}
          />
        </div>
        <div className="col-3 ticket_vertical">
          <TicketTimes
            title={intl.formatMessage({ id: APPOINTMENT_STATUS_RETURN })}
            inputData={inputData}
            isDeparture={false}
            updateParent={setInputData}
          />
        </div>
        <div className="col-4 flex align-items-end ticket_vertical">
          <TicketCheckboxes
            checklist={appointment?.appointmentChecklistItems}
            groups={["transport"]}
          />
          <TicketCheckboxes
            checklist={appointment?.appointmentChecklistItems}
            groups={["transport2"]}
          />
        </div>
      </div>
      <div className="ticket_column_title">
        {intl.formatMessage({ id: DRIVES_INFO_TITLE })}
      </div>
      <Divider />
      <div className="ticket_vertical">
        <TicketCheckboxes
          checklist={appointment?.appointmentChecklistItems}
          groups={["info", "material", "desinfektion"]}
          numberColumns={4}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      appWizard: { appointmentDrive },
    } = state;
    return {
      currentUser,
      value: appointmentDrive,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      value: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  AppointmentDriveRedux
);
