import {
  PERSIST_SET_ADDRESS_TYPES,
  PERSIST_SET_APPOINTMENT_TYPES,
  PERSIST_SET_ON_CALLS_TYPES,
  PERSIST_SET_PERSON_TITLES,
  PERSIST_SET_SEXES,
  PERSIST_SET_SHIFTS_TYPES,
  PERSIST_SET_VACATION_STATES,
  PERSIST_SET_CARS,
} from "actions/types";

const INITIAL_STATE = {
    addressTypes: [],
    appointmentTypes: [],
    onCallTypes: [],
    titles: [],
    sexes: [],
    shiftTypes: [],
    vacationStates: [],
    cars: [],
}

const  persistReducer = (state = {...INITIAL_STATE}, action) => {
    const { payload, type } = action;
    switch(type) {
        case PERSIST_SET_ADDRESS_TYPES: {
            return {...state, addressTypes: [...payload]}
        }
        case PERSIST_SET_APPOINTMENT_TYPES: {
            return {...state, appointmentTypes: [...payload]}
        }
        case PERSIST_SET_ON_CALLS_TYPES: {
            return {...state, onCallTypes: [...payload]}
        }
        case PERSIST_SET_PERSON_TITLES: {
            return {...state, titles: [...payload]}
        }
        case PERSIST_SET_SEXES: {
            return {...state, sexes: [...payload]}
        }
        case PERSIST_SET_SHIFTS_TYPES: {
            return {...state, shiftTypes: [...payload]}
        }
        case PERSIST_SET_VACATION_STATES: {
            return {...state, vacationStates: [...payload]}
        }
        case PERSIST_SET_CARS: {
            return {...state, cars: [...payload]}
        }
        default: return state;
    }
}

export default persistReducer;