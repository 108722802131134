import React from "react";
// Custom components
import ChecklistItems from "components/SchedulerView/components/DialogViews/DriveComponents/ChecklistItems";

const TicketCheckboxes = ({ checklist, groups, numberColumns = 1, disabled }) => {  

  return (
    <div className="ticket_checklist">
    <ChecklistItems      
      groups={groups}
      numberColumns={numberColumns}
      allItems={checklist}
      end={false}
      disabled={disabled}
    /></div>
  );
};

export default TicketCheckboxes;
