import React from "react";

// Styling
import "./Style.scss";

//primeng
import { Avatar } from "primereact/avatar";
import { dateToString } from "common/Helpers";

const ChatBlock = ({ user, message, date }) => {
  return (
    <div className="chat-block grid">
      <Avatar
        label={user ? `${user[0]}${user[1]}${user[2]}` : "?"}
        size="large"
        shape="circle"
        className="col-1"
      />
      <div className="message col-10">
        {message ? message : "oops. message is broken."}
      </div>
      <div className="date col-1">{date ? dateToString(date) : "?"}</div>
    </div>
  );
};

export default ChatBlock;
