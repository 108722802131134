import React from "react";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Logging
import { initLogger } from "common/Helpers";
const logger = initLogger('reaction_button');

const ReactionButton = ({ reaction, handleReactionDelete }) => {
  const { firstId, icon, includesMe, count, aliases, shoutboxId } = reaction;
  const baseId = `reaction_base_${firstId}`;
  const intl = useIntl();
  const { ACCOUNT_ERROR_NAME_REQUIRED, ERROR_RENDER } = MESSAGE_KEYS;

  const generateGroupToolTip = (aliases) => {
    try {
      return (
        <div>
          {aliases.map((alias) => {
            const name =
              alias !== ""
                ? alias
                : intl.formatMessage({ id: ACCOUNT_ERROR_NAME_REQUIRED });
            return <div key={`${name}_${firstId}`}>{name}</div>;
          })}
        </div>
      );
    } catch (e) {
      logger.error(e);
      return (
        <div>
          {intl.formatMessage({
            id: ERROR_RENDER,
          })}
        </div>
      );
    }
  };

  return (
    <Tippy content={generateGroupToolTip(aliases)}>
      <div>
        <Avatar
          id={baseId}
          className="p-overlay-badge  reaction_avatar tooltip"
          label={icon}
          key={firstId}
          style={{
            backgroundColor: includesMe ? "#2196f345" : "transparent",
            cursor: includesMe ? "pointer" : "default",
          }}
          onClick={() => {
            if (includesMe) {
              handleReactionDelete(firstId, shoutboxId);
            }
          }}
        >
          <Badge value={count} />
        </Avatar>
      </div>
    </Tippy>
  );
};

export default ReactionButton;
