/**
 * Creates the content of the tooltip.
 * - If the data fetch is still pending, it returns a loading animation.
 * - If an error occurred during data fetch or rendering, it will display the error message.
 * - If none above is true, it will return the layout of the tooltip with the respective data.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
import TooltipCustomer from "./TooltipViews/TooltipCustomer";
import TooltipIcons from "./TooltipViews/TooltipIcons";
import TooltipEdit from "./TooltipViews/TooltipEdit";
import {
  TooltipAddress,
  TooltipBill,
  TooltipDrive,
} from "./TooltipViews/index";
import { initLogger } from "common/Helpers";
// Logging
const logger = initLogger("appointment_tooltip");

const AppointmentTooltip = (props) => {
  const { intl } = props;

  const {
    ERROR_RENDER,
    APPOINTMENTS_STATUS_LABEL,
    APPOINTMENTS_END_ADDRESS_LABEL,
    APPOINTMENTS_START_ADDRESS_LABEL,

    APPOINTMENT_STATUS_OUTWARD,

    APPOINTMENTS_RETURN_HEADER,
  } = MESSAGE_KEYS;
  try {
    const {
      tooltipContent: {
        customerfullname,
        fromaddress,
        toaddress,
        firstDriverName,
        secondDriverName,
        starttime,

        roomTo,
        departmentTo,
        roomFrm,
        departmentFrm,

        oxygen,
        dsa,
        corona,
        infusion,

        remark,

        createUserName,
        createDate,
        lastmodifyDate,
        lastmodifyUserName,

        isolated,

        lastInvoiceDate,
        lastInvoiceNumber,
        notPaid,

        fullFromAddress,
        fullToAddress,
        titleId,
        statusText,

        customerId,
        returnAppointment,
        oxyliter,

        firstCarLicencePlate,
        secondCarLicencePlate,

        toAddressTxt,
        fromAddressTxt,

        bus,
        vaccinated,
        stayByPatient,

        healthInsuranceNumber,

        phone1,
        phone2,

        firstDriverReturnName,
        secondDriverReturnName,

        wheelchair,
        elevator,

        weight,
        overweighted,
        syringepump,
        disinfectionTxt,
        disinfection,
        stairCount,
        hasReturn,

        driverHasRead,
        nextAppointment,

        showReturnIcon,
      },
    } = props;
    const showDeparture =
      firstDriverName || secondDriverName || firstCarLicencePlate;

    let toText;
    if (toaddress || roomTo || departmentTo) {
      let toKeyWord = toaddress && !fullToAddress;
      toText = `${toKeyWord ? toaddress : ""}${
        toKeyWord && roomTo ? " - " : ""
      }${roomTo ?? ""}${roomTo && departmentTo ? " - " : ""}${
        departmentTo ?? ""
      }`;
    } else {
      toText = intl.formatMessage({ id: MESSAGE_KEYS.ERROR_NO_DATA });
    }

    let fromText;
    if (fromaddress || roomFrm || departmentFrm) {
      let fromKeyWord = fromaddress && !fullFromAddress;
      fromText = `${fromKeyWord ? fromaddress : ""}${
        fromKeyWord && roomFrm ? " - " : ""
      }${roomFrm ?? ""}${roomFrm && departmentFrm ? " - " : ""}${
        departmentFrm ?? ""
      }`;
    } else {
      fromText = intl.formatMessage({ id: MESSAGE_KEYS.ERROR_NO_DATA });
    }

    return (
      <div className="flex">
        <div>
          <div className="flex">
            <strong className="mr-1">
              {intl.formatMessage({ id: APPOINTMENTS_STATUS_LABEL })}
            </strong>
            {`${statusText}`}
          </div>

          <Divider />

          <TooltipAddress
            title={APPOINTMENTS_START_ADDRESS_LABEL}
            text={fromText}
            fullAddress={fullFromAddress}
            addressTxt={fromAddressTxt}
          />
          <TooltipAddress
            title={APPOINTMENTS_END_ADDRESS_LABEL}
            text={toText}
            fullAddress={fullToAddress}
            addressTxt={toAddressTxt}
            starttime={starttime}
          />
          {showDeparture && (
            <TooltipDrive
              title={APPOINTMENT_STATUS_OUTWARD}
              firstCarLicencePlate={firstCarLicencePlate}
              firstDriverName={firstDriverName}
              secondDriverName={secondDriverName}
            />
          )}

          {showReturnIcon && (
            <TooltipDrive
              title={APPOINTMENTS_RETURN_HEADER}
              firstCarLicencePlate={secondCarLicencePlate}
              firstDriverName={firstDriverReturnName}
              secondDriverName={secondDriverReturnName}
              starttime={returnAppointment}
            />
          )}

          <TooltipBill
            lastInvoiceDate={lastInvoiceDate}
            lastInvoiceNumber={lastInvoiceNumber}
            notPaid={notPaid}
          />
        </div>
        <Divider layout="vertical" />
        <div>
          <TooltipCustomer
            customerId={customerId}
            customerfullname={customerfullname}
            titleId={titleId}
            phone1={phone1}
            phone2={phone2}
            healthInsuranceNumber={healthInsuranceNumber}
          />
          <TooltipIcons
            stayByPatient={stayByPatient}
            vaccinated={vaccinated}
            bus={bus}
            elevator={elevator}
            wheelchair={wheelchair}
            dsa={dsa}
            roundtrip={hasReturn}
            oxygen={oxygen}
            oxyliter={oxyliter}
            corona={corona}
            remark={remark}
            infusion={infusion}
            isolated={isolated}
            weight={weight}
            overweighted={overweighted}
            syringepump={syringepump}
            disinfectionTxt={disinfectionTxt}
            disinfection={disinfection}
            driverHasRead={driverHasRead}
            nextAppointment={nextAppointment}
            stairCount={stairCount}
          />
          <TooltipEdit
            lastmodifyUserName={lastmodifyUserName}
            lastmodifyDate={lastmodifyDate}
            createUserName={createUserName}
            createDate={createDate}
          />
        </div>
      </div>
    );
  } catch (renderException) {
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default injectIntl(AppointmentTooltip);
