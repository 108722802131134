import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

import "./Style.scss";

const TicketDriveInfo = ({
  firstDriver,
  secondDriver,
  isReturn,
  carList,
  updateParent,
  inputData,
  bColor,
  disabled,
  isNew,
  drivers,
  userId,
}) => {
  const {
    APPOINTMENTS_FIRST_DRIVER_LABEL,
    APPOINTMENTS_SECOND_DRIVER_LABEL,
    APPOINTMENTS_VEHICLE_LABEL,
    APPOINTMENTS_START_DATE_LABEL,
    DRIVES_TABLE_RETURN,
    DRIVES_KILOMETERS,
  } = MESSAGE_KEYS;
  const intl = useIntl();

  const generateNameText = (driver) => {
    if (driver) {
      const { firstname = "", lastname = "" } = driver;
      if (firstname || lastname) {
        return `${firstname} ${lastname}`;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  let titleKey = DRIVES_TABLE_RETURN;
  let carKey = "carIdReturn";
  let kilometerKey = "kilometerReturn";

  if (!isReturn) {
    titleKey = APPOINTMENTS_START_DATE_LABEL;
    carKey = "car";
    kilometerKey = "kilometer";
  }

  const car = inputData[carKey];

  let displayedCars = [];
  if (carList?.length > 0) {
    displayedCars = [...carList];
  } else if (car) {
    displayedCars = [car];
  }

  const kilometer = inputData[kilometerKey];

  let bColorCar =
    bColor === "3px solid red" && !inputData[carKey] ? bColor : "";
  let bColorKm =
    bColor === "3px solid red" &&
    (!inputData[kilometerKey] || inputData[kilometerKey] === "")
      ? bColor
      : "";

  const getDriverNameById = (id) => {
    if (drivers && drivers.length > 0) {
      return drivers.filter((driver) => driver.personId === id)[0]?.alias;
    } else {
      return "-";
    }
  };

  return (
    <div>
      <div className="ticket_column_title">
        {intl.formatMessage({ id: titleKey })}
      </div>
      <Divider />
      <div className="grid">
        <div className="col-6">
          <strong>
            {intl.formatMessage({ id: APPOINTMENTS_VEHICLE_LABEL })}
          </strong>
          <div>
            <Dropdown
              value={car}
              options={displayedCars}
              onChange={(e) =>
                updateParent({ ...inputData, [carKey]: e.value })
              }
              optionLabel="license_plate"
              filterBy="license_plate"
              filter
              required={true}
              style={{ border: bColorCar, width: "100%" }}
              disabled={disabled}
              filterInputAutoFocus={true}
            />
          </div>
        </div>
        <div className="col-6">
          <strong>{intl.formatMessage({ id: DRIVES_KILOMETERS })} </strong>
          <div>
            <InputText
              inputMode="numeric"
              keyfilter="pint"
              id={DRIVES_KILOMETERS}
              value={kilometer}
              onChange={(event) => {
                const newNumber = parseInt(event.target.value);
                const updatedData = {
                  ...inputData,
                  [kilometerKey]: !isNaN(newNumber) ? newNumber : 0,
                };
                updateParent(updatedData);
              }}
              onFocus={(e) => e.target.select()}
              style={{ border: bColorKm, width: "100%" }}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="col-6">
          <strong>
            {intl.formatMessage({ id: APPOINTMENTS_FIRST_DRIVER_LABEL })}
          </strong>
          {!isNew && <div>{generateNameText(firstDriver)}</div>}
          {isNew && (
            <div>
              <InputText
                value={!isReturn ? getDriverNameById(userId) : ""}
                disabled={disabled || isNew}
                /*       className="responsive-input-drives" */
                style={{ width: "100%" }}
                /*             options={drivers}
                optionLabel="alias"
                filter
                filterBy="alias" */

                /*            onChange={(e) => {
                  isReturn
                    ? updateParent({
                        ...inputData,
                        firstDriverReturn: e.value,
                      })
                    : updateParent({
                        ...inputData,
                        firstDriver: e.value,
                      });
                }} */
                /*           style={{
                  border:
                    !inputData.firstDriver && !inputData.secondDriver
                      ? "3px solid red"
                      : "",
                }} */
              />
            </div>
          )}
        </div>
        <div className="col-6">
          <strong>
            {intl.formatMessage({ id: APPOINTMENTS_SECOND_DRIVER_LABEL })}
          </strong>
          {!isNew && <div>{generateNameText(secondDriver)}</div>}
          {isNew && (
            <div>
              <Dropdown
                value={
                  isReturn
                    ? inputData?.secondDriverReturn
                    : inputData?.secondDriver
                }
                options={drivers}
                optionLabel="alias"
                filter
                disabled={disabled}
                filterBy="alias"
                style={{ width: "100%" }}
                onChange={(e) => {
                  isReturn
                    ? updateParent({
                        ...inputData,
                        secondDriverReturn: e.value,
                      })
                    : updateParent({
                        ...inputData,
                        secondDriver: e.value,
                      });
                }}
                filterInputAutoFocus={true}

                /*                 style={{
                  border:
                    !inputData.firstDriver && !inputData.secondDriver
                      ? "3px solid red"
                      : "",
                }} */
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketDriveInfo;
