import React, { useState } from "react";
// PrimeReact
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, mapDriversToComboData } from "common/Helpers";
// Static data
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Redux
import { connect } from "react-redux";
import { updateDrive } from "actions/wizardActions";

const ReturnInputs = (props) => {
  const logger = initLogger("appointment_drive_return_inputs");
  const {
    intl,
    cars,
    drivers,
    updateDrive,
    value: {
      firstDriverReturn,
      secondDriverReturn,
      carIdReturn,
      returnAppointment,
      kilometerReturn,
    },
  } = props;

  const {
    APPOINTMENTS_VEHICLE_LABEL,

    APPOINTMENTS_FIRST_DRIVER_LABEL,
    APPOINTMENTS_SECOND_DRIVER_LABEL,
    APPOINTMENTS_RETURN_TIME,
    APPOINTMENTS_KILOMETERS,

    ERROR_RENDER,
  } = MESSAGE_KEYS;

  const [inputVehicleReturn, setInputVehicleReturn] = useState(carIdReturn);
  const [inputKilometerReturn, setInputKilometerReturn] =
    useState(kilometerReturn);
  const [inputFirstDriverReturn, setInputFirstDriverReturn] = useState(
    mapDriversToComboData(firstDriverReturn, drivers)
  );
  const [inputSecondDriverReturn, setInputSecondDriverReturn] = useState(
    mapDriversToComboData(secondDriverReturn, drivers)
  );
  const [inputReturnStart, setInputReturnStart] = useState(returnAppointment);

  /**
   * Checks if time input is valid (at least hh:m), then checks if hours and minutes have valid values. (hours < 24, minutes < 59).
   * If hour/minute values are invalid, they will be reduced to valid values. Leading zeroes will be added to single digit values.
   * If all checks pass, a trailing :00 will be added to the returned time. Return null else
   *
   * @param {String} val Time input as colon-separated string value.
   * @returns {String} Input value formatted to hh:MM:ss if valid, null else.
   */
  const sanitizeTime = (val) => {
    try {
      let times = val.split(":");
      if (times.length >= 2) {
        let hours = parseInt(times[0]) > 23 ? "23" : times[0];
        let minutes = parseInt(times[1]) > 59 ? "59" : times[1];
        return `${hours}:${minutes}:00`;
      } else {
        return null;
      }
    } catch (sanitizeException) {
      logger.error(sanitizeException);
      return "";
    }
  };

  let displayedCars = [];
  if (cars?.length > 0) {
    displayedCars = [...cars];
  } else if (inputVehicleReturn) {
    displayedCars = [inputVehicleReturn];
  }

  try {
    return (
      <div className="grid">
        <div className="col-6 flex justify-content-between">
          <Dropdown
            value={inputVehicleReturn}
            options={displayedCars}
            onChange={(e) => {
              setInputVehicleReturn(e.value);
              updateDrive("carIdReturn", e.value);
            }}
            optionLabel="license_plate"
            filterBy="license_plate"
            filter
            showClear
            placeholder={intl.formatMessage({
              id: APPOINTMENTS_VEHICLE_LABEL,
            })}
            style={{ width: "100%" }}
            filterInputAutoFocus={true}
          />
          <Button
            icon="pi pi-times"
            className="ml-1 p-button-danger"
            onClick={() => {
              setInputVehicleReturn(null);
              updateDrive("carIdReturn", null);
            }}
            disabled={!inputVehicleReturn}
          />
        </div>
        <div className="col-6 flex align-items-end">
          <Dropdown
            placeholder={intl.formatMessage({
              id: APPOINTMENTS_FIRST_DRIVER_LABEL,
            })}
            optionLabel="alias"
            value={inputFirstDriverReturn}
            options={drivers}
            onChange={(e) => {
              setInputFirstDriverReturn(e.value);
              updateDrive("firstDriverReturn", e.value);
            }}
            style={{ width: "100%" }}
            disabled={!drivers || drivers.length < 1}
            appendTo={document.body}
            filter
            showClear
            filterBy="alias"
            filterInputAutoFocus={true}
          />
        </div>
        <div className="col-6 flex align-items-end mt-1">
          <Dropdown
            placeholder={intl.formatMessage({
              id: APPOINTMENTS_SECOND_DRIVER_LABEL,
            })}
            optionLabel="alias"
            value={inputSecondDriverReturn}
            options={drivers}
            onChange={(e) => {
              setInputSecondDriverReturn(e.value);
              updateDrive("secondDriverReturn", e.value);
            }}
            style={{ width: "100%" }}
            disabled={!drivers || drivers.length < 1}
            appendTo={document.body}
            filter
            showClear
            filterBy="alias"
            filterInputAutoFocus={true}
          />
        </div>
        <div className="col-4 flex align-items-end mt-1">
          <span className="p-float-label">
            <InputMask
              id={APPOINTMENTS_RETURN_TIME}
              mask="99:99"
              value={inputReturnStart}
              onChange={(e) => {
                setInputReturnStart(e.value);
                updateDrive("returnAppointment", sanitizeTime(e.value));
              }}
              style={{ width: "100%" }}
            />
            <label htmlFor={APPOINTMENTS_RETURN_TIME}>
              {intl.formatMessage({ id: APPOINTMENTS_RETURN_TIME })}
            </label>
          </span>
        </div>

        <div className="col-2 flex align-items-end mt-1">
          <span className="p-float-label">
            <FloatingTextInput
              className="ml-1"
              value={inputKilometerReturn}
              onChange={(e) => {
                setInputKilometerReturn(e.target.value);
                updateDrive("kilometerReturn", e.target.value);
              }}
              label={intl.formatMessage({ id: APPOINTMENTS_KILOMETERS })}
            />
          </span>
        </div>
      </div>
    );
  } catch (renderException) {
    logger.warn(
      "Exception on render return inputs",
      renderException,
      this.state
    );
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      appWizard: { appointmentDrive },
    } = state;
    return {
      currentUser,
      value: appointmentDrive,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      value: null,
    };
  }
};

export default connect(mapStateToProps, { updateDrive })(
  injectIntl(ReturnInputs)
);
