import React from "react";
// Custom components
import { FloatingTextInput, AddressSelector } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, equalObjects, hasValueChanged } from "common/Helpers";
// Redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Logging
const logger = initLogger("address_input_form");

const EMPTY_STATE = {
  inputLine1: "",
  inputLine2: "",
  inputZip: "",
  inputName: "",
  inputCity: "",
  inputAddress: null,
};

class AddressInputForm extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps, prevState) => {
    try {
      if (!equalObjects(prevProps.value, this.props.value, "addressId")) {
        this.initInputs();
      }
      if (this.valueModified(prevState)) {
        this.updateParent();
      }
    } catch (updateException) {
      logger.warn(
        "Exception on component did update",
        updateException,
        prevProps,
        this.props,
        prevState
      );
    }
  };

  valueModified = (prevState) => {
    let modified;
    try {
      const {
        inputAddress,
        inputCity,
        inputLine1,
        inputLine2,
        inputName,
        inputZip,
      } = this.state;
      modified =
        !equalObjects(inputAddress, prevState.inputAddress, "addressId") ||
        hasValueChanged(inputCity, prevState.inputCity) ||
        hasValueChanged(inputLine1, prevState.inputLine1) ||
        hasValueChanged(inputLine2, prevState.inputLine2) ||
        hasValueChanged(inputName, prevState.inputName) ||
        hasValueChanged(inputZip, prevState.inputZip);
      return modified;
    } catch (checkException) {
      logger.war("Exception on value modified");
      return false;
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value) {
      const { line1, line2, zipCode, addressName, countryProvince, addressId } =
        value;
      this.setState({
        inputLine1: line1 ? line1 : "",
        inputLine2: line2 ? line2 : "",
        inputZip: zipCode ? zipCode : "",
        inputName: addressName ? addressName : "",
        inputCity: countryProvince ? countryProvince : "",
        inputAddress: addressId ? value : null,
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  handleAddressSelection = (address) => {
    let newState;
    if (address !== null && address.addressId !== null) {
      newState = { ...address };
    } else {
      const { resetValue } = this.props;
      newState = resetValue
        ? { ...resetValue }
        : {
            ...EMPTY_STATE,
          };
    }
    this.props.setBaseValue("appointmentCustomerAddress", { ...newState });
  };

  updateParent = () => {
    const { setBaseValue } = this.props;
    const { inputAddress } = this.state;
    setBaseValue(
      "appointmentCustomerAddress",
      inputAddress !== null ? inputAddress : this.mapInputsToDTO()
    );
  };

  mapInputsToDTO = () => {
    let mappedValue;
    try {
      const {
        inputLine1,
        inputLine2,
        inputZip,
        inputName,
        inputCity,
        inputAddress,
      } = this.state;
      mappedValue = {
        line1: inputLine1,
        line2: inputLine2,
        countryProvince: inputCity,
        zipCode: inputZip,
        addressName: inputName,
        addressId: inputAddress,
      };
      return mappedValue;
    } catch (mapException) {
      logger.warn("Exception on map inputs to DTO", mapException);
      return null;
    }
  };

  render = () => {
    const { intl, className } = this.props;
    const {
      CUSTOMERS_ADDRESS_LINE_1_LABEL,
      CUSTOMERS_FILTER_NAME,
      CUSTOMERS_ADDRESS_LINE_2_LABEL,
      CUSTOMERS_ADDRESS_ZIP_LABEL,
      CUSTOMERS_ADDRESS_CITY_LABEL,
      APPOINTMENTS_SELECT_ADDRESS_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputLine1,
      inputLine2,
      inputZip,
      inputName,
      inputCity,
      inputAddress,
    } = this.state;

    return (
      <div className={className ? className : ""}>
        <AddressSelector
          value={inputAddress}
          onChange={(selection) => {
            this.handleAddressSelection(selection);
          }}
          placeholder={intl.formatMessage({
            id: APPOINTMENTS_SELECT_ADDRESS_LABEL,
          })}
        />
        <div className="flex justify-content-between mt-1">
          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_LINE_1_LABEL}
            value={inputLine1}
            onChange={(e) => {
              this.setState({ inputLine1: e.target.value });
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_1_LABEL })}
            disabled={inputAddress !== null}
            className="mr-1"
          />
          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
            value={inputLine2}
            onChange={(e) => {
              this.setState({ inputLine2: e.target.value });
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_2_LABEL })}
            disabled={inputAddress !== null}
          />
        </div>

        <div className="flex justify-content-between">
          <FloatingTextInput
            id={CUSTOMERS_FILTER_NAME}
            value={inputName}
            onChange={(e) => {
              this.setState({ inputName: e.target.value });
            }}
            label={intl.formatMessage({ id: CUSTOMERS_FILTER_NAME })}
            disabled={inputAddress !== null}
            className="mr-1"
          />

          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_ZIP_LABEL}
            value={inputZip}
            onChange={(e) => {
              this.setState({ inputZip: e.target.value });
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_ZIP_LABEL })}
            disabled={inputAddress !== null}
            className="mr-1"
          />

          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_CITY_LABEL}
            value={inputCity}
            onChange={(e) => {
              this.setState({ inputCity: e.target.value });
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_CITY_LABEL })}
            disabled={inputAddress !== null}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      appWizard: { appointmentCustomerAddress, resetCustomerAddress },
    } = state;
    return {
      value: appointmentCustomerAddress,
      resetValue: resetCustomerAddress,
    };
  } catch (mapException) {
    logger.error(mapException);
    return {
      value: null,
      resetValue: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(AddressInputForm)
);
