/**
 * This component renders a text input component.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
import { InputNumber } from "primereact/inputnumber";
import "./Common.scss";
import "primeflex/primeflex.css";

/**
 * Renders a text input field with the floating label effect.
 *
 * @param {Object} props - Component props
 * @param {String} props.id - The unique HTML ID.
 * @param {String} props.value - Contains value of the input field.
 * @param {Function} props.onChange - The onChange behaviour of the field.
 * @param {String} props.label - The label attached to the input.
 * @param {Boolean} props.valid - Set to false to mark the input as invalid.
 * @param {Function} props.onKeyDown - The onKeyDown behaviour of the field.
 *
 * @returns {JSX.Element} The text input with a floating label.
 */
const FloatingNumberInput = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    valid = true,
    onKeyDown,
    className = "",
  } = props;
  return (
    <span
      className={`p-float-label mt-4 ${className}`}
      style={{ width: "100%" }}
    >
      <InputNumber
        className={`${valid === true || valid === null ? "" : "p-invalid"}`}
        style={{ width: "100%" }}
        id={id}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        onFocus={(e) => e.target.select()}
        mode="decimal"
        suffix="€"
        minFractionDigits={2}
        maxFractionDigits={2}        
        useGrouping={false}
        locale="en-US"
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
};

export default FloatingNumberInput;
