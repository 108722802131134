import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
// Localization
import { useIntl } from "react-intl";
// Helper functions
import { generateName } from "common/Helpers";
import { MESSAGE_KEYS } from "assets/staticData/enums";
const TooltipCustomer = ({
  customerId,
  customerfullname,
  titleId,
  phone1,
  phone2,
  healthInsuranceNumber
}) => {
  const intl = useIntl();
  const {
    APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE,    
    CUSTOMERS_PHONE_LABEL,
    APPOINTMENTS_PHONE_LABEL,
    DRIVES_TABLE_PATIENT,
    CUSTOMERS_MATRICULATION_LABEL,
    CUSTOMERS_ERROR_MATRICULATION_REQUIRED,
  } = MESSAGE_KEYS;

  const generateCustomerRow = (label, value) => {
    return <div className="flex">
    <strong className="mr-1">
      {intl.formatMessage({ id: label })}:
    </strong>
    {value}
  </div>
  }

  const hin = healthInsuranceNumber ? healthInsuranceNumber : intl.formatMessage({id: CUSTOMERS_ERROR_MATRICULATION_REQUIRED});

  return (
    <div>
      <strong>{intl.formatMessage({ id: DRIVES_TABLE_PATIENT })}</strong>
      {customerId ? null : (
        <div style={{color: "yellow"}}>
          <strong>
            {intl.formatMessage({
              id: APPOINTMENTS_NO_CUSTOMER_ASSIGNED_NOTICE,
            })}
          </strong>
        </div>
      )}
      <div>{generateName(customerfullname, titleId, intl)}</div>
      {generateCustomerRow(CUSTOMERS_MATRICULATION_LABEL, hin)}
      {phone1 && generateCustomerRow(CUSTOMERS_PHONE_LABEL, phone1)}
      {phone2 && generateCustomerRow(APPOINTMENTS_PHONE_LABEL, phone2)}      
      <Divider />
    </div>
  );
};

export default TooltipCustomer;
