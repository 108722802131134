import React from "react";
// PrimeReact components
import { Dropdown } from "primereact/dropdown";
// Helper functions
import { isCurrentUserAdmin } from "common/Helpers";

const VacationUserSelector = ({ filterUser, drivers, changeUser }) => {
  if (isCurrentUserAdmin()) {
    return (
      <Dropdown
        id="vacation_header_user_filter"
        placeholder={filterUser?.alias}
        optionLabel="alias"
        value={filterUser}
        options={drivers}
        onChange={(e) => changeUser(e.value)}
        style={{ height: "40px", lineHeight: "20px" }}
        disabled={!drivers || drivers.length < 1}
        appendTo={document.body}
        filter
        filterBy="alias"
        filterInputAutoFocus={true}
      />
    );
  } else {
    return <></>;
  }
};

export default VacationUserSelector;
