import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact Components
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
// Custom components
import { SplitDateTimeInput } from "components/common";
// Helper functions
import { initLogger, dateToQueryString, valiDate } from "common/Helpers";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
import "./Style.scss";
// Logging
const logger = initLogger("dashboard_sales_table_filter");

const EMPTY_STATE = {
  inputFromDate: undefined,
  inputToDate: undefined,
  inputIncludeFacilities: false,
};

class SalesTableFilter extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  renderInputs = () => {
    const { intl } = this.props;
    const {
      DASHBOARD_SALES_FILTER_FROM_LABEL,
      DASHBOARD_SALES_FILTER_INCLUDE_LABEL,
      DASHBOARD_SALES_FILTER_TO_LABEL,
      ERROR_RENDER,
    } = MESSAGE_KEYS;

    const { inputFromDate, inputToDate, inputIncludeFacilities } = this.state;
    let filterContent;
    try {
      filterContent = (
        <div className="flex">
          <span>
            <Checkbox
              inputId={DASHBOARD_SALES_FILTER_INCLUDE_LABEL}
              checked={inputIncludeFacilities}
              onChange={(e) => {
                this.setState({ inputIncludeFacilities: e.checked });
              }}
            />
            <label
              htmlFor={DASHBOARD_SALES_FILTER_INCLUDE_LABEL}
              className="p-checkbox-label"
            >
              {intl.formatMessage({ id: DASHBOARD_SALES_FILTER_INCLUDE_LABEL })}
            </label>
          </span>

          <SplitDateTimeInput
            value={inputFromDate}
            onChange={(e) => {
              this.setState({
                inputFromDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({
              id: DASHBOARD_SALES_FILTER_FROM_LABEL,
            })}
            className="mr-2"
          />

          <SplitDateTimeInput
            value={inputToDate}
            onChange={(e) => {
              this.setState({
                inputToDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({
              id: DASHBOARD_SALES_FILTER_TO_LABEL,
            })}
          />
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on render sales filter", renderException);
      filterContent = <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    } finally {
      return filterContent;
    }
  };

  renderButtonRow = () => {
    const { intl, handleSearch, isPending } = this.props;
    const {
      ERROR_RENDER,
      DIALOG_SEARCH_BUTTON_LABEL,
      DIALOG_CLEAR_FILTER_LABEL,
    } = MESSAGE_KEYS;
    const { inputFromDate, inputIncludeFacilities, inputToDate } = this.state;
    let buttonRow;
    try {
      let buttonClass = isPending ? "pi pi-spin pi-spinner" : "pi pi-search";
      buttonRow = (
        <div className="flex mt-2">
          <Button
            icon={buttonClass}
            label={
              isDesktop
                ? intl.formatMessage({
                    id: DIALOG_SEARCH_BUTTON_LABEL,
                  })
                : ""
            }
            onClick={() => {
              logger.info("CALLING SEARCH", this.state);
              handleSearch({
                inputFromDate: inputFromDate
                  ? dateToQueryString(inputFromDate)
                  : "",
                inputToDate: inputToDate ? dateToQueryString(inputToDate) : "",
                inputIncludeFacilities,
              });
            }}
            className="mr-2"
          />

          <Button
            icon={"pi pi-undo"}
            label={
              isDesktop
                ? intl.formatMessage({
                    id: DIALOG_CLEAR_FILTER_LABEL,
                  })
                : ""
            }
            onClick={() => {
              this.setState({ ...EMPTY_STATE }, handleSearch());
            }}
          />
        </div>
      );
    } catch (renderException) {
      logger.warn(
        "Exception on render sales filter button row",
        renderException
      );
      buttonRow = <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    } finally {
      return buttonRow;
    }
  };

  render = () => {
    const { intl } = this.props;
    const { DASHBOARD_SALES_FILTER_TITLE } = MESSAGE_KEYS;
    return (
      <Fieldset
        legend={intl.formatMessage({ id: DASHBOARD_SALES_FILTER_TITLE })}
      >
        {this.renderInputs()}
        {this.renderButtonRow()}
      </Fieldset>
    );
  };
}

export default injectIntl(SalesTableFilter);
