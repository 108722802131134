import React from "react";
// React redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

class EmptyPage extends React.Component {
  render = () => {
    const { intl, setTopbarTitle } = this.props;
    const { MENU_EMPTY, EMPTY_PAGE_TITLE, EMPTY_PAGE_SUMMARY } = MESSAGE_KEYS;

    setTopbarTitle(MENU_EMPTY);

    return (
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h1>{intl.formatMessage({ id: EMPTY_PAGE_TITLE })}</h1>
            <p>{intl.formatMessage({ id: EMPTY_PAGE_SUMMARY })}</p>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(null, { setTopbarTitle })(injectIntl(EmptyPage));
