/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
// Custom components
import { SplitDateTimeInput } from "components/common";

// Helper functions
import {
  numberToPriceString,
  dateToISOString,
  sendQuery,
  initLogger,
  valiDate,
  safeRender,
  equalObjects,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  VALIDATION_RULES,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";

const availableOptions = [
  {
    name: `BILL`,
    value: "bill",
  },
  {
    name: `BCEE`,
    value: "bcee",
  },
  {
    name: `CCRA`,
    value: "ccra",
  },
];
const logger = initLogger("add_payment_dialog");

const EMPTY_STATE = {
  inputBank: "",
  inputBillingDate: new Date(),
  inputAmount: 0,
  inputComment: "",

  validBank: null,
  validBillingDate: undefined,
  validAmount: null,
  updatePending: false,
};

class AddPaymentDialog extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (
      !(value && prevProps.value) ||
      !equalObjects(prevProps.value, value, "transactionId")
    ) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value) {
      const { amount, paymentDate, bank, info } = value;
      this.setState({
        inputBank: bank ? bank : "",
        inputBillingDate: new Date(paymentDate ? paymentDate : null),
        inputAmount: amount ? amount : 0,
        inputComment: info ? info : "",
      });
    }
  };

  validateInputs = () => {
    const { inputAmount, inputBank, inputBillingDate } = this.state;
    this.setState({
      updatePending: true,
    });
    let validBank, validBillingDate, validAmount;
    validBank =
      inputBank &&
      inputBank !== null &&
      inputBank.length >= VALIDATION_RULES.BANK_MIN_LENGTH;
    validBillingDate = inputBillingDate != null;
    validAmount = !isNaN(inputAmount);
    this.setState(
      {
        validBillingDate,
        validAmount,
        validBank,
      },
      () => {
        if (validBank && validBillingDate && validAmount) {
          this.savePayment();
        } else {
          this.setState({ updatePending: false });
        }
      }
    );
  };

  mapPaymentToDTO = () => {
    const { intl, transactionId, customerId, value } = this.props;
    const { ERROR_DATA_SAVE } = MESSAGE_KEYS;
    const { inputAmount, inputBank, inputBillingDate, inputComment } =
      this.state;
    let dto;
    try {
      dto = {
        paymentId: value && value.paymentId ? value.paymentId : null,
        amount: inputAmount ? inputAmount : 0.0,
        paymentDate: inputBillingDate
          ? dateToISOString(inputBillingDate)
          : null,
        bank: inputBank ? inputBank : "",
        info: inputComment ? inputComment : "",
        transactionId,
        active: true,
        invoiceCustomer: { personId: customerId },
      };
    } catch (mapException) {
      logger.warn("Exception op map state to DTO", mapException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({
          id: ERROR_DATA_SAVE,
        }),
      });
      dto = null;
    } finally {
      return dto;
    }
  };

  savePayment = (newPayment) => {
    const { handleAdd, intl } = this.props;
    const { ERROR_DATA_SAVE } = MESSAGE_KEYS;
    let data = this.mapPaymentToDTO(newPayment);
    try {
      if (data) {
        sendQuery(QUERIES.EDIT_PAYMENT, "POST", data).then(
          (response) => {
            const savedBank = this.state.inputBank;
            this.setState({
              ...EMPTY_STATE,
            });
            this.setState({ inputBank: savedBank });
            handleAdd(response);
          },
          (error) => {
            logger.warn("Error on payment add", error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: intl.formatMessage({
                id: ERROR_DATA_SAVE,
              }),
            });
            this.setState({
              updatePending: false,
            });
          }
        );
      } else {
        this.setState({
          updatePending: false,
        });
      }
    } catch (saveException) {
      this.setState({
        updatePending: false,
      });
    }
  };

  renderAmountRow = () => {
    const { inputAmount, validAmount, updatePending } = this.state;
    const { intl, remainingAmount } = this.props;
    const { PAYMENTS_AMOUNT_LABEL, BILLS_AMOUNT_REMAINING_LABEL } =
      MESSAGE_KEYS;
    return (
      <div className={`${remainingAmount ? "flex" : ""} mt-4`}>
        <span className="p-float-label">
          <InputNumber
            id={PAYMENTS_AMOUNT_LABEL}
            value={inputAmount}
            onValueChange={(e) => this.setState({ inputAmount: e.value })}
            suffix=" €"
            className={`${
              validAmount === true || validAmount === null ? "" : "p-invalid"
            }`}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            disabled={updatePending}
            onFocus={(e) => e.target.select()}
            useGrouping={false}
            locale="en-US"
          />
          <label htmlFor={PAYMENTS_AMOUNT_LABEL}>
            {intl.formatMessage({ id: PAYMENTS_AMOUNT_LABEL })}
          </label>
          {remainingAmount ? (
            <span className="ml-2">{`${intl.formatMessage({
              id: BILLS_AMOUNT_REMAINING_LABEL,
            })}: ${numberToPriceString(remainingAmount)}`}</span>
          ) : (
            <></>
          )}
        </span>
      </div>
    );
  };

  renderFooter = () => {
    const { onHide, intl, value } = this.props;
    const {
      PAYMENTS_SAVE_BUTTON_LABEL,
      PAYMENTS_CREATE_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    const { updatePending } = this.state;
    let saveButtonLabel = intl.formatMessage({
      id: value ? PAYMENTS_SAVE_BUTTON_LABEL : PAYMENTS_CREATE_BUTTON_LABEL,
    });
    return (
      <div className="p-display-flex justify-content-between mb-2">
        <Button
          label={saveButtonLabel}
          onClick={this.validateInputs}
          disabled={updatePending}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={() => {
            this.setState({ ...EMPTY_STATE });
            onHide();
          }}
          disabled={updatePending}
        />
      </div>
    );
  };

  renderContent = () => {
    const { intl } = this.props;
    const {
      PAYMENTS_DATE_LABEL,
      PAYMENTS_BANK_LABEL,
      BILLS_COMMENT_INTERNAL_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputBank,
      inputBillingDate,
      inputComment,
      validBillingDate,
      updatePending,
    } = this.state;

    return (
      <div className="mt-2">            
        <div className="mb-1">
          <span className="p-float-label" style={{ width: "100%" }}>
            <Dropdown
              id={PAYMENTS_BANK_LABEL}
              options={availableOptions}
              value={inputBank.toLowerCase()}
              optionLabel="name"
              onChange={(event) => this.setState({ inputBank: event.value })}
              disabled={updatePending}
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: PAYMENTS_BANK_LABEL })}
            </label>
          </span>
        </div>

        {this.renderAmountRow()}

        <div className="mt-4">
          <SplitDateTimeInput
            value={inputBillingDate}
            onChange={(e) => {
              this.setState({
                inputBillingDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({ id: PAYMENTS_DATE_LABEL })}
            className={
              validBillingDate === true || validBillingDate === null
                ? ""
                : "p-invalid"
            }
          />
        </div>

        <div className="mt-4">
          <span className="p-float-label" style={{ width: "100%" }}>
            <InputTextarea
              id="inputComment"
              value={inputComment}
              onChange={(e) => this.setState({ inputComment: e.target.value })}
              rows={5}
              cols={30}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
              disabled={updatePending}
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}
            </label>
          </span>
        </div>
      </div>
    );
  };

  render = () => {
    const { intl, visible, onHide } = this.props;
    const { CUSTOMERS_ADD_PAYMENT_TITLE_LABEL, ERROR_RENDER } = MESSAGE_KEYS;
    return (
      <Dialog
        header={intl.formatMessage({ id: CUSTOMERS_ADD_PAYMENT_TITLE_LABEL })}
        footer={this.renderFooter}
        visible={visible}
        onHide={onHide}
        style={{ maxWidth: "500px" }}
      >
        <Toast ref={(el) => (this.toast = el)} />
        {safeRender(
          this.renderContent,
          intl.formatMessage({ id: ERROR_RENDER })
        )}
      </Dialog>
    );
  };
}

export default injectIntl(AddPaymentDialog);
