import React, { memo } from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
// Custom components
import {
  AppointmentCustomerView,
  AppointmentDriveView,
  AppointmentReturnView,
} from "./DialogViews";
// Helper functions
import {
  dateToISOString,
  dateToMaskString,
  equalObjects,
  initLogger,
  sendQuery,
} from "common/Helpers";
//  Localization
import { injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import { initWizard } from "actions/wizardActions";
// Styling
import "./Style.scss";
// Static data
import {
  QUERIES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
// Logger
const logger = initLogger("appointment_create_dialog");

const EMPTY_WIZARD = {
  viewIndex: 0,
  appointmentCustomer: null,
  appointmentDeparture: null,
  appointmentDestination: null,
  appointmentDrive: null,
  appointmentRepeating: null,
  baseItemList: [],
  title: "",
};

document.ontouchmove = function (e) {
  e.stopPropagation();
  e.stopImmediatePropagation();
};
class AppointmentCreateDialog extends React.Component {
  state = {
    ...EMPTY_WIZARD,
  };

  /**
   * Remove the keydown eventlistener.
   */
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleCtrlEnter);
  };

  handleCtrlEnter = (event) => {
    if (event.key === "Enter" && event.ctrlKey) {
      this.updateAppointment();
    }
  };

  componentDidMount = () => {
    // Adds a keydown listener to enable the Ctrl+Enter-keypress.
    document.addEventListener("keydown", this.handleCtrlEnter, false);
    try {
      sendQuery(`${QUERIES.GET_CHECKLIST_BY_ID}1`, "get").then(
        // TODO Watch out, static ID.
        (response) => {
          if (response) {
            const { checklistItems } = response;
            this.setState({
              baseItemList: checklistItems,
            });
          }
        },
        (error) => {
          logger.warn("APPOINTMENT ERROR", error);
        }
      );
    } catch (fetchException) {
      logger.warn("Exception on fetch checklist items", fetchException);
    }
  };

  cmp = (objA, objB) => {
    if (objA.sortingOrder < objB.sortingOrder) {
      return -1;
    }
    if (objA.sortingOrder > objB.sortingOrder) {
      return 1;
    }
    return 0;
  };

  orderChecklistItems = (checklist) => {
    let orderedList;
    try {
      let tmp = {};
      checklist.forEach((entry) => {
        if (tmp[entry.group]) {
          tmp[entry.group].push(entry);
        } else {
          tmp[entry.group] = [entry];
        }
      });
      orderedList = [];
      for (let key in tmp) {
        orderedList.push(...tmp[key].sort(this.cmp));
      }
      return orderedList;
    } catch (orderException) {
      return [];
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.hasSelectionChanged(prevProps)) {
      this.initInputs();
      this.generateHeader();
    }
    if (
      !equalObjects(
        prevProps.appointmentCustomer,
        this.props.appointmentCustomer,
        "personId"
      )
    ) {
      this.generateHeader();
    }
  };

  mapTime = (date) => {
    if (typeof date === "string") {
      let tmpDate = new Date(date);
      return new Date(tmpDate.getTime() + tmpDate.getTimezoneOffset() * 60000);
    } else {
      return date;
    }
  };

  initInputs = () => {
    try {
      const {
        selectedAppointment: {
          starttime,
          station,
          transportTypeLine,
          state,
          stateName,
          description,
          appointmentChecklistItems,
          car,
          transportType,
          transportTypeName,
          firstDriver,
          secondDriver,
          stationName,
          transportTypeLineName,
          firstDriverReturn,
          secondDriverReturn,
          carIdReturn,

          customer,
          fromAddressKeyword,
          fromAddress,
          roomFrm,
          departmentFrm,
          toAddressKeyword,
          toAddress,
          roomTo,
          departmentTo,
          nextAppointment,
          appointmentId,

          fromAddressTxt,
          toAddressTxt,

          kilometer,
          kilometerReturn,

          showDescription,
          isolated,

          notPaid,
          lastInvoiceNumber,
          lastInvoiceDate,

          meetingTime,

          personId,
          firstname,
          lastname,
          girlName,
          healthInsuranceNumber,
          phoneHome,
          phoneWork,
          gsm,
          titleId,
          sexId,
          ownWheelchair,
          elevator,
          stairsCount,
          stairs,
          weight,
          overweight,
          addressId,
          vaccinated,

          email,

          line1,
          line2,
          zipCode,
          addressName,
          countryProvince,

          departureAddress1,
          departureAddress2,
          departureZipcode,

          arrivalAddress1,
          arrivalAddress2,
          arrivalZipcode,

          returnAppointment,

          remark,
          patientVaccinated,

          outwardPaper,
          outwardDeparture,
          outwardArrival,
          outwardDischarge,
          returnPaper,
          returnDeparture,
          returnArrival,
          returnDischarge,

          signature,

          transportTypeInvoice,
        },
      } = this.props;

      let appointmentCustomer;
      let appointmentCustomerAddress;
      let dateOfBirth;
      if (customer?.personId) {
        dateOfBirth = customer.dateOfBirth;
      } else {
        dateOfBirth = this.props.selectedAppointment.dateOfBirth;
      }

      let resetCustomer = {
        personId,
        firstname,
        lastname,
        girlName,
        healthInsuranceNumber,
        phoneHome,
        phoneWork,
        gsm,
        titleId,
        sexId,
        ownWheelchair,
        elevator,
        stairsCount,
        stairs,
        weight,
        overweight,
        addressId,

        notPaid,
        lastInvoiceNumber,
        lastInvoiceDate,
        vaccinated: vaccinated || patientVaccinated,
        email,
        dateOfBirth,
      };
      let resetCustomerAddress = {
        line1,
        line2,
        zipCode,
        addressName,
        countryProvince,
      };
      // Check if customer is set. Load from customer- & address-objects if true, load from appointment else.
      if (customer && customer.personId) {
        appointmentCustomer = {
          ...customer,
          notPaid,
          lastInvoiceNumber,
          lastInvoiceDate,
          patientVaccinated,
          dateOfBirth,
        };
        appointmentCustomerAddress =
          customer.addressList && customer.addressList.length > 0
            ? customer.addressList[0]
            : {};
      } else {
        appointmentCustomer = {
          ...resetCustomer,
          patientVaccinated,
        };

        appointmentCustomerAddress = {
          ...resetCustomerAddress,
        };
      }

      if (!appointmentCustomerAddress.hasOwnProperty("addressId")) {
        appointmentCustomerAddress.addressId = null;
      }

      let checklist =
        appointmentChecklistItems && appointmentChecklistItems.length > 0
          ? [...appointmentChecklistItems]
          : this.generateChecklist(
              this.orderChecklistItems(this.state.baseItemList)
            );

      this.generateHeader();
      this.props.initWizard({
        appointmentCustomer,
        appointmentDeparture: {
          keyword: fromAddressKeyword,
          address: fromAddress,
          room: roomFrm,
          department: departmentFrm,
          text: fromAddressTxt,

          departureAddress1: departureAddress1 ? departureAddress1 : "",
          departureAddress2: departureAddress2 ? departureAddress2 : "",
          departureZipcode: departureZipcode ? departureZipcode : "",
        },
        appointmentCustomerAddress,
        appointmentDestination: {
          keyword: toAddressKeyword,
          address: toAddress,
          room: roomTo,
          department: departmentTo,
          text: toAddressTxt,

          arrivalAddress1: arrivalAddress1 ? arrivalAddress1 : "",
          arrivalAddress2: arrivalAddress2 ? arrivalAddress2 : "",
          arrivalZipcode: arrivalZipcode ? arrivalZipcode : "",
        },
        appointmentDrive: {
          starttime: this.mapTime(starttime).getTime(),
          station,
          transportType:
            typeof transportType === "string"
              ? parseInt(transportType)
              : transportType,
          transportTypeLine,
          state,
          stateName,
          description,
          appointmentChecklistItems: checklist,
          car,
          transportTypeName,
          firstDriver,
          secondDriver,
          stationName,
          transportTypeLineName,
          firstDriverReturn,
          secondDriverReturn,
          carIdReturn,
          nextAppointment: nextAppointment,
          appointmentId,
          kilometer,
          kilometerReturn,
          showDescription,
          isolated,
          meetingTime: /* this.mapTime( */ meetingTime /* ) */,
          returnAppointment,
          multipleChecked: false,
          remark: remark ? remark : "",
          outwardPaper,
          outwardDeparture,
          outwardArrival,
          outwardDischarge,
          returnPaper,
          returnDeparture,
          returnArrival,
          returnDischarge,
          signature,
          transportTypeInvoice,
        },
        appointmentRepeating: [],
        resetCustomer,
        resetCustomerAddress,
      });
    } catch (dumException) {
      logger.warn("Exception on init inputs", dumException, this.props);
    }
  };

  hasSelectionChanged = (oldProps) => {
    let hasChanged = false;
    try {
      if (oldProps === null || oldProps === undefined) {
        hasChanged = true;
      } else {
        const { selectedAppointment } = this.props;
        if (!oldProps.selectedAppointment && selectedAppointment) {
          hasChanged = true;
        } else {
          if (selectedAppointment) {
            const {
              starttime,
              station,
              transportType,
              transportTypeLine,
              appointmentId,
            } = selectedAppointment;
            hasChanged =
              oldProps.selectedAppointment.starttime !== starttime ||
              oldProps.selectedAppointment.transportType !== transportType ||
              oldProps.selectedAppointment.station !== station ||
              oldProps.selectedAppointment.transportTypeLine !==
                transportTypeLine ||
              oldProps.selectedAppointment.appointmentId !== appointmentId;
          }
        }
      }
      return hasChanged;
    } catch (checkException) {
      logger.warn(
        "Exception on has selection changed",
        checkException,
        this.props,
        oldProps
      );
      return false;
    }
  };

  generateChecklist = (itemList) => {
    const { selectedAppointment } = this.props;
    let mappedChecklist = [];
    try {
      if (itemList && itemList.length > 0) {
        itemList.forEach((checklistItem) => {
          mappedChecklist.push({
            appointmentChecklistItemsId: checklistItem.checklistItemId,
            appointmentId:
              selectedAppointment && selectedAppointment.appointmentId
                ? selectedAppointment.appointmentId
                : null,
            checklistItem,
            itemState: false,
            txt_value: "",
            nbr_value: 0,
            hasTxtValue: checklistItem.hasTxtValue,
            hasNbrValue: checklistItem.hasNbrValue,
          });
        });
      }
      return mappedChecklist;
    } catch (mapException) {
      logger.warn("Exception on generateChecklist", mapException, itemList);
      return [];
    }
  };

  updateAppointment = () => {
    const { intl, handleParentUpdate } = this.props;
    let mappedValue = this.mapInputsToDTO();
    sendQuery(QUERIES.EDIT_APPOINTMENT, "POST", mappedValue).then(
      (response) => {
        logger.info("Appointment was saved", response, mappedValue);
        if (this.toast) {
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
            }),
          });
        }
        if (response && response.appointmentId) {
          this.setState(
            { viewIndex: 0 },
            handleParentUpdate(response.appointmentId)
          );
        }
      },
      (error) => {
        logger.warn("Could not save data", error, mappedValue);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: MESSAGE_KEYS.ERROR_DATA_SAVE }),
        });
      }
    );
  };

  mapInputsToDTO = () => {
    let mappedValue;
    const { selectedAppointment } = this.props;
    try {
      const {
        appointmentCustomer,
        appointmentCustomerAddress,
        appointmentDeparture,
        appointmentDestination,
        appointmentDrive,
        appointmentRepeating,
      } = this.props;
      let dummyDate = dateToISOString(new Date(), false);
      let endtime = dummyDate;
      if (appointmentDrive.starttime) {
        endtime = new Date(appointmentDrive.starttime);
        endtime.setMinutes(endtime.getMinutes() + 15);
      }
      endtime = dateToISOString(endtime, false);

      let starttime;
      if (
        appointmentDrive?.starttime &&
        typeof appointmentDrive.starttime !== "string"
      ) {
        starttime = dateToISOString(appointmentDrive.starttime, false);
      } else {
        starttime = appointmentDrive.starttime
          ? appointmentDrive.starttime
          : dummyDate;
      }

      let meetingTime = appointmentDrive?.meetingTime;

      let mappedDeparture = {};
      if (appointmentDeparture) {
        const {
          room,
          department,
          address,
          keyword,
          text,
          departureAddress1,
          departureAddress2,
          departureZipcode,
        } = appointmentDeparture;
        mappedDeparture = {
          departureAddress1,
          departureAddress2,
          departureZipcode,
        };
        mappedDeparture.roomFrm = room ? room : "";
        mappedDeparture.departmentFrm = department ? department : "";
        mappedDeparture.fromAddressKeyword = keyword ? keyword : "";
        let addressId = address;
        if (typeof address === "object") {
          addressId = address?.addressId ? address.addressId : null;
        }
        mappedDeparture.fromAddress = addressId;
        mappedDeparture.fromAddressTxt = text ? text : "";
      }
      let mappedArrival = {};
      if (appointmentDestination) {
        const {
          room,
          department,
          address,
          keyword,
          text,
          arrivalAddress1,
          arrivalAddress2,
          arrivalZipcode,
        } = appointmentDestination;
        mappedArrival = {
          arrivalAddress1,
          arrivalAddress2,
          arrivalZipcode,
        };
        mappedArrival.roomTo = room ? room : "";
        mappedArrival.departmentTo = department ? department : "";
        mappedArrival.toAddressKeyword = keyword;
        let addressId = address;
        if (typeof address === "object") {
          addressId = address?.addressId ? address.addressId : null;
        }
        mappedArrival.toAddress = addressId;

        mappedArrival.toAddressTxt = text ? text : "";
      }

      mappedValue = {
        ...appointmentDrive,
        multiAppointmentList:
          appointmentRepeating.length > 0 ? appointmentRepeating : null,
        active: true,
        entryCreatedManually: false,
        starttime,
        endtime,
        meetingTime,
        transportType: appointmentDrive.transportType, // Bloody hell
        ...mappedDeparture,
        ...mappedArrival,
        // Unaccounted values
        title: "",
        type: appointmentDrive.state,
        state: appointmentDrive.state,
        typeName: null,
        watingPaperTime: null,
        departureTime: null,
        arriveTime: null,
        dischargeTime: null,
        signature: null,
        patientVaccinated: appointmentCustomer.patientVaccinated,
        //carIdReturn: appointmentDrive.carIdReturn && appointmentDrive.carIdReturn.vehicleId ? appointmentDrive.carIdReturn.vehicleId : null
      };
      // If a customer has been selected, add customer & customer address as object. Attach to appointment else.
      if (
        appointmentCustomer?.personId ||
        appointmentCustomer.createCustomer === true
      ) {
        mappedValue.customer = {
          ...appointmentCustomer,
          addressList: [{ ...appointmentCustomerAddress }],
        };
      } else {
        mappedValue = {
          ...mappedValue,
          ...appointmentCustomer,
          ...appointmentCustomerAddress,
          customer: { personId: null },
          phoneWork: appointmentCustomer?.gsm,
        };
      }

      if (selectedAppointment && selectedAppointment.appointmentId) {
        mappedValue.appointmentId = selectedAppointment.appointmentId;
      }
      mappedValue.driverHasRead = selectedAppointment?.driverHasRead
        ? selectedAppointment.driverHasRead
        : 0;

      mappedValue.driverUpload = ![undefined, null].includes(
        selectedAppointment?.driverUpload
      )
        ? selectedAppointment.driverUpload
        : 0;

      logger.info("CALLED MAPPING FUNCTION", this.props, mappedValue);
      return mappedValue;
    } catch (mapException) {
      logger.warn("Exception on map inputs to DTO", mapException);
      return null;
    }
  };

  generateHeader = (reset = false) => {    
    const {
      intl,
      selectedAppointment,
      appointmentCustomer,
      appointmentDeparture,
    } = this.props;
    const {
      APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL,
      APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL,
    } = MESSAGE_KEYS;
    let headerKey = selectedAppointment?.appointmentId
      ? APPOINTMENTS_EDIT_DIALOG_TITLE_LABEL
      : APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL;
    if (reset) {
      this.setState({
        title: `${intl.formatMessage({ id: headerKey })} - ${dateToMaskString(
          selectedAppointment?.starttime,
          true,
          "/"
        )}`,
      });
    } else {
      let title;
      try {
        title = `${intl.formatMessage({ id: headerKey })} - ${dateToMaskString(
          selectedAppointment?.starttime,
          true,
          "/"
        )} ${
          appointmentCustomer?.firstname ? appointmentCustomer.firstname : ""
        } ${
          appointmentCustomer?.lastname ? appointmentCustomer.lastname : ""
        } ${
          appointmentDeparture?.departureAddress1
            ? appointmentDeparture.departureAddress1
            : ""
        }`;
      } catch (headerException) {
        title = intl.formatMessage({
          id: APPOINTMENTS_CREATE_DIALOG_TITLE_LABEL,
        });
      }
      this.setState({ title });
    }
  };

  renderContent = () => {
    const { cars, drivers, status, intl, handleParentUpdate } = this.props;

    let view;
    try {
      const { viewIndex } = this.state;
      switch (this.state.viewIndex) {
        case 1:
          view = (
            /*<AppointmentDriveRedux cars={cars}
            drivers={drivers}
              appointment={selectedAppointment}
            />*/
            <AppointmentDriveView
              cars={cars}
              drivers={drivers}
              status={status}
              handleBackClick={() =>
                this.setState({ viewIndex: viewIndex - 1 }, this.generateHeader)
              }
              handleParentUpdate={() =>
                this.setState({ viewIndex: viewIndex + 1 }, this.generateHeader)
              }
              handleAppointmentSave={this.updateAppointment}
            />
          );
          break;
        case 2:
          view = (
            <AppointmentReturnView
              cars={cars}
              drivers={drivers}
              handleBackClick={() =>
                this.setState({ viewIndex: viewIndex - 1 })
              }
              handleParentUpdate={this.updateAppointment}
            />
          );
          break;
        default:
          view = (
            <AppointmentCustomerView
              handleParentUpdate={() =>
                this.setState(
                  {
                    viewIndex: viewIndex + 1,
                  },
                  this.generateHeader
                )
              }
              handleCustomerSave={this.updateAppointment}
              handleDialogClose={() =>
                this.setState({ viewIndex: 0 }, handleParentUpdate(null))
              }
              callWebsocket={this.props.callWebsocket}
            />
          );
      }
      return view;
    } catch (renderException) {
      logger.warn("Exception on renderContent", renderException);
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { intl, visible, onHide } = this.props;
    const {
      APPOINTMENTS_DRIVE_STEP_LABEL,
      APPOINTMENTS_PASSENGER_STEP_LABEL,
      APPOINTMENTS_MULTI_STEP_LABEL,
    } = MESSAGE_KEYS;

    let wizard = [
      {
        label: intl.formatMessage({ id: APPOINTMENTS_PASSENGER_STEP_LABEL }),
        command: () => {
          this.setState({ viewIndex: 0 });
        },
      },
      {
        label: intl.formatMessage({ id: APPOINTMENTS_DRIVE_STEP_LABEL }),
        command: () => {
          this.setState({ viewIndex: 1 });
        },
      },
      {
        label: intl.formatMessage({ id: APPOINTMENTS_MULTI_STEP_LABEL }),
        command: () => {
          this.setState({ viewIndex: 2 });
        },
      },
    ];

    return (
      <Dialog
        header={this.state.title}
        visible={visible}
        className="wizard_dialog"
        onHide={() => {
          this.setState({ title: "" });
          this.setState({ viewIndex: 0 }, onHide());
        }}
        closeOnEscape={false}
        id={this.props.id}
        modal
        blockScroll={true}
      >
        <Toast ref={(el) => (this.toast = el)} />
        <Steps
          model={wizard}
          activeIndex={this.state.viewIndex}
          onSelect={(e) => this.setState({ viewIndex: e.index })}
          className="mt-1 mb-1"
        />
        {this.renderContent()}
      </Dialog>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.appWizard,
  };
};

export default connect(mapStateToProps, { initWizard })(
  injectIntl(memo(AppointmentCreateDialog))
);
