import React, { useState, useEffect, useRef, useCallback } from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// Custom components
import {
  TicketDetails,
  TicketCheckboxes,
  TicketTransport,
  TicketTimes,
  TicketSignature,
  TicketDriveInfo,
} from "./TicketViews";
// Localization
import { useIntl } from "react-intl";
import {
  MESSAGE_KEYS,
  QUERIES,
  RESPONSIVE_BREAKPOINT,
} from "assets/staticData/enums";
// Helper functions
import {
  dateToQueryString,
  initLogger,
  sendQuery,
  dateToISOString,
} from "common/Helpers";
import { connect } from "react-redux";

// Logging
const logger = initLogger("TransportTicket");
// Assets
const logo = "assets/layout/images/logo_luxabl.svg";

const TransportTicket = ({
  appointment,
  cars,
  appointmentTypes,
  isDrive,
  handleCancel,
  handleAppointmentFinish,
  storeFailedUpload,
  currentUser,
  status,
  drivers,
  checkboxesList,
  handleRefresh,
  userId,
}) => {
  const departure = {
    line1: appointment?.departureAddress1,
    line2: appointment?.departureAddress2,
    city: appointment?.departureCity,
    zip: appointment?.departureZipcode,
    keyword: appointment?.fromAddressKeyword,
  };
  const arrival = {
    line1: appointment?.arrivalAddress1,
    line2: appointment?.arrivalAddress2,
    city: appointment?.arrivalCity,
    zip: appointment?.arrivalZipcode,
    keyword: appointment?.toAddressKeyword,
  };

  const toast = useRef(null);

  const intl = useIntl();

  const [allCheckItems, setAllCheckItems] = useState([]);

  const [formatErrorInput, setFormatErrorInput] = useState(false);

  const orderChecklistItems = useCallback((checklist) => {
    let orderedList;
    try {
      let tmp = {};
      checklist.forEach((entry) => {
        if (tmp[entry.group]) {
          tmp[entry.group].push(entry);
        } else {
          tmp[entry.group] = [entry];
        }
      });
      orderedList = [];
      for (let key in tmp) {
        orderedList.push(...tmp[key].sort(cmp));
      }
      return orderedList;
    } catch (orderException) {
      return [];
    }
  }, []);

  const cmp = (objA, objB) => {
    if (objA.sortingOrder < objB.sortingOrder) {
      return -1;
    }
    if (objA.sortingOrder > objB.sortingOrder) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    // Remove bus checklist item and add next appointment item for drives view.
    if (allCheckItems.length === 0) {
      if (appointment?.isNew) {
        appointment.appointmentChecklistItems = generateChecklist(
          orderChecklistItems(checkboxesList),
          appointment
        );
      }
      if (isDrive) {
        const BUS_ITEM_ID = 27;
        let newList = appointment?.appointmentChecklistItems
          ? appointment?.appointmentChecklistItems.filter(
              (searchItem) =>
                searchItem?.checklistItem?.checklistItemId !== BUS_ITEM_ID
            )
          : null;
        let nextAppointment = {
          ...appointment?.appointmentChecklistItems?.[0],
        };
        if (nextAppointment) {
          nextAppointment.checklistItem = {
            ...appointment?.appointmentChecklistItems?.[0]?.checklistItem,
          };
          nextAppointment.txt_value = appointment?.nextAppointment
            ? new Date(appointment.nextAppointment)
            : "";
          nextAppointment.itemState = appointment?.nextAppointment
            ? true
            : false;
          nextAppointment.appointmentChecklistItemsId = null;
          nextAppointment.checklistItem.checklistItemId = null;
          nextAppointment.checklistItem.hasTxtValue = true;
          nextAppointment.checklistItem.hasNbrValue = false;
          nextAppointment.checklistItem.nameDe = "N. Termin";
          nextAppointment.checklistItem.nameFr = "Pr. rdv.";
          nextAppointment.checklistItem.sortingOrder = 9;
          nextAppointment.checklistItem.group = "Desinfektion";
          nextAppointment.checklistItem.hasDateVal = true;
          nextAppointment.checklistItem.updateParent = setInputData;
        }

        if (newList) {
          newList.unshift(nextAppointment);
          setAllCheckItems(newList);
        }
      } else {
        setAllCheckItems(
          appointment?.appointmentChecklistItems
            ? [...appointment.appointmentChecklistItems]
            : []
        );
      }
    }
  }, [
    allCheckItems,
    appointment,
    isDrive,
    checkboxesList,
    orderChecklistItems,
  ]);

  const generateChecklist = (itemList, appointment) => {
    let mappedChecklist = [];
    try {
      if (itemList && itemList.length > 0) {
        itemList.forEach((checklistItem) => {
          mappedChecklist.push({
            appointmentChecklistItemsId: checklistItem.checklistItemId,
            appointmentId: appointment?.appointmentId
              ? appointment.appointmentId
              : null,
            checklistItem,
            itemState: false,
            txt_value: "",
            nbr_value: 0,
            hasTxtValue: checklistItem.hasTxtValue,
            hasNbrValue: checklistItem.hasNbrValue,
          });
        });
      }
      return mappedChecklist;
    } catch (mapException) {
      logger.warn("Exception on generateChecklist", mapException, itemList);
      return [];
    }
  };

  const getCurrentHour = () => {
    const currentDate = new Date();
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    return currentDate;
  };

  const {
    APPOINTMENT_STATUS_OUTWARD,
    APPOINTMENT_STATUS_RETURN,
    DRIVES_INFO_TITLE,
    DRIVES_SIGNATURE_HEADER,
    DRIVES_SIGNATURE_SAVE,
    DIALOG_CANCEL_BUTTON_LABEL,
    APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL,
    APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS,
  } = MESSAGE_KEYS;

  const disableInputs = false;

  const UPLOAD = {
    STATUS_FAILED: -1,
    STATUS_NOT_UPLOADED: 0,
    STATUS_GO_UPLOADED: 1,
    STATUS_RETURN_UPLOADED: 2,
  };

  const APPOINTMENT_STATIONS = [
    {
      station: 1,
      stationName: "Luxembourg",
    },
    {
      station: 2,
      stationName: "Ettelbrück",
    },
    {
      station: 3,
      stationName: "Wiltz",
    },
  ];

  const [inputData, setInputData] = useState({
    kilometer: appointment?.kilometer ? appointment.kilometer : "",
    kilometerReturn: appointment?.kilometerReturn
      ? appointment.kilometerReturn
      : "",
    remark: appointment?.remark ? appointment.remark : "",
    transportTypeInvoice: appointment?.transportTypeInvoice,
    signature: null,
    driverUpload:
      appointment.state === UPLOAD.STATUS_RETURN_UPLOADED
        ? UPLOAD.STATUS_GO_UPLOADED
        : UPLOAD.STATUS_RETURN_UPLOADED,
    car: appointment?.car,
    carIdReturn: appointment?.carIdReturn,
    healthInsuranceNumber: appointment?.healthInsuranceNumber
      ? appointment.healthInsuranceNumber
      : null,
    girlName: appointment?.girlName ? appointment.girlName : "",
    firstname: appointment?.firstname ? appointment.firstname : "",
    lastname: appointment?.lastname ? appointment.lastname : "",
    sexId: appointment?.sexId ? appointment.sexId : null,
    titleId: appointment?.titleId ? appointment.titleId : null,
    phoneHome: appointment?.phoneHome ? appointment.phoneHome : "",
    phoneWork: appointment?.phoneWork ? appointment.phoneWork : "",
    customer: appointment?.customer,
    fromAddressKeyword: appointment?.fromAddressKeyword
      ? appointment.fromAddressKeyword
      : "",
    toAddressKeyword: appointment?.toAddressKeyword
      ? appointment.toAddressKeyword
      : "",
    startHour: getCurrentHour(),
    appointmentStatus: null,
    firstDriver: appointment?.firstDriver ? appointment.firstDriver : null,
    firstDriverReturn: appointment?.firstDriverReturn
      ? appointment.firstDriverReturn
      : null,
    secondDriver: appointment?.secondDriver ? appointment.secondDriver : null,
    secondDriverReturn: appointment?.secondDriverReturn
      ? appointment.secondDriverReturn
      : null,
    chosenStation: null,
  });

  const [uploadPending, setUploadPending] = useState(false);

  const [signatureVisible, setSignatureVisible] = useState(false);

  const [validPaper, setValidPaper] = useState(true);
  const [validDischarge, setValidDischarge] = useState(true);

  const isMobile = window.innerWidth <= RESPONSIVE_BREAKPOINT;

  const validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        let prefix = "outward";
        if (appointment.rueckfahrt) {
          prefix = "return";
        }
        const paperLabel = `${prefix}Paper`;
        const dischargeLabel = `${prefix}Discharge`;

        const _validPaper =
          /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
            inputData[paperLabel]
          );
        const _validDischarge =
          /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
            inputData[dischargeLabel]
          );

        setValidDischarge(_validDischarge);
        setValidPaper(_validPaper);

        if (_validPaper && _validDischarge) {
          resolve();
        } else {
          reject();
        }
      } catch (validateException) {
        logger.error(validateException);
        reject();
      }
    });
  };

  const getDriverById = (id) => {
    return drivers.filter((driver) => driver.personId === id)[0];
  };

  const saveTicket = (imgData = null) => {
    if (appointment?.isNew && inputData?.appointmentStatus) {
      appointment.stateName = inputData.appointmentStatus?.name;
      inputData.firstDriver = getDriverById(userId);
    }
    if (!isValidInputs() || (appointment?.isNew && !isValidNewAppointment())) {
      toast.current.show({
        severity: "error",
        summary: intl.formatMessage({
          id: APPOINTMENTS_DRIVER_SAVE_ERROR_TITLE_LABEL,
        }),
        detail: intl.formatMessage({
          id: APPOINTMENTS_DRIVER_SAVE_ERROR_DETAILS,
        }),
      });
      setFormatErrorInput(true);
    } else {
      setFormatErrorInput(false);
      setUploadPending(true);

      setInputData({ ...inputData, signature: imgData });

      let mappedValue = mapInputsToDTO(imgData);

      sendQuery(QUERIES.EDIT_APPOINTMENT, "POST", mappedValue).then(
        (response) => {
          logger.info("Appointment was saved", response, mappedValue);
          const { STATUS_RETURN_UPLOADED, STATUS_GO_UPLOADED } = UPLOAD;
          setUploadPending(false);
          setSignatureVisible(false);
          handleAppointmentFinish(
            {
              ...mappedValue,
              driverUpload:
                appointment.state === STATUS_RETURN_UPLOADED
                  ? STATUS_RETURN_UPLOADED
                  : STATUS_GO_UPLOADED,
            },
            true,
            response
          );
          handleRefresh(userId);
        },
        (error) => {
          logger.warn("Could not save data", error, mappedValue);
          setUploadPending(false);
          setSignatureVisible(false);
          storeFailedUpload({ ...mappedValue, driverUpload: -1 });
          handleAppointmentFinish({ ...mappedValue, driverUpload: -1 }, false);
        }
      );
    }
    /*},
      () => {}
    );*/
  };

  const isValidInputs = () => {
    if (
      (appointment?.stateName === "return journey" &&
        (!inputData?.carIdReturn ||
          !inputData?.car ||
          !inputData?.kilometer ||
          !inputData?.kilometerReturn ||
          inputData?.kilometer < 0 ||
          inputData?.kilometerReturn < 0)) ||
      !inputData?.car ||
      !inputData?.kilometer ||
      inputData?.kilometer < 0
    ) {
      return false;
    }
    return true;
  };

  const isValidNewAppointment = () => {
    if (
      !inputData.appointmentStatus ||
      (!inputData.firstDriver &&
        !inputData.secondDriver &&
        !inputData.firstDriverReturn &&
        !inputData.secondDriverReturn) ||
      !inputData.startHour ||
      !inputData.chosenStation
    ) {
      return false;
    }
    return true;
  };

  const getTimeStringFromDateString = (dateString) => {
    const currentDate = new Date(dateString);
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const prepareNewAppointment = () => {
    appointment.entryCreatedManually = true;
    appointment.active = true;
    if (inputData?.startHour) {
      let splittedHours = getTimeStringFromDateString(
        inputData.startHour
      ).split(":");

      appointment.starttime.setHours(
        parseInt(splittedHours[0]) > 23 ? 23 : parseInt(splittedHours[0])
      );
      //always sum up to the nearest 15 minutes
      appointment.starttime.setMinutes(
        parseInt(splittedHours[1]) > 59
          ? 45
          : Math.round(parseInt(splittedHours[1]) / 15) * 15
      );
      appointment.starttime = dateToISOString(appointment.starttime, false);
      appointment.endtime = dateToISOString(appointment.starttime, false);
    }
    if (inputData?.appointmentStatus) {
      inputData.state = inputData.appointmentStatus?.appointmentStateId;
      inputData.stateName = inputData.appointmentStatus?.name;
    }
    // calendar show requirements
    if (inputData?.car) {
      appointment.transportType =
        inputData.car?.type <= 3 ? inputData.car.type : 2;
    }
    if (inputData?.chosenStation) {
      appointment.station = inputData?.chosenStation?.station;
      appointment.stationName = inputData?.chosenStation?.stationName;
    }

    if (inputData?.sexId) {
      inputData.sexId = inputData.sexId.sexId;
    }
    if (inputData?.titleId) {
      inputData.titleId = inputData.titleId.titleId;
    }
  };

  const mapInputsToDTO = (imgData = null) => {
    if (appointment?.isNew) {
      prepareNewAppointment();
    }

    let nextAppointment = null;
    // Check value of next appointment checkbox.
    try {
      const { appointmentChecklistItemsId, itemState, txt_value } =
        allCheckItems[0];
      if (appointmentChecklistItemsId === null && itemState === true) {
        nextAppointment = dateToQueryString(txt_value, true);
      }
    } catch (nextAppException) {
      logger.error(nextAppException);
      nextAppointment = null;
    }
    const { STATUS_RETURN_UPLOADED, STATUS_GO_UPLOADED } = UPLOAD;

    return {
      ...appointment,
      ...inputData,
      nextAppointment,
      signature: imgData,
      driverUpload:
        appointment.state === STATUS_RETURN_UPLOADED
          ? STATUS_GO_UPLOADED
          : STATUS_RETURN_UPLOADED,
      driverHasRead: 2,
    };
  };

  return (
    <div>
      <Toast ref={toast} />
      <TicketSignature
        isVisible={signatureVisible}
        handleClose={() => setSignatureVisible(false)}
        handleConfirm={saveTicket}
        pending={uploadPending}
      />
      <div className="flex justify-content-between mb-4">
        <img alt="Logo" src={logo} style={{ maxHeight: 60 }} />
        <img alt="Logo" src={logo} style={{ maxHeight: 60 }} />
      </div>
      <TicketDetails
        customerName={appointment?.fullName}
        startTime={appointment?.starttime}
        meetingTime={appointment?.meetingTime}
        inputData={inputData}
        updateParent={setInputData}
        disabled={disableInputs}
        driveType={appointment?.state}
        departure={departure}
        arrival={arrival}
        appointmentTypes={appointmentTypes}
        currentUser={currentUser}
        isNew={appointment?.isNew}
        status={status}
        isMobile={isMobile}
        stations={APPOINTMENT_STATIONS}
      />
      <div className="grid">
        <div className="col-6">
          <TicketDriveInfo
            bColor={formatErrorInput ? "3px solid red" : ""}
            firstDriver={appointment?.firstDriver}
            secondDriver={appointment?.secondDriver}
            carList={cars}
            updateParent={setInputData}
            inputData={inputData}
            disabled={disableInputs}
            isNew={appointment?.isNew}
            drivers={drivers}
            isMobile={isMobile}
            userId={userId}
          />
        </div>
        <div className="col-6">
          <TicketDriveInfo
            bColor={
              formatErrorInput && appointment?.stateName === "return journey"
                ? "3px solid red"
                : ""
            }
            isReturn
            firstDriver={appointment?.firstDriverReturn}
            secondDriver={appointment?.secondDriverReturn}
            carList={cars}
            updateParent={setInputData}
            inputData={inputData}
            disabled={appointment?.isNew}
            isNew={appointment?.isNew}
            drivers={drivers}
            isMobile={isMobile}
          />
        </div>
      </div>
      <Divider />
      <div className="grid">
        <div className={`col-${isMobile ? 12 : 2} ticket_vertical`}>
          <TicketTransport
            inputData={inputData}
            updateParent={setInputData}
            disabled={disableInputs}
          />
        </div>
        <div className={`col-${isMobile ? 5 : 3} ticket_vertical flex`}>
          <TicketTimes
            title={intl.formatMessage({ id: APPOINTMENT_STATUS_OUTWARD })}
            inputData={inputData}
            isDeparture={true}
            updateParent={setInputData}
            appointment={appointment}
            disabled={disableInputs}
            validPaper={validPaper}
            validDischarge={validDischarge}
          />
        </div>
        <div className={`col-${isMobile ? 6 : 3} ticket_vertical`}>
          <TicketTimes
            title={intl.formatMessage({ id: APPOINTMENT_STATUS_RETURN })}
            inputData={inputData}
            isDeparture={false}
            updateParent={setInputData}
            appointment={appointment}
            disabled={disableInputs || appointment?.isNew}
            validPaper={validPaper}
            validDischarge={validDischarge}
          />
        </div>
        <div
          className={`col-${
            isMobile ? 12 : 4
          } flex align-items-end ticket_vertical`}
        >
          <TicketCheckboxes
            checklist={allCheckItems}
            groups={["transport"]}
            disabled={disableInputs}
          />
          <TicketCheckboxes
            checklist={allCheckItems}
            groups={["transport2"]}
            disabled={disableInputs}
          />
        </div>
      </div>

      <div className="ticket_column_title">
        {intl.formatMessage({ id: DRIVES_INFO_TITLE })}
      </div>
      <Divider />
      <div className="ticket_vertical">
        <TicketCheckboxes
          checklist={allCheckItems}
          groups={["info", "material", "desinfektion"]}
          numberColumns={isMobile ? 2 : 4}
          disabled={disableInputs}
        />
      </div>
      <div className="flex justify-content-between">
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={handleCancel}
          className="m-mr-2 p-button-danger"
        />
        <div>
          {!disableInputs && (
            <Button
              label={intl.formatMessage({ id: DRIVES_SIGNATURE_HEADER })}
              onClick={() => setSignatureVisible(true)}
              className="mr-2"
            />
          )}

          {!disableInputs && (
            <Button
              label={intl.formatMessage({ id: DRIVES_SIGNATURE_SAVE })}
              onClick={() => saveTicket(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  try {
    return { userId: state.mobile.userId };
  } catch (mapException) {
    logger.error(mapException);
    return { userId: null };
  }
};

export default connect(mapStatesToProps, {})(TransportTicket);
