import React from "react";
// Styling
import "./Style.scss";
// PrimeReact components
import { Skeleton } from "primereact/skeleton";

const DriverStatusLoading = () => {
  return (
    <>
      <Skeleton size="15rem"></Skeleton>
      <Skeleton size="15rem"></Skeleton>
      <Skeleton size="15rem"></Skeleton>
      <Skeleton size="15rem"></Skeleton>
      <Skeleton size="15rem"></Skeleton>
    </>
  );
};

export default DriverStatusLoading;
