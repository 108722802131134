/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Styling
import "./Style.scss";

const DEFAULT_STATE = {
  inputDescription: "",
  inputAmount: 0,
  inputQuantity: 1,
  inputPreset: null,

  validDescription: null,
  validQuantity: null,
};

class AddPositionDialog extends React.Component {
  state = {
    ...DEFAULT_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { visible } = this.props;
    if (prevProps.visible !== visible && visible === true) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value.transactionDetailsId >= 0) {
      const { amount, price, productName, product } = value;
      this.setState({
        inputAmount: price,
        inputQuantity: amount,
        inputDescription: productName,
        inputPreset: product ? product : null,
        validDescription: null,
        validQuantity: null,
      });
    } else {
      this.resetState();
    }
  };

  /** Check if required inputs have values & add new address if true. Display error messages if false. */
  validateInput = () => {
    const { inputAmount, inputDescription, inputQuantity, inputPreset } =
      this.state;

    let validDescription = inputDescription.length > 0;
    let validQuantity = inputQuantity >= 0;
    this.setState(
      {
        validQuantity,
        validDescription,
      },
      () => {
        // Using state variables for this check will require to clicks to create the element, as hooks are not updated instantly.
        if (validDescription && validQuantity) {
          this.props.handleAdd({
            inputDescription,
            inputAmount,
            inputQuantity,
            inputPreset,
          });
          this.resetState();
        }
      }
    );
  };

  handlePresetChange = (preset) => {
    if (preset) {
      const { price, productName } = preset;
      this.setState({
        inputAmount: price,
        inputDescription: productName,
        inputQuantity: 1,
        inputPreset: preset,
      });
    }
  };

  /** Reset state variables. */
  resetState = () => {
    this.setState({
      ...DEFAULT_STATE,
    });
  };

  footer = () => {
    const { onHide, intl, value } = this.props;
    const {
      BILLS_ADD_POSITION_BUTTON_LABEL,
      BILLS_EDIT_POSITION_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
    } = MESSAGE_KEYS;

    return (
      <div className="flex justify-content-between mb-2">
        <Button
          label={intl.formatMessage({
            id:
              value.transactionDetailsId > 0 ||
              value.transactionDetailsId === null
                ? BILLS_EDIT_POSITION_BUTTON_LABEL
                : BILLS_ADD_POSITION_BUTTON_LABEL,
          })}
          onClick={this.validateInput}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
        />
      </div>
    );
  };

  render = () => {
    const { onHide, visible, intl, value, presets } = this.props;
    const {
      BILLS_POSITIONS_DESCRIPTION_LABEL,
      BILLS_POSITIONS_QUANTITY_LABEL,
      BILLS_POSITIONS_AMOUNT_LABEL,
      BILLS_ADD_POSITION_DIALOG_TITLE,
      BILLS_EDIT_POSITION_DIALOG_TITLE,
      BILLS_POSITIONS_PRESET_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputAmount,
      inputDescription,
      inputQuantity,
      inputPreset,
      validDescription,
      validQuantity,
    } = this.state;
    return (
      <Dialog
        header={intl.formatMessage({
          id: value
            ? BILLS_EDIT_POSITION_DIALOG_TITLE
            : BILLS_ADD_POSITION_DIALOG_TITLE,
        })}
        footer={this.footer()}
        visible={visible}
        onHide={() => {
          this.resetState();
          onHide();
        }}
        style={{ maxWidth: "500px" }}
      >
        <div className="m-2">
          <div className="p-fluid formgrid grid">
            <div className={`col-12 mb-1`}>
              <Dropdown
                value={inputPreset}
                options={presets}
                optionLabel="productName"
                placeholder={intl.formatMessage({
                  id: BILLS_POSITIONS_PRESET_LABEL,
                })}
                onChange={(e) => this.handlePresetChange(e.value)}
                filter                
                showClear
                filterBy="productName"
                appendTo={document.body} // Use this to allow the combobox dropdown to be larger than the dialog.
                filterInputAutoFocus={true}
              />
            </div>

            <div className={`col-12 mb-4`}>
              <FloatingTextInput
                id={BILLS_POSITIONS_DESCRIPTION_LABEL}
                value={inputDescription}
                onChange={(event) =>
                  this.setState({ inputDescription: event.target.value })
                }
                label={intl.formatMessage({
                  id: BILLS_POSITIONS_DESCRIPTION_LABEL,
                })}
                valid={validDescription}
              />
            </div>

            <div className={`col-6 p-field`}>
              <span className="p-float-label">
                <InputNumber
                  id={BILLS_POSITIONS_QUANTITY_LABEL}
                  className={`${
                    validQuantity === true || validQuantity === null
                      ? ""
                      : "p-invalid"
                  }`}
                  value={inputQuantity}
                  onValueChange={(event) =>
                    this.setState({ inputQuantity: event.target.value })
                  }
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  onFocus={(e) => e.target.select()}
                  useGrouping={false}
                  locale="en-US"
                />
                <label htmlFor={BILLS_POSITIONS_QUANTITY_LABEL}>
                  {intl.formatMessage({ id: BILLS_POSITIONS_QUANTITY_LABEL })}
                </label>
              </span>
            </div>
            <div className="col-6 flex align-items-center">
              <span className="p-float-label">
                <InputNumber
                  id={BILLS_POSITIONS_AMOUNT_LABEL}
                  value={inputAmount}
                  onValueChange={(event) =>
                    this.setState({ inputAmount: event.target.value })
                  }
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  onFocus={(e) => e.target.select()}
                  useGrouping={false}
                  locale="en-US"
                />
                <label htmlFor={BILLS_POSITIONS_AMOUNT_LABEL}>
                  {intl.formatMessage({ id: BILLS_POSITIONS_AMOUNT_LABEL })}
                </label>
              </span>
              <span className="ml-2">€</span>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };
}

export default injectIntl(AddPositionDialog);
