import React from "react";
// PrimeReact Components
import { SelectButton } from "primereact/selectbutton";
// Static data
import { LOCALES } from "assets/staticData/enums";
// Helper functions
import { initLogger } from "common/Helpers";

const LanguageSelector = ({ value, setValue }) => {
  try {
    let dropdownValues = [];
    let keys = Object.keys(LOCALES);
    keys.forEach((languageKey) => {
      dropdownValues.push({
        label: LOCALES[languageKey].key,
        value: LOCALES[languageKey].languageId,
      });
    });
    return (
      <div className="locale_select mt-4">
        <SelectButton
          value={value}
          onChange={(event) => {
            setValue(event.value);
          }}
          options={dropdownValues}
          optionLabel="label"
        />
      </div>
    );
  } catch (renderException) {
    const logger = initLogger("LanguageSelector");
    logger.error(renderException);
    return <></>;
  }
};

export default LanguageSelector;
