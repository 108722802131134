import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faElevator,
  faWheelchair,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faDroplet,
  faBoltLightning,
  faBusSimple,
  faSyringe,
  faTriangleExclamation,
  faArrowRightArrowLeft,
  faClock,
  faFaceMask,
  faWeightScale,
  faPager,
  faPumpMedical,
  faCheck,
  faCheckDouble,
  faCalendarCheck,
  faStairs,
} from "@fortawesome/pro-solid-svg-icons";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

// Helper functions
import { dateToString } from "common/Helpers";

const TooltipIcons = ({
  stayByPatient,
  vaccinated,
  bus,
  elevator,
  wheelchair,
  dsa,
  oxygen,
  oxyliter,
  roundtrip,
  corona,
  remark,
  infusion,
  isolated,
  weight,
  overweighted,
  stairCount,
  stairs,

  syringepump,
  disinfectionTxt,
  disinfection,

  driverHasRead,
  nextAppointment,
}) => {
  const intl = useIntl();
  const {
    APPOINTMENTS_PATIENT_STAY_LABEL,
    APPOINTMENTS_VACCINATED_LABEL,
    BILLS_ELEVATOR_LABEL,
    BILLS_WHEELCHAIR_LABEL,
    APPOINTMENTS_BUS_LABEL,
    APPOINTMENTS_DSA_LABEL,
    APPOINTMENTS_ROUND_TRIP_LABEL,
    DIALOG_CONFIRM_BUTTON_LABEL,
    APPOINTMENTS_OXYGEN_LABEL,
    APPOINTMENTS_CORONA_LABEL,
    APPOINTMENTS_INFUSION_LABEL,
    APPOINTMENTS_ISOLATION_LABEL,
    BILLS_IS_OVERWEIGHT_LABEL,
    APPOINTMENTS_DESINFECTION_LABEL,
    APPOINTMENTS_PERFUSOR_LABEL,
    APPOINTMENTS_DRIVER_MESSAGE,
    APPOINTMENTS_DRIVER_MESSAGE_SENT,
    APPOINTMENTS_DRIVER_MESSAGE_RECEIVED,
    APPOINTMENTS_DRIVER_MESSAGE_READ,
    APPOINTMENTS_NEXT_HEADER,
    APPOINTMENTS_STAIRS_COUNT,
  } = MESSAGE_KEYS;

  const hasIcon =
    stayByPatient ||
    vaccinated ||
    bus ||
    elevator ||
    wheelchair ||
    dsa ||
    oxygen ||
    oxyliter ||
    roundtrip ||
    corona ||
    remark ||
    infusion ||
    isolated ||
    weight ||
    stairCount ||
    (driverHasRead ?? false);

  const renderTextIconRow = (icon, label, value, color = "yellow") => {
    if (value) {
      let valueLabel = value;
      if (typeof value === "boolean") {
        valueLabel = intl.formatMessage({
          id: DIALOG_CONFIRM_BUTTON_LABEL,
        });
      }

      return (
        <div className="flex mb-1">
          <div className="tooltip_icon_container">
            <FontAwesomeIcon icon={icon} color={color} />
          </div>
          <strong className="ml-3 mr-1">
            {intl.formatMessage({
              id: label,
            })}
            :
          </strong>
          {valueLabel}
        </div>
      );
    } else {
      return null;
    }
  };

  let readColor;
  let readIcon;
  let readValue;

  switch (driverHasRead) {
    case 2:
      readIcon = faCheckDouble;
      readValue = APPOINTMENTS_DRIVER_MESSAGE_READ;
      readColor = "LightSkyBlue";
      break;
    case 1:
      readIcon = faCheckDouble;
      readValue = APPOINTMENTS_DRIVER_MESSAGE_RECEIVED;
      readColor = "grey";
      break;
    default:
      readIcon = faCheck;
      readValue = APPOINTMENTS_DRIVER_MESSAGE_SENT;
      readColor = "grey";
      break;
  }

  return (
    <div>
      {renderTextIconRow(
        faClock,
        APPOINTMENTS_PATIENT_STAY_LABEL,
        stayByPatient
      )}
      {renderTextIconRow(faSyringe, APPOINTMENTS_VACCINATED_LABEL, vaccinated)}
      {renderTextIconRow(faBusSimple, APPOINTMENTS_BUS_LABEL, bus)}
      {renderTextIconRow(faElevator, BILLS_ELEVATOR_LABEL, elevator)}
      {renderTextIconRow(faWheelchair, BILLS_WHEELCHAIR_LABEL, wheelchair)}
      {renderTextIconRow(faBoltLightning, APPOINTMENTS_DSA_LABEL, dsa)}
      {renderTextIconRow(
        faArrowRightArrowLeft,
        APPOINTMENTS_ROUND_TRIP_LABEL,
        roundtrip
      )}
      {oxygen && (
        <div className="flex mb-1">
          <div style={{ color: "yellow" }} className="tooltip_icon_container">
            <strong>
              O<sup>2</sup>
            </strong>
          </div>
          <div className="ml-3 mr-1">
            <strong>
              {intl.formatMessage({
                id: APPOINTMENTS_OXYGEN_LABEL,
              })}
              :
            </strong>
            {oxyliter ? ` ${oxyliter}l` : ""}
          </div>
        </div>
      )}
      {renderTextIconRow(faFaceMask, APPOINTMENTS_CORONA_LABEL, corona)}
      {remark && (
        <div className="flex mb-1">
          <div className="tooltip_icon_container">
            <FontAwesomeIcon icon={faCircleInfo} color="yellow" />
          </div>
          <div className="ml-3 mr-1">{remark}</div>
        </div>
      )}
      {renderTextIconRow(faDroplet, APPOINTMENTS_INFUSION_LABEL, infusion)}
      {renderTextIconRow(
        faTriangleExclamation,
        APPOINTMENTS_ISOLATION_LABEL,
        isolated
      )}
      {overweighted &&
        renderTextIconRow(
          faWeightScale,
          BILLS_IS_OVERWEIGHT_LABEL,
          `${weight ?? 0}kg`
        )}        
      {stairs &&
        renderTextIconRow(faStairs, APPOINTMENTS_STAIRS_COUNT, stairCount)}
      {renderTextIconRow(faPager, APPOINTMENTS_PERFUSOR_LABEL, syringepump)}
      {disinfection &&
        renderTextIconRow(
          faPumpMedical,
          APPOINTMENTS_DESINFECTION_LABEL,
          disinfectionTxt
        )}
      {driverHasRead !== undefined &&
        renderTextIconRow(
          readIcon,
          APPOINTMENTS_DRIVER_MESSAGE,
          intl.formatMessage({ id: readValue }),
          readColor
        )}
      {nextAppointment &&
        renderTextIconRow(
          faCalendarCheck,
          APPOINTMENTS_NEXT_HEADER,
          dateToString(nextAppointment)
        )}
      {hasIcon === true && <Divider />}
    </div>
  );
};

export default TooltipIcons;
