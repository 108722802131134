/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
// Tippy tooltip
import "tippy.js/dist/tippy.css";
// Custom components
import {
  CustomerSelector,
  BillSelector,
  FloatingTextInput,
  SplitDateTimeInput,
} from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { dateToQueryString, valiDate } from "common/Helpers";
// Styling
import "./Style.scss";

const INITIAL_STATE = {
  inputFreeAddress: "",
  inputFreePayment: "",
  inputFreeCustomer: "",
  inputFreeBill: "",
  inputPaymentFromDate: undefined,
  inputPaymentToDate: undefined,

  inputBillFromDate: undefined,
  inputBillToDate: undefined,
  inputMinAmount: null,
  inputMaxAmount: null,
  inputCustomer: null,
  inputBill: null,
};

class PaymentFilterLayout extends React.Component {
  state = {
    ...INITIAL_STATE,
  };

  /** Reset state variables. */
  resetState = () => {
    this.setState({
      ...INITIAL_STATE,
    });
    this.props.handleSearch(this.mapState());
  };
  /** Call search function if enter key is pressed. */
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.props.handleSearch(this.mapState());
    }
  };

  mapState = (isReset = false) => {
    const {
      inputFreeAddress,
      inputFreeBill,
      inputFreeCustomer,
      inputFreePayment,
      inputPaymentFromDate,
      inputPaymentToDate,

      inputBillFromDate,
      inputBillToDate,
      inputMinAmount,
      inputMaxAmount,
      inputCustomer,
      inputBill,
    } = isReset ? INITIAL_STATE : this.state;

    return {
      address: inputFreeAddress,
      payment: inputFreePayment,
      customer: inputFreeCustomer,
      invoice: inputFreeBill,
      paymentFromdate: inputPaymentFromDate
        ? dateToQueryString(inputPaymentFromDate)
        : null,
      paymentTodate: inputPaymentToDate
        ? dateToQueryString(inputPaymentToDate)
        : null,
      transactionFromdate: inputBillFromDate
        ? dateToQueryString(inputBillFromDate)
        : null,
      transactionTodate: inputBillToDate
        ? dateToQueryString(inputBillToDate)
        : null,
      customerId: inputCustomer ? inputCustomer.personId : null,
      transactionId: inputBill ? inputBill.transactionId : null,
      min: inputMinAmount,
      max: inputMaxAmount,
    };
  };

  render = () => {
    const { isPending, handleSearch, intl } = this.props;
    const {
      inputFreeAddress,
      inputFreeBill,
      inputFreeCustomer,
      inputFreePayment,
      inputPaymentFromDate,
      inputPaymentToDate,

      inputBillFromDate,
      inputBillToDate,
      inputMinAmount,
      inputMaxAmount,
      inputCustomer,
      inputBill,
    } = this.state;
    const {
      PAYMENTS_FILTER_MIN_AMOUNT_LABEL,
      PAYMENTS_FILTER_MAX_AMOUNT_LABEL,
      PAYMENTS_FILTER_FROM_DATE_LABEL,
      PAYMENTS_FILTER_TO_DATE_LABEL,
      PAYMENTS_FILTER_ADDRESS_KEY_LABEL,
      PAYMENTS_FILTER_PAYMENT_KEY_LABEL,
      PAYMENTS_FILTER_CUSTOMER_KEY_LABEL,
      PAYMENTS_FILTER_BILL_KEY_LABEL,
      PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL,
      PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL,
      DIALOG_SEARCH_BUTTON_LABEL,
      DIALOG_CLEAR_FILTER_LABEL,
    } = MESSAGE_KEYS;

    let buttonClass = isPending ? "pi pi-spin pi-spinner" : "pi pi-search";

    const renderCalendars = () => {
      return [
        <div
          className={`mt-4 flex ${
            isDesktop ? "justify-content-between" : "flex-column"
          }`}
          key="pmt_flt_bill_cals"
        >
          <SplitDateTimeInput
            value={inputBillFromDate}
            onChange={(e) => {
              this.setState({
                inputBillFromDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({ id: PAYMENTS_FILTER_FROM_DATE_LABEL })}
            className="mr-2"
          />

          <SplitDateTimeInput
            value={inputBillToDate}
            onChange={(e) => {
              this.setState({
                inputBillToDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({ id: PAYMENTS_FILTER_TO_DATE_LABEL })}
          />
        </div>,

        <div
          className={`mt-4 flex ${
            isDesktop ? "justify-content-between" : "flex-column"
          }`}
          key="pmt_flt_pmt_cals"
        >
          <SplitDateTimeInput
            value={inputPaymentFromDate}
            onChange={(e) => {
              this.setState({
                inputPaymentFromDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({
              id: PAYMENTS_FILTER_PAYMENT_FROM_DATE_LABEL,
            })}
            className="mr-2"
          />

          <SplitDateTimeInput
            value={inputPaymentToDate}
            onChange={(e) => {
              this.setState({
                inputPaymentToDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({
              id: PAYMENTS_FILTER_PAYMENT_TO_DATE_LABEL,
            })}
          />
        </div>,
      ];
    };

    return (
      <div>
        <div>
          <div
            className={`payment_filter_row flex ${
              isDesktop ? "justify-content-space-between" : "flex-column"
            }`}
          >
            <FloatingTextInput
              id={PAYMENTS_FILTER_ADDRESS_KEY_LABEL}
              value={inputFreeAddress}
              onChange={(event) =>
                this.setState({ inputFreeAddress: event.target.value })
              }
              label={intl.formatMessage({
                id: PAYMENTS_FILTER_ADDRESS_KEY_LABEL,
              })}
              onKeyDown={this.handleKeyDown}
              className={`p-float-label ${isDesktop ? "mr-2" : ""}`}
              autoFocus
            />

            <FloatingTextInput
              id={PAYMENTS_FILTER_BILL_KEY_LABEL}
              value={inputFreeBill}
              onChange={(event) =>
                this.setState({ inputFreeBill: event.target.value })
              }
              label={intl.formatMessage({ id: PAYMENTS_FILTER_BILL_KEY_LABEL })}
              onKeyDown={this.handleKeyDown}
            />
          </div>

          <div
            className={`payment_filter_row flex ${
              isDesktop ? "justify-content-space-between" : "flex-column"
            }`}
          >
            <FloatingTextInput
              id={PAYMENTS_FILTER_CUSTOMER_KEY_LABEL}
              value={inputFreeCustomer}
              onChange={(event) =>
                this.setState({ inputFreeCustomer: event.target.value })
              }
              label={intl.formatMessage({
                id: PAYMENTS_FILTER_CUSTOMER_KEY_LABEL,
              })}
              onKeyDown={this.handleKeyDown}
              className={`p-float-label ${isDesktop ? "mr-2" : ""}`}
            />

            <FloatingTextInput
              id={PAYMENTS_FILTER_PAYMENT_KEY_LABEL}
              value={inputFreePayment}
              onChange={(event) =>
                this.setState({ inputFreePayment: event.target.value })
              }
              label={intl.formatMessage({
                id: PAYMENTS_FILTER_PAYMENT_KEY_LABEL,
              })}
              onKeyDown={this.handleKeyDown}
            />
          </div>

          <div className="payment_filter_row">
            <span className={`p-float-label ${isDesktop ? "mr-2" : ""}`}>
              <InputNumber
                id={PAYMENTS_FILTER_MIN_AMOUNT_LABEL}
                value={inputMinAmount}
                onValueChange={(event) =>
                  this.setState({ inputMinAmount: event.target.value })
                }
                suffix=" €"
                onKeyDown={this.handleKeyDown}
                onFocus={(e) => e.target.select()}
                useGrouping={false}
                locale="en-US"
              />
              <label htmlFor={PAYMENTS_FILTER_MIN_AMOUNT_LABEL}>
                {intl.formatMessage({ id: PAYMENTS_FILTER_MIN_AMOUNT_LABEL })}
              </label>
            </span>

            <span className="p-float-label">
              <InputNumber
                id={PAYMENTS_FILTER_MAX_AMOUNT_LABEL}
                value={inputMaxAmount}
                onValueChange={(event) =>
                  this.setState({ inputMaxAmount: event.target.value })
                }
                suffix=" €"
                onKeyDown={this.handleKeyDown}
                onFocus={(e) => e.target.select()}
                useGrouping={false}
                locale="en-US"
              />
              <label htmlFor={PAYMENTS_FILTER_MAX_AMOUNT_LABEL}>
                {intl.formatMessage({ id: PAYMENTS_FILTER_MAX_AMOUNT_LABEL })}
              </label>
            </span>
          </div>

          {renderCalendars()}

          <div className="payment_filter_row">
            <CustomerSelector
              value={inputCustomer}
              onChange={(selection) =>
                this.setState({ inputCustomer: selection })
              }
            />
          </div>
          <div className="payment_filter_row">
            <BillSelector
              value={inputBill}
              onChange={(selection) => this.setState({ inputBill: selection })}
            />
          </div>
        </div>
        <div className="customer_filter_button_row">
          <Button
            icon={buttonClass}
            label={intl.formatMessage({
              id: DIALOG_SEARCH_BUTTON_LABEL,
            })}
            onClick={() => handleSearch(this.mapState())}
          />
          <Button
            label={intl.formatMessage({
              id: DIALOG_CLEAR_FILTER_LABEL,
            })}
            onClick={() => {
              this.resetState();
            }}
            disabled={isPending}
          />
        </div>
      </div>
    );
  };
}

export default injectIntl(PaymentFilterLayout);
