import React from "react";
// PrimeReact components
import { Toast } from "primereact/toast";
// Localization
import { injectIntl } from "react-intl";
// Custom components
import { PaymentEditorLayout } from "./components";
// Helper functions
import {
  filterActive,
  initLogger,
  safeRender,
  sendQuery,
  equalArrays,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";

const EMPTY_STATE = {
  inputPayments: [],
  updatePending: false,
};
const logger = initLogger("payment_editor");

class PaymentEditor extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { invoiceList = [] } = this.props;
    if (!equalArrays(invoiceList, prevProps.invoiceList, "paymentId")) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { invoiceList = [] } = this.props;
    if (invoiceList) {
      this.setState({
        inputPayments: [...filterActive(invoiceList)],
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  handlePaymentDelete = (index) => {
    const { handleParentDelete, intl, selectedCustomer } = this.props;
    let inputPayments = [...this.state.inputPayments];
    let removedPayment = inputPayments[index];
    /* CHANGES: selectCustomer not set in removedPayment object */
    if (removedPayment.invoiceCustomer === null) {
      logger.warn("invoice customer is null, using fallback way... ");
      if (selectedCustomer !== null) {
        removedPayment.invoiceCustomer = {
          personId: this.props.selectedCustomer,
        };
        logger.warn(
          "successfully added selectedCustomer in removedPayment object!"
        );
      } else {
        logger.warn("Selected customer not set");
        this.toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.PAYMENT_DELETE_FAILURE_MESSAGE,
          }),
        });
      }
    }

    removedPayment.active = false;
    this.setState({
      updatePending: true,
    });
    sendQuery(QUERIES.EDIT_PAYMENT, "POST", removedPayment).then(
      (response) => {
        if (response && response.paymentId) {
          inputPayments.splice(index, 1);
          this.setState({
            inputPayments,
            updatePending: false,
          });
          if (handleParentDelete) {
            handleParentDelete(response.paymentId);
          }
        } else {
          logger.warn("COULD NOT DELETE FILE");
          this.toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.PAYMENT_DELETE_FAILURE_MESSAGE,
            }),
          });
          this.setState({
            updatePending: false,
          });
        }
      },
      (error) => {
        logger.warn(error);
        this.toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.PAYMENT_DELETE_FAILURE_MESSAGE,
          }),
        });
        this.setState({
          updatePending: false,
        });
      }
    );
  };

  handlePaymentUpdate = (newData, index) => {
    const { handleParentUpdate } = this.props;
    let newPayments = [...this.state.inputPayments];
    if (index !== null) {
      newPayments[index] = newData;
    } else {
      newPayments.push(newData);
    }
    this.setState({
      inputPayments: [...newPayments],
    });
    if (handleParentUpdate) {
      handleParentUpdate(newPayments);
    }
  };

  renderContent = () => {
    const { inputPayments } = this.state;
    const { selectedBill, selectedCustomer, fetchPending, totalAmount } =
      this.props;
    return (
      <PaymentEditorLayout
        value={inputPayments}
        updatePayment={this.handlePaymentUpdate}
        deletePayment={this.handlePaymentDelete}
        selectedBill={selectedBill}
        selectedCustomer={selectedCustomer}
        totalAmount={totalAmount}
        fetchPending={fetchPending}
      />
    );
  };

  render = () => {
    const { intl } = this.props;
    const { ERROR_RENDER } = MESSAGE_KEYS;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        {safeRender(
          this.renderContent,
          intl.formatMessage({ id: ERROR_RENDER })
        )}
      </div>
    );
  };
}

export default injectIntl(PaymentEditor);
