import {
  WIZARD_INIT,
  WIZARD_RESET,
  WIZARD_SET_VALUE,
  WIZARD_UPDATE_DRIVE,
  WIZARD_UPDATE_FROM_ADDRESS,
  WIZARD_UPDATE_TO_ADDRESS,
} from "actions/types";

const INITIAL_STATE = {
  appointmentCustomer: {},
  appointmentDeparture: {
    keyword: "",
    address: "",
    room: "",
    department: "",
    text: "",
  },
  appointmentCustomerAddress: {},
  appointmentDestination: {
    keyword: "",
    address: "",
    room: "",
    department: "",
    text: "",
  },
  appointmentDrive: {
    starttime: null,
    station: null,
    transportType: null,
    transportTypeLine: null,
    state: null,
    stateName: "",
    description: "",
    appointmentChecklistItems: [],
    car: null,    
    transportTypeName: "",
    firstDriver: null,
    secondDriver: null,
    stationName: "",
    transportTypeLineName: "",
    firstDriverReturn: null,
    secondDriverReturn: null,
    carIdReturn: null,
    nextAppointment: null,
    appointmentId: null,
    kilometer: 0,
    showDescription: false,
    isolated: false,
  },
  appointmentRepeating: [],
  resetCustomer: null,
  resetCustomerAddress: null,
};

const appointmentReducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case WIZARD_RESET:
      return { ...INITIAL_STATE };
    case WIZARD_INIT:      
      return { ...payload };
    case WIZARD_SET_VALUE:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case WIZARD_UPDATE_DRIVE:
      return {
        ...state,
        appointmentDrive: {
          ...state.appointmentDrive,
          [payload.key]: payload.value,
        },
      };
    case WIZARD_UPDATE_FROM_ADDRESS:
      return {
        ...state,
        appointmentDeparture: {
          ...state.appointmentDeparture,
          [payload.key]: payload.value,
        },
      };
    case WIZARD_UPDATE_TO_ADDRESS:
      return {
        ...state,
        appointmentDestination: {
          ...state.appointmentDestination,
          [payload.key]: payload.value,
        },
      };
    default:
      return { ...state };
  }
};

export default appointmentReducer;
