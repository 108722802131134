import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Checkbox } from "primereact/checkbox";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faCross } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Static values
import {
  MESSAGE_KEYS,
  PAGE_LINK_SIZES,
  EMPTY_FILTER_QUERY,
  ROWS_PER_PAGE,
} from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
import {
  dateToBillNumber,
  dateToString,
  numberToPriceString,
} from "common/Helpers";
import { BILL_STATUS } from "assets/staticData/combodata";
import BillFilterToolTip from "./BillFilterToolTip";

const COLUMN_WIDTHS = {
  CHECK_BOX: 32,
  DATE: 115,
  BILL_NUMBER: 75,
  PRICE: 75,
  CUSTOMER: "35%",
  BILL_ADDRESS: "59%",
};

class BillFilterTable extends React.PureComponent {
  state = {
    first: 0,
    rows: isDesktop ? 10 : 5,
    rowsPerPage: ROWS_PER_PAGE,
    displayedBills: [],
    totalBills: 0,
    currentPage: 0,
    selectedBill: null,
    fetchPending: false,
    fetchError: null,
    billFetchPending: false,
    billFetchError: null,
    queryString: EMPTY_FILTER_QUERY,
    selectedRow: null,

    skipFetch: false,

    selectionPaymentPending: false,
    selectionBillsPending: false,
    selectionReminderPending: false,
    allReminderPending: false,
    selectionStatusPending: false,

    totalPaid: 0,
    totalAmount: 0,

    multiDialogVisible: false,
    multiStatusDialogVisible: false,
  };

  /**
   * Gets called when the user changes the table page or number of rows displayed.
   * This function updates the table control values of the state and calls the fetchBills function to update the displayed rows.
   *
   * @param {Object} event
   */
  handlePageChange = (event) => {
    const { first, rows, page } = event;
    this.setState(
      {
        first,
        rows,
        currentPage: page,
      },
      () => {
        this.props.filterBills(page, rows);
      }
    );
  };

  selectAllCellTemplate = () => {
    const { selectedBills, displayedBills, handleAllCheckboxSelection } =
      this.props;

    let allSelected = true;

    displayedBills.forEach((bill) => {
      if (
        allSelected &&
        selectedBills &&
        selectedBills.findIndex((selection) => {
          return selection.transactionId === bill.transactionId;
        }) === -1
      ) {
        allSelected = false;
      }
    });

    return (
      <div>
        <Checkbox
          checked={allSelected}
          onChange={(e) => handleAllCheckboxSelection(e.checked)}
        />
      </div>
    );
  };

  selectionCellTemplate = (rowData) => {
    const { transactionId } = rowData;
    const { selectedBills, handleCheckboxSelection } = this.props;
    let entryIndex = selectedBills
      ? selectedBills.findIndex((bill) => {
          return bill.transactionId === transactionId;
        })
      : 0;
    return (
      <div>
        <Checkbox
          checked={entryIndex >= 0}
          onChange={(e) => {
            handleCheckboxSelection(rowData, e.checked);
          }}
        />
      </div>
    );
  };

  billCellTemplate = (rowData) => {
    const { BILL_NUMBER, CHECK_BOX, DATE, PRICE, CUSTOMER, BILL_ADDRESS } =
      COLUMN_WIDTHS;
    const {
      name,
      customerName,
      orderDate,
      totalPrice,
      totalPaidReal,
      invoiceNumber,
      isDead,
      hasFilearchive,
    } = rowData;

    let billStatus = BILL_STATUS.find((status) => {
      return status.billStatusId === rowData.state;
    });
    if (!billStatus) {
      billStatus = BILL_STATUS[0];
    }
    library.add(fas, faCross);

    return (
      <Tippy
        content={<BillFilterToolTip rowData={rowData} />}
        followCursor="horizontal"
        plugins={[followCursor]}
      >
        <div
          className="flex p-2"
          style={hasFilearchive ? {} : { background: "#ffcbcb" }}
        >
          <div style={{ minWidth: CHECK_BOX }}>
            {this.selectionCellTemplate(rowData)}
          </div>
          <div style={{ minWidth: DATE }}>
            <i
              className={`${billStatus.icon} mr-2`}
              style={{ color: billStatus.color }}
            />
            {dateToString(orderDate)}
          </div>
          <div
            className="text-right mr-2"
            style={{ minWidth: BILL_NUMBER }}
          >{`${invoiceNumber}/${dateToBillNumber(orderDate)}`}</div>
          <div className="ml-1" style={{ width: CUSTOMER }}>
            {isDead ? (
              <FontAwesomeIcon icon={["fas", "cross"]} className="mr-2" />
            ) : (
              <></>
            )}
            {customerName}
          </div>
          <div className="ml-1" style={{ width: BILL_ADDRESS }}>
            {name}
          </div>
          <div className="ml-1" style={{ minWidth: CHECK_BOX }}>
            {rowData?.peppolParticipantExists === true && (
              <img
                src="assets/layout/images/peppol-icon-alone.png"
                alt="peppol icon"
                className="peppol-icon"
                title="Peppol"
                height={"35px"}
              />
            )}
          </div>
          <div className="text-right" style={{ minWidth: PRICE }}>
            {numberToPriceString(totalPrice)}
          </div>
          <div className="text-right" style={{ minWidth: PRICE }}>
            {numberToPriceString(totalPrice - totalPaidReal)}
          </div>
        </div>
      </Tippy>
    );
  };

  billHeaderTemplate = () => {
    const { BILL_NUMBER, CHECK_BOX, DATE, PRICE, CUSTOMER, BILL_ADDRESS } =
      COLUMN_WIDTHS;
    const { intl } = this.props;
    const {
      BILLS_AMOUNT_DUE_LABEL,
      BILLS_AMOUNT_PAID_LABEL,
      BILLS_CUSTOMER_LABEL,
      BILLS_NUMBER_LABEL,
      BILLS_FILTER_ADDRESS_LABEL,
    } = MESSAGE_KEYS;
    return (
      <div className="flex mb-1 mt-1">
        <div style={{ minWidth: CHECK_BOX }}>
          {this.selectAllCellTemplate()}
        </div>
        <div style={{ minWidth: DATE }}></div>
        <div className="text-right mr-2" style={{ minWidth: BILL_NUMBER }}>
          {intl.formatMessage({ id: BILLS_NUMBER_LABEL })}
        </div>
        <div className="ml-1" style={{ width: CUSTOMER }}>
          {intl.formatMessage({ id: BILLS_CUSTOMER_LABEL })}
        </div>
        <div className="ml-1" style={{ width: BILL_ADDRESS }}>
          {intl.formatMessage({ id: BILLS_FILTER_ADDRESS_LABEL })}
        </div>
        <div className="text-right" style={{ minWidth: PRICE }}>
          {intl.formatMessage({ id: BILLS_AMOUNT_DUE_LABEL })}
        </div>
        <div className="text-right" style={{ minWidth: PRICE }}>
          {intl.formatMessage({ id: BILLS_AMOUNT_PAID_LABEL })}
        </div>
      </div>
    );
  };

  renderSearchTableFooter = () => {
    const { intl, totalAmount, totalPaid } = this.props;
    const { BILLS_TOTAL_AMOUNT_LABEL, BILLS_TOTAL_PAID_LABEL } = MESSAGE_KEYS;
    return (
      <div className="flex flex-row justify-content-end">
        <span className="mr-2">
          {intl.formatMessage(
            { id: BILLS_TOTAL_AMOUNT_LABEL },
            {
              value: totalAmount
                ? Number(totalAmount).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "0,00",
            }
          )}
        </span>
        <span>
          {intl.formatMessage(
            { id: BILLS_TOTAL_PAID_LABEL },
            {
              value: totalPaid
                ? Number(totalPaid).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "0,00",
            }
          )}
        </span>
      </div>
    );
  };

  render = () => {
    const { first, rows, rowsPerPage } = this.state;
    const {
      displayedBills,
      selectedRow,
      fetchPending,
      handleSelectionChange,
      totalBills,
    } = this.props;
    const { ERROR_NO_DATA } = MESSAGE_KEYS;

    return (
      <div>
        <DataTable
          id="bill_filter_table"
          value={displayedBills}
          selectionMode="single"
          selection={selectedRow}
          dataKey="transactionId"
          onSelectionChange={(e) => {
            handleSelectionChange(e.value || selectedRow);
          }}
          emptyMessage={this.props.intl.formatMessage({
            id: ERROR_NO_DATA,
          })}
          loading={fetchPending}
          className="p-datatable-sm"
          footer={this.renderSearchTableFooter()}
        >
          <Column
            header={this.billHeaderTemplate()}
            body={this.billCellTemplate}
          />
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalBills}
          rowsPerPageOptions={rowsPerPage}
          onPageChange={(e) => this.handlePageChange(e)}
          pageLinkSize={
            isDesktop ? PAGE_LINK_SIZES.DESKTOP : PAGE_LINK_SIZES.MOBILE
          }
        />
      </div>
    );
  };
}

export default injectIntl(BillFilterTable);
