import {
  APP_SET_BACKEND_AVAILABILITY,
  APP_FETCH_FAILS,
  APP_FETCH_START,
  APP_FETCH_SUCCESS,
  APP_FETCH_APPOINTMENTS,
  APP_SET_TRANSFER_PARAM,
  APP_UNSET_TRANSFER_PARAM,
  APP_SET_TOP_BAR_TITLE,
  APP_SET_MENU_ACTIVE,
  APP_SET_PEPPOL_EXPORTER_TASK_ACTIVE,
} from "actions/types";

const INITIAL_STATE = {
  topBarTitleAppendix: null,
  topbarTitle: "",
  backendAvailable: true,
  routerParams: null,
  offlineData: {
    failed: [],
    appointments: {},
    hasOfflineData: false,
  },
  menuActive: true,
  isPeppolExporterTaskActive: false,
};

const appReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case APP_SET_PEPPOL_EXPORTER_TASK_ACTIVE:
      return { ...state, isPeppolExporterTaskActive: payload };
    case APP_SET_BACKEND_AVAILABILITY:
      return { ...state, backendAvailable: payload };
    case APP_FETCH_START:
      return {
        ...state,
        offlineData: { ...state.offlineData, failed: [] },
      };
    case APP_FETCH_FAILS:
      return {
        ...state,
        offlineData: {
          ...state.offlineData,
          failed: [...state.offlineData.failed, payload],
          hasOfflineData: false,
        },
      };
    case APP_FETCH_SUCCESS:
      return {
        ...state,
        offlineData: {
          ...state.offlineData,
          failed: [],
          hasOfflineData: true,
        },
      };
    case APP_FETCH_APPOINTMENTS: {
      return {
        ...state,
        offlineData: {
          ...state.offlineData,
          appointments: {
            ...state.offlineData.appointments,
            payload,
          },
        },
      };
    }
    case APP_SET_TRANSFER_PARAM: {
      return {
        ...state,
        routerParams: payload,
      };
    }
    case APP_UNSET_TRANSFER_PARAM: {
      return {
        ...state,
        routerParams: null,
      };
    }
    case APP_SET_TOP_BAR_TITLE: {
      return {
        ...state,
        topbarTitle: payload[0],
        topBarTitleAppendix: payload[1],
      };
    }
    case APP_SET_MENU_ACTIVE: {
      return {
        ...state,
        menuActive: payload,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
