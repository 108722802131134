/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React, { useState, useEffect } from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Styling
import "./Style.scss";

const CustomerFilterLayout = (props) => {
  const { isPending, handleSearch, intl, initValue = "" } = props;
  const {
    CUSTOMERS_FILTER_NAME,
    CUSTOMERS_FILTER_ONLY_ACTIVE,
    DIALOG_SEARCH_BUTTON_LABEL,
    DIALOG_CLEAR_FILTER_LABEL,
  } = MESSAGE_KEYS;
  // Setup function state
  const [inputName, setName] = useState(initValue);

  const [inputOnlyActive, setOnlyActive] = useState(true);

  useEffect(() => {
    if (initValue !== "") {
      setName(initValue);
      handleSearch({ name: initValue, active: true });
    }
  }, [initValue, handleSearch]);

  /** Reset state variables. */
  let resetState = () => {
    setName("");
    setOnlyActive(true);
    handleSearch({
      name: "",
      active: true,
    });
  };

  const callSearch = () => {
    handleSearch({
      name: inputName,
      active: inputOnlyActive,
    });
  };

  /** Call search function if enter key is pressed. */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      callSearch();
    }
  };

  let buttonClass = isPending ? "pi pi-spin pi-spinner" : "pi pi-search";

  return (
    <div>
      <div>
        <FloatingTextInput
          id={CUSTOMERS_FILTER_NAME}
          value={inputName}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={handleKeyDown}
          label={intl.formatMessage({
            id: CUSTOMERS_FILTER_NAME,
          })}
        />

        <div className="my-2">
          <Checkbox
            className="mr-1"
            inputId={CUSTOMERS_FILTER_ONLY_ACTIVE}
            checked={inputOnlyActive}
            onChange={(e) => setOnlyActive(e.target.checked)}
          />
          <label htmlFor={CUSTOMERS_FILTER_ONLY_ACTIVE}>
            {intl.formatMessage({ id: CUSTOMERS_FILTER_ONLY_ACTIVE })}
          </label>
        </div>
      </div>
      <div className="flex flex-row justify-content-between">
        <Button
          icon={buttonClass}
          label={intl.formatMessage({
            id: DIALOG_SEARCH_BUTTON_LABEL,
          })}
          onClick={() => {
            callSearch();
          }}
          disabled={isPending}
          className="mr-2"
        />

        <Button
          icon={"pi pi-undo"}
          label={intl.formatMessage({
            id: DIALOG_CLEAR_FILTER_LABEL,
          })}
          onClick={() => {
            resetState();
          }}
          disabled={isPending}
        />
      </div>
    </div>
  );
};

export default injectIntl(CustomerFilterLayout);
