import React from "react";
import { memo } from "react";
// Styling
import "./Style.scss";

import { ProgressSpinner } from "primereact/progressspinner";
import { LOCALES } from "assets/staticData/enums";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
import AppointmentElement from "./AppointmentElement";

// Logging
import { initLogger } from "common/Helpers";
const logger = initLogger("scheduler_view");

const AppointmentSearchContainer = ({
  pendingSearch,
  appointments,
  status,
  currentUser,
  handleEventClick,
  handleShowHistoricalModal,
}) => {
  const intl = useIntl();
  const { DRIVER_STATUS_SORT_NO_RESULT } = MESSAGE_KEYS;

  const renderSpinner = <ProgressSpinner />;

  const renderEmptySearchFieldText = (
    <h1 style={{ margin: "auto" }}>
      {intl.formatMessage({
        id: DRIVER_STATUS_SORT_NO_RESULT,
      })}
    </h1>
  );

  const editSelection = (id, index, isSelected) => {
    if (appointments[index].appointmentid === id) {
      appointments[index].isSelected = isSelected;
    } else {
      logger.warn("Could not change selection");
    }
  };

  const renderAppointments = () => {
    let isGerman =
      currentUser.currentLocale.languageId === LOCALES.GERMAN.languageId;
    if (appointments.length > 0) {
      let appointmentsElements = [];

      appointments.forEach((appointment, index) => {
        let backgroundColor = "darkgray";
        let borderColor = appointment?.entryCreatedManually
          ? "#ff0000"
          : "#a7a7a7";
        let textColor = "white";
        let statusName = "";
        if (status && status.length > 0) {
          let selectedStatus = status.find((searchStats) => {
            return (
              searchStats?.appointmentStateId === appointment?.appointmentstate
            );
          });
          if (selectedStatus) {
            backgroundColor = selectedStatus.color;
            statusName = isGerman
              ? selectedStatus.nameDe
              : selectedStatus.nameFr;
          }
          // Set darker text color for better contrast.
          if (backgroundColor === "#FAEBEB" || backgroundColor === "#E8E8E8") {
            textColor = "#494949";
          }
        }
        const preparedEvent = {
          event: {
            _def: {
              extendedProps: {
                appointmentData: { ...appointment },
                statusText: statusName,
                index: index,
              },
              ui: {
                display: "",
              },
              resourceIds: ["1-1-0"],
            },
            extendedProps: {
              updatedPending: false,
              appointmentData: { ...appointment },
            },
          },
          view: {
            type: "resourceTimeGridDay",
          },
          backgroundColor,
          borderColor,
          textColor,
        };
        appointmentsElements.push(
          <AppointmentElement
            editSelection={editSelection}
            handleShowHistoricalModal={handleShowHistoricalModal}
            handleEventClick={handleEventClick}
            preparedEvent={preparedEvent}
            index={index}
          />
        );
      });

      return appointmentsElements;
    }

    return (
      <h1 style={{ margin: "auto" }}>
        {" "}
        {intl.formatMessage({
          id: DRIVER_STATUS_SORT_NO_RESULT,
        })}
      </h1>
    );
  };

  if (pendingSearch === true) {
    return renderSpinner;
  } else if (appointments.length > 0) {
    return renderAppointments();
  } else {
    return renderEmptySearchFieldText;
  }
};

export default memo(AppointmentSearchContainer); //only rerender if props are actually changed
