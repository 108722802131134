import React, { useState } from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { Button } from "primereact/button";
// Tippy.js tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Helper functions
import { changePendingConfirm, sendQuery, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Logging
const logger = initLogger("on_call_event_layout");

const ShiftEventLayout = (props) => {
  const [deletePending, setDeletePending] = useState(false);
  const { value, onClick, intl } = props;
  const { ON_CALLS_SHIFT_EMPTY_LABEL, ON_CALLS_DRIVERS_TITLE, ERROR_RENDER } =
    MESSAGE_KEYS;

  try {
    const {
      event: {
        _def: {
          title,
          extendedProps: {
            members,
            limit,
            appointmentId,
            description,
            appointmentTypeId,
          },
          ui: { display },
        },
      },
    } = value;

    const isHoliday = display === "background";
    let totalConfirmed = 0;
    if (members && members.length > 0) {
      totalConfirmed = members.filter(
        (member) => member.confirmed === true
      ).length;
    }

    const handleDelete = () => {
      setDeletePending(true);
      try {
        sendQuery(`${QUERIES.DELETE_ON_CALL}${appointmentId}`, "DELETE").then(
          () => {
            setDeletePending(false);
            value.event.remove();
          },
          (error) => {
            logger.error(error);
            setDeletePending(false);
          }
        );
      } catch (deleteException) {
        setDeletePending(false);
      }
    };

    const renderMembers = () => {
      let memberDisplay;
      if (members && members.length > 0) {
        memberDisplay = [
          <strong key={`mbr_title_${new Date().getTime()}`}>
            {intl.formatMessage({ id: ON_CALLS_DRIVERS_TITLE })}
          </strong>,
        ];
        members.forEach((member) => {
          const { personId, shortname } = member;
          memberDisplay.push(
            <div key={`${personId}_${new Date().getTime()}`}>
              {shortname}
              {member.confirmed === true && <i className="pl-2 pi pi-check" />}
            </div>
          );
        });
      } else {
        memberDisplay = (
          <div>{intl.formatMessage({ id: ON_CALLS_SHIFT_EMPTY_LABEL })}</div>
        );
      }
      return memberDisplay;
    };

    if (isHoliday) {
      return (
        <Tippy content={description}>
          <strong>{title}</strong>
        </Tippy>
      );
    } else {
      const SPECIAL_TYPE_ID = 10;
      let titleAppendix = "";
      if (
        appointmentTypeId === SPECIAL_TYPE_ID &&
        description &&
        description !== "Standby"
      ) {
        titleAppendix = ` - ${description}`;
      }

      const { canEdit } = props;
      let tooltipContent = (
        <div className={canEdit ? "flex p-flex-row justify-content-between" : ""}>
          <div>
            <strong>{`${title}${titleAppendix}`}</strong>
            <div>{`${totalConfirmed}/${limit ? limit : 0}`}</div>
            <div>{`${intl.formatMessage({
              id: MESSAGE_KEYS.ON_CALLS_REGISTER_BUTTON_LABEL,
            })}: ${members?.length ? members.length : "0"}`}</div>
          </div>
          {canEdit ? (
            <Button
              icon={`pi ${deletePending ? "pi-spin pi-spinner" : "pi-times"}`}
              className="p-button-rounded p-button-secondary m-1 p-button-sm"
              onClick={(e) => {
                e.stopPropagation();
                changePendingConfirm(value, handleDelete, intl, true, e);
              }}
              disabled={deletePending}
            />
          ) : (
            <></>
          )}
        </div>
      );

      return (
        <div onClick={onClick}>
          {isDesktop ? (
            <Tippy content={renderMembers()}>{tooltipContent}</Tippy>
          ) : (
            tooltipContent
          )}
        </div>
      );
    }
  } catch (renderException) {
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default injectIntl(ShiftEventLayout);
