/**
 * This component renders the customer CRUD view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
import { setChangePending, setCustomerSession } from "actions/sessionActions";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVenus,
  faMars,
  faGenderless,
  faCross,
} from "@fortawesome/free-solid-svg-icons";
// Custom components
import { CustomerEditView, CustomerFilterLayout } from "./components";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
  EMPTY_FILTER_QUERY,
  PAGE_LINK_SIZES,
  ROWS_PER_PAGE,
} from "assets/staticData/enums";
import { GENDERS, TITLES } from "assets/staticData/combodata";
// Helper classes
import {
  sendQuery,
  generateQueryParameters,
  initLogger,
  changePendingConfirm,
} from "common/Helpers";
// Styling
import "./Style.scss";
// Logging
const logger = initLogger("users_view");

const NAME_SORT = [
  { value: "asc", icon: "pi-sort-alpha-down" },
  { value: "desc", icon: "pi-sort-alpha-up" },
];

class CustomerView extends React.Component {
  state = {
    first: 0,
    rows: isDesktop ? 10 : 5,
    rowsPerPage: ROWS_PER_PAGE,
    displayedCustomers: [],
    totalCustomers: 0,
    currentPage: 0,
    selectedCustomer: null,
    fetchPending: false,
    fetchError: null,
    customerFetchPending: false,
    customerFetchError: null,
    selectedRow: null,
    queryString: EMPTY_FILTER_QUERY,

    sortIndex: 0,
  };

  /**
   * Fetches customer data on component mount from session storage if available, from backend else.
   */
  componentDidMount = () => {
    const { currentPage, rows } = this.state;
    const { setTopbarTitle, setChangePending, customerSession } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_CUSTOMERS })
    );
    setChangePending(false);
    if (customerSession) {
      this.setState({
        ...customerSession,
      });
    } else {
      this.filterCustomers(currentPage, rows);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      rows,
      displayedCustomers,
      currentPage,
      selectedCustomer,
      queryString,
    } = this.state;

    if (
      prevState.currentPage !== currentPage ||
      prevState.rows !== rows ||
      prevState.queryString !== queryString ||
      prevState.selectedCustomer !== selectedCustomer
    ) {
      logger.info("UPDATING SESSION", selectedCustomer);
      this.handleSessionUpdate(displayedCustomers);
    }
  };

  filterCustomers = (page, rows, queryString) => {
    try {
      this.setState({ fetchPending: true }, () => {
        sendQuery(
          `${QUERIES.GET_CUSTOMER_FILTERED}page=${
            page ? page : this.state.currentPage
          }&size=${rows ? rows : this.state.rows}${
            queryString ? queryString : this.state.queryString
          }`,
          "get"
        ).then(
          (response) => {
            let displayedCustomers = [...response.content];
            this.setState(
              {
                displayedCustomers,
                totalCustomers: response.totalElements,
                fetchPending: false,
              },
              () => {
                this.handleSessionUpdate([...response.content]);
              }
            );
          },
          (error) => {
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              () => {
                logger.error(error, error.message);
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary:
                    typeof error === "string"
                      ? error
                      : this.props.intl.formatMessage({
                          id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                        }),
                });
                this.handleSessionUpdate();
              }
            );
          }
        );
      });
    } catch (filterException) {
      logger.error(filterException);
      this.handleSessionUpdate();
    }
  };

  handleSessionUpdate = (displayedCustomers = []) => {
    const { setCustomerSession, customerSession } = this.props;

    const {
      first,
      rows,
      totalCustomers,
      currentPage,
      queryString,
      selectedRow,
    } = this.state;
    setCustomerSession({
      ...customerSession,
      first,
      rows,
      displayedCustomers: [...displayedCustomers],
      totalCustomers,
      currentPage,
      queryString,
      selectedRow,
    });
  };

  /**
   * Gets called when the user changes the table page or number of rows displayed.
   * This function updates the table control values of the state and calls the filterCustomers function to update the displayed rows.
   *
   * @param {Object} event
   */
  handlePageChange = (event) => {
    const { first, rows, page } = event;
    this.setState(
      {
        first,
        rows,
        currentPage: page,
      },
      this.filterCustomers(page, rows)
    );
  };

  /**
   * Gets called when a row in the table is selected.
   * If the changePending value within the state is true, the user will be prompted to confirm the selection.
   * On confirmation, the state is updated and the data for the new selection will be fetched and displayed. No change will happen else.
   *
   * @param {Object} selection - The id of the new selection.
   */
  handleSelectionChange = (selection) => {
    const { changePending, intl } = this.props;
    if (!changePending) {
      this.changeSelection(selection);
    } else {
      changePendingConfirm(selection, this.changeSelection, intl);
    }
  };

  changeSelection = (selection) => {
    this.setState(
      {
        selectedCustomer: null,
      },
      () => {
        this.setState(
          { selectedCustomer: selection.personId, selectedRow: selection },
          () => {
            this.props.setChangePending(false);
          }
        );
      }
    );
  };

  /**
   * Resets the current page to the first one and fetches customer data for that page.
   * @param {Object} filter
   */
  handleSearch = (filter = null) => {
    const { rows, sortIndex } = this.state;
    let sortOrder = NAME_SORT[sortIndex];
    let queryString = filter
      ? generateQueryParameters(filter)
      : this.state.queryString;
    this.setState(
      {
        currentPage: 0,
        queryString,
        fetchPending: true,
        fetchError: null,
      },
      this.filterCustomers(
        0,
        rows,
        `${queryString}${
          sortOrder.value ? `&sort=countryProvince,${sortOrder.value}` : ""
        }&sort=lastname,asc`
      )
    );
  };

  /**
   * Renders the button to create a new user.
   * It calls the handleSelectionChange-function with a negative ID to initialize the edit-mask with empty values.
   */
  renderNewUserButton = () => {
    const { fetchError, fetchPending } = this.props;
    return (
      <Button
        icon="pi pi-plus"
        onClick={() => {
          this.handleSelectionChange({ personId: new Date().getTime() * -1 });
        }}
        disabled={fetchError || fetchPending}
      />
    );
  };

  /**
   * Renders the toolbar with the customer filter component.
   *
   * @returns {JSX.Element} - The rendered toolbar.
   */
  renderFilter = () => {
    const { fetchPending } = this.state;
    try {
      return (
        <Panel
          header={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.CUSTOMERS_FILTER_TITLE_LABEL,
          })}
          toggleable
          collapsed={true}
        >
          <CustomerFilterLayout
            isPending={fetchPending}
            handleSearch={this.handleSearch}
          />
        </Panel>
      );
    } catch (renderException) {
      logger.error(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  genderCustomerCellTemplate = (rowData) => {
    const { firstname, girlName, lastname, title, sexId, isDead } = rowData;
    library.add(fas, faVenus, faMars, faGenderless, faCross);
    let genderClass = [];
    if (isDead === true) {
      genderClass = ["fas", "cross"];
    } else {
      switch (sexId) {
        case GENDERS[2].sexId:
          genderClass = ["fas", "venus"];
          break;
        case GENDERS[1].sexId:
          genderClass = ["fas", "mars"];
          break;
        default:
          genderClass = ["fas", "genderless"];
      }
    }
    let titleMessageKey = TITLES.find((titleObject) => {
      return titleObject.titleId === title;
    });
    if (!titleMessageKey) {
      titleMessageKey = TITLES[0];
    }

    let fullLastName = `${lastname ? lastname : ""}${
      girlName && lastname ? "-" : ""
    }${girlName ? girlName : ""}`;

    let nameText = `${
      titleMessageKey
        ? this.props.intl.formatMessage({ id: titleMessageKey.messageKey })
        : title
    } ${fullLastName ? fullLastName : ""}`;

    return (
      <div className="flex align-items-center">
        <FontAwesomeIcon icon={genderClass} className="mr-2" />
        <span>
          <strong>{nameText}</strong> {firstname}
        </span>
      </div>
    );
  };

  addressCellTemplate = (rowData) => {
    try {
      const { address, countryProvince } = rowData;
      return (
        <div>
          <div>{address ? address : "-"}</div>
          <div>{countryProvince ? countryProvince : "-"}</div>
        </div>
      );
    } catch (renderException) {
      return <></>;
    }
  };

  handleSortHeaderClick = () => {
    const { sortIndex } = this.state;
    let newIndex = sortIndex + 1;
    this.setState(
      {
        sortIndex: newIndex < NAME_SORT.length ? newIndex : 0,
      },
      this.handleSearch
    );
  };

  generateColumns = () => {
    const { intl } = this.props;
    const {
      APPOINTMENTS_CLIENT_LABEL,
      CUSTOMERS_MATRICULATION_LABEL,
      CUSTOMERS_FILTER_ADDRESS,
    } = MESSAGE_KEYS;

    const { sortIndex } = this.state;
    let sortOrder = NAME_SORT[sortIndex];
    return [
      <Column
        key="col_cst_name"
        header={intl.formatMessage({
          id: APPOINTMENTS_CLIENT_LABEL,
        })}
        body={this.genderCustomerCellTemplate}
      />,
      <Column
        key="col_cst_number"
        field="healthInsuranceNumber"
        header={intl.formatMessage({ id: CUSTOMERS_MATRICULATION_LABEL })}
      />,
      <Column
        key="col_cst_address"
        body={this.addressCellTemplate}
        header={
          <div
            className="flex flex-row justify-content-between sortable_header"
            onClick={this.handleSortHeaderClick}
          >
            {intl.formatMessage({
              id: CUSTOMERS_FILTER_ADDRESS,
            })}
            {sortOrder.value ? <i className={`pi ${sortOrder.icon}`} /> : <></>}
          </div>
        }
      />,
    ];
  };

  /**
   * Renders te customers table & paginator with the displayed customers.
   * If no customers are displayed, the table will contain a single row with the message that no data is available.
   * If an error occurred, the error message is displayed instead.
   */
  renderTable = () => {
    const { intl, isLandscape } = this.props;
    try {
      const {
        displayedCustomers,
        first,
        fetchPending,
        totalCustomers,
        rows,
        rowsPerPage,
        selectedRow,
      } = this.state;
      return (
        <div>
          <DataTable
            value={displayedCustomers}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => {
              this.handleSelectionChange(e.value || selectedRow);
            }}
            emptyMessage={this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_NO_DATA,
            })}
            loading={fetchPending}
            className="p-datatable-sm"
          >
            {this.generateColumns()}
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalCustomers}
            rowsPerPageOptions={rowsPerPage}
            onPageChange={(e) => this.handlePageChange(e)}
            pageLinkSize={
              isDesktop || isLandscape
                ? PAGE_LINK_SIZES.DESKTOP
                : PAGE_LINK_SIZES.MOBILE
            }
          />
        </div>
      );
    } catch (renderException) {
      logger.error(renderException);
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { isLandscape, setChangePending } = this.props;
    const { selectedCustomer } = this.state;
    let notResponsive = isDesktop || isLandscape;

    return (
      <div className={notResponsive ? "grid" : ""}>
        <Toast ref={(el) => (this.toast = el)} />
        <div className={notResponsive ? "col-4" : ""}>
          <div className="flex tool_row">
            {this.renderNewUserButton()}
            {this.renderFilter()}
          </div>
          {this.renderTable(notResponsive)}
        </div>
        <div className={notResponsive ? "col-8" : ""}>
          <Panel
            header={this.props.intl.formatMessage({
              id: MESSAGE_KEYS.CUSTOMER_DATA_TITLE_LABEL,
            })}
          >
            <CustomerEditView
              selectedCustomer={selectedCustomer}
              currentUser={this.props.currentUser}
              handleParentUpdate={(newId) => {
                this.setState({
                  selectedCustomer: newId,
                });
                setChangePending(false);
                this.filterCustomers();
              }}
            />
          </Panel>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser = null },
      session: { changePending = false, customerSession = null },
    } = state;
    return {
      currentUser,
      changePending,
      customerSession,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      currentUser: null,
      changePending: false,
      customerSession: null,
    };
  }
};

export default withOrientationChange(
  connect(mapStateToProps, {
    setTopbarTitle,
    setChangePending,
    setCustomerSession,
  })(injectIntl(CustomerView))
);
