import React, { useState, useEffect, memo, useRef } from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Help functions
import { dateToString, sendQuery, initLogger } from "common/Helpers";

const logger = initLogger("RegularListTable");

const RegularListTable = () => {
  const intl = useIntl();
  const {
    APPOINTMENTS_VEHICLE_TYPE_LABEL,
    CUSTOMERS_COMMENT_LABEL,
    CUSTOMERS_FILTER_INSURANCE_NUMBER,
    CUSTOMERS_FILTER_PHONE,
    REGULARS_DATES,
    REGULARS_END,
    REGULARS_START,

    APPOINTMENTS_CLIENT_LABEL,
  } = MESSAGE_KEYS;
  const [regulars, setRegulars] = useState([]);
  const [pending, setPending] = useState(false);
  const toast = useRef(null);

  const renderCustomerCell = ({
    title,
    address,
    phone,
    healthInsuranceNumber,
    customerName,
  }) => {
    return (
      <div>
        {customerName && (
          <strong>{`${title ? title : ""} ${customerName}`}</strong>
        )}
        {healthInsuranceNumber ? (
          <div>{`${intl.formatMessage({
            id: CUSTOMERS_FILTER_INSURANCE_NUMBER,
          })} ${healthInsuranceNumber}`}</div>
        ) : (
          <></>
        )}
        {address && <div>{address}</div>}
        {phone && (
          <div>{`${intl.formatMessage({
            id: CUSTOMERS_FILTER_PHONE,
          })} ${phone}`}</div>
        )}
      </div>
    );
  };

  const TABLE_STRUCTURE = [
    {
      field: "transportType",
      header: APPOINTMENTS_VEHICLE_TYPE_LABEL,
      body: null,
      style: { width: "175px", maxWidth: "175px", verticalAlign: "top" },
    },
    {
      field: "startDate",
      header: REGULARS_START,
      body: ({ startDate }) => dateToString(startDate, "/"),
      style: { width: "175px", maxWidth: "175px", verticalAlign: "top" },
    },
    {
      field: "customerFilter",
      header: APPOINTMENTS_CLIENT_LABEL,
      body: renderCustomerCell,
    },
    {
      field: "remark",
      header: CUSTOMERS_COMMENT_LABEL,
      body: null,
    },

    {
      field: "destination",
      header: REGULARS_END,
      body: null,
      style: { width: "175px", maxWidth: "175px", verticalAlign: "top" },
    },
    {
      field: "dates",
      header: REGULARS_DATES,
      body: null,
      style: { width: "175px", maxWidth: "175px", verticalAlign: "top" },
    },
  ];

  useEffect(() => {
    setPending(true);
    sendQuery(QUERIES.GET_REGULARS, "get").then(
      (response) => {
        response.forEach((entry) => normalizeResponse(entry));
        setRegulars(response);
        setPending(false);
      },
      (error) => {
        logger.warn(error);
        setPending(false);
      }
    );
  }, [setRegulars]);

  const renderColumns = () => {
    const columns = [
      <Column
        field="regularCustomerId"
        header={intl.formatMessage({ id: MESSAGE_KEYS.REGULARS_ID })}
        sortable
        style={{ width: "50px", maxWidth: "50px", verticalAlign: "top" }}
        key="regularCustomerId"
      />,
    ];

    TABLE_STRUCTURE.forEach((col) => {
      columns.push(
        <Column
          header={intl.formatMessage({ id: col.header })}
          field={col.field}
          editor={col.editor}
          body={col.body}
          filter
          sortable
          showFilterMenu={false}
          key={`regulars_${col.field}`}
          style={col.style}
          filterFunction={(value, filter) => col.filterFunction(value, filter)}
          filterMatchMode={
            col.filterFunction !== undefined ? "custom" : "contains"
          }
          filterField={col.filterField ? col.filterField : col.field}
        />
      );
    });
    return columns;
  };

  const normalizeResponse = (regular) => {
    Object.keys(regular).forEach(
      (k) => (regular[k] = regular[k] === null ? "" : regular[k])
    );
    if (regular.startDate) {
      regular.startDate = new Date(regular.startDate);
      regular.customerFilter = `${regular.customerName} ${regular.address} ${regular.healthInsuranceNumber} ${regular.phone}`;
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <DataTable
        loading={pending}
        value={regulars}
        dataKey="regularCustomerId"
        responsiveLayout="scroll"
        className="inline_edit_table"
        filterDisplay="row"
        paginator
        rows={25}
        rowsPerPageOptions={[10, 25, 50]}
        sortField={"regularCustomerId"}
        sortOrder={1}
      >
        {renderColumns()}
      </DataTable>
    </div>
  );
};

export default memo(RegularListTable);
