/**
 * This component is used to perform long tasks which should be proceeded in the background while the UI still works properly
 *
 * @version 1.0
 * @author [Dylan Ribeiro]
 */
import React from "react";
import "./Style.scss";
import PeppolExporterTask from "./tasks/PeppolExporterTask";
import { BACKGROUND_TASKS } from "assets/staticData/enums";
import { setPeppolExporterTaskActive } from "actions";
import { connect } from "react-redux";
import { initLogger } from "common/Helpers";

import { Sidebar } from "primereact/sidebar";

class BackgroundTasks extends React.Component {
  logger = initLogger("background_tasks_view");

  killTask = (task) => {
    const { setPeppolExporterTaskActive } = this.props;
    switch (task) {
      case BACKGROUND_TASKS.PeppolExporterTask:
        setPeppolExporterTaskActive(false);
        break;
      default:
        this.logger.warn("Unknown background task: ", task);
    }
  };

  render() {
    const { isPeppolTaskActive, setPeppolExporterTaskActive } = this.props;

    // set another var to visible whenever an new background process is being programmed
    return (
      <>
        <Sidebar
          visible={isPeppolTaskActive}
          position="bottom"
          onHide={() => setPeppolExporterTaskActive(false)}
          dismissable={false}
          showCloseIcon={false}
          modal={false}
          className="footer-sidebar"
        >
          {isPeppolTaskActive && (
            <PeppolExporterTask killTask={this.killTask} />
          )}
        </Sidebar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPeppolTaskActive: state.application?.isPeppolExporterTaskActive,
  };
};

export default connect(mapStateToProps, { setPeppolExporterTaskActive })(
  BackgroundTasks
);
