import React from "react";
// Styling
import "./Style.scss";
// PrimeReact components
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useState } from "react";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const AppointmentSearchHeader = ({
  pendingSearch,
  handleFilter,
  handleCancelAppointments,
}) => {
  const [filterSearch, setFilterSearch] = useState("");
  const [checkedHistory, setChecked] = useState(false);

  const intl = useIntl();
  const { DIALOG_SEARCH_BUTTON_LABEL, APPOINTMENT_CANCEL_SELECTED_LABEL } =
    MESSAGE_KEYS;

  return (
    <div className="appointment_search_toolbar">
      <div className="search-container">
        <span className="input-search">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              handleFilter(filterSearch, checkedHistory);
            }}
          >
            <InputText
              placeholder={`${intl.formatMessage({
                id: DIALOG_SEARCH_BUTTON_LABEL,
              })}`}
              value={filterSearch}
              onChange={(e) => {
                setFilterSearch(e.target.value);
              }}
              disabled={pendingSearch}
            />
            <Button
              icon="pi pi-search"
              loading={pendingSearch}
              onClick={() => {
                handleFilter(filterSearch, checkedHistory);
              }}
              disabled={pendingSearch || filterSearch === ""}
            />
          </form>
        </span>
      </div>
      <div>
        <label htmlFor="historyCheck">
          <i className="pi pi-history" />
        </label>
        <Checkbox
          inputId="historyCheck"
          name="historyCheck"
          value="historyCheck"
          checked={checkedHistory}
          onChange={(e) => {
            setChecked(e.checked);
            handleFilter(filterSearch, e.checked);
          }}
          disabled={pendingSearch}
        />
        <Button
          label={`${intl.formatMessage({
            id: APPOINTMENT_CANCEL_SELECTED_LABEL,
          })}`}
          loading={pendingSearch}
          onClick={() => {
            handleCancelAppointments();
          }}
          className="p-button-danger"
        />
      </div>
    </div>
  );
};

export default AppointmentSearchHeader;
