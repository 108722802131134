import React from "react";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Helper functions
import {
  priceCellTemplate,
  dateCellTemplate,
  numberToPriceString,
  initLogger,  
  changePendingConfirm,  
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Logger
const logger = initLogger("paymentsTable");

const PaymentsTable = (props) => {
  const { value,  intl, handlePaymentDelete, handleEditClick, fetchPending } = props;
  const {
    ERROR_NO_DATA,
    ERROR_RENDER,
    PAYMENTS_AMOUNT_LABEL,
    PAYMENTS_DATE_LABEL,
    PAYMENTS_BANK_LABEL,
    BILLS_COMMENT_INTERNAL_LABEL,
  } = MESSAGE_KEYS;


  const generateTooltipContent = (rowData) => {
    const { info } = rowData;
    let content;
    try {
      content = (
        <div>
          <div>
            {`${intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}: `}{" "}
            <strong>{`${info ? info : "-"}`}</strong>
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn(
        "Exception on generateTooltipContent",
        renderException,
        rowData
      );
      content = <div>-</div>;
    } finally {
      return content;
    }
  };

  /** Custom table cell renderer that returns an edit-button. */
  const editButtonTemplate = (rowData, index) => {    
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        onClick={() => {
          handleEditClick(index.rowIndex)          
        }}
      />
    );
  };

  /** Custom table cell renderer that returns a remove-button. */
  const deleteButtonTemplate = (rowData, index) => {
    const { amount, active } = rowData;
    let displayAmount = amount ? `${amount}€` : "";
    if (active === true) {
      return (
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() =>
            changePendingConfirm(index.rowIndex, handlePaymentDelete, intl, displayAmount)
          }
        />
      );
    } else {
      return <i className="pi pi-times" style={{ color: "red" }} />;
    }
  };

  /** Custom table cell renderer that returns a goto-button.
   * Button is not displayed for new buttons.
   */
  const viewButtonTemplate = (rowData) => {
    if (rowData && rowData.info) {
      return (
        <Tippy content={generateTooltipContent(rowData)}>
          <i className="pi pi-comment" />
        </Tippy>
      );
    } else {
      return <></>;
    }
  };

  const paymentsFooterTemplate = () => {
    let total = 0;
    if (value && value.length > 0) {
      value.forEach((payment) => {
        total +=
          payment && payment.amount && payment.active === true
            ? payment.amount
            : 0;
      });
    }
    return <div>{numberToPriceString(total)}</div>;
  };
  try {    
    return (
      <div>      
      <DataTable
        value={value}
        emptyMessage={intl.formatMessage({
          id: ERROR_NO_DATA,
        })}        
        className="p-datatable-sm"
        loading={fetchPending}
      >
        <Column
          header={intl.formatMessage({
            id: PAYMENTS_DATE_LABEL,
          })}
          body={(rowData) => {
            return dateCellTemplate(rowData.paymentDate);
          }}
        />
        <Column
          style={{ textAlign: "right" }}
          header={intl.formatMessage({
            id: PAYMENTS_BANK_LABEL,
          })}
          field="bank"
        />
        <Column
          style={{ textAlign: "center", width: "4em" }}
          body={viewButtonTemplate}
        />
        <Column
          style={{ textAlign: "right" }}
          field="amount"
          header={intl.formatMessage({
            id: PAYMENTS_AMOUNT_LABEL,
          })}
          body={(rowData) => {
            return priceCellTemplate(
              rowData && rowData.amount
                ? rowData.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                : (0).toLocaleString(undefined, { minimumFractionDigits: 2 })
            );
          }}
          footer={paymentsFooterTemplate}
        />
        <Column
          body={editButtonTemplate}
          style={{ textAlign: "center", width: "4em" }}
        />
        <Column
          body={deleteButtonTemplate}
          style={{ textAlign: "center", width: "4em" }}
        />
      </DataTable></div>
    );
  } catch (renderException) {
    logger.warn(renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default (injectIntl(PaymentsTable));
