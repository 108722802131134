/**
 * This functional component renders a password input component.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
import { Password } from "primereact/password";
import "./Common.scss";
import "primeflex/primeflex.css";
/**
 * Renders a password input field with the floating label effect.
 *
 * @param {Object} props
 * @param {String} props.id - The ID for the generated object.
 * @param {String} props.value - The input value of the object.
 * @param {Function} props.onChange - The action that will be executed if onChange is triggered.
 * @param {String} props.label - The label for the input field.
 * @param {Boolean} props.valid - Determines if the current input is valid. Is valid is false, the input field will get a red highlight.
 * @param {Boolean} props.feedback - If true, the password strength hint will be displayed on user input.
 * @returns {JSX.Element}
 */
const FloatingPassword = (props) => {
  const { id, value, onChange, label, valid, feedback, onKeyDown, className } = props;

  return (
    <span className={`p-float-label input_wrapper  ${className}`}>
      <Password
        id={id}
        value={value}
        onChange={(e) => onChange(e)}
        inputStyle={{width: "100%"}}
        style={{width: "100%"}}
        feedback={feedback}
        autoComplete={id}
        className={`${valid === true || valid === null ? "" : "p-invalid"}`}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
};

export default FloatingPassword;
