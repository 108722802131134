import React, { useRef } from "react";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";
import { ReactionButton } from "./index";
import EmojiPicker from "emoji-picker-react";

const ReactionContainer = ({
  reactions,
  id,
  index,
  isPreview,
  handleReactionAdd,
  handleReactionDelete,
  userId,
}) => {
  const groupReactions = () => {
    if (reactions) {
      const groupedData = reactions.reduce((groups, item) => {
        const { icon, alias, personId, reactionId } = item;
        if (!groups[icon]) {
          groups[icon] = {
            firstId: reactionId,
            count: 0,
            aliases: [],
            icon: "",
            includesMe: false,
            shoutboxId: id,
          };
        }
        groups[icon].count++;
        groups[icon].aliases.push(alias);
        if (!groups[icon].includesMe) {
          groups[icon].includesMe = personId === userId;
          groups[icon].firstId = reactionId;
        }
        groups[icon].icon = icon;
        return groups;
      }, {});
      return Object.values(groupedData);
    } else {
      return [];
    }
  };

  const op = useRef(null);
  const formattedReactions = [];
  const generateReactions = () => {
    if (reactions) {
      const groupedReactions = groupReactions();
      groupedReactions.forEach((reaction, i) => {
        formattedReactions.push(
          <ReactionButton
            reaction={reaction}
            key={`reaction_base_${reaction.firstId}`}
            handleReactionDelete={handleReactionDelete}
          />
        );
      });

      return formattedReactions;
    } else if (isPreview) {
      let emojis = ["😂", "💯", "👏", "🤔", "😫"];
      emojis.forEach((emoji, i) => {
        formattedReactions.push(
          <Avatar className="p-overlay-badge" label={emoji} key={i}>
            <Badge value={(i + 1) * 2} />
          </Avatar>
        );
      });
      return formattedReactions;
    } else {
      return "";
    }
  };
  return (
    <div id="reaction_base" className="reaction-container">
      {generateReactions()}

      {!isPreview && (
        <>
          <OverlayPanel ref={op}>
            <EmojiPicker
              emojiStyle="native"
              lazyLoadEmojis={true}
              onEmojiClick={(e) => {
                handleReactionAdd(e.emoji, index, id);
                op.current.hide();
              }}
            />
          </OverlayPanel>
          <Avatar
            label={"+"}
            className="add-reaction-button"
            onClick={(e) => {
              op.current.toggle(e);
              e.stopPropagation();
            }}
          />
        </>
      )}
    </div>
  );
};

export default ReactionContainer;
