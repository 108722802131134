import React from "react";
// PrimeReact components
import { Toast } from "primereact/toast";
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
// Localization
import { injectIntl } from "react-intl";
// Custom components
import { BillFilterLayout } from "components/BillsView/components";
// Static values
import {
  QUERIES,
  MESSAGE_SEVERITY,
  MESSAGE_KEYS,
} from "assets/staticData/enums";
// Helper classes
import {
  sendQuery,
  initLogger,
  generateQueryParameters,
  dateToBillNumber,
  dateToString,
} from "common/Helpers";
// Logging
const logger = initLogger("BillSelector");

class BillSelector extends React.Component {
  state = {
    displayedBills: [],
    fetchPending: false,
  };

  handleSearch = (filter) => {
    let queryString = generateQueryParameters(filter, "yearsort,invnumbersort");
    this.setState(
      {
        first: 0,
        currentPage: 0,
        queryString,
        fetchPending: true,
        fetchError: null,
      },
      this.filterBills(0, 30, queryString)
    );
  };

  filterBills = (page, rows, queryString) => {
    try {
      this.setState({ fetchPending: true }, () => {
        sendQuery(
          `${QUERIES.GET_BILLS_PAGES}page=${
            page ? page : this.state.currentPage
          }&size=${rows ? rows : this.state.rows}${
            queryString ? queryString : this.state.queryString
          }`,
          "get"
        ).then(
          (response) => {
            let displayedBills = [...response.content];
            this.setState({
              displayedBills,
              fetchPending: false,
            });
          },
          (error) => {
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : this.props.intl.formatMessage({
                        id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                      }),
              })
            );
          }
        );
      });
    } catch (filterException) {
      logger.error(filterException);
    }
  };

  renderList = () => {
    try {
      const { selectedBill, displayedBills } = this.state;
      if (displayedBills && displayedBills.length > 0) {
        return (
          <ListBox
            value={selectedBill}
            options={displayedBills}
            itemTemplate={this.renderListItem}
            onChange={(e) => this.handleBillSelection(e.value)}
            listStyle={{ maxHeight: "325px" }}
            optionLabel={"transactionNumber"}
            className="ml-2"
          />
        );
      } else {
        return <></>;
      }
    } catch (listRenderException) {
      logger.warn("Exception in renderList", listRenderException);
      return <></>;
    }
  };

  renderListItem = (item) => {
    try {
      const { invoiceNumber, orderDate } = item;
      return `${invoiceNumber}/${
        orderDate
          ? `${dateToBillNumber(orderDate)} ${dateToString(orderDate)}`
          : ""
      }`;
    } catch (renderException) {
      logger.warn("Could not render list item.", renderException, item);
      return <div>Error</div>;
    }
  };

  handleBillSelection = (selectedBill) => {
    this.props.onChange(selectedBill);
    this.billOp.hide();
  };

  resetBillFilter = () => {
    this.props.onChange(null);
  };

  renderBillOverlayButton = () => {
    return (
      <div>
        <Button icon="pi pi-search" onClick={(e) => this.billOp.toggle(e)} />
        <OverlayPanel
          ref={(el) => (this.billOp = el)}
          showCloseIcon
          dismissable
        >
          {this.renderOverlayContent()}
        </OverlayPanel>
      </div>
    );
  };

  renderOverlayContent = () => {
    return (
      <div className="bill_selector_base">
        <Toast ref={(el) => (this.toast = el)} />
        <BillFilterLayout
          isPending={this.state.fetchPending}
          handleSearch={this.handleSearch}
        />
        {this.renderList()}
      </div>
    );
  };

  renderInput = () => {
    let clearButton;
    let displayText;
    if (this.props.value) {
      const {
        value: { invoiceNumber, orderDate },
      } = this.props;
      clearButton = (
        <Button
          className="p-button-warning"
          icon="pi pi-times"
          onClick={this.resetBillFilter}
        />
      );
      displayText = `${invoiceNumber}/${
        orderDate
          ? `${dateToBillNumber(orderDate)} ${dateToString(orderDate)}`
          : ""
      }`;
    } else {
      const { placeholder, intl } = this.props;
      if (placeholder) {
        displayText = placeholder;
      } else {
        displayText = intl.formatMessage({
          id: MESSAGE_KEYS.PAYMENTS_FILTER_BILL_LABEL,
        });
      }
      clearButton = <></>;
    }
    const { valid } = this.props;
    return (
      <div className="p-inputgroup">
        {this.renderBillOverlayButton()}
        <InputText
          value={displayText}
          disabled
          className={`${
            valid === true || valid === null || valid === undefined
              ? ""
              : "p-invalid"
          }`}
        />
        {clearButton}
      </div>
    );
  };

  render = () => {
    try {
      return this.renderInput();
    } catch (renderException) {
      logger.warn("Exception on renderBillSelection", renderException);
      return <div></div>;
    }
  };
}

export default injectIntl(BillSelector);
