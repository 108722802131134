/**
 * Not in use anymore.
 */
import React from "react";
// PrimeReact components
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Logging
import { initLogger } from "common/Helpers";
const logger = initLogger("vacation_more_content");

const VacationMoreContent = (props) => {
  const {
    text,
    view: { getCurrentData },
  } = props;

  let data = getCurrentData();
  const renderMore = () => {
    try {
      const content = [];
      Object.keys(data.eventStore.defs).forEach((key) => {
        const {
          title,
          ui: { backgroundColor },
          extendedProps: { remark, remark2, appointmentId },
        } = data.eventStore.defs[key];
        content.push(
          <div key={`apt_more_${appointmentId}`}>
            <div className="flex align-items-center">
              <Avatar
                icon=""
                className="mr-2"
                style={{
                  backgroundColor: backgroundColor,
                  color: backgroundColor,
                }}
                shape="circle"
              />
              <div>
                <div>{title}</div> <div>{`KOMMENTAR: ${remark}`}</div>{" "}
                <div>{`ANTWORT: ${remark2}`}</div>
              </div>
            </div>
            <Divider />
          </div>
        );
      });
      return content;
    } catch (renderException) {
      logger.error(renderException);
      return <div>Fehler beim Darstellen der Komponente</div>;
    }
  };

  return (
    <Tippy content={renderMore()} placement="bottom">
      <div>{text}</div>
    </Tippy>
  );
};

export default VacationMoreContent;
