import React from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
// Custom components
import { UserEmergencyPersonDialog } from "./";
// Helper functions
import {
  safeRender,
  sendQuery,
  initLogger,
  fullNameTemplate,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

const logger = initLogger("UserEmergencyPersonLayout");
const isFullHD = window.innerWidth >= 1920;

class UserEmergencyPersonLayout extends React.Component {
  state = {
    showDialog: false,
    selectedIndex: null,
    deletePending: false,
  };

  /** Custom table cell renderer that returns an edit-button. */
  editButtonTemplate = (rowData, index) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        onClick={() => {
          this.setState({
            selectedIndex: index.rowIndex,
            showDialog: true,
          });
        }}
      />
    );
  };

  /** Custom table cell renderer that returns an edit-button. */
  deleteButtonTemplate = (rowData, index) => {
    const { intl } = this.props;
    const {
      DIALOG_CONFIRM_BUTTON_LABEL,
      DIALOG_DENY_BUTTON_LABEL,
      USERS_DELETE_EMERGENCY_PERSON_MESSAGE,
      USERS_DELETE_EMERGENCY_PERSON_LABEL,
    } = MESSAGE_KEYS;
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={() => {
          confirmDialog({
            message: intl.formatMessage(
              { id: USERS_DELETE_EMERGENCY_PERSON_MESSAGE },
              {
                fullname: `${rowData.lastname} ${rowData.firstname} (${rowData.emergencyContactId})`,
              }
            ),
            header: intl.formatMessage({
              id: USERS_DELETE_EMERGENCY_PERSON_LABEL,
            }),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: intl.formatMessage({
              id: DIALOG_CONFIRM_BUTTON_LABEL,
            }),
            rejectLabel: intl.formatMessage({ id: DIALOG_DENY_BUTTON_LABEL }),
            accept: () =>
              this.handleDelete(rowData.emergencyContactId, index.rowIndex),
          });
        }}
        severity="danger"
        rounded
      />
    );
  };

  renderTable = () => {
    const { value, intl } = this.props;
    const {
      USERS_FIRST_NAME_LABEL,
      USERS_LAST_NAME_LABEL,
      USERS_HOME_PHONE_LABEL,
      USERS_MOBILE_PHONE_LABEL,
      CUSTOMERS_FILTER_NAME,
    } = MESSAGE_KEYS;

    if (!isFullHD) {
      return (
        <DataTable
          size="small"
          value={value}
          loading={this.state.deletePending}
        >
          <Column
            header={intl.formatMessage({ id: CUSTOMERS_FILTER_NAME })}
            body={(rowData) => {
              return fullNameTemplate(rowData.firstname, rowData.lastname);
            }}
          />
          <Column
            field="gsm"
            header={intl.formatMessage({
              id: USERS_HOME_PHONE_LABEL,
            })}
            style={{ minWidth: "160px" }}
          />
          <Column
            field="phone"
            header={intl.formatMessage({
              id: USERS_MOBILE_PHONE_LABEL,
            })}
            style={{ minWidth: "160px" }}
          />
          <Column body={this.editButtonTemplate} />
          <Column body={this.deleteButtonTemplate} />
        </DataTable>
      );
    } else {
      return (
        <DataTable
          size="small"
          value={value}
          loading={this.state.deletePending}
        >
          <Column
            field="firstname"
            header={intl.formatMessage({ id: USERS_FIRST_NAME_LABEL })}
          />
          <Column
            field="lastname"
            header={intl.formatMessage({ id: USERS_LAST_NAME_LABEL })}
          />
          <Column
            field="gsm"
            header={intl.formatMessage({
              id: USERS_HOME_PHONE_LABEL,
            })}
            style={{ minWidth: "160px" }}
          />
          <Column
            field="phone"
            header={intl.formatMessage({
              id: USERS_MOBILE_PHONE_LABEL,
            })}
            style={{ minWidth: "160px" }}
          />
          <Column body={this.editButtonTemplate} />
          <Column body={this.deleteButtonTemplate} />
        </DataTable>
      );
    }
  };

  handleDelete = (emergencyContactId, index) => {
    const { intl, value, handleUpdate } = this.props;
    const {
      USERS_EMERGENCY_PERSON_DELETE_SUCCESS,
      USERS_EMERGENCY_PERSON_DELETE_FAIL,
    } = MESSAGE_KEYS;
    const { ERROR, SUCCESS } = MESSAGE_SEVERITY;
    this.setState({ deletePending: true });
    sendQuery(
      `${QUERIES.DELETE_USERS_EMERGENCY_PERSON}/${emergencyContactId}`,
      "DELETE"
    )
      .then(
        () => {
          const _value = [...value];
          _value.splice(index, 1);
          handleUpdate(_value);
          this.toast.show({
            severity: SUCCESS,
            summary: intl.formatMessage({
              id: USERS_EMERGENCY_PERSON_DELETE_SUCCESS,
            }),
          });
        },
        (error) => {
          logger.error(error);
          this.toast.show({
            severity: ERROR,
            summary: intl.formatMessage({
              id: USERS_EMERGENCY_PERSON_DELETE_FAIL,
            }),
          });
        }
      )
      .finally(() => {
        this.setState({ deletePending: false });
      });
  };

  render = () => {
    const { intl, addRow, value, userId } = this.props;
    const { ERROR_RENDER, USERS_ADD_EMERGENCY_PERSON_LABEL } = MESSAGE_KEYS;
    const { showDialog, selectedIndex } = this.state;

    let val = selectedIndex !== null ? value[selectedIndex] : null;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <UserEmergencyPersonDialog
          visible={showDialog}
          onHide={() => {
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          handleSave={(newValue) => {
            addRow(newValue, selectedIndex);
            this.setState({ showDialog: false, selectedIndex: null });
          }}
          value={val}
          userId={userId}
        />
        <Button
          icon="pi pi-plus"
          label={intl.formatMessage({
            id: USERS_ADD_EMERGENCY_PERSON_LABEL,
          })}
          onClick={() => {
            this.setState({ showDialog: true, selectedIndex: null });
          }}
          disabled={value?.length === 4}
          className="mb-2"
        />
        {safeRender(this.renderTable, intl.formatMessage({ id: ERROR_RENDER }))}
      </div>
    );
  };
}

export default injectIntl(UserEmergencyPersonLayout);
