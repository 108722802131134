import React from "react";
// React redux
import { connect } from "react-redux";
// PrimeReact components
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, dateToString, findObjectById } from "common/Helpers";
// Static values
import { MESSAGE_KEYS, LOCALES } from "assets/staticData/enums";

const MyVacationsLayout = (props) => {
  const logger = initLogger("my_vacations_layout");
  const {
    intl,
    value: { vacationstates, currentLocale, myVacations, displayedDate },
  } = props;
  const {
    ERROR_RENDER,
    VACATIONS_MY_VACATIONS_LABEL,
    VACATIONS_STATUS_LABEL,
    VACATIONS_COMMENT_ADMIN_LABEL,
    VACATIONS_COMMENT_USER_LABEL,
    VACATIONS_NO_VACATIONS_LABEL,

    VACATIONS_HALF_DAY_LABEL,
  } = MESSAGE_KEYS;

  let headerLabel =
    displayedDate && !isNaN(displayedDate.getTime())
      ? displayedDate.getFullYear()
      : new Date().getFullYear();

  let content;

  try {
    content = [];
    if (myVacations && myVacations.length > 0) {
      myVacations.forEach((entry) => {
        const {
          starttime,
          endtime,
          state,
          remark,
          remark2,
          appointmentId,
          halfday,
        } = entry;
        let selectedState = findObjectById(
          vacationstates,
          state,
          "appointmentStateId"
        );

        let tooltipContent = null;
        let icon = "";
        if (remark || remark2) {
          tooltipContent = (
            <div>
              {remark && (
                <div>
                  <strong>
                    {intl.formatMessage({ id: VACATIONS_COMMENT_USER_LABEL })}
                  </strong>
                  <div></div>
                  {remark}
                </div>
              )}
              {remark2 && (
                <div>
                  <strong>
                    {intl.formatMessage({ id: VACATIONS_COMMENT_ADMIN_LABEL })}
                  </strong>
                  <div></div>
                  {remark2}
                </div>
              )}
            </div>
          );
          icon = `pi pi-comment${remark && remark ? "s" : ""}`;
        }

        content.push(
          <div key={`my_vct_${appointmentId}`}>
            <div className="flex flex-row justify-content-between align-items-center">
              <div>
                <strong>{`${dateToString(starttime)} - ${dateToString(
                  endtime
                )}`}</strong>
                {halfday && (
                  <div>
                    {intl.formatMessage({ id: VACATIONS_HALF_DAY_LABEL })}
                  </div>
                )}
                {selectedState ? (
                  <div>{`${intl.formatMessage({
                    id: VACATIONS_STATUS_LABEL,
                  })} ${
                    currentLocale && currentLocale === LOCALES.GERMAN.key
                      ? selectedState.nameDe
                      : selectedState.nameFr
                  }`}</div>
                ) : (
                  <></>
                )}
              </div>
              {tooltipContent ? (
                <Tippy content={tooltipContent}>
                  <i className={icon} style={{ fontSize: "2em" }} />
                </Tippy>
              ) : (
                <></>
              )}
            </div>
            <Divider />
          </div>
        );
      });
    } else {
      content = intl.formatMessage(
        { id: VACATIONS_NO_VACATIONS_LABEL },
        { year: headerLabel }
      );
    }
  } catch (renderException) {
    logger.warn(renderException);
    content = intl.formatMessage({ id: ERROR_RENDER });
  }
  return (
    <Panel
      header={intl.formatMessage(
        { id: VACATIONS_MY_VACATIONS_LABEL },
        { year: headerLabel }
      )}
    >
      {content}
    </Panel>
  );
};

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
    } = state;

    return { currentUser };
  } catch (mapException) {
    return { currentUser: null };
  }
};

export default connect(mapStateToProps, {})(injectIntl(MyVacationsLayout));
