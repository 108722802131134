import React, { useState } from "react";
// Redux
import { startOfflineMode } from "actions";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
// Helper functions
import { safeRender } from "common/Helpers";
// Static values
import messages from "data/messages";
import { MESSAGE_KEYS, LOCALES } from "assets/staticData/enums";
import { connect } from "react-redux";
/**
 * Renders the dialog where the user can switch to offline mode.
 * Will online be displayed if no user is logged in, backendAvailable is set to false and the login request is no longer pending.
 *
 * @param {Object} props
 * @param {Function} props.startOfflineMode
 * @param {Function} props.onhide
 * @param {Boolean} props.visible
 * @returns {JSX.Element}
 */
const OfflineDialog = (props) => {
  const { startOfflineMode, visible, onhide } = props;
  const {
    DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL,
    DIALOG_CANCEL_BUTTON_LABEL,
    ERROR_SERVER_NOT_AVAILABLE,
    ERROR,
    ERROR_RENDER,
  } = MESSAGE_KEYS;  

  const [inputLanguage, setLanguage] = useState(LOCALES.GERMAN);

  let message = messages[inputLanguage.key];

  const dialogFooter = (
    <div className="flex flex-row justify-content-between">
      <Button
        label={message[DIALOG_CONTINUE_OFFLINE_BUTTON_LABEL]}
        icon="pi pi-check"
        onClick={() => {
          onhide();
          startOfflineMode(inputLanguage);
        }}
      />
      <Button
        label={message[DIALOG_CANCEL_BUTTON_LABEL]}
        icon="pi pi-times"
        onClick={onhide}
      />
    </div>
  );
  const renderLocales = () => {
    let content = [];
    Object.keys(LOCALES).forEach((locale) => {
      let localeValue = LOCALES[locale];
      let id = `lng_${localeValue.key}`;
      content.push(
        <div className="p-field-radiobutton" key={id}>
          <RadioButton
            id={id}
            value={localeValue}
            name="lng"
            onChange={(e) => setLanguage(e.value)}
            checked={inputLanguage === localeValue}
          />
          <label htmlFor={id}>{message[localeValue.key]}</label>
        </div>
      );
    });
    return content;
  };
  return (
    <Dialog
      header={message[ERROR]}
      visible={visible}
      footer={dialogFooter}
      onHide={() => onhide()}
    >
      <div>
        <div className="mt-4 flex flex-row justify-content-between align-items-center">
          <i
            className="pi pi-exclamation-circle mr-2"
            style={{ fontSize: "40px" }}
          />
          {message[ERROR_SERVER_NOT_AVAILABLE]}
        </div>
        <div className="mt-2 flex flex-row justify-content-around align-items-center">
          {safeRender(renderLocales, message[ERROR_RENDER])}
        </div>        
      </div>
    </Dialog>
  );
};

export default connect(null, { startOfflineMode })(OfflineDialog);
