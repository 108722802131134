import React from "react";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { dateToString } from "common/Helpers";

const TooltipEdit = ({
  lastmodifyUserName,
  lastmodifyDate,
  createUserName,
  createDate,
}) => {
  const intl = useIntl();
  const { EDITED_BY, EDITED_ON, CREATED_BY, CREATED_ON } = MESSAGE_KEYS;

  return (
    <div>
      <div className="flex align-items-center">
        {intl.formatMessage({ id: EDITED_BY })}
        <strong className="ml-1">
          {lastmodifyUserName ? lastmodifyUserName : "-"}
        </strong>
      </div>
      <div className="flex align-items-center">
        {intl.formatMessage({ id: EDITED_ON })}
        <strong className="ml-1">
          {lastmodifyDate ? dateToString(new Date(lastmodifyDate), "/") : ""}
        </strong>
      </div>
      <div className="flex align-items-center">
        {intl.formatMessage({ id: CREATED_BY })}
        <strong className="ml-1">
          {createUserName ? createUserName : ""}
        </strong>
      </div>
      <div className="flex align-items-center">
        {intl.formatMessage({ id: CREATED_ON })}
        <strong className="ml-1">
          {createDate ? dateToString(new Date(createDate), "/") : ""}
        </strong>
      </div>
    </div>
  );
};

export default TooltipEdit;
