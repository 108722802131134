import React, { useState } from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import { confirmDialog } from "primereact/confirmdialog";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Helper functions
import {
  dateToQueryString,
  isCurrentUserAdmin,
  sendQuery,
  initLogger,
} from "common/Helpers";
// Generate download dialog
import download from "downloadjs";

const logger = initLogger("VacationActions");

const VacationActions = ({
  isDesktop,
  displayedDate,
  select,
  updateParent,
}) => {
  const intl = useIntl();
  const {
    VACATIONS_REPORT_CURRENT_LABEL,
    VACATIONS_CREATE_BUTTON_LABEL,
    VACATIONS_HOLIDAY_TITLE_LABEL,
    VACATIONS_CREATE_ADMIN_BUTTON_LABEL,

    VACATIONS_SET_OLD,
    VACATIONS_SET_OLD_CONFIRM,
    VACATIONS_SET_OLD_FAIL,
    DIALOG_CLOSE_BUTTON_LABEL,
    DIALOG_CONFIRM_BUTTON_LABEL,
    DIALOG_DENY_BUTTON_LABEL,
  } = MESSAGE_KEYS;

  const [closeResponse, setCloseResponse] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);

  /**
   * Fetches the vacation report for all users for the currently selected year from the backend.
   * Opens a download window for the report on success.
   */
  const fetchVacationReport = () => {
    try {
      let startDate = new Date(displayedDate.getTime());
      startDate.setMonth(0, 1);
      let endDate = new Date(displayedDate.getTime());
      endDate.setMonth(11, 31);
      sendQuery(
        `${QUERIES.GET_VACATION_REPORT_BY_DATE}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}`,
        "get",
        null,
        "blob"
      ).then(
        (response) => {
          download(response, "vacation-report.pdf", "application/pdf");
        },
        (error) => {
          logger.warn(error);
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
    }
  };

  const handleCloseClick = () => {
    confirmDialog({
      message: intl.formatMessage({ id: VACATIONS_SET_OLD_CONFIRM }),
      header: intl.formatMessage({ id: VACATIONS_SET_OLD }),
      icon: "pi pi-exclamation-triangle",
      accept: closeOldVacations,
      acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
      rejectLabel: intl.formatMessage({ id: DIALOG_DENY_BUTTON_LABEL }),
    });
  };

  const closeOldVacations = () => {
    try {
      let startDate = new Date();
      startDate.setFullYear(2000, 1, 1);
      sendQuery(
        `${QUERIES.UPDATE_OLD_VACATIONS}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(displayedDate)}`,
        "get"
      ).then(
        (response) => {
          setCloseResponse(response);
          setDialogVisible(true);
          updateParent({ value: displayedDate });
        },
        (error) => {
          logger.warn(error);
        }
      );
    } catch (closeException) {
      logger.warn(closeException);
    }
  };

  const renderCloseResponse = () => {
    if (closeResponse.length > 0) {
      const responses = [];
      for (let c = 0; c < closeResponse.length; c++) {
        responses.push(<li key={`close_response_${c}`}>{closeResponse[c]}</li>);
      }
      return (
        <ScrollPanel style={{ width: "100%", height: "200px" }}>
          <ul>{responses}</ul>
        </ScrollPanel>
      );
    } else {
      return <div>{intl.formatMessage({ id: VACATIONS_SET_OLD_FAIL })}</div>;
    }
  };

  let yearLabel =
    displayedDate && !isNaN(displayedDate.getTime())
      ? displayedDate.getFullYear()
      : new Date().getFullYear();

  return (
    <div
      className={`flex flex-row ${
        isDesktop ? "justify-content-end" : " justify-content-center"
      }`}
    >
      {isCurrentUserAdmin() && (
        <div className="m-2 flex">
          <Dialog
            visible={dialogVisible}
            onHide={() => {
              setCloseResponse([]);
              setDialogVisible(false);
            }}
            header={intl.formatMessage({ id: VACATIONS_SET_OLD })}
            style={{ maxWidth: "800px" }}
          >
            <div className="m-4">{renderCloseResponse()}</div>
            <div className="flex justify-content-end">
              <Button
                label={intl.formatMessage({ id: DIALOG_CLOSE_BUTTON_LABEL })}
                onClick={() => setDialogVisible(false)}
              />
            </div>
          </Dialog>
          <Button
            label={intl.formatMessage({ id: VACATIONS_SET_OLD })}
            onClick={handleCloseClick}
          />
          <Button
            label={intl.formatMessage(
              { id: VACATIONS_REPORT_CURRENT_LABEL },
              { year: yearLabel }
            )}
            onClick={fetchVacationReport}
            className="mx-1"
            icon="pi pi-download"
          />
          <Button
            label={intl.formatMessage(
              { id: VACATIONS_HOLIDAY_TITLE_LABEL },
              { year: yearLabel }
            )}
            onClick={() => select(null, false)}
            className="mx-1"
            icon="pi pi-calendar-plus"
          />
        </div>
      )}

      <div className="m-2">
        <Button
          label={intl.formatMessage({
            id: isCurrentUserAdmin()
              ? VACATIONS_CREATE_ADMIN_BUTTON_LABEL
              : VACATIONS_CREATE_BUTTON_LABEL,
          })}
          onClick={() => {
            select();
          }}
          className="mx-1"
          icon="pi pi-calendar-plus"
        />
      </div>
    </div>
  );
};

export default VacationActions;
