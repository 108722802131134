/**
 * This component renders the base layout of the right hand side of the customers view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending, setCustomerSession } from "actions/sessionActions";
// PrimeReact Components
import { ProgressSpinner } from "primereact/progressspinner";
// Custom Components
import { CustomerEditLayout } from "./";
// Static values
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Helper classes
import { sendQuery, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
import { isDesktop } from "react-device-detect";

const logger = initLogger("customer_edit_view");

class CustomerEditView extends React.Component {
  state = {
    selectedCustomer: null,
    customerFetchPending: false,
    customerError: null,
    displayedCustomer: null,
    addressList: [],
    billsList: [],
  };

  componentDidMount = () => {
    // Create ref to customer edit view for scroll to functionality.
    this.editViewRef = React.createRef();
    const { customerSession } = this.props;
    if (customerSession?.selectedSessionCustomer) {
      // Display session data.
      const {
        selectedSessionCustomer: {
          customer,
          addressList,
          invoiceList,
          filearchive,
        },
      } = customerSession;
      this.setState({
        displayedCustomer: customer,
        billsList: invoiceList,
        filearchive,
        addressList,
        customerFetchPending: false,
        selectedCustomer: customer?.personId ? customer.personId : null,
      });
    }
  };

  /**
   * Checks if the new customer button was clicked or a customer was selected in the customer view table.
   * If a different customer was selected, this function will fetch the selected customer's data from the backend and store if in the state's displayedCustomer object.
   * If the new customer button was clicked, the state's displayedCustomer object will be filled with empty values.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { selectedCustomer } = this.props;
    if (selectedCustomer !== prevProps.selectedCustomer) {
      if (selectedCustomer > 0) {
        this.setState(
          {
            customerFetchPending: true,
            customerError: null,
          },
          () => {
            this.fetchCustomer();
          }
        );
      } else {
        this.setState(
          {
            displayedCustomer: { personId: selectedCustomer },
            addressList: [],
            billsList: [],
            customerFetchPending: false,
          },
          this.handleScrollTo()
        );
      }
    }
  };

  handleSessionUpdate = (newCustomer = null) => {
    const { customerSession, setCustomerSession } = this.props;
    setCustomerSession({
      ...customerSession,
      selectedSessionCustomer: { ...newCustomer },
    });
  };

  /**
   * This function is used to scroll to the edit view.
   * Only available if  the edit view ref is set.
   */
  handleScrollTo = () => {
    if (this.editViewRef && !isDesktop) {
      this.editViewRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  handleParentUpdate = (newId) => {
    const { setChangePending, handleParentUpdate } = this.props;
    this.setState(
      {
        selectedCustomer: newId,
        displayedCustomer: { ...this.state.displayedCustomer, personId: newId },
      },
      () => {
        let upload = new Promise((resolve) => {
          // DOM manipulation to get upload button.
          let uploadButton = document.querySelector(
            ".p-fileupload-buttonbar > button:nth-child(2)"
          );
          try {
            uploadButton.click();
          } catch (clickException) {
            logger.error(clickException);
          } finally {
            resolve();
          }
        });
        upload
          .then(() => {
            handleParentUpdate(newId);
            this.fetchCustomer();
          })
          .finally(() => {
            setChangePending(false);
          });
      }
    );
  };

  /**
   * Attempts to fetch customer data from the backend.
   *
   * @returns {Promise<CustomerData|String>} - The customer data on success, an error code else.
   */
  fetchCustomer = () => {
    if (this.props.selectedCustomer) {
      try {
        const { selectedCustomer } = this.props;
        return sendQuery(
          `${QUERIES.GET_CUSTOMER_BY_ID}${selectedCustomer}`,
          "GET",
          null
        ).then(
          (response) => {
            const { customer, addressList, invoiceList, filearchive } =
              response;
            this.setState(
              {
                displayedCustomer: customer,
                billsList: invoiceList,
                addressList,
                customerFetchPending: false,
                filearchive,
              },
              () => {
                this.handleScrollTo();
                this.handleSessionUpdate(response);
              }
            );
          },
          (error) => {
            this.setState({
              displayedCustomer: null,
              addressList: [],
              billsList: [],
              customerFetchPending: false,
              customerError: error,
            });
            this.handleSessionUpdate();
          }
        );
      } catch (fetchException) {
        this.setState({
          displayedCustomer: null,
          addressList: [],
          billsList: [],
          customerFetchPending: false,
          customerError: fetchException.message,
        });
        this.handleSessionUpdate();
      }
    }
  };

  /**
   * Returns the customer edit layout component if the customer fetch request is done.
   * Returns an error message if an error occurred during the data transfer.
   * Will return a loading animation while the request is pending.
   *
   * @returns {JSX.Element}
   */
  renderCustomerInputs = () => {
    const {
      customerError,
      displayedCustomer,
      addressList,
      billsList,
      filearchive,
    } = this.state;

    if (customerError) {
      return (
        <div>
          {typeof customerError === "string"
            ? customerError
            : customerError.message}
        </div>
      );
    } else {
      return (
        <div>
          <CustomerEditLayout
            selectedCustomer={displayedCustomer}
            addressList={addressList}
            billsList={billsList}
            filearchive={filearchive}
            handleParentUpdate={this.handleParentUpdate}
          />
        </div>
      );
    }
  };

  /**
   * Renders a basic 3 column layout for the customer data-, bills- & payments-components when a customer is selected.
   * Will return a simple card component with a "Select customer" message else.
   *
   * @returns {JSX.Element}
   */
  renderContent = () => {
    const { selectedCustomer, intl, customerSession } = this.props;
    const { CUSTOMER_SELECTED_CUSTOMER_LABEL } = MESSAGE_KEYS;
    let content;
    if (this.state.customerFetchPending) {
      content = <ProgressSpinner />;
    } else if (
      selectedCustomer ||
      (customerSession?.selectedSessionCustomer?.customer &&
        this.state.displayedCustomer)
    ) {
      content = <div>{this.renderCustomerInputs()} </div>;
    } else {
      content = (
        <div>
          <strong>
            {intl.formatMessage({
              id: CUSTOMER_SELECTED_CUSTOMER_LABEL,
            })}
          </strong>
        </div>
      );
    }
    return content;
  };

  render = () => {
    return <div ref={this.editViewRef}>{this.renderContent()}</div>;
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      session: { customerSession = null },
    } = state;
    return {
      customerSession,
    };
  } catch (mapException) {
    logger.error(mapException);
    return {
      customerSession: null,
    };
  }
};

export default connect(mapStateToProps, {
  setChangePending,
  setCustomerSession,
})(injectIntl(CustomerEditView));
