import React from "react";

const ShoutboxEditorHeader = () => {
  //to limit the actions, there are some feature that could break the entire description structure :D
  return (
    <>
      <span className="ql-formats">
        <select className="ql-header"></select>
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="Bullet List"
        ></button>
        <button className="ql-link" aria-label="Insert Link"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </span>
    </>
  );
};

export default ShoutboxEditorHeader;
