import React from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
// Localization
import { useIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Styling
import "./Style.scss";
// Helper functions
import { initLogger, sendQuery } from "common/Helpers";
// Logging
const logger = initLogger("manual_entry_dialog");

const ManualEntryDialog = ({
  selDate,
  userId,
  handleRefresh,
  showModal,
  selAppointment,
  setSelAppointment,
  onHide,
  showToast,
}) => {
  const {
    DRIVES_HOURS_DEPARTURE,
    DRIVES_HOURS_ARRIVAL,
    APPOINTMENTS_COMMENT_LABEL,
    APPOINTMENTS_DRIVER_REPORT_SAVE,
    APPOINTMENTS_DRIVER_REPORT_EDIT,
    APPOINTMENTS_DRIVER_REPORT_CANCEL,
    APPOINTMENTS_DRIVER_REPORT_NEW,
  } = MESSAGE_KEYS;
  const intl = useIntl();

  const generateModalContent = () => {
    return (
      <div className="grid custom-dialog">
        <div className="col-6 mt-3">
          <span className="p-float-label">
            <InputMask
              type="tel"
              id={intl.formatMessage({ id: DRIVES_HOURS_ARRIVAL })}
              mask="99:99"
              value={
                selAppointment?.outwardArrival
                  ? selAppointment.outwardArrival
                  : null
              }
              onChange={(e) =>
                setSelAppointment({
                  ...selAppointment,
                  outwardArrival: e.value,
                  outwardPaper: e.value,
                })
              }
              style={
                selAppointment?.outwardArrival
                  ? {}
                  : { border: "1px solid red" }
              }
            />
            <label htmlFor={intl.formatMessage({ id: DRIVES_HOURS_ARRIVAL })}>
              {intl.formatMessage({ id: DRIVES_HOURS_ARRIVAL })}
            </label>
          </span>
        </div>
        <div className="col-6 mt-3">
          <span className="p-float-label">
            <InputMask
              type="tel"
              id={intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}
              mask="99:99"
              value={
                selAppointment?.outwardDeparture
                  ? selAppointment.outwardDeparture
                  : null
              }
              onChange={(e) =>
                setSelAppointment({
                  ...selAppointment,
                  outwardDeparture: e.value,
                  outwardDischarge: e.value,
                })
              }
            />
            <label htmlFor={intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}>
              {intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}
            </label>
          </span>
        </div>
        <div className="col-12 mt-3">
          <span className="p-float-label">
            <InputTextarea
              id={intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
              value={selAppointment?.remark ? selAppointment.remark : ""}
              onChange={(e) =>
                setSelAppointment({ ...selAppointment, remark: e.target.value })
              }
              autoResize={true}
              style={selAppointment?.remark ? {} : { border: "1px solid red" }}
            />
            <label
              htmlFor={intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
            >
              {intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
            </label>
          </span>
        </div>
      </div>
    );
  };

  const generateModalFooter = () => {
    return (
      <div>
        <p>
          <Button
            label={intl.formatMessage({ id: APPOINTMENTS_DRIVER_REPORT_SAVE })}
            className="p-button"
            onClick={() => saveNewReport()}
            disabled={
              !selAppointment?.remark || !selAppointment?.outwardArrival
            }
          />
          <Button
            label={intl.formatMessage({
              id: APPOINTMENTS_DRIVER_REPORT_CANCEL,
            })}
            className="p-button-danger"
            onClick={() => onHide("displayBasic")}
          />
        </p>
      </div>
    );
  };

  const saveNewReport = () => {
    if (
      selAppointment.remark !== "" &&
      selAppointment.outwardArrival !== null
    ) {
      if (
        selAppointment.outwardDeparture
          ? selAppointment.outwardArrival < selAppointment.outwardDeparture
          : true
      ) {
        let curDate = "";
        if (selDate !== null) {
          curDate = selDate;
          curDate.setHours(12, 0, 0);
          curDate.toISOString();
        } else {
          curDate = new Date();
          curDate.setHours(12, 0, 0);
          curDate.toISOString();
        }

        if (selAppointment.appointmentId) {
          selAppointment = {
            ...selAppointment,
            appointmentId: selAppointment.appointmentId,
          };
        } else {
          selAppointment = { ...selAppointment, starttime: curDate };
        }

        sendQuery(QUERIES.EDIT_APPOINTMENT, "post", selAppointment).then(
          () => {
            onHide();
            handleRefresh(userId);
          },
          (error) => {
            logger.error(error);
            onHide();
            showToast();
          }
        );
      } else {
        return;
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <Dialog
        header={
          selAppointment?.appointmentId
            ? intl.formatMessage({ id: APPOINTMENTS_DRIVER_REPORT_EDIT })
            : intl.formatMessage({ id: APPOINTMENTS_DRIVER_REPORT_NEW })
        }
        footer={generateModalFooter()}
        visible={showModal}
        onHide={() => onHide()}
        modal={true}
        draggable={false}
        resizable={false}
        blockScroll={true}
        className="manual-dialog"
      >
        {generateModalContent()}
      </Dialog>
    </div>
  );
};

export default ManualEntryDialog;
