/**
 * Functional object to generate the event as it will be displayed on the FullCalendar component.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React, { useRef } from "react";
// Custom components
import { AppointmentToolTip } from "./";
import { ContextMenu } from "primereact/contextmenu";
// Helper functions
import {
  truncateText,
  generateName,
  renderFullAddress,
  renderBoolean,
  dateToString,
  addLeadingZero,
  stringToOffsetDate,
} from "common/Helpers";
// Tippy.js tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faExclamationTriangle,
  faBolt,
  faTint,
  faBus,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faStairs } from "@fortawesome/pro-solid-svg-icons";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
const MAX_TEXT_LENGTH = 25;
const CANCELLED_STATE_ID = 6;
/**
 * This function generates the layout of the FullCalendare events; it differentiates between regular and background events.
 * - Regular events are rendered as colored boxes displaying the event informations; the colors represent the event status and if car & drivers are assigned.
 * - Background events only exist to change the background color of the respective column; these are hardcoded and will be generated every time the displayed date is changed.
 *
 * @param {Object} props - The function props.
 * @param {Object} props.eventData - The respective event
 */
const EventLayout = (props) => {
  const {
    intl,
    eventData = null,
    disableTooltip,
    showAdditionalAttr,
    handleShowHistoricalModal,
  } = props;

  const getHoursFromDate = (date) => {
    if (date) {
      return date.split("T")[1].split(".")[0].slice(0, 5);
    } else {
      return "?";
    }
  };

  const handleHistoryClick = () => {
    if (eventData.event._def.publicId) {
      handleShowHistoricalModal(eventData.event._def.publicId);
    } else {
      handleShowHistoricalModal(
        eventData.event._def.extendedProps.appointmentData.appointmentid
      );
    }
  };

  const cm = useRef(null);
  const items = [
    {
      label: intl.formatMessage({
        id: MESSAGE_KEYS.HISTORICAL_APPOINTMENTS_LABEL,
      }),
      icon: "pi pi-history",
      command: handleHistoryClick,
    },
  ];

  const {
    APPOINTMENTS_APPOINTMENT_LABEL,
    APPOINTMENTS_END_ADDRESS_LABEL,
    APPOINTMENTS_FIRST_DRIVER_LABEL,
    APPOINTMENTS_SECOND_DRIVER_LABEL,
    APPOINTMENTS_START_ADDRESS_LABEL,

    APPOINTMENTS_INFUSION_LABEL,
    APPOINTMENTS_CORONA_LABEL,
    APPOINTMENTS_OXYGEN_LABEL,
    APPOINTMENTS_DSA_LABEL,
    APPOINTMENTS_ROUND_TRIP_LABEL,

    APPOINTMENTS_COMMENT_LABEL,
    APPOINTMENTS_ISOLATION_LABEL,

    APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
    APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
    BILLS_AMOUNT_REMAINING_LABEL,

    EDITED_BY,
    EDITED_ON,
    CREATED_BY,
    CREATED_ON,

    ERROR_NO_DATA,
  } = MESSAGE_KEYS;

  try {
    if (props !== undefined && eventData !== undefined) {
      if (eventData.event && eventData.event._def.ui.display !== "background") {
        const {
          event: {
            _def: {
              start,
              end,
              extendedProps: {
                appointmentData: {
                  customerfullname,
                  toaddress,
                  fromaddress,
                  carId,
                  starttime,
                  firstDriverName,
                  secondDriverName,
                  appointmentstate,

                  roomTo,
                  departmentTo,
                  roomFrm,
                  departmentFrm,

                  oxygen,
                  dsa,
                  corona,
                  infusion,
                  nextAppointment,

                  remark,

                  createUserName,
                  createDate,
                  lastmodifyDate,
                  lastmodifyUserName,
                  showDescription,
                  isolated,

                  lastInvoiceDate,
                  lastInvoiceNumber,
                  notPaid,

                  fullFromAddress,
                  fullToAddress,

                  toAddressTxt,
                  fromAddressTxt,

                  titleId,

                  customerId,
                  returnAppointment,
                  healthInsuranceNumber,

                  firstCarLicencePlate,
                  secondCarLicencePlate,

                  meetingTime,

                  bus,
                  vaccinated,
                  stayByPatient,

                  lastName,
                  girlName,

                  firstDriverReturnName,
                  secondDriverReturnName,

                  wheelchair,
                  elevator,

                  syringepump,
                  disinfectionTxt,
                  disinfection,

                  driverHasRead,
                  entryCreatedManually,
                  endtime,
                  stairCount,
                  stairs,
                  hasReturn,
                },

                statusText,
                phone1,
                phone2,
              },
            },
          },
          view: { type },
        } = props.eventData;

        // Check for girl name and add to last name if available.
        let fullLastName = `${lastName}${girlName ? `-${girlName}` : ""}`;

        const { APPOINTMENTS_NO_CUSTOMER_NOTICE } = MESSAGE_KEYS;
        let toText = `${toaddress ?? ""} ${roomTo ?? ""} ${departmentTo ?? ""}`;
        let fromText = `${fromaddress ?? ""} ${roomFrm ?? ""} ${
          departmentFrm ?? ""
        }`;

        let textStyle =
          appointmentstate === CANCELLED_STATE_ID
            ? { textDecoration: "line-through", color: "#7f7f7f" }
            : {};

        let boxStyle = { height: "100%", width: "100%" };

        let returnTime = null;
        if (returnAppointment) {
          let splitTime = returnAppointment.split(":");
          if (splitTime.length >= 2) {
            returnTime = `${splitTime[0]}:${splitTime[1]}`;
          }
        }

        let formattedMeetingTime = null;
        if (meetingTime) {
          const timeSplitters = meetingTime.split(":");
          if (timeSplitters.length > 2) {
            formattedMeetingTime = `${timeSplitters[0]}:${timeSplitters[1]}`;
          } else {
            formattedMeetingTime = meetingTime;
          }
        }

        const showReturnIcon =
          returnAppointment ||
          ((firstDriverName || secondDriverName || firstCarLicencePlate) &&
            (firstDriverReturnName ||
              secondDriverReturnName ||
              secondCarLicencePlate))
            ? true
            : false;

        let customerWarning =
          customerId === null || customerId === undefined ? "!" : "";
        library.add(
          fas,
          faBolt,
          faExclamationTriangle,
          faTint,
          faBus,
          faExchangeAlt,
          faStairs
        );
        if (type === "resourceTimeGridDay") {
          let displayTo = "";
          let toBits = fullToAddress.split("#");
          if (toBits && toBits.length > 0) {
            displayTo = `${toText} ${toBits[0]} ${
              toBits.length >= 1 ? toBits[1] : ""
            }`;
          } else {
            displayTo = toText;
          }

          let displayFrom = "";
          let fromBits = fullFromAddress.split("#");
          if (fromBits && fromBits.length > 0) {
            displayFrom = `${fromText} ${fromBits[0]} ${
              fromBits.length >= 1 ? fromBits[1] : ""
            }`;
          } else {
            displayFrom = fromText;
          }
          const startDate = new Date(starttime);

          let slotTime = `${addLeadingZero(
            startDate.getUTCHours()
          )}:${addLeadingZero(startDate.getMinutes())}:00`;

          let showIcons =
            infusion ||
            dsa ||
            oxygen ||
            isolated ||
            bus ||
            hasReturn ||
            stairs ||
            nextAppointment ||
            driverHasRead === 2;
          let numberIconColumns = [
            infusion,
            dsa,
            oxygen,
            isolated,
            bus,
            hasReturn,
            driverHasRead === 2,
            stairs,
            nextAppointment,
          ].reduce((count, bool) => count + (bool ? 1 : 0));
          if (numberIconColumns > 0) {
            numberIconColumns = Math.ceil(numberIconColumns / 3);
          }
          const className = entryCreatedManually
            ? "schedule_timeslot manually_created_event"
            : "schedule_timeslot";
          let eventContent = (
            <div
              className={className}
              slot={slotTime}
              role={eventData.event._def.resourceIds[0]}
              onContextMenu={(e) => cm.current.show(e)}
            >
              <ContextMenu model={items} ref={cm} />
              <div
                style={boxStyle}
                onClick={(e) => {
                  if (e.ctrlKey && showAdditionalAttr) {
                    props.addSelection(
                      eventData.event._def.extendedProps.appointmentData
                        .appointmentid,
                      eventData.event._def.extendedProps.index
                    );
                  } else {
                    props.handleEventClick(props.eventData);
                  }
                }}
              >
                <div
                  className="flex flex-row justify-content-between align-items-start"
                  style={{
                    overflow: "clip",
                    whiteSpace: "nowrap",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: !showIcons
                        ? "100%"
                        : `calc(100% - ${numberIconColumns * 13}px)`,
                    }}
                  >
                    <strong>
                      {customerfullname
                        ? `${customerWarning} ${truncateText(
                            generateName(
                              lastName ? fullLastName : customerfullname,
                              titleId,
                              intl
                            ),
                            MAX_TEXT_LENGTH
                          )}`
                        : intl.formatMessage({
                            id: APPOINTMENTS_NO_CUSTOMER_NOTICE,
                          })}
                    </strong>

                    {showAdditionalAttr && (
                      <div className="event-insurance">
                        {healthInsuranceNumber}
                      </div>
                    )}

                    <div style={textStyle} className="second_violin">
                      <div>{displayFrom}</div>
                      <div>{displayTo}</div>
                      {showAdditionalAttr && (
                        <div className="event-start-end-hour">{`${dateToString(
                          stringToOffsetDate(starttime
                          ),
                          "/"
                        )}: ${getHoursFromDate(starttime)} - ${getHoursFromDate(
                          endtime
                        )} `}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="flex flex-column flex-wrap justify-content-between align-items-center second_violin py-1"
                    style={{
                      height: "100%",
                      backgroundColor: eventData?.backgroundColor,
                      width: `${13 * numberIconColumns}px`,
                    }}
                  >
                    {nextAppointment && (
                      <FontAwesomeIcon
                        icon={["fas", "calendar-check"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px", color: "yellow" }}
                        color="yellow"
                      />
                    )}
                    {infusion && (
                      <FontAwesomeIcon
                        icon={["fas", "tint"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px", color: "yellow" }}
                        color="yellow"
                      />
                    )}
                    {dsa && (
                      <FontAwesomeIcon
                        icon={["fas", "bolt"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px" }}
                        color="yellow"
                      />
                    )}
                    {oxygen && (
                      <strong
                        className="shadow_icon"
                        style={{ fontSize: "9px", color: "yellow" }}
                      >
                        O<sup>2</sup>
                      </strong>
                    )}
                    {isolated && (
                      <FontAwesomeIcon
                        icon={["fas", "exclamation-triangle"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px" }}
                        color="yellow"
                      />
                    )}
                    {bus && (
                      <FontAwesomeIcon
                        icon={["fas", "bus"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px" }}
                        color="yellow"
                      />
                    )}

                    {hasReturn && (
                      <FontAwesomeIcon
                        icon={["fas", "exchange-alt"]}
                        className="shadow_icon"
                        style={{ fontSize: "10px" }}
                        color="yellow"
                      />
                    )}
                    {driverHasRead === 2 && (
                      <div
                        style={{
                          background: "LightSkyBlue",
                          height: "8px",
                          width: "8px",
                          overflow: "visible",
                          borderRadius: "10px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          color={"green"}
                          style={{ position: "relative", top: "-1px" }}
                        />
                      </div>
                    )}
                    {stairs && (
                      <FontAwesomeIcon
                        icon={faStairs}
                        className="shadow_icon"
                        style={{ fontSize: "10px" }}
                        color="yellow"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );

          let event;
          if (disableTooltip || eventData.event.extendedProps.updatePending) {
            event = <div>{eventContent}</div>;
            if (eventData.event.extendedProps.updatePending) {
              event = (
                <div className="flex align-items-center justify-content-center">
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2em" }}
                  ></i>
                </div>
              );
            }
          } else {
            event = (
              <Tippy
                maxWidth={600}
                content={
                  <AppointmentToolTip
                    tooltipContent={{
                      ...eventData.event.extendedProps.appointmentData,

                      carId,
                      start,
                      end,
                      starttime: formattedMeetingTime,
                      createUserName,
                      createDate,
                      lastmodifyDate,
                      lastmodifyUserName,

                      lastInvoiceDate,
                      lastInvoiceNumber,
                      notPaid,

                      titleId,
                      statusText,

                      customerId,

                      returnAppointment: returnTime,

                      firstCarLicencePlate,
                      secondCarLicencePlate,

                      toAddressTxt,
                      fromAddressTxt,

                      bus,
                      vaccinated,
                      stayByPatient,

                      healthInsuranceNumber,

                      phone1,
                      phone2,

                      firstDriverReturnName,
                      secondDriverReturnName,

                      wheelchair,
                      elevator,

                      syringepump,
                      disinfectionTxt,
                      disinfection,

                      driverHasRead,
                      stairCount,
                      hasReturn,

                      showReturnIcon,
                    }}
                  />
                }
              >
                {eventContent}
              </Tippy>
            );
          }

          return event;
        } else {
          return (
            <div
              className="flex justify-content-between align-items-center"
              onClick={() => {
                props.handleEventClick(props.eventData);
              }}
            >
              <div>
                <strong>
                  {customerfullname
                    ? truncateText(
                        generateName(customerfullname, titleId, intl),
                        MAX_TEXT_LENGTH
                      )
                    : intl.formatMessage({
                        id: APPOINTMENTS_NO_CUSTOMER_NOTICE,
                      })}
                </strong>
                <div>{statusText}</div>
                <div>
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_APPOINTMENT_LABEL })}
                  </strong>
                  {`: ${
                    starttime ?? intl.formatMessage({ id: ERROR_NO_DATA })
                  }`}
                </div>
              </div>

              <div>
                <strong>
                  {intl.formatMessage({
                    id: APPOINTMENTS_START_ADDRESS_LABEL,
                  })}
                </strong>
                <div>{fromText}</div>
                <div>{renderFullAddress(fullFromAddress)}</div>
                <div>{fromAddressTxt ?? ""}</div>
                <strong>
                  {intl.formatMessage({ id: APPOINTMENTS_END_ADDRESS_LABEL })}
                </strong>
                <div>{toText}</div>
                <div>{renderFullAddress(fullToAddress)}</div>
                <div>{toAddressTxt ?? ""}</div>
              </div>

              <div>
                <strong>
                  {intl.formatMessage({
                    id: APPOINTMENTS_FIRST_DRIVER_LABEL,
                  })}
                </strong>
                <div>{`${
                  firstDriverName ?? intl.formatMessage({ id: ERROR_NO_DATA })
                }`}</div>
                <strong>
                  {intl.formatMessage({
                    id: APPOINTMENTS_SECOND_DRIVER_LABEL,
                  })}
                </strong>
                <div>{`${
                  secondDriverName ?? intl.formatMessage({ id: ERROR_NO_DATA })
                }`}</div>
              </div>
              <div>
                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_ROUND_TRIP_LABEL })}:
                  </strong>
                  {renderBoolean(hasReturn)}
                </div>

                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_OXYGEN_LABEL })}:
                  </strong>
                  {renderBoolean(oxygen)}
                </div>

                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_DSA_LABEL })}:
                  </strong>
                  {renderBoolean(dsa)}
                </div>
              </div>
              <div>
                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_INFUSION_LABEL })}:
                  </strong>
                  {renderBoolean(infusion)}
                </div>

                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_ISOLATION_LABEL })}:
                  </strong>
                  {renderBoolean(isolated)}
                </div>

                <div className="flex align-items-center justify-content-between">
                  <strong>
                    {intl.formatMessage({ id: APPOINTMENTS_CORONA_LABEL })}:
                  </strong>
                  {renderBoolean(corona)}
                </div>
              </div>

              {showDescription ? (
                <div className="flex">
                  <strong>
                    {intl.formatMessage({
                      id: APPOINTMENTS_COMMENT_LABEL,
                    })}
                  </strong>
                  {`: ${remark ?? ""}`}
                </div>
              ) : (
                <></>
              )}
              <div>
                <div className="flex">
                  <strong className="mr-1">
                    {intl.formatMessage({
                      id: APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
                    })}
                  </strong>
                  {lastInvoiceNumber ?? "-"}
                </div>

                <div className="flex">
                  <strong className="mr-1">
                    {intl.formatMessage({
                      id: APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
                    })}
                  </strong>
                  {lastInvoiceDate ? dateToString(lastInvoiceDate) : "-"}
                </div>

                <div className="flex">
                  <strong>
                    {intl.formatMessage({
                      id: BILLS_AMOUNT_REMAINING_LABEL,
                    })}
                  </strong>
                  {`: ${notPaid ?? "-"} €`}
                </div>
              </div>

              <div>
                <div className="flex align-items-center">
                  {intl.formatMessage({ id: EDITED_BY })}
                  <strong className="ml-1">{lastmodifyUserName ?? "-"}</strong>
                </div>
                <div className="flex align-items-center">
                  {intl.formatMessage({ id: EDITED_ON })}
                  <strong className="ml-1">
                    {lastmodifyDate
                      ? dateToString(new Date(lastmodifyDate), "/")
                      : ""}
                  </strong>
                </div>
                <div className="flex align-items-center">
                  {intl.formatMessage({ id: CREATED_BY })}
                  <strong className="ml-1">{createUserName ?? ""}</strong>
                </div>
                <div className="flex align-items-center">
                  {intl.formatMessage({ id: CREATED_ON })}
                  <strong className="ml-1">
                    {createDate ? dateToString(new Date(createDate), "/") : ""}
                  </strong>
                </div>
              </div>
            </div>
          );
        }
      } else {
        // No title for background events.
        return <></>;
      }
    } else {
      return (
        <div>
          {intl.formatMessage({
            id: MESSAGE_KEYS.APPOINTMENTS_NEW_EVENT_LABEL,
          })}
        </div>
      );
    }
  } catch (renderException) {
    return (
      <div>
        {intl.formatMessage({ id: MESSAGE_KEYS.APPOINTMENTS_NEW_EVENT_LABEL })}
      </div>
    );
  }
};

EventLayout.whyDidYouRender = true;

export default injectIntl(EventLayout);
