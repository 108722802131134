import React, { useState } from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
import { InputMask } from "primereact/inputmask";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const TicketTimes = ({
  title,
  isDeparture,
  inputData,
  updateParent,
  appointment,
  disabled,
  validPaper,
  validDischarge,
  lightHeader = false,
}) => {
  const intl = useIntl();
  const {
    DRIVES_PAPER_WAIT,
    DRIVES_HOURS_DEPARTURE,
    DRIVES_HOURS_ARRIVAL,
    DRIVES_ADMISSION,
  } = MESSAGE_KEYS;

  let initPaper;
  let initStartTime;
  let initEndTime;
  let initDischarge;

  let paperLabel;
  let startLabel;
  let endLabel;
  let dischargeLabel;

  const initHours = (date) => {
    return date ? date : "00:00";
  };

  if (isDeparture) {
    const { outwardPaper, outwardDeparture, outwardArrival, outwardDischarge } =
      appointment;
    initPaper = initHours(outwardPaper);
    paperLabel = "outwardPaper";
    initStartTime = initHours(outwardDeparture);
    startLabel = "outwardDeparture";
    initEndTime = initHours(outwardArrival);
    endLabel = "outwardArrival";
    initDischarge = initHours(outwardDischarge);
    dischargeLabel = "outwardDischarge";
  } else {
    const { returnPaper, returnDeparture, returnArrival, returnDischarge } =
      appointment;
    initPaper = initHours(returnPaper);
    paperLabel = "returnPaper";
    initStartTime = initHours(returnDeparture);
    startLabel = "returnDeparture";
    initEndTime = initHours(returnArrival);
    endLabel = "returnArrival";
    initDischarge = initHours(returnDischarge);
    dischargeLabel = "returnDischarge";
  }

  const [startTime, setStartTime] = useState(initStartTime);
  const [endTime, setEndTime] = useState(initEndTime);
  const [paperTime, setPaperTime] = useState(initPaper);
  const [dischargeTime, setDischargeTime] = useState(initDischarge);

  return (
    <div className="p-2">
      {lightHeader ? (
        <div className="font-light split_label">{title}</div>
      ) : (
        <div>
          <div className="ticket_column_title">{title}</div>
          <Divider />
        </div>
      )}
      <div className="grid">
        <div className="col-6 flex align-items-center">
          {intl.formatMessage({ id: DRIVES_PAPER_WAIT })}
        </div>
        <div className="col-6 flex align-items-center">
          <InputMask
            type="tel"
            mask="99:99"
            slotChar="hh:mm"
            value={paperTime}
            onChange={(event) => {
              setPaperTime(event.value);
              updateParent({ ...inputData, [paperLabel]: event.value });
            }}
            disabled={disabled}
            className={validPaper ? "" : "p-invalid"}
            style={{ maxWidth: "65px" }}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-6 flex align-items-center">
          {intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}
        </div>
        <div className="col-6 flex align-items-center">
          <InputMask
            type="tel"
            mask="99:99"
            slotChar="hh:mm"
            value={startTime}
            onChange={(event) => {
              setStartTime(event.value);
              updateParent({ ...inputData, [startLabel]: event.value });
            }}
            disabled={disabled}
            style={{ maxWidth: "65px" }}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-6 flex align-items-center">
          {intl.formatMessage({ id: DRIVES_HOURS_ARRIVAL })}
        </div>
        <div className="col-6 flex align-items-center">
          <InputMask
            type="tel"
            mask="99:99"
            slotChar="hh:mm"
            value={endTime}
            onChange={(event) => {
              setEndTime(event.value);
              updateParent({ ...inputData, [endLabel]: event.value });
            }}
            disabled={disabled}
            style={{ maxWidth: "65px" }}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-6 flex align-items-center">
          {intl.formatMessage({ id: DRIVES_ADMISSION })}
        </div>
        <div className="col-6 flex align-items-center">
          <InputMask
            type="tel"
            mask="99:99"
            slotChar="hh:mm"
            value={dischargeTime}
            onChange={(event) => {
              setDischargeTime(event.value);
              updateParent({
                ...inputData,
                [dischargeLabel]: event.value,
              });
            }}
            disabled={disabled}
            className={validDischarge ? "" : "p-invalid"}
            style={{ maxWidth: "65px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketTimes;
