import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { dateToTime } from "common/Helpers";

const TooltipDrive = ({
  title,
  firstCarLicencePlate,
  firstDriverName,
  secondDriverName,
  starttime,
}) => {
  const intl = useIntl();

  const {
    APPOINTMENTS_DRIVE_VEHICLE,
    ERROR_NO_DATA,
    APPOINTMENTS_FIRST_DRIVER_LABEL,
    APPOINTMENTS_SECOND_DRIVER_LABEL,
    APPOINTMENTS_RETURN_TIME_LABEL,
  } = MESSAGE_KEYS;

  let timeText = "";
  if (starttime && Date.parse(starttime)) {
    timeText = dateToTime(new Date(starttime));
  }

  return (
    <div>
      <div className="flex">
        <strong>{intl.formatMessage({ id: title })}</strong>
      </div>
      {firstCarLicencePlate ? (
        <div className="flex">
          <strong>
            {intl.formatMessage({
              id: APPOINTMENTS_DRIVE_VEHICLE,
            })}
          </strong>
          {`: ${firstCarLicencePlate}`}
        </div>
      ) : null}
      <div className="flex">
        <strong>
          {intl.formatMessage({
            id: APPOINTMENTS_FIRST_DRIVER_LABEL,
          })}
        </strong>
        {`: ${
          firstDriverName
            ? firstDriverName
            : intl.formatMessage({ id: ERROR_NO_DATA })
        }`}
      </div>
      {secondDriverName ? (
        <div className="flex">
          <strong>
            {intl.formatMessage({
              id: APPOINTMENTS_SECOND_DRIVER_LABEL,
            })}
          </strong>
          {`: ${secondDriverName}`}
        </div>
      ) : null}
      {timeText && (
        <strong>{`${intl.formatMessage({
          id: APPOINTMENTS_RETURN_TIME_LABEL,
        })}: ${timeText}`}</strong>
      )}
      <Divider />
    </div>
  );
};

export default TooltipDrive;
