import React, { useState } from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const OnCallEventPrompt = ({ onConfirm, onCancel, isSpecial, visible }) => {
  const intl = useIntl();
  const [limit, setLimit] = useState(0);
  const [title, setTitle] = useState("");
  const {
    CUSTOMERS_TITLE_LABEL,
    ON_CALLS_NUMBER_SLOTS_LABEL,
    ON_CALLS_EDIT_BUTTON_LABEL,
    ON_CALLS_NEW_SHIFT_TITLE,
    DIALOG_CANCEL_BUTTON_LABEL,
  } = MESSAGE_KEYS;

  const footer = (
    <div className="flex justify-content-between p-2">
      <Button
        className="mr-2"
        label={intl.formatMessage({ id: ON_CALLS_EDIT_BUTTON_LABEL })}
        onClick={() => onConfirm(limit, title)}
      />
      <Button
        label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
        onClick={onCancel}
      />
    </div>
  );

  return (
    <Dialog
      header={intl.formatMessage({ id: ON_CALLS_NEW_SHIFT_TITLE })}
      footer={footer}
      onHide={onCancel}
      visible={visible}
      style={{ overflowY: "none !important" }}
      id="new_oncall_dialog"
    >
      <div>
        <span className="p-float-label mt-5">
          <InputNumber
            id="oc_event_prompt_limit"
            value={limit}
            onChange={(e) => setLimit(e.value)}
          />
          <label htmlFor="oc_event_prompt_limit">
            {intl.formatMessage({
              id: ON_CALLS_NUMBER_SLOTS_LABEL,
            })}
          </label>
        </span>
        {isSpecial && (
          <FloatingTextInput
            className="mt-4"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label={intl.formatMessage({
              id: CUSTOMERS_TITLE_LABEL,
            })}
          />
        )}
      </div>
    </Dialog>
  );
};

export default OnCallEventPrompt;
