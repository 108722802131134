import React from "react";

// Style
import "./Style.scss";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Custom components
import { DriverStatusHeader, DriverStatusTable } from "./components";
import { initLogger, sendQuery } from "common/Helpers";
import { DriverStatusLoading } from "./components/index";
import { getLocalTimeDate } from "common/Helpers";

// Websocket connections
import { Client } from "@stomp/stompjs";

const { MENU_DRIVER_STATUS } = MESSAGE_KEYS;

// Live update
let websocket = null;

const logger = initLogger("driverStatus_view");

class DriverStatusView extends React.Component {
  componentDidMount = () => {
    const { setTopbarTitle, intl } = this.props;
    setTopbarTitle(intl.formatMessage({ id: MENU_DRIVER_STATUS }));
    this.getLatestStates();
    this.connectWebsocket();
  };

  componentWillUnmount = () => {
    this.disconnectWebsocket();
  };

  state = {
    filterStatus: "sortByState",
    filterSearch: "",
    pending: true,
    driverStates: [],
    displayDriverStates: [],
  };

  connectWebsocket = () => {
    let brokerURL;

    switch (process.env.REACT_APP_SETTING) {
      case "prod":
      case "start":
      case "start-prod":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_PROD;
        break;
      case "demo":
      case "start-demo":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEMO;
        break;
      case "kai":
      case "start-local-kai":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_KAI;
        break;
      default:
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEV;
    }

    try {
      websocket = new Client();
      websocket.configure({
        brokerURL,
        onConnect: () => {
          websocket.subscribe(
            process.env.REACT_APP_WEBSOCKET_DRIVER_STATE_SUBSCRIBE,
            (msg) => {
              try {
                let newStatusObject = JSON.parse(msg.body);
                let isNew = true;

                const updatedDriverStates = this.state.driverStates.map(
                  (driverStatus) => {
                    if (
                      //TODO hier muss nochmal gecheckt werden ob es sich um einen VAC oder nur status change, sonst wenn zB ATW VAC ist aber einen status ändert, dann werden beide objekte geändert :(
                      driverStatus.personId === newStatusObject.personId /*  &&
                      getStatusNameById(
                        driverStatus.driverState,
                        this.props.intl
                      ) !== ""  */ //status wie VAC soll NICHT geändert werden, nur S1-S8
                    ) {
                      isNew = false;

                      const oldObjectWithHist = {
                        ...driverStatus,
                        histList: driverStatus.histList
                          ? driverStatus.driverStateId !==
                            newStatusObject.driverStateId
                            ? [...driverStatus.histList, driverStatus]
                            : [...driverStatus.histList] //erweitere das Array mit dem alten Status
                          : [driverStatus], //erzeuge ein neues array mit dem alten status
                      };

                      return {
                        ...newStatusObject,
                        histList: oldObjectWithHist.histList,
                      };
                    }
                    return driverStatus;
                  }
                );

                if (isNew) {
                  this.setState({
                    driverStates: [...this.state.driverStates, newStatusObject],
                  });
                } else {
                  this.setState({ driverStates: [...updatedDriverStates] });
                }

                setTimeout(() => {
                  this.setOrder(this.state.filterStatus);
                }, 100);
              } catch (wsException) {
                logger.error(wsException);
              }
            }
          );
        },
      });

      websocket.activate();
    } catch (connectException) {
      logger.error("Could not connect to websocket.");
    }
  };

  disconnectWebsocket = () => {
    try {
      if (websocket) {
        websocket.deactivate();
      }
    } catch (discoException) {
      logger.error(discoException);
    }
  };

  getLatestStates = () => {
    sendQuery(
      `${QUERIES.GET_DAILY_DRIVER_STATUS}${getLocalTimeDate()}`,
      "get"
    ).then(
      (data) => {
        this.setState({
          pending: false,
          driverStates: [...data],
        });
        //a little timeout: to prevent ordering issues.
        setTimeout(() => {
          this.setOrder("sortByState");
        });
      },
      (error) => {
        logger.error(error);
        this.setState({ pending: false, driverStates: null });
      }
    );
  };

  setOrder = (value) => {
    if (this.state.driverStates) {
      let filtered = [];
      switch (value) {
        case "driverName":
          filtered = this.state.driverStates.sort((a, b) =>
            a[value].toLowerCase() > b[value].toLowerCase() && a[value] !== null
              ? 1
              : -1
          );
          break;
        case "licencePlate":
          filtered = this.state.driverStates.sort((a, b) =>
            a[value] !== null
              ? a[value]?.toLowerCase() > b[value]?.toLowerCase()
                ? 1
                : -1
              : -1
          );
          break;
        case "lastModifyDate":
          filtered = this.state.driverStates.sort((a, b) =>
            a[value] < b[value] ? 1 : -1
          );
          break;
        case "sortByState":
          filtered = this.state.driverStates.sort((a, b) =>
            a[value] > b[value] && a[value] !== null
              ? 1
              : a[value] === b[value] &&
                a["licencePlate"] != null &&
                b["licencePlate"] != null
              ? a["licencePlate"].toLowerCase() >
                b["licencePlate"].toLowerCase()
                ? 1
                : -1
              : -1
          );
          break;
        default:
          break;
      }
      this.setState(
        {
          filterStatus: value,
          displayDriverStates: [...filtered],
        },
        () => {
          if (
            this.state.filterSearch !== null ||
            this.state.filterSearch !== ""
          ) {
            this.setFilterSearch(this.state.filterSearch);
          }
        }
      );
    } else {
      this.setState({ filterStatus: value });
    }
  };

  setFilterSearch = (value) => {
    if (this.state.driverStates) {
      const filtererdArray = this.state.driverStates.filter(
        (status) =>
          status?.driverName?.toLowerCase().includes(value.toLowerCase()) ||
          status?.licencePlate?.toLowerCase().includes(value.toLowerCase()) ||
          status?.driverState?.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ displayDriverStates: [...filtererdArray] });
    }

    this.setState({ filterSearch: value });
  };

  render = () => {
    return (
      <div>
        <div className="flex align-items-center driver_status_header">
          <DriverStatusHeader
            filterStatus={this.state.filterStatus}
            setFilterStatus={this.setOrder}
            filterSearch={this.state.filterSearch}
            setFilterSearch={this.setFilterSearch}
          />
        </div>
        <div className="driver_status_container">
          {this.state.pending ? (
            <DriverStatusLoading />
          ) : (
            <DriverStatusTable driversStatus={this.state.displayDriverStates} />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.authentication,
    ...state.application,
  };
};

export default connect(mapStateToProps, { setTopbarTitle })(
  injectIntl(DriverStatusView)
);
