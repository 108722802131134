import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending } from "actions/sessionActions";
// Responsive
import { isDesktop } from "react-device-detect";
// Localization
import { injectIntl } from "react-intl";
// Primereact components
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import { Dropdown } from "primereact/dropdown";
// Helper functions
import {
  sendQuery,
  initLogger,
  equalDates,
  equalObjects,
  dateToString,
  numberToPriceString,
  dateToISOString,
  hasValueChanged,
  valiDate,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import {
  SplitDateTimeInput,
  CustomerSelector,
  BillSelector,
} from "components/common";

const availableOptions = [
  {
    name: `BILL`,
    value: "bill",
  },
  {
    name: `BCEE`,
    value: "bcee",
  },
  {
    name: `CCRA`,
    value: "ccra",
  },
];

// Logging
const logger = initLogger("payment_edit_layout");

const EMPTY_STATE = Object.freeze({
  inputCustomer: null,
  inputBill: null,
  inputBillingDate: new Date(),
  inputAmount: 0,
  inputBank: "",
  inputComment: "",
  inputActive: true,
  validCustomer: null,
  validBill: null,
  validAmount: null,
  validBank: null,
  validBillingDate: null,
});

class PaymentEditLayout extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    try {
      const {
        selectedPayment: { paymentId },
      } = this.props;
      if (
        !prevProps.selectedPayment ||
        paymentId !== prevProps.selectedPayment.paymentId
      ) {
        this.initInputs();
      }
    } catch (dumException) {
      logger.warn(dumException);
    }
  };

  handleSaveClick = () => {
    const { intl, handleParentUpdate } = this.props;
    this.validateInputs().then(
      (newPayment) => {
        if (newPayment) {
          this.sendData(newPayment).then(
            (response) => {
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: intl.formatMessage({
                  id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
                }),
              });
              this.setState({ savePending: false });
              handleParentUpdate(response.paymentId);
            },
            (error) => {
              logger.error(error);
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : this.props.intl.formatMessage({
                        id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                      }),
              });
              this.setState({ savePending: false });
            }
          );
        }
      },
      (validationErrors) => {
        this.toast.show(validationErrors);
      }
    );
  };

  initInputs = () => {
    const { selectedPayment } = this.props;
    if (selectedPayment && selectedPayment.paymentId > 0) {
      const { amount, paymentDate, bank, info, bill, invoiceCustomer, active } =
        selectedPayment;
      this.setState({
        inputCustomer: invoiceCustomer ? invoiceCustomer : null,
        inputBill: bill ? bill : null,
        inputBillingDate: new Date(paymentDate ? paymentDate : null),
        inputAmount: amount ? amount : 0,
        inputBank: bank ? bank : "",
        inputComment: info ? info : "",
        inputActive: active === true ? true : false,
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
        paymentId: new Date().getTime() * -1,
      });
    }
  };

  mapStateToDTO = () => {
    let dto;
    try {
      const { selectedPayment } = this.props;
      const {
        inputAmount,
        inputBank,
        inputBill,
        inputCustomer,
        inputBillingDate,
        inputComment,
        inputActive,
      } = this.state;

      dto = {
        paymentId:
          selectedPayment && selectedPayment.paymentId > 0
            ? selectedPayment.paymentId
            : null,
        amount: inputAmount ? inputAmount : 0.0,
        paymentDate: inputBillingDate
          ? dateToISOString(inputBillingDate)
          : null,
        bank: inputBank ? inputBank : "",
        info: inputComment ? inputComment : "",
        transactionId:
          inputBill && inputBill.transactionId ? inputBill.transactionId : null,
        active: inputActive,
        invoiceCustomer: inputCustomer,
      };
    } catch (mapException) {
      logger.warn("Exception op map state to DTO", mapException);
      dto = null;
    } finally {
      return dto;
    }
  };

  /**
   * Checks if the current inputs are different from the selected object.
   *
   * @param {String} newKey
   * @param {Any} newValue
   */
  checkChangePending = (keyValue) => {
    let pending = false;
    const {
      selectedPayment: {
        bank,
        paymentDate,
        amount,
        info,
        bill,
        customer,
        active,
      },
      setChangePending,
    } = this.props;

    try {
      let payment = { ...this.state, ...keyValue };
      const {
        inputAmount,
        inputBank,
        inputBill,
        inputCustomer,
        inputBillingDate,
        inputComment,
        inputActive,
      } = payment;
      pending =
        hasValueChanged(amount, inputAmount) ||
        hasValueChanged(bank, inputBank) ||
        !equalDates(inputBillingDate, paymentDate) ||
        hasValueChanged(info, inputComment) ||
        !equalObjects(bill, inputBill, "transactionId") ||
        !equalObjects(customer, inputCustomer, "personId") ||
        hasValueChanged(active, inputActive);
    } catch (checkException) {
      logger.warn("Exception on check change pending", checkException);
      pending = true;
    } finally {
      setChangePending(pending);
    }
  };

  /**
   * Sends the modified data to the server.
   * @param {Object} data
   * @returns {Promise<Object|String>}
   */
  sendData = (data) => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.EDIT_PAYMENT, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
        // TODO Handle offline action
      } catch (queryException) {
        logger.error(queryException);
        reject(queryException);
      }
    });
  };

  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        const { intl } = this.props;
        const {
          PAYMENTS_VALIDATION_ERROR_BANK,
          PAYMENTS_VALIDATION_ERROR_CUSTOMER,
          PAYMENTS_VALIDATION_ERROR_BILL,
          PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT,
          PAYMENTS_VALIDATION_ERROR_BILLING_DATE,
        } = MESSAGE_KEYS;
        const { ERROR } = MESSAGE_SEVERITY;
        const {
          inputAmount,
          inputBank,
          inputBill,
          inputCustomer,
          inputBillingDate,
        } = this.state;

        let validationErrors = [];

        let validCustomer =
          inputCustomer !== null &&
          inputCustomer !== undefined &&
          !isNaN(inputCustomer.personId);
        if (!validCustomer) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: PAYMENTS_VALIDATION_ERROR_CUSTOMER,
            }),
          });
        }
        let validBill = inputBill !== null && !isNaN(inputBill.transactionId);
        if (!validBill) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: PAYMENTS_VALIDATION_ERROR_BILL }),
          });
        }
        let validAmount = !isNaN(inputAmount);
        if (!validAmount) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT,
            }),
          });
        }
        let validBank = typeof inputBank === "string" && inputBank.length > 0;
        if (!validBank) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: PAYMENTS_VALIDATION_ERROR_BANK }),
          });
        }
        let validBillingDate = !isNaN(inputBillingDate.getTime());
        if (!validBillingDate) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: PAYMENTS_VALIDATION_ERROR_BILLING_DATE,
            }),
          });
        }

        this.setState({
          validCustomer,
          validBill,
          validAmount,
          validBank,
          validBillingDate,
        });

        if (validationErrors.length === 0) {
          resolve(this.mapStateToDTO());
        } else {
          reject(validationErrors);
        }
      } catch (validationException) {
        reject({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR }),
        });
      }
    });
  };

  renderEditInputs = () => {
    const { intl } = this.props;
    const {
      PAYMENTS_DATE_LABEL,
      PAYMENTS_AMOUNT_LABEL,
      PAYMENTS_BANK_LABEL,
      BILLS_COMMENT_INTERNAL_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputCustomer,
      inputBill,
      inputBillingDate,
      inputAmount,
      inputBank,
      inputComment,

      validAmount,
      //validBank,
      validBill,
      validCustomer,
      validBillingDate,
    } = this.state;

    return (
      <div className={isDesktop ? "p-fluid formgrid grid" : ""}>
        <div className={isDesktop ? "p-field col-6" : "payment_filter_row"}>
          <CustomerSelector
            value={inputCustomer}
            onChange={(selection) =>
              this.setState({ inputCustomer: selection }, () =>
                this.checkChangePending({ inputCustomer: selection })
              )
            }
            valid={validCustomer}
          />
        </div>
        <div className={isDesktop ? "p-field col-6" : "payment_filter_row"}>
          <BillSelector
            value={inputBill}
            onChange={(selection) =>
              this.setState({ inputBill: selection }, () =>
                this.checkChangePending({ inputBill: selection })
              )
            }
            valid={validBill}
          />
        </div>

        <div
          className={
            isDesktop ? "p-field col-4 mb-4" : "payment_filter_row"
          }
        >
          {/*   <FloatingTextInput
            id={PAYMENTS_BANK_LABEL}
            value={inputBank}
            onChange={(event) =>
              this.setState({ inputBank: event.target.value }, () =>
                this.checkChangePending({ inputBank: event.target.value })
              )
            }
            label={intl.formatMessage({ id: PAYMENTS_BANK_LABEL })}
            valid={validBank}
          /> */}
          <span
            className="p-float-label  input_wrapper"
            style={{ width: "100%" }}
          >
            <Dropdown
              id={PAYMENTS_BANK_LABEL}
              options={availableOptions}
              value={inputBank.toLowerCase()}
              optionLabel="name"
              onChange={(event) =>
                this.setState({ inputBank: event.value }, () =>
                  this.checkChangePending({ inputBank: event.value })
                )
              }
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: PAYMENTS_BANK_LABEL })}
            </label>
          </span>
        </div>

        <div
          className={
            isDesktop ? "p-field col-4 mb-4" : "payment_filter_row"
          }
        >
          <span className="p-float-label input_wrapper">
            <InputNumber
              id={PAYMENTS_AMOUNT_LABEL}
              value={inputAmount}
              onValueChange={(e) =>
                this.setState({ inputAmount: e.value }, () =>
                  this.checkChangePending({ inputAmount: e.value })
                )
              }
              suffix=" €"
              className={`${
                validAmount === true || validAmount === null ? "" : "p-invalid"
              }`}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              onFocus={(e) => e.target.select()}
              useGrouping={false}
              locale="en-US"
            />
            <label htmlFor={PAYMENTS_AMOUNT_LABEL}>
              {intl.formatMessage({ id: PAYMENTS_AMOUNT_LABEL })}
            </label>
          </span>
        </div>

        <div
          className={
            isDesktop
              ? "p-field col-4 mb-4 flex align-items-end"
              : "payment_filter_row"
          }
        >
          <SplitDateTimeInput
            value={inputBillingDate}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState(
                {
                  inputBillingDate: newDate,
                },
                this.checkChangePending("inputBillingDate", newDate)
              );
            }}
            label={intl.formatMessage({
              id: PAYMENTS_DATE_LABEL,
            })}
            valid={validBillingDate}
            className="mr-2"
          />
        </div>

        <div className={isDesktop ? "p-field col-12" : "payment_filter_row"}>
          <span className="p-float-label" style={{ width: "100%" }}>
            <InputTextarea
              id="inputComment"
              value={inputComment}
              onChange={(e) =>
                this.setState({ inputComment: e.target.value }, () =>
                  this.checkChangePending({ inputComment: e.target.value })
                )
              }
              rows={5}
              cols={30}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}
            </label>
          </span>
        </div>
      </div>
    );
  };

  renderBillAddress = (address) => {
    const { intl } = this.props;
    let addressInfo;
    try {
      if (address) {
        const { line1, line2, zipCode, countryProvince } = address;
        addressInfo = [
          <span key="pmt_adr_ln1">{line1 ? line1 : "-"}</span>,
          <span key="pmt_adr_ln2">{line2 ? line2 : "-"}</span>,
          <span key="pmt_adr_ln3">
            {zipCode || countryProvince
              ? `${zipCode ? zipCode : ""} ${
                  countryProvince ? countryProvince : "-"
                }`
              : "-"}
          </span>,
        ];
      } else {
        addressInfo = <span>-</span>;
      }
    } catch (renderException) {
      logger.warn("Exception on render bill address.");
      addressInfo = (
        <span>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</span>
      );
    } finally {
      return addressInfo;
    }
  };

  renderBillData = () => {
    const { intl } = this.props;
    const {
      BILLS_CUSTOMER_LABEL,
      PAYMENTS_BILLING_ADDRESS_LABEL,
      BILLS_POSITIONS_TOTAL_AMOUNT_LABEL,
      BILLS_AMOUNT_PAID_LABEL,
    } = MESSAGE_KEYS;
    if (this.state.inputBill) {
      const {
        inputBill: {
          totalPrice,
          customer,
          invoiceAddress,
          totalPaidReal,
          orderDate,
        },
      } = this.state;
      return (
        <div className="grid">
          <div className="col-5 text-right">
            {intl.formatMessage({ id: MESSAGE_KEYS.PAYMENTS_DATE_LABEL })}
          </div>
          <div className="col-7">
            {orderDate ? dateToString(orderDate) : "-"}
          </div>
          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_CUSTOMER_LABEL })}
          </div>
          <div className="col-7">
            {customer && (customer.firstname || customer.lastname)
              ? `${customer.firstname ? customer.firstname : ""} ${
                  customer.lastname ? customer.lastname : ""
                }`
              : "-"}
          </div>

          <div className="col-5 text-right col-align-start">
            {intl.formatMessage({ id: PAYMENTS_BILLING_ADDRESS_LABEL })}
          </div>
          <div className="col-7 col-align-start flex flex-column">
            {this.renderBillAddress(invoiceAddress)}
          </div>

          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_POSITIONS_TOTAL_AMOUNT_LABEL })}
          </div>
          <div className="col-7">{numberToPriceString(totalPrice)}</div>

          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_AMOUNT_PAID_LABEL })}
          </div>
          <div className="col-7">{numberToPriceString(totalPaidReal)}</div>
        </div>
      );
    } else {
      return (
        <div className="grid">
          <div className="col-5 text-right">
            {intl.formatMessage({ id: MESSAGE_KEYS.PAYMENTS_DATE_LABEL })}
          </div>
          <div className="col-7">-</div>
          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_CUSTOMER_LABEL })}
          </div>
          <div className="col-7">-</div>

          <div className="col-5 text-right col-align-start">
            {intl.formatMessage({ id: PAYMENTS_BILLING_ADDRESS_LABEL })}
          </div>
          <div className="col-7 col-align-start flex flex-column">
            -
          </div>

          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_POSITIONS_TOTAL_AMOUNT_LABEL })}
          </div>
          <div className="col-7">-</div>

          <div className="col-5 text-right">
            {intl.formatMessage({ id: BILLS_AMOUNT_PAID_LABEL })}
          </div>
          <div className="col-7">-</div>
        </div>
      );
    }
  };

  /**
   * Renders the bottom row of the form.
   * Returns a loading animation if a data transfer is pending, returns a row containing the save- & reset-button else.
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      BILLS_CREATE_BUTTON_LABEL,
      BILLS_SAVE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { pending, intl } = this.props;
    if (pending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else {
      let saveButtonLabel = "";
      if (this.state.isNewBill) {
        saveButtonLabel = intl.formatMessage({
          id: BILLS_CREATE_BUTTON_LABEL,
        });
      } else {
        saveButtonLabel = intl.formatMessage({
          id: BILLS_SAVE_BUTTON_LABEL,
        });
      }
      return (
        <div className="account_button_row">
          <Button
            onClick={() => this.handleSaveClick()}
            label={saveButtonLabel}
          />
          <ToggleButton
            checked={this.state.inputActive}
            offIcon="pi pi-times"
            offLabel={intl.formatMessage({ id: INACTIVE })}
            onIcon={"pi pi-check"}
            onLabel={intl.formatMessage({ id: ACTIVE })}
            onChange={(e) =>
              this.setState({ inputActive: e.value }, () => {
                this.checkChangePending({ inputActive: e.value });
              })
            }
            disabled={pending}
          />
          <Button
            onClick={() => this.initInputs()}
            label={intl.formatMessage({
              id: RESET_VALUES,
            })}
            className="p-button-warning"
          />
        </div>
      );
    }
  };

  render = () => {
    const { selectedPayment, intl } = this.props;
    const { PAYMENTS_TITLE_LABEL, PAYMENTS_BILL_TITLE_LABEL } = MESSAGE_KEYS;
    logger.info(
      "VALID",
      this.state.validCustomer,
      this.state.validBill,
      this.state.validBillingDate
    );
    if (selectedPayment) {
      return (
        <div className={isDesktop ? "grid" : ""}>
          <Toast ref={(el) => (this.toast = el)} />
          <div className={isDesktop ? "col-8" : ""}>
            <Panel
              header={intl.formatMessage({
                id: PAYMENTS_TITLE_LABEL,
              })}
            >
              {this.renderEditInputs()}
              {this.renderButtonRow()}
            </Panel>
          </div>
          <div className={isDesktop ? "col-4" : ""}>
            <Panel
              header={intl.formatMessage({ id: PAYMENTS_BILL_TITLE_LABEL })}
            >
              {this.renderBillData()}
            </Panel>
          </div>
        </div>
      );
    } else {
      return <ProgressSpinner />;
    }
  };
}

export default connect(null, { setChangePending })(
  injectIntl(PaymentEditLayout)
);
