import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { setTransferParams } from "actions";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Helper functions
import {
  priceCellTemplate,
  dateToBillNumber,
  initLogger,
  changePendingConfirm,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Static values
import { MESSAGE_KEYS, URLS } from "assets/staticData/enums";
import { BILL_STATUS } from "assets/staticData/combodata";
// Logger
const logger = initLogger("billsTable");

const BillsTable = (props) => {
  const {
    value,
    fetchPending,
    intl,
    setTransferParams,
    handleSelectionChange,
    selection,
  } = props;
  const {
    ERROR_NO_DATA,
    ERROR_RENDER,
    BILLS_NUMBER_LABEL,
    BILLS_AMOUNT_DUE_LABEL,
    BILLS_AMOUNT_REMAINING_LABEL,
    BILLS_DEPARTURE_LABEL,
    BILLS_DESTINATION_LABEL,
    BILLS_TOTAL_AMOUNT_LABEL,
    BILLS_TOTAL_PAID_LABEL,
    BILLS_HOSPITAL_WARNING_LABEL,
  } = MESSAGE_KEYS;

  let totalAmount, totalPaid;
  totalAmount = totalPaid = 0;

  if (value?.length) {
    value.forEach((entry) => {
      totalAmount += entry.totalPrice;
      totalPaid += entry.totalPaidReal;
    });
    totalPaid = totalAmount - totalPaid;
  }

  const footerTemplate = (
    <div className="flex flex-row justify-content-between">
      <span className="mr-2">
        {intl.formatMessage(
          { id: BILLS_TOTAL_AMOUNT_LABEL },
          {
            value: totalAmount
              ? Number(totalAmount).toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0,00",
          }
        )}
      </span>
      <span>
        {intl.formatMessage(
          { id: BILLS_TOTAL_PAID_LABEL },
          {
            value: totalPaid
              ? Number(totalPaid).toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0,00",
          }
        )}
      </span>
    </div>
  );

  let history = useHistory();

  /** Custom table cell renderer that returns a goto-button. */
  const viewButtonTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-search"
        onClick={() => {
          const { transactionId } = rowData;
          if (!props.changePending) {
            handleViewChange(transactionId);
          } else {
            changePendingConfirm(transactionId, handleViewChange, intl);
          }
        }}
      />
    );
  };

  const handleViewChange = (transactionId) => {
    setTransferParams(transactionId);
    history.push(URLS.BILLS);
  };

  /**
   * A custom renderer for the table's bill status column. Displays the respective gender icon instead of the gender name.
   *
   * @param {Object} rowData
   * @param {String} rowData.state - The gender value as string of the respective row.
   */
  const renderStatusColumn = (rowData) => {
    try {
      let billStatus = BILL_STATUS.find((status) => {
        return status.billStatusId === rowData.state;
      });
      if (!billStatus) {
        billStatus = BILL_STATUS[0];
      }

      return (
        <Tippy content={intl.formatMessage({ id: billStatus.messageKey })}>
          <i style={{ color: billStatus.color }} className={billStatus.icon} />
        </Tippy>
      );
    } catch (renderStatusColumn) {
      logger.warn("Exception renderStatusColumn", renderStatusColumn);
      return <></>;
    }
  };

  const generateTooltipContent = (rowData) => {
    const { fromAddress, toAddress } = rowData;
    try {
      return (
        <div>
          <div>
            {`${intl.formatMessage({ id: BILLS_DEPARTURE_LABEL })}: `}{" "}
            <strong>{`${fromAddress ? fromAddress : "-"}`}</strong>
          </div>
          <div>
            {`${intl.formatMessage({ id: BILLS_DESTINATION_LABEL })}: `}{" "}
            <strong>{`${toAddress ? toAddress : "-"}`}</strong>
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn(
        "Exception on generateTooltipContent",
        renderException,
        rowData
      );
      return <div>-</div>;
    }
  };

  const renderHospitalColumn = (rowData) => {
    try {
      const { isHospital } = rowData;
      logger.info("IS HOSPITAL", isHospital);
      return isHospital ? (
        <Tippy
          content={intl.formatMessage({ id: BILLS_HOSPITAL_WARNING_LABEL })}
        >
          <i className="pi pi-flag" />
        </Tippy>
      ) : (
        <></>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <></>;
    }
  };

  const renderAddressColumn = (rowData) => {
    try {
      let cell;
      const { fromAddress, toAddress } = rowData;

      if (fromAddress && toAddress) {
        cell = (
          <Tippy content={generateTooltipContent(rowData)}>
            <i className="pi pi-info-circle" />
          </Tippy>
        );
      } else {
        cell = <></>;
      }
      return cell;
    } catch (renderException) {
      logger.warn("Exception on renderAddressColumn");
      return <></>;
    }
  };

  const renderBillNumberColumn = (rowData) => {
    try {
      const { orderDate, invoiceNumber } = rowData;
      return (
        <div>{`${invoiceNumber ? invoiceNumber : ""}/${
          orderDate ? dateToBillNumber(orderDate) : "-"
        }`}</div>
      );
    } catch (renderException) {
      logger.warn(
        "Exception on render bul number column",
        renderException,
        rowData
      );
      return <div>-</div>;
    }
  };

  try {
    return (
      <div>
        <DataTable
          value={value}
          emptyMessage={intl.formatMessage({
            id: ERROR_NO_DATA,
          })}
          loading={fetchPending}
          selectionMode="single"
          onSelectionChange={(e) => {
            if (handleSelectionChange) {
              handleSelectionChange(e.value || selection);
            }
          }}
          className="p-datatable-sm"
          paginator
          rows={5}
          selection={selection}
          footer={footerTemplate}
        >
          <Column
            field="statusId"
            header=""
            body={renderStatusColumn}
            style={{ width: "60px", textAlign: "center" }}
          />
          <Column
            header=""
            body={renderAddressColumn}
            style={{ width: "30px", textAlign: "center" }}
          />
          <Column
            header=""
            body={renderHospitalColumn}
            style={{ width: "30px", textAlign: "center" }}
          />
          <Column
            body={renderBillNumberColumn}
            header={intl.formatMessage({
              id: BILLS_NUMBER_LABEL,
            })}
            style={{ textAlign: "right" }}
          />
          <Column
            field="totalPrice"
            style={{ textAlign: "right" }}
            header={intl.formatMessage({
              id: BILLS_AMOUNT_DUE_LABEL,
            })}
            body={(rowData) => {
              return priceCellTemplate(rowData.totalPrice);
            }}
          />
          <Column
            style={{ textAlign: "right" }}
            header={intl.formatMessage({
              id: BILLS_AMOUNT_REMAINING_LABEL,
            })}
            body={(rowData) => {
              const { totalPrice = 0.0, totalPaidReal = 0.0 } = rowData;
              return priceCellTemplate(totalPrice - totalPaidReal);
            }}
          />
          <Column
            style={{ textAlign: "center", width: "4em" }}
            body={viewButtonTemplate}
          />
        </DataTable>
      </div>
    );
  } catch (renderException) {
    logger.error("Exception on BillTable render", renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default connect(null, { setTransferParams })(injectIntl(BillsTable));
