import React from "react";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Style
import "./Style.scss";

const MonthReportTable = ({ value, columns, loading, displayedDate }) => {
  const intl = useIntl();
  const {
    MONTH_REPORT_NAME,
    MONTH_REPORT_TOTAL,
    DRIVER_STATUS_SORT_NO_RESULT,
    SUNDAY_SHORT,
    MONDAY_SHORT,
    TUESDAY_SHORT,
    WEDNESDAY_SHORT,
    THURSDAY_SHORT,
    FRIDAY_SHORT,
    SATURDAY_SHORT,
    MONTH_REPORT_SEARCH_NAME,
  } = MESSAGE_KEYS;

  const weekdays = [
    SUNDAY_SHORT,
    MONDAY_SHORT,
    TUESDAY_SHORT,
    WEDNESDAY_SHORT,
    THURSDAY_SHORT,
    FRIDAY_SHORT,
    SATURDAY_SHORT,
  ];

  const minutesToHours = (minutes) => {
    if (isNaN(minutes)) {
      return "-";
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.abs(minutes % 60);

      // Ensure leading zeros for single-digit minutes
      const formattedHours = String(hours);
      const formattedMinutes = String(remainingMinutes).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    }
  };

  const valueTemplate = (rowData, column) => {
    const className = `white-space-nowrap ${getCellClass(
      rowData[column.field]
    )}`;
    return (
      <div className={className}>{minutesToHours(rowData[column.field])}</div>
    );
  };

  const getCellClass = (value) => {
    const HOURS_THRESHOLD = 150;
    if (value > HOURS_THRESHOLD) {
      return "text-green-500 font-bold";
    } else if (value < -HOURS_THRESHOLD) {
      return "text-red-500 font-bold";
    }
    return "";
  };

  const dayHeader = ({ field }) => {
    try {
      const dayDate = new Date(displayedDate.getTime());
      dayDate.setDate(field);
      return (
        <div style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: weekdays[dayDate.getDay()] })}
        </div>
      );
    } catch (renderException) {
      console.warn(renderException);
      return <></>;
    }
  };

  if (columns) {
    return (
      <div style={{ height: "calc(100vh - 164px)" }}>
        <DataTable
          value={value}
          loading={loading}
          scrollable
          scrollHeight="flex"
          size={"small"}
          emptyMessage={intl.formatMessage({
            id: DRIVER_STATUS_SORT_NO_RESULT,
          })}
          stripedRows
          showGridlines
          filterDisplay="row"
        >
          <Column
            key="name"
            field="name"
            header={intl.formatMessage({ id: MONTH_REPORT_NAME })}
            sortable
            filter
            filterPlaceholder={intl.formatMessage({
              id: MONTH_REPORT_SEARCH_NAME,
            })}
            style={{ minWidth: "260px" }}
            frozen            
          />
          {columns.map((col, i) => (
            <Column
              key={col.field}
              field={col.field}
              body={valueTemplate}
              header={col.header}
              headerStyle={{ textAlign: "right" }}
              bodyStyle={{ textAlign: "right" }}
              filterElement={dayHeader}
              filterApply={<></>}
              filterClear={<></>}
              filterHeaderClassName="day_empty_filter"
              filter
              alignHeader="center"
              align="right"              
            />
          ))}
          <Column
            key="totalDifference"
            field="totalDifference"
            header={intl.formatMessage({ id: MONTH_REPORT_TOTAL })}
            body={valueTemplate}
            sortable
          />
        </DataTable>
      </div>
    );
  } else {
    return (
      <div className="flex-row justify-content-center align-items-center">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    );
  }
};

export default MonthReportTable;
