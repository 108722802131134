import React from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Custom components
import ProductEditView from "./components/ProductEditView";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
import { setChangePending } from "actions/sessionActions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, ROWS_PER_PAGE, LOCALES } from "assets/staticData/enums";
// Helper functions
import {
  sendQuery,
  initLogger,
  changePendingConfirm,
  priceCellTemplate,
} from "common/Helpers";
// Static values
import { QUERIES, PAGE_LINK_SIZES } from "assets/staticData/enums";

const logger = initLogger("products_view");

class ProductsView extends React.Component {
  state = {
    first: 0,
    rows: isDesktop ? 10 : 5,
    rowsPerPage: ROWS_PER_PAGE,
    displayedProducts: [],
    totalProducts: 0,
    fetchPending: false,
    fetchProductPending: false,
    selectedRow: null,
    selectedProduct: null,
  };

  componentDidMount = () => {
    const { setTopbarTitle, setChangePending } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_PRODUCTS })
    );
    setChangePending(false);
    this.fetchProducts();
    // Set ref for autoscroll in responsive mode.
    this.editViewRef = React.createRef();
  };

  /**
   * Gets called when a row in the table is selected.
   * If the changePending value within the state is true, the user will be prompted to confirm the selection.
   * On confirmation, the state is updated and the data for the new selection will be fetched and displayed. No change will happen else.
   *
   * @param {Object} product - The new selection.
   */
  handleSelectionChange = (product) => {
    const { changePending, intl } = this.props;
    this.setState({ selectedRow: product });
    if (!changePending) {
      this.changeSelection(product.productId);
    } else {
      changePendingConfirm(product.productId, this.changeSelection, intl);
    }
  };

  handleParentUpdate = (newId) => {
    this.fetchProducts();
    this.changeSelection(newId);
  };

  changeSelection = (newId) => {
    if (newId >= 0) {
      this.fetchProduct(newId);
    } else {
      this.setState({
        selectedProduct: { productId: newId },
      });
    }

    this.props.setChangePending(false);
  };

  fetchProduct = (id) => {
    try {
      this.setState({ fetchProductPending: true });
      if (id) {
        return sendQuery(
          `${QUERIES.GET_PAYMENT_PRESET_BY_ID}${id}`,
          "get"
        ).then((response) => {
          this.setState({
            fetchProductPending: false,
            selectedProduct: { ...response },
          });
          if (this.editViewRef && !isDesktop) {
            this.editViewRef.current.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        });
      }
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({ fetchProductPending: false });
    }
  };

  fetchProducts = () => {
    this.setState({
      fetchPending: true,
    });
    return sendQuery(QUERIES.GET_PAYMENT_PRESETS, "get").then(
      (response) => {
        if (response && response.content) {
          this.setState({
            displayedProducts: [...response.content],
            totalProducts: response.content.length,
            fetchPending: false,
          });
        } else {
          logger.warn("No values found.");
          this.setState({
            displayedProducts: [],
            totalProducts: 0,
            fetchPending: false,
          });
        }
      },
      (error) => {
        logger.warn(error);
        this.setState({
          fetchPending: false,
        });
      }
    );
  };

  renderTable = () => {
    const { intl } = this.props;
    const {
      ERROR_RENDER,
      ERROR_NO_DATA,
      PRODUCTS_PRICE_LABEL,
      PRODUCTS_NAME_LABEL,
    } = MESSAGE_KEYS;
    const { GERMAN, FRENCH } = LOCALES;
    const {
      displayedProducts,
      rowsPerPage,
      rows,
      totalProducts,
      selectedRow,
      fetchPending,
      first,
    } = this.state;
    let content;
    try {
      content = (
        <div>
          <DataTable
            value={displayedProducts}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => this.handleSelectionChange(e.value || selectedRow)}
            emptyMessage={this.props.intl.formatMessage({
              id: ERROR_NO_DATA,
            })}
            loading={fetchPending}
            rows={rows}
            totalRecords={totalProducts}
            className="p-datatable-sm"
            paginator
            first={first}
            rowsPerPageOptions={rowsPerPage}
            onPage={(e) => {
              logger.info("ON PAGE", e);
              this.setState({ first: e.first, rows: e.rows });
            }}
            pageLinkSize={
              isDesktop ? PAGE_LINK_SIZES.DESKTOP : PAGE_LINK_SIZES.MOBILE
            }
          >
            <Column
              field="productName"
              header={`${intl.formatMessage({
                id: PRODUCTS_NAME_LABEL,
              })} (${intl.formatMessage({ id: FRENCH.key })})`}
              filter
            />
            <Column
              field="productNameDE"
              header={`${intl.formatMessage({
                id: PRODUCTS_NAME_LABEL,
              })} (${intl.formatMessage({ id: GERMAN.key })})`}
              filter
            />
            <Column
              key="pmt_col_amount"
              style={{ textAlign: "right", width: "6em" }}
              header={intl.formatMessage({
                id: PRODUCTS_PRICE_LABEL,
              })}
              body={(rowData) => {
                return priceCellTemplate(rowData.price);
              }}
            />
          </DataTable>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      content = <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    } finally {
      return content;
    }
  };

  render = () => {
    const { selectedProduct, fetchProductPending } = this.state;

    return (
      <div className={isDesktop ? "grid" : ""}>
        <div className={isDesktop ? "col-5" : ""}>
          <Button
            icon="pi pi-plus"
            onClick={() => {
              this.handleSelectionChange({ productId: -1 });
            }}
          />
          {this.renderTable()}
        </div>
        <div ref={this.editViewRef} className={isDesktop ? "col-7" : ""}>
          <ProductEditView
            value={selectedProduct}
            handleParentUpdate={this.handleParentUpdate}
            fetchPending={fetchProductPending}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      session: { changePending = false },
    } = state;
    return {
      changePending,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      changePending: false,
    };
  }
};

export default connect(mapStateToProps, { setTopbarTitle, setChangePending })(
  injectIntl(ProductsView)
);
