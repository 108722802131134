/**
 * This component renders an autocomplete component for customer names.
 * The suggestions for the entered values are fetched from the backend.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// React redux
import { connect } from "react-redux";
// PrimeReact components
import { AutoComplete } from "primereact/autocomplete";
// Helper functions
import { initLogger, sendQuery } from "common/Helpers";
// Static values
import { QUERIES } from "assets/staticData/enums";

const logger = initLogger('customer_auto_complete');
const MAX_SUGGESTIONS = 5;
const FIRST_PAGE_ONLY = 0

class CustomerAuoComplete extends React.Component {
  state = {
    suggestions: [],    
  };
    /**
   * Custom render function to display the individual suggestions.
   * Returns a div containing the suggested customer's full name an health insurance number if available.
   * 
   * @param {Object} suggestion 
   * @param {String} suggestion.firstname - The first name of the suggested customer.
   * @param {String} suggestion.lastname - The last name of the suggested customer.
   * @param {String} suggestion.healthInsuranceNumber - The suggested customer's healt insurance number.
   * 
   * @returns {JSX.Element} - A div with the customer's full name & insurance number.
   */
  renderSuggestion = (suggestion) => {
      const { firstname, lastname, healthInsuranceNumber } = suggestion;
      return (
          <div>
              {`${firstname ? firstname : ""} ${lastname ? lastname : ""} - ${healthInsuranceNumber ? healthInsuranceNumber : ""}`}
          </div>
      )
  }
  /**
   * Requests suggestions from the backend.
   * The backend's response will be stored in the backend.
   * 
   * @param {Event} event 
   * @param {String} event.query - The user input.
   */
  suggestCustomers = (event) => {      
      sendQuery(
        `${QUERIES.GET_CUSTOMERS_PAGES}?filter=${
          event.query ? event.query : ""
        }&size=${MAX_SUGGESTIONS}&page=${FIRST_PAGE_ONLY}`,
        "get",
        null
      ).then(
        (response) => {
          this.setState({
            suggestions: [...response.content],
          });
        },
        (error) => {
          logger.error(error);
          this.setState({
            suggestions: [],
          });
        }
      );
    
  };

  render = () => {
    const { selectedCustomer, handleSelect, valid } = this.props;

    return (
      <AutoComplete        
        value={selectedCustomer}
        field="lastname"
        onChange={(e) => handleSelect(e.value)}
        suggestions={this.state.suggestions}
        completeMethod={this.suggestCustomers}
        itemTemplate={this.renderSuggestion} 
        className={(valid ? "" : "p-invalid")}           
      />
    );
  };
}

const mapStateToProps = (state) => {
  const {
    authentication: { currentUser },
  } = state;
  return { currentUser };
};

export default connect(mapStateToProps, {})(CustomerAuoComplete);
