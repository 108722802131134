import React from "react";
// PrimeReact imports
import { Dropdown } from "primereact/dropdown";
// Localization
import { injectIntl } from "react-intl";
import { initLogger } from "common/Helpers";

const logger = initLogger("translated_cb");

const TranslatedCB = (props) => {
  const {
    value,
    onChange,
    intl,
    options,
    placeholder,
    valid,
    className = "",
    disabled = false,
    appendId = null,
  } = props;

  const renderItem = (option) => {
    if (option) {
      try {
        return (
          <div className="TT_ITEM">
            {intl.formatMessage({ id: option.messageKey })}
          </div>
        );
      } catch (cbRenderException) {
        logger.error(cbRenderException);
        return <></>;
      }
    } else {
      return <div>-</div>;
    }
  };
  let appendix = document.body;
  if (appendId) {
    appendix = document.getElementById(appendId);
  }

  return (
    <Dropdown
      value={value}
      options={options}
      onChange={(e) => onChange(e.value)}
      optionLabel="messageKey"
      valueTemplate={renderItem}
      itemTemplate={renderItem}
      placeholder={placeholder ? placeholder : ""}
      className={`${
        valid === true || valid === null || valid === undefined
          ? ""
          : "p-invalid"
      } ${className}`}
      style={{ width: "100%" }}
      disabled={disabled}
      appendTo={appendix ? appendix : document.body}
    />
  );
};

export default injectIntl(TranslatedCB);
