import React from "react";
// PrimeReact components
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, hasValueChanged, sendQuery } from "common/Helpers";
// Redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Static values
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";

// Logging
const logger = initLogger("simple_address_input_form");

const EMPTY_STATE = {
  inputKeyword: "",
  inputRoom: "",
  inputDepartment: "",
  inputAddress: "",
  inputText: "",

  inputLine1: "",
  inputLine2: "",
  inputZip: "",

  suggestions: [],
  fetchPending: false,
  prefix: "",
};
const DEFAULT_PAGE = 0;
const NUMBER_RESULTS = 10;

class SimpleAddressInputForm extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.value && this.props.value) {
      this.initInputs();
    } else if (prevProps.value && this.props && this.props.value) {
      if (this.valueModified(prevProps)) {
        this.initInputs();
      }
    } else if (
      this.props.isDeparture &&
      this.props.appointmentDeparture?.address?.addressId !==
        prevProps.appointmentDeparture?.address?.addressId
    ) {
      this.initInputs();
    }
  };

  valueModified = (prevProps) => {
    try {
      const {
        value: { department, room, address, keyword, text },
      } = this.props;

      const { prefix } = this.state;

      return (
        hasValueChanged(prevProps.value.department, department) ||
        hasValueChanged(prevProps.value.room, room) ||
        hasValueChanged(prevProps.value.address, address) ||
        hasValueChanged(prevProps.value.keyword, keyword) ||
        hasValueChanged(prevProps.value.text, text) ||
        hasValueChanged(
          prevProps.value[`${prefix}Address1`],
          this.props.value[`${prefix}Address1`]
        ) ||
        hasValueChanged(
          prevProps.value[`${prefix}Address2`],
          this.props.value[`${prefix}Address2`]
        ) ||
        hasValueChanged(
          prevProps.value[`${prefix}Zipcode`],
          this.props.value[`${prefix}Zipcode`]
        )
      );
    } catch (checkException) {
      logger.error(checkException);
      return false;
    }
  };

  fetchAddressData = (addressId) => {
    sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${addressId}`, "get").then(
      (response) => {
        if (response && response.address) {
          const { line1, line2, countryProvince, zipCode, name } =
            response.address;
          this.setState({
            inputAddress: {
              addressId,
              customerName: name,
              line1,
              countryProvince,
              zipCode,
              name,
            },
            inputLine1: line1,
            inputLine2: line2,
            inputZip: zipCode,
          });
        }
      },
      (error) => {
        logger.warn(error);
      }
    );
  };

  initInputs = () => {
    const { appointmentDeparture, appointmentDestination, isDeparture } =
      this.props;
    let value = isDeparture ? appointmentDeparture : appointmentDestination;

    let prefix = isDeparture ? "departure" : "arrival";

    if (value) {
      const { keyword, room, department, address, text } = value;
      let newState = {
        inputKeyword: keyword ? keyword : "",
        inputRoom: room ? room : "",
        inputDepartment: department ? department : "",
        inputAddress: address ? address : "",
        inputText: text ? text : "",

        inputLine1: value[`${prefix}Address1`],
        inputLine2: value[`${prefix}Address2`],
        inputZip: value[`${prefix}Zipcode`],

        prefix,
      };
      if (address) {
        if (typeof address === "number") {
          sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${address}`, "get").then(
            (response) => {
              if (response && response.address) {
                const { line1, line2, countryProvince, zipCode, name } =
                  response.address;
                this.setState({
                  inputAddress: {
                    customerName: name,
                    line1,
                    line2,
                    countryProvince,
                    zipCode,
                    name,
                  },
                  /*inputLine1: line1,
                  inputLine2: line2,
                  inputZip: zipCode,*/
                });
              } else {
                const { line1, line2, zipCode } = address;
                this.setState({
                  inputAddress: address,
                  inputLine1: line1,
                  inputLine2: line2,
                  inputZip: zipCode,
                });
              }
            },
            (error) => {
              logger.warn(error);
              newState.inputAddress = address;
            }
          );
        } else {
          newState.inputAddress = address;
        }
      } else {
        newState.inputAddress = "";
      }
      this.setState({ ...newState });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  updateParent = (newState = null) => {
    const { setBaseValue, isDeparture } = this.props;

    let key = isDeparture ? "appointmentDeparture" : "appointmentDestination";

    setBaseValue(key, this.mapInputsToDTO(newState ? newState : this.state));
  };

  mapInputsToDTO = (value) => {
    try {
      const {
        inputKeyword,
        inputRoom,
        inputDepartment,
        inputAddress,
        inputText,

        inputLine1,
        inputLine2,
        inputZip,
      } = value;

      const { isDeparture } = this.props;

      let prefix = isDeparture ? "departure" : "arrival";

      return {
        keyword: inputKeyword,
        room: inputRoom,
        department: inputDepartment,
        address: inputAddress,
        text: inputText,

        [`${prefix}Address1`]: inputLine1,
        [`${prefix}Address2`]: inputLine2,
        [`${prefix}Zipcode`]: inputZip,
      };
    } catch (mapException) {
      logger.warn("Exception on map inputs to DTO", mapException);
      return null;
    }
  };

  handleAddressAutoComplete = (e) => {
    this.setState({ fetchPending: true });
    sendQuery(
      `${QUERIES.GET_ADDRESSES_PAGES}page=${DEFAULT_PAGE}&size=${NUMBER_RESULTS}&address=${e.query}&queryIsEmtpy=false&sort=personId,desc`,
      "get",
      null
    ).then(
      (response) => {
        this.setState(
          {
            inputKeyword: e.query,
            suggestions: response.content,
            fetchPending: false,
          },
          this.updateParent
        );
      },
      (error) => {
        logger.warn(error);
        this.setState(
          {
            inputKeyword: e.query,
            fetchPending: false,
          },
          this.updateParent
        );
      }
    );
  };

  handleAddressClear = () => {
    this.setState({
      ...EMPTY_STATE,
    });
  };

  handleCustomerAddressSelect = () => {
    const { appointmentCustomerAddress, appointmentCustomer } = this.props;
    try {
      if (appointmentCustomerAddress.addressId) {
        const { firstname, lastname } = appointmentCustomer;
        const { countryProvince, line1, line2, zipCode } =
          appointmentCustomerAddress;
        let customerName;
        if (firstname || lastname) {
          customerName = `${firstname ? firstname : ""} ${
            lastname ? lastname : ""
          }`;
        } else {
          customerName = countryProvince ? countryProvince : "";
        }
        this.setState(
          {
            inputAddress: {
              ...appointmentCustomerAddress,
              customerName,
              name: countryProvince,
            },
            inputKeyword: countryProvince
              ? countryProvince
              : this.state.inputKeyword,
            inputLine1: line1,
            inputLine2: line2,
            inputZip: zipCode,
          },
          this.updateParent
        );
      } else {
        const { countryProvince, line1, line2, zipCode, searchKey } =
          appointmentCustomerAddress;
        let inputKeyword;
        if (countryProvince) {
          inputKeyword = countryProvince;
        } else {
          inputKeyword = searchKey ? searchKey : "";
        }
        this.setState(
          {
            inputKeyword,
            inputRoom: "",
            inputDepartment: "",
            inputAddress: null,
            inputText: "",

            inputLine1: line1 ? line1 : "",
            inputLine2: line2 ? line2 : "",
            inputZip: zipCode,
          },
          this.updateParent
        );
      }
    } catch (exception) {
      logger.error(exception);
    }
  };

  renderAddressInfos = () => {
    const { intl } = this.props;
    const {
      ERROR_RENDER,
      CUSTOMERS_ADDRESS_LINE_1_LABEL,
      CUSTOMERS_ADDRESS_LINE_2_LABEL,
      CUSTOMERS_ADDRESS_ZIP_LABEL,
    } = MESSAGE_KEYS;
    try {
      const { inputLine1, inputZip, inputLine2 } = this.state;
      return (
        <div className="flex justify-content-between">
          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_LINE_1_LABEL}
            value={inputLine1}
            onChange={(e) => {
              this.setState({ inputLine1: e.target.value }, this.updateParent);
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_1_LABEL })}
            className="mr-1"
          />

          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
            value={inputLine2}
            onChange={(e) => {
              this.setState({ inputLine2: e.target.value }, this.updateParent);
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_2_LABEL })}
            className="mr-1"
          />

          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_ZIP_LABEL}
            value={inputZip}
            onChange={(e) => {
              this.setState({ inputZip: e.target.value }, this.updateParent);
            }}
            label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_ZIP_LABEL })}
            className="mr-1"
          />
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on render address infos", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderAutoCompleteItem = (item) => {
    try {
      const { customerName, line1, zipCode, countryProvince } = item;
      return (
        <div>
          <div>{customerName}</div>
          <div>{`${line1} ${zipCode} ${countryProvince}`}</div>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException, item);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  handleAddressSelect = (address) => {
    const { inputKeyword } = this.state;
    //this.fetchAddressData(address.addressId);
    const { line1, line2, zipCode } = address;
    this.setState(
      {
        inputAddress: { ...address, name: address.countryProvince },
        inputKeyword:
          address && address.countryProvince
            ? address.countryProvince
            : inputKeyword,
        inputLine1: line1,
        inputLine2: line2,
        inputZip: zipCode,
      },
      this.updateParent
    );
  };

  render = () => {
    const { intl, className } = this.props;
    const {
      CUSTOMERS_ADDRESS_LINE_2_LABEL,
      CUSTOMERS_ADDRESS_ZIP_LABEL,
      PAYMENTS_FILTER_ADDRESS_KEY_LABEL,

      APPOINTMENTS_ROOM_LABEL,
      APPOINTMENTS_DEPARTMENT_LABEL,
      APPOINTMENTS_FREE_TEXT_LABEL,
      APPOINTMENTS_KEY_WORD_LABEL,
      APPOINTMENTS_USE_CUSTOMER_ADDRESS,
      APPOINTMENTS_CLEAR_ADDRESS,
    } = MESSAGE_KEYS;
    const {
      inputRoom,
      inputDepartment,
      inputAddress,
      inputText,
      inputKeyword,

      suggestions,
    } = this.state;

    return (
      <div className={className ? className : ""}>
        <div className="flex  align-items-end">
          <span className="p-float-label mr-1" style={{ width: "100%" }}>
            <AutoComplete
              id={PAYMENTS_FILTER_ADDRESS_KEY_LABEL}
              value={inputAddress}
              suggestions={suggestions}
              completeMethod={this.handleAddressAutoComplete}
              field="customerName"
              onChange={(e) => {
                this.setState({ inputAddress: e.value });
              }}
              onSelect={(e) => {
                this.handleAddressSelect(e.value);
              }}
              dropdown
              appendTo={document.body}
              style={{ width: "100%" }}
              delay={700}
              itemTemplate={this.renderAutoCompleteItem}
            />
            <label htmlFor={PAYMENTS_FILTER_ADDRESS_KEY_LABEL}>
              {intl.formatMessage({ id: PAYMENTS_FILTER_ADDRESS_KEY_LABEL })}
            </label>
          </span>

          <FloatingTextInput
            value={inputKeyword}
            onChange={(e) => {
              this.setState(
                { inputKeyword: e.target.value },
                this.updateParent
              );
            }}
            label={intl.formatMessage({ id: APPOINTMENTS_KEY_WORD_LABEL })}
            className="mr-1"
          />
        </div>

        {this.renderAddressInfos()}

        <div className="flex justify-content-between">
          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
            value={inputRoom}
            onChange={(e) => {
              this.setState({ inputRoom: e.target.value }, this.updateParent);
            }}
            label={intl.formatMessage({ id: APPOINTMENTS_ROOM_LABEL })}
            className="mr-1"
          />
          <FloatingTextInput
            id={CUSTOMERS_ADDRESS_ZIP_LABEL}
            value={inputDepartment}
            onChange={(e) => {
              this.setState(
                { inputDepartment: e.target.value },
                this.updateParent
              );
            }}
            label={intl.formatMessage({ id: APPOINTMENTS_DEPARTMENT_LABEL })}
            className="mr-1"
          />
        </div>
        <div className="flex justify-content-between align-items-end">
          <FloatingTextInput
            value={inputText}
            onChange={(e) => {
              this.setState({ inputText: e.target.value }, this.updateParent);
            }}
            label={intl.formatMessage({ id: APPOINTMENTS_FREE_TEXT_LABEL })}
          />
          <Tippy
            content={intl.formatMessage({
              id: APPOINTMENTS_USE_CUSTOMER_ADDRESS,
            })}
          >
            <Button
              className="ml-1"
              icon="pi pi-copy"
              onClick={this.handleCustomerAddressSelect}
            />
          </Tippy>
          <Tippy
            content={intl.formatMessage({ id: APPOINTMENTS_CLEAR_ADDRESS })}
          >
            <Button
              className="ml-1 p-button-warning"
              icon="pi pi-times"
              onClick={this.handleAddressClear}
            />
          </Tippy>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      appWizard: {
        appointmentDeparture,
        appointmentDestination,
        appointmentCustomerAddress,
        appointmentCustomer,
      },
    } = state;
    return {
      appointmentDeparture,
      appointmentDestination,
      appointmentCustomerAddress,
      appointmentCustomer,
    };
  } catch (mapException) {
    logger.error(mapException);
    return {
      appointmentDeparture: null,
      appointmentDestination: null,
      appointmentCustomerAddress: null,
      appointmentCustomer: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(SimpleAddressInputForm)
);
