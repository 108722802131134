import {
  WIZARD_INIT,
  WIZARD_RESET,
  WIZARD_SET_VALUE,
  WIZARD_UPDATE_DRIVE,
  WIZARD_UPDATE_FROM_ADDRESS,
  WIZARD_UPDATE_TO_ADDRESS,
} from "./types";

export const resetWizard = () => {
  return {
    type: WIZARD_RESET,
  };
};
/**
 *
 * @param {Object} initialValues
 * @param {Object} initialValues.appointmentCustomer,
 * @param {Object} initialValues.appointmentDeparture,
 * @param {Object} initialValues.appointmentCustomerAddress,
 * @param {Object} initialValues.appointmentDestination,
 * @param {Object} initialValues.appointmentDrive,
 * @param {Object} initialValues.appointmentRepeating
 * @returns
 */
export const initWizard = (initialValues) => {
  return {
    type: WIZARD_INIT,
    payload: {
      ...initialValues,
    },
  };
};

export const setBaseValue = (key, value) => async(dispatch) => {
  dispatch({
    type: WIZARD_SET_VALUE,
    payload: { key, value },
  });
  return Promise.resolve();
};

export const updateDrive = (key, value) => {
  return {
    type: WIZARD_UPDATE_DRIVE,
    payload: { key, value },
  };
};

export const updateFromAddress = (key, value) => {
  return {
    type: WIZARD_UPDATE_FROM_ADDRESS,
    payload: { key, value },
  };
};

export const updateToAddress = (key, value) => {
  return {
    type: WIZARD_UPDATE_TO_ADDRESS,
    payload: { key, value },
  };
};
