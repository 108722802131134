import React, { useState, useRef } from "react";

// Styling
import "./Style.scss";

// primeng
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Chip } from "primereact/chip";
import { Galleria } from "primereact/galleria";
//others
import {
  dateToString,
  fetchTexts,
  getColorByMessageType,
  getCurrentUserLocale,
} from "common/Helpers";
import { GroupmembersDialog, ReactionContainer } from "./index";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const ShoutboxElement = ({
  message,
  handleDialog,
  isPreview,
  isAdmin,
  handleRemoveImage,
  handleReactionAdd,
  handleReactionDelete,
  index,
  user,
}) => {
  //Possibly future feature
  /*   const generateChatIcon = () => {
    return (
      <i
        className="pi pi-comments p-overlay-badge"
        style={{ fontSize: "2rem" }}
        onClick={() => {
          if (!isPreview) {
            handleDialog(true, "chat", message);
          }
        }}
      >
        <Badge
          value={
            message?.chatMessages
              ? message.chatMessages.length > 0
                ? message.chatMessages.length
                : "0"
              : "0"
          }
        ></Badge>
      </i>
    );
  }; */
  const intl = useIntl();
  const galleria = useRef(null);

  const [galleryIndex, setGalleryIndex] = useState(-1);
  const [groupDialogVisible, setGroupDialogVisible] = useState(false);

  const generateAssignedGroup = () => {
    // This convoluted mess is required as Tippy.js is very fidgety when used with generated content.
    return (
      <div
        onClick={() => {
          setGroupDialogVisible(true);
        }}
      >
        <Chip
          id="group_chip"
          label={message.assignedGroup?.groupName}
          icon="pi pi-users"
          className="custom-chip"
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  };

  const generatePerson = () => {
    return (
      message.assignedPerson && (
        <Chip
          label={message.assignedPerson?.alias}
          key="2"
          icon="pi pi-user"
          className="custom-chip"
        />
      )
    );
  };

  const generateGroupToolTip = ({ groupMembers }) => {
    const aliasesPerColumn = 5;
    const firstFiveAliases = groupMembers.slice(0, aliasesPerColumn);
    const remainingCount = groupMembers.length - aliasesPerColumn;

    return (
      <div className="column">
        {firstFiveAliases.map((member) => (
          <div className="flex flex-column" key={member.user.personId}>
            {member.user.alias}
          </div>
        ))}
        {remainingCount > 0 && (
          <div>
            {intl.formatMessage(
              { id: MESSAGE_KEYS.SHOUTBOX_GROUP_TT_MORE },
              { count: remainingCount }
            )}
          </div>
        )}
      </div>
    );
  };

  const generateHeaderActions = () => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-info p-button-rounded"
          onClick={(e) => {
            handleDialog(true, "edit", message);
            e.stopPropagation();
          }}
          disabled={isPreview || !isAdmin}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger p-button-rounded"
          onClick={(e) => {
            handleDialog(true, "delete", message);
            e.stopPropagation();
          }}
          disabled={isPreview || !isAdmin}
        />
      </>
    );
  };

  const galleriaTemplate = ({ mediaId, mediaData, mediaType }, index) => {
    return (
      <div
        key={mediaId}
        className="galleria-container mb-2"
        onClick={() => {
          setGalleryIndex(index);
          galleria.current.show();
        }}
      >
        <img
          src={`data:${mediaType};base64,${mediaData}`}
          key={mediaId}
          alt=""
        />
        <div className="galleria-overlay">
          <i className="pi pi-eye"></i>
        </div>
      </div>
    );
  };

  const itemTemplate = ({ mediaId, mediaData, mediaType }) => {
    return (
      <img
        src={`data:${mediaType};base64,${mediaData}`}
        key={mediaId}
        alt=""
        style={{ maxWidth: "90vw", maxHeight: "90vh" }}
      />
    );
  };

  const generateImages = () => {
    const { mediaList } = message;

    if (mediaList.length >= 0) {
      if (isPreview) {
        let images = [];
        message.mediaList.forEach((media, i) => {
          let imageSrc = `data:${media?.mediaType};base64,${media?.mediaData}`;
          if (isPreview) {
            images.push(
              <div
                className="media-container"
                key={
                  media?.mediaId
                    ? media.mediaId
                    : Math.floor(Math.random() * (1000 - 100 + 1)) + 100
                }
              >
                <div
                  className="remove-button"
                  onClick={(e) => {
                    handleRemoveImage(i, media?.mediaId);
                    e.stopPropagation();
                  }}
                >
                  <span>&times;</span>
                </div>
                <Image src={imageSrc} alt="Image" preview key={media.mediaId} />
              </div>
            );
          } else {
            images.push(
              <Image src={imageSrc} alt="Image" preview key={media.mediaId} />
            );
          }
        });
        return images;
      } else {
        return (
          <div>
            <Galleria
              ref={galleria}
              value={mediaList}
              numVisible={7}
              style={{ maxWidth: "850px" }}
              activeIndex={galleryIndex}
              onItemChange={(e) => setGalleryIndex(e.index)}
              circular
              fullScreen
              showItemNavigators
              showThumbnails={false}
              item={itemTemplate}
            />
            <div
              className="flex flex-column align-items-center"
              style={{ maxWidth: "300px" }}
            >
              {mediaList.map((image, index) => {
                return galleriaTemplate(image, index);
              })}
            </div>
          </div>
        );
      }
    }
    return <></>;
  };

  const texts = fetchTexts(getCurrentUserLocale(true), message);

  return (
    <div
      className={`m-3 shoutbox-element ${
        isPreview ? "preview_shoutbox-element" : "shoutbox-hover"
      }`}
      style={{
        borderTop:
          "10px solid " + getColorByMessageType(message?.state).bgColor,
        color: "black",
      }}
    >
      {message?.assignedGroup && (
        <GroupmembersDialog
          group={message.assignedGroup}
          visible={groupDialogVisible}
          closeDialog={() => setGroupDialogVisible(false)}
        />
      )}
      <div className="header">
        <h1>{texts.title}</h1>
        <span className="actions">{generateHeaderActions()}</span>
      </div>
      <div className="assigned-group-person-container">
        {message.assignedGroup && (
          <Tippy content={generateGroupToolTip(message.assignedGroup)}>
            {generateAssignedGroup()}
          </Tippy>
        )}
        {generatePerson()}
      </div>

      <br />
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: texts.description,
        }}
      ></div>
      {message?.mediaList && (
        <div className="image-container">{generateImages()}</div>
      )}
      {/*    <div className="chat-icon-container">{generateChatIcon()}</div> */}
      <div className="reaction-container">
        <ReactionContainer
          reactions={message?.reactions}
          handleReactionAdd={handleReactionAdd}
          handleReactionDelete={handleReactionDelete}
          isPreview={isPreview}
          id={message?.shoutboxId}
          index={index}
          userId={user.personId}
        />
      </div>
      <div className="footer">
        <span>
          {isPreview
            ? message?.releaseDate
            : dateToString(message?.releaseDate)}
        </span>
        <span>{isPreview ? user?.alias : message?.sender?.alias}</span>
      </div>
    </div>
  );
};

export default ShoutboxElement;
