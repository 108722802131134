import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
// Localization
import { useIntl } from "react-intl";
// Static data
import { TRANSPORT_TYPES } from "assets/staticData/combodata";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const TicketTransport = ({
  inputData,
  updateParent,
  disabled,
  hideTitle = false,
}) => {
  const intl = useIntl();
  const radioGroup = [];

  const {
    TRANSPORT_TYPE_AMBULANCE,
    TRANSPORT_TYPE_BUS,
    TRANSPORT_TYPE_CADDY,
    TRANSPORT_TYPE_VSL,
  } = MESSAGE_KEYS;

  const availableTypes = [
    TRANSPORT_TYPE_AMBULANCE,
    TRANSPORT_TYPE_BUS,
    TRANSPORT_TYPE_CADDY,
    TRANSPORT_TYPE_VSL,
  ];

  const { transportTypeInvoice } = inputData;

  TRANSPORT_TYPES.filter((type) =>
    availableTypes.includes(type.messageKey)
  ).forEach((type) => {
    radioGroup.push(
      <div
        key={`tt_key_${type.transportTypeId}`}
        className="flex align-items-center ticket_radio"
      >
        <RadioButton
          inputId={`tt_${type.transportTypeId}`}
          name="ticket_transport"
          value={type.transportTypeId}
          onChange={(e) => {
            updateParent({ ...inputData, transportTypeInvoice: e.value });
          }}
          checked={type.transportTypeId === transportTypeInvoice}
          disabled={disabled}
        />
        <label htmlFor={`tt_${type.transportTypeId}`} className="ml-2">
          {intl.formatMessage({ id: type.messageKey })}
        </label>
      </div>
    );
  });

  return (
    <div>
      {!hideTitle && (
        <div className="ticket_column_title">
          {intl.formatMessage({ id: MESSAGE_KEYS.DRIVES_TRANSPORT_TITLE })}
        </div>
      )}
      {!hideTitle && <Divider />}
      {radioGroup}
    </div>
  );
};

export default TicketTransport;
