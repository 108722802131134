import React from "react";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Custom components
import { SalesTableFilter } from "./";
// Helper functions
import { sendQuery, initLogger, priceCellTemplate } from "common/Helpers";
// Static values
import { QUERIES, MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
import "./Style.scss";
// Logging
const logger = initLogger("dashboard_sales_table");

const MONTHS = [
  MESSAGE_KEYS.JANUARY_LONG,
  MESSAGE_KEYS.FEBRUARY_LONG,
  MESSAGE_KEYS.MARCH_LONG,
  MESSAGE_KEYS.APRIL_LONG,
  MESSAGE_KEYS.MAY_LONG,
  MESSAGE_KEYS.JUNE_LONG,
  MESSAGE_KEYS.JULY_LONG,
  MESSAGE_KEYS.AUGUST_LONG,
  MESSAGE_KEYS.SEPTEMBER_LONG,
  MESSAGE_KEYS.OCTOBER_LONG,
  MESSAGE_KEYS.NOVEMBER_LONG,
  MESSAGE_KEYS.DECEMBER_LONG,
];

const FILTER_DEFAULT = {
  inputToDate: `${new Date().getFullYear()+1}-01-01`,  
  inputFromDate: "1970-01-01",
  inputIncludeFacilities: false
}

class SalesTableView extends React.Component {
  state = {
    salesFetchPending: false,
    displayedSales: [],
    inputFromDate: null,
    inputToDate: null,
    inputIncludeFacilities: false,
  };

  componentDidMount = () => {
    this.fetchSales();
  };
  /**
   *
   * @param {Object} filter
   * @param {Date} filter.inputFromDate,
   * @param {Date} filter.inputToDate,
   * @param {Boolean} filter.inputIncludeFacilities,
   */
  fetchSales = (filter = null) => {
    this.setState({
      salesFetchPending: true,
    });
    try {
      const { inputFromDate, inputIncludeFacilities, inputToDate } = (filter ? filter : FILTER_DEFAULT);
      sendQuery(
        `${QUERIES.GET_SALES_BY_TYPE_AND_PERIOD}${inputIncludeFacilities ? "4" : "1"}&fromDate=${inputFromDate}&toDate=${inputToDate}`,
        "get",
        null
      ).then(        
        (response) => {
          if (response) {
            this.setState({
              displayedSales: response,
              salesFetchPending: false,
            });
          }
        },
        (error) => {
          logger.warn("Error on fetchdata", error);
          this.setState({
            salesFetchPending: false,
          });
        }
      );
    } catch (fetchError) {
      logger.warn("Exception on fetch sales", fetchError);
      this.setState({ salesFetchPending: false });
    }
  };

  mapNumberToMonth = (monthNumber) => {
    const { intl } = this.props;
    let monthName = "";
    try {
      monthName = intl.formatMessage({ id: MONTHS[monthNumber - 1] });
    } catch (mapException) {
      logger.warn(
        "Exception on map number to month",
        mapException,
        monthNumber
      );
      monthName = "-";
    } finally {
      return monthName;
    }
  };

  renderMonthCell = (rowData) => {
    let cell;
    try {
      cell = <div>{this.mapNumberToMonth(rowData.monthNumber)}</div>;
    } catch (renderException) {
      logger.warn("Exception on render month cell", renderException, rowData);
      cell = <div>-</div>;
    } finally {
      return cell;
    }
  };

  filterMonths = (value, filter) => {
    logger.info(MONTHS[value]);
    return (
      this.props.intl
        .formatMessage({ id: MONTHS[value - 1] })
        .toLowerCase()
        .indexOf(filter.toLowerCase()) >= 0
    );
  };

  renderSalesTable = () => {
    const { intl } = this.props;
    const {
      ERROR_NO_DATA,
      BILLS_AMOUNT_DUE_LABEL,
      BILLS_AMOUNT_REMAINING_LABEL,
      DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER,
      DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER,
    } = MESSAGE_KEYS;
    const { displayedSales, salesFetchPending } = this.state;
    let content;
    try {
      content = (
        <DataTable
          value={displayedSales}
          emptyMessage={intl.formatMessage({
            id: ERROR_NO_DATA,
          })}
          loading={salesFetchPending}
          className="p-datatable-sm"
          paginator
          rows={12}
          sortMode="multiple"
          removableSort
        >
          <Column
            header={intl.formatMessage({
              id: DASHBOARD_OVERVIEW_TABLE_YEAR_HEADER,
            })}
            field="year"
            filter
            sortable
          />
          <Column
            header={intl.formatMessage({
              id: DASHBOARD_OVERVIEW_TABLE_MONTH_HEADER,
            })}
            field="monthNumber"
            body={this.renderMonthCell}
            filter
            filterMatchMode="custom"
            filterFunction={this.filterMonths}
            sortable
          />
          <Column
            style={{ textAlign: "right" }}
            header={intl.formatMessage({
              id: BILLS_AMOUNT_DUE_LABEL,
            })}
            body={(rowData) => {
              return priceCellTemplate(rowData.invoiceTotal);
            }}
          />
          <Column
            style={{ textAlign: "right" }}
            header={intl.formatMessage({
              id: BILLS_AMOUNT_REMAINING_LABEL,
            })}
            body={(rowData) => {
              return priceCellTemplate(rowData.invoiceOpen);
            }}
          />
        </DataTable>
      );
    } catch (renderException) {
      content = <div>Error</div>;
    } finally {
      return content;
    }
  };

  render = () => {
    const { salesFetchPending } = this.state;
    return (
      <div>
        <SalesTableFilter
          isPending={salesFetchPending}
          handleSearch={this.fetchSales}
        />
        {this.renderSalesTable()}
      </div>
    );
  };
}

export default injectIntl(SalesTableView);
