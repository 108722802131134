/**
 * This component renders the login view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// React router
import { withRouter } from "react-router-dom";
// PrimeReact Components
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// Custom Components
import { FloatingTextInput, FloatingPassword } from "components/common";
import { OfflineDialog } from "./components";
// Redux
import { connect } from "react-redux";
import { login, loginFaild, loginSuccess } from "actions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Styling
import "./LoginView.scss";
// Static values
import {
  MESSAGE_SEVERITY,
  QUERIES,
  LOCALES,
  URLS,
} from "assets/staticData/enums";
import {
  ADMIN_ROLE,
  DRIVER_ROLE,
  FIDUS_ROLE,
  INVOICE_ROLE,
  PLANNING_ROLE,
} from "assets/staticData/combodata";
// Encryption
//import { genSaltSync, hashSync } from "bcryptjs";
import * as Jasypt from "@eryue/jasypt";
// Helper functions
import { initLogger, sendQuery, hasCurrentUserRole } from "common/Helpers";
// Logging
const logger = initLogger("login_view");

const jasypt = new Jasypt();

class LoginView extends React.Component {
  state = {
    inputLogin: "",
    inputPassword: "",
    validLogin: true,
    validPassword: true,
    showOfflineDialog: false,
    loginPending: false,
  };

  /**
   * Adds a keydown listener to enable the Enter-keypress.
   */
  componentDidMount = () => {
    document.addEventListener("keydown", this.handleEnterPress, false);
    const { pending } = this.props;
    if (pending && this.props.login) {
      this.props.login(null, null);
    }
    jasypt.setPassword(process.env.REACT_APP_KEY);
  };

  /**
   * Remove the keydown eventlistener.
   */
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleEnterPress);
  };

  /**
   * If the component updates, check if the error message has changed. If so, display the new message in a toast component.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { error, backendAvailable, pending } = this.props;
    const { showOfflineDialog } = this.state;
    // Display error message.
    if (error && error.length > 0 && prevProps.error !== error) {
      this.toast.show({ severity: MESSAGE_SEVERITY.ERROR, summary: error });
    }
    // If backend is not available, open popup for offline mode.
    if (
      !showOfflineDialog &&
      backendAvailable === false &&
      !pending &&
      prevProps.pending
    ) {
      this.setState({ showOfflineDialog: true });
    }
  };

  /**
   * Get called when the user presses a key. If the pressed key is the Enter-key, this the handleLogin-function will be executed.
   *
   * @param {*} event
   */
  handleEnterPress = (event) => {
    if (event.key === "Enter") {
      this.handleLogin();
    }
  };

  /**
   * Checks the user input
   *
   */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        const { inputLogin, inputPassword } = this.state;
        let validLogin = inputLogin && inputLogin.length > 0;
        let validPassword = inputPassword && inputPassword.length > 0;
        this.setState({ validLogin, validPassword });
        if (validLogin && validPassword) {
          resolve();
        } else {
          reject();
        }
      } catch (validationException) {
        logger.error(validationException);
        reject();
      }
    });
  };
  /**
   * Is called after a successful login.
   * Uses React dom history to redirect the user based on the associated user roles.
   * - Administrators will be redirected to the Dashboard.
   * - Appointment planners will be redirected to the Scheduler.
   * - Billing personel will be redirected to the Billing component.
   * - All other users will be redirected to the On Calls component.
   *
   * @param {Object} responseUser
   */
  handleRedirect = (responseUser) => {
    const { history } = this.props;
    const { DASHBOARD, BILLS, SCHEDULER, ON_CALL, DRIVES } = URLS;
    try {
      let redirectUrl;
      if (hasCurrentUserRole([ADMIN_ROLE, FIDUS_ROLE], responseUser)) {
        redirectUrl = DASHBOARD;
      } else if (hasCurrentUserRole([INVOICE_ROLE], responseUser)) {
        redirectUrl = BILLS;
      } else if (hasCurrentUserRole([PLANNING_ROLE], responseUser)) {
        redirectUrl = SCHEDULER;
      } else if (hasCurrentUserRole([DRIVER_ROLE], responseUser)) {
        redirectUrl = DRIVES;
      } else {
        redirectUrl = ON_CALL;
      }
      logger.info(`REDIRECTING TO ${redirectUrl}`);
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    } catch (redirectException) {
      logger.warn(redirectException);
    }
  };

  /**
   * If all validations check out, sends a login request to the server. Displays an error message else.
   */
  handleLogin = () => {
    try {
      const { inputLogin, inputPassword } = this.state;
      this.setState({ loginPending: true });
      this.validateInputs().then(
        () => {
          window.Buffer = window.Buffer || require("buffer").Buffer;
          let encryptedPW = jasypt.encrypt(inputPassword);
          this.props.login(inputLogin, encryptedPW);
          try {
            sendQuery(QUERIES.AUTHENTICATION, "post", {
              login: inputLogin,
              password: encryptedPW,
              sort: "id,asc",
            }).then(
              (response) => {
                if (!response.hasOwnProperty("languageId")) {
                  response.currentLocale = LOCALES.FRENCH;
                } else {
                  if (response.languageId === LOCALES.GERMAN.languageId) {
                    response.currentLocale = LOCALES.GERMAN;
                  } else {
                    response.currentLocale = LOCALES.FRENCH;
                  }
                }
                this.handleRedirect(response);
                this.setState({ loginPending: false }, () => {
                  loginSuccess(response);
                });
              },
              (error) => {
                logger.warn("Error on login", error);
                loginFaild(error);
                this.setState({ loginPending: false });
                if (this.toast) {
                  this.toast.show({
                    severity: MESSAGE_SEVERITY.ERROR,
                    summary: this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.LOGIN_WARNING_FAILED,
                    }),
                  });
                }
              }
            );
          } catch (loginException) {
            logger.error("Exception on login", loginException);
            loginFaild(loginException);
            this.setState({ loginPending: false });
          }
        },
        () => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.INFO,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_MISSING_VALUES,
            }),
          });
          this.setState({ loginPending: false });
        }
      );
    } catch (loginException) {
      logger.error(loginException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: loginException.message,
      });
      this.setState({ loginPending: false });
    }
  };

  /**
   * Renders the login- & password- input fields. Renders an error message if an exception occurs.
   *
   * @returns {JSX.Element}
   */
  renderInputFields = () => {
    try {
      const { intl } = this.props;
      const { inputLogin, inputPassword, validLogin, validPassword } =
        this.state;

      return (
        <form autoComplete="on" noValidate>
          <FloatingTextInput
            id={MESSAGE_KEYS.LOGIN_USERNAME_LABEL}
            value={inputLogin}
            label={intl.formatMessage({
              id: MESSAGE_KEYS.LOGIN_USERNAME_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputLogin: event.target.value });
            }}
            valid={validLogin}
          />
          <FloatingPassword
            id={MESSAGE_KEYS.LOGIN_PASSWORD_LABEL}
            value={inputPassword}
            label={intl.formatMessage({
              id: MESSAGE_KEYS.LOGIN_PASSWORD_LABEL,
            })}
            onChange={(event) => {
              this.setState({ inputPassword: event.target.value });
            }}
            feedback={false}
            valid={validPassword}
          />
          <div className="login_container">{this.renderButtonRow()}</div>
        </form>
      );
    } catch (renderException) {
      logger.error(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  /**
   * Renders the login button; will render a loading animation if a login request is pending.
   *
   * @returns {JSX.Element}
   */
  renderButtonRow = () => {
    let button;
    try {
      const { intl } = this.props;
      const { loginPending } = this.state;
      button = (
        <Button
          disabled={loginPending}
          label={intl.formatMessage({ id: MESSAGE_KEYS.LOGIN_BUTTON_LABEL })}
          icon={loginPending ? "pi pi-spinner pi-spin" : ""}
          type="submit"
          className="mt-4"
          onClick={(e) => {
            e.preventDefault();
            this.handleLogin();
          }}
        />
      );
    } catch (renderException) {
      logger.error(renderException);
      button = (
        <Button
          disabled={true}
          label={this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR })}
          className="mt-4"
        />
      );
    } finally {
      return button;
    }
  };

  render = () => {
    return (
      <div className="login_container">
        <OfflineDialog
          onhide={() => this.setState({ showOfflineDialog: false })}
          visible={this.state.showOfflineDialog}
        />
        <Toast ref={(el) => (this.toast = el)} />
        <Card
          title={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.LOGIN_TITLE_LABEL,
          })}
        >
          {this.renderInputFields()}
        </Card>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { pending, error },
      application: { backendAvailable },
    } = state;
    return {
      pending,
      error,
      backendAvailable,
    };
  } catch (mapException) {
    logger.warn(mapException);
    return {
      pending: false,
      error: null,
      backendAvailable: false,
    };
  }
};

export default connect(mapStateToProps, {
  login,
  loginFaild,
  loginSuccess,
})(injectIntl(withRouter(LoginView)));
