import React, { useState, useRef } from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// Generate download dialog
import download from "downloadjs";
// Localization
import { useIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  RESPONSIVE_BREAKPOINT,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Styling
import "./Style.scss";
// Helper functions
import { connect } from "react-redux";
import { ManualEntryDialog } from "./index";
import { initLogger, sendQuery } from "common/Helpers";
// Logging
const logger = initLogger("daily_report_table");

const DailyReportTable = ({
  appointments,
  pending,
  handleSelection,
  userId,
  handleRefresh,
  selDate,
}) => {
  const {
    DRIVES_HOURS_DEPARTURE,
    DRIVES_HOURS_ARRIVAL,
    APPOINTMENTS_CLIENT_LABEL,
    DRIVES_TABLE_START_ADDRESS,
    BILLS_DESTINATION_LABEL,
    APPOINTMENTS_VEHICLE_LABEL,
    DRIVES_NO_APPOINTMENT,
    MENU_DRIVES,
    DRIVES_TABLE_BUTTON_START,
    APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON,
    ERROR_DATA_SAVE,
    APPOINTMENTS_DELETE_NOTICE,
    DIALOG_CONFIRM_BUTTON_LABEL,
    DIALOG_DENY_BUTTON_LABEL,
  } = MESSAGE_KEYS;
  const intl = useIntl();
  const isMobile = window.innerWidth <= RESPONSIVE_BREAKPOINT;
  const toast = useRef(null);

  const heightStyle = {
    height: `calc(100vh - ${isMobile ? "360" : "410"}px)`,
  };

  const [reportPending, setReportPending] = useState(false);

  const generateTransportReport = (idPerson, idAppoint) => {
    if (idPerson !== null && idAppoint !== null) {
      setReportPending(true);
      sendQuery(
        `${QUERIES.GET_DELIVERY_NOTE}?appointmentIds=${idAppoint}&personId=${idPerson}`,
        "GET",
        null,
        "blob"
      ).then(
        (response) => {
          if (response) {
            download(
              response,
              `transport_report-${idAppoint}-${idPerson}.pdf`,
              "application/pdf"
            );
          }
          setReportPending(false);
        },
        (error) => {
          logger.error(error);
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: error,
          });
          setReportPending(false);
        }
      );
    } else {
      logger.error(
        "Could not generate transport report: idPerson or idAppointment may be null"
      );
      toast.current.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: "Could not generate transport report",
      });
    }
  };
  const rowClass = (data) => {
    return {
      daily_group_row: data.group === true,
      daily_cancelled_row:
        data.appointment.state && data.appointment.state === 6,
    };
  };

  const renderAddress = (rowData, column) => {
    if (rowData.appointment.driverEntry === null) {
      if (rowData[column.field]) {
        return <div>{rowData[column.field].replaceAll("#", " ")}</div>;
      } else {
        return <div> - </div>;
      }
    } else {
      return <div className="force-shrink"> </div>;
    }
  };

  const renderAddressResponsive = (rowData) => {
    if (rowData.appointment.driverEntry === null) {
      const departureText = rowData.fullFromAddress
        ? rowData.fullFromAddress.replaceAll("#", " ")
        : "-";
      const arrivalText = rowData.fullToAddress
        ? rowData.fullToAddress.replaceAll("#", " ")
        : "-";
      return (
        <div>
          <div>
            <strong>
              {intl.formatMessage({ id: DRIVES_TABLE_START_ADDRESS })}
            </strong>
          </div>
          <div>{departureText}</div>
          <div>
            <strong>
              {intl.formatMessage({ id: BILLS_DESTINATION_LABEL })}
            </strong>
          </div>
          <div>{arrivalText}</div>
        </div>
      );
    } else {
      return <div className="force-shrink"></div>;
    }
  };
  const renderTime = (rowData, column) => {
    let style = { backgroundColor: "" };
    const field = `${rowData.rueckfahrt ? "return" : "outward"}${column.field}`;
    const firstDriverId = rowData.appointment?.firstDriver?.personId
      ? rowData.appointment?.firstDriver?.personId
      : null;
    const firstDriverReturn = rowData.appointment?.firstDriverReturn?.personId
      ? rowData.appointment?.firstDriverReturn?.personId
      : null;
    const secondDriverId = rowData.appointment?.secondDriver?.personId
      ? rowData.appointment?.secondDriver?.personId
      : null;
    const secondDriverReturn = rowData.appointment?.secondDriverReturn?.personId
      ? rowData.appointment?.secondDriverReturn?.personId
      : null;
    if (rowData.appointment?.[field]) {
      if (
        (firstDriverId && firstDriverReturn
          ? firstDriverReturn === userId
          : false) ||
        (secondDriverId && secondDriverReturn
          ? secondDriverReturn === userId
          : false)
      ) {
        return <div>{rowData.appointment[field]}</div>;
      } else {
        if (firstDriverId === userId || secondDriverId === userId) {
          return (
            <div style={style}>
              {rowData.appointment["outward" + column.field]}
            </div>
          );
        } else {
          return (
            <div style={style}>
              {rowData.appointment["return" + column.field]}
            </div>
          );
        }
      }
    } else {
      return <div style={style}>-</div>;
    }
  };

  const renderCustomer = (rowData) => {
    if (rowData.appointment.driverEntry === null) {
      return (
        <div>
          {rowData?.customerfullname?.toString().includes("null")
            ? rowData.appointment?.girlName || "-"
            : rowData.customerfullname || "-"}
        </div>
      );
    } else {
      return <div className="force-grow">{rowData.appointment.remark}</div>;
    }
  };

  const renderCar = (rowData) => {
    if (rowData.appointment.driverEntry === null) {
      let car;
      if (rowData.rueckfahrt) {
        car = rowData.appointment.carIdReturn?.license_plate;
      } else {
        car = rowData.appointment.car?.license_plate;
      }
      if (car) {
        return <div>{car}</div>;
      } else {
        return <div>-</div>;
      }
    } else {
      return <div className="force-shrink"></div>;
    }
  };

  const renderCheck = (rowData, column) => {
    if (rowData[column.field]) {
      return <i className="pi pi-check ml-1" style={{ fontSize: "12px" }} />;
    } else {
      return <div className="force-shrink"></div>;
    }
  };

  const renderStartbutton = (rowData) => {
    if (rowData.appointment.driverEntry === null) {
      return (
        <div className="button-cell">
          <Button
            label={intl.formatMessage({ id: DRIVES_TABLE_BUTTON_START })}
            onClick={() => {
              handleSelection({
                ...rowData.appointment,
                fullName: rowData.customerfullname,
                rueckfahrt: rowData.rueckfahrt,
              });
            }}
            className="custom-button"
          />
          <Button
            /* loading={reportPending} */
            icon="pi pi-file-pdf"
            onClick={() => {
              generateTransportReport(
                userId,
                rowData.appointment.appointmentId
              );
            }}
            loading={reportPending}
            className="custom-button-right"
          />
        </div>
      );
    } else {
      return (
        <div className="button-cell">
          <Button
            label={intl.formatMessage({
              id: APPOINTMENTS_DRIVER_REPORT_EDIT_BUTTON,
            })}
            onClick={() => onCreateClick(rowData.appointment)}
            className="custom-button"
          />
          <Button
            icon="pi pi-times"
            className="p-button-danger custom-button-right"
            aria-label="Cancel"
            onClick={() => confirm(rowData.appointment.appointmentId)}
          />
        </div>
      );
    }
  };
  /* WORKAROUND :has() CSS */
  const setTDClass = () => {
    if (document.getElementsByClassName("force-grow").length > 0) {
      const forceGrowEl = document.querySelectorAll(".force-grow");
      forceGrowEl.forEach((e) => {
        e.parentNode.style.flexGrow = "1";
      });

      const forceShrinkEl = document.querySelectorAll(".force-shrink");
      forceShrinkEl.forEach((e) => {
        e.parentNode.style.display = "none";
      });
    } else {
      const elements = document.querySelectorAll('[role="cell"]');
      elements.forEach((e) => {
        e.style.display = "flex !important";
        e.style.flexGrow = "1 !important";
        e.style.whiteSpace = "nowrap !important";
      });
    }
  };

  /* this object is a placeholder for creating a new manual entry */
  let newAppointmentDummy = {
    starttime: null,
    remark: "",
    firstDriver: {
      personId: userId,
    },
    driverEntry: true,
    driverHasRead: 1,
    driverUpload: 2,
    outwardArrival: "",
    outwardDeparture: "",
    outwardPaper: "",
    outwardDischarge: "",
  };

  const [displayBasic, setDisplayBasic] = useState(false);
  const [selAppointment, setSelAppointment] = useState(null);

  const onCreateClick = (appointment) => {
    if (appointment === "new") {
      setSelAppointment(newAppointmentDummy);
    } else {
      setSelAppointment(appointment);
    }
    setDisplayBasic(true);
  };

  const onHide = () => {
    setDisplayBasic(false);
  };

  const showToast = () => {
    toast.current.show({
      severity: MESSAGE_SEVERITY.ERROR,
      summary: intl.formatMessage({ id: ERROR_DATA_SAVE }),
    });
  };

  /* -------------- Delete Dialog  ------------------ */
  const confirm = (id) => {
    confirmDialog({
      message: intl.formatMessage({ id: APPOINTMENTS_DELETE_NOTICE }),
      header: "Eintrag löschen",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
      rejectLabel: intl.formatMessage({ id: DIALOG_DENY_BUTTON_LABEL }),
      accept: () => acceptFunc(id),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = (id) => {
    sendQuery(`${QUERIES.DELETE_APPOINTMENT}${id}`, "DELETE").then(
      () => {
        handleRefresh(userId);
      },
      (error) => {
        logger.error(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({ id: ERROR_DATA_SAVE }),
        });
      }
    );
  };

  function rejectFunc() {
    return;
  }
  return (
    <div className="card">
      <Toast ref={toast} />
      <ManualEntryDialog
        selDate={selDate}
        userId={userId}
        handleRefresh={handleRefresh}
        showModal={displayBasic}
        onHide={onHide}
        selAppointment={selAppointment}
        setSelAppointment={setSelAppointment}
        showToast={showToast}
      ></ManualEntryDialog>
      <ConfirmDialog />
      <div style={heightStyle}>
        <DataTable
          value={appointments}
          scrollable
          scrollHeight="flex"
          loading={pending}
          emptyMessage={intl.formatMessage({ id: DRIVES_NO_APPOINTMENT })}
          className="drives_table"
          rowClassName={rowClass}
          onComplete={() => setTDClass()}
        >
          <Column
            header={intl.formatMessage({ id: DRIVES_HOURS_DEPARTURE })}
            body={renderTime}
            field="Paper"
            style={{ maxWidth: "90px" }}
          />
          <Column
            header={intl.formatMessage({ id: APPOINTMENTS_CLIENT_LABEL })}
            body={renderCustomer}
            field="customerfullname"
          />
          {!isMobile && (
            <Column
              header={intl.formatMessage({ id: DRIVES_TABLE_START_ADDRESS })}
              field="fullFromAddress"
              body={renderAddress}
            />
          )}
          {!isMobile && (
            <Column
              header={intl.formatMessage({ id: BILLS_DESTINATION_LABEL })}
              field="fullToAddress"
              body={renderAddress}
            />
          )}
          {isMobile && (
            <Column
              header={intl.formatMessage({ id: MENU_DRIVES })}
              body={renderAddressResponsive}
            />
          )}
          <Column
            header={intl.formatMessage({ id: DRIVES_HOURS_ARRIVAL })}
            field="Discharge"
            body={renderTime}
            style={{ maxWidth: "90px" }}
          />
          <Column
            header={intl.formatMessage({ id: APPOINTMENTS_VEHICLE_LABEL })}
            field="car"
            body={renderCar}
            style={{ maxWidth: "90px" }}
          />
          <Column
            header="F"
            body={renderCheck}
            style={{ maxWidth: "50px" }}
            field="fiche"
          />
          <Column
            header="T"
            body={renderCheck}
            style={{ maxWidth: "50px" }}
            field="title"
          />
          <Column
            body={renderStartbutton}
            headerClassName="drive_no_header"
            style={{ maxWidth: "170px" }}
            header={
              <Button
                icon="pi pi-plus"
                className="p-button mr-2"
                onClick={() => onCreateClick("new")}
              />
            }
          />
        </DataTable>
      </div>
    </div>
  );
};
const mapStatesToProps = (state) => {
  try {
    return { userId: state.mobile.userId };
  } catch (mapException) {
    logger.error(mapException);
    return { userId: null };
  }
};

export default connect(mapStatesToProps, {})(DailyReportTable);
