/* import "./wdyr"; */
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// PrimeReact components
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
// Redux
import { Provider } from "react-redux";
import store from "data/configPersist";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <App />
      </Provider>
    </ScrollToTop>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => {
    console.info("Stored application for offline use.");
  },
  onUpdate: (registration) => {
    alert("New version available!  Ready to update?");
    if (registration?.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});
//serviceWorker.unregister();
