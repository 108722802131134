import React from "react";

// Styling
import "./Style.scss";
// PrimeReact components
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const DriverStatusHeader = ({
  filterStatus,
  setFilterStatus,
  filterSearch,
  setFilterSearch,
}) => {
  const intl = useIntl();
  const {
    DRIVER_STATUS_SORT_DRIVER_NAME_LABEL,
    DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL,
    DRIVER_STATUS_SORT_NEWEST_LABEL,
    DIALOG_SEARCH_BUTTON_LABEL,
    VACATIONS_STATUS_LABEL,
  } = MESSAGE_KEYS;

  const availableOptions = [
    {
      name: `${intl.formatMessage({
        id: DRIVER_STATUS_SORT_DRIVER_NAME_LABEL,
      })}`,
      value: "driverName",
    },
    {
      name: `${intl.formatMessage({
        id: DRIVER_STATUS_SORT_LICENCE_PLATE_LABEL,
      })}`,
      value: "licencePlate",
    },
    {
      name: `${intl.formatMessage({ id: DRIVER_STATUS_SORT_NEWEST_LABEL })}`,
      value: "lastModifyDate",
    },
    {
      name: `${intl.formatMessage({ id: VACATIONS_STATUS_LABEL })}`,
      value: "sortByState",
    },
  ];

  return (
    <div className="driver_status_header_toolbar">
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder={intl.formatMessage({ id: DIALOG_SEARCH_BUTTON_LABEL })}
            value={filterSearch}
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
          />
        </span>
      </div>
      <div>
        <Dropdown
          dropdownIcon={"pi pi-sort-alt"}
          options={availableOptions}
          optionLabel="name"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.value)}
        />
      </div>
    </div>
  );
};

export default DriverStatusHeader;
