import React from "react";
// Redux
import { connect } from "react-redux";
import { setChangePending } from "actions/sessionActions";
// PrimeReact components
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
// Custom components
import { FloatingTextInput, TranslatedCB } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger, equalObjects, sendQuery } from "common/Helpers";
import { MESSAGE_KEYS, MESSAGE_SEVERITY } from "assets/staticData/enums";
// Static data
import { TRANSPORT_TYPES } from "assets/staticData/combodata";
import { QUERIES, VALIDATION_RULES } from "assets/staticData/enums";
// Logging
const logger = initLogger("payments_edit_view");

const EMPTY_STATE = {
  inputType: TRANSPORT_TYPES[0],
  inputAvailable: true,
  inputYear: new Date().getFullYear(),
  inputKm: 0,
  inputPlate: "",
  inputPhone: "",
  inputComment: "",
  inputWinFleetName: "",
  inputActive: true,

  validPlate: null,
  validType: null,

  updatePending: false,
};

class VehicleEditView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (!equalObjects(prevProps.value, value, "vehicleId")) {
      this.initInputs(value);
    }
  };

  initInputs = (value) => {
    if (value && value !== -1) {
      const {
        available,
        year,
        km,
        license_plate,
        phone,
        comment,
        type,
        active,
        winfleetName,
      } = this.props.value;
      this.setState({
        inputType: type
          ? TRANSPORT_TYPES.find((searchType) => {
              return searchType.transportTypeId === type;
            })
          : TRANSPORT_TYPES[0],
        inputAvailable: available ? true : false,
        inputYear: year ? year : new Date().getFullYear(),
        inputKm: km ? km : 0,
        inputPlate: license_plate ? license_plate : "",
        inputPhone: phone ? phone : "",
        inputComment: comment ? comment : "",
        inputActive: active,
        inputWinFleetName: winfleetName ? winfleetName : "",
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  mapInputsToDTO = () => {
    let dto;
    try {
      const { vehicleId } = this.props.value;
      const {
        inputType,
        inputAvailable,
        inputYear,
        inputKm,
        inputPlate,
        inputPhone,
        inputComment,
        inputActive,
        inputWinFleetName,
      } = this.state;
      dto = {
        ...this.props.value,
        available: inputAvailable,
        year: inputYear,
        km: inputKm,
        license_plate: inputPlate,
        phone: inputPhone,
        comment: inputComment,
        type: inputType ? inputType.transportTypeId : null,
        transportType: inputType ? inputType.name : "",
        vehicleId: vehicleId && vehicleId > 0 ? vehicleId : null,
        active: inputActive ? true : false,
        winfleetName: inputWinFleetName ? inputWinFleetName : "",
      };
    } catch (mapException) {
      logger.warn(mapException);
      dto = null;
    } finally {
      return dto;
    }
  };

  /**
   * Checks if input values are valid.
   * Resolves if true, rejects & returns errors else.
   *
   * @returns {Promise}
   */
  validateInputs = () => {
    return new Promise((resolve, reject) => {
      try {
        const { inputPlate, inputType } = this.state;
        let validPlate =
          inputPlate &&
          inputPlate.length >= VALIDATION_RULES.LICENSE_PLATE_MIN_LENGTH;
        let validType = inputType && inputType !== TRANSPORT_TYPES[0];
        this.setState({
          validPlate,
          validType,
        });
        if (validPlate && validType) {
          resolve();
        } else {
          const { intl } = this.props;
          const {
            VEHICLES_VALIDATION_PLATE_INVALID,
            VEHICLES_VALIDATION_PLATE_REQUIRED,
            VEHICLES_VALIDATION_TYPE_REQUIRED,
          } = MESSAGE_KEYS;
          let errors = [];
          if (!validPlate) {
            errors.push(
              intl.formatMessage({ id: VEHICLES_VALIDATION_PLATE_REQUIRED })
            );
          } else if (
            inputPlate.length < VALIDATION_RULES.LICENSE_PLATE_MIN_LENGTH
          ) {
            errors.push(
              intl.formatMessage({ id: VEHICLES_VALIDATION_PLATE_INVALID })
            );
          }
          if (!validType) {
            errors.push(
              intl.formatMessage({ id: VEHICLES_VALIDATION_TYPE_REQUIRED })
            );
          }
          reject(errors);
        }
      } catch (validationException) {
        logger.warn(validationException);
        reject([validationException.message]);
      }
    });
  };

  checkChangePending = (keyValue) => {
    let isPending;
    try {
      const {
        available,
        year,
        km,
        license_plate,
        phone,
        comment,
        type,
        vehicleId,
      } = this.props.value;

      isPending = !equalObjects(
        {
          available,
          year,
          km,
          license_plate,
          phone,
          comment,
          type,
          vehicleId,
        },
        this.mapInputsToDTO(),
        "transportTypeId"
      );
    } catch (checkException) {
      logger.warn(checkException, keyValue);
      isPending = true;
    } finally {
      this.props.setChangePending(isPending);
    }
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    this.validateInputs().then(
      () => {
        let data = this.mapInputsToDTO();
        if (data) {
          sendQuery(QUERIES.EDIT_CAR, "post", data).then(
            (response) => {
              if (response && response.vehicleId) {
                this.props.handleParentUpdate(response.vehicleId);
              } else {
                logger.warn("Server response is empty", response);
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: intl.formatMessage({
                    id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                  }),
                });
              }
            },
            (error) => {
              logger.warn(error);
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary: intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                }),
              });
            }
          );
        } else {
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.ERROR,
            }),
          });
        }
      },
      (errors) => {
        this.toast.show({
          severity: MESSAGE_SEVERITY.WARNING,
          summary: this.props.intl.formatMessage({
            id: MESSAGE_KEYS.WARNING_VALIDATION_FAILED,
          }),
          detail: errors,
        });
      }
    );
  };

  /**
   * Renders the bottom row of the form.
   * Returns a loading animation if a data transfer is pending, returns a row containing the save- & reset-button else.
   */
  renderButtonRow = () => {
    const {
      RESET_VALUES,
      VEHICLES_SAVE_BUTTON_LABEL,
      VEHICLES_CREATE_BUTTON_LABEL,
      ACTIVE,
      INACTIVE,
    } = MESSAGE_KEYS;
    const { intl, value, fetchPending } = this.props;
    const { updatePending, inputActive } = this.state;

    let saveButtonLabel = "";
    if (value && value.vehicleId < 0) {
      saveButtonLabel = intl.formatMessage({
        id: VEHICLES_CREATE_BUTTON_LABEL,
      });
    } else {
      saveButtonLabel = intl.formatMessage({
        id: VEHICLES_SAVE_BUTTON_LABEL,
      });
    }
    return (
      <div className="account_button_row">
        <Button
          onClick={() => this.handleSaveClick()}
          label={saveButtonLabel}
          disabled={fetchPending || updatePending}
          icon={fetchPending || updatePending ? "pi pi-spin pi-spinner" : ""}
        />

        <ToggleButton
          checked={inputActive ? true : false}
          offIcon="pi pi-times"
          offLabel={intl.formatMessage({ id: INACTIVE })}
          onIcon={"pi pi-check"}
          onLabel={intl.formatMessage({ id: ACTIVE })}
          onChange={(e) => this.setState({ inputActive: e.value })}
          disabled={updatePending}
        />

        <Button
          onClick={() => this.initInputs()}
          label={intl.formatMessage({
            id: RESET_VALUES,
          })}
          className="p-button-warning"
          disabled={fetchPending || updatePending}
        />
      </div>
    );
  };

  renderInputs = () => {
    const {
      VEHICLES_AVAILABLE_LABEL,
      VEHICLES_COMMENT_LABEL,
      VEHICLES_KM_LABEL,
      VEHICLES_LICENSE_PLATE_LABEL,
      VEHICLES_PHONE_LABEL,
      VEHICLES_YEAR_LABEL,
      VEHICLES_WINFLEET_NAME_LABEL,
    } = MESSAGE_KEYS;
    const { intl, fetchPending } = this.props;
    const {
      inputType,
      inputAvailable,
      inputYear,
      inputKm,
      inputPlate,
      inputPhone,
      inputComment,
      inputWinFleetName,

      validPlate,
      validType,

      updatePending,
    } = this.state;
    let content;
    try {
      content = (
        <div>
          <div className="flex flex-row justify-content-between align-items-end mb-4 mt-2">
            <FloatingTextInput
              value={inputPlate}
              onChange={(e) => {
                this.setState(
                  {
                    inputPlate: e.target.value,
                  },
                  () => {
                    this.checkChangePending({ inputPlate: e.target.value });
                  }
                );
              }}
              label={intl.formatMessage({ id: VEHICLES_LICENSE_PLATE_LABEL })}
              className="mr-2"
              valid={validPlate}
              disabled={fetchPending || updatePending}
            />
            <TranslatedCB
              value={inputType}
              onChange={(selection) =>
                this.setState({
                  inputType: selection,
                })
              }
              options={TRANSPORT_TYPES}
              className="mr-2"
              disabled={fetchPending || updatePending}
            />
            <span className="p-float-label mr-2">
              <InputMask
                mask="9999"
                value={inputYear}
                onChange={(e) => {
                  this.setState({ inputYear: e.value }, () => {
                    this.checkChangePending({ inputYear: e.value });
                  });
                }}
                id={VEHICLES_YEAR_LABEL}
                valid={validType}
                disabled={fetchPending || updatePending}
              />
              <label htmlFor={VEHICLES_YEAR_LABEL}>
                {intl.formatMessage({ id: VEHICLES_YEAR_LABEL })}
              </label>
            </span>
            <span className="p-float-label">
              <InputNumber
                id={VEHICLES_KM_LABEL}
                value={inputKm}
                onValueChange={(event) =>
                  this.setState({ inputKm: event.target.value }, () => {
                    this.checkChangePending({ inputKm: event.target.value });
                  })
                }
                mode="decimal"
                onFocus={(e) => e.target.select()}
                useGrouping={false}
                disabled={fetchPending || updatePending}
                locale="en-US"
              />
              <label htmlFor={VEHICLES_KM_LABEL}>
                {intl.formatMessage({ id: VEHICLES_KM_LABEL })}
              </label>
            </span>
          </div>
          <div className="flex flex-row justify-content-between align-items-end mb-4 mt-2">
            <FloatingTextInput
              value={inputPhone}
              onChange={(e) => {
                this.setState(
                  {
                    inputPhone: e.target.value,
                  },
                  () => {
                    this.checkChangePending({ inputPhone: e.target.value });
                  }
                );
              }}
              label={intl.formatMessage({ id: VEHICLES_PHONE_LABEL })}
              className="mr-2"
              disabled={fetchPending || updatePending}
            />
            <FloatingTextInput
              value={inputComment}
              onChange={(e) => {
                this.setState(
                  {
                    inputComment: e.target.value,
                  },
                  () => {
                    this.checkChangePending({ inputComment: e.target.value });
                  }
                );
              }}
              label={intl.formatMessage({ id: VEHICLES_COMMENT_LABEL })}
              className="mr-2"
              disabled={fetchPending || updatePending}
            />
            <div className="flex flex-row align-items-center">
              <Checkbox
                inputId={VEHICLES_AVAILABLE_LABEL}
                checked={inputAvailable}
                onChange={(e) => {
                  this.setState({ inputAvailable: e.checked });
                }}
                className="mr-1"
                disabled={fetchPending || updatePending}
              />
              <label htmlFor={VEHICLES_AVAILABLE_LABEL}>
                {intl.formatMessage({ id: VEHICLES_AVAILABLE_LABEL })}
              </label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-5">
            <FloatingTextInput
              value={inputWinFleetName}
              onChange={(e) => {
                this.setState(
                  {
                    inputWinFleetName: e.target.value,
                  },
                  () => {
                    this.checkChangePending({
                      inputWinFleetName: e.target.value,
                    });
                  }
                );
              }}
              label={intl.formatMessage({ id: VEHICLES_WINFLEET_NAME_LABEL })}
              className="mr-2"
              disabled={fetchPending || updatePending}
            />
          </div>
          {this.renderButtonRow()}
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      content = (
        <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>
      );
    } finally {
      return content;
    }
  };

  renderContent = () => {
    const { intl } = this.props;
    const { updatePending } = this.state;
    let content;
    try {
      if (updatePending) {
        content = <ProgressSpinner />;
      } else {
        content = this.renderInputs();
      }
    } catch (renderException) {
      logger.warn(renderException);
      content = (
        <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>
      );
    } finally {
      return content;
    }
  };

  render = () => {
    const { value, intl } = this.props;
    const {
      VEHICLES_NEW_ENTRY_TITLE,
      VEHICLES_EDIT_ENTRY_TITLE,
      VEHICLES_NO_SELECTION_LABEL,
    } = MESSAGE_KEYS;
    if (value !== null) {
      let panelHeader =
        value.vehicleId >= 0
          ? intl.formatMessage(
              { id: VEHICLES_EDIT_ENTRY_TITLE },
              { plate: value.license_plate }
            )
          : intl.formatMessage({ id: VEHICLES_NEW_ENTRY_TITLE });
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <Panel header={panelHeader}>{this.renderContent()}</Panel>
        </div>
      );
    } else {
      return (
        <Card>{intl.formatMessage({ id: VEHICLES_NO_SELECTION_LABEL })}</Card>
      );
    }
  };
}

export default connect(null, { setChangePending })(injectIntl(VehicleEditView));
