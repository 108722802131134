import React from "react";
// Helper functions
import { initLogger } from "common/Helpers";
// Websocket connections
import { Client } from "@stomp/stompjs";
// Live update
let websocket = null;
const logger = initLogger("GlobalShoutboxWebsocket");

class GlobalShoutboxWebsocket extends React.Component {
  componentDidMount = () => {
    this.connectWebsocket();
  };

  componentWillUnmount = () => {
    this.disconnectWebsocket();
  };

  connectWebsocket = () => {
    const { callback } = this.props;
    let brokerURL;

    switch (process.env.REACT_APP_SETTING) {
      case "prod":
      case "start":
      case "start-prod":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_PROD;
        break;
      case "demo":
      case "start-demo":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEMO;
        break;
      case "kai":
      case "start-local-kai":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_KAI;
        break;
      default:
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEV;
    }

    try {
      websocket = new Client();
      websocket.configure({
        brokerURL,
        onConnect: () => {
          logger.warn("Connected to", brokerURL);
          websocket.subscribe(
            process.env.REACT_APP_WEBSOCKET_SHOUTBOX_SUBSCRIBE,
            callback
          );
        },
      });

      websocket.activate();
    } catch (connectException) {
      logger.warn(connectException, brokerURL);
    }
  };

  getUrl = (target, callDelete) => {
    //target should be media, reaction or message
    const handler = {
      target: target === "shoutbox" ? "" : `_${target.toUpperCase()}`,
      action: callDelete ? "UNPUBLISH" : "PUBLISH",
    };

    const destination = `REACT_APP_WEBSOCKET_SHOUTBOX${handler.target}_${handler.action}`;

    return process.env[destination];
  };

  callWebsocket = (id, target, callDelete = false) => {
    const destination = this.getUrl(target, callDelete);
    try {
      logger.info("SENDING MESSAGE", destination, id);
      websocket.publish({
        destination,
        body: id,
      });
    } catch (sendException) {
      logger.warn(sendException);
    }
  };

  disconnectWebsocket = () => {
    try {
      if (websocket) {
        websocket.deactivate();
      }
    } catch (discoException) {
      logger.warn(discoException);
    }
  };

  render = () => {
    return false;
  };
}

export default GlobalShoutboxWebsocket;
