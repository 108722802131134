import React from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";

// Custom components
import { FloatingTextInput, SplitDateTimeInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import {
  safeRender,
  initLogger,
  equalObjects,
  valiDate,
  dateToISOString,
  sendQuery,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
const logger = initLogger("user_budget_dialog");
const EMPTY_STATE = {
  inputBudget: 0,
  inputYear: new Date().getFullYear().toString(),
  inputComment: "",
  inputBudgetStart: new Date(),

  updatePending: false,
};

class UserBudgetDialog extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (!equalObjects(prevProps.value, value, "vacationBudgetId")) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value) {
      const { budget, year, remark, budgetStartTime } = value;
      this.setState({
        inputBudget: budget,
        inputYear: year ? year.toString() : "",
        inputComment: remark ?? "",
        inputBudgetStart: budgetStartTime
          ? new Date(budgetStartTime)
          : new Date(),
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  mapInputsToDTO = () => {
    try {
      const { value, userId } = this.props;
      const { inputBudget, inputComment, inputYear, inputBudgetStart } =
        this.state;
      return {
        budget: inputBudget ?? EMPTY_STATE.inputBudget,
        year: inputYear ?? EMPTY_STATE.inputYear,
        remark: inputComment ?? EMPTY_STATE.inputComment,
        vacationBudgetId: value?.vacationBudgetId ?? null,
        personId: userId,
        state: 1,
        budgetStartTime: inputBudgetStart
          ? dateToISOString(inputBudgetStart)
          : null,
      };
    } catch (mapException) {
      logger.warn(mapException);
      return null;
    }
  };

  handleSave = (data) => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({ updatePending: true });
        sendQuery(QUERIES.EDIT_VACATION_BUDGET, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            logger.warn(error);
            reject();
          }
        );
      } catch (saveException) {
        logger.warn(saveException);
        reject();
      }
    });
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    const { ERROR_DATA_SAVE, USERS_VACATION_BUDGET_SAVE_SUCCESS } =
      MESSAGE_KEYS;
    let data = this.mapInputsToDTO();
    if (data) {
      this.handleSave(data).then(
        (newData) => {
          this.props.handleSave(newData);
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage({
              id: USERS_VACATION_BUDGET_SAVE_SUCCESS,
            }),
          });
          this.setState({ updatePending: false }, () => this.initInputs());
        },
        () => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({ id: ERROR_DATA_SAVE }),
          });
          this.setState({ updatePending: false });
        }
      );
    }
  };

  renderFooter = () => {
    const { onHide, intl, value } = this.props;
    const {
      DIALOG_CANCEL_BUTTON_LABEL,
      USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,
      USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    const { updatePending } = this.state;
    let saveLabel = intl.formatMessage({
      id: value
        ? USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL
        : USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,
    });
    return (
      <div className="flex flex-row justify-content-between mb-4">
        <Button
          icon={updatePending ? "pi pi-spin pi-spinner" : ""}
          disabled={updatePending}
          label={saveLabel}
          onClick={this.handleSaveClick}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
          disabled={updatePending}
        />
      </div>
    );
  };

  renderContent = () => {
    const { intl } = this.props;
    const {
      USERS_VACATION_BUDGET_COMMENT_LABEL,
      USERS_VACATION_BUDGET_LABEL,
      USERS_VACATION_BUDGET_YEAR_LABEL,
      USERS_VACATION_BUDGET_START_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputBudget,
      inputComment,
      inputYear,
      inputBudgetStart,

      updatePending,
    } = this.state;
    return (
      <div>
        <div className="flex flex-row mt-4 justify-content-between">
          <FloatingTextInput
            value={inputComment}
            label={intl.formatMessage({
              id: USERS_VACATION_BUDGET_COMMENT_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputComment: e.target.value });
            }}
            disabled={updatePending}
            className="mr-2"
          />

          <SplitDateTimeInput
            value={inputBudgetStart}
            onChange={(e) => {
              let newDate = valiDate(e) ? new Date(e) : null;
              this.setState({ inputBudgetStart: newDate });
            }}
            label={intl.formatMessage({
              id: USERS_VACATION_BUDGET_START_LABEL,
            })}
            disabled={updatePending}
          />
        </div>
        <div className="flex flex-row mt-4 justify-content-between">
          <span className="p-float-label mr-4">
            <InputNumber
              id={USERS_VACATION_BUDGET_LABEL}
              value={inputBudget}
              onValueChange={(event) =>
                this.setState({ inputBudget: event.value })
              }
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              onFocus={(e) => e.target.select()}
              useGrouping={false}
              locale="en-US"
              disabled={updatePending}
            />
            <label htmlFor={USERS_VACATION_BUDGET_LABEL}>
              {intl.formatMessage({ id: USERS_VACATION_BUDGET_LABEL })}
            </label>
          </span>
          <span className="p-float-label">
            <InputMask
              id={USERS_VACATION_BUDGET_YEAR_LABEL}
              mask={`9999`}
              value={inputYear}
              onChange={(e) => {
                this.setState({ inputYear: e.target.value });
              }}
              disabled={updatePending}
            />
            <label htmlFor={USERS_VACATION_BUDGET_YEAR_LABEL}>
              {intl.formatMessage({ id: USERS_VACATION_BUDGET_YEAR_LABEL })}
            </label>
          </span>
        </div>
      </div>
    );
  };

  render = () => {
    const { visible, onHide, intl, value } = this.props;
    const {
      USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,
      USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    let title = intl.formatMessage({
      id: value
        ? USERS_VACATION_BUDGET_UPDATE_BUTTON_LABEL
        : USERS_VACATION_BUDGET_CREATE_BUTTON_LABEL,
    });
    return (
      <Dialog
        header={title}
        visible={visible}
        onHide={onHide}
        footer={() => safeRender(this.renderFooter, "")}
      >
        <Toast ref={(el) => (this.toast = el)} />
        {safeRender(
          this.renderContent,
          intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })
        )}
      </Dialog>
    );
  };
}

export default injectIntl(UserBudgetDialog);
