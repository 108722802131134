/**
 * This component renders the address CRUD view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
import { setAddressSession, setChangePending } from "actions/sessionActions";
// PrimeReact Components
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
// Custom components
import { ErrorBoundary } from "components/common";
import {
  AddressEditLayout,
  AddressFilterLayout,
  AddressResultTable,
} from "./components";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
  ROWS_PER_PAGE,
} from "assets/staticData/enums";
// Helper classes
import {
  sendQuery,
  generateQueryParameters,
  initLogger,
  equalObjects,
  changePendingConfirm,
} from "common/Helpers";
// Styling
import "./Style.scss";
// Logging
const logger = initLogger("address_view");

const EMPTY_STATE = {
  first: 0,
  rows: isDesktop ? 10 : 5,
  rowsPerPage: ROWS_PER_PAGE,
  displayedAddresses: [],
  totalAddresses: 0,
  currentPage: 0,
  selectedRow: null,
  selectedAddress: null,
  fetchPending: false,
  fetchError: null,
  addressFetchPending: false,
  addressFetchError: null,
  queryString: "",
};

class AddressView extends React.Component {
  state = {
    ...EMPTY_STATE,
  };
  /**
   * Fetch the first page of addresses on mount.
   */
  componentDidMount = () => {
    const { setTopbarTitle, setChangePending, addressSession } = this.props;
    const { currentPage, rows } = this.state;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_ADDRESSES })
    );
    setChangePending(false);

    // Create ref to customer edit view for scroll to functionality.
    this.editViewRef = React.createRef();
    // Init state with redux session if available, fetch addresses else.
    if (addressSession) {
      this.setState({
        ...addressSession,
      });
    } else {
      this.fetchAddresses(currentPage, rows);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { rows, displayedAddresses, currentPage, selectedRow, queryString } =
      this.state;
    if (
      prevState.currentPage !== currentPage ||
      prevState.rows !== rows ||
      prevState.queryString !== queryString ||
      !equalObjects(prevState.selectedRow, selectedRow, "addressId")
    ) {
      this.handleSessionUpdate(displayedAddresses);
    }
  };

  handleSessionUpdate = (
    displayedAddresses = null,
    selectedAddress = null,
    selectedRow = null
  ) => {
    const { first, rows, totalAddresses, currentPage, queryString } =
      this.state;
    this.props.setAddressSession({
      first,
      rows,
      displayedAddresses: displayedAddresses
        ? [...displayedAddresses]
        : [...this.state.displayedAddresses],
      totalAddresses,
      currentPage,
      selectedAddress: selectedAddress
        ? { ...selectedAddress }
        : { ...this.state.selectedAddress },
      selectedRow: selectedRow
        ? { ...selectedRow }
        : { ...this.state.selectedRow },
      queryString,
    });
  };

  /**
   * Get called when the user changes the table page or number of displayed rows.
   * Will fetch data based on the user input.
   *
   * @param {*} event
   */
  handlePageChange = (event) => {
    const { first, rows, page } = event;
    this.setState(
      {
        first,
        rows,
        currentPage: page,
      },
      this.fetchAddresses(page, rows)
    );
  };

  /**
   * Get called when the user selects a table row.
   * Will fetch the data of the selected table entry and store it in the state.
   *
   * @param {Object} address
   */
  handleSelectionChange = (address) => {
    const { changePending, intl } = this.props;
    if (!changePending) {
      this.handleAddressFetch(address);
    } else {
      changePendingConfirm(address, this.handleAddressFetch, intl);
    }
  };

  handleAddressFetch = (address) => {
    const { intl, setChangePending } = this.props;
    try {
      if (address && address.hasOwnProperty("addressId")) {
        this.setState({
          addressFetchError: null,
          addressFetchPending: true,
        });
        let addressId = address.addressId;
        sendQuery(`${QUERIES.GET_ADDRESS_BY_ID}${addressId}`, "GET", null).then(
          (response) => {
            if (response?.address) {
              this.setState(
                {
                  selectedRow: address,
                  selectedAddress: response.address,
                  addressFetchPending: false,
                  addressFetchError: null,
                },
                () => {
                  this.handleSessionUpdate(null, response.address, address);
                  // Scroll to edit component if mobile.
                  if (this.editViewRef && !isDesktop) {
                    this.editViewRef.current.scrollIntoView({
                      block: "start",
                      behavior: "smooth",
                    });
                  }
                }
              );
            } else {
              let error = intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_FETCH,
              });
              this.setState(
                {
                  selectedAddress: null,
                  addressFetchPending: false,
                  addressFetchError: error,
                },
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary: error,
                })
              );
            }
          },
          (error) => {
            this.setState(
              {
                selectedRow: null,
                selectedAddress: null,
                addressFetchPending: false,
                addressFetchError: error,
              },
              this.toast.show({
                severity: MESSAGE_SEVERITY.ERROR,
                summary:
                  typeof error === "string"
                    ? error
                    : this.props.intl.formatMessage({
                        id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                      }),
              })
            );
          }
        );
      } else {
        this.setState({
          selectedRow: null,
          selectedAddress: { addressId: new Date().getTime() * -1 },
        });
      }
    } catch (selectionException) {
      this.setState(
        {
          selectedRow: null,
          selectedAddress: null,
          addressFetchPending: false,
          addressFetchError: selectionException,
        },
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: selectionException.message,
        })
      );
    } finally {
      setChangePending(false);
    }
  };

  /**
   * Get called by the filter component.
   * Resets current page to 0, stores new query string and calls the the search function with the new filter input values.
   *
   * @param {Object} filter
   */
  handleSearch = (filter) => {
    const { rows } = this.state;
    let queryString = generateQueryParameters(filter, "personId");
    this.setState({
      queryString,
    });
    this.fetchAddresses(0, rows, queryString);
  };

  /**
   * Send a request to get the page data for the table.
   * Table data is limited to the selected page and page per rows parameters.
   *
   * @param {Number} page
   * @param {Number} rows
   */
  fetchAddresses = (page, rows, queryString) => {
    const { currentUser } = this.props;
    this.setState({
      currentPage: 0,
      fetchPending: true,
      fetchError: null,
    });

    try {
      if (currentUser) {
        sendQuery(
          `${QUERIES.GET_ADDRESSES_PAGES}page=${page}&size=${rows}${
            queryString ? queryString : this.state.queryString
          }`,
          "get",
          null
        ).then(
          (response) => {
            this.setState(
              {
                displayedAddresses: response.content,
                totalAddresses: response.totalElements,
                fetchPending: false,
              },
              () => this.handleSessionUpdate(response.content)
            );
          },
          (error) => {
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              () => this.handleSessionUpdate([])
            );
          }
        );
      }
    } catch (fetchException) {
      logger.error(fetchException);
      this.setState(
        {
          fetchPending: false,
          fetchError: fetchException.message,
        },
        () => this.handleSessionUpdate([])
      );
    }
  };

  /**
   * Renders the detailled part of the view. Will render a text telling the user to select an address if no address is selected.
   * Will return a loading animation while the data fetch is pending, and the AddressEditLayout-component if an address is selected.
   * Will display an error message if an error occurred.
   *
   * @returns {JSX.Element}
   */
  renderEditLayout = () => {
    const { intl } = this.props;
    const {
      ERROR,
      ERROR_DATA_FETCH,
      ADDRESSES_TITLE_LABEL,
      ADDRESSES_SELECT_ADDRESS_LABEL,
    } = MESSAGE_KEYS;
    const {
      selectedAddress,
      addressFetchPending,
      addressFetchError,
      currentPage,
      rows,
    } = this.state;
    if (addressFetchPending) {
      return (
        <Panel header={intl.formatMessage({ id: ADDRESSES_TITLE_LABEL })}>
          <ProgressSpinner />
        </Panel>
      );
    } else if (addressFetchError) {
      return (
        <Card
          title={intl.formatMessage({
            id: ERROR,
          })}
        >
          {intl.formatMessage({ id: ERROR_DATA_FETCH })}
        </Card>
      );
    } else if (selectedAddress) {
      return (
        <Panel header={intl.formatMessage({ id: ADDRESSES_TITLE_LABEL })}>
          <ErrorBoundary>
            <AddressEditLayout
              selectedAddress={selectedAddress}
              handleParentUpdate={(newId) => {
                this.setState(
                  { selectedAddress: newId },
                  this.handleSelectionChange({ addressId: newId })
                );
                this.fetchAddresses(currentPage, rows);
              }}
            />
          </ErrorBoundary>
        </Panel>
      );
    } else {
      return (
        <Card
          title={intl.formatMessage({
            id: ADDRESSES_SELECT_ADDRESS_LABEL,
          })}
        />
      );
    }
  };

  renderNewAddressButton = () => {
    const { fetchError, fetchPending } = this.props;
    return (
      <Button
        icon="pi pi-plus"
        onClick={() => this.handleSelectionChange()}
        disabled={fetchError || fetchPending}
      />
    );
  };

  /**
   * Renders the toolbar with the address filter component.
   *
   * @returns {JSX.Element} - The rendered toolbar.
   */
  renderFilter = () => {
    const { fetchPending } = this.state;
    try {
      return (
        <Panel
          header={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.ADDRESSES_FILTER_TITLE_LABEL,
          })}
          toggleable
          collapsed={true}
        >
          <AddressFilterLayout
            isPending={fetchPending}
            handleSearch={this.handleSearch}
          />
        </Panel>
      );
    } catch (renderException) {
      logger.error(renderException);
      return (
        <div>
          {this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}
        </div>
      );
    }
  };

  /**
   * Renders the data table displaying the current page of addresses.
   * If no address data is available, it will display a single row with a "No data" message.
   * Will render an error message if something went wrong during rendering.
   *
   * @returns {JSX.Element}
   */
  renderTable = () => {
    const { intl } = this.props;
    const {
      displayedAddresses,
      first,
      fetchPending,
      totalAddresses,
      rows,
      rowsPerPage,
      selectedRow,
    } = this.state;

    try {
      return (
        <div>
          <AddressResultTable
            params={{
              displayedAddresses,
              first,
              fetchPending,
              totalAddresses,
              rows,
              rowsPerPage,
              selectedRow,
            }}
            handlePageChange={this.handlePageChange}
            handleSelectionChange={this.handleSelectionChange}
          />
        </div>
      );
    } catch (renderException) {
      return <div>{intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { isLandscape } = this.props;
    let notResponsive = isDesktop || isLandscape;
    return (
      <div className={notResponsive ? "grid" : ""}>
        <Toast ref={(el) => (this.toast = el)} />
        <div className={notResponsive ? "col-6" : ""}>
          <div className="flex tool_row">
            {this.renderNewAddressButton()}
            {this.renderFilter()}
          </div>
          {this.renderTable()}
        </div>
        <div ref={this.editViewRef} className={notResponsive ? "col-6" : ""}>
          {this.renderEditLayout()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser = null },
      session: { changePending = false, addressSession = null },
    } = state;
    return {
      currentUser,
      changePending,
      addressSession,
    };
  } catch (mapException) {
    logger.error(mapException);
    return { currentUser: null, changePending: false, addressSession: null };
  }
};

export default connect(mapStateToProps, {
  setTopbarTitle,
  setChangePending,
  setAddressSession,
})(injectIntl(withOrientationChange(AddressView)));
