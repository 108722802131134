import React from "react";
// PrimeReact components
import { Divider } from "primereact/divider";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { renderFullAddress, dateToTime } from "common/Helpers";

const TooltipAddress = ({
  title,
  text,
  fullAddress,
  addressTxt,
  starttime,
}) => {
  const intl = useIntl();

  let timeText = "";
  if (starttime && Date.parse(starttime)) {
    timeText = dateToTime(new Date(starttime));
  }

  return (
    <div>
      <div className="flex">
        <strong>
          {intl.formatMessage({
            id: title,
          })}
        </strong>
        {`: ${text}`}
      </div>
      {renderFullAddress(fullAddress)}
      {addressTxt ? addressTxt : <></>}
      {timeText && (
        <div>
          <strong>
            {`${intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENTS_DESTINATION_TIME_LABEL,
            })}
            : ${timeText}`}
          </strong>
        </div>
      )}
      <Divider />
    </div>
  );
};

export default TooltipAddress;
