import React from "react";
// PrimeReact components
import { Card } from "primereact/card";
// Helper functions
import { getCurrentUserLocale, initLogger } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, LOCALES } from "assets/staticData/enums";
import { isDesktop } from "react-device-detect";
// Logging
const logger = initLogger('vacation_budget');

const VacationBudget = (props) => {
  const {
    value: { vacationBudgetData, displayedDate, vacationstates },
    intl,
  } = props;
  const {
    ERROR_RENDER,
    USERS_VACATION_BUDGET_LABEL,
    VACATIONS_AVAILABLE_LABEL,
  } = MESSAGE_KEYS;

  const getColor = (value) => {
    return value === 0 ? 'text-orange-700' : value < 0 ? 'text-red-700' : 'text-black-700';
  }

  try {
    let content;
    if (vacationBudgetData) {
      const { budget, available, userName = "" } = vacationBudgetData;
    
      let discoData = Object.keys(vacationBudgetData);
      let contentData = [
        <div
          key="lbl_budget_bugdet"
          className="mr-2 flex justify-content-between"
        >
          {`${intl.formatMessage({
            id: USERS_VACATION_BUDGET_LABEL,
          })}:`}
          <span className={`font-bold ml-1 ${getColor(budget)}`}>{budget.toFixed(2)}</span>
        </div>,
        <div
          key="lbl_budget_available"
          className="mr-2 flex justify-content-between"
        >
          {`${intl.formatMessage({
            id: VACATIONS_AVAILABLE_LABEL,
          })}:`}
          <span className={`font-bold ml-1 ${getColor(available)}`}>{available.toFixed(2)}</span>
        </div>,
      ];
      let isGerman = getCurrentUserLocale() === LOCALES.GERMAN.key;

      vacationstates.forEach((state) => {
        if (discoData.includes(state.name)) {
          contentData.push(
            <div
              key={`lbl_budget_${state.appointmentStateId}`}
              className="mr-2 flex justify-content-between"
            >
              {`${isGerman ? state.nameDe : state.nameFr}:`}
              <span className="font-bold ml-1">
                {vacationBudgetData[state.name].toFixed(2)}
              </span>
            </div>
          );
        }
      });

      let contentRow = contentData.splice(0, contentData.length / 2);
      let yearLabel = displayedDate
        ? displayedDate.getFullYear()
        : new Date.getFullYear();

      let header = (
        <div className="font-bold pt-3 pl-3">{`${intl.formatMessage({
          id: USERS_VACATION_BUDGET_LABEL,
        })} ${userName} ${yearLabel}`}</div>
      );

      content = (
        <Card header={header} className="mb-2">
          <div
            className={`flex flex-row flex-wrap ${
              isDesktop ? "" : "justify-content-between"
            }`}
          >
            <div className={isDesktop ? "flex" : ""}>{contentRow}</div>
            <div className={isDesktop ? "flex" : ""}>{contentData}</div>
          </div>
        </Card>
      );
    } else {
      content = <></>;
    }
    return content;
  } catch (renderException) {
  
    logger.error(renderException);
    return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
  }
};

export default injectIntl(VacationBudget);
