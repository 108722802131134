import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import appReducer from './appReducer';
import sessionReducer from './sessionReducer';
import persistReducer from './persistReducer';
import wizardReducer from './appointmentReducer';
import mobileReducer from './mobileReducer';

export default combineReducers({
    authentication: loginReducer,
    application: appReducer,
    session: sessionReducer,
    persist: persistReducer,
    appWizard: wizardReducer,
    mobile: mobileReducer,
});