import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
// Localization
import { injectIntl } from "react-intl";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
// Custom components
import { TranslatedCB } from "components/common";
// Helper functions
import {
  priceCellTemplate,
  dateCellTemplate,
  invoiceTemplate,
  numberToPriceString,
  initLogger,
  sendQuery,
  changePendingConfirm,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import { BILL_STATUS } from "assets/staticData/combodata";

const logger = initLogger("Multi_payments_dialog");

const EMPTY_STATE = {
  selectedBills: [],
  updatePending: false,

  inputStatus: BILL_STATUS[0],
  validStatus: null,
  validBill: null,
};

class MultiStatusDialog extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.setState({
      ...EMPTY_STATE,
      selectedBills: this.props.value,
    });
  };

  componentDidUpdate = (prevProps) => {
    /*if (prevProps.visible === false && this.props.visible === true) {
      this.setState({
        ...EMPTY_STATE,
        selectedBills: this.props.value,
      });
    }*/

    if (prevProps.value !== this.props.value) {
      this.setState({
        ...EMPTY_STATE,
        selectedBills: this.props.value,
      });
    }
  };

  handleClose = () => {
    this.props.onHide();
  };

  handleRowDelete = (index) => {
    let newSelection = [...this.state.selectedBills];
    newSelection.splice(index, 1);
    this.setState({ selectedBills: [...newSelection] });
  };

  customerCellTemplate = (rowData) => {
    const { name } = rowData;

    return <div>{`${name ? name : ""}`}</div>;
  };

  mapStateToDTO = () => {
    const { selectedBills, inputStatus } = this.state;

    try {
      let transactionIds = [];
      selectedBills.forEach((bill) => {
        transactionIds.push(bill.transactionId);
      });
      return {
        transactionIds,
        state: inputStatus.billStatusId,
      };
    } catch (mapException) {
      logger.warn("Exception on mapStateToDTO", mapException);
      return null;
    }
  };

  validateInputs = () => {
    return new Promise((resolve, reject) => {
      let validationErrors = [];
      try {
        const { inputStatus, selectedBills } = this.state;
        const { intl } = this.props;
        const { ERROR, PAYMENTS_VALIDATION_ERROR_BILL } = MESSAGE_KEYS;
        let validBill = selectedBills && selectedBills.length > 0;
        if (!validBill) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: PAYMENTS_VALIDATION_ERROR_BILL }),
          });
        }
        let validStatus =
          inputStatus && inputStatus.hasOwnProperty("billStatusId");
        if (!validStatus) {
          validationErrors.push({
            severity: ERROR,
            summary: "HOW DID YOU GET HERE THIS IS FORBIDDEN",
          });
        }
        this.setState({
          validStatus,
          validBill,
        });
        if (validationErrors.length === 0) {
          resolve(this.mapStateToDTO());
        } else {
          reject(validationErrors);
        }
      } catch (validationException) {
        logger.warn("Exception on validateInputs", validationException);
        reject({
          summary: this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR }),
        });
      }
    });
  };

  handleSaveClick = () => {
    const { intl, handleParentUpdate } = this.props;
    this.setState({ updatePending: true });
    this.validateInputs().then(
      (newData) => {
        sendQuery(QUERIES.UPDATE_MULTI_BILL_STATUS, "post", newData).then(
          () => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: intl.formatMessage({
                id: MESSAGE_KEYS.BILLS_MULTI_STATUS_SUCCESS,
              }),
            });
            this.setState({ updatePending: false });
            handleParentUpdate();
            this.handleClose();
          },
          (updateError) => {
            logger.error(updateError);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary:
                typeof updateError === "string"
                  ? updateError
                  : this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                    }),
            });
            this.setState({ updatePending: false });
          }
        );
      },
      (error) => {
        logger.error(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary:
            typeof error === "string"
              ? error
              : this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                }),
        });
        this.setState({ savePending: false });
      }
    );
  };

  /** Custom table cell renderer that returns a remove-button. */
  deleteButtonTemplate = (rowData, index) => {
    const { intl } = this.props;
    const { name } = rowData;
    return (
      <Button
        type="button"
        icon="pi pi-minus-circle"
        className="p-button-danger"
        onClick={() => {
          changePendingConfirm(
            index.rowIndex,
            this.handleRowDelete,
            intl,
            name
          );
        }}
      />
    );
  };
  /**
   * Generates the table's footer cell containing the total remaining amount.
   * Only active bills will be used to calculate the total remaining amount.
   */
  priceFooterTemplate = () => {
    const { selectedBills } = this.state;
    let total = 0;
    if (selectedBills && selectedBills.length > 0) {
      selectedBills.forEach((bill) => {
        const { totalPrice, totalPaidReal, active } = bill;
        total += active === true ? (totalPrice ?? 0) - (totalPaidReal ?? 0) : 0;
      });
    }
    return <div>{numberToPriceString(total)}</div>;
  };

  renderPaymentInputs = () => {
    const { inputStatus, validStatus } = this.state;
    return (
      <div className={isDesktop ? "p-field col-4" : "payment_filter_row"}>
        <TranslatedCB
          value={inputStatus}
          options={BILL_STATUS}
          onChange={(selection) => {
            this.setState({ inputStatus: selection });
          }}
          valid={validStatus}
          appendId="dlg_multi_status"
        />
      </div>
    );
  };

  renderButtonRow = () => {
    const { intl } = this.props;
    const {
      DIALOG_CANCEL_BUTTON_LABEL,
      RESET_VALUES,
      BILLS_MULTI_BILLING_UPDATE_STATE_LABEL,
    } = MESSAGE_KEYS;
    const { updatePending } = this.state;

    return (
      <div className="multi_dialog_button_row mb-2">
        <Button
          label={intl.formatMessage({
            id: BILLS_MULTI_BILLING_UPDATE_STATE_LABEL,
          })}
          icon={updatePending ? "pi pi-spin pi-spinner" : "pi pi-check"}
          onClick={this.handleSaveClick}
          disabled={updatePending}
        />
        <Button
          label={intl.formatMessage({ id: RESET_VALUES })}
          className="p-button-warning"
          icon="pi pi-refresh"
          onClick={() => {
            this.setState({
              ...EMPTY_STATE,
              selectedBills: this.props.value,
            });
          }}
          disabled={updatePending}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          className="p-button-error"
          icon="pi pi-times"
          onClick={this.handleClose}
          disabled={updatePending}
        />
      </div>
    );
  };

  renderBillList = () => {
    const { selectedBills } = this.state;
    const { intl } = this.props;
    const {
      BILLS_NUMBER_LABEL,
      BILLS_CUSTOMER_LABEL,
      BILLS_MULTI_FOOTER_LABEL,
      BILLS_AMOUNT_DUE_LABEL,
      APPOINTMENT_STATUS_PENDING,
    } = MESSAGE_KEYS;

    return (
      <DataTable
        value={selectedBills}
        className="p-datatable-sm mt-2"
        scrollable
        scrollHeight="400px"
      >
        <Column
          style={{ width: "100px" }}
          body={(rowData) =>
            invoiceTemplate(rowData.invoiceNumber, rowData.orderDate)
          }
          header={intl.formatMessage({ id: BILLS_NUMBER_LABEL })}
        />
        <Column
          body={(rowData) => {
            return dateCellTemplate(rowData.orderDate);
          }}
          style={{ width: "100px" }}
          header=""
        />
        <Column
          body={(rowData) => this.customerCellTemplate(rowData)}
          header={intl.formatMessage({ id: BILLS_CUSTOMER_LABEL })}
        />
        <Column
          style={{ textAlign: "right" }}
          body={(rowData) => {
            return priceCellTemplate(
              rowData.totalPrice ? rowData.totalPrice : 0
            );
          }}
          footer={
            <div className="text-right">
              {intl.formatMessage({
                id: BILLS_MULTI_FOOTER_LABEL,
              })}
            </div>
          }
          header={intl.formatMessage({ id: BILLS_AMOUNT_DUE_LABEL })}
        />
        <Column
          style={{ textAlign: "right" }}
          body={(rowData) => {
            return priceCellTemplate(
              (rowData.totalPrice ? rowData.totalPrice : 0) -
                (rowData.totalPaidReal ? rowData.totalPaidReal : 0)
            );
          }}
          header={intl.formatMessage({ id: APPOINTMENT_STATUS_PENDING })}
          footer={this.priceFooterTemplate}
        />
        <Column body={this.deleteButtonTemplate} style={{ width: "62px" }} />
      </DataTable>
    );
  };

  render = () => {
    const { BILLS_MULTI_STATUS_LABEL } = MESSAGE_KEYS;
    const { intl, visible } = this.props;
    return (
      <Dialog
        id="dlg_multi_status"
        header={intl.formatMessage({ id: BILLS_MULTI_STATUS_LABEL })}
        visible={visible}
        onHide={this.handleClose}
        style={{ maxWidth: "650px" }}
        footer={this.renderButtonRow}
      >
        <Toast ref={(el) => (this.toast = el)} />
        {this.renderBillList()}
        {this.renderPaymentInputs()}
      </Dialog>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authentication.currentUser,
  };
};

export default connect(mapStateToProps, {})(injectIntl(MultiStatusDialog));
