import React from "react";

// Style
import "./Style.scss";

// Redux
import { connect } from "react-redux";
import { setTopbarTitle, setAppointmentTypes } from "actions";
import {
  setChangePending,
  setAppointmentSession,
} from "actions/sessionActions";

// Localization
import { injectIntl } from "react-intl";
import { sendQuery, sortObjectArray, initLogger } from "common/Helpers";
import {
  LOCALES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

// Custom components
import { Toast } from "primereact/toast";
import {
  AppointmentSearchHeader,
  AppointmentSearchContainer,
} from "./components";
import AppointmentCreateDialog from "components/SchedulerView/components/AppointmentCreateDialog";

// Websocket connections
import { Client } from "@stomp/stompjs";
import { AppointmentHistoryDialog } from "components/SchedulerView/components/index";

// Live update
let websocket = null;
const logger = initLogger("scheduler_view");

const { MENU_APPOINTMENTS_SEARCH } = MESSAGE_KEYS;

const APPOINTMENT_DIALOG_ID = "dlg_app_create";
const LIMIT_RESULTS = "200";
const DEFAULT_FROM_DATE = "1923-01-01";
const DEFAULT_TO_DATE = "2123-12-3";

class AppointmentSearchView extends React.Component {
  componentDidMount = () => {
    const { setTopbarTitle, intl } = this.props;
    setTopbarTitle(intl.formatMessage({ id: MENU_APPOINTMENTS_SEARCH }));
    this.getRequiredItems();
    this.connectWebsocket();
  };

  componentWillUnmount = () => {
    this.disconnectWebsocket();
  };

  state = {
    appointments: [],
    pendingSearch: false,
    inputSearchValue: "",
    inputShowHistory: false,

    status: [],
    cars: [],
    drivers: [],

    selection: null,
    showEditDialog: false,
    appointmentFetchPending: false,

    showHistoryModal: false,
    historyModalTitle: "",
    historyModalContent: "",
  };

  hideHistoryModal = () => {
    this.setState({ showHistoryModal: false });
  };

  handleShowHistoricalModal = (id) => {
    if (!id) {
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: "MISSING ID",
      });
      return;
    }
    const title = `${this.props.intl.formatMessage({
      id: MESSAGE_KEYS.HISTORICAL_APPOINTMENTS_INFO,
    })} ${id}`;

    sendQuery(`${QUERIES.GET_HISTORY_BY_APPOINTMENT}/${id}`, "GET", null).then(
      (data) => {
        if (data.length > 0) {
          this.setState({
            historyModalTitle: title,
            historyModalContent: data,
            showHistoryModal: true,
          });
        } else {
          this.toast.show({
            severity: MESSAGE_SEVERITY.INFO,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.HISTORICAL_APPOINTMENTS_NO_RESULT,
            }),
          });
        }
      },
      (error) => {
        logger.error(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: error?.message,
        });
      }
    );
  };

  connectWebsocket = () => {
    let brokerURL;

    switch (process.env.REACT_APP_SETTING) {
      case "prod":
      case "start":
      case "start-prod":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_PROD;
        break;
      case "demo":
      case "start-demo":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEMO;
        break;
      case "kai":
      case "start-local-kai":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_KAI;
        break;
      default:
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEV;
    }

    try {
      websocket = new Client();
      websocket.configure({
        brokerURL,
        onConnect: () => {
          logger.info("Connected to", brokerURL);
          websocket.subscribe(
            process.env.REACT_APP_WEBSOCKET_SUBSCRIBE,
            (msg) => {
              let newAppointment = JSON.parse(msg.body);
              if (typeof newAppointment === "number") {
                const newCurrentAppointments = [
                  ...this.state.appointments,
                ].filter(
                  (currentEvent) =>
                    currentEvent.appointmentid !== newAppointment
                );
                this.setState({
                  appointments: [...newCurrentAppointments],
                  eventFetchPending: false,
                });
                this.removeFromRedux(newAppointment);
              } else {
                let isNew = true;
                const updatedAppointments = this.state.appointments.map(
                  (appointment) => {
                    if (
                      appointment.appointmentid === newAppointment.appointmentid
                    ) {
                      isNew = false;
                      // Create a new object with the updated values using spread syntax
                      return {
                        ...appointment, // Spread the existing appointment attributes
                        ...newAppointment, // Spread the attributes from newAppointment (overrides existing values)
                      };
                    } else {
                      // If the appointment doesn't match the condition, return the original object
                      return appointment;
                    }
                  }
                );
                if (!isNew) {
                  this.setState({
                    appointments: [...updatedAppointments],
                    eventFetchPending: false,
                  });
                } else {
                  if (
                    this.state.inputSearchValue === "" ||
                    newAppointment?.customerfullname == null ||
                    newAppointment?.starttime == null ||
                    (!this.state.inputShowHistory &&
                      new Date(newAppointment?.starttime) < new Date())
                  ) {
                    return;
                  }

                  const formattedFullname =
                    newAppointment.customerfullname.toLowerCase();
                  const formattedInput =
                    this.state.inputSearchValue.toLowerCase();

                  if (formattedFullname.includes(formattedInput)) {
                    this.setState({
                      appointments: [...updatedAppointments, newAppointment],
                      eventFetchPending: false,
                    });
                  } else if (newAppointment?.healthInsuranceNumber !== "") {
                    if (
                      newAppointment.healthInsuranceNumber.includes(
                        formattedInput
                      )
                    ) {
                      this.setState({
                        appointments: [...updatedAppointments, newAppointment],
                        eventFetchPending: false,
                      });
                    }
                  }
                }
              }
            }
          );
        },
      });

      websocket.activate();
    } catch (connectException) {
      logger.warn(connectException, brokerURL);
    }
  };

  disconnectWebsocket = () => {
    try {
      if (websocket) {
        websocket.deactivate();
      }
    } catch (discoException) {
      logger.warn(discoException);
    }
  };

  callWebsocket = (id, callDelete = false) => {
    if (id) {
      try {
        const destination = callDelete
          ? process.env.REACT_APP_WEBSOCKET_UNPUBLISH
          : process.env.REACT_APP_WEBSOCKET_PUBLISH;
        logger.info("SENDING MESSAGE", destination);
        websocket.publish({
          destination,
          body: id,
        });
      } catch (sendException) {
        logger.warn(sendException);
      }
    }
  };

  removeFromRedux = (appointmentId) => {
    logger.warn("Removing from redux", appointmentId);
    const { appointmentSession, setAppointmentSession } = this.props;
    const newAppointmentSession = { ...appointmentSession };
    for (const [key] of Object.entries(newAppointmentSession)) {
      newAppointmentSession[key] = newAppointmentSession[key].filter(
        (entry) => entry.appointmentId !== appointmentId
      );
    }
    setAppointmentSession(newAppointmentSession);
  };

  formatDate = (date) => {
    // Get the year, month, and day components from the Date object
    const year = date.getFullYear(); // e.g., 2023
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0"); // e.g., 01

    // Concatenate the components to form the desired format "yyyy-mm-dd"
    return `${year}-${month}-${day}`;
  };

  getFilteredAppointments = (input, showHistory) => {
    this.setState({ inputSearchValue: input, inputShowHistory: showHistory });

    if (input === "") {
      this.setState({ appointments: [] });
      return;
    }

    const fromDate = showHistory
      ? DEFAULT_FROM_DATE
      : this.formatDate(new Date(), "-");

    this.setState({ pendingSearch: true });
    sendQuery(
      `${QUERIES.GET_DETAILED_APPOINTMENTS_BY_DATE}?fromDate=${fromDate}&toDate=${DEFAULT_TO_DATE}1&searchtext=${input}&size=${LIMIT_RESULTS}`,
      "get",
      {}
    ).then(
      (appointments) => {
        this.setState({
          appointments: [...appointments],
          pendingSearch: false,
        });
      },
      (error) => {
        logger.error(error);
        this.setState({ pendingSearch: false });
      }
    );
  };

  getRequiredItems = () => {
    const { GET_DRIVERS, GET_CARS, GET_APPOINTMENT_STATUS } = QUERIES;
    sendQuery(GET_DRIVERS, "get").then(
      (response) => {
        if (response && typeof (response[Symbol.iterator] === "function")) {
          let drivers = [...response];
          sortObjectArray(drivers, "alias");
          this.setState({ drivers });
        }
      },
      (error) => {
        logger.error(error);
      }
    );
    sendQuery(GET_CARS, "get").then(
      (response) => {
        if (response?.content) {
          this.setState({ cars: response.content.filter((car) => car.active) });
        }
      },
      (error) => {
        logger.error(error);
      }
    );
    sendQuery(GET_APPOINTMENT_STATUS, "get").then(
      (response) => {
        if (response) {
          this.setState({
            status: [...response],
          });
        }
      },
      (error) => {
        logger.error(error);
      }
    );
  };

  handleEventClick = (event) => {
    this.setState({
      selection: null,
      setAppointmentFetchPending: true,
    });

    sendQuery(
      `${QUERIES.GET_APPOINTMENT_BY_ID}${event?.event?._def?.extendedProps?.appointmentData?.appointmentid}`,
      "get",
      null
    ).then(
      (response) => {
        this.setState({
          selection: response,
          showEditDialog: true,
          setAppointmentFetchPending: false,
        });
      },
      (error) => {
        this.setState({
          showEditDialog: false,
          setAppointmentFetchPending: false,
        });
        if (this.toast) {
          logger.error(error);
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_DATA_FETCH,
            }),
          });
        }
      }
    );
  };

  handleCancelAppointments = () => {
    this.setState({
      setAppointmentFetchPending: true,
    });

    let appointmentIdsToCancel = this.state.appointments
      .filter((appointment) => appointment?.isSelected)
      .map((appointment) => appointment.appointmentid);

    if (appointmentIdsToCancel.length > 0) {
      sendQuery(
        `${QUERIES.CANCEL_APPOINTMENTS}`,
        "post",
        appointmentIdsToCancel
      ).then(
        (res) => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENT_CANCEL_SUCCESS,
            }),
          });
          this.setState({
            setAppointmentFetchPending: false,
          });
          appointmentIdsToCancel.forEach((id) => {
            this.callWebsocket(id);
          });
          this.getFilteredAppointments(
            this.state.inputSearchValue,
            this.state.inputShowHistory
          );
        },
        (error) => {
          logger.error(error);
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENT_CANCEL_ERROR,
            }),
          });
          this.setState({
            setAppointmentFetchPending: true,
          });
        }
      );
    } else {
      this.toast.show({
        severity: MESSAGE_SEVERITY.INFO,
        summary: this.props.intl.formatMessage({
          id: MESSAGE_KEYS.APPOINTMENT_CANCEL_NO_SELECTED,
        }),
      });
    }
  };

  render = () => {
    const { showHistoryModal, historyModalTitle, historyModalContent } =
      this.state;
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <AppointmentHistoryDialog
          visible={showHistoryModal}
          hideHistoryModal={this.hideHistoryModal}
          title={historyModalTitle}
          content={historyModalContent}
          isGerman={
            this.props.currentUser.currentLocale.languageId ===
            LOCALES.GERMAN.languageId
          }
          status={this.state.status}
        />
        <AppointmentCreateDialog
          keepInViewport={false}
          id={APPOINTMENT_DIALOG_ID}
          selectedAppointment={this.state.selection}
          visible={
            this.state.showEditDialog && !this.state.appointmentFetchPending
          }
          onHide={() => {
            this.setState({
              selection: null,
              showEditDialog: false,
            });
          }}
          handleParentUpdate={(newId) => {
            this.setState({ showEditDialog: false, selection: null }, () => {
              if (newId) {
                this.callWebsocket(newId);
              }
            });
          }}
          /*checkLineNumber={this.checkLineNumber} */
          drivers={this.state.drivers}
          cars={this.state.cars}
          status={this.state.status}
          callWebsocket={this.callWebsocket}
        />
        <div className="flex align-items-center search_container">
          <AppointmentSearchHeader
            pendingSearch={this.state.pendingSearch}
            handleFilter={this.getFilteredAppointments}
            handleCancelAppointments={this.handleCancelAppointments}
          />
        </div>
        <div
          className="appointments_container"
          style={{ overflowX: this.state.pendingSearch ? "hidden" : "auto" }}
        >
          <AppointmentSearchContainer
            pendingSearch={this.state.pendingSearch}
            appointments={this.state.appointments}
            status={this.state.status}
            currentUser={this.props.currentUser}
            handleEventClick={this.handleEventClick}
            handleShowHistoricalModal={this.handleShowHistoricalModal}
          />
        </div>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      persist: { appointmentTypes },
      application: { backendAvailable, menuActive },
      session: { appointmentSession },
    } = state;
    return {
      currentUser,
      appointmentSession,
      backendAvailable: backendAvailable,
      appointmentTypes,
      menuActive,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      appointmentSession: null,
      backendAvailable: null,
      appointmentTypes: null,
      menuActive: true,
    };
  }
};

export default connect(mapStateToProps, {
  setTopbarTitle,
  setChangePending,
  setAppointmentTypes,
  setAppointmentSession,
})(injectIntl(AppointmentSearchView));
