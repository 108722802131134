import { URLS, MESSAGE_KEYS } from "./enums";

const {
  MENU_ADDRESSES,
  MENU_APPOINTMENTS,
  MENU_CUSTOMERS,
  MENU_USERS,
  MENU_BILLS,
  MENU_PAYMENTS,
  MENU_BILL_MANAGEMENT,
  MENU_COMPANY_MANAGEMENT,
  MENU_APPOINTMENT_MANAGEMENT,
  MENU_SHIFTS,
  MENU_VACATION,
  MENU_DASHBOARD,
  MENU_SETTINGS,
  MENU_PRODUCTS,
  MENU_VEHICLES,
  MENU_ON_CALL,
  MENU_REGULARS,
  MENU_HOLIDAYS,
  MENU_DRIVES,
  MENU_DRIVER_STATUS,
  MENU_APPOINTMENTS_SEARCH,
  MENU_SHOUTBOX,
  MENU_MONTH_REPORT,
} = MESSAGE_KEYS;
const {
  ADDRESSES,
  SCHEDULER,
  CUSTOMERS,
  USERS,
  BILLS,
  PAYMENTS,
  VACATIONS,
  SHIFTS,
  SETTINGS,
  PRODUCTS,
  VEHICLES,
  ON_CALL,
  REGULARS,
  REGULARS_LIST,
  HOLIDAYS,
  DRIVES,
  DRIVER_STATUS,
  APPOINTMENTS_SEARCH,
  SHOUTBOX,
  MONTH_REPORT,
} = URLS;

const MENU_ITEMS = {
  ADDRESS_ITEM: {
    url: ADDRESSES,
    icon: "pi pi-map-marker",
    label: MENU_ADDRESSES,
  },
  SCHEDULER_ITEM: {
    url: SCHEDULER,
    icon: "pi pi-calendar",
    label: MENU_APPOINTMENTS,
  },
  CUSTOMER_ITEM: {
    url: CUSTOMERS,
    icon: "pi pi-fw pi-users",
    label: MENU_CUSTOMERS,
  },
  USERS_ITEM: {
    url: USERS,
    icon: "pi pi-fw pi-pencil",
    label: MENU_USERS,
  },
  BILLS_ITEM: {
    url: BILLS,
    icon: "pi pi-inbox",
    label: MENU_BILLS,
  },
  PAYMENTS_ITEM: {
    url: PAYMENTS,
    icon: "pi pi-money-bill",
    label: MENU_PAYMENTS,
  },
  SHIFTS_ITEM: {
    url: SHIFTS,
    icon: "pi pi-briefcase",
    label: MENU_SHIFTS,
  },
  VACATIONS_ITEM: {
    url: VACATIONS,
    icon: "pi pi-calendar-plus",
    label: MENU_VACATION,
  },
  DASHBOARD_ITEM: {
    url: "/",
    icon: "pi pi-fw pi-chart-bar",
    label: MENU_DASHBOARD,
  },
  SETTINGS_ITEM: {
    url: SETTINGS,
    icon: "pi pi-cog",
    label: MENU_SETTINGS,
  },
  PRODUCTS_ITEM: {
    url: PRODUCTS,
    icon: "pi pi-list",
    label: MENU_PRODUCTS,
  },
  ONCALLS_ITEM: {
    url: ON_CALL,
    icon: "pi pi-mobile",
    label: MENU_ON_CALL,
  },
  VEHICLES_ITEM: {
    url: VEHICLES,
    icon: "pi pi-key",
    label: MENU_VEHICLES,
  },
  CATEGORY_BILLS_ITEM: {
    url: null,
    icon: "pi pi-fw pi-globe",
    label: MENU_BILL_MANAGEMENT,
  },
  CATEGORY_APPOINTMENT_ITEM: {
    url: null,
    icon: "pi pi-th-large",
    label: MENU_APPOINTMENT_MANAGEMENT,
  },
  CATEGORY_COMPANY_ITEM: {
    url: null,
    icon: "pi pi-fw pi-home",
    label: MENU_COMPANY_MANAGEMENT,
  },
  REGULARS_ITEM: {
    url: REGULARS,
    icon: "pi pi-history",
    label: MENU_REGULARS,
  },
  REGULAR_LIST_ITEM: {
    url: REGULARS_LIST,
    icon: "pi pi-list",
    label: MENU_REGULARS,
  },
  HOLIDAYS_ITEM: {
    url: HOLIDAYS,
    icon: "pi pi-star",
    label: MENU_HOLIDAYS,
  },
  DRIVES_ITEM: {
    url: DRIVES,
    icon: "pi pi-car",
    label: MENU_DRIVES,
  },
  DRIVER_STATUS_ITEM: {
    url: DRIVER_STATUS,
    icon: "pi pi-question-circle",
    label: MENU_DRIVER_STATUS,
  },
  APPOINTMENTS_SEARCH_ITEM: {
    url: APPOINTMENTS_SEARCH,
    icon: "pi pi-search",
    label: MENU_APPOINTMENTS_SEARCH,
  },
  SHOUTBOX_ITEM: {
    url: SHOUTBOX,
    icon: "pi pi-comment",
    label: MENU_SHOUTBOX,
  },
  MONTH_REPORT_ITEM: {
    url: MONTH_REPORT,
    icon: "pi pi-check-square",
    label: MENU_MONTH_REPORT,
  },
};

export const BILLS_MENU = [
  {
    ...MENU_ITEMS.CATEGORY_BILLS_ITEM,
    items: [
      MENU_ITEMS.CUSTOMER_ITEM,
      MENU_ITEMS.ADDRESS_ITEM,
      MENU_ITEMS.BILLS_ITEM,
      MENU_ITEMS.PAYMENTS_ITEM,
      MENU_ITEMS.PRODUCTS_ITEM,
    ],
  },
  {
    ...MENU_ITEMS.CATEGORY_COMPANY_ITEM,
    items: [
      MENU_ITEMS.VACATIONS_ITEM,
      MENU_ITEMS.SHIFTS_ITEM,
      MENU_ITEMS.HOLIDAYS_ITEM,
    ],
  },
  MENU_ITEMS.REGULAR_LIST_ITEM,
  MENU_ITEMS.SHOUTBOX_ITEM,
];

export const ADMIN_MENU_PROD = [
  MENU_ITEMS.DASHBOARD_ITEM,
  {
    ...MENU_ITEMS.CATEGORY_APPOINTMENT_ITEM,
    items: [
      MENU_ITEMS.SCHEDULER_ITEM,
      MENU_ITEMS.APPOINTMENTS_SEARCH_ITEM,
      MENU_ITEMS.SHIFTS_ITEM,
      MENU_ITEMS.ONCALLS_ITEM,
      MENU_ITEMS.REGULARS_ITEM,
      MENU_ITEMS.DRIVER_STATUS_ITEM,
      MENU_ITEMS.MONTH_REPORT_ITEM,
    ],
  },
  {
    ...MENU_ITEMS.CATEGORY_BILLS_ITEM,
    items: [
      MENU_ITEMS.CUSTOMER_ITEM,
      MENU_ITEMS.ADDRESS_ITEM,
      MENU_ITEMS.BILLS_ITEM,
      MENU_ITEMS.PAYMENTS_ITEM,
      MENU_ITEMS.PRODUCTS_ITEM,
    ],
  },
  {
    ...MENU_ITEMS.CATEGORY_COMPANY_ITEM,
    items: [
      MENU_ITEMS.USERS_ITEM,
      MENU_ITEMS.VACATIONS_ITEM,
      MENU_ITEMS.VEHICLES_ITEM,
      MENU_ITEMS.SETTINGS_ITEM,
      MENU_ITEMS.SHIFTS_ITEM,
      MENU_ITEMS.HOLIDAYS_ITEM,
    ],
  },
  MENU_ITEMS.REGULAR_LIST_ITEM,
  MENU_ITEMS.DRIVES_ITEM,
  MENU_ITEMS.SHOUTBOX_ITEM,
];

export const ADMIN_MENU = [
  MENU_ITEMS.DASHBOARD_ITEM,
  {
    ...MENU_ITEMS.CATEGORY_APPOINTMENT_ITEM,
    items: [
      MENU_ITEMS.SCHEDULER_ITEM,
      MENU_ITEMS.APPOINTMENTS_SEARCH_ITEM,
      MENU_ITEMS.SHIFTS_ITEM,
      MENU_ITEMS.ONCALLS_ITEM,
      MENU_ITEMS.REGULARS_ITEM,
      MENU_ITEMS.DRIVER_STATUS_ITEM,
      MENU_ITEMS.MONTH_REPORT_ITEM,
    ],
  },
  {
    ...MENU_ITEMS.CATEGORY_BILLS_ITEM,
    items: [
      MENU_ITEMS.CUSTOMER_ITEM,
      MENU_ITEMS.ADDRESS_ITEM,
      MENU_ITEMS.BILLS_ITEM,
      MENU_ITEMS.PAYMENTS_ITEM,
      MENU_ITEMS.PRODUCTS_ITEM,
    ],
  },
  {
    ...MENU_ITEMS.CATEGORY_COMPANY_ITEM,
    items: [
      MENU_ITEMS.USERS_ITEM,
      MENU_ITEMS.VACATIONS_ITEM,
      MENU_ITEMS.VEHICLES_ITEM,
      MENU_ITEMS.SETTINGS_ITEM,
      MENU_ITEMS.SHIFTS_ITEM,
      MENU_ITEMS.HOLIDAYS_ITEM,
    ],
  },
  MENU_ITEMS.REGULAR_LIST_ITEM,
  MENU_ITEMS.DRIVES_ITEM,
  MENU_ITEMS.SHOUTBOX_ITEM,
];

export const USER_MENU = [
  MENU_ITEMS.VACATIONS_ITEM,
  MENU_ITEMS.ONCALLS_ITEM,
  MENU_ITEMS.REGULAR_LIST_ITEM,
  MENU_ITEMS.DRIVES_ITEM,
  MENU_ITEMS.SHOUTBOX_ITEM,
];

export const APPOINTMENTS_MENU = [
  MENU_ITEMS.SCHEDULER_ITEM,
  MENU_ITEMS.APPOINTMENTS_SEARCH_ITEM,
  MENU_ITEMS.SHIFTS_ITEM,
  MENU_ITEMS.ONCALLS_ITEM,
  MENU_ITEMS.VACATIONS_ITEM,
];

export const FIDUS_MENU = [MENU_ITEMS.DASHBOARD_ITEM];

export const AUTHOR_MENU = [MENU_ITEMS.SHOUTBOX_ITEM];

export const OFFLINE_MENU = [MENU_ITEMS.SCHEDULER_ITEM, MENU_ITEMS.DRIVES_ITEM];
