/**
 * This component generates a table displaying addresses of the selected user.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { changePendingConfirm, initLogger } from "common/Helpers";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { ADDRESS_TYPES } from "assets/staticData/combodata";

const logger = initLogger("address_edit_layout");

const AddressEditLayout = (props) => {
  const { intl, addresses, handleDelete, handleAdd, handleEdit } = props;
  const {
    CUSTOMERS_ADDRESS_LINE_1_LABEL,
    CUSTOMERS_ADDRESS_LINE_2_LABEL,
    CUSTOMERS_ADDRESS_CITY_LABEL,
    CUSTOMERS_ADDRESS_ZIP_LABEL,
    CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,
    ERROR_NO_DATA,
  } = MESSAGE_KEYS;

  /** Custom table cell renderer that returns a remove-button. */
  const editButtonTemplate = (rowData, index) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="mr-2"
        onClick={() => {
          handleEdit(index.rowIndex);
        }}
      />
    );
  };

  /** Custom table cell renderer that returns a remove-button. */
  const deleteButtonTemplate = (rowData) => {
    const { line1, line2, countryProvince, zipCode } = rowData;
    let value = `${line1 ? line1 : ""} ${line2 ? line2 : ""} ${
      zipCode ? zipCode : ""
    } ${countryProvince ? countryProvince : ""}`;
    return (
      <Button
        type="button"
        icon="pi pi-minus-circle"
        className="p-button-danger"
        onClick={() => {
          changePendingConfirm(
            rowData.addressId,
            handleDelete,
            props.intl,
            value
          );
        }}
      />
    );
  };

  /** Customer table cell renderer for address types.
   * Returns an icon with a tooltip representing the respective address type.
   * Selects the first address type (None) if no corresponding address type was found */
  const typeCellTemplate = (rowData) => {
    try {
      const { addressTypeId } = rowData;

      let addressType = ADDRESS_TYPES.find((atype) => {
        return atype.addressTypeId === addressTypeId;
      });

      if (!addressType) {
        addressType = ADDRESS_TYPES[0];
      }

      return (
        <Tippy content={intl.formatMessage({ id: addressType.messageKey })}>
          <i className={addressType.icon} />
        </Tippy>
      );
    } catch (renderException) {
      logger.error("Exception on typeCellTemplate", renderException);
      return <></>;
    }
  };

  let headerTemplate = (
    <div className="table-header">
      <Button
        icon="pi pi-plus-circle"
        style={{ float: "right" }}
        label={intl.formatMessage({
          id: CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,
        })}
        onClick={handleAdd}
      />
    </div>
  );

  const renderRowButtons = (rowData, index) => {    
    try {
      return (
        <div className="flex flex-row align-items-center">
          {handleEdit ? editButtonTemplate(rowData, index) : <></>}
          {deleteButtonTemplate(rowData)}
        </div>
      );
    } catch (renderException) {
      return <></>;
    }
  };

  const renderListItem = (item) => {
    const { addressId, line1, line2, countryProvince, zipCode } = item;
    return (
      <div
        key={`address_item_${addressId}`}
        className="responsive_address_item"
      >
        {typeCellTemplate(item)}
        <div>
          <div>{line1}</div>
          <div>{line2}</div>
          <div>
            {zipCode} {countryProvince}
          </div>
        </div>
        {deleteButtonTemplate(addressId)}
      </div>
    );
  };

  const renderListContent = () => {
    if (addresses && addresses.length > 0) {
      let addressList = [];
      addresses.forEach((address) => {
        addressList.push(renderListItem(address));
      });
      return addressList;
    } else {
      return <span>{intl.formatMessage({ id: ERROR_NO_DATA })}</span>;
    }
  };

  const renderList = () => {
    return (
      <div>
        {headerTemplate}
        {renderListContent()}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <DataTable
        value={addresses}
        header={headerTemplate}
        emptyMessage={intl.formatMessage({ id: ERROR_NO_DATA })}
        className="p-datatable-sm"
      >
        <Column
          field="addressTypeId"
          body={typeCellTemplate}
          style={{ textAlign: "center", width: "3em" }}
        />
        <Column
          field="line1"
          header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_1_LABEL })}
        />
        <Column
          field="line2"
          header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_2_LABEL })}
        />
        <Column
          field="countryProvince"
          header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_CITY_LABEL })}
        />
        <Column
          field="zipCode"
          header={intl.formatMessage({ id: CUSTOMERS_ADDRESS_ZIP_LABEL })}
        />

        <Column body={renderRowButtons} style={{ textAlign: "center" }} />
      </DataTable>
    );
  };

  if (isDesktop || props.isLandscape) {
    return renderTable();
  } else {
    return renderList();
  }
};

export default withOrientationChange(injectIntl(AddressEditLayout));
