import React from "react";
// PrimeReact components
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";

// Tippy.js tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// React redux
import { connect } from "react-redux";
// Helper functions
import {
  initLogger,
  sendQuery,
  dateToParameter,
  getCurrentUserLocale,
  dateToISOString,
  setShiftOrder,
  sortObjectArray,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  QUERIES,
  LOCALES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
import ShiftEventPopup from "components/ShiftsView/components/ShiftEventPopup";

const logger = initLogger("on_call_toolbar");

const EMPTY_STATE = {
  onCalls: [],
  types: [],
  currentLocale: LOCALES.FRENCH,
  displayPopup: false,
  selectedShift: null,
  drivers: [],
};

class OnCallToolbar extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    const { value } = this.props;
    this.setState({
      currentLocale: getCurrentUserLocale(),
    });
    this.fetchAppointmentTypes().then(() => {
      this.fetchOnCalls(value);
    });
    this.fetchDrivers();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (value.getTime() !== prevProps.value.getTime()) {
      this.fetchOnCalls(value);
    }
  };

  fetchAppointmentTypes = () => {
    const { shiftTypes } = this.props;
    return new Promise((resolve) => {
      try {
        sendQuery(QUERIES.GET_SHIFT_TYPES, "get").then(
          (response) => {
            this.setState({
              types: setShiftOrder(response),
            });
            resolve();
          },
          (error) => {
            logger.warn(error);
            this.setState({
              types: setShiftOrder(shiftTypes),
            });
            resolve();
          }
        );
      } catch (fetchException) {
        logger.warn(fetchException);
        this.setState({
          types: setShiftOrder(shiftTypes),
        });
        resolve();
      }
    });
  };

  /**
   * Fetch an store drivers list for later use.
   */
  fetchDrivers = () => {
    sendQuery(QUERIES.GET_DRIVERS, "get").then(
      (response) => {
        if (response) {
          this.setState({ drivers: [...response] });
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
  };

  fetchOnCalls = (startDate) => {
    let parameter = dateToParameter(startDate);
    try {
      sendQuery(
        `${QUERIES.GET_EMPLOYEE_ON_CALLS_RANGE}${parameter[0]}&toDate=${parameter[1]}`,
        "get"
      ).then(
        (response) => {
          this.mapResponseToEvents(response).then(
            (onCalls) => {
              this.setState({
                onCalls,
              });
            },
            () => {
              this.setState({
                onCalls: [],
              });
            }
          );
        },
        (error) => {
          logger.warn(error);
          this.setState({
            onCalls: [],
          });
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({
        onCalls: [],
      });
    }
  };

  /**
   * @param {Array} response
   * @returns {Promise}
   */
  mapResponseToEvents = (response) => {
    return new Promise((resolve, reject) => {
      logger.info("MAPPING RESPONSE", response);
      const { types, currentLocale } = this.state;
      const { value } = this.props;
      let onCalls = [];
      try {
        let categories = {};
        types.forEach((type) => {
          let title =
            currentLocale === LOCALES.GERMAN.key ? type.name : type.nameFr;
          let color = type.color;
          let appointmentId;
          let members = response.filter(
            (entry) => entry.typeOfAppointment === type.appointmentTypeId
          );
          if (members.length > 0) {
            appointmentId = members[0].appointmentId;
          }

          categories[type.appointmentTypeId] = {
            title,
            color,
            members: members ? members : [],
            order: type.order,
            appointmentTypeId: type.appointmentTypeId,
            appointmentId,
            startDate: value,
          };
        });

        Object.keys(categories).forEach((key) => {
          onCalls.push(categories[key]);
        });
        sortObjectArray(onCalls, "order");
        logger.info("DONE MAPPING", onCalls);
        resolve(onCalls);
      } catch (mapException) {
        logger.warn(mapException);
        reject();
      }
    });
  };

  mapEventToFullcalendar = (event) => {
    const { title, members, appointmentId, startDate, appointmentTypeId } =
      event;
    return {
      _def: {
        title,
        extendedProps: {
          members,
          limit: 0,
          startDate,
          appointmentId,
          appointmentTypeId,
        },
      },
      _instance: {
        range: { start: startDate },
      },
    };
  };

  mapEventToDTO = (event) => {
    logger.info("MAPPING", event);
    try {
      const {
        _def: {
          extendedProps: {
            members,
            startDate,
            appointmentId,
            appointmentTypeId,
          },
        },
      } = event;
      return {
        appointmentId,
        starttime: dateToISOString(startDate),
        type: 4,
        typeOfAppointment: appointmentTypeId,
        active: true,
        layerEmployees: [...members],
      };
    } catch (mappingException) {
      logger.warn(mappingException);
      return null;
    }
  };

  handleEventSave = (event) => {
    try {
      let data = this.mapEventToDTO(event);
      logger.info("SAVING", data);
      if (data) {
        sendQuery(QUERIES.EDIT_SHIFT, "post", data).then(
          (response) => {
            if (response && response.appointmentId) {
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.SHIFTS_UPDATE_SUCCESS,
                }),
              });
              let dateValue = new Date(this.props.value);
              this.setState(
                { displayPopup: false, selectedShift: null },
                this.fetchOnCalls(dateValue)
              );
            }
          },
          (error) => {
            logger.warn(error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_SAVE,
              }),
            });
          }
        );
      }
    } catch (dropException) {
      logger.warn(dropException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: this.props.intl.formatMessage({
          id: MESSAGE_KEYS.ERROR_DATA_SAVE,
        }),
      });
    }
  };

  renderMembers = (members) => {
    const { intl } = this.props;
    const { ON_CALLS_DRIVERS_TITLE, ERROR_RENDER, SHIFTS_NO_MEMBERS_LABEL } =
      MESSAGE_KEYS;
    let content;
    try {
      if (members.length === 0) {
        content = (
          <strong key={`mbr_title_${new Date().getTime()}`}>
            {intl.formatMessage({ id: SHIFTS_NO_MEMBERS_LABEL })}
          </strong>
        );
      } else {
        content = [
          <strong key={`mbr_title_${new Date().getTime()}`}>
            {intl.formatMessage({ id: ON_CALLS_DRIVERS_TITLE })}
          </strong>,
        ];

        members.forEach((member) => {
          content.push(
            <div
              key={`mbr_${member.shortname.replace(/\s+/g, "")}_${
                member.typeOfAppointment
              }`}
            >
              {member.shortname}, Beginn:{" "}
              {member.starttime ? member.starttime : "- "}
            </div>
          );
        });
      }
      return content;
    } catch (renderException) {
      logger.info(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderOnCalls = () => {
    const { intl } = this.props;
    const { SHIFTS_EVENT_SUMMARY_LABEL, ERROR_RENDER, SHIFTS_NO_SHIFT_LABEL } =
      MESSAGE_KEYS;
    const { onCalls } = this.state;
    let content;
    try {
      if (onCalls.length > 0) {
        content = [];

        onCalls.forEach((oncall) => {
          const { title, members, color } = oncall;
          content.push(
            <div
              key={`lbl_${title.replace(/\s/g, "")}`}
              className="col-1 mr-1"
              style={{ color: "white", background: color, cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  displayPopup: true,
                  selectedShift: this.mapEventToFullcalendar(oncall),
                })
              }
            >
              <Tippy content={<div>{this.renderMembers(members)}</div>}>
                <div>
                  <strong>{title}</strong>
                  <div>
                    {intl.formatMessage(
                      { id: SHIFTS_EVENT_SUMMARY_LABEL },
                      { members: members.length }
                    )}
                  </div>
                </div>
              </Tippy>
            </div>
          );
        });
      } else {
        content = (
          <div
            className="col-1 mr-1"
            style={{ color: "white", background: "black" }}
          >
            <div>
              <strong>
                {intl.formatMessage({ id: SHIFTS_NO_SHIFT_LABEL })}
              </strong>
            </div>
          </div>
        );
      }
      return content;
    } catch (renderException) {
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderPlaceHolders = () => {
    let content = [];
    for (let c = 0; c < 7; c++) {
      content.push(
        <div
          key={`oncall_placeholder_${c}`}
          className="col-1 mr-1"
          style={{ padding: "0px" }}
        >
          <Skeleton height="58.6667px"></Skeleton>
        </div>
      );
    }
    return content;
  };

  render = () => {
    const { onCalls, displayPopup, selectedShift, drivers } = this.state;

    if (onCalls && onCalls.length > 0) {
      return (
        <div>
          <Toast ref={(el) => (this.toast = el)} />
          <Dialog
            visible={displayPopup}
            onHide={() => this.setState({ displayPopup: false })}
            header={this.props.intl.formatMessage({
              id: MESSAGE_KEYS.SHIFTS_EDIT_BUTTON_LABEL,
            })}
          >
            <div className="p-2">
              <ShiftEventPopup
                value={selectedShift}
                drivers={drivers}
                handleSaveClick={(data) => {
                  let newSelection = { ...selectedShift };
                  newSelection._def.extendedProps.members = [];
                  data.forEach((member) => {
                    newSelection._def.extendedProps.members.push({
                      personId: member.personId,
                    });
                  });
                  this.handleEventSave(newSelection);
                }}
                handleCancel={() =>
                  this.setState({ displayPopup: false, selectedShift: null })
                }
                simpleView
              />
            </div>
          </Dialog>
          <Card className="mb-1">
            <div className="grid">{this.renderOnCalls()}</div>
          </Card>
        </div>
      );
    } else {
      return (
        <Card className="mb-1">
          <div className="grid">{this.renderPlaceHolders()}</div>
        </Card>
      );
    }
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      persist: { shiftTypes },
    } = state;
    return { currentUser, shiftTypes };
  } catch (mapException) {
    return { currentUser: null, shiftTypes: null };
  }
};

export default connect(mapStateToProps, {})(injectIntl(OnCallToolbar));
