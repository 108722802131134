import React from "react";
// PrimeReact Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// Helper functions
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const BirthdayView = (props) => {
  const { fetchPending, birthdays, intl } = props;
  const { USERS_NICK_NAME_LABEL, CUSTOMERS_BIRTH_DATE_LABEL, ERROR_NO_DATA } =
    MESSAGE_KEYS;

  const renderDateCell = ({ dateOfBirth }) => {
    if (dateOfBirth) {
      const dateParts = dateOfBirth.split("-");
      if (dateParts.length === 3) {
        return <div>{`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`}</div>;
      } else {
        return <div>{dateOfBirth}</div>;
      }
    } else {
      return <> </>;
    }
  };

  return (
    <div>
      <DataTable
        value={birthdays}
        emptyMessage={intl.formatMessage({
          id: ERROR_NO_DATA,
        })}
        loading={fetchPending}
        className="p-datatable-sm"
        paginator
        rows={18}
      >
        <Column
          field="alias"
          header={intl.formatMessage({ id: USERS_NICK_NAME_LABEL })}
          showFilterMenu={false}
        />
        <Column
          field="dateOfBirth"
          header={intl.formatMessage({ id: CUSTOMERS_BIRTH_DATE_LABEL })}
          body={renderDateCell}
        />
      </DataTable>
    </div>
  );
};

export default injectIntl(BirthdayView);
