import React from "react";
// PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { FilterMatchMode } from "primereact/api";
// Custom components
import VehicleEditView from "./components/VehicleEditView";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
import { setChangePending } from "actions/sessionActions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, ROWS_PER_PAGE } from "assets/staticData/enums";
// Helper functions
import { sendQuery, initLogger, changePendingConfirm } from "common/Helpers";
// Static values
import { TRANSPORT_TYPES } from "assets/staticData/combodata";
import { QUERIES, PAGE_LINK_SIZES } from "assets/staticData/enums";
import { ActiveCellTemplate } from "components/common/index";

const logger = initLogger("vehicles_view");

class VehiclesView extends React.Component {
  state = {
    first: 0,
    rows: ROWS_PER_PAGE[isDesktop ? 2 : 0],
    rowsPerPage: ROWS_PER_PAGE,
    displayedVehicles: [],
    totalVehicles: 0,
    fetchPending: false,
    fetchVehiclePending: false,
    selectedRow: null,
    selectedVehicle: null,

    filters: {
      license_plate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      transportType: { value: null, matchMode: FilterMatchMode.EQUALS },
      active: { value: true, matchMode: FilterMatchMode.EQUALS },
    },
  };

  componentDidMount = () => {
    const { setTopbarTitle, setChangePending } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_VEHICLES })
    );
    setChangePending(false);
    this.fetchVehicles();
  };

  /**
   * Gets called when a row in the table is selected.
   * If the changePending value within the state is true, the user will be prompted to confirm the selection.
   * On confirmation, the state is updated and the data for the new selection will be fetched and displayed. No change will happen else.
   *
   * @param {Object} vehicle - The new selection.
   */
  handleSelectionChange = (vehicle) => {
    const { changePending, intl } = this.props;
    this.setState({ selectedRow: vehicle });
    if (!changePending) {
      this.changeSelection(vehicle.vehicleId);
    } else {
      changePendingConfirm(vehicle.vehicleId, this.changeSelection, intl);
    }
  };

  handleParentUpdate = (newVehicleId) => {
    this.fetchVehicles();
    this.changeSelection(newVehicleId);
  };

  changeSelection = (newId) => {
    if (newId >= 0) {
      this.fetchVehicle(newId);
    } else {
      this.setState({
        selectedVehicle: newId,
      });
    }
    this.props.setChangePending(false);
  };

  fetchVehicle = (id) => {
    try {
      this.setState({ fetchVehiclePending: true });
      if (id) {
        return sendQuery(`${QUERIES.GET_CAR_BY_ID}${id}`, "get").then(
          (response) => {
            this.setState({
              fetchVehiclePending: false,
              selectedVehicle: response,
            });
          }
        );
      }
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({ fetchVehiclePending: false });
    }
  };

  fetchVehicles = () => {
    this.setState({
      fetchPending: true,
    });
    return sendQuery(QUERIES.GET_CARS, "get").then(
      (response) => {
        if (response && response.content) {
          this.setState({
            displayedVehicles: [...response.content],
            totalVehicles: response.length,
            fetchPending: false,
          });
        } else {
          logger.warn("No values found.");
          this.setState({
            displayedVehicles: [],
            totalVehicles: 0,
            fetchPending: false,
          });
        }
      },
      (error) => {
        logger.warn(error);
        this.setState({
          fetchPending: false,
        });
      }
    );
  };

  typeFilterTemplate = (options) => {
    const typeNames = TRANSPORT_TYPES.map((entry) => entry.name).slice(1);
    return (
      <Dropdown
        value={options.value}
        options={typeNames}
        onChange={(e) => {
          return options.filterApplyCallback(e.value);
        }}
      />
    );
  };

  renderTable = () => {
    const { intl } = this.props;
    const {
      ERROR_RENDER,
      ERROR_NO_DATA,
      VEHICLES_LICENSE_PLATE_LABEL,
      VEHICLES_TYPE_LABEL,
      VEHICLES_PHONE_LABEL,
      VEHICLES_KM_LABEL,
      ACTIVE,
    } = MESSAGE_KEYS;
    const {
      displayedVehicles,
      rowsPerPage,
      rows,
      totalVehicles,
      selectedRow,
      fetchPending,
      first,
    } = this.state;
    try {
      return (
        <div>
          <DataTable
            value={displayedVehicles}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => this.handleSelectionChange(e.value || selectedRow)}
            emptyMessage={this.props.intl.formatMessage({
              id: ERROR_NO_DATA,
            })}
            loading={fetchPending}
            rows={rows}
            totalRecords={totalVehicles}
            className="p-datatable-sm"
            paginator
            first={first}
            rowsPerPageOptions={rowsPerPage}
            onPage={(e) => {
              this.setState({ first: e.first, rows: e.rows });
            }}
            pageLinkSize={
              isDesktop ? PAGE_LINK_SIZES.DESKTOP : PAGE_LINK_SIZES.MOBILE
            }
            filterDisplay="row"
            globalFilterFields={["license_plate", "transportType", "phone"]}
            filters={this.state.filters}
          >
            <Column
              field="license_plate"
              header={intl.formatMessage({ id: VEHICLES_LICENSE_PLATE_LABEL })}
              filter
              showFilterMenu={false}
            />
            <Column
              field="transportType"
              filterField="transportType"
              header={intl.formatMessage({ id: VEHICLES_TYPE_LABEL })}
              filter
              filterElement={this.typeFilterTemplate}
              style={{ minWidth: "12em" }}
              showFilterMenu={false}
            />
            <Column
              field="phone"
              header={intl.formatMessage({ id: VEHICLES_PHONE_LABEL })}
              filter
              filterMatchMode="contains"
              style={{ minWidth: "12em" }}
              showFilterMenu={false}
            />
            <Column
              field="km"
              header={intl.formatMessage({ id: VEHICLES_KM_LABEL })}
              filter
              filterMatchMode="contains"
              style={{ minWidth: "12em" }}
              showFilterMenu={false}
            />
            <Column
              field="active"
              header={intl.formatMessage({ id: ACTIVE })}
              body={ActiveCellTemplate}
              filter
              dataType="boolean"
              filterMatchMode="equals"
              filterElement={(filter) => {
                return (
                  <TriStateCheckbox
                    value={filter.value}
                    onChange={(e) => {
                      filter.filterApplyCallback(e.value);
                    }}
                  />
                );
              }}
              showFilterMenu={false}
            />
          </DataTable>
        </div>
      );
    } catch (renderException) {
      logger.warn(renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { selectedVehicle, fetchVehiclePending } = this.state;
    return (
      <div className={isDesktop ? "grid" : "flex"}>
        <div className={isDesktop ? "col-6 pr-2" : ""}>
          <Button
            icon="pi pi-plus"
            onClick={() => {
              this.handleSelectionChange({ vehicleId: -1 });
            }}
          />
          {this.renderTable()}
        </div>
        <div className={isDesktop ? "col-6" : ""}>
          <VehicleEditView
            value={selectedVehicle}
            handleParentUpdate={this.handleParentUpdate}
            fetchPending={fetchVehiclePending}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      session: { changePending = false },
    } = state;
    return {
      changePending,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      changePending: false,
    };
  }
};

export default connect(mapStateToProps, { setTopbarTitle, setChangePending })(
  injectIntl(VehiclesView)
);
