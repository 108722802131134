import React from "react";
// React Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
// Custom components
import { SalesTableView, BirthdayTableView } from "./components";
// PrimeReact Components
import { Card } from "primereact/card";
// Static values
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
import { initLogger, isCurrentUserAdmin, sendQuery } from "common/Helpers";

const logger = initLogger("DashboardView");

class DashboardView extends React.Component {
  state = { birthdays: [], birthdayFetchPending: true };

  componentDidMount = () => {
    const { setTopbarTitle } = this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_DASHBOARD })
    );
    this.fetchBirthdays();
  };

  fetchBirthdays = () => {
    try {
      this.setState({ birthdayFetchPending: true });
      sendQuery(
        `${QUERIES.GET_USERS_PAGES}?size=3000&sort=personId,asc`,
        "get"
      ).then(
        (response) => {
          if (response && response.content) {
      /*       const upcomingBirthdays = []; */
      /*       response.content.forEach((entry) => {
              if (this.isUpcomingBirthday(entry.dateOfBirth) && entry.active) {
                upcomingBirthdays.push(entry);
              }
            }); */
            const fetchedBirthdays = [];
            response.content.forEach((entry) => {
              if (entry.active && entry.dateOfBirth) {
               fetchedBirthdays.push(entry);
              }
            });
      /*       upcomingBirthdays.sort(this.sortBirthdays); */
            fetchedBirthdays.sort(this.sortBirthdays)
            this.setState({
              birthdayFetchPending: false,
           /*    birthdays: upcomingBirthdays, */
              birthdays: fetchedBirthdays,
            });
          }
        },
        (error) => {
          logger.warn(error);
          this.setState({
            birthdayFetchPending: false,
          });
        }
      );
    } catch (fetchException) {
      logger.error(fetchException);
      this.setState({
        birthdayFetchPending: false,
      });
    }
  };

  sortBirthdays = (a, b) => {
    const today = new Date();
    const firstDay = new Date(a.dateOfBirth);
    firstDay.setFullYear(today.getFullYear());
    const secondDay = new Date(b.dateOfBirth);
    secondDay.setFullYear(today.getFullYear());
    if (firstDay > secondDay) {
      return 1;
    }
    if (firstDay < secondDay) {
      return -1;
    }
    return 0;
  };

  isUpcomingBirthday = (dtoDate) => {
    const today = new Date();
    let cmpDate = new Date(dtoDate);
    today.setHours(0, 0, 0, 0);
    cmpDate.setHours(0, 0, 0, 0);
    cmpDate.setFullYear(today.getFullYear());
    return cmpDate >= today;
  };

  render = () => {
    const { intl } = this.props;
    const { DASHBOARD_SALES_TITLE, DASHBOARD_BIRTHDAY_TITLE } = MESSAGE_KEYS;
    const { birthdayFetchPending, birthdays } = this.state;
    return (
      <div className="grid">
        <Card
          title={intl.formatMessage({ id: DASHBOARD_SALES_TITLE })}
          className="col-6"
          style={{ height: "100%" }}
        >
          <SalesTableView />
        </Card>
        <div className="col-4">
          {isCurrentUserAdmin() && (
            <Card
              title={intl.formatMessage({ id: DASHBOARD_BIRTHDAY_TITLE })}
              style={{ height: "100%" }}
            >
              <BirthdayTableView
                fetchPending={birthdayFetchPending}
                birthdays={birthdays}
              />
            </Card>
          )}
        </div>
        <div className="col-4">3</div>
        <div className="col-4">4</div>
        <div className="col-4">5</div>
        <div className="col-4">6</div>
      </div>
    );
  };
}

export default connect(null, { setTopbarTitle })(injectIntl(DashboardView));
