import React from "react";
// Responsive
import { isDesktop, isMobile } from "react-device-detect";
// React redux
import { connect } from "react-redux";
import { setTopbarTitle, setOnCallTypes } from "actions";
// Fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import frLocale from "@fullcalendar/core/locales/fr";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
// Custom components
import {
  OnCallEventLayout,
  OnCallEventPopup,
  OnCallEventPrompt,
} from "./components";
import { CalendarToolbar } from "components/common";
// Helper functions
import {
  initLogger,
  sendQuery,
  getCurrentUserLocale,
  getRandomColor,
  dateToQueryString,
  dateToISOString,
  hasCurrentUserRole,
  fetchHolidays,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  QUERIES,
  MESSAGE_KEYS,
  LOCALES,
  MESSAGE_SEVERITY,
} from "assets/staticData/enums";
import { ADMIN_ROLE, PLANNING_ROLE } from "assets/staticData/combodata";

const NEW_DRAGGABLE_CLASS = "new_draggable";

const logger = initLogger("on calls view");
class OnCallsView extends React.Component {
  calendarRef = React.createRef();

  state = {
    currentEvents: [],
    selectedAppointment: null,
    displayedDate: new Date(),
    drivers: [],
    currentLocale: getCurrentUserLocale(),
    eventFetchPending: false,

    dragInitialized: false,

    createDialogVisible: false,
    eventPromptVisible: false,

    newEvent: null,
    editDialogVisible: false,

    canEdit: false,
  };

  componentDidMount = () => {
    const { onCallTypes } = this.props;
    this.fetchDrivers();
    this.fetchOnCallTypes().then(
      () => {
        this.initDraggables();
        this.fetchAppointments();
      },
      (error) => {
        logger.warn(error);
        if (onCallTypes && onCallTypes.length > 0) {
          this.initDraggables();
          this.fetchAppointments();
        }
      }
    );

    this.setState({ canEdit: hasCurrentUserRole([ADMIN_ROLE, PLANNING_ROLE]) });

    this.props.setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_ON_CALL })
    );
  };

  /**
   * Adds drag&drop functionality to all draggable elements. (identified by their class name fc-event)
   */
  initDraggables = () => {
    try {
      // Fetch all drag objects and add draggable functionality.
      let draggables = [...document.getElementsByClassName("fc-event")];

      if (draggables && draggables.length > 0) {
        draggables.forEach((element) => {
          let eventType = this.props.onCallTypes.find((shift) => {
            return shift.appointmentTypeId === parseInt(element.id);
          });
          if (eventType) {
            const eventBackgroundColor = eventType.color;

            new Draggable(element, {
              eventData: {
                title: element.innerHTML,
                allDay: true,
                backgroundColor: eventBackgroundColor,
                borderColor: eventBackgroundColor,
                extendedProps: {
                  members: [],
                  limit: 0,
                  appointmentTypeId: eventType.appointmentTypeId,
                },
              },
            });
          }
        });
      }
    } catch (mountException) {
      logger.error(mountException);
    }
  };

  fetchAppointments = () => {
    try {
      const { displayedDate, canEdit } = this.state;
      let startDate = new Date(displayedDate.getTime());
      startDate.setDate(1);
      startDate.setDate(startDate.getDate() - 8);
      let endDate = new Date(
        displayedDate.getFullYear(),
        displayedDate.getMonth() + 1,
        0
      );
      endDate.setDate(endDate.getDate() + 8);
      this.setState({
        eventFetchPending: true,
      });
      sendQuery(
        `${QUERIES.GET_ON_CALLS_BY_DATE}${dateToQueryString(
          startDate
        )}&toDate=${dateToQueryString(endDate)}`,
        "get"
      ).then(
        (response) => {
          let displayedResults = [...response];
          if (!canEdit) {
            displayedResults = this.filterCalls(response);
          }
          this.mapResponseToEvents(displayedResults).then(
            (currentEvents) => {
              if (this.calendarRef?.current?.getApi) {
                let eventSources = this.calendarRef.current
                  .getApi()
                  .getEvents();
                // Clear calendar events to prevent duplicates after adding new event.
                const len = eventSources.length;
                for (let i = 0; i < len; i++) {
                  eventSources[i].remove();
                }
              }
              fetchHolidays(startDate, endDate).then(
                (holidays) => {
                  this.setState({
                    currentEvents: [...currentEvents, ...holidays],
                    eventFetchPending: false,
                  });
                },
                () => {
                  this.setState({ currentEvents, eventFetchPending: false });
                }
              );
            },
            (error) => {
              logger.warn(error);
              this.setState({
                currentEvents: [],
                eventFetchPending: false,
              });
            }
          );
        },
        (error) => {
          logger.warn(error);
          this.setState({
            eventFetchPending: false,
          });
        }
      );
    } catch (fetchException) {
      logger.warn(fetchException);
      this.setState({
        eventFetchPending: false,
      });
    }
  };

  /**
   * Fetch & store appointment types for draggable initialization.
   */
  fetchOnCallTypes = () => {
    return new Promise((resolve, reject) => {
      try {
        sendQuery(QUERIES.GET_ON_CALL_TYPES, "get").then(
          (response) => {
            if (response) {
              try {
                this.props.setOnCallTypes([...response]);
                resolve();
              } catch (e) {
                logger.warn(e);
                reject(e);
              }
            } else {
              reject(new Error("No types found."));
            }
          },
          (error) => {
            reject(error);
          }
        );
      } catch (fetchException) {
        logger.warn(fetchException);
        reject(fetchException);
      }
    });
  };

  /**
   * Fetch an store drivers list for later use.
   */
  fetchDrivers = () => {
    sendQuery(QUERIES.GET_DRIVERS, "get").then(
      (response) => {
        if (response && typeof (response[Symbol.iterator] === "function")) {
          this.setState({ drivers: [...response] });
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
  };

  /**
   * Filters the list to only contain either appointments with free free spots or or containing the current user.
   *
   * @param {Array<Object>} allCalls An array of appointments.
   * @returns {Array<Object} The filtered list.
   */
  filterCalls = (allCalls) => {
    const { currentUser } = this.props;
    return allCalls.filter(
      (entry) =>
        entry.placesTotal > entry.appointementEmployees.length ||
        entry.appointementEmployees.find(
          (employee) => employee.personId === currentUser.personId
        )
    );
  };

  /**
   * Gets called when the date in the calendar component is changed. It will update the state and call the Fullcalendar function to display the selected date.
   *
   * @param {Object} e - The onChange event object of the calendar component.
   * @param {Date} e.value - The selected date.
   */
  handleDateChange = (e) => {
    try {
      this.setState({ displayedDate: e.value }, () => {
        /** @type {Date} */
        let date = e.value;
        if (this.calendarRef.current && date) {
          this.fetchAppointments();
          const dumDumDate = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          this.calendarRef.current.getApi().gotoDate(dumDumDate);
        }
      });
    } catch (changeException) {
      logger.error(changeException);
    }
  };

  handleEventClick = (clickInfo) => {
    this.setState({
      selectedAppointment: clickInfo.event,
      selection: [...clickInfo.event._def.extendedProps.members],
    });
    if (isMobile) {
      this.openEditDialog(null);
    }
  };

  /**
   *
   * @param {Array<Object>} response
   */
  mapResponseToEvents = (response) => {
    return new Promise((resolve, reject) => {
      const { intl, onCallTypes } = this.props;
      try {
        logger.info("MAPPING RESPONSE", response);
        let events = [];
        if (response && response.length > 0) {
          response.forEach((entry) => {
            const {
              starttime,
              endtime,
              placesTotal,
              appointementEmployees,
              appointmentId,
              typeOfAppointment,
              description,
            } = entry;

            let eventColor;
            let title;

            const { currentLocale } = this.state;

            if (onCallTypes) {
              let searchShift = onCallTypes.find((entry) => {
                return entry.appointmentTypeId === typeOfAppointment;
              });
              if (searchShift) {
                title =
                  currentLocale === LOCALES.GERMAN.key
                    ? searchShift.name
                    : searchShift.nameFr;
                if (
                  appointementEmployees.filter(
                    (member) => member.confirmed === true
                  ).length === placesTotal
                ) {
                  eventColor = "#454852";
                } else {
                  eventColor = searchShift.color
                    ? searchShift.color
                    : getRandomColor();
                }
              }
            } else {
              title = intl.formatMessage({
                id: MESSAGE_KEYS.ON_CALLS_TYPES_WARNING,
              });
            }

            events.push({
              id: appointmentId,
              title,
              start: new Date(starttime),
              end: new Date(endtime),
              allDay: true,
              extendedProps: {
                limit: placesTotal,
                members: [...appointementEmployees],
                appointmentTypeId: typeOfAppointment,
                appointmentId,
                description,
              },
              backgroundColor: eventColor,
              borderColor: eventColor,
              textColor: "white",
              disableResizing: this.state.canEdit,
            });
          });
        }
        resolve(events);
      } catch (mapException) {
        logger.warn(mapException);
        reject([]);
      }
    });
  };

  mapEventToDTO = (event) => {
    logger.info("MAPPING", event);
    try {
      const {
        _def: {
          extendedProps: {
            appointmentTypeId,
            limit = 0,
            members = [],
            appointmentId = null,
            description,
          },
        },
        _instance: {
          range: { start, end },
        },
      } = event;
      let placesTotal = parseInt(limit);
      return {
        appointmentId,
        starttime: dateToISOString(start),
        endtime: dateToISOString(end), //"2021-01-17T10:15:00.000+00:00",
        placesTotal,
        placesUsed: members.length,
        placesAvailable: placesTotal - members.length,
        description,
        type: 2,
        typeOfAppointment: appointmentTypeId,
        active: true,
        appointementEmployees: [...members],
      };
    } catch (mappingException) {
      logger.warn(mappingException);
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: this.props.intl.formatMessage({
          id: MESSAGE_KEYS.ERROR_DATA_SAVE,
        }),
      });
      return null;
    }
  };

  handleEventSave = (event) => {
    try {
      let editEvent = event;
      if (editEvent._def === undefined && editEvent.event !== undefined) {
        editEvent = event.event;
      }
      let data = this.mapEventToDTO(editEvent);
      logger.info("SAVING", data);
      if (data) {
        sendQuery(QUERIES.EDIT_ON_CALL, "post", data).then(
          (response) => {
            if (response?.appointmentId) {
              this.toast.show({
                severity: MESSAGE_SEVERITY.SUCCESS,
                summary: this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
                }),
              });
              editEvent.setExtendedProp(
                "appointmentId",
                response.appointmentId
              );
              if (
                data.appointementEmployees.filter((member) => member.confirmed)
                  .length === data.placesTotal
              ) {
                editEvent.setProp("backgroundColor", "#454852");
              } else {
                const currentType = this.props.onCallTypes.find(
                  (callType) =>
                    callType.appointmentTypeId === data.typeOfAppointment
                );
                if (currentType) {
                  editEvent.setProp("backgroundColor", currentType.color);
                }
              }
              this.setState({ eventPromptVisible: false });
            }
          },
          (error) => {
            logger.warn(error);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.ERROR_DATA_SAVE,
              }),
            });
          }
        );
      }
    } catch (dropException) {
      logger.warn(dropException);
    }
  };
  /**
   * Handles the save click on the create event dialog.
   * Closes the dialog and adds the created event to the calendar view.
   *
   * @param {Object} eventData Values entered in the create dialog.
   */
  handleEventCreate = (eventData) => {
    const { state, start, members, limit } = eventData;

    let dto = {
      appointmentId: null,
      starttime: dateToISOString(start),
      endtime: dateToISOString(start),
      placesTotal: limit,
      placesUsed: members.length,
      placesAvailable: limit - members.length,
      description: "Standby",
      type: 2,
      typeOfAppointment: state ? state.appointmentTypeId : null,
      active: true,
      appointementEmployees: [...members],
    };

    sendQuery(QUERIES.EDIT_ON_CALL, "post", dto).then(
      (response) => {
        this.setState({
          createDialogVisible: false,
        });
        if (response?.appointmentId) {
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
            }),
          });
          this.fetchAppointments();
        }
      },
      (error) => {
        logger.warn(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: this.props.intl.formatMessage({
            id: MESSAGE_KEYS.ERROR_DATA_SAVE,
          }),
        });
      }
    );
  };

  renderDraggables = () => {
    const { intl, onCallTypes } = this.props;
    let newContentLabel = intl.formatMessage({
      id: MESSAGE_KEYS.ON_CALLS_NEW_SHIFT_TITLE,
    });

    if (this.state.canEdit) {
      if (isDesktop) {
        const { currentLocale } = this.state;
        let draggables = [];
        if (onCallTypes?.length)
          onCallTypes.forEach((shift) => {
            let label =
              currentLocale === LOCALES.GERMAN.key ? shift.name : shift.nameFr;
            let dragger = (
              <div
                id={shift.appointmentTypeId}
                key={`dragg_${label}`}
                className={`fc-event px-4 py-1 mr-1 ${
                  isDesktop ? "" : "flex-grow mb-1"
                } ${NEW_DRAGGABLE_CLASS}`}
                style={{
                  backgroundColor: shift.color ? shift.color : getRandomColor(),
                  borderRadius: "5px",
                  color: "white",
                  cursor: "pointer",
                  flexGrow: 1,
                }}
              >
                {label}
              </div>
            );
            draggables.push(dragger);
          });

        return (
          <div className="mb-2">
            <h4>{newContentLabel}</h4>
            <div
              id="external-events"
              className={`flex ${isDesktop ? "" : "flex-wrap"}`}
            >
              {draggables}
            </div>
          </div>
        );
      } else {
        return (
          <div className="mb-2 flex justify-content-center">
            <Button
              label={newContentLabel}
              onClick={() => {
                this.setState({
                  selection: [],
                  createDialogVisible: true,
                });
              }}
            />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  handleSave = (data) => {
    const { selectedAppointment } = this.state;
    selectedAppointment.setExtendedProp("members", data.members);
    selectedAppointment.setExtendedProp("limit", data.limit);
    selectedAppointment.setExtendedProp("description", data.description);
    selectedAppointment.setExtendedProp(
      "appointmentTypeId",
      data.state.appointmentTypeId
    );

    const title =
      getCurrentUserLocale() === LOCALES.GERMAN.key
        ? data.state.name
        : data.state.nameFr;

    selectedAppointment.setProp("title", title);
    this.handleEventSave(selectedAppointment);
    this.closeEditDialog();
  };

  openEditDialog = (event) => {
    if (isMobile || event === null) {
      this.setState({ editDialogVisible: true });
    } else {
      this.op.toggle(event);
    }
  };

  closeEditDialog = () => {
    if (isMobile) {
      this.setState({ editDialogVisible: false });
    } else {
      this.op.hide();
    }
  };

  cancelEventCreate = () => {
    const { newEvent } = this.state;
    newEvent.event.remove();
    this.setState({ eventPromptVisible: false, newEvent: null });
  };

  confirmEventCreate = (limit, title) => {
    const { newEvent } = this.state;
    newEvent.event.setExtendedProp("limit", limit);
    newEvent.event.setExtendedProp("description", title);
    this.handleEventSave(newEvent.event);
  };

  closeCreateDialog = () => {
    this.setState({ createDialogVisible: false });
  };

  handleEventReceive = (newEvent) => {
    const eventPromptVisible =
      newEvent?.draggedEl?.className.includes(NEW_DRAGGABLE_CLASS);
    this.setState({ newEvent, eventPromptVisible });
  };

  handleEventChange = (event) => {
    try {
      const {
        event: {
          _instance: {
            range: { start, end },
          },
        },
      } = event;
      logger.info("CHANGED RANGE", start, end);
      let difference = Math.ceil(Math.abs(start - end) / (24 * 60 * 60 * 1000));
      let startDate = start <= end ? start : end;
      logger.info(
        `DAY DIFFERENCE ${difference} STARTING FROM ${startDate.toDateString()}`
      );
      if (difference > 1) {
        let calendarApi = this.calendarRef.current.getApi();
        // Was stretched over multiple days, generate events for individual dates.
        let keepId = true;
        for (let c = 0; c < difference; c++) {
          let newDate = new Date(startDate.getTime());
          newDate.setDate(newDate.getDate() + c);
          // Keep ID of original event, set id to null for follow ups to create new events.
          let newId = keepId
            ? event.event._def.extendedProps.appointmentId
            : null;
          // Update member appointment id.
          let members = [...event.event._def.extendedProps.members];
          if (!keepId && members.length > 0) {
            members.forEach((member) => {
              member.appointmentId = null;
              member.standbyEmployeeId = null;
            });
          }
          calendarApi.addEvent({
            id: newId,
            title: event.event._def.title,
            start: newDate,
            end: newDate,
            allDay: event.event._def.allDay,
            extendedProps: {
              ...event.event._def.extendedProps,
              members,
              appointmentId: newId,
            },
            color: event.event._def.ui.backgroundColor,
          });
          // Unset flag to clear ids of follow up events.
          if (keepId) {
            keepId = false;
          }
        }
        event.event.remove();
      }
    } catch (splitException) {
      logger.warn(splitException);
    }
  };

  renderEditDialog = () => {
    const { selectedAppointment, drivers, editDialogVisible, canEdit } =
      this.state;
    const content = (
      <OnCallEventPopup
        value={selectedAppointment}
        drivers={drivers}
        handleSaveClick={this.handleSave}
        handleCancel={this.closeEditDialog}
        canEdit={canEdit}
      />
    );
    if (isMobile) {
      return (
        <Dialog visible={editDialogVisible} onHide={this.closeEditDialog}>
          {content}
        </Dialog>
      );
    } else {
      return (
        <OverlayPanel ref={(el) => (this.op = el)}>{content}</OverlayPanel>
      );
    }
  };

  renderEventContent = (e) => {
    return (
      <OnCallEventLayout
        value={e}
        onClick={this.openEditDialog}
        canEdit={this.state.canEdit}
      />
    );
  };

  render() {
    const {
      drivers,
      createDialogVisible,
      eventFetchPending,
      eventPromptVisible,
      currentEvents,
      canEdit,
      newEvent,
    } = this.state;
    let isSpecial =
      newEvent?.event?._def.extendedProps.appointmentTypeId === 10;

    // User can edit if either admin or planning rights are set.
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        {this.renderDraggables()}

        {this.renderEditDialog()}
        <OnCallEventPrompt
          visible={eventPromptVisible}
          onCancel={this.cancelEventCreate}
          onConfirm={this.confirmEventCreate}
          isSpecial={isSpecial}
        />
        <Dialog
          header={this.props.intl.formatMessage({
            id: MESSAGE_KEYS.ON_CALLS_NEW_SHIFT_TITLE,
          })}
          visible={createDialogVisible}
          onHide={this.closeCreateDialog}
          isSpecial={isSpecial}
        >
          <OnCallEventPopup
            value={null}
            drivers={drivers}
            handleSaveClick={this.handleEventCreate}
            handleCancel={this.closeCreateDialog}
            canEdit={canEdit}
          />
        </Dialog>
        <CalendarToolbar
          displayedDate={this.state.displayedDate}
          handleDateChange={this.handleDateChange}
          monthOnly
          disabled={eventFetchPending}
          selectOtherMonths={true}
        />

        <FullCalendar
          ref={this.calendarRef}
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          initialView={"dayGridMonth"}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={false}
          headerToolbar={false}
          droppable
          initialEvents={[]}
          eventReceive={this.handleEventReceive}
          events={currentEvents}
          eventContent={this.renderEventContent} // custom render function
          eventClick={this.handleEventClick}
          eventAdd={this.handleEventSave}
          eventChange={this.handleEventChange}
          locales={[deLocale, frLocale]}
          locale={this.state.currentLocale}
          firstDay={1}
          eventStartEditable={canEdit}
          eventDurationEditable={canEdit}
          contentHeight={window.innerHeight - 300}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  try {
    const {
      persist: { onCallTypes },
      authentication: { currentUser },
    } = state;

    return { currentUser, onCallTypes };
  } catch (mapException) {
    return { currentUser: null, onCallTypes: null };
  }
};

export default connect(mapStateToProps, { setTopbarTitle, setOnCallTypes })(
  injectIntl(OnCallsView)
);
