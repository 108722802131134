import React, { useState } from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from 'primereact/divider';
import { Checkbox } from 'primereact/checkbox';
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";

const SortDialog = (props) => {
  const { visible, onHide, onAccept, intl } = props;
  const {
    BILLS_MULTI_EXPORT_SORT_TITLE,
    BILLS_MULTI_EXPORT_SORT_SUMMARY,

    BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC,
    BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC,
    BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC,

    DIALOG_CANCEL_BUTTON_LABEL,
    DIALOG_DOWNLOAD_BUTTON_LABEL,
  } = MESSAGE_KEYS;

  const SORT_OPTIONS = [
    {
      value: 1,
      label: BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_ASC,
      icon: "pi-sort-numeric-up",
    },
    {
      value: 2,
      label: BILLS_MULTI_EXPORT_SORT_ORDER_BILL_NUMBER_DESC,
      icon: "pi-sort-numeric-down",
    },
    {
      value: 3,
      label: BILLS_MULTI_EXPORT_SORT_ORDER_CUSTOMER_ASC,
      icon: "pi-sort-alpha-up",
    },
  ];

  const [inputSort, setSort] = useState(SORT_OPTIONS[0].value);
  const [inputFormat, setFormat] = useState("PDF");
  const [checked, setChecked] = useState(false);

  let sortSelectionLeft = [];
  let sortSelectionRight = [];

  let maxRows = SORT_OPTIONS.length / 2;

  SORT_OPTIONS.forEach((option) => {
    let radioId = `rdb_sort_${option.value}`;
    let selectedArray =
      sortSelectionLeft.length < maxRows
        ? sortSelectionLeft
        : sortSelectionRight;
    selectedArray.push(
      <div className="p-field-radiobutton" key={radioId}>
        <RadioButton
          id={radioId}
          value={option.value}
          name="sort_selection"
          onChange={(e) => setSort(e.value)}
          checked={option.value === inputSort}
        />
        <label htmlFor={radioId}>
          {`${intl.formatMessage({ id: option.label }) }`}
          <i className={`pi ${option.icon}`} />
        </label>
      </div>
    );
  });

  const footer = (
    <div className="flex flex-row justify-content-between mb-2">
      <Button
        label={intl.formatMessage({ id: DIALOG_DOWNLOAD_BUTTON_LABEL })}
        onClick={() => {
          onAccept(inputSort, inputFormat, checked ? "&isHospital=true" : "&isHospital=false");
          onHide();
        }}
      />
      <Button
        label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
        onClick={onHide}
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      footer={footer}
      header={intl.formatMessage({ id: BILLS_MULTI_EXPORT_SORT_TITLE })}
    >
      <div className="mt-4">
        <div>{intl.formatMessage({ id: BILLS_MULTI_EXPORT_SORT_SUMMARY })}</div>
        <div className="flex flex-row justify-content-between align-items-start mt-2">
          <div className="mr-2">{sortSelectionLeft}</div>
          <div>{sortSelectionRight}</div>
        </div>
        <Divider />
        <div className="flex flex-row">
          <div className="mr-2">
            <RadioButton
            id="rdb_pdf"
              value="PDF"
              name="format"
              onChange={(e) => setFormat(e.value)}
              checked={inputFormat === "PDF"}
            />
            <label htmlFor="rdb_pdf" className="ml-1">PDF <i className="pi pi-file-pdf"/></label>
          </div>
          <div>
            <RadioButton
            id="rdb_xls"
              value="XLSX"
              name="format"
              onChange={(e) => setFormat(e.value)}
              checked={inputFormat === "XLSX"}
            />
            <label htmlFor="rdb_xls" className="ml-1">XLS <i className="pi pi-file-excel"/></label>
          </div>
          <div>
            <label htmlFor="hospitalCheck" className="ml-1">Hospital: </label>
            <Checkbox inputId="hospitalCheck" checked={checked} onChange={e => setChecked(e.checked)} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default injectIntl(SortDialog);
