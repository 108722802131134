import {
  LOGIN_FAIL,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  START_OFFLINE_MODE,
  LOGOUT_FAIL,
  UPDATE_ACCOUNT_PENDING,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  SET_TOKEN_EXPIRED,
} from "actions/types";

const INITIAL_STATE = {
  currentUser: null,
  pending: false,
  error: null,
  tokenExpired: false,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_ACCOUNT_PENDING:
    case LOGIN_PENDING:
      return { ...state, pending: true, error: null };
    case UPDATE_ACCOUNT_SUCCESS:      
      return { ...state, pending: false, currentUser: {...state.currentUser, ...payload}, error: null };
    case START_OFFLINE_MODE:
    case LOGIN_SUCCESS:
      return { ...state, pending: false, currentUser: payload, error: null, tokenExpired: false };
    case UPDATE_ACCOUNT_FAIL:
    case LOGIN_FAIL:
      return { ...state, pending: false, currentUser: null, error: payload };
    case LOGOUT_SUCCESS:
      return { ...state, currentUser: null, error: null, tokenExpired: false };
    case LOGOUT_FAIL:
      return { ...state, error: payload };
    case SET_TOKEN_EXPIRED:
      return {...state, tokenExpired: true, currentUser: null}
    default:
      return state;
  }
};


export default loginReducer;