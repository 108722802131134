import React from "react";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Helper functions
import { dateToString } from "common/Helpers";

const TooltipBill = ({ lastInvoiceNumber, lastInvoiceDate, notPaid }) => {
  const intl = useIntl();
  const {
    APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
    APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
    BILLS_AMOUNT_REMAINING_LABEL,
  } = MESSAGE_KEYS;
  return (
    <div>
      <div className="flex">
        <strong className="mr-1">
          {intl.formatMessage({
            id: APPOINTMENTS_LAST_BILL_NUMBER_LABEL,
          })}
        </strong>
        {lastInvoiceNumber ? lastInvoiceNumber : "-"}
      </div>

      <div className="flex">
        <strong className="mr-1">
          {intl.formatMessage({
            id: APPOINTMENTS_LAST_PAYMENT_DATE_LABEL,
          })}
        </strong>
        {lastInvoiceDate ? dateToString(lastInvoiceDate) : "-"}
      </div>

      <div className="flex">
        <strong>
          {intl.formatMessage({
            id: BILLS_AMOUNT_REMAINING_LABEL,
          })}
        </strong>
        {`: ${notPaid ? notPaid : "-"} €`}
      </div>
    </div>
  );
};

export default TooltipBill;
