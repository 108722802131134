import React, { useState } from "react";
// PrimeReact components
import { Button } from "primereact/button";
// Custom components
import { FloatingTextInput, FloatingPassword } from "components/common";
// Redux
import { connect } from "react-redux";
import { login } from "actions";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";

const LoginLayout = (props) => {
  const { LOGIN_USERNAME_LABEL, LOGIN_PASSWORD_LABEL } = MESSAGE_KEYS;
  const { intl, pending, login } = props;

  const [inputLogin, setLogin] = useState("");
  const [inputPassword, setPassword] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login(inputLogin, inputPassword, false);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <FloatingTextInput
          id={LOGIN_USERNAME_LABEL}
          value={inputLogin}
          label={intl.formatMessage({
            id: LOGIN_USERNAME_LABEL,
          })}
          onChange={(event) => {
            setLogin(event.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <FloatingPassword
          id={LOGIN_PASSWORD_LABEL}
          value={inputPassword}
          label={intl.formatMessage({
            id: LOGIN_PASSWORD_LABEL,
          })}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          feedback={false}
          onKeyDown={handleKeyDown}
        />
      </div>
      <Button
        disabled={pending}
        label={intl.formatMessage({ id: MESSAGE_KEYS.LOGIN_BUTTON_LABEL })}
        icon={pending ? "pi pi-spinner pi-spin" : ""}
        onClick={() => {
          login(inputLogin, inputPassword, false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    authentication: { pending },
    application: { backendAvailable },
  } = state;
  return { pending, backendAvailable };
};

export default connect(mapStateToProps, { login })(injectIntl(LoginLayout));
