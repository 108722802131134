import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
// Localization
import { injectIntl } from "react-intl";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
// Custom components
import { SplitDateTimeInput } from "components/common";
// Helper functions
import {
  priceCellTemplate,
  dateCellTemplate,
  numberToPriceString,
  initLogger,
  sendQuery,
  dateToQueryString,
  changePendingConfirm,
  valiDate,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

const availableOptions = [
  {
    name: `BILL`,
    value: "bill",
  },
  {
    name: `BCEE`,
    value: "bcee",
  },
  {
    name: `CCRA`,
    value: "ccra",
  },
];

const logger = initLogger("Multi_payments_dialog");

const EMPTY_STATE = {
  selectedBills: [],
  updatePending: false,

  inputBank: "",
  inputAmount: 0,
  inputBillingDate: new Date(),
  inputComment: "",
  inputFillLast: true,

  validAmount: null,
  validBank: null,
  validBillingDate: null,
  validBill: null,
};

class MultiPaymentsDialog extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.setState({
      ...EMPTY_STATE,
      selectedBills: this.props.value,
    });
  };

  componentDidUpdate = (prevProps) => {
    /*if (prevProps.visible === false && this.props.visible === true) {
      this.setState({
        ...EMPTY_STATE,
        selectedBills: this.props.value,
      });
    }*/

    if (prevProps.value !== this.props.value) {
      this.setState({
        ...EMPTY_STATE,
        selectedBills: this.props.value,
      });
    }
  };

  handleClose = () => {
    this.props.onHide();
  };

  handleRowDelete = (index) => {
    let newSelection = [...this.state.selectedBills];
    newSelection.splice(index, 1);
    this.setState({ selectedBills: [...newSelection] });
  };

  customerCellTemplate = (rowData) => {
    const { name } = rowData;

    return <div>{`${name ? name : ""}`}</div>;
  };

  mapStateToDTO = () => {
    const {
      selectedBills,
      inputBank,
      inputAmount,
      inputBillingDate,
      inputComment,
      inputFillLast,
    } = this.state;

    try {
      let transactionIds = [];
      selectedBills.forEach((bill) => {
        transactionIds.push(bill.transactionId);
      });
      return {
        transactionIds,
        amount: inputAmount,
        bank: inputBank,
        info: inputComment,
        paymentDate: dateToQueryString(inputBillingDate, true),
        fillLast: inputFillLast,
      };
    } catch (mapException) {
      logger.warn("Exception on mapStateToDTO", mapException);
      return null;
    }
  };

  validateInputs = () => {
    return new Promise((resolve, reject) => {
      let validationErrors = [];
      try {
        const { inputBank, inputAmount, inputBillingDate, selectedBills } =
          this.state;
        const { intl } = this.props;
        const {
          PAYMENTS_VALIDATION_ERROR_BANK,
          PAYMENTS_VALIDATION_ERROR_BILL,
          PAYMENTS_VALIDATION_ERROR_BILLING_DATE,
          PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT,
          ERROR,
        } = MESSAGE_KEYS;
        let validBill = selectedBills && selectedBills.length > 0;
        if (!validBill) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: PAYMENTS_VALIDATION_ERROR_BILL }),
          });
        }
        let validAmount = !isNaN(inputAmount);
        if (!validAmount) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: PAYMENTS_VALIDATION_ERROR_INVALID_AMOUNT,
            }),
          });
        }
        let validBank = typeof inputBank === "string" && inputBank.length > 0;
        if (!validBank) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({ id: PAYMENTS_VALIDATION_ERROR_BANK }),
          });
        }
        let validBillingDate = !isNaN(inputBillingDate.getTime());
        logger.info(
          "VALID DATE?",
          inputBillingDate,
          inputBillingDate.getTime()
        );
        if (!validBillingDate) {
          validationErrors.push({
            severity: ERROR,
            summary: intl.formatMessage({
              id: PAYMENTS_VALIDATION_ERROR_BILLING_DATE,
            }),
          });
        }
        this.setState({
          validAmount,
          validBank,
          validBill,
          validBillingDate,
        });
        if (validationErrors.length === 0) {
          resolve(this.mapStateToDTO());
        } else {
          reject(validationErrors);
        }
      } catch (validationException) {
        logger.warn("Exception on validateInputs", validationException);
        reject({
          summary: this.props.intl.formatMessage({ id: MESSAGE_KEYS.ERROR }),
        });
      }
    });
  };

  handleSaveClick = () => {
    const { intl, handleParentUpdate } = this.props;
    this.setState({ updatePending: true });
    this.validateInputs().then(
      (newData) => {
        sendQuery(QUERIES.CREATE_MULTI_PAYMENT, "post", newData).then(
          (response) => {
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: intl.formatMessage({
                id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
              }),
            });
            this.setState({ updatePending: false });
            handleParentUpdate();
            this.handleClose();
          },
          (updateError) => {
            logger.error(updateError);
            this.toast.show({
              severity: MESSAGE_SEVERITY.ERROR,
              summary:
                typeof updateError === "string"
                  ? updateError
                  : this.props.intl.formatMessage({
                      id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                    }),
            });
            this.setState({ updatePending: false });
          }
        );
      },
      (error) => {
        logger.error(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary:
            typeof error === "string"
              ? error
              : this.props.intl.formatMessage({
                  id: MESSAGE_KEYS.ERROR_DATA_SAVE,
                }),
        });
        this.setState({ updatePending: false });
      }
    );
  };

  /** Custom table cell renderer that returns a remove-button. */
  deleteButtonTemplate = (rowData, index) => {
    const { intl } = this.props;
    const { name } = rowData;
    return (
      <Button
        type="button"
        icon="pi pi-minus-circle"
        className="p-button-danger"
        onClick={() => {
          changePendingConfirm(
            index.rowIndex,
            this.handleRowDelete,
            intl,
            name
          );
        }}
        disabled={this.state.updatePending}
      />
    );
  };
  /**
   * Generates the table's footer cell containing the total remaining amount.
   * Only active bills will be used to calculate the total remaining amount.
   */
  priceFooterTemplate = () => {
    const { selectedBills } = this.state;
    let total = 0;
    if (selectedBills && selectedBills.length > 0) {
      selectedBills.forEach((bill) => {
        const { totalPrice, totalPaidReal, active } = bill;
        total += active === true ? (totalPrice ?? 0) - (totalPaidReal ?? 0) : 0;
      });
    }
    return <div>{numberToPriceString(total)}</div>;
  };

  renderPaymentInputs = () => {
    const { intl } = this.props;
    const {
      PAYMENTS_DATE_LABEL,
      PAYMENTS_AMOUNT_LABEL,
      PAYMENTS_BANK_LABEL,
      BILLS_COMMENT_INTERNAL_LABEL,
      BILLS_MULTI_BILLING_FILL_LAST,
    } = MESSAGE_KEYS;
    const {
      inputBillingDate,
      inputAmount,
      inputBank,
      inputComment,
      inputFillLast,

      validAmount,
      //validBank,
      validBillingDate,

      updatePending,
    } = this.state;
    return (
      <div
        className={
          isDesktop ? "p-fluid formgrid grid mt-2 flex align-items-end" : ""
        }
      >
        <div className={isDesktop ? "p-field col-4" : "payment_filter_row"}>
          {/*      <FloatingTextInput
            id={PAYMENTS_BANK_LABEL}
            value={inputBank}
            onChange={(event) =>
              this.setState({ inputBank: event.target.value })
            }
            label={intl.formatMessage({ id: PAYMENTS_BANK_LABEL })}
            valid={validBank}
            disabled={updatePending}
          /> */}
          <span className="p-float-label" style={{ width: "100%" }}>
            <Dropdown
              id={PAYMENTS_BANK_LABEL}
              options={availableOptions}
              value={inputBank.toLowerCase()}
              optionLabel="name"
              onChange={(event) => this.setState({ inputBank: event.value })}
              disabled={updatePending}
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: PAYMENTS_BANK_LABEL })}
            </label>
          </span>
        </div>

        <div className={isDesktop ? "p-field col-4" : "payment_filter_row"}>
          <SplitDateTimeInput
            value={inputBillingDate}
            onChange={(e) => {
              this.setState({
                inputBillingDate: valiDate(e) ? new Date(e) : null,
              });
            }}
            label={intl.formatMessage({ id: PAYMENTS_DATE_LABEL })}
            className={
              validBillingDate === null || validBillingDate ? "" : "p-invalid"
            }
            disabled={updatePending}
          />
        </div>

        <div className={isDesktop ? "p-field col-4" : "payment_filter_row"}>
          <span className="p-float-label">
            <InputNumber
              id={PAYMENTS_AMOUNT_LABEL}
              value={inputAmount}
              onValueChange={(e) => this.setState({ inputAmount: e.value })}
              suffix=" €"
              className={`${
                validAmount === true || validAmount === null ? "" : "p-invalid"
              }`}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              onFocus={(e) => e.target.select()}
              useGrouping={false}
              locale="en-US"
              disabled={updatePending}
            />
            <label htmlFor={PAYMENTS_AMOUNT_LABEL}>
              {intl.formatMessage({ id: PAYMENTS_AMOUNT_LABEL })}
            </label>
          </span>
        </div>

        <div className={isDesktop ? "p-field col-12" : "payment_filter_row"}>
          <span className="p-float-label" style={{ width: "100%" }}>
            <InputTextarea
              id="inputComment"
              value={inputComment}
              onChange={(e) => this.setState({ inputComment: e.target.value })}
              rows={5}
              cols={30}
              className="p-inputtext p-inputtextarea p-component"
              autoResize
              disabled={updatePending}
            />
            <label htmlFor="inputComment">
              {intl.formatMessage({ id: BILLS_COMMENT_INTERNAL_LABEL })}
            </label>
          </span>
        </div>

        <div
          className={`${
            isDesktop ? "p-field col-12" : "payment_filter_row"
          } flex align-items-baseline`}
        >
          <Checkbox
            inputId="cb_fill_last"
            onChange={(e) => {
              this.setState({ inputFillLast: e.checked });
            }}
            checked={inputFillLast}
            disabled={updatePending}
          ></Checkbox>
          <label htmlFor="cb_fill_last" className="p-checkbox-label ml-1">
            {intl.formatMessage({ id: BILLS_MULTI_BILLING_FILL_LAST })}
          </label>
        </div>
      </div>
    );
  };

  renderButtonRow = () => {
    const { intl } = this.props;
    const {
      DIALOG_CANCEL_BUTTON_LABEL,
      RESET_VALUES,
      BILLS_MULTI_BILLING_CREATE_LABEL,
    } = MESSAGE_KEYS;
    const { updatePending } = this.state;

    return (
      <div className="multi_dialog_button_row mb-2">
        <Button
          label={intl.formatMessage({ id: BILLS_MULTI_BILLING_CREATE_LABEL })}
          icon={updatePending ? "pi pi-spin pi-spinner" : "pi pi-check"}
          onClick={this.handleSaveClick}
          disabled={updatePending}
        />
        <Button
          label={intl.formatMessage({ id: RESET_VALUES })}
          className="p-button-warning"
          icon="pi pi-refresh"
          onClick={() => {
            this.setState({
              ...EMPTY_STATE,
              selectedBills: this.props.value,
            });
          }}
          disabled={updatePending}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          className="p-button-error"
          icon="pi pi-times"
          onClick={this.handleClose}
          disabled={updatePending}
        />
      </div>
    );
  };

  renderBillList = () => {
    const { selectedBills } = this.state;

    return (
      <DataTable
        value={selectedBills}
        className="p-datatable-sm"
        scrollable
        scrollHeight="400px"
      >
        <Column field="transactionId" style={{ width: "100px" }} />
        <Column
          body={(rowData) => {
            return dateCellTemplate(rowData.orderDate);
          }}
          style={{ width: "100px" }}
        />
        <Column field="health_insurance_number" header="" />
        <Column
          body={(rowData) => this.customerCellTemplate(rowData)}
          footer={
            <div className="text-right">
              {this.props.intl.formatMessage({
                id: MESSAGE_KEYS.BILLS_MULTI_FOOTER_LABEL,
              })}
            </div>
          }
        />
        <Column
          style={{ textAlign: "right" }}
          body={(rowData) => {
            return priceCellTemplate(
              (rowData.totalPrice ? rowData.totalPrice : 0) -
                (rowData.totalPaidReal ? rowData.totalPaidReal : 0)
            );
          }}
          footer={this.priceFooterTemplate}
        />
        <Column body={this.deleteButtonTemplate} style={{ width: "62px" }} />
      </DataTable>
    );
  };

  render = () => {
    const { BILLS_MULTI_BILLING_LABEL } = MESSAGE_KEYS;
    const { intl, visible } = this.props;
    return (
      <Dialog
        header={intl.formatMessage({ id: BILLS_MULTI_BILLING_LABEL })}
        visible={visible}
        onHide={this.handleClose}
        style={{ maxWidth: "800px" }}
        footer={this.renderButtonRow}
      >
        <Toast ref={(el) => (this.toast = el)} />
        {this.renderBillList()}
        {this.renderPaymentInputs()}
      </Dialog>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authentication.currentUser,
  };
};

export default connect(mapStateToProps, {})(injectIntl(MultiPaymentsDialog));
