/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
// Custom components
import {
  FloatingTextInput,
  TranslatedCB,
  AddressSelector,
} from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { ADDRESS_TYPES, ADDRESS_TYPE_IDS } from "assets/staticData/combodata";
// Styling
import "./Style.scss";
import { initLogger } from "common/Helpers";

const logger = initLogger("add_address_dialog");

const EMPTY_STATE = {
  inputPreset: null,
  inputLine1: "",
  inputLine2: "",
  inputCity: "",
  inputZip: "",
  inputAddressType: ADDRESS_TYPES[3],
  inputRetrospec: false,

  duplicateBill: false,
  duplicateBase: false,

  validLine1: null,
  validZip: null,
  validCity: null,
  validName: null,
  validType: null,
};

class AddAddressDialog extends React.Component {
  state = { ...EMPTY_STATE };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { visible, value } = this.props;
    if (prevProps.visible !== visible && visible === true) {
      this.initInputs(value);
    }
  };

  initInputs = (value) => {
    const { defaultName, basicSet } = this.props;
    // Set default selection to basic if no basic address is set, sets it to additional else.
    let inputAddressType;
    if (basicSet) {
      inputAddressType = ADDRESS_TYPES[3];
    } else {
      inputAddressType = ADDRESS_TYPES[1];
    }

    if (value?.addressId) {
      const {
        line1,
        line2,
        countryProvince,
        name,
        addressTypeId,
        zipCode,
        synchAddress,
      } = value;
      let inputName;
      if (name || defaultName) {
        inputName = name ? name : defaultName;
      } else {
        inputName = "";
      }
      let inputPreset = value;
      let inputAddressType = ADDRESS_TYPES[0];
      if (addressTypeId && addressTypeId >= 0) {
        let addressIndex = ADDRESS_TYPES.findIndex((type) => {
          return type.addressTypeId === addressTypeId;
        });
        if (addressIndex >= 0) {
          inputAddressType = ADDRESS_TYPES[addressIndex];
        }
      }
      this.setState({
        ...EMPTY_STATE,
        inputLine1: line1 ? line1 : "",
        inputLine2: line2 ? line2 : "",
        inputCity: countryProvince ? countryProvince : "",
        inputZip: zipCode ? zipCode : "",
        inputAddressType,
        inputName,
        inputPreset,
        inputRetrospec: synchAddress ? true : false,
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
        inputName: defaultName,
        inputAddressType,
      });
    }
  };

  /** Check if required inputs have values & add new address if true. Display error messages if false. */
  validateInput = () => {
    const { handleAdd } = this.props;
    const {
      inputLine1,
      inputLine2,
      inputZip,
      inputCity,
      inputName,
      inputAddressType,
      inputPreset,
      inputRetrospec,

      duplicateBill,
      duplicateBase,
    } = this.state;
    let validLine1 = inputLine1.length > 0;
    let validZip = inputZip.length > 0;
    let validCity = inputCity.length > 0;
    let validName = inputName.length > 0;
    let validType =
      inputAddressType?.addressTypeId !== null &&
      inputAddressType?.addressTypeId !== undefined;

    this.setState({
      validLine1,
      validZip,
      validCity,
      validName,
      validType,
    });

    // Using state variables for this check as hooks are not updated instantly.
    if (validLine1 && validZip && validCity && validName && validType) {
      handleAdd({
        inputLine1,
        inputLine2,
        inputCity,
        inputZip,
        inputAddressType,
        inputName,
        inputRetrospec,
        preset: inputPreset?.addressId ? inputPreset.addressId : null,
        duplicateBill,
        duplicateBase,
      });
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };
  /**
   * Is called when selecting an address type.
   * If base- or invoice-type are selected, checks if the respective type is already set.
   *
   * @param {Object} selection
   * @param {Number} selection.addressTypeId
   */
  handleAddressTypeChange = (selection) => {
    const { basicSet, billSet } = this.props;
    let duplicateBill,
      duplicateBase = false;
    if (selection?.addressTypeId) {
      const { addressTypeId } = selection;
      duplicateBase = basicSet && addressTypeId === ADDRESS_TYPE_IDS.BASE;
      duplicateBill = billSet && addressTypeId === ADDRESS_TYPE_IDS.INVOICE;
    }

    this.setState({
      inputAddressType: selection,
      duplicateBase,
      duplicateBill,
    });
  };

  renderWarning = () => {
    const { basicSet, billSet, intl } = this.props;
    const { inputAddressType } = this.state;
    const { CUSTOMERS_ADDRESS_DUPLICATE_WARNING, ERROR_RENDER } = MESSAGE_KEYS;
    let content = <></>;
    let duplicateBill,
      duplicateBase = false;
    try {
      if (
        (basicSet || billSet) &&
        inputAddressType &&
        inputAddressType.addressTypeId
      ) {
        const { addressTypeId } = inputAddressType;
        duplicateBase = basicSet && addressTypeId === ADDRESS_TYPE_IDS.BASE;
        duplicateBill = billSet && addressTypeId === ADDRESS_TYPE_IDS.INVOICE;
        if (duplicateBase || duplicateBill) {
          content = (
            <div className="flex align-items-center mb-2">
              <i
                className="pi pi-exclamation-triangle mr-2"
                style={{ fontSize: "2em" }}
              />
              {intl.formatMessage(
                { id: CUSTOMERS_ADDRESS_DUPLICATE_WARNING },
                {
                  type: intl.formatMessage({ id: inputAddressType.messageKey }),
                }
              )}
            </div>
          );
        }
        return content;
      }
    } catch (renderException) {
      logger.error(renderException);
      return <div> {intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderFooter = () => {
    const { onHide, intl, value } = this.props;
    const {
      CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,
      DIALOG_CANCEL_BUTTON_LABEL,
      ADDRESSES_SAVE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          label={intl.formatMessage({
            id: value?.addressId
              ? ADDRESSES_SAVE_BUTTON_LABEL
              : CUSTOMERS_ADDRESS_CREATE_BUTTON_LABEL,
          })}
          onClick={this.validateInput}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
        />
      </div>
    );
  };

  render = () => {
    const { onHide, visible, intl } = this.props;
    const {
      CUSTOMERS_ADD_ADDRESS_TITLE_LABEL,
      CUSTOMERS_ADDRESS_TYPE_LABEL,
      CUSTOMERS_ADDRESS_LINE_1_LABEL,
      CUSTOMERS_ADDRESS_LINE_2_LABEL,
      CUSTOMERS_ADDRESS_CITY_LABEL,
      CUSTOMERS_ADDRESS_ZIP_LABEL,
      CUSTOMERS_FILTER_NAME,
      ADDRESSES_FILTER_TITLE_LABEL,
      CUSTOMERS_ADDRESS_RETRO_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputLine1,
      inputLine2,
      inputZip,
      inputCity,
      inputName,
      inputAddressType,
      inputPreset,
      inputRetrospec,

      validCity,
      validLine1,
      validName,
      validZip,
      validType,
    } = this.state;

    return (
      <Dialog
        header={intl.formatMessage({ id: CUSTOMERS_ADD_ADDRESS_TITLE_LABEL })}
        footer={this.renderFooter()}
        visible={visible}
        onHide={() => {
          this.setState({
            ...EMPTY_STATE,
          });
          onHide();
        }}
        style={{ maxWidth: "500px" }}
      >
        <div className="p-fluid formgrid grid">
          <div className={`p-field col-12 mt-4`}>
            <AddressSelector
              value={inputPreset}
              onChange={this.initInputs}
              placeholder={intl.formatMessage({
                id: ADDRESSES_FILTER_TITLE_LABEL,
              })}
            />
          </div>

          <div className={`p-field col-12`}>
            <TranslatedCB
              value={inputAddressType}
              options={ADDRESS_TYPES}
              onChange={this.handleAddressTypeChange}
              placeholder={intl.formatMessage({
                id: CUSTOMERS_ADDRESS_TYPE_LABEL,
              })}
              valid={validType}
            />
          </div>

          <div className="p-field col-12 dialog_row">
            <FloatingTextInput
              id={CUSTOMERS_FILTER_NAME}
              value={inputName}
              onChange={(event) =>
                this.setState({ inputName: event.target.value })
              }
              label={intl.formatMessage({ id: CUSTOMERS_FILTER_NAME })}
              valid={validName}
            />
          </div>

          <div className={`p-field col-12 dialog_row`}>
            <FloatingTextInput
              id={CUSTOMERS_ADDRESS_LINE_1_LABEL}
              value={inputLine1}
              onChange={(event) =>
                this.setState({ inputLine1: event.target.value })
              }
              label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_1_LABEL })}
              valid={validLine1}
            />
          </div>

          <div className={`p-field col-12 dialog_row`}>
            <FloatingTextInput
              id={CUSTOMERS_ADDRESS_LINE_2_LABEL}
              value={inputLine2}
              onChange={(event) =>
                this.setState({ inputLine2: event.target.value })
              }
              label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_LINE_2_LABEL })}
            />
          </div>

          <div className={`p-field col-6 dialog_row`}>
            <FloatingTextInput
              id={CUSTOMERS_ADDRESS_ZIP_LABEL}
              value={inputZip}
              onChange={(event) =>
                this.setState({ inputZip: event.target.value })
              }
              label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_ZIP_LABEL })}
              valid={validZip}
            />
          </div>
          <div className={`p-field col-6 dialog_row`}>
            <FloatingTextInput
              id={CUSTOMERS_ADDRESS_CITY_LABEL}
              value={inputCity}
              onChange={(event) =>
                this.setState({ inputCity: event.target.value })
              }
              label={intl.formatMessage({ id: CUSTOMERS_ADDRESS_CITY_LABEL })}
              valid={validCity}
            />
          </div>

          <div className={`p-field col-12 dialog_row`}>
            <div className="flex align-items-center">
              <Checkbox
                inputId={CUSTOMERS_ADDRESS_RETRO_LABEL}
                checked={inputRetrospec}
                onChange={(e) => this.setState({ inputRetrospec: e.checked })}
              />
              <span className="ml-2">
                {intl.formatMessage({ id: CUSTOMERS_ADDRESS_RETRO_LABEL })}
              </span>
            </div>
          </div>
        </div>
        {this.renderWarning()}
      </Dialog>
    );
  };
}

export default injectIntl(AddAddressDialog);
