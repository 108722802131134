import { STATION_NAMES, MESSAGE_KEYS, LOCALES } from "./enums";

const {
  TRANSPORT_TYPE_AMBULANCE,
  TRANSPORT_TYPE_CADDY,
  TRANSPORT_TYPE_NONE,
  TRANSPORT_TYPE_TAXI,
  TRANSPORT_TYPE_EMPTY,
  TRANSPORT_TYPE_BUS,
  TRANSPORT_TYPE_VSL,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_NONE,
  GENDER_UNDEFINED,
  TITLES_CHILD,
  TITLES_DOCTOR,
  TITLES_MASTER,
  TITLES_MISS,
  TITLES_MISSES,
  TITLES_MISTER,
  TITLES_NONE,
  TITLES_SENIOR,
  ADDRESS_TYPE_ADDITIONAL,
  ADDRESS_TYPE_BASE,
  ADDRESS_TYPE_INVOICE,
  ADDRESS_TYPE_NONE,
  BILL_STATUS_CLOSED,
  BILL_STATUS_DAS,
  BILL_STATUS_NEW,
  BILL_STATUS_REMINDER_1,
  BILL_STATUS_REMINDER_2,
  BILL_STATUS_SENT,
  BILL_STATUS_PARTIALLY_PAID,
  BILL_STATUS_OVERPAID,
  BILL_STATUS_DELIVERY_NOTE,
  ROLE_ADMIN,
  ROLE_INVOICE,
  ROLE_PLANNING,
  ROLE_USER,
  ROLE_DRIVER,
  ROLE_FIDU,
  ROLE_AUTHOR,
  APPOINTMENT_STATUS_CANCELLED,
  APPOINTMENT_STATUS_EXECUTED,
  APPOINTMENT_STATUS_INVOICED,
  APPOINTMENT_STATUS_NONE,
  APPOINTMENT_STATUS_OUTWARD,
  APPOINTMENT_STATUS_RETURN,
  APPOINTMENT_STATUS_WAITING,
  APPOINTMENT_STATUS_BILLED,
  APPOINTMENT_STATUS_PENDING,
} = MESSAGE_KEYS;

/**
 * Combobox-Data for genders.
 */
export const GENDERS = [
  { sexId: null, messageKey: GENDER_NONE },
  { sexId: 1, messageKey: GENDER_MALE },
  { sexId: 0, messageKey: GENDER_FEMALE },
  { sexId: 2, messageKey: GENDER_UNDEFINED },
];

export const TITLES = [
  { titleId: null, shn: "", messageKey: TITLES_NONE },
  { titleId: 1, shn: "Mr.", messageKey: TITLES_MISTER },
  { titleId: 2, shn: "Mme.", messageKey: TITLES_MISS },
  { titleId: 3, shn: "Mlle.", messageKey: TITLES_MISSES },
  { titleId: 4, shn: "Dr.", messageKey: TITLES_DOCTOR },
  { titleId: 5, shn: "M. & Mme.", messageKey: TITLES_MASTER },
  { titleId: 9, shn: "Sr.", messageKey: TITLES_SENIOR },
  { titleId: 10, shn: "Enfant", messageKey: TITLES_CHILD },
];

export const VEHICLES = [
  { carTypeID: null, name: "not set" },
  { carTypeID: 1, name: "Some car" },
];

export const ADDRESS_TYPE_IDS = {
  NONE: null,
  BASE: 1,
  INVOICE: 2,
  ADDITIONAL: 3,
};

export const ADDRESS_TYPES = [
  {
    addressTypeId: ADDRESS_TYPE_IDS.NONE,
    name: "not set",
    messageKey: ADDRESS_TYPE_NONE,
    icon: "",
  },
  {
    addressTypeId: ADDRESS_TYPE_IDS.BASE,
    name: "Base address",
    messageKey: ADDRESS_TYPE_BASE,
    icon: "pi pi-star",
  },
  {
    addressTypeId: ADDRESS_TYPE_IDS.INVOICE,
    name: "Invoice Address",
    messageKey: ADDRESS_TYPE_INVOICE,
    icon: "pi pi-money-bill",
  },
  {
    addressTypeId: ADDRESS_TYPE_IDS.ADDITIONAL,
    name: "Additional Address",
    messageKey: ADDRESS_TYPE_ADDITIONAL,
    icon: "pi pi-clone",
  },
];

export const APPOINTMENT_STATUS = [
  {
    appointmentStateID: null,
    name: "not set",
    messageKey: APPOINTMENT_STATUS_NONE,
    backgroundColor: "lightgray",
  },
  {
    appointmentStateID: 1,
    name: "outward journey",
    messageKey: APPOINTMENT_STATUS_OUTWARD,
    backgroundColor: "darkgreen",
  },
  {
    appointmentStateID: 2,
    name: "return journey",
    messageKey: APPOINTMENT_STATUS_RETURN,
    backgroundColor: "darkred",
  },
  {
    appointmentStateID: 3,
    name: "waiting for action",
    messageKey: APPOINTMENT_STATUS_WAITING,
    backgroundColor: "dodgerblue",
  },
  {
    appointmentStateID: 4,
    name: "ausstehend",
    messageKey: APPOINTMENT_STATUS_PENDING,
    backgroundColor: "indigo",
  },
  {
    appointmentStateID: 5,
    name: "invoiced",
    messageKey: APPOINTMENT_STATUS_INVOICED,
    backgroundColor: "goldenrod",
  },
  {
    appointmentStateID: 6,
    name: "executed",
    messageKey: APPOINTMENT_STATUS_EXECUTED,
    backgroundColor: "firebrick",
  },
  {
    appointmentStateID: 7,
    name: "executed",
    messageKey: APPOINTMENT_STATUS_BILLED,
    backgroundColor: "",
  },
  {
    appointmentStateID: 8,
    name: "chancelled",
    messageKey: APPOINTMENT_STATUS_CANCELLED,
    backgroundColor: "darkgray",
  },
];

export const TRANSPORT_TYPES = [
  {
    transportTypeId: 0,
    name: "not set",
    luxembourg_count: null,
    messageKey: TRANSPORT_TYPE_NONE,
  },
  {
    transportTypeId: 3,
    name: "Ambulance",
    luxembourg_count: 4,
    messageKey: TRANSPORT_TYPE_AMBULANCE,
  },
  {
    transportTypeId: 2,
    name: "Caddy",
    luxembourg_count: 5,
    messageKey: TRANSPORT_TYPE_CADDY,
  },
  {
    transportTypeId: 1,
    name: "VSL", // Jeff requested this (06/12/2023)
    luxembourg_count: 2,
    messageKey: TRANSPORT_TYPE_TAXI,
  },
  {
    transportTypeId: 5,
    name: "Empty",
    luxembourg_count: null,
    messageKey: TRANSPORT_TYPE_EMPTY,
  },
  {
    transportTypeId: 6,
    name: "Bus",
    luxembourg_count: null,
    messageKey: TRANSPORT_TYPE_BUS,
  },
  {
    transportTypeId: 4,
    name: "VSL",
    luxembourg_count: null,
    messageKey: TRANSPORT_TYPE_VSL,
  },
];

export const MAIN_LOCATIONS = [
  { stationId: null, stationName: "not set" },
  { stationId: 1, stationName: STATION_NAMES.LUX },
  { stationId: 2, stationName: STATION_NAMES.ETB },
  { stationId: 3, stationName: STATION_NAMES.WLZ },
];

export const BILL_STATUS = [
  {
    billStatusId: 1,
    messageKey: BILL_STATUS_NEW,
    icon: "pi pi-plus-circle",
    color: "#000000",
  },
  {
    billStatusId: 2,
    messageKey: BILL_STATUS_SENT,
    icon: "pi pi-arrow-circle-right",
    color: "#9191ff",
  },
  {
    billStatusId: 3,
    messageKey: BILL_STATUS_PARTIALLY_PAID,
    icon: "pi pi-minus-circle",
    color: "#000000",
  },
  {
    billStatusId: 4,
    messageKey: BILL_STATUS_REMINDER_1,
    icon: "pi pi-clock",
    color: "#e6ae04",
  },
  {
    billStatusId: 5,
    messageKey: BILL_STATUS_REMINDER_2,
    icon: "pi pi-clock",
    color: "#e2700f",
  },
  {
    billStatusId: 6,
    messageKey: BILL_STATUS_DAS,
    icon: "pi pi-exclamation-circle",
    color: "#ff4949",
  },
  {
    billStatusId: 7,
    messageKey: BILL_STATUS_OVERPAID,
    icon: "pi pi-chevron-circle-up",
    color: "#e2700f",
  },
  {
    billStatusId: 9,
    messageKey: BILL_STATUS_CLOSED,
    icon: "pi pi-check-circle",
    color: "#2ddc00",
  },
  {
    billStatusId: 10,
    messageKey: BILL_STATUS_DELIVERY_NOTE,
    icon: "pi pi-copy",
    color: "#000000",
  },
];

export const OFFLINE_ROLE = { role_id: -1, messageKey: "offline" };
export const USER_ROLE = { role_id: 1, messageKey: ROLE_USER };
export const ADMIN_ROLE = { role_id: 4, messageKey: ROLE_ADMIN };
export const INVOICE_ROLE = { role_id: 5, messageKey: ROLE_INVOICE };
export const PLANNING_ROLE = { role_id: 6, messageKey: ROLE_PLANNING };
export const DRIVER_ROLE = { role_id: 7, messageKey: ROLE_DRIVER };
export const FIDUS_ROLE = { role_id: 10, messageKey: ROLE_FIDU };
export const AUTHOR_ROLE = { role_id: 11, messageKey: ROLE_AUTHOR };

export const ROLES = [
  ADMIN_ROLE,
  USER_ROLE,
  INVOICE_ROLE,
  PLANNING_ROLE,
  DRIVER_ROLE,
  FIDUS_ROLE,
  AUTHOR_ROLE,
];

export const OFFLINE_USER = {
  personId: -1,
  email: "offline",
  username: "OFFLINE",
  roles: [OFFLINE_ROLE],
  currentLocale: LOCALES.GERMAN,
};
