/**
 * The scheduler view displays an editable appointment calendar using the FullCalendar component.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle, setAppointmentTypes, setCars } from "actions";
import {
  setChangePending,
  setAppointmentSession,
} from "actions/sessionActions";
// PrimeReact components
import { Toast } from "primereact/toast";
// Custom components
import {
  AppointmentCreateDialog,
  OnCallToolbar,
  AppointmentScheduler,
} from "./components";
import { CalendarToolbar } from "components/common";
// Body scroll disabling
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
// Websocket connections
import { Client } from "@stomp/stompjs";
// Localization
import { injectIntl } from "react-intl";
// Helper classes
import {
  sendQuery,
  dateToParameter,
  initLogger,
  dateToISOString,
  stringToOffsetDate,
  utcToDate,
  equalDates,
  dateToString,
  sortObjectArray,
  setShiftOrder,
  dateToSessionIdentifier,
  fetchSurrounding,
  isWithinRange,
} from "common/Helpers";
// Static data
import {
  QUERIES,
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  STATION_NAMES,
  LOCALES,
} from "assets/staticData/enums";
import { MAIN_LOCATIONS, TRANSPORT_TYPES } from "assets/staticData/combodata";
// Styling
import "./Style.scss";
import { AppointmentHistoryDialog } from "./components/index";
// Logging
const logger = initLogger("scheduler_view");
const APPOINTMENT_DIALOG_ID = "dlg_app_create";
// Live update
let websocket = null;
// Static values
const DEFAULT_ROW_HEIGHT = 46;
const REARRANGE_DELAY = 0;
const PLUGIN_LIST = "listDay";
const PLUGIN_DAY = "resourceTimeGridDay";

const DEFAULT_MIN_SLOT = "06:00:00";
const DEFAULT_MAX_SLOT = "19:00:00";

class SchedulerView extends React.Component {
  state = {
    currentEvents: [],
    resources: null,
    eventFetchPending: false,
    eventFetchError: null,
    tooltipFetchPending: false,
    tooltipFetchError: null,
    showEditDialog: false,
    selection: null,
    displayedDate: new Date(),
    appointmentFetchPending: false,
    hasHeader: false,

    drivers: [],
    cars: [],
    status: [],

    slotMinTime: DEFAULT_MIN_SLOT,
    slotMaxTime: DEFAULT_MAX_SLOT,

    pendingEvents: [],

    showHistoryModal: false,
    historyModalTitle: "",
    historyModalContent: "",
  };

  calendarRef = React.createRef();

  dialogRef = React.createRef();

  /**
   * Generates resources, the table's location header, fetches today's appointments and generates today's background events.
   * Create ref to appointment create dialog for body scroll disable.
   * Initialize web socket for live update.
   * Clean up redux.
   */
  componentDidMount = () => {
    const {
      appointmentSession,
      setChangePending,
      setTopbarTitle,
      setAppointmentSession,
    } = this.props;
    let reduxData = { ...appointmentSession };
    setTopbarTitle(
      this.props.intl.formatMessage(
        { id: MESSAGE_KEYS.APPOINTMENTS_TITLE_LABEL },
        {
          date: dateToString(new Date()),
        }
      )
    );
    setChangePending(false);
    this.generateResources();
    this.fetchComboData();
    let today = new Date();
    this.fetchAppointments(today);
    this.connectWebsocket();

    let reduxRange = fetchSurrounding(dateToSessionIdentifier(today));
    const _reduxData = Object.keys(reduxData)
      .filter((key) => reduxRange.includes(key))
      .reduce((obj, key) => {
        obj[key] = reduxData[key];
        return obj;
      }, {});

    setAppointmentSession(_reduxData);
  };
  /**
   * Checks if the table header has been generated.
   * If not, will attempt to generate the table header.
   */
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.hasHeader) {
      this.generateLocationRow();
      this.generateRightTimeSlots();
    }
    // If dialog was toggled, disable scrolling if visible, enable else.
    if (this.state.showEditDialog !== prevState.showEditDialog) {
      if (this.state.showEditDialog === true) {
        disableBodyScroll(this.dialogRef.current);
      } else {
        enableBodyScroll(this.dialogRef.current);
      }
    }
    if (this.props.menuActive !== prevProps.menuActive) {
      setTimeout(() => this.setState({ ...this.state }), REARRANGE_DELAY);
    }
  };
  /**
   * Clear scroll locks & attempt to close websocket connection.
   */
  componentWillUnmount = () => {
    clearAllBodyScrollLocks();
    this.disconnectWebsocket();
  };
  /**
   * Set up websocket connection.
   */
  connectWebsocket = () => {
    let brokerURL;

    switch (process.env.REACT_APP_SETTING) {
      case "prod":
      case "start":
      case "start-prod":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_PROD;
        break;
      case "demo":
      case "start-demo":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEMO;
        break;
      case "kai":
      case "start-local-kai":
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_KAI;
        break;
      default:
        brokerURL = process.env.REACT_APP_WEBSOCKET_URL_WS_DEV;
    }

    try {
      websocket = new Client();
      websocket.configure({
        brokerURL,
        onConnect: () => {
          logger.info("Connected to", brokerURL);
          websocket.subscribe(
            process.env.REACT_APP_WEBSOCKET_SUBSCRIBE,
            (msg) => {
              try {
                const { displayedDate } = this.state;
                let newAppointment = JSON.parse(msg.body);
                if (typeof newAppointment === "number") {
                  const newCurrentEvents = [...this.state.currentEvents].filter(
                    (currentEvent) => currentEvent.id !== newAppointment
                  );
                  this.setState({
                    currentEvents: [...newCurrentEvents],
                    eventFetchPending: false,
                  });
                  this.removeFromRedux(newAppointment);
                } else {
                  let cmpDate = new Date(displayedDate.getTime());
                  cmpDate.setHours(0, 0, 0, 0);
                  // Check if received appointment is on displayed date.
                  let cmpStartTime = new Date(newAppointment.starttime);
                  cmpStartTime.setHours(0, 0, 0, 0);
                  if (equalDates(cmpStartTime, cmpDate)) {
                    // Received event is on displayed date, add new data to event array.
                    this.responseToEvents([newAppointment], false).then(
                      (mappedData) => {
                        const { events, slotMinTime, slotMaxTime } = mappedData;
                        // Check if the received appointment is already displayed.
                        let newEvent = events[0];
                        let newCurrentEvents = [...this.state.currentEvents];
                        let searchIndex = newCurrentEvents.findIndex((e) => {
                          return e.id === newEvent.id;
                        });
                        if (searchIndex >= 0) {
                          newCurrentEvents[searchIndex] = newEvent;
                        } else {
                          newCurrentEvents.push(newEvent);
                        }
                        // Remove pending events
                        const pendingEvents = [
                          ...this.state.pendingEvents.filter(
                            (searchId) => searchId !== newEvent.id
                          ),
                        ];

                        this.setState(
                          {
                            currentEvents: [...newCurrentEvents],
                            eventFetchPending: false,
                            slotMinTime,
                            slotMaxTime,
                            pendingEvents,
                          },
                          () => {
                            setTimeout(() => {
                              this.adjustRowHeight().then(
                                this.updateEventHeading
                              );
                            }, REARRANGE_DELAY);
                          }
                        );
                      },
                      (error) => {
                        logger.warn("LIVE UPDATE FAILED", error);
                      }
                    );
                  }
                  // Update redux state.
                  this.updateRedux(cmpStartTime, newAppointment);
                }
                logger.info("Greetings", JSON.parse(msg.body));
                this.adjustRowHeight().then(this.updateEventHeading);
              } catch (wsException) {
                logger.warn(wsException);
              }
            }
          );
        },
      });

      websocket.activate();
    } catch (connectException) {
      logger.warn(connectException, brokerURL);
    }
  };

  removeFromRedux = (appointmentId) => {
    logger.warn("Removing from redux", appointmentId);
    const { appointmentSession, setAppointmentSession } = this.props;
    const newAppointmentSession = { ...appointmentSession };
    for (const [key] of Object.entries(newAppointmentSession)) {
      newAppointmentSession[key] = newAppointmentSession[key].filter(
        (entry) => entry.appointmentId !== appointmentId
      );
    }
    setAppointmentSession(newAppointmentSession);
  };

  updateRedux = (cmpStartTime, newAppointment) => {
    // Update redux state.
    const { appointmentSession, setAppointmentSession } = this.props;
    let identifier = dateToSessionIdentifier(cmpStartTime);
    let reduxData = { ...appointmentSession };
    // Check if redux data for the respective date exists & update state accordingly.
    if (reduxData && reduxData[identifier]) {
      // Date has entries in redux, check if the received appointment needs to be added or updated.
      let searchIndex = reduxData[identifier].findIndex((searchEntry) => {
        return searchEntry.appointmentId === newAppointment.appointmentId;
      });
      if (searchIndex >= 0) {
        reduxData[identifier][searchIndex] = {
          ...newAppointment,
        };
      } else {
        reduxData[identifier].push({ ...newAppointment });
      }
    } else {
      // No data for the received data, create entry.
      reduxData[identifier] = [{ ...newAppointment }];
    }
    setAppointmentSession({ ...reduxData });
  };

  /**
   * Attempt to close current websocket connection.
   */
  disconnectWebsocket = () => {
    try {
      if (websocket) {
        websocket.deactivate();
      }
    } catch (discoException) {
      logger.warn(discoException);
    }
  };
  /**
   * Is called after updating/creating an event.
   * Sends the id of the saved event to the websocket to inform other connected clients and start the live update.
   *
   * @param {Number} id The ID of the saved event received by the backend.
   */
  callWebsocket = (id, callDelete = false) => {
    if (id) {
      try {
        const destination = callDelete
          ? process.env.REACT_APP_WEBSOCKET_UNPUBLISH
          : process.env.REACT_APP_WEBSOCKET_PUBLISH;
        logger.info("SENDING MESSAGE", destination);
        websocket.publish({
          destination,
          body: id,
        });
      } catch (sendException) {
        logger.warn(sendException);
      }
    }
  };

  /**
   * Returns the name of background color for the column of the respective vehicle type. Returns the string white by default.
   *
   * @param {String} typeName - The name of the respective vehicle type.
   * @returns {String} - The background color assigned to the respective vehicle type.
   */
  selectColor = (typeName) => {
    switch (typeName) {
      case "Taxi":
        return "rgb(255, 213, 0, 0.2)";
      case "Ambulance":
        return "rgb(41, 84, 255, 0.2)";
      case "Caddy":
        return "rgb(247, 22, 22, 0.2)";
      default:
        return "rgb(255,255,255,0.2)";
    }
  };

  adjustRowHeight = () => {
    return new Promise((resolve) => {
      const slots = {};

      const rowModifiers = {};
      // Find all events via custom schedule_timeslot CSS class.
      document.querySelectorAll(".schedule_timeslot").forEach((event) => {
        // Store the row-value as key and all column ids into an array as value.
        if (slots[event.slot]) {
          slots[event.slot].push(event.attributes.role.value);
        } else {
          slots[event.slot] = [event.attributes.role.value];
        }
      });
      // Fetch all time slot rows via FullCalendar CSS class.
      document
        .querySelectorAll(
          ".fc-timegrid-slots > table > tbody > tr > .fc-timegrid-slot.fc-timegrid-slot-lane"
        )
        .forEach((row) => {
          const slot = row.attributes["data-time"].value;

          let modifier = 1;
          // If available, get the largest numbers of events in a cell within the current row. Set modifier to 1 to reset row height else.
          if (slots[slot]?.length > 1) {
            const uniqueSlots = [...new Set(slots[slot])];
            const modifiers = [];
            uniqueSlots.forEach((uslot) => {
              modifiers.push(slots[slot].filter((mod) => mod === uslot).length);
            });
            modifier = Math.max.apply(Math, modifiers);
          }
          row.style.height = `${
            DEFAULT_ROW_HEIGHT * (modifier ? modifier : 1)
          }px`;
          row.parentElement.style.height = `${
            DEFAULT_ROW_HEIGHT * (modifier ? modifier : 1)
          }px`;

          rowModifiers[row.dataset.time] = { top: row.offsetTop };
        });

      resolve(rowModifiers);
    });
  };

  updateEventHeading = (rowModifiers) => {
    let secondViolins = [];
    document
      .querySelectorAll(".fc-timegrid-event-harness-inset")
      .forEach((row) => {
        // Event order within a cell can be determined via z-index; any event with z-index larger than 1 shares a cell with another event.
        if (row.style["z-index"] === 1) {
          // Set left & right of first event within cell to 0% to guarantee event width  of 100%.
          row.style.left = row.style.right = "0%";
        }
        secondViolins.push(row);
        // Set max height to 46px for each event
        row.style["max-height"] = `${DEFAULT_ROW_HEIGHT}px`;
      });
    secondViolins = [...new Set(secondViolins)];
    // Place events after first one vertically.

    secondViolins.forEach((violin) => {
      // Set position of additional events below the first one. Use top value from first violin as starting point.
      try {
        const rowData =
          rowModifiers[violin.childNodes[0].childNodes[0].childNodes[0].slot];
        const place = parseInt(violin.style["z-index"]);

        if (rowData?.top) {
          const newHeight = rowData.top + DEFAULT_ROW_HEIGHT * (place - 1);
          violin.style.left = violin.style.right = "0%";
          violin.style.top = `${newHeight}px`;
        }
      } catch (exception) {
        logger.warn(exception);
      }
    });
  };

  generateRightTimeSlots = (setColSpans = true) => {
    let timeSlotClasses = [".fc-timegrid-axis", ".fc-timegrid-slot-label"];
    timeSlotClasses.forEach((timeSlotElement) => {
      // Duplicate Time Axis
      let axis = document.querySelectorAll(timeSlotElement);
      if (axis) {
        for (let i = 0; i < axis.length; i++) {
          let element = axis[i];
          let p = element.parentElement;
          let n = element.cloneNode(true);
          p.appendChild(n);
        }
      }
    });
    if (setColSpans) {
      // Get colspans
      let luxHeaderColspan = 0;
      let ettHeaderColspan = 0;
      let wlzHeaderColspan = 1;
      TRANSPORT_TYPES.forEach((type) => {
        if (type.luxembourg_count) {
          luxHeaderColspan += type.luxembourg_count;
          ettHeaderColspan++;
        }
      });

      let colSpanners = [luxHeaderColspan, ettHeaderColspan, wlzHeaderColspan];

      // Update formatting
      let colGroups = document.getElementsByTagName("colgroup");
      let c = 0;
      for (let group of colGroups) {
        if (c === 1) {
          group.appendChild(document.createElement("col"));
        } else {
          colSpanners.forEach((spanner) => {
            let col = document.createElement("col");
            col.setAttribute("span", spanner);
            group.appendChild(col);
          });
        }

        let timeCol = document.createElement("col");
        timeCol.style.width = "45px";
        group.appendChild(timeCol);
        c++;
      }
    }
  };

  /**
   * Manipulates DOM structure to create grouped headers for the main locations.
   * This method is required as FullCalendar vertical view allows no nested resources.
   *
   */
  generateLocationRow = () => {
    try {
      let header =
        document.getElementsByClassName("fc-timegrid-axis")[0].parentNode
          .parentNode;
      let baseHeader =
        document.getElementsByClassName("fc-timegrid-axis")[0].parentNode;
      let row = document.createElement("tr");
      row.appendChild(document.createElement("th"));

      let luxHeader = document.createElement("th");
      let ettHeader = document.createElement("th");
      let wlzHeader = document.createElement("th");
      // Luxembourg has mutliple columns per vehicle.
      luxHeader.className = "fc-col-header-cell fc-resource";
      let luxHeaderColspan = 0;
      let ettHeaderColspan = 0;
      TRANSPORT_TYPES.forEach((type) => {
        if (type.luxembourg_count) {
          luxHeaderColspan += type.luxembourg_count;
          ettHeaderColspan++;
        }
        return;
      });
      luxHeader.colSpan = luxHeaderColspan;
      luxHeader.textContent = "Luxembourg";
      row.appendChild(luxHeader);
      // Ettelbrück has a single column per vehicle.
      ettHeader.className = "fc-col-header-cell fc-resource";

      ettHeader.colSpan = ettHeaderColspan;
      ettHeader.textContent = "Ettelbrück";
      row.appendChild(ettHeader);
      // Wiltz has only a single row.
      wlzHeader.className = "fc-col-header-cell fc-resource";
      wlzHeader.colSpan = 1;
      wlzHeader.textContent = "Wiltz";
      row.appendChild(wlzHeader);
      header.insertBefore(row, baseHeader);
      this.setState({ hasHeader: true });
    } catch (initException) {
      logger.warn(initException);
    }
  };

  /**
   * Generates full day background events to change the color of the respective columns.
   * This is apparently the only implented way for FullCalendar.
   *
   * @param {Date} date - The displayed date.
   * @returns {Promise<Array<Object>>} - A background event for each resorce with the respective background color.
   */
  generateBackgroundEvents = (date) => {
    return new Promise((resolve, reject) => {
      try {
        let backgroundEvents = [];
        let baseDate = dateToParameter(date)[0];
        this.state.resources.forEach((resource) => {
          const { id, backgroundColor } = resource;
          backgroundEvents.push({
            start: `${baseDate} 00:00:00`,
            end: `${baseDate} 23:59:00`,
            display: "background",
            resourceId: id,
            backgroundColor,
          });
          resolve(backgroundEvents);
        });
      } catch (generatorException) {
        logger.error(generatorException);
        reject([]);
      }
    });
  };

  /**
   * Generates the resources for FullCalendar and stores them in the state; a resource object represents a column in FullCalendar.
   * The following resources/columns will be generated:
   * - Luxembourg: 4 Taxis, 2 Caddies, 3 Ambulances
   * - Ettelbrück: 1 Taxi, 1 Caddy & 1 Ambulance
   * - Wiltz: Only 1 Wiltz resource.
   */
  generateResources = () => {
    let resources = [];
    if (MAIN_LOCATIONS.length > 0 && TRANSPORT_TYPES.length > 0) {
      const { LUX, ETB } = STATION_NAMES;
      MAIN_LOCATIONS.forEach((location) => {
        switch (location.stationName) {
          case ETB:
            TRANSPORT_TYPES.forEach((type) => {
              const { transportTypeId, name, luxembourg_count } = type;
              if (luxembourg_count) {
                resources.push({
                  id: `${location.stationId}-${transportTypeId}-0`,
                  title: `${name}`,
                  backgroundColor: this.selectColor(name),
                });
              }
            });
            break;
          case LUX:
            TRANSPORT_TYPES.forEach((type) => {
              const { transportTypeId, name, luxembourg_count } = type;
              if (transportTypeId) {
                for (let c = 0; c < luxembourg_count; c++) {
                  resources.push({
                    id: `${location.stationId}-${transportTypeId}-${c}`,
                    title: `${name}`,
                    backgroundColor: this.selectColor(name),
                  });
                }
              }
            });
            break;
          default:
            if (location.stationId) {
              const { stationId } = location;
              resources.push({
                id: `${stationId}`,
                title: " ",
                backgroundColor: this.selectColor(),
              });
            }
        }
      });
    }
    this.setState({ resources });
  };

  /**
   * Maps the data received from to server to an array of objects accepted by FullCalendar.
   * A FullCalendar event object stores start- & endtime, id und customer name in the base object, all other values are stored in the property extendedProps.
   * Set resetMinMax-flag to false to use current min/max values as base for new slot values to prevent the calendar from jumping up and down.
   *
   * @param {Promise<Array<Object>|Object>} response An array of FullCalendar-compliant objects on success, an exception object else.
   * @param {Boolean} resetMinMax Set flag to reset min/max slot time comparison values to default (min: 6, max 19). Defaults to true.
   */
  responseToEvents = (response, resetMinMax = true) => {
    let events = [];
    logger.info("MAPPING RESULTS TO EVENTS", response);
    return new Promise((resolve, reject) => {
      try {
        const { status, slotMinTime, slotMaxTime } = this.state;
        const { currentUser } = this.props;

        let minStartHour, maxStartHour;

        if (resetMinMax) {
          minStartHour = parseInt(DEFAULT_MIN_SLOT.substring(0, 2));
          maxStartHour = parseInt(DEFAULT_MAX_SLOT.substring(0, 2));
        } else {
          minStartHour = slotMinTime.substring(1, 2);
          maxStartHour = slotMaxTime.substring(0, 2);
        }

        response.forEach((entry) => {
          const {
            appointmentid,
            carId,
            firstDriverName,
            secondDriverName,
            customerfullname,
            transporttype, // Bloody hell
            transportType,
            transportTypeLine,
            starttime,
            appointmentstate,
            stationtype,

            oxygen,
            dsa,
            corona,
            infusion,
            hinfahrt,
            rueckfahrt,
            firstCarLicencePlate,
            secondCarLicencePlate,
            remark,

            isolated,
            showDescription,

            createUserName,
            createDate,
            lastmodifyDate,
            lastmodifyUserName,

            lastInvoiceDate,
            lastInvoiceNumber,
            notPaid,

            fullFromAddress,
            fullToAddress,
            toAddressTxt,
            fromAddressTxt,
            titleId,

            customerId,
            returnAppointment,
            oxyliter,
            healthInsuranceNumber,
            meetingTime,

            bus,
            vaccinated,
            stayByPatient,

            lastName,
            girlName,

            phone2,
            pphone1,
            pphone2,
            phone1,

            firstDriverReturnName,
            secondDriverReturnName,

            wheelchair,
            elevator,

            weight,
            overweighted,
            stairCount,
            stairs,

            hasReturn,

            driverHasRead,
            driverUpload,
            entryCreatedManually,
          } = entry; // detailed Query call
          let backgroundColor = "darkgray";
          let borderColor = entryCreatedManually ? "#ff0000" : "#a7a7a7";
          let textColor = "white";
          let isGerman =
            currentUser.currentLocale.languageId === LOCALES.GERMAN.languageId;
          let statusName = "";
          if (status && status.length > 0) {
            let selectedStatus = status.find((searchStats) => {
              return searchStats.appointmentStateId === appointmentstate;
            });
            if (selectedStatus) {
              backgroundColor = selectedStatus.color;
              statusName = isGerman
                ? selectedStatus.nameDe
                : selectedStatus.nameFr;
            }
            // Set darker text color for better contrast.
            if (
              backgroundColor === "#FAEBEB" ||
              backgroundColor === "#E8E8E8"
            ) {
              textColor = "#494949";
            }
          }
          let actualTransportType = transportType ?? transporttype;
          let resourceId;
          let appointmentStation = MAIN_LOCATIONS.find((location) => {
            return location.stationId === stationtype;
          });
          if (appointmentStation) {
            switch (appointmentStation.stationName) {
              case STATION_NAMES.LUX:
                // Luxembourg vehicles have mutliple columns. Fetch line number if exists, generate one between 0 & max iteration else.
                let iterations = TRANSPORT_TYPES.find((line) => {
                  return line.transportTypeId === actualTransportType;
                });
                let colNumber = transportTypeLine;
                if (iterations) {
                  if (!colNumber) {
                    colNumber = 0;
                  } else if (colNumber > iterations.luxembourg_count) {
                    colNumber = iterations.luxembourg_count;
                  }
                  resourceId = `${appointmentStation.stationId}-${actualTransportType}-${colNumber}`;
                }
                break;
              case STATION_NAMES.ETB:
                // Ettelbrück vehicles have a single column per vehicle, set transport type parameter to 0.
                resourceId = `${appointmentStation.stationId}-${actualTransportType}-0`;
                break;
              default:
                // Other cases have no vehicle, skip transport line & transport type parameter.
                resourceId = appointmentStation.stationId;
            }
          } else {
            resourceId = appointmentStation.stationId;
          }
          // Set endtime to starttime + 14 minutes to fit into a single calendar block..
          let mappedEndTime;

          mappedEndTime = new Date(starttime);
          mappedEndTime.setMinutes(mappedEndTime.getMinutes() + 15);
          // Check if event is outside regular hours (6:00 and 19:00), adjust minValue if true.
          let appointmentHour = new Date(starttime).getUTCHours();
          if (appointmentHour < minStartHour) {
            minStartHour = appointmentHour;
          } else if (appointmentHour >= maxStartHour) {
            maxStartHour = appointmentHour + 2;
          }
          events.push({
            id: appointmentid,
            customerfullname,
            start: starttime,
            end: mappedEndTime,
            resourceId,
            allDay: false,
            extendedProps: {
              appointmentData: entry,
              tooltipContent: null,
              carId,
              firstDriverName,
              secondDriverName,
              oxygen,
              dsa,
              corona,
              infusion,
              hinfahrt,
              rueckfahrt,
              firstCarLicencePlate,
              secondCarLicencePlate,
              remark,
              isolated,
              showDescription,

              createUserName,
              createDate,
              lastmodifyDate,
              lastmodifyUserName,

              lastInvoiceDate,
              lastInvoiceNumber,
              notPaid,
              fullFromAddress,
              fullToAddress,
              titleId,
              statusText: statusName,

              toAddressTxt,
              fromAddressTxt,

              customerId,
              returnAppointment,
              healthInsuranceNumber,
              oxyliter,
              meetingTime,

              bus,
              vaccinated,
              stayByPatient,
              lastName,
              girlName,

              phone1: phone1 ?? pphone1,
              phone2: phone2 ?? pphone2,

              wheelchair,
              elevator,

              firstDriverReturnName: firstDriverReturnName ?? "",
              secondDriverReturnName: secondDriverReturnName ?? "",

              disableTooltip: false,
              updatePending: false,
              weight,
              overweighted,
              stairCount,
              stairs,
              hasReturn,

              driverHasRead,
              driverUpload,
            },
            backgroundColor,
            borderColor,
            textColor,
          });
        });
        logger.info("DONE MAPPING", events, minStartHour, maxStartHour);
        resolve({
          events,
          slotMinTime: `0${minStartHour}:00:00`,
          slotMaxTime: `${maxStartHour}:00:00`,
        });
      } catch (mapException) {
        logger.error(mapException);
        reject(mapException);
      }
    });
  };

  /**
   * Is called when an appointment was created/edited via dialog.
   * Updates the displayed event list and send the ID of the edited appointment to the websocket.
   *
   * @param {Number} newAppointmentId
   */
  handleAppointmentAdd = (newAppointmentId) => {
    this.fetchAppointments(this.state.displayedDate);
    this.callWebsocket(newAppointmentId);
  };

  fetchComboData = () => {
    const { appointmentTypes, setAppointmentTypes, cars } = this.props;
    const { GET_DRIVERS, GET_CARS, GET_APPOINTMENT_STATUS } = QUERIES;
    sendQuery(GET_DRIVERS, "get").then(
      (response) => {
        if (response && typeof (response[Symbol.iterator] === "function")) {
          let drivers = [...response];
          sortObjectArray(drivers, "alias");
          this.setState({ drivers });
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
    sendQuery(GET_CARS, "get").then(
      (response) => {
        if (response?.content) {
          const activeCars = response.content.filter((car) => car.active);
          this.setState({ cars: activeCars });
          this.props.setCars(cars);
        }
      },
      (error) => {
        logger.warn("Error on cars fetch", error);
        if (cars) {
          this.setState({ cars });
        }
      }
    );
    sendQuery(GET_APPOINTMENT_STATUS, "get").then(
      (response) => {
        if (response) {
          setAppointmentTypes(setShiftOrder(response));
          this.setState({
            status: [...response],
          });
        }
      },
      (error) => {
        logger.warn("Error on status fetch", error);
        if (appointmentTypes && appointmentTypes.length > 0) {
          this.setState({
            status: setShiftOrder(appointmentTypes),
          });
        }
      }
    );
    //}
  };

  /**
   * Sends a request to fetch appointments for the respective date.
   * Stores the received data in the state on success, stores the received error else.
   *
   * @param {Date} startDate The selected date of the scheduler.
   */
  fetchAppointments = (startDate) => {
    let parameters = dateToParameter(startDate);
    const { backendAvailable, appointmentSession, setAppointmentSession } =
      this.props;
    if (this.state.resources)
      this.generateBackgroundEvents(startDate).then((backgroundEvents) => {
        if (backendAvailable) {
          this.setState(
            { eventFetchPending: true, eventFetchError: null },
            () => {
              sendQuery(
                `${QUERIES.GET_DETAILED_APPOINTMENTS_BY_DATE}?fromDate=${parameters[0]}&toDate=${parameters[1]}`,
                "get",
                {}
              ).then(
                (response) => {
                  // Update redux state.
                  let reduxState = { ...appointmentSession };
                  let identifier = dateToSessionIdentifier(startDate);
                  reduxState[identifier] = [...response];
                  if (isWithinRange(new Date(), startDate))
                    setAppointmentSession({
                      ...reduxState,
                    });
                  this.responseToEvents(response).then(
                    (mappedData) => {
                      const { events, slotMinTime, slotMaxTime } = mappedData;
                      // Fetched data from backend, generate calendar.
                      this.setState({
                        currentEvents: [...events, ...backgroundEvents],
                        eventFetchPending: false,
                        slotMaxTime,
                        slotMinTime,
                      });
                      // Force update to rebuild standard onclick behaviour.
                      this.setState(
                        {
                          currentEvents: [...events, ...backgroundEvents, {}],
                        },
                        () => {
                          this.adjustRowHeight().then(this.updateEventHeading);
                          // TODO Update right bar time slots. Not fully working.
                          /*const sidebars = document.getElementsByClassName(
                            "fc-timegrid-col fc-timegrid-axis"
                          );
                          const sideSlots = document.getElementsByClassName(
                            "fc-timegrid-slot fc-timegrid-slot-label fc-scrollgrid-shrink"
                          );                          
                          if (sidebars.length === 2) {
                            sidebars[1].remove();
                            for (let slot of sideSlots) {
                              if (slot.parentElement.childNodes.length === 3)
                                slot.parentElement.childNodes[2].remove();
                            }
                            this.generateRightTimeSlots(false);
                          }*/
                          // Reset right sidebar position.
                          let axis = document.getElementsByClassName(
                            "fc-timegrid-col fc-timegrid-axis"
                          )[1];

                          if (axis) {
                            axis.parentElement.appendChild(axis);
                          }
                        }
                      );
                    },
                    (mapError) => {
                      this.setState({
                        currentEvents: [...backgroundEvents],
                        eventFetchPending: false,
                        eventFetchError: mapError.message,
                      });
                    }
                  );
                },
                (error) => {
                  // Could not fetch data from backend, attempt to fetch data from state.
                  logger.warn(
                    "COULD NOT FETCH APPOINTMENTS",
                    appointmentSession,
                    error
                  );

                  this.fetchReduxAppointments(startDate);
                }
              );
            }
          );
        } else {
          try {
            this.fetchReduxAppointments(startDate);
          } catch (showOfflineException) {
            logger.error(showOfflineException);
          }
        }
      });
  };

  fetchReduxAppointments = (startDate) => {
    const { appointmentSession } = this.props;
    let identifier = dateToSessionIdentifier(startDate);

    if (appointmentSession?.[identifier]) {
      this.generateBackgroundEvents(startDate).then((backgroundEvents) => {
        this.responseToEvents(appointmentSession[identifier]).then(
          (mappedData) => {
            const { events, slotMinTime, slotMaxTime } = mappedData;
            this.setState({
              currentEvents: [...events, ...backgroundEvents],
              eventFetchPending: false,
              slotMinTime,
              slotMaxTime,
            });
            this.adjustRowHeight().then(this.updateEventHeading);
          },
          () => {
            this.setState({
              eventFetchPending: false,
              currentEvents: [...backgroundEvents],
            });
          }
        );
      });
    } else {
      this.setState({
        eventFetchPending: false,
        currentEvents: [],
      });
    }
  };

  dateToSessionIdentifier = (date = new Date()) => {
    return `${date.getDate()}_${date.getMonth()}_${date.getFullYear()}`;
  };

  handleViewChange = (e) => {
    try {
      if (e?.value) {
        this.calendarRef.current.setView(e.value, e.start);
        // Regenerate locations header & right hand time column when returning to day list view.
        if (e.value === PLUGIN_DAY) {
          setTimeout(() => {
            this.generateLocationRow();
            this.generateRightTimeSlots();
          }, 50);
        }
      }
    } catch (exception) {
      logger.error(exception);
    }
  };

  /**
   * Gets called when the date in the calendar component is changed. It will update the state and call the Fullcalendar function to display the selected date.
   *
   * @param {Object} e - The onChange event object of the calendar component.
   * @param {Date} e.value - The selected date.
   */
  handleDateChange = (e) => {
    try {
      this.setState({ displayedDate: e.value }, () => {
        /** @type {Date} */
        let date = e.value;
        if (this.calendarRef.current && date) {
          this.calendarRef.current.setDate(date);
          this.props.setTopbarTitle(
            this.props.intl.formatMessage(
              { id: MESSAGE_KEYS.APPOINTMENTS_TITLE_LABEL },
              {
                date: dateToString(new Date(date)),
              }
            )
          );
        }
      });
    } catch (changeException) {
      logger.error(changeException);
    }
  };

  /**
   * This event gets fired when the user clicks on an empty slot in scheduler.
   * Updates the state to display the dialog an sets schedule selection to the selected time slot.
   *
   * @param {Object} selectedDate An object containing informations of the selected date slot.
   * @param {Date} selectedDate.start The date of the selected time slot.
   */
  handleDateSelect = (selectedDate) => {
    let resourceIds = selectedDate.resource._resource.id.split("-");
    let starttime = stringToOffsetDate(selectedDate.start);
    this.setState({
      showEditDialog: true,
      selection: {
        starttime,
        station: resourceIds.length > 0 ? resourceIds[0] : null,
        transportType: resourceIds.length >= 2 ? resourceIds[1] : null,
        transportTypeLine: resourceIds.length >= 3 ? resourceIds[2] : 0,
      },
    });
  };

  /**
   * Gets called when the user clicks on an event.
   * Calls backend to fetch detailed data of the clicked event. Will display the edit dialog when the data was fetched successfully, will display an error message else.
   * @param {Object} event
   */
  handleEventClick = (event) => {
    logger.log("FULL CLICK", event);

    this.setState({
      selection: null,
      showEditDialog: true,
      appointmentFetchPending: true,
    });
    sendQuery(
      `${QUERIES.GET_APPOINTMENT_BY_ID}${event.event._def.publicId}`,
      "get",
      null
    ).then(
      (response) => {
        this.setState({
          selection: response,
          appointmentFetchPending: false,
        });
      },
      (error) => {
        if (this.toast) {
          logger.warn(error);
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_DATA_FETCH,
            }),
          });
        }
      }
    );
  };

  /**
   * Gets called when an appointment is changed via dragging or API.
   * Maps & sends updated data to the backend.
   * This step is skipped if the tooltip data is added to the extended props.
   *
   * @param {Object} event The changed event.
   */
  handleEventChange = (event) => {
    logger.info("WAS CHANGED", event);
    const { APPOINTMENTS_UPDATE_SUCCESS_MESSAGE, ERROR_DATA_SAVE } =
      MESSAGE_KEYS;

    try {
      const {
        event: {
          extendedProps: { updatePending },
          _def: { publicId, resourceIds },
          _instance: {
            range: { start },
          },
        },
      } = event;
      if (!updatePending) {
        event.event.setExtendedProp("updatePending", true);
        this.setState({
          pendingEvents: [
            ...this.state.pendingEvents,
            parseInt(event.event.id),
          ],
        });
      } else {
        if (publicId > 0) {
          // Fetch & update extended data.
          sendQuery(
            `${QUERIES.GET_APPOINTMENT_BY_ID}${publicId}`,
            "get",
            null
          ).then(
            (response) => {
              let updatedEvent = { ...response };
              updatedEvent.starttime = dateToISOString(utcToDate(start), false);

              let endtime = utcToDate(start);
              // Map end time to start time + 15 minutes
              endtime.setMinutes(endtime.getMinutes() + 15);

              updatedEvent.endtime = dateToISOString(endtime, false);
              try {
                let splitIds = resourceIds[0].split("-");
                updatedEvent.station =
                  splitIds.length > 0 ? parseInt(splitIds[0]) : null;

                let selectedLocation = MAIN_LOCATIONS.find((location) => {
                  return location.stationId === parseInt(splitIds[0]);
                });
                if (selectedLocation) {
                  updatedEvent.stationName = selectedLocation.stationName;
                }

                updatedEvent.transportType =
                  splitIds.length >= 2 ? parseInt(splitIds[1]) : null;
                updatedEvent.transporttype = updatedEvent.transportType; // Bloody camel case typo in backend.
                updatedEvent.transportTypeLine =
                  splitIds.length >= 3 ? parseInt(splitIds[2]) : null;
                updatedEvent.driverHasRead = 0;
                // Send updated event.
                sendQuery(QUERIES.EDIT_APPOINTMENT, "POST", updatedEvent).then(
                  (saveResponse) => {
                    logger.info(
                      "Appointment was saved",
                      saveResponse,
                      updatedEvent
                    );
                    this.toast.show({
                      severity: MESSAGE_SEVERITY.SUCCESS,
                      summary: this.props.intl.formatMessage({
                        id: APPOINTMENTS_UPDATE_SUCCESS_MESSAGE,
                      }),
                    });

                    if (saveResponse && saveResponse.appointmentId) {
                      logger.info("CALLING WEB SOCKET");
                      this.callWebsocket(saveResponse.appointmentId);
                    }
                  },
                  (error) => {
                    logger.warn("Could not save data", error, updatedEvent);
                    this.toast.show({
                      severity: MESSAGE_SEVERITY.ERROR,
                      summary: this.props.intl.formatMessage({
                        id: ERROR_DATA_SAVE,
                      }),
                    });
                  }
                );
              } catch (updateException) {
                logger.warn(
                  "Exception on handle event change during update",
                  updateException,
                  updatedEvent
                );
              }
            },
            (error) => {
              /*this.toast.show(
              MESSAGE_SEVERITY.ERROR,
              typeof error === "string" ? error : error.message
            );*/
            }
          );
        }
      }
    } catch (updateException) {
      logger.warn(updateException);
    }
  };

  updateEvents = (start) => {
    if (this.props.backendAvailable) {
      this.fetchAppointments(start);
    } else {
      this.fetchReduxAppointments(start);
    }
  };

  handleShowHistoricalModal = (id) => {
    if (!id) {
      this.toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: "MISSING ID",
      });
      return;
    }
    const title = `${this.props.intl.formatMessage({
      id: MESSAGE_KEYS.HISTORICAL_APPOINTMENTS_INFO,
    })} ${id}`;

    sendQuery(`${QUERIES.GET_HISTORY_BY_APPOINTMENT}/${id}`, "GET", null).then(
      (data) => {
        if (data.length > 0) {
          this.setState({
            historyModalTitle: title,
            historyModalContent: data,
            showHistoryModal: true,
          });
        } else {
          this.toast.show({
            severity: MESSAGE_SEVERITY.INFO,
            summary: this.props.intl.formatMessage({
              id: MESSAGE_KEYS.HISTORICAL_APPOINTMENTS_NO_RESULT,
            }),
          });
        }
      },
      (error) => {
        logger.error(error);
        this.toast.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: error?.message,
        });
      }
    );
  };

  hideHistoryModal = () => {
    this.setState({ showHistoryModal: false });
  };

  render = () => {
    const {
      showEditDialog,
      selection,
      appointmentFetchPending,
      cars,
      drivers,
      status,
      displayedDate,
      eventFetchPending,
      currentEvents,
      resources,
      slotMaxTime,
      slotMinTime,
      pendingEvents,
      showHistoryModal,
      historyModalTitle,
      historyModalContent,
    } = this.state;
    const { currentUser } = this.props;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <div ref={this.dialogRef}>
          <AppointmentHistoryDialog
            visible={showHistoryModal}
            hideHistoryModal={this.hideHistoryModal}
            title={historyModalTitle}
            content={historyModalContent}
            isGerman={
              this.props.currentUser.currentLocale.languageId ===
              LOCALES.GERMAN.languageId
            }
            status={this.state.status}
            className="history_dialog"
          />
          <AppointmentCreateDialog
            keepInViewport={false}
            id={APPOINTMENT_DIALOG_ID}
            selectedAppointment={selection}
            checkLineNumber={this.checkLineNumber}
            visible={showEditDialog && !appointmentFetchPending}
            onHide={() =>
              this.setState({ showEditDialog: false, selection: null })
            }
            handleParentUpdate={(newId) => {
              this.setState({ showEditDialog: false, selection: null }, () => {
                if (newId) {
                  this.handleAppointmentAdd(newId);
                } else {
                  this.fetchAppointments(this.state.displayedDate);
                }
              });
            }}
            drivers={drivers}
            cars={cars}
            status={status}
            callWebsocket={this.callWebsocket}
          />
        </div>

        <div
          id="scheduler_calendar_placeholder"
          className={this.props.menuActive ? "toolbar_small" : "toolbar_big"}
        ></div>

        <CalendarToolbar
          displayedDate={displayedDate}
          handleDateChange={this.handleDateChange}
          handleViewChange={this.handleViewChange}
          pending={eventFetchPending}
          viewOptions={[
            { icon: "pi pi-calendar", value: PLUGIN_DAY },
            { icon: "pi pi-list", value: PLUGIN_LIST },
          ]}
          id="scheduler_calendar_bar"
          selectOtherMonths={true}
          monthOnly={false}
        />
        <OnCallToolbar value={new Date(displayedDate.getTime())} />
        <div id="scroll_top" />
        <AppointmentScheduler
          ref={this.calendarRef}
          currentUser={currentUser}
          resources={resources}
          slotMaxTime={slotMaxTime}
          slotMinTime={slotMinTime}
          currentEvents={currentEvents}
          handleDateSelect={this.handleDateSelect}
          handleEventClick={this.handleEventClick}
          handleEventChange={this.handleEventChange}
          updateEvents={this.updateEvents}
          pendingEvents={pendingEvents}
          handleShowHistoricalModal={this.handleShowHistoricalModal}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      persist: { appointmentTypes, cars },
      application: { backendAvailable, menuActive },
      session: { appointmentSession },
    } = state;
    return {
      currentUser,
      appointmentSession,
      backendAvailable: backendAvailable,
      appointmentTypes,
      menuActive,
      cars,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      appointmentSession: null,
      backendAvailable: null,
      appointmentTypes: null,
      menuActive: true,
      cars: [],
    };
  }
};

export default connect(mapStateToProps, {
  setTopbarTitle,
  setChangePending,
  setAppointmentTypes,
  setAppointmentSession,
  setCars,
})(injectIntl(SchedulerView));
