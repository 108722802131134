import React from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS } from "assets/staticData/enums";

const GroupmembersDialog = ({ group, visible, closeDialog }) => {
  const intl = useIntl();
  const renderContent = () => {
    const { groupMembers } = group;
    if (groupMembers?.length > 0) {
      return groupMembers.map((member) => (
        <div
          className="p-2"
          style={{ borderBottom: "1px solid gray" }}
          key={member.user.personId}
        >
          {member.user.alias}
        </div>
      ));
    } else {
      return (
        <div>
          <ScrollPanel style={{ width: "100%", height: "150px" }}>
            {intl.formatMessage({
              id: MESSAGE_KEYS.SHOUTBOX_GROUP_EMPTY_MESSAGE,
            })}
          </ScrollPanel>
        </div>
      );
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={closeDialog}
      header={group?.groupName ?? ""}
    >
      <div className="my-5">{renderContent()}</div>
    </Dialog>
  );
};

export default GroupmembersDialog;
