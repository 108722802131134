import React, { useState } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
// PrimeReact components
import { Avatar } from "primereact/avatar";
// Redux
import { connect } from "react-redux";
import { logout } from "actions";
// Localization
import { injectIntl } from "react-intl";
import { MESSAGE_KEYS, URLS } from "assets/staticData/enums";
// Style
import "./Style.scss";

const AppProfile = (props) => {
  let content;
  let history = useHistory();
  let location = useLocation();

  const [expanded, setExpanded] = useState(false);
  const { currentUser } = props;
  try {
    const {
      currentUser: { login, alias, fullname },
      logout,
      intl,
    } = props;

    let avatarLabel;
    let tmpVar = fullname ? fullname : alias;
    tmpVar = tmpVar.split(" ");    

    avatarLabel = tmpVar[0][0];

    const { MENU_ACCOUNT, MENU_LOGOUT } = MESSAGE_KEYS;

    if (currentUser) {
      content = (
        <div className="layout-profile">
          <div>
            <Avatar
              label={avatarLabel}
              className="mr-2"
              size="xlarge"
              shape="circle"
              style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
            />
            {/**<img
              src={`https://robohash.org/${login}?set=set5&size=120x120`}
              alt=""
            /> Old robohash avatar. */}
          </div>
          <button
            className="p-link layout-profile-link"
            onClick={(event) => {
              setExpanded(!expanded);
              event.preventDefault();
            }}
          >
            <span className="username mr-1">{alias ? alias : login}</span>
            <i className={`pi ${expanded ? "pi-angle-up" : "pi-angle-down"}`} />
          </button>
          <ul
            className={classNames({
              "layout-profile-expanded": expanded,
            })}
          >
            <li>
              <button
                className={`p-link ${
                  location.pathname === URLS.ACCOUNT ? "active-route" : ""
                }`}
                onClick={() => {
                  history.push(URLS.ACCOUNT);
                }}
              >
                <i className="pi pi-fw pi-user" />
                <span>{intl.formatMessage({ id: MENU_ACCOUNT })}</span>
              </button>
            </li>
            <li>
              <button className="p-link" onClick={() => logout()}>
                <i className="pi pi-fw pi-power-off" />
                <span>{intl.formatMessage({ id: MENU_LOGOUT })}</span>
              </button>
            </li>
          </ul>
        </div>
      );
    } else {
      content = <></>;
    }
  } catch (renderException) {
    content = <></>;
  } finally {
    return content;
  }
};

const mapStateToProps = (state) => {
  return { ...state.authentication };
};

export default connect(mapStateToProps, { logout })(injectIntl(AppProfile));
