import React, { useState } from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from 'primereact/checkbox';
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";



const FormatDialog = (props) => {
  const { visible, onHide, onAccept, intl } = props;
  const {
    BILLS_MULTI_EXPORT_SORT_TITLE,
    BILLS_MULTI_EXPORT_SORT_SUMMARY,

    DIALOG_CANCEL_BUTTON_LABEL,
    DIALOG_DOWNLOAD_BUTTON_LABEL,
  } = MESSAGE_KEYS;
  const [inputFormat, setFormat] = useState("PDF");
  const [checked, setChecked] = useState(false);
  const footer = (
    <div className="flex flex-row justify-content-between mb-2">
      <Button
        label={intl.formatMessage({ id: DIALOG_DOWNLOAD_BUTTON_LABEL })}
        onClick={() => {
          onAccept(inputFormat, checked ? "&isHospital=true" : "&isHospital=false");
          onHide();
        }}
      />
      <Button
        label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
        onClick={onHide}
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      footer={footer}
      header={intl.formatMessage({ id: BILLS_MULTI_EXPORT_SORT_TITLE })}
    >
      <div className="mt-4">
        <div>{intl.formatMessage({ id: BILLS_MULTI_EXPORT_SORT_SUMMARY })}</div>
        <div className="flex flex-row">
          <div className="mr-2">
            <RadioButton
            id="rdb_pdf"
              value="PDF"
              name="format"
              onChange={(e) => setFormat(e.value)}
              checked={inputFormat === "PDF"}
            />
            <label htmlFor="rdb_pdf" className="ml-1">PDF <i className="pi pi-file-pdf"/></label>
          </div>
          <div>
            <RadioButton
            id="rdb_xls"
              value="XLSX"
              name="format"
              onChange={(e) => setFormat(e.value)}
              checked={inputFormat === "XLSX"}
            />
            <label htmlFor="rdb_xls" className="ml-1">XLS <i className="pi pi-file-excel"/></label>
          </div>
          <div>
            <label htmlFor="hospitalCheck" className="ml-1">Hospital: </label>
            <Checkbox inputId="hospitalCheck" checked={checked} onChange={e => setChecked(e.checked)} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default injectIntl(FormatDialog);
