import React, { Component } from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact Components
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
// Custom components
import { LoginLayout } from "components/common";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { forceLogout } from "actions";
// Static data
import { VIEW_MODES, MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import { initLogger } from "common/Helpers";
// Logging
const logger = initLogger("layout_top_bar");

class AppTopbar extends Component {
  state = {
    viewmodeChecked: this.props.layoutColorMode === VIEW_MODES.DARK.value,
  };

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  renderOfflineMessage = () => {
    const { backendAvailable, intl } = this.props;
    logger.info("DISPLAY OFFLINE MESSAGE", backendAvailable);
    if (!backendAvailable) {
      return (
        <div className="flex flex-row align-items-center">
          {isDesktop
            ? intl.formatMessage({ id: MESSAGE_KEYS.WARNING_OFFLINE_MODE })
            : ""}
          <div className="offline_button" onClick={(e) => this.op.toggle(e)}>
            {isDesktop ? (
              "Check connection"
            ) : (
              <i className="pi pi-exclamation-triangle" />
            )}
          </div>
          <OverlayPanel ref={(el) => (this.op = el)} appendTo={document.body}>
            <LoginLayout />
          </OverlayPanel>
        </div>
      );
    } else {
      return <></>;
    }
  };

  renderTitle = () => {
    const { topbarTitle } = this.props;
    if (topbarTitle) {
      return (
        <div className="top_bar_title">
          <strong>{topbarTitle}</strong>
        </div>
      );
    } else {
      return <></>;
    }
  };

  render() {
    const { intl, onToggleMenu, forceLogout } = this.props;
    return (
      <div className="layout-topbar clearfix">
        <div>
          <button
            className="p-link layout-menu-button"
            onClick={onToggleMenu}
          >
            <span className="pi pi-bars" />
          </button>
        </div>
        <div className={isDesktop ? "" : "top_bar_title_responsive"}>
          {this.renderTitle()}
        </div>
        <div className="layout-topbar-icons">
          {this.renderOfflineMessage()}{" "}
          <Button
            label={intl.formatMessage({id: MESSAGE_KEYS.PURGE_BUTTON_LABEL})}
            severity="danger"
            outlined
            rounded
            size="small"
            onClick={() => forceLogout()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authentication: { currentUser },
    application: { backendAvailable, topbarTitle, topBarTitleAppendix },
  } = state;
  return { currentUser, backendAvailable, topbarTitle, topBarTitleAppendix };
};

export default connect(mapStateToProps, { forceLogout })(injectIntl(AppTopbar));
