import React from "react";
// PrimeReact components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Helper functions
import {
  safeRender,
  initLogger,
  equalObjects,
  sendQuery,
} from "common/Helpers";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";

const logger = initLogger("user_emergency_contact_dialog");
const EMPTY_STATE = {
  inputFirstname: "",
  inputLastname: "",
  inputGsm: "",
  inputPhone: "",

  updatePending: false,
};

class UserEmergencyPersonDialog extends React.Component {
  state = {
    ...EMPTY_STATE,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (!equalObjects(prevProps.value, value, "emergencyContactId")) {
      this.initInputs();
    }
  };

  initInputs = () => {
    const { value } = this.props;
    if (value) {
      const { firstname, lastname, gsm, phone } = value;
      this.setState({
        inputFirstname: firstname ?? "",
        inputLastname: lastname ?? "",
        inputGsm: gsm ?? "",
        inputPhone: phone ?? "",
      });
    } else {
      this.setState({
        ...EMPTY_STATE,
      });
    }
  };

  mapInputsToDTO = () => {
    try {
      const { value, userId } = this.props;
      const { inputFirstname, inputLastname, inputGsm, inputPhone } =
        this.state;
      return {
        emergencyContactId: value?.emergencyContactId
          ? value.emergencyContactId
          : null,
        firstname: inputFirstname ?? "",
        lastname: inputLastname ?? "",
        gsm: inputGsm ?? "",
        phone: inputPhone ?? "",
        personId: userId,
      };
    } catch (mapException) {
      logger.warn(mapException);
      return null;
    }
  };

  handleSave = (data) => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({ updatePending: true });
        sendQuery(QUERIES.ADD_USERS_EMERGENCY_PERSON, "post", data).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            logger.warn(error);
            reject();
          }
        );
      } catch (saveException) {
        logger.warn(saveException);
        reject();
      }
    });
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    const {
      USERS_EMERGENCY_PERSON_ADD_SUCCESS,
      USERS_EMERGENCY_PERSON_ADD_FAIL,
    } = MESSAGE_KEYS;
    let data = this.mapInputsToDTO();
    if (data) {
      this.handleSave(data).then(
        (newId) => {
          this.props.handleSave({
            ...data,
            emergencyContactId: newId,
          });
          this.toast.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage({
              id: USERS_EMERGENCY_PERSON_ADD_SUCCESS,
            }),
          });
          this.setState({ ...EMPTY_STATE });
        },
        () => {
          this.toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: USERS_EMERGENCY_PERSON_ADD_FAIL,
            }),
          });
          this.setState({ updatePending: false });
        }
      );
    }
  };

  renderFooter = () => {
    const { onHide, intl, value } = this.props;
    const {
      DIALOG_CANCEL_BUTTON_LABEL,
      USERS_ADD_EMERGENCY_PERSON_LABEL,
      USERS_EDIT_EMERGENCY_PERSON_LABEL,
    } = MESSAGE_KEYS;
    const { updatePending } = this.state;
    let saveLabel = intl.formatMessage({
      id: value
        ? USERS_EDIT_EMERGENCY_PERSON_LABEL
        : USERS_ADD_EMERGENCY_PERSON_LABEL,
    });
    return (
      <div className="flex flex-row justify-content-between mb-4">
        <Button
          icon={updatePending ? "pi pi-spin pi-spinner" : ""}
          disabled={updatePending}
          label={saveLabel}
          onClick={this.handleSaveClick}
        />
        <Button
          label={intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL })}
          onClick={onHide}
          disabled={updatePending}
        />
      </div>
    );
  };

  renderContent = () => {
    const { intl } = this.props;
    const {
      USERS_FIRST_NAME_LABEL,
      USERS_LAST_NAME_LABEL,
      USERS_MOBILE_PHONE_LABEL,
      USERS_HOME_PHONE_LABEL,
    } = MESSAGE_KEYS;
    const {
      inputFirstname,
      inputLastname,
      inputGsm,
      inputPhone,
      updatePending,
    } = this.state;
    return (
      <div>
        <div className="mt-4">
          <FloatingTextInput
            value={inputFirstname}
            label={intl.formatMessage({
              id: USERS_FIRST_NAME_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputFirstname: e.target.value });
            }}
            className="mr-2"
            disabled={updatePending}
          />
          <FloatingTextInput
            value={inputLastname}
            label={intl.formatMessage({
              id: USERS_LAST_NAME_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputLastname: e.target.value });
            }}
            className="mr-2 mt-5"
            disabled={updatePending}
          />
          <FloatingTextInput
            value={inputPhone}
            label={intl.formatMessage({
              id: USERS_HOME_PHONE_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputPhone: e.target.value });
            }}
            className="mr-2 mt-5"
            disabled={updatePending}
          />
          <FloatingTextInput
            value={inputGsm}
            label={intl.formatMessage({
              id: USERS_MOBILE_PHONE_LABEL,
            })}
            onChange={(e) => {
              this.setState({ inputGsm: e.target.value });
            }}
            className="mr-2 mt-5"
            disabled={updatePending}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const { visible, onHide, intl, value } = this.props;
    const {
      USERS_ADD_EMERGENCY_PERSON_LABEL,
      USERS_DELETE_EMERGENCY_PERSON_LABEL,
    } = MESSAGE_KEYS;
    let title = intl.formatMessage({
      id: value
        ? USERS_DELETE_EMERGENCY_PERSON_LABEL
        : USERS_ADD_EMERGENCY_PERSON_LABEL,
    });
    return (
      <Dialog
        header={title}
        visible={visible}
        onHide={onHide}
        footer={() => safeRender(this.renderFooter, "")}
        className="dialog-emergency-contact"
      >
        <Toast ref={(el) => (this.toast = el)} />
        {safeRender(
          this.renderContent,
          intl.formatMessage({ id: MESSAGE_KEYS.ERROR_RENDER })
        )}
      </Dialog>
    );
  };
}

export default injectIntl(UserEmergencyPersonDialog);
