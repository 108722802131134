import React from "react";
// React redux
import { connect } from "react-redux";
import { setBaseValue } from "actions/wizardActions";
// PrimeReact components
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { Divider } from "primereact/divider";
// Custom components
import { FloatingTextInput, SplitDateTimeInput } from "components/common";
import { ChecklistItems, ReturnInputs } from "./DriveComponents";
// Helper functions
import {
  initLogger,
  dateToISOString,
  dateToMaskString,
  valiDate,
  addLeadingZero,
  findObjectById,
  mapDriversToComboData,
  dateToQueryString,
} from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static data
import { MESSAGE_KEYS, STATION_NAMES, LOCALES } from "assets/staticData/enums";
import { MAIN_LOCATIONS } from "assets/staticData/combodata";
import TicketTransport from "components/DrivesView/components/TicketViews/TicketTransport";
import TicketTimes from "components/DrivesView/components/TicketViews/TicketTimes";
// Stylingnt
import "./Style.scss";
// Logging
const logger = initLogger("appointment_drive_view");

class AppointmentDriveView extends React.Component {
  state = {
    inputStatus: null,
    inputVehicleType: null,
    inputVehicle: null,
    inputStartDate: undefined,
    inputFirstDriver: null,
    inputSecondDriver: null,
    inputRendezVous: "",
    inputReturn: "",
    inputRegion: STATION_NAMES.LUX,
    inputComment: "",
    inputLine: 0,
    inputDateMask: "",

    inputNextAppointment: "",
    inputHasMulti: false,
    inputChecklist: [],

    inputKilometer: null,

    validStartDate: null,
    validStatus: null,
    validVehicleType: null,

    transportTypesMapped: [],
    advancedStatus: [],
    inputTransportTypeInvoice: 0,

    inputDriveTimes: null,
  };

  componentDidMount = () => {
    this.initInputs();
  };

  initInputs = () => {
    const { value, drivers } = this.props;
    if (value) {
      const {
        transportType,
        station,
        state,
        starttime,
        remark,
        appointmentChecklistItems,
        car,
        firstDriver,
        secondDriver,

        meetingTime,
        nextAppointment,
        kilometer,

        multipleChecked,
        transportTypeInvoice,

        outwardPaper,
        outwardDeparture,
        outwardArrival,
        outwardDischarge,

        returnPaper,
        returnDeparture,
        returnArrival,
        returnDischarge,
      } = value;

      // Fetch region/status object based on the respective id.
      let inputRegion = station
        ? findObjectById(
            MAIN_LOCATIONS,
            typeof station === "number" ? station : parseInt(station),
            "stationId"
          )
        : MAIN_LOCATIONS[0];

      const advancedStatus = this.props.status
        .filter((aType) => aType.visible === true)
        .sort((a, b) => a.sort - b.sort);
      const inputStatus = advancedStatus.find(
        (aType) => aType.appointmentStateId === state
      );
      // The drivers received by an existing appointment are different objects than the simple combobox data object. Find & set the corresponding drivers from the combobox data.
      let inputFirstDriver = mapDriversToComboData(firstDriver, drivers);
      let inputSecondDriver = mapDriversToComboData(secondDriver, drivers);

      let mappedStartDate = new Date(starttime);
      let mappedNextAppointmentDate = nextAppointment
        ? new Date(nextAppointment)
        : "";

      //mappedStartDate.setHours(mappedStartDate.getHours()+1);
      let inputDateMask = dateToMaskString(mappedStartDate, true, "/");
      let inputRendezVous = "";

      try {
        if (typeof meetingTime === "string") {
          let tmpDate = new Date(meetingTime);
          tmpDate = new Date(
            tmpDate.getTime() + tmpDate.getTimezoneOffset() * 60000
          );
          inputRendezVous = `${addLeadingZero(
            tmpDate.getHours()
          )}:${addLeadingZero(tmpDate.getMinutes())}`;
        } else {
          if (meetingTime) {
            inputRendezVous = `${addLeadingZero(
              meetingTime.getHours()
            )}:${addLeadingZero(meetingTime.getMinutes())}`;
          }
        }
      } catch (rdvExpception) {
        logger.warn(rdvExpception);
        inputRendezVous = "";
      }

      this.setState({
        inputStartDate: mappedStartDate,
        inputComment: remark ?? "",
        inputNextAppointment: mappedNextAppointmentDate,
        inputVehicleType: transportType
          ? { transportTypeId: transportType }
          : null,
        inputChecklist: appointmentChecklistItems
          ? [...appointmentChecklistItems]
          : [],
        inputVehicle: car,
        inputFirstDriver,
        inputSecondDriver,
        inputRegion,
        inputLine: parseInt(value.transportTypeLine),
        inputDateMask,
        inputRendezVous,

        inputKilometer: kilometer ?? null,

        inputHasMulti: multipleChecked,
        inputStatus,

        inputTransportTypeInvoice: transportTypeInvoice,

        inputDriveTimes: {
          outwardPaper,
          outwardDeparture,
          outwardArrival,
          outwardDischarge,

          returnPaper,
          returnDeparture,
          returnArrival,
          returnDischarge,
        },

        advancedStatus,
      });
    }
  };

  mapInputsToDTO = () => {
    let appointmentDrive;
    try {
      const {
        inputRendezVous,
        inputRegion,
        inputComment,

        inputStatus,

        inputVehicle,

        inputFirstDriver,
        inputSecondDriver,
        inputStartDate,
        inputLine,

        inputChecklist,

        inputKilometer,

        inputHasMulti,

        inputNextAppointment,
        inputTransportTypeInvoice,

        inputDriveTimes,
      } = this.state;
      let transportType;

      const { value } = this.props;
      transportType = value.transportType ? value.transportType : null;

      let meetingTime = null;
      if (inputRendezVous && inputStartDate) {
        meetingTime = new Date(inputStartDate);
        try {
          let timeSplit = inputRendezVous.split(":");
          if (timeSplit.length === 2) {
            meetingTime.setHours(timeSplit[0], timeSplit[1]);
          }
        } catch (dateException) {
          logger.warn(dateException);
        }
      }
      let startDate = inputStartDate;

      if (isNaN(inputStartDate?.getTime())) {
        try {
          startDate = new Date(this.props.value.starttime);
        } catch (dateException) {
          logger.warn(dateException);
        }
      }

      const {
        outwardPaper,
        outwardDeparture,
        outwardArrival,
        outwardDischarge,

        returnPaper,
        returnDeparture,
        returnArrival,
        returnDischarge,
      } = inputDriveTimes;

      appointmentDrive = {
        ...this.props.value,
        state: inputStatus ? inputStatus.appointmentStateId : null,
        stateName: inputStatus ? inputStatus.name : null,
        starttime: startDate ? startDate.getTime() : null,
        meetingTime: meetingTime ? dateToISOString(meetingTime, false) : null,
        appointmentChecklistItems: inputChecklist,
        car: inputVehicle ?? null,
        transportType,
        transportTypeName: inputVehicle ? inputVehicle.transportType : null,
        firstDriver: inputFirstDriver ?? null,
        secondDriver: inputSecondDriver ?? null,
        station: inputRegion ? inputRegion.stationId : null,
        stationName: inputRegion ? inputRegion.stationName : null,
        transportTypeLine: inputLine,
        transportTypeLineName: "", // TODO Is this value ever set?
        remark: inputComment ? inputComment : "",
        remark2: "",

        kilometer: inputKilometer ? inputKilometer : null,

        showDescription: inputComment ? true : false,
        isolated: false,
        multipleChecked: inputHasMulti,

        nextAppointment: valiDate(inputNextAppointment)
          ? dateToQueryString(inputNextAppointment, true)
          : null,

        transportTypeInvoice: inputTransportTypeInvoice,
        outwardPaper,
        outwardDeparture,
        outwardArrival,
        outwardDischarge,

        returnPaper,
        returnDeparture,
        returnArrival,
        returnDischarge,
      };
      return appointmentDrive;
    } catch (mappingException) {
      logger.warn(
        "Exception on map inputs to DTO",
        mappingException,
        this.state
      );
      return null;
    }
  };

  handleNextClick = () => {
    const { handleAppointmentSave, setBaseValue, handleParentUpdate } =
      this.props;
    setBaseValue("appointmentDrive", this.mapInputsToDTO()).then(() => {
      if (this.state.inputHasMulti) {
        handleParentUpdate();
      } else {
        handleAppointmentSave();
      }
    });
  };

  handleBackClick = () => {
    const { setBaseValue } = this.props;
    setBaseValue("appointmentDrive", this.mapInputsToDTO()).then(() => {
      this.props.handleBackClick();
    });
  };

  handleStatusChange = (e) => {
    this.setState({ inputStatus: e.value });
  };

  renderButtonRow = () => {
    const {
      intl,
      value: { appointmentId = null },
    } = this.props;
    const {
      ERROR_RENDER,
      APPOINTMENTS_MULTIPLE_DRIVES_LABEL,
      DIALOG_CONTINUE_BUTTON_LABEL,
      DIALOG_BACK_BUTTON_LABEL,
      APPOINTMENTS_SAVE_BUTTON_LABEL,
      APPOINTMENTS_CREATE_BUTTON_LABEL,
    } = MESSAGE_KEYS;
    const { inputHasMulti } = this.state;

    let nextButtonLabel = "";
    if (!inputHasMulti) {
      nextButtonLabel =
        appointmentId !== null
          ? APPOINTMENTS_SAVE_BUTTON_LABEL
          : APPOINTMENTS_CREATE_BUTTON_LABEL;
    } else {
      nextButtonLabel = DIALOG_CONTINUE_BUTTON_LABEL;
    }

    try {
      return (
        <div className="grid mt-3">
          <div className="col-4 flex justify-content-start">
            <Button
              label={intl.formatMessage({ id: DIALOG_BACK_BUTTON_LABEL })}
              onClick={this.handleBackClick}
            />
          </div>
          <div className="col-4 flex align-items-center justify-content-center">
            <Checkbox
              inputId={APPOINTMENTS_MULTIPLE_DRIVES_LABEL}
              onChange={(e) => this.setState({ inputHasMulti: e.checked })}
              checked={inputHasMulti}
            />
            <label
              htmlFor={APPOINTMENTS_MULTIPLE_DRIVES_LABEL}
              className="p-checkbox-label ml-2"
            >
              {intl.formatMessage({ id: APPOINTMENTS_MULTIPLE_DRIVES_LABEL })}
            </label>{" "}
          </div>
          <div className="col-4 flex justify-content-end">
            <Button
              label={intl.formatMessage({
                id: nextButtonLabel,
              })}
              onClick={this.handleNextClick}
            />
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on map button row", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderDepartureInputs = () => {
    const { intl, cars, drivers, currentUser } = this.props;
    const {
      APPOINTMENTS_STATUS_LABEL,
      APPOINTMENTS_VEHICLE_LABEL,

      APPOINTMENTS_FIRST_DRIVER_LABEL,
      APPOINTMENTS_SECOND_DRIVER_LABEL,
      APPOINTMENTS_DRIVE_APPOINTMENT,
      APPOINTMENTS_DEPARTURE_TIME,

      APPOINTMENTS_KILOMETERS,
      APPOINTMENT_STATUS_OUTWARD,
      APPOINTMENT_STATUS_RETURN,
      DRIVES_TRANSPORT_TITLE,

      ERROR_RENDER,
    } = MESSAGE_KEYS;

    try {
      const {
        inputStatus,

        inputVehicle,
        validStatus,

        inputRegion,
        inputFirstDriver,
        inputSecondDriver,
        inputRendezVous,

        inputStartDate,

        inputKilometer,

        advancedStatus,
        inputTransportTypeInvoice,

        inputDriveTimes,
      } = this.state;

      let isGerman = true;
      if (currentUser && currentUser.currentLocale) {
        isGerman =
          currentUser.currentLocale.languageId === LOCALES.GERMAN.languageId;
      }

      let displayedCars = [];
      if (cars?.length > 0) {
        displayedCars = [...cars];
      } else if (inputVehicle) {
        displayedCars = [inputVehicle];
      }

      return (
        <div className="grid">
          <div className="col-3 flex align-items-end">
            <Dropdown
              value={inputStatus}
              options={advancedStatus}
              onChange={this.handleStatusChange}
              placeholder={intl.formatMessage({
                id: APPOINTMENTS_STATUS_LABEL,
              })}
              valid={validStatus}
              style={{ width: "100%" }}
              optionLabel={isGerman ? "nameDe" : "nameFr"}
              optionDisabled="inactive"
            />
          </div>
          <div className="col-2 flex align-items-center">
            <strong style={{ marginTop: "16px" }}>
              {inputRegion?.stationName ? inputRegion.stationName : ""}
            </strong>
          </div>
          <div className="col-4 flex align-items-end">
            <Dropdown
              value={inputVehicle}
              options={displayedCars}
              onChange={(e) =>
                this.setState({
                  inputVehicle: e.value,
                })
              }
              optionLabel="license_plate"
              filterBy="license_plate"
              filter
              showClear
              placeholder={intl.formatMessage({
                id: APPOINTMENTS_VEHICLE_LABEL,
              })}
              style={{ width: "100%" }}
              filterInputAutoFocus={true}
            />
          </div>

          <div className="col-3 flex align-items-end">
            <span className="p-float-label">
              <InputMask
                type="tel"
                id={APPOINTMENTS_DEPARTURE_TIME}
                mask="99:99"
                value={inputRendezVous}
                onChange={(e) => this.setState({ inputRendezVous: e.value })}
                style={{ width: "100%" }}
              />
              <label htmlFor={APPOINTMENTS_DEPARTURE_TIME}>
                {intl.formatMessage({ id: APPOINTMENTS_DEPARTURE_TIME })}
              </label>
            </span>

            <FloatingTextInput
              className="ml-1"
              value={inputKilometer}
              onChange={(e) =>
                this.setState({
                  inputKilometer: e.target.value,
                })
              }
              label={intl.formatMessage({ id: APPOINTMENTS_KILOMETERS })}
            />
          </div>

          <div className="col-10">
            <div className="grid flex">
              <div className="col-4 flex align-items-end">
                <SplitDateTimeInput
                  value={inputStartDate}
                  onChange={(selection) => {
                    let tmpDate = null;
                    if (valiDate(selection)) {
                      // If date is valid, add an hours to prevent UTC conversion BS.
                      tmpDate = new Date(selection.getTime());
                    }
                    this.setState({
                      inputStartDate: tmpDate,
                    });
                  }}
                  label={intl.formatMessage({
                    id: APPOINTMENTS_DRIVE_APPOINTMENT,
                  })}
                  showTime
                  disableDrag
                />
              </div>

              <div className="col-4 flex align-items-end">
                <Dropdown
                  id={APPOINTMENTS_FIRST_DRIVER_LABEL}
                  placeholder={intl.formatMessage({
                    id: APPOINTMENTS_FIRST_DRIVER_LABEL,
                  })}
                  optionLabel="alias"
                  value={inputFirstDriver}
                  options={drivers}
                  onChange={(e) => {
                    this.setState({ inputFirstDriver: e.value });
                  }}
                  style={{ width: "100%" }}
                  disabled={!drivers || drivers.length < 1}
                  appendTo={document.body}
                  filter
                  showClear
                  filterBy="alias"
                  filterInputAutoFocus={true}
                />
              </div>
              <div className="col-4 flex align-items-end">
                <Dropdown
                  id={APPOINTMENTS_SECOND_DRIVER_LABEL}
                  placeholder={intl.formatMessage({
                    id: APPOINTMENTS_SECOND_DRIVER_LABEL,
                  })}
                  optionLabel="alias"
                  value={inputSecondDriver}
                  options={drivers}
                  onChange={(e) => {
                    this.setState({ inputSecondDriver: e.value });
                  }}
                  style={{ width: "100%" }}
                  disabled={!drivers || drivers.length < 1}
                  appendTo={document.body}
                  filter
                  showClear
                  filterBy="alias"
                  filterInputAutoFocus={true}
                />
              </div>
            </div>
            <div className="flex justify-content-between">
              <div className="flex flex-column justify-content-center ticket_vertical">
                <div
                  className="font-light split_label flex align-items-bottom"
                  style={{ lineHeight: "inherit" }}
                >
                  <span>
                    {intl.formatMessage({ id: DRIVES_TRANSPORT_TITLE })}
                  </span>
                </div>
                <TicketTransport
                  inputData={{
                    transportTypeInvoice: inputTransportTypeInvoice,
                  }}
                  hideTitle
                  updateParent={(selectedType) => {
                    this.setState({
                      inputTransportTypeInvoice:
                        selectedType?.transportTypeInvoice,
                    });
                  }}
                />
              </div>

              {inputDriveTimes && (
                <TicketTimes
                  title={intl.formatMessage({
                    id: APPOINTMENT_STATUS_OUTWARD,
                  })}
                  inputData={inputDriveTimes}
                  isDeparture
                  updateParent={(newTimes) => {
                    this.setState({ inputDriveTimes: newTimes });
                  }}
                  appointment={inputDriveTimes}
                  validPaper
                  validDischarge
                  lightHeader
                />
              )}

              {inputDriveTimes && (
                <TicketTimes
                  title={intl.formatMessage({
                    id: APPOINTMENT_STATUS_RETURN,
                  })}
                  inputData={inputDriveTimes}
                  updateParent={(newTimes) => {
                    this.setState({ inputDriveTimes: newTimes });
                  }}
                  appointment={inputDriveTimes}
                  validPaper
                  validDischarge
                  lightHeader
                />
              )}

              <div className="flex flex-column justify-content-end ticket_checklist wizard">
                <ChecklistItems
                  groups={["transport"]}
                  numberColumns={1}
                  allItems={this.state.inputChecklist}
                />
              </div>
            </div>
          </div>

          <div className="col-2 flex flex-column justify-content-end ticket_checklist wizard">
            <ChecklistItems
              groups={["transport2"]}
              numberColumns={1}
              allItems={this.state.inputChecklist}
            />
          </div>
        </div>
      );
    } catch (renderException) {
      logger.warn("Exception on render appointment inputs", renderException);
      return <div>{intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  render = () => {
    const { intl, cars, drivers } = this.props;
    const { inputComment, inputNextAppointment } = this.state;
    const {
      APPOINTMENTS_DRIVE_STEP_LABEL,
      APPOINTMENTS_RETURN_HEADER,
      APPOINTMENTS_COMMENT_LABEL,
      APPOINTMENTS_NEXT_APPOINTMENT_LABEL,
    } = MESSAGE_KEYS;

    return (
      <div id="drive_view-body">
        <div className="grid" id="app_drive_view">
          <div className="col-12">
            <Fieldset
              legend={intl.formatMessage({
                id: APPOINTMENTS_DRIVE_STEP_LABEL,
              })}
            >
              {this.renderDepartureInputs()}

              <Divider />
              <div className="ticket_checklist wizard">
                <ChecklistItems
                  groups={["info", "material", "desinfektion"]}
                  numberColumns={4}
                  allItems={this.state.inputChecklist}
                />
              </div>
            </Fieldset>
          </div>
          <div className="col-8">
            <Fieldset
              legend={intl.formatMessage({ id: APPOINTMENTS_RETURN_HEADER })}
            >
              <ReturnInputs cars={cars} drivers={drivers} />
            </Fieldset>
          </div>
          <div className="col-4">
            <div className="mt-2">
              <SplitDateTimeInput
                value={inputNextAppointment}
                onChange={(selection) => {
                  let tmpDate = null;
                  if (valiDate(selection)) {
                    // If date is valid, add an hours to prevent UTC conversion BS.
                    tmpDate = new Date(selection.getTime());
                  }
                  this.setState({
                    inputNextAppointment: tmpDate,
                  });
                }}
                label={intl.formatMessage({
                  id: APPOINTMENTS_NEXT_APPOINTMENT_LABEL,
                })}
                showTime
                disableDrag
              />
            </div>
            <span className="mt-2" style={{ flexGrow: 1 }}>
              <label
                htmlFor="textarea"
                style={{ fontSize: "9pt", fontWeight: "300" }}
              >
                {intl.formatMessage({ id: APPOINTMENTS_COMMENT_LABEL })}
              </label>
              <InputTextarea
                id="textarea"
                value={inputComment}
                onChange={(e) =>
                  this.setState({ inputComment: e.target.value })
                }
                rows={4}
                style={{ width: "100%" }}
              />
            </span>
          </div>
        </div>

        {this.renderButtonRow()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      authentication: { currentUser },
      appWizard: { appointmentDrive },
    } = state;
    return {
      currentUser,
      value: appointmentDrive,
    };
  } catch (mapException) {
    return {
      currentUser: null,
      value: null,
    };
  }
};

export default connect(mapStateToProps, { setBaseValue })(
  injectIntl(AppointmentDriveView)
);
